import React from 'react';

const WidgetTop = (props) => {
  return <div id={'WidgetTop'}>
    <div className={'widgetTop d-flex align-items-center justify-content-between mb-2 mt-3'}>
      <div className={'widgetTitle'}>{props.title}</div>
      <div className={'widgetAction'}>
        {props.action}
      </div>
    </div>
  </div>;
};

export default WidgetTop;
