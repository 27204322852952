import React, {useEffect,useState} from 'react';
import "./Payroll.css"

import {Tab, Nav, Form, Modal, Button, Col, Container, Row, Card, Spinner, Badge} from "react-bootstrap";
import ContextMenu from "../../menus/ContextMenu";
import DataTable from "react-data-table-component";
import mSVG from "../../../assets/gender/m.png";
import * as moment from "moment";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import * as RI from "react-icons/ri"
import * as BI from "react-icons/bi"
import * as FI from "react-icons/fi"
import {BsPlus} from "react-icons/bs";
import Select from "react-select";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {formatDate, parseDate} from "react-day-picker/moment";
import {getFormatedDate, isAdmin, userIsEmployee,
    handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
import {getFileUrl, uploadOnCdn} from "../../../service";
import Setting from "../../widgets/Setting/Setting";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions};
const optionMois = [
    { value: 'Janvier', label: 'Janvier' },
    { value: 'Février', label: 'Février' },
    { value: 'Mars', label: 'Mars' },
    { value: 'Avril', label: 'Avril' },
    { value: 'Mais', label: 'Mais' },
    { value: 'Juin', label: 'Juin' },
    { value: 'Juillet', label: 'Juillet' },
    { value: 'Août', label: 'Août' },
    { value: 'Septembre', label: 'Septembre' },
    { value: 'Octobre', label: 'Octobre' },
    { value: 'Novembre', label: 'Novembre' },
    { value: 'Decembre', label: 'Decembre' },
];
const OptionAnnee = [
    { value: '2015', label: '2015' },
    { value: '2016', label: '2016' },
    { value: '2017', label: '2017' },
    { value: '2018', label: '2018' },
    { value: '2019', label: '2019' },
    { value: '2020', label: '2020' },
    { value: '2021', label: '2021' },
    { value: '2022', label: '2022' },
    { value: '2023', label: '2023' },
    { value: '2024', label: '2024' },
    { value: '2025', label: '2025' },
    { value: '2026', label: '2026' },
    { value: '2027', label: '2027' },
    { value: '2028', label: '2028' },
    { value: '2029', label: '2029' },
    { value: '2030', label: '2030' },
];


const Payroll = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    useEffect(() => {
        props.action_listPayrolls();
        props.action_listExpenses();
        props.action_listTypeDepense();
        props.action_listColleagues();
        props.action_listSetting();
        props.action_setTitle("Payroll")
    }, []);
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    const [show, setShow] = useState([]);
    const [type, setType] = useState();
    const [recu, setRecu] = useState(undefined)
    const [amount, setAmount] = useState();
    const [comment, setComment] = useState();
    const [employee, setEmployee] = useState();
    const [payroll_date, setPayrollDate] = useState();
    const [payroll_description, setPayrollDescription] = useState();
    const [payroll_file, setPayrollFile] = useState();
    const [expenseDate, setExpenseDate] = useState(formatDate(new Date(), 'DD-MM-YYYY HH:MM:SS', 'fr'));
    const [name, setName] = useState();
    const [description, setDescriptionName] = useState();
    const [current_row, setCurrentRow] = useState();
    const [isUpdate, setHasUpdate] = useState(false);
    const [mois, setMois] = useState()
    const [annee, setAnnee] = useState()
    const [jour, setJour] = useState()
    const [element, setElement] = useState()
    const [legende, setLegendStatus] = useState(false)
    const [formError, setFormError] = useState({})
    const [payrollFileName, setPayrollFileName] = useState(t("no_file_choose"))
    const [expenseFileName, setExpenseFileName] = useState(t("no_file_choose"))
    const handlePayrollEmployee = (e) => {
        setEmployee(e._id)
        let data =handleValidation(t,{type:"text",name:'payrollEmployee','value':e._id,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
  const handlePayrollFile = (e) => {
        let file_data = e.target.files[0]
        if(file_data){
        setPayrollFile(file_data)
        setPayrollFileName(file_data.name)
        let data =handleValidation(t,{type:"text",name:'payrollfile','value':file_data.name,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
        }
    };

const handlePayrollDate = (e) => {
        setPayrollDate(e.target.value)
        let data =handleValidation(t,{type:"text",name:'payrollDate','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
                
    };

const handlePayrollDescription = (e) => {
        setPayrollDescription(e.target.value)
       
    };

    const resetPayroll = () => {
        setEmployee('')
        setPayrollDate('')
        setPayrollFileName('')
        setPayrollFile('')
        setPayrollDescription('')
        setFormError({})
}

    const requestDataBuilderPayroll = () => (
        {
            "employee": employee,
            "date": payroll_date,
            "description": payroll_description,
            "sio_channel": "",
        }
    );
    const createPayroll = () => {
        let  file=payroll_file;
        setPayrollFile(undefined)
        let o = requestDataBuilderPayroll();
        if(file){
            uploadOnCdn(file)
                .then(resp => {
                    console.log('cdn upload pp response : ', resp);
                    setPayrollFile(resp?.filename);
                    o.file=resp?.filename;
                        props.action_createPayroll(o)
                }).catch(e=>{
                props.action_createPayroll(o)
            })
        }
        setShow(null);
        props.action_loading(true)
        resetPayroll()
    };

    const handleLegende = () => {
       legende ? setLegendStatus(false) : setLegendStatus(true);
    };
    const handleClose = () => {
        setShow(null)
        setFormError({})
    };


    const handleTypeChange = (e) => {
        setType(e._id)
        let data =handleValidation(t,{type:"text",name:'typeChange','value':e._id,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };

    const handleExpenseDate = (expenseDate) => {
        let d = new moment(expenseDate).format('Y-M-D HH:MM:SS');
        //let d = new moment(expenseDate);
        setExpenseDate(d)
        let data =handleValidation(t,{type:"date",name:'expenseDate','value':d,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
               
    };
    const handleFile = (e) => {
        let file_data = e.target.files[0]
        if(file_data){
        setRecu(file_data)
        setExpenseFileName(file_data.name)
        let data =handleValidation(t,{type:"text",name:'expenseFile','value':file_data.name,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
        }
    };
    const handleAmount = (e) => {
        setAmount(e.target.value)
        let data =handleValidation(t,{type:"number",name:'amount','value':e.target.value,required:true,min:1});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handleComment = (e) => {
        setComment(e.target.value)
        let data =handleValidation(t,{type:"textarea",name:'expenseComment','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const editExpense = (expense)=>{
        expense['id'] = expense._id;
        setCurrentRow(expense);
        setComment(expense.comments);
        setAmount(expense.amount);
        setExpenseDate(expense.payment_date);
        setType(expense.expensetype_info[0]);
        setHasUpdate(true);
        setShow('expense_modal')
        let data=  name([
            {type:"text",name:'expenseComment','value':expense.comments,required:true},
            {type:"number",name:'amount','value':expense.amount,required:true,min:1},
            {type:"date",name:'expenseDate','value':expense.payment_date,required:true},
            {type:"text",name:'typeChange','value':expense.expensetype_info[0],required:true},
            {type:"text",name:'expenseFile','value':expense.cours,required:true},
             ])
                setFormError(data)
    };
    const resetExpense = () => {
        setType('')
        setAmount('')
        setComment('')
        setExpenseDate('')
        setFormError({})
        setExpenseFileName('')
}
    const requestDataBuilderExpense = () => (
        {
            "expense_type": type,
            "amount": amount,
            "comments": comment,
            "date_depense": expenseDate,
            "sio_channel": "",
        }
    );
    const createExpense = () => {
        let  file=recu;
        setRecu(undefined)
        let o = requestDataBuilderExpense();
        if(file){
            uploadOnCdn(file)
                .then(resp => {
                    setRecu(resp?.filename);
                    o.cours=resp?.filename;
                    if (!isUpdate){
                        props.action_createExpense(o)
                    }
                    else{
                        o.id = current_row._id;
                        props.action_updateExpense(o);
                    }

                })
        }else{
            if (!isUpdate){
                props.action_createExpense(o)
            }
            else{
                o.id = current_row._id;
                props.action_updateExpense(o);
            }
        }
        setShow(null);
        props.action_loading(true)
        resetExpense()
    };
    const deleteExpense = () => {
        setShow(null);
        props.action_deleteExpense(current_row._id);
        props.action_loading(true)
    };
    const validExpense = (expense)=>{
        setShow(null);
        props.action_validExpense(expense._id);
        props.action_loading(true)
    };

    const approbateExpense = () => {
        setShow(null);
        props.action_appobateExpense(current_row._id);
        props.action_loading(true)
    };
    const handleShowDetails = (expense)=>{
        setShow('show_detail');
        setCurrentRow(expense);
    };
    const contextMenu =  !isAdmin(role)?
        [
            {label: t("pay_slip_title_text"), eventKey: 'paie'},
            {label: t("expense_account_title_text"), eventKey: 'depense'}
        ]
        : [
        {label: t("pay_slip_title_text"), eventKey: 'paie'},
        {label: t("expense_account_title_text"), eventKey: 'depense'},
        {label: t("type_expense_title_text"), eventKey: 'typedepense'},
        // {label: 'Paramètres', eventKey: 'settings'},
    ];
    const pColumns = [
        {
            name:t("name_and_profession"),
            selector:'firstname',
            cell: row => {
                return <div className={'d-flex align-items-center pt-3 mb-3'}>
                    <div className={'mr-3'}>
                        <img src={mSVG} style={{width:38,height:38}}/>
                    </div>
                    <div>
                        <div className={'colleagueName'}>{row.employee.firstname + ' ' + row.employee.lastname}</div>
                        <div style={{fontSize:"10px"}} className={'colleagueDetails'}>{row.position?.title}  {row.employee.role_info?.name}</div>
                    </div>
                </div>
            }
        },
        {
            name:t("department_title_unique"),
            selector:'department',
            cell:row => {
                return <div className={'colleagueInfo'}>{row.employee.department?.name}</div>;
            }
        },
        {
            name: t("date"),
            cell: row => {
                return <div className={'colleagueInfo'}> {getFormatedDate(row.date)} </div>
            }
        },
         {
            name:t("message"),
            cell: row => {
                return <div className={'colleagueInfo'}> {row.description} </div>
            }
        },
        {
            name: t("dowload"),
            cell: row => <div style={{width:'100%'}}><div className={'text-center'}><a href={getFileUrl(row.file)} target={'_blank'} className={"btn btn-light btn-sm"}><FI.FiDownload/></a></div></div>,
        }
    ];
    const dColumns = [
        {
            name: t("first_last_name"),
            selector:'firstname',
            cell: row => {
                return <div className={'d-flex align-items-center pt-3 mb-3'}>
                    <div className={'mr-3'}>
                        <img src={mSVG} style={{width:38,height:38}}/>
                    </div>
                    <div>
                        <div className={'colleagueName'}>{row.employee_info[0]?.firstname + ' ' + row.employee_info[0]?.lastname}</div>
                        <div style={{fontSize:"10px"}} className={'colleagueDetails'}>{row.employee_info[0]?.position?.title}  {row.employee_info[0]?.role_info?.name}</div>
                    </div>
                </div>
            }
        },
        {
            name: t("expense_date"),
            selector: 'employee_info',
            cell: row => <strong>{getFormatedDate(row.date_depense)}</strong>
        },
        {
            name: t("amount"),
            selector: 'hired_on',
            cell: row => <strong>{row.amount} XOF</strong>
        },
        {
            name: t("status"),
            cell: row => {
                if (row.status===0){
                    return  <div style={{borderImage:"linear-gradient(to right, #C7C7C7 0%, #C7C7C7 100%) 4",borderBottom:"solid 3px", width:56}}> </div>
                }
                else if (row.status===1) {
                    return  <div style={{borderImage:"linear-gradient(to right, #EFC300 65%, #C7C7C7 35%) 4",borderBottom:"solid 3px", width:56}}> </div>
                }
                else  if (row.status===2){
                    return  <div style={{borderImage:"linear-gradient(to right, #008000 45%, #C7C7C7 55%) 4",borderBottom:"solid 3px", width:56}}> </div>
                }
                else  if (row.status===3){
                    return  <div style={{borderImage:"linear-gradient(to right, #008000 100%, #008000 100%) 4",borderBottom:"solid 3px", width:56}}> </div>
                }
            },
        },
        {
            name: '',
            cell: row => <Button onClick={e=>history.push('/dash/payroll/expense/'+row._id)} className={"btn btn-light btn-sm text-uppercase"}>{t("see")}</Button>,
        }
    ];
    const empDColumns = [
        {
            name: t("name_and_profession"),
            selector: 'firstname',
            cell: row => {
                return <div className={'d-flex align-items-center pt-3 mb-3'}>
                    <div className={'mr-3'}>
                        <img src={mSVG} style={{width: 38, height: 38}}/>
                    </div>
                    <div>
                        <div>{row.employee_info[0]?.firstname + ' ' + row.employee_info[0]?.lastname}</div>
                    </div>
                </div>
            }
        },
        {
            name: t("date"),
            selector: 'employee_info',
            cell: row => <strong>{new Date(row.payment_date).toLocaleDateString()}</strong>
        },
        {
            name: t("amount"),
            selector: 'hired_on',
            cell: row => <strong>{row.amount} XOF</strong>
        },
        {
            name: t("status"),
            cell: row => <Badge variant={"warning"} className={'text-white'}>En attente</Badge>,
        },

        {

            cell: row => <Button onClick={()=>handleShowDetails(row)} style={{backgroundColor:'transparent', border: 'solid 1px #FF796B',borderRadius: 6, color:"#FF796B",padding:'5px 15px'}}>{t("see")}</Button>,
        },
        {

            cell: row => (
                <Row style={{width:"100%"}}>
                    <Button onClick={() => editExpense(row)} variant={"link"} size={"sm"}><BI.BiEdit size={22}/></Button>
                    <Button onClick={() => {setShow('confirm_expense') ;setCurrentRow(row)}} variant={"link"} size={"sm"}><BI.BiTrash size={22}/></Button>

                </Row>
            ),
            grow:0
        }
    ];
    const tdColumns = [

        {
            name: t("last_name"),
            selector: 'name',
            cell: row => <strong>{row.name} </strong>
        },
        {
            name: t("description"),
            selector: 'description',
            cell: row => <strong>{row.description} </strong>
        },
       
        {
            cell: row => <div style={{width:'100%'}}  className={'text-right'}>
                <Button onClick={() => updateExpenseType(row)} variant={"warning"}  style={{color:'#fff'}} className={'ml-2'} size={"sm"}><BI.BiPencil style={{color:'#fff'}}/> {t("update")}</Button>
                <Button  onClick={ ()=>deleteTypeExpense(row)}  variant={"danger"} style={{color:'#fff'}} className={'ml-2'} size={"sm"}><BI.BiTrash style={{color:'#fff'}}/> {t("delete")}</Button>
                </div>
        }
    ];

    const handleName = (e) => {
        setName(e.target.value)
        let data =handleValidation(t,{type:"text",name:'name','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };

    const handleDescriptionName = (e) => {
        setDescriptionName(e.target.value)
    };

    const resetExpenseType = () => {
            setName('')
            setDescriptionName('')
    }
    const requestDataBuilder = () => (
        {
            "name": name,
            "description": description,
            "sio_channel": ""
        }
    );
    const editExpenseType = () => {
        let type_data = requestDataBuilder();
        type_data['id'] = current_row._id;
        type_data['name'] = name;
        type_data['description'] = description;
        console.log('data', type_data);
        props.action_updateExpenseType(type_data);
        setShow(null);
        props.action_loading(true);
        setCurrentRow()
     };

    const createExpenseType= () => {
        let o = requestDataBuilder();
        props.action_createExpenseType(o);
        setShow(null);
        props.action_loading(true)
    };
    const proceed = () => {
        if(current_row && current_row._id) {
            editExpenseType()
        } else {
            createExpenseType()
        }
        resetExpenseType()
    };

    const updateExpenseType = row_data => {
        setCurrentRow(row_data);
        setName(row_data.name);
        setDescriptionName(row_data.description);
        setShow('typedepense')
        let data=  handleFinaleValidation(t,[
            {type:"text",name:'name','value':row_data.name,required:true},
            ])
                setFormError(data)
    };
    const deleteTypeExpense = row_data => {
       setCurrentRow(row_data);
        setShow('confirm')
    };
    const deleteExpenseType = () => {
        let data = requestDataBuilder();
        data['id'] = current_row._id;
        props.action_deleteExpenseType(data);
        console.log('data', data);
        setShow(null)
        props.action_loading(true);
        setCurrentRow()
    };

    return <div id={"Payroll"}>
        <Container fluid>
            <Row>
                <Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={!isAdmin(role)?'depense':'paie'}>
                        <div className="d-flex align-items-start justify-content-between">
                            <ContextMenu menu={contextMenu}/>
                        </div>
                        <div className="d-flex align-items-end justify-content-end">
                        </div>

                        <Tab.Content>
                            <Tab.Pane className={"lastRollL"} eventKey="paie">
                                <Card className={"p-4 border-0"} style={{borderRadius:10}}>
                                    <div className={"d-flex align-items-center justify-content-between"}>
                                        <div className={"tableLabel"}>Fiche de paie</div>
                                        <div></div>
                                        <div style={{marginLeft:"50%",marginTop:"0%"}} className="d-flex align-items-end justify-content-end">
                                            <Button className={'m-2'} size={"sm"} onClick={e => setShow('fiche_paid_create')}>
                                                <BsPlus /> {t("upload")}
                                            </Button>
                                        </div>
                                    </div>
                                    <div>
                                        <DataTable
                                        pagination
                                            columns={pColumns}
                                            data={props.rwState.payrolls}
                                            highlightOnHover
                                            pointerOnHover
                                            noDataComponent={t("no_paid_slip")}
                                            paginationComponentOptions={
                                                {
                                               rowsPerPageText: t("line_per_page"),
                                                rangeSeparatorText: t("for"),
                                                 noRowsPerPage: false,
                                                  selectAllRowsItem: false,
                                                   selectAllRowsItemText: t("all")} 
                                               }
                                        />
                                    </div>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane className={"lastRollL"} eventKey="depense">
                                <Card className={"p-4 border-0"} style={{borderRadius:10}}>
                                    <div className={"d-flex align-items-center justify-content-between"}>
                                        <div className={"tableLabel"}>{t("expense_account_title_text")}</div>
                                        <div></div>
                                        <div style={{marginLeft:"50%",marginTop:"0%"}} className="d-flex align-items-end justify-content-end">
                                            <Button className={'m-2'} size={"sm"} onClick={e => setShow('expense_modal')}>
                                                <BsPlus /> {t("submit_an_expense")}
                                            </Button>
                                        </div>
                                    </div>
                                    <div style={{background:'#f4f4f4'}} className={"col-12 pt-2"}>
                                       <h6 onClick={handleLegende} style={{cursor:'pointer'}}>{t("expnse_status_legend")}
                                           {!legende ? <BI.BiChevronDown  size={22}  />
                                               : <BI.BiChevronUp size={22} />}
                                       </h6>
                                        {legende ?
                                        <Row className={"mt-2 mb-3"} >
                                            <Col xs={12} className={"mt-2"}>
                                                <div style={{display:'inline-block', borderImage:"linear-gradient(to right, #C7C7C7 0%, #C7C7C7 100%) 4",borderBottom:"solid 3px", width:56}}> </div>
                                              &nbsp;&nbsp;&nbsp;&nbsp;  {t("new_expense")}
                                            </Col>
                                            <Col xs={12} className={"mt-2"}>
                                                <div style={{display:'inline-block',borderImage:"linear-gradient(to right, #EFC300 65%, #C7C7C7 35%) 4",borderBottom:"solid 3px", width:56}}> </div>
                                                &nbsp;&nbsp;&nbsp;&nbsp; {t("expense_validated")}
                                            </Col>
                                            <Col xs={12} className={"mt-2"}>
                                                <div style={{display:'inline-block',borderImage:"linear-gradient(to right, #008000 45%, #C7C7C7 55%) 4",borderBottom:"solid 3px", width:56}}> </div>
                                                &nbsp;&nbsp;&nbsp;&nbsp; {t("expense_approuved")}
                                            </Col>
                                            <Col xs={12} className={"mt-2"}>
                                                <div style={{display:'inline-block',borderImage:"linear-gradient(to right, #008000 100%, #008000 100%) 4",borderBottom:"solid 3px", width:56}}> </div>
                                                &nbsp;&nbsp;&nbsp;&nbsp; <span className={"mt-2"}> {t("last_approbation")} </span>
                                            </Col>
                                        </Row>
                                            : ""}
                                    </div>
                                    <div>
                                        <DataTable
                                        pagination
                                            columns={!isAdmin(role)?empDColumns:dColumns}
                                            data={props.rwState.expenses}
                                            highlightOnHover
                                            pointerOnHover
                                            noDataComponent={t("no_expense_approved")}
                                        />
                                    </div>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane className={"lastRollL"} eventKey="typedepense">
                                <Card className={"p-4 border-0"} style={{borderRadius:10}}>
                                    <div className={"d-flex align-items-center justify-content-between"}>
                                        <div className={"tableLabel"}>{t("expense_type")}</div>
                                        <div></div>
                                        <div style={{marginLeft:"50%",marginTop:"0%"}} className="d-flex align-items-end justify-content-end">
                                            <Button className={'m-2'} size={"sm"} onClick={e => setShow('typedepense')}>
                                                <BsPlus /> {t("expense_type")}
                                            </Button>
                                        </div>
                                    </div>
                                    <div>
                                        <DataTable
                                        pagination
                                            columns={tdColumns}
                                            data={props.rwState.expensetypes}
                                            highlightOnHover
                                            pointerOnHover
                                            noDataComponent={t("no_expense_type_founded")}
                                        />
                                    </div>
                                </Card>
                            </Tab.Pane>
                            {/*<Tab.Pane className={"lastRollL"} eventKey="settings">*/}
                            {/*    <Card className={"p-4 border-0"} style={{borderRadius:10}}>*/}
                            {/*        <div className={"d-flex align-items-center justify-content-between"}>*/}
                            {/*            <div className={"tableLabel"}>Liste des configurations des primes et des taxes</div>*/}
                            {/*            <div></div>*/}
                            {/*        </div>*/}
                            {/*        <div>*/}
                            {/*            <div style={{marginTop:"24px"}}>*/}
                            {/*                <Col sm={"auto"}><Setting/></Col>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </Card>*/}
                            {/*</Tab.Pane>*/}
                        </Tab.Content>
                    </Tab.Container>
                </Col>
            </Row>

        </Container>

        <Modal centered show={show === 'fiche_paid_create'} onHide={handleClose}>
            <Modal.Header className={"bg-primary"}>
                <h4 className={"col-lg-12 text-white text-center"}>{t("upload_paid_slip")}</h4>
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <Form.Group>
                    <Row>
                        <Col>
                        <Form.Label>{t("choose_an_employee")}  <span className={'required_box'}>*</span></Form.Label>
                           
                            <Select noOptionsMessage={() => t("no_data_available")}
                                options={props.rwState.colleagues.filter(r=>r.activated)}
                                getOptionLabel={o => o.firstname+' '+o.lastname}
                                getOptionValue={o => o._id}
                                styles={{
                                    control: base => ({
                                        ...base,
                                        height: 48,
                                        minHeight: 48,
                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                defaultValue={type}
                                placeholder={t("choose_an_employee")}
                                onChange={handlePayrollEmployee}
                                className={handleinputValidationError(formError,'payrollEmployee')}
                                />
                                       {handleTextValidationError(formError,'payrollEmployee')}
                      
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Label>{t("date")}  <span className={'required_box'}>*</span></Form.Label>
                            <Form.Control
                                onChange={handlePayrollDate}
                                type={"date"}
                                className={handleinputValidationError(formError,'payrollDate')}
                                />
                                       {handleTextValidationError(formError,'payrollDate')}
                      
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Label>{t("join_paid_slip")}  <span className={'required_box'}>*</span></Form.Label>
                            <Form.File id="formcheck-api-custom" custom>
                                <Form.File.Input onChange={handlePayrollFile} required 
                                className={handleinputValidationError(formError,'payrollfile')}
                                />
                                <Form.File.Label data-browse={t("choose_a_file")}>
                                    {payrollFileName}
                                </Form.File.Label>
                                <Form.Control.Feedback type="valid">5MB</Form.Control.Feedback>
                            </Form.File>
                               {handleTextValidationError(formError,'payrollfile')}
                      
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Label>{t("description")}</Form.Label>
                            <Form.Control
                                onChange={handlePayrollDescription}
                                as={"textarea"}/>
                        </Col>
                    </Row>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("cancel")}
                </Button>
                <Button variant="primary"
                 disabled={
                    handleDisabledBtnValidationError(formError,
                    ['payrollfile','payrollEmployee','payrollDate'])
                }
                onClick={createPayroll}>
                    {t("save")}
                </Button>
            </Modal.Footer>
        </Modal>


        {/*  modals */}
        <div>
            <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
                <Modal.Header closeButton className={'border-0'}>
                    <Modal.Title>{t("confirmation")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"text-center"}>
                        {t("confirme_irreversile_deletion_text")}
                    </div>
                </Modal.Body>
                <Modal.Footer className={'text-center border-0'}>
                    <Button variant="primary" onClick={deleteExpenseType}>
                        {t("yes")}
                    </Button>
                    <Button variant="light" onClick={() => setShow(null)}>
                        {t("no")}
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    <div>
        <Modal show={show==="typedepense"} onHide={() => setShow(null)} centered size={"md"}>
            <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
                {
                    current_row ? (<Modal.Title className='w-100'>{t("change_expense_type")}</Modal.Title>) : (<Modal.Title className='w-100'>Ajout d'un nouveau type de depense</Modal.Title>)
                }
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <Form.Group>
                    <Form.Label>Libellé <span className={'required_box'}>*</span></Form.Label>
                    <Form.Control
                        size={"lg"}
                        placeholder={t("last_name")}
                        defaultValue={current_row?.name}
                        onChange={handleName}
                        className={handleinputValidationError(formError,'name')}
                        />
                               {handleTextValidationError(formError,'name')}
              
                </Form.Group>
                <Form.Group>
                <Form.Label>{t("description")} <span className={'required_box'}>*</span></Form.Label>
                     <Form.Control
                        size={"lg"}
                        placeholder={t("description")}
                        onChange={handleDescriptionName}
                        defaultValue={current_row?.description}
                    />
                </Form.Group>
                <Row>

                </Row>


            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("cancel")}
                </Button>
                <Button variant="primary"
                  disabled={
                    handleDisabledBtnValidationError(formError,
                    ['name'])
                }
                onClick={proceed}>
                    {t("save")}
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
        <Modal centered show={show === 'expense_modal'} onHide={handleClose}>
            <Modal.Header className={"bg-primary"}>
                <h4 className={"col-lg-12 text-white text-center"}>{t("submit_an_expense")}</h4>
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <Form.Group>
                     <Row>
                    <Col>
                    <Form.Label>{t("choose_expense_type")}  <span className={'required_box'}>*</span></Form.Label>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={props.rwState.expensetypes}
                            getOptionLabel={o => o.name}
                            getOptionValue={o => o._id}
                            styles={{
                                control: base => ({
                                    ...base,
                                    height: 48,
                                    minHeight: 48,
                                    // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            defaultValue={type}
                            placeholder={'Type de la dépense'}
                            onChange={handleTypeChange}
                            className={handleinputValidationError(formError,'typeChange')}
                            />
                                   {handleTextValidationError(formError,'typeChange')}
                  
                    </Col>
                </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                    <Col>
                        <Form.Label>{t("date_of_expense")}  <span className={'required_box'}>*</span></Form.Label>
                        <DayPickerInput
                            inputProps={{className: 'form-control  all'}}
                            format="DD-MM-YYYY HH:MM:SS"
                            formatDate={formatDate}
                            value={expenseDate}
                            parseDate={parseDate}
                            onDayChange={handleExpenseDate}
                            className={handleinputValidationError(formError,'expenseDate')}
                            />
                                   {handleTextValidationError(formError,'expenseDate')}
                  
                    </Col>
                    <Col>
                        <Form.Label>{t("amount_of_expense")}  <span className={'required_box'}>*</span></Form.Label>
                        <Form.Control
                            onChange={handleAmount}
                            defaultValue={amount}
                            type={"number"}
                            className={handleinputValidationError(formError,'amount')}
                            />
                                   {handleTextValidationError(formError,'amount')}
                  
                    </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>{t("join_a_file")}  <span className={'required_box'}>*</span></Form.Label>
                            <Form.File id="formcheck-api-custom" custom>
                                <Form.File.Input onChange={handleFile} 
                                className={handleinputValidationError(formError,'expenseFile')}
                                />
                                <Form.File.Label data-browse={t("choose_a_file")}>
                                    {expenseFileName}
                                </Form.File.Label>
                                <Form.Control.Feedback type="valid">5MB</Form.Control.Feedback>
                            </Form.File>
                            
                            {handleTextValidationError(formError,'expenseFile')}
              </Col>
                    </Row>
                </Form.Group>

                    <Form.Group>
                        <Row>
                        <Col>
                            <Form.Label>{t("expense_description")}  <span className={'required_box'}>*</span></Form.Label>
                            <Form.Control
                                onChange={handleComment}
                                defaultValue={comment}
                                as={"textarea"} 
                                className={handleinputValidationError(formError,'expenseComment')}
                                />
                                       {handleTextValidationError(formError,'expenseComment')}
                      
                        </Col>
                        </Row>
                    </Form.Group>
            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("cancel")}
                </Button>
                <Button variant="primary"
                 disabled={
                    handleDisabledBtnValidationError(formError,
                        ['expenseComment','expenseFile','typeChange','amount','expenseDate'])
                }
                onClick={createExpense}>
                    {t("save")}
                </Button>
            </Modal.Footer>
        </Modal>

        {
            current_row && current_row.expensetype_info &&
            <Modal  centered show={show === 'show_detail'} onHide={handleClose}>
                <Modal.Header className={"bg-primary"}>

                    <h4 className={"col-lg-12 text-white text-center"}> {t("expense_details")}</h4>
                </Modal.Header>
                <Modal.Body  className={'p-4'}>
                    <Row className={""}>
                            {t("date_of_expense")} :
                            <Form.Control disabled={true}
                                          onChange={handleComment}
                                          defaultValue={ new Date(current_row.payment_date).toLocaleDateString()}
                            />


                    </Row>
                    <Row  className={"mt-4"}>
                    {t("amount_of_expense")} :
                        <Form.Control disabled={true}
                                      onChange={handleAmount}
                                      defaultValue={current_row.amount}
                        />
                    </Row>
                    <Row  className={"mt-4"}>
                        {t("description")} :
                        <Form.Control disabled={true}
                            onChange={handleComment}
                            defaultValue={current_row.comments}
                            as={"textarea"}/>
                    </Row>

                </Modal.Body>
                <Modal.Footer hidden={userIsEmployee()} className={'text-center border-0 pt-0'}>
                    {current_row.status===1 &&
                    <Button  variant="primary" onClick={approbateExpense} style={{borderRadius:0,width:"80%",margin:"auto"}}>
                        {t("validate_expense")}
                    </Button>
                    }
                </Modal.Footer>
            </Modal>
        }
        <Modal show={show==="confirm_expense"} onHide={() => setShow(null)} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("confirmation")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={"text-center"}>
                    {t("confirme_irreversile_deletion_text")}
                </div>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>
                        <Button variant="primary" onClick={deleteExpense}>
                            {t("yes_delete")}
                        </Button>
                        <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                            {t("cancel")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>



    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Payroll);
