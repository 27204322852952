import React, {useEffect, useRef, useState} from 'react';
import "./Parametres.css"
import {Button, Col, Container, Form, Row, Tab} from "react-bootstrap";
import ContextMenu from "../../menus/ContextMenu";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import Select from "react-select";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const Parametres = (props) => {
    const { t } = useTranslation();
    useEffect(() => {
        props.action_listParameters();
        props.action_listColleagues();
        props.action_setTitle(t("menu.settings"))
    }, []);
  
    const editorInit={
        height: 300,
        menubar: false,
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
    };
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    let user = JSON.parse(window.localStorage.getItem('glowhr_user')).user;
    const [show, setShow] = useState([])
    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const [current_row, setCurrentRow] = useState()
    const [eventData, setEventData] = useState([])
    const [content, setContent] = useState("<p>"+t("enter_the_content")+"p>")
    const [contentCandidatureAccepted, setContentCandidatureAccepted] = useState("<p>"+t("enter_the_content")+"</p>")
    const [contentCandidatureRefused, setContentCandidatureRefused] = useState("<p>"+t("enter_the_content")+"</p>")
    const editorRef = useRef(null);
    const editorRef2 = useRef(null);
    const [attendees_target_id, setAttendeesTargetId] = useState([])

    const [isUpdate, setHasUpdate] = useState(false)
    const editParameters = (formation)=>{
        setCurrentRow(formation);
        setName(formation.name);
        setDescription(formation.description);
        setHasUpdate(true);
        setShow('formation_modal')
    };
    const resetParameters = ()=>{
        setCurrentRow({});
        setName("");
        setDescription("");
        setHasUpdate(false);
        setShow(null)
    };
    const createParameters = () => {
        let o = {
            "name": name,
            "description": description,
            "sio_channel": "",
            "id":current_row?.id
        };
            props.action_updateParameters(o)
       };

    const handleShowDetails = (expense)=>{
        setShow('show_detail');
        setCurrentRow(expense);
    }
    useEffect(() => {
        if(props.rwState.parameters && getParameterValue(props.rwState.parameters,'candidature-accept','recruitment'))
        setContentCandidatureAccepted( getParameterValue(props.rwState.parameters,'candidature-accept','recruitment'));
       if(props.rwState.parameters && getParameterValue(props.rwState.parameters,'candidature-reject','recruitment'))
       setContentCandidatureRefused( getParameterValue(props.rwState.parameters,'candidature-reject','recruitment'));
    
     }, [props.rwState.parameters]);
 const contextMenuAdmin =
        [
            {label: t("events_title"), eventKey: 'events'},
             {label: t("recruitments_title"), eventKey: 'recruitment'},
        /*    {label: 'Collègues', eventKey: 'employee'},
            {label: 'Depart', eventKey: 'depart'},
            {label: 'Sondage', eventKey: 'sondage'},
            {label: 'Notification', eventKey: 'notification'}, */
        ]

    const handleName = (e) => {
        setName(e.target.value)
    }

    const handleDescriptionName = (e) => {
        setDescription(e.target.value)
    }

   const handleEventParametersChange = (code,target,name,e) => {
       let type_data = {},data=e.target ? e.target.value : e;
       type_data['code'] = code
       type_data['name'] = name
       type_data['target'] = target
       type_data['value'] = data
     if(eventData.length>0) {
         let findEvent =  eventData.find(res => res.code === code && res.target === target)
         let newEventData =eventData;
         if (findEvent)  newEventData.splice(newEventData.indexOf(findEvent),1);
         if(data!=="") newEventData.push(type_data);
        setEventData(newEventData)
     }else{
         setEventData([type_data])
     }
     console.log(eventData)
   }

    const getParameterValue = (data,code,target) => {
        return data.find(res => res.code === code && res.target === target)?.value;
    }
    const updateParameters = (code,target,name,e) => {
        let data={
            parameters:eventData
        }
        props.action_updateParameters(data);
        props.action_loading(true)
    }
    const handleEditorChange = (value, editor) => {
        setContent(value)
    };
    const handleSelectAssign = (e) => {
        let data=[];
        if(e && e.length > 1) {
            data=e.map(res => {
                return res._id
            })
            setAttendeesTargetId(data)
        }else{
            setAttendeesTargetId([])
        }
        handleEventParametersChange('notificate-rh','recruitment',t("message_for_successfull_candidat"),JSON.stringify(data))
    };
    const handleCandidatureAcceptedEditorChange = (value, editor) => {
        setContentCandidatureAccepted(value)
        handleEventParametersChange('candidature-accept','recruitment',t("message_for_successfull_candidat"),value)
    };
  const handleCandidatureRefusedEditorChange = (value, editor) => {
        setContentCandidatureRefused(value)
        handleEventParametersChange('candidature-reject','recruitment',t("message_for_reject_candidat"),value)
    };
    return <div id={"Parameters"}>
        <Container fluid>
            <Row>
                <Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={'events'}>
                        <div className="d-flex align-items-start justify-content-between">
                            <ContextMenu menu={contextMenuAdmin}/>
                        </div>
                        { props.rwState.parameters ?
                            <Tab.Content>
                            <Tab.Pane className={"lastRollL"} eventKey="events">
                                <div className={"p-3 border-0"} style={{borderRadius:10}}>

                                  <Row>
                                          <Col sm={8}>
                                          <Row>
                                              <Col sm={9} className={"mt-2"}>
                                                  <h6>{t("time_to_general_recall")} </h6>
                                              </Col>
                                              <Col sm={3}>
                                              <Form.Group>
                                              <Form.Control
                                                  size={"sm"}
                                                  as={"input"}
                                                  type={"number"}
                                                  placeholder={"valeur"}
                                                  onChange={(e)=>{handleEventParametersChange('trg','event',t("time_to_general_recall_target"),e)}}
                                                  defaultValue={props.rwState.parameters ? getParameterValue(props.rwState.parameters,'trg','event') :""}
                                              />
                                          </Form.Group>
                                              </Col>

                                          </Row>
                                              <Row>
                                                  <Col sm={9} className={"mt-2"}>
                                                      <h6>{t("total_max_recall")} </h6>
                                                  </Col>
                                                  <Col sm={3}>
                                                      <Form.Group>
                                                          <Form.Control
                                                              size={"sm"}
                                                              as={"input"}
                                                              type={"number"}
                                                              placeholder={"valeur"}
                                                              onChange={(e)=>{handleEventParametersChange('nr','event',t("total_max_recall_target"),e)}}
                                                              defaultValue={props.rwState.parameters ?
                                                                 getParameterValue(props.rwState.parameters,'nr','event') :""}
                                                          />
                                                      </Form.Group>
                                                  </Col>

                                              </Row>

                                              <Row>
                                              <Col sm={9} className={"mt-2"}>
                                                  <h6>{t("interval_time_total_recall")} </h6>
                                              </Col>
                                              <Col sm={3}>
                                              <Form.Group>
                                              <Form.Control
                                                  size={"sm"}
                                                  as={"input"}
                                                  type={"number"}
                                                  placeholder={"valeur"}
                                                  onChange={(e)=>{handleEventParametersChange('itr','event',t("interval_time_total_recall_target"),e)}}
                                                  defaultValue={props.rwState.parameters ? getParameterValue(props.rwState.parameters,'itr','event') :""}
                                              />
                                          </Form.Group>
                                              </Col>

                                          </Row>
                                          <Row>
                                              <Col sm={9} className={"mt-2"}>
                                                  <h6> {t("birthday_recall_target")} </h6>
                                              </Col>
                                              <Col sm={3}>
                                              <Form.Group>
                                              <Form.Control
                                                  size={"sm"}
                                                  as={"input"}
                                                  type={"number"}
                                                  placeholder={"valeur"}
                                                  onChange={(e)=>{handleEventParametersChange('tra','event',t("birthday_total_recall_target"),e)}}
                                                  defaultValue={props.rwState.parameters ? getParameterValue(props.rwState.parameters,'tra','event') :""}
                                              />
                                          </Form.Group>
                                              </Col>

                                          </Row>
                                          <Row>
                                              <Col sm={9} className={"mt-2"}>
                                                  <h6> {t("formation_total_recall")} <br/> <span style={{color: 'yellowgreen'}}> {t("formation_total_recall2")}</span> </h6>
                                              </Col>
                                              <Col sm={3}>
                                              <Form.Group>
                                              <Form.Control
                                                  size={"sm"}
                                                  as={"input"}
                                                  type={"number"}
                                                  placeholder={"valeur"}
                                                  onChange={(e)=>{handleEventParametersChange('trf','event',t("formation_total_recall_target"),e)}}
                                                  defaultValue={props.rwState.parameters ? getParameterValue(props.rwState.parameters,'trf','event') :""}
                                              />
                                          </Form.Group>
                                              </Col>

                                          </Row>
                                          <Row>
                                              <Col sm={9} className={"mt-2"}>
                                                  <h6>{t("meet_recall")} </h6>
                                              </Col>
                                              <Col sm={3}>
                                              <Form.Group>
                                              <Form.Control
                                                  size={"sm"}
                                                  as={"input"}
                                                  type={"number"}
                                                  placeholder={"valeur"}
                                                  onChange={(e)=>{handleEventParametersChange('trm','event',t("meet_recall_target"),e)}}
                                                  defaultValue={props.rwState.parameters ? getParameterValue(props.rwState.parameters,'trm','event') :""}
                                              />
                                          </Form.Group>
                                              </Col>

                                          </Row>
                                        
                                          <Button type="submit" className={"float-right mt-4"} onClick={()=>{updateParameters('code','target','name')}}>{t("save")}</Button>
                                      </Col>
                                  </Row>
                                </div>
                            </Tab.Pane>
                             <Tab.Pane className={"lastRollL"} eventKey="recruitment">
                                <div className={"p-3 border-0"} style={{borderRadius:10}}>

                                  <Row>
                                    <Col sm={12} className={""}>
                                        <Col sm={12} className={"mb-2"}>
                                                  <h6>{t("rh_receiving_position")}.</h6>
                                        </Col>
                                        <Col sm={6} className={"mt-2"}>
                                            <Form.Group>
                                                <Select noOptionsMessage={() => t("no_data_available")}
                                                    options={props.rwState.colleagues.filter(res=> res.role_info && (res.role_info?.name==="RH" || res.role_info?.name==="Administrator"))}
                                                    getOptionLabel={o => o.lastname+' '+o.firstname}
                                                    getOptionValue={o => o._id}
                                                    defaultValue={
                                                        props.rwState.parameters && getParameterValue(props.rwState.parameters,'notificate-rh','recruitment')  ?
                                                        props.rwState.colleagues.map(re=>{
                                                             if(JSON.parse(getParameterValue(props.rwState.parameters,'notificate-rh','recruitment')).indexOf(re._id)>=0) {
                                                                
                                                                return re;}
                                                            
                                                            })
                                                        : []
                                                    }
                                                    styles={{
                                                        control: base => ({
                                                            ...base,
                                                            minHeight: 48,
                                                        })
                                                    }}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: '#6600CC',
                                                            primary25: '#6600CC25',
                                                            primary75: '#6600CC75',
                                                            primary50: '#6600CC50'
                                                        },
                                                    })}
                                                    placeholder={'RH - Administrator'}
                                                    isMulti
                                                    onChange={handleSelectAssign}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Col>
                                    <Col sm={12} className={"mt-2"}>
                                        <Col sm={12} className={"mt-2"}>
                                                  <h6>{t("usage_text1")}</h6>
                                                  <code>$nom : {t("usage_text2")}</code> &nbsp; &nbsp; &nbsp;
                                                  <code>$prenom : {t("usage_text3")}</code>  &nbsp; &nbsp; &nbsp;
                                                  <code>$poste : {t("usage_text4")}</code>  &nbsp; &nbsp; &nbsp;
                                                  <code>$email :{t("usage_text5")}</code>  &nbsp; &nbsp; &nbsp;
                                                  <code>$motif : {t("usage_text7")}</code>  &nbsp; &nbsp; &nbsp;
                                                  <code>$new-account : {t("usage_text8")}</code>  &nbsp; &nbsp; &nbsp;
                                        </Col>
                                    </Col>
                                          <Col sm={6} className={"mt-3"}>
                                       
                                                 <Col sm={12} className={"mt-2 mb-2"}>
                                                  <h6>{t("message_to_sent_to_candidat")} </h6>
                                                </Col>
                                                 <Col sm={12}>
                                                        <Form.Group>
                                                        <Editor
                                                            onInit={(evt, editor) => editorRef.current = editor}
                                                            value={contentCandidatureAccepted}
                                                            onEditorChange={handleCandidatureAcceptedEditorChange}
                                                            init={editorInit}/>
                                                    </Form.Group>
                                                 </Col>
                                              </Col>
                                              <Col sm={6} className={"mt-3"}>
                                                    <Col sm={12} className={"mt-2 mb-2"}>
                                                        <h6>{t("message_to_sent_to_reject_candidat")}</h6>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <Form.Group>
                                                        <Editor
                                                            onInit={(evt, editor) => editorRef2.current = editor}
                                                            value={contentCandidatureRefused}
                                                            onEditorChange={handleCandidatureRefusedEditorChange}
                                                            init={editorInit}/>
                                                          </Form.Group>
                                                    </Col>
                                              </Col>
                                              <Col sm={12} className={"mt-2 text-right"}>
                                              <Button type="submit" className={"float-right mt-4"} onClick={()=>{updateParameters('code','target','name')}}>{t("save")}</Button>
                                                    </Col>
                                  </Row>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className={"lastRollL"} eventKey="formation">
                                <div className={"p-3 border-0"} style={{borderRadius:10}}>
                                   {t("formation_parameter_title")}
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className={"lastRollL"} eventKey="employee">
                                <div className={"p-3 border-0"} style={{borderRadius:10}}>
                                    {t("employee_parameter_title")}
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className={"lastRollL"} eventKey="depart">
                                <div className={"p-3 border-0"} style={{borderRadius:10}}>
                                    {t("departure_parameter_title")}
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className={"lastRollL"} eventKey="sondage">
                                <div className={"p-3 border-0"} style={{borderRadius:10}}>
                                    {t("survey_parameter_title")}
                                </div>
                            </Tab.Pane>

                            <Tab.Pane className={"lastRollL"} eventKey="notification">
                                <div className={"p-3 border-0"} style={{borderRadius:10}}>
                                    {t("notification_parameter_title")}
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                            :
                            <Col sm={12} className={"text-center mt-5 pt-5"}>
                                <img src={"../../../loader.gif"} width={"100px"}/>
                            </Col>
                            }
                    </Tab.Container>
                </Col>
            </Row>

        </Container>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Parametres);
