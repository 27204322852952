import React, {useEffect, useState} from 'react';
import "./Suggestions.css"
import {Accordion, Card, Col, Container, Row, Tab, Form, Button,Modal} from "react-bootstrap";
import ContextMenu from "../../menus/ContextMenu";
import * as MD from "react-icons/md"
import * as BS from "react-icons/bs"
import * as BI from "react-icons/bi"
import {connect} from "react-redux";
import * as actions from "../../../actions";
import * as moment from "moment";
import mSVG from "../../../assets/gender/m.png"
import Select from "react-select";
import {isAdmin, limitText,
    handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import {MdGroupWork} from "react-icons/md";

const mapStateToProps = (state) => {
  return {rwState: state}
}
const mapDispatchToProps = {...actions}

function Items({ currentItems }) {
    return (
        <>
            {currentItems &&
            currentItems.map((item) => (
                <div>
                    <h3>Item #{item}</h3>
                </div>
            ))}
        </>
    );
}

function PaginatedItems({ itemsPerPage }) {

    return (
        <>
            <Items currentItems={currentItems} />
            <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
            />
        </>
    );
}

const Suggestions = (props) => {
    const { t } = useTranslation();
    useEffect(()=>{
        props.action_listSuggestions();
        props.action_listColleagues();
        props.action_setTitle(t("suggestions"))
    },[]);
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    let user = JSON.parse(window.localStorage.getItem('glowhr_user')).user;
    const contextMenu = !isAdmin(role)?
      [ {label: t("write_a_suggestion"), eventKey: 'write'},
        {label: t("suggestions"), eventKey: 'list'},
         ]
      :[
    {label: t("write_a_suggestion"), eventKey: 'write'},
    {label: t("ask_for_suggestion"), eventKey: 'dvalidate'},
    {label: t("suggestions"), eventKey: 'list'},
    {label: t("my_suggestion"), eventKey: 'list-anonyms'},
    ];
  const [anon,setAnon] = useState(1)
  const [text,setText] = useState()
  const [show, setShow] = useState()
  const [activeTab,setActiveTab] = useState('list')
  const [employee, setEmployee] = useState()
  const [current_row, setCurrentRow] = useState()
  const [comments, setComments] = useState()
    const [assignTo, setIsAssignTo] = useState('tous')
    // We start with an empty list of items.
     //my suggestion
    const [currentmySuggestionsLists, setCurrentmySuggestionsLists] = useState(undefined);
    const [pageMySuggestionsCount, setMySuggestionsPageCount] = useState(0);
    const [itemMySuggestionsOffset, setMySuggestionsItemOffset] = useState(0);
    const [itemsMySuggestionsPerPage, setMySuggestionsItemsPerPage] = useState(8);
    //list suggestion
    const [currentLists, setCurrentLists] = useState(undefined);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(8);
    //validation suggestion
    const [currentSuggestionToValideLists, setCurrentSuggestionToValideLists] = useState(undefined);
    const [SuggestionToValidepageCount, setSuggestionToValidePageCount] = useState(0);
    const [itemSuggestionToValideOffset, setItemSuggestionToValideOffset] = useState(0);
    const [itemsSuggestionToValidePerPage, setItemsSuggestionToValidePerPage] = useState(8);
    const [formError, setFormError] = useState({})

    const [list, setList] = useState(undefined);
    const [mySuggestions, setMySuggestions] = useState(undefined);
    const [suggestionToValide, setSuggestionToValide] = useState(undefined);

    useEffect(() => {
        setList([]);
        setMySuggestions([]);
        setSuggestionToValide([]);
     if(props.rwState.suggestions && props.rwState.suggestions.length>0) {

         //set list data
         let mylist = props.rwState.suggestions?.filter(res =>
             res.employee.find(r=>r.user=== user._id)
                 || 
             res.valide
                 
            )
         setList(mylist)

         //set my suggestions data
         let mysuggest=props.rwState.suggestions?.filter(res=>( res.created_by && res.created_by._id===user._id));
         setMySuggestions(mysuggest);

         // set suggestion to valide
         let suggestionValidat=props.rwState.suggestions?.filter(res=>
           (res.anonymous || (!res.anonymous && res.assignTo==='tous')) && !res.valide);
         setSuggestionToValide(suggestionValidat);
     }
    }, [props.rwState.suggestions]);

    // ****************************************** begin suggestion to validate methods********************************************

    useEffect(() => {
     if(suggestionToValide ) {
         const endOffset = itemSuggestionToValideOffset + itemsSuggestionToValidePerPage;
         console.log(`Loading items from ${itemSuggestionToValideOffset} to ${endOffset}`);
         setCurrentSuggestionToValideLists(suggestionToValide.slice(itemSuggestionToValideOffset, endOffset));
         setSuggestionToValidePageCount(Math.ceil(suggestionToValide.length / itemsSuggestionToValidePerPage));
     }
    }, [itemSuggestionToValideOffset, itemsSuggestionToValidePerPage, suggestionToValide]);

    const handleSuggestionToValidePageClick = (event) => {
        const newOffset = (event.selected * itemsSuggestionToValidePerPage) % list.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemSuggestionToValideOffset(newOffset);
    };
    // ****************************************** end suggestion to validate methods********************************************

      useEffect(() => {
     if(list) {
         const endOffset = itemOffset + itemsPerPage;
         console.log(`Loading items from ${itemOffset} to ${endOffset}`);
         setCurrentLists(list.slice(itemOffset, endOffset));
         setPageCount(Math.ceil(list.length / itemsPerPage));
     }
    }, [itemOffset, itemsPerPage, list]);

    useEffect(() => {
     if(mySuggestions ) {
         const endOffset = itemMySuggestionsOffset + itemsMySuggestionsPerPage;
         console.log(`Loading items from ${itemMySuggestionsOffset} to ${endOffset}`);
         setCurrentmySuggestionsLists(mySuggestions.slice(itemMySuggestionsOffset, endOffset));
         setMySuggestionsPageCount(Math.ceil(mySuggestions.length / itemsMySuggestionsPerPage));
     }
    }, [itemMySuggestionsOffset, itemsMySuggestionsPerPage, mySuggestions]);

    // Invoke when user click to request another page.

 const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % list.length;
        setItemOffset(newOffset);
    };
const handleMySuggestionsPageClick = (event) => {
        const newOffset = (event.selected * itemsMySuggestionsPerPage) % mySuggestions.length;
    setMySuggestionsItemOffset(newOffset);
    };

  const handleSuggText = (e) =>{
    setText(e.target.value)
    let data =handleValidation(t,{type:"textarea",name:'suggestionText','value':e.target.value,required:true});
    let form=formError;
            form[data.name]=data   
            setFormError(form)
  }


    const handleClose = () => {
        setShow(null)
        setFormError({})
    }
    const handleAssignTo = (e) => {
        setIsAssignTo(e);
    }
    const resetSuggestion = (e) => {
        setText('')
        setAnon(1)
        setIsAssignTo('tous')
        setEmployee('');
    }

    const sendSuggestion = () => {
    let o = {
      "suggestion": text,
      "anonymous": anon,
      "assignTo": assignTo,
    }
    if(assignTo==='employee'){
       o.employee=employee?._id;
    }
    // console.log(o,'*********** o datas')
    props.action_makeSuggestion(o)
        setActiveTab('list');
        props.action_loading(true)
        resetSuggestion();
     }
    const commentSuggestion = () => {
    let o = {
        "comment": text,
        "anonymous": anon,
        "suggestion_id":current_row._id
    };
    props.action_commentSuggestion(o)
        props.action_loading(true);
   // setComments([current_row.comments,...[o]])
        setShow(null);
     }
    const handleSelectEmployee = e => {
        setEmployee(e)
        let data =handleValidation(t,{type:"text",name:'employee','value':e._id,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }

    const assignToEmp = () => {
        let o = {
            "employee": employee._id,
            id: current_row._id
        };
        props.action_assignSuggestion(o);
        props.action_loading(true);
    }
     const validateSuggestion = () => {
        let o = {
            "valide": true,
            "validateBy": user._id,
            id: current_row._id
        };
        props.action_updateSuggestion(o);
        props.action_loading(true);
         setShow(null);
     }
    const setAssignTo=(data)=>{
      setCurrentRow(data)
        setShow('assign')
    }
    const setHaveToBeValidate=(data)=>{
      setCurrentRow(data)
        setShow('confirmValidate')
    }
    const deleteSuggestion = () => {
      props.action_deleteSuggestion(current_row._id);
      props.action_loading(true);
      setShow(null);
      setCurrentRow(null);
    }
    const deleteSuggestionComment = (id) => {
      props.action_deleteSuggestionComment(id);
      props.action_loading(true);
      setShow(null);
    }
    const showComments = (suggestion)=>{
      setCurrentRow(suggestion);
      setComments(suggestion.comments);
      setShow('comments')
    };

  return <div id={"Suggestions"}>
    <Container fluid>
      <Tab.Container id="left-tabs-example" onSelect={(k) => setActiveTab(k)} activeKey={activeTab} defaultActiveKey={activeTab}>
          <ContextMenu menu={contextMenu}/>
        <Tab.Content>
          <Tab.Pane eventKey="dvalidate">
            <div className={"pt-1"}>
              <Accordion>
                      <Card  hidden={currentSuggestionToValideLists?.length>0}>
                          <Card.Body className={"text-center"}>
                           {t("no_validation_suggestion")}
                          </Card.Body>
                      </Card>
                      { currentSuggestionToValideLists?.map((s,k)=>
                              <Card className={"border-0 mb-3"}>
                                  <Card.Header className={"bg-white border-0"}>
                                      <div className={"d-flex align-items-center justify-content-between"}>
                                          <div>
                                              <Accordion.Toggle as={"span"} variant="link" eventKey={s.id}>
                                                  <div className={"d-flex align-items-center"}>
                                                      <div>
                                                          <div>
                                                              {s.anonymous?(<div className={"d-flex p-3 align-items-center justify-content-center"} style={{background: '#6600CC 0% 0% no-repeat padding-box',borderRadius: 6,color:'white'}}><BS.BsEyeSlash/></div>):(<img src={mSVG} alt="avatar" height={56}/>)}
                                                          </div>                                                      </div>
                                                      <div className={"pl-2 pr-2"}>
                                                          <div className={"user"} hidden={s.anonymous}>{s.created_by && s.created_by.firstname?s.created_by.firstname  +" "+s.created_by.lastname:(s.created_by ? s.created_by.email : '')}</div>
                                                          <div className={"anonym"}>{s.anonymous?<span><BS.BsEyeSlash/> {t("anonyme")}</span>:''}</div>
                                                      </div>
                                                  </div>
                                              </Accordion.Toggle>
                                          </div>

                                          <div className={"time"}>
                                              <div  hidden={!isAdmin(role)}>
                                                  <Button variant={"light"} size={"sm"} onClick={e=>{setHaveToBeValidate(s)}}>valider</Button>
                                              </div>
                                              {moment(s.created_date).fromNow()}
                                              <Button hidden={!isAdmin(role)} style={{backgroundColor:"transparent",border:"none",}}>
                                                  <BI.BiTrash onClick={()=>{setShow("confirm"); setCurrentRow(s)}} style={{color: '#240265',fontWeight:'600',}} size={20}/>
                                              </Button>
                                          </div>
                                      </div>
                                      <div style={{flexGrow:1}} className={"pt-2 pr-3 object"}>
                                          <Accordion.Toggle as={"span"} variant="link" eventKey={s._id} style={{cursor:"pointer"}}>
                                              {limitText(s.suggestion,100)}
                                          </Accordion.Toggle>
                                      </div>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey={s._id}>
                                      <Card.Body>
                                          <div className={"ml-5"}>
                                              {s.suggestion}
                                          </div>
                                      </Card.Body>
                                  </Accordion.Collapse>
                              </Card>)
                      }
                  <div className="" hidden={!currentSuggestionToValideLists || (currentSuggestionToValideLists &&  currentSuggestionToValideLists.length<=0)}>
                      <ReactPaginate
                          breakLabel="..."
                          nextLabel={t("paginate_next")}
                          onPageChange={handleSuggestionToValidePageClick}
                          pageRangeDisplayed={5}
                          pageCount={SuggestionToValidepageCount}
                          previousLabel={t("paginate_previous")}
                          renderOnZeroPageCount={null}
                          className={"react-paginate float-right"}
                      />
                  </div>
              </Accordion>
            </div>
          </Tab.Pane>

            <Tab.Pane eventKey="list">

            <div></div>
            <div className={"pt-1"}>
              <Accordion>
                      <Card  hidden={currentLists?.length>0}>
                          <Card.Body className={"text-center"}>
                           {t("no_suggestion")}
                          </Card.Body>
                      </Card>
                      { currentLists?.map((s)=>
                              <Card className={"border-0 mb-3"}>
                                  <Card.Header className={"bg-white border-0"}>
                                      <div className={"d-flex align-items-center justify-content-between"}>
                                          <div>
                                              <Accordion.Toggle as={"span"} variant="link" eventKey={s.id}>
                                                  <div className={"d-flex align-items-center"}>
                                                      <div>
                                                          {s.anonymous?(<div className={"d-flex p-3 align-items-center justify-content-center"} style={{background: '#6600CC 0% 0% no-repeat padding-box',borderRadius: 6,color:'white'}}><BS.BsEyeSlash/></div>):(<img src={mSVG} alt="avatar" height={56}/>)}
                                                      </div>
                                                      <div className={"pl-2 pr-2"}>
                                                          <div className={"user"} hidden={s.anonymous}>{s.created_by && s.created_by.firstname?s.created_by.firstname  +" "+s.created_by.lastname:(s.created_by ? s.created_by.email : '')}</div>
                                                          <div className={"anonym"}>{s.anonymous?<span><BS.BsEyeSlash/> {t("anonyme")}</span>:''}</div>
                                                      </div>
                                                  </div>
                                              </Accordion.Toggle>
                                          </div>

                                          <div className={"time"}>
                                              {moment(s.created_date).fromNow()}
                                              <Button hidden={!isAdmin(role) && s.created_by._id!==user._id} style={{backgroundColor:"transparent",border:"none",}}>
                                                  <BI.BiTrash onClick={()=>{setShow("confirm"); setCurrentRow(s)}} style={{color: '#240265',fontWeight:'600',}} size={20}/>
                                              </Button>
                                              <br/><br/>
                                              <a href={"#"} style={{fontSize:"9pt"}} onClick={()=>{showComments(s)}}>{t("comments")} ({s.comments?.length})</a>
                                          </div>
                                      </div>
                                      <div style={{flexGrow:1}} className={" pt-2 pr-3 object"}>
                                          <Accordion.Toggle as={"span"} variant="link" eventKey={s._id} style={{cursor:"pointer"}}>
                                              {limitText(s.suggestion,100)}
                                          </Accordion.Toggle>
                                      </div>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey={s._id}>
                                      <Card.Body>
                                          <div className={"ml-5"}>
                                              {s.suggestion}
                                          </div>
                                      </Card.Body>
                                  </Accordion.Collapse>
                              </Card>)}
                 <div className="" hidden={!currentLists || (currentLists && currentLists.length<=0)}>
                  <ReactPaginate
                      breakLabel="..."
                      nextLabel={t("paginate_next")}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel={t("paginate_previous")}
                      renderOnZeroPageCount={null}
                      className={"react-paginate float-right"}
                  />
                 </div>
              </Accordion>
            </div>
          </Tab.Pane>
            <Tab.Pane eventKey="list-anonyms">
            <div className={"pt-1"}>
              <Accordion>
                      <Card  hidden={currentmySuggestionsLists?.length>0}>
                          <Card.Body className={"text-center"}>
                           {t("no_suggestion")}
                          </Card.Body>
                      </Card>
                      { currentmySuggestionsLists?.map((s)=>
                              <Card className={"border-0 mb-3"}>
                                  <Card.Header className={"bg-white border-0"}>
                                      <div className={"d-flex align-items-center justify-content-between"}>
                                          <div>
                                              <Accordion.Toggle as={"span"} variant="link" eventKey={s.id}>
                                                  <div className={"d-flex align-items-center"}>
                                                      <div>
                                                          {s.anonymous?(<div className={"d-flex p-3 align-items-center justify-content-center"} style={{background: '#6600CC 0% 0% no-repeat padding-box',borderRadius: 6,color:'white'}}><BS.BsEyeSlash/></div>):(<img src={mSVG} alt="avatar" height={56}/>)}
                                                      </div>

                                                      <div className={"pl-2 pr-2"}>
                                                          <div className={"user"} hidden={s.anonymous}>{s.created_by && s.created_by.firstname?s.created_by.firstname  +" "+s.created_by.lastname:(s.created_by ? s.created_by.email : '')}</div>
                                                          <div className={"anonym"}>{s.anonymous?<span><BS.BsEyeSlash/> {t("anonyme")}</span>:''}</div>
                                                      </div>
                                                      <h6 className={"pt-2"}>
                                                          {s.anonymous?
                                                              (s.assignTo==='tous' ?
                                                                      <span className={'badge badge-light'}>Envoyé à tous </span> :
                                                                      <span className={"badge badge-light"}>Envoyé à {s.employee && s.employee[0] ? s.employee[0].firstname+' '+s.employee[0].lastname : ''}</span>
                                                              ) : ''}
                                                      </h6>
                                                  </div>
                                              </Accordion.Toggle>
                                          </div>

                                          <div className={"time"}>
                                              {moment(s.created_date).fromNow()}
                                              <Button hidden={!isAdmin(role) && s.created_by._id!==user._id} style={{backgroundColor:"transparent",border:"none",}}>
                                                  <BI.BiTrash onClick={()=>{setShow("confirm"); setCurrentRow(s)}} style={{color: '#240265',fontWeight:'600',}} size={20}/>
                                              </Button>
                                              <br/><br/>
                                              <a href={"#"} style={{fontSize:"9pt"}} onClick={()=>{showComments(s)}}>Commentaires ({s.comments?.length})</a>
                                          </div>
                                      </div>
                                      <div style={{flexGrow:1}} className={"pt-2 pr-3 object"}>
                                          <Accordion.Toggle as={"span"} variant="link" eventKey={s._id} style={{cursor:"pointer"}}>
                                              {limitText(s.suggestion,100)}
                                          </Accordion.Toggle>
                                      </div>

                                  </Card.Header>
                                  <Accordion.Collapse eventKey={s._id}>
                                      <Card.Body>
                                          <div className={"ml-5"}>
                                              {s.suggestion}
                                          </div>
                                      </Card.Body>
                                  </Accordion.Collapse>
                              </Card>)
                      }
                  <div className="" hidden={!currentmySuggestionsLists || (currentmySuggestionsLists && currentmySuggestionsLists.length<=0)}>
                      <ReactPaginate
                          breakLabel="..."
                          nextLabel={t("paginate_next")}
                          onPageChange={handleMySuggestionsPageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageMySuggestionsCount}
                          previousLabel={t("paginate_previous")}
                          renderOnZeroPageCount={null}
                          className={"react-paginate float-right"}
                      />
                  </div>
              </Accordion>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="write">
            <div className={"p-5"}>
              <Card className={"shadow border-0"}>
                <Card.Header className={"bg-white p-4"}><MdGroupWork/> {t("suggestions")}</Card.Header>
                <Card.Body className={"p-4"}>
                  <div className={"d-inline-flex align-items-center anon mb-4"}>
                    <div onClick={e=>setAnon(1)} className={anon?"p-3 pl-5 pr-5 active":"p-3 pl-5 pr-5"}><BS.BsEyeSlash/> {t("anonyme")}</div>
                    <div onClick={e=>setAnon(0)} className={!anon?"p-3 pl-5 pr-5 active":"p-3 pl-5 pr-5"}><BS.BsEye/> {t("visible")}</div>
                  </div>
                  <Form.Group>
                    <Form.Control
                      as={"textarea"}
                      // className={"border-0"}
                        value={text}
                      autoFocus
                      placeholder={t("your_suggestion")}
                      onChange={handleSuggText}
                     />
                  </Form.Group>
                    {anon===0 ?
                    <Form.Group>
                        <Form.Label>{t("send_to")} :</Form.Label> <br/>
                        <div key={`inline-assignTo`} className="mb-3">
                            <Form.Check
                                size={"lg"}
                                type={"radio"}
                                name={"assignTo"}
                                label={"Tous"}
                                value={"tous"}
                                id={"inline-assignTo-2"}
                                inline
                                checked={assignTo==='tous'}
                                onClick={()=>handleAssignTo('tous')}
                            />
                            <Form.Check
                                size={"lg"}
                                type={"radio"}
                                name={"assignTo"}
                                label={"Employé"}
                                value={"employee"}
                                id={"inline-assignTo-1"}
                                inline
                                checked={assignTo==='employee'}
                                onClick={()=>handleAssignTo('employee')}
                            />
                        </div>
                    </Form.Group>
                   : ''
                    }
                    {anon===0 && assignTo==='employee' ?
                        <Form.Group>
                            <Select noOptionsMessage={() => t("no_data_available")}
                                options={props.rwState.colleagues.filter(r=>r.activated)}
                                getOptionLabel={o => o.firstname+ " "+o.lastname +" ("+o.email+")"}
                                getOptionValue={o => o.id}
                                styles={{
                                    control: base => ({
                                        ...base,
                                        minHeight: 38
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("employee_text")}
                                onChange={e => handleSelectEmployee(e)}
                                className={handleinputValidationError(formError,'employee')}
                                />
                                       {handleTextValidationError(formError,'employee')}
                     
                        </Form.Group>
                        : '' }
                </Card.Body>
                <Card.Footer className={"bg-white p-4 float-right text-right"}>
                  <Button onClick={sendSuggestion}
                  disabled={
                             anon===0 && assignTo==='employee' ? handleDisabledBtnValidationError(formError,
                                ['employee','suggestionText']) :
                                handleDisabledBtnValidationError(formError,
                                    ['suggestionText'])
                            } >
                       <MD.MdSend/> {t("send")} </Button>
                </Card.Footer>
              </Card>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
      <Modal size={"md"} centered show={show === 'assign'} onHide={handleClose}>
          <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
              {t("choose_an_employee")}
          </Modal.Header>
          <Modal.Body className={'p-4'}>
              <Row>
                  <Col>
                      <Form.Group>
                          <Select noOptionsMessage={() => t("no_data_available")}
                              options={props.rwState.colleagues.filter(r=>r.activated)}
                              getOptionLabel={o => o.firstname+ " "+o.lastname +" ("+o.email+")"}
                              getOptionValue={o => o.id}
                              styles={{
                                  control: base => ({
                                      ...base,
                                      minHeight: 38
                                  })
                              }}
                              theme={theme => ({
                                  ...theme,
                                  colors: {
                                      ...theme.colors,
                                      primary: '#6600CC',
                                      primary25: '#6600CC25',
                                      primary75: '#6600CC75',
                                      primary50: '#6600CC50'
                                  },
                              })}
                              placeholder={t("employee_text")}
                              onChange={e => handleSelectEmployee(e)}
                          />
                      </Form.Group>
                  </Col>

              </Row>
          </Modal.Body>
          <Modal.Footer className={'border-0 pt-0'}>
              <Button variant="light" onClick={handleClose}>
                  {t("cancel")}
              </Button>
              <Button variant="primary" onClick={assignToEmp}>
                  {t("assigned_action")}
              </Button>
          </Modal.Footer>
      </Modal>
      <Modal size={"lg"} centered show={show === 'comments'} onHide={handleClose}>
          <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
              Commentaires ({comments?.length})
              <Button onClick={handleClose}><BI.BiWindowClose/></Button>
          </Modal.Header>
          <Modal.Body className={'p-4'}>
                      { comments?.map((c,k)=>
                          <Row>
                              <Card className={"border-0 mb-3"}>
                              <Card.Header className={"bg-white border-0"}>
                                      <div>
                                          <Accordion.Toggle as={"span"} variant="link" eventKey={c._id}>
                                              <div className={"d-flex align-items-center"}>
                                                  <div className={"pl-2 pr-2"}>
                                                      <div className={"anonym"}>{c.anonymous?'':<span><BS.BsEye/> {c.employee?.firstname +" "+c.employee?.lastname}</span>}</div>
                                                  </div>
                                              </div>
                                          </Accordion.Toggle>
                                      </div>
                                      <Col style={{flexGrow:1}} className={"pl-3 pr-3 object"}>
                                          <Accordion.Toggle as={"span"} variant="link" eventKey={c._id} style={{cursor:"pointer"}}>
                                              {limitText(c.comment,100)}
                                          </Accordion.Toggle>
                                      </Col>
                                      <Row>

                                          <Col className={"time float-right"}>
                                              {moment(c.created_date).fromNow()}
                                              <Button onClick={()=>{deleteSuggestionComment(c._id)}} hidden={c.created_by===user._id} style={{backgroundColor:"transparent",border:"none",color:"#262B65",fontSize:"11pt"}}>
                                                  {t("delete")}
                                              </Button>
                                          </Col>
                                      </Row>

                              </Card.Header>
                              <Accordion.Collapse eventKey={c._id}>
                                  <Card.Body>
                                      <div className={"ml-5"}>
                                          {c.comment}
                                      </div>
                                  </Card.Body>
                              </Accordion.Collapse>
                          </Card>
                          </Row>

                      )}
              <div className={"m-4"} hidden={comments?.length>0}>{t("no_comment")}</div>
                  <Card className={"shadow border-0"}>
                      <Card.Body className={"p-4"}>
                          <div className={"d-inline-flex align-items-center anon mb-4"}>
                              <div onClick={e=>setAnon(1)} className={anon?"p-3 pl-5 pr-5 active":"p-3 pl-5 pr-5"}><BS.BsEyeSlash/> {t("anonyme")}</div>
                              <div onClick={e=>setAnon(0)} className={!anon?"p-3 pl-5 pr-5 active":"p-3 pl-5 pr-5"}><BS.BsEye/> {t("visible")}</div>
                          </div>
                          <Form.Group>
                              <Form.Control
                                  as={"textarea"}
                                  className={"border-0"}
                                  autoFocus
                                  placeholder={t("your_comment")}
                                  onChange={handleSuggText}
                              />
                          </Form.Group>
                      </Card.Body>
                      <Card.Footer className={"bg-white p-2 float-right text-right"}>
                          <Button disabled={!text} onClick={commentSuggestion}> <MD.MdSend/> {t("leave_a_comment")} </Button>
                      </Card.Footer>
                  </Card>
          </Modal.Body>

      </Modal>
      <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
          <Modal.Header closeButton className={'border-0'}>
              <Modal.Title>{t("confirmation")}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
              <div className={"text-center"}>
                  {t("confirme_irreversile_deletion_text")}
              </div>
          </Modal.Body>
          <Modal.Footer className={'text-center border-0'}>
              <div>
                  <div className={"text-center"}>
                      <Button variant="primary" onClick={deleteSuggestion}>
                          {t("yes_delete")}
                      </Button>
                      <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                          {t("cancel")}
                      </Button>
                  </div>
              </div>

          </Modal.Footer>
      </Modal>
        <Modal show={show==="confirmValidate"} onHide={() => setShow(null)} centered size={"md"}>
          <Modal.Header closeButton className={'border-0'}>
              <Modal.Title>{t("confirmation")}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
              <div className={"text-center"}>
                  {t("notif_change_suggestion_status")}
              </div>
          </Modal.Body>
          <Modal.Footer className={'text-center border-0'}>
              <div>
                  <div className={"text-center"}>
                      <Button variant="primary" onClick={validateSuggestion}>
                          {t("yes")}
                      </Button>
                      <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                          {t("cancel")}
                      </Button>
                  </div>
              </div>

          </Modal.Footer>
      </Modal>

  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Suggestions);
