import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
const availableLanguages = ['en', 'fr'];

const resources = {
    en: {translation: translationEN},
    fr: {translation: translationFR}
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            checkWhitelist: true,
          },
        whitelist: availableLanguages,
        debug: true,
        fallbackLng: 'en',
        resources,
        lng: "en",
        react: {wait: true},
        keySeparator: false,
        interpolation: {escapeValue: false}
    });

export default i18n;