import axios from "axios"
import env from "../env"
axios.defaults.withCredentials = true;

let session = JSON.parse(window.localStorage.getItem('glowhr_user')) || {}
let authorization = session ? session.authorization_key : ''
let sid = session ? session.session_id : ''
let headers = {
    Authorization: authorization,
    sid
}

export const uploadOnCdn = file_data => {
    let data = new FormData()
    data.append('file', file_data)
    let config = {
        method: 'post',
        url: env.DEV.apiUrl + env.urls.upload_to_cdn,
        headers,
        data
    }
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                console.log(response)
                resolve(response.data)
            })
            .catch(error => {
                console.log(error)
                reject(error)
            })
    })
}

export const updateProfileDocuments = (data, channel) => {
    return new Promise((resolve, reject) => {
        axios.post(env.DEV.apiUrl + env.urls.update_profile_documents,
            { sio_channel: channel, documents: data },
            { headers })
            .then(response => {
                console.log(response)
                resolve(response.data)
            })
            .catch(error => {
                console.log(error)
                reject(error)
            })
    })
}

export const updateProfileInformations = (infos, sio_channel) => {
    console.log(' sio ',sio_channel)
    return new Promise((resolve, reject) => {
        axios.put(env.DEV.apiUrl + env.urls.update_profil_infos,
            { type: 'self', sio_channel, ...infos},
            { headers })
            .then(response => {
                console.log(response)
                resolve(response.data)
                
            })
            .catch(error => {
                console.log(error)
                reject(error)
            })
    })
}

export const getFileFromCdn = key => {
    return new Promise((resolve, reject) => {
        axios.get(env.DEV.apiUrl + env.urls.get_from_cdn,{responseType: 'blob',params: {file: key, sid}})
        .then(response => {
            console.log(response)
            resolve(response)
        })
        .catch(err => {
            console.log(err)
            reject(err)
        })
    })
}

export const deleteProfileDocument = (key, sio_channel) => {
    return new Promise((resolve, reject) => {
        const url = `${env.DEV.apiUrl}${env.urls.delete_profile_document}?image_key=${key}&sio_channel=${sio_channel}`
        axios.delete(url, {headers})
        .then(response => {
            console.log(response)
            resolve(response)
        })
        .catch(err => {
            console.log(err)
            reject(err)
        })
    })
}

export const getCalendarEvent = (sio_channel, start_date, end_date) => axios.get(env.DEV.apiUrl + env.urls.get_calendar_event, {params: {sio_channel, start_date, end_date}, headers})

export const verifyColleagueLink = (sio_channel, link_id) => axios.get(`${env.DEV.apiUrl}${env.urls.verifyColleagueLink}`, {params: {sio_channel, linkid: link_id}})

export const activeColleagueAccount = (sio_channel, link_id, password) => axios.post(`${env.DEV.apiUrl}${env.urls.activateColleagueAccount}`, {sio_channel, linkid: link_id, password})
//get a file from cdn
export const getFileUrl = (filename)=>{
    let url=env.DEV.apiUrl,
        imgUrl = url+"/cdn?sid="+sid+"&file="+filename;
    return imgUrl;
}
export const getExportFileUrl = (filename)=>{
    let url=env.DEV.apiUrl,
        imgUrl = url+"/cdn/export?sid="+sid+"&file="+filename;
    return imgUrl;
}

export const getExportExcelFileFromCdn = filename => {
    return new Promise((resolve, reject) => {
        axios.get(env.DEV.apiUrl + "/cdn/export?sid="+sid+"&file="+filename,{
            responseType: 'blob',
          })
        .then(response => {
            console.log(response)
            resolve(response)
        })
        .catch(err => {
            console.log(err)
            reject(err)
        })
    })
}