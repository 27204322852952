import React, {useEffect} from 'react';
import './InOut.css'
import {Col, Container, Row, Tab} from "react-bootstrap";
import ContextMenu from "../../menus/ContextMenu";
import Integration from "../Integration";
import Outtegration from "../Outtegration";

const InOut = (props) => {
    const contextMenu = [
        {label: 'Intégrations', eventKey: 'integration'},
        {label: 'Départs', eventKey: 'depart'}
    ]

    return <div id={'InOut'}>
        <Container fluid>
            <Row>
                <Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="integration">
                        <ContextMenu menu={contextMenu}/>
                        <Tab.Content>
                            <Tab.Pane eventKey="integration">
                                <Integration/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="depart">
                                <Outtegration/>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Col>
            </Row>
        </Container>
    </div>;
};

export default InOut;
