import React, {useEffect, useState} from 'react';
import "./FormationsFind.css"
import { Form, Modal, Button, Col, Container, Row} from "react-bootstrap";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import {isAdmin} from "../../../helpers/helper";
import SingleFormation from "../../widgets/SingleFormation/SingleFormation";
import Select from "react-select";
import {useParams,useHistory} from 'react-router-dom'
import * as RI from "react-icons/ri";
import * as BI from "react-icons/bi";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const FormationsFind = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    let { id_formation } = useParams();
    useEffect(() => {
        props.action_loading(true);
        props.action_listFormations();
        props.action_listColleagues()
        props.action_allListModuleFormations()
        props.action_setTitle(t("my_training_title"))
    }, []);
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    let user = JSON.parse(window.localStorage.getItem('glowhr_user')).user;
    const [show, setShow] = useState([])
    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const [current_row, setCurrentRow] = useState()
    const [isUpdate, setHasUpdate] = useState(false)
    const [isExternal, setIsExternal] = useState(false)
    const [externalLink, setExternalLink] = useState("")
    const [start, setStart] = useState()
    const [end, setEnd] = useState()
    const affectations_type =
            [
                { label: t('all'), value: 'all' },
                { label: t("departments"), value: "departments"},
                { label: t('branches'), value: 'branches'},
                { label: t('positions'), value: 'positions'},
                { label: t('specific_employee'), value: 'employee'}
            ]
    const [affect, setAffect] = useState(affectations_type[0])
    const [assignTo, setAssignTo] = useState([])
    const [checkAssign, setCheckAssign] = useState(false)
    const [attendees_target_id, setAttendeesTargetId] = useState([])
    const [attendees_target, setAttendeesTarget] = useState("all")
    const handleAffectation_type = (e) => {
        setAssignTo([]);
        setAffect(e)
        setAttendeesTarget(e.value)
    };

    const handleCheckAssign = () => {
           checkAssign ? setCheckAssign(false) : setCheckAssign(true);
    };
    const handleSelectAssign = (e) => {
        if(e) {
            setAssignTo(e)
            setAttendeesTargetId(e.filter(res => {
                return res._id
            }))
        }else{
            setAttendeesTargetId([])
        }
    };
    const checkUserHasFinish=(test_formation)=>{
        let resp =false;
        if(test_formation){
            if( test_formation.test_finisher_info && test_formation.test_finisher_info.length>0){
                test_formation.test_finisher_info.forEach(response=>{
                    let check=  response[test_formation.assign_to.find(p=>p.employee_info.user===user._id)?.employee_info._id]
                    if(check){ resp=true; return; }
                })
            }
        }
        return resp;
    }

   const checkFinishFormations=(test_formations)=>{
       let resp =[],checkHas=false;
       test_formations.map(test_formation=>{
        if(test_formation){
            if( test_formation.test_finisher_info && test_formation.test_finisher_info.length>0){
                test_formation.test_finisher_info.forEach(response=>{
                    let check=  response[test_formation.assign_to.find(p=>p.employee_info.user===user._id)?.employee_info._id]
                    if(check) checkHas=true;
                })
            }
            if(test_formation.assign_to) {
                let check=  test_formation.assign_to.find(p=>p.employee_info.user===user._id)?.employee_info._id
                if (checkHas && check && test_formation.status==="active") resp.push(test_formation)
            }
        }
        })
        return resp;
    }

    const checkNotBeginFormations=(test_formations)=>{
       let resp =[],checkHas=true;
       test_formations.map(test_formation=>{
        if(test_formation){
            if( test_formation.test_finisher_info){
                test_formation.test_finisher_info.forEach(response=>{
                    let check=  response[test_formation.assign_to.find(p=>p.employee_info.user===user._id)?.employee_info._id]
                    if(check)  checkHas=false;
                })
            }
            if(test_formation.assign_to) {
                let check=  test_formation.assign_to.find(p=>p.employee_info.user===user._id)?.employee_info._id
                if (checkHas && check && test_formation.status==="active") resp.push(test_formation)
            }
        }

        })
        return resp;
    }

    const handleClose = () => {
        setShow(null)
    }

    const editFormations = (formation)=>{
        setCurrentRow(formation);
        setName(formation.name);
        setDescription(formation.description);
        setHasUpdate(true);
        setShow('formation_modal')
    };
    const resetFormations = ()=>{
        setCurrentRow({});
        setName("");
        setDescription("");
        setHasUpdate(false);
        setShow(null)
        setCheckAssign(false)
        setAffect(affectations_type[0])
        setStart("")
        setEnd("")
    };
    const createFormations = () => {
        let o = {
            "name": name,
            "description": description,
            "sio_channel": "",
            "id":current_row?.id,
            "attendees_target": attendees_target,
            "attendees_target_id": attendees_target_id,
            "start_date": start,
            "end_date": end,
        };
        if (!isUpdate)
            props.action_createFormation(o)
        else
            props.action_updateFormation(o);
        setShow(null)
        props.action_loading(true)
        resetFormations()
    };

    const handleShowDetails = (expense)=>{
        setShow('show_detail');
        setCurrentRow(expense);
    }
    const contextMenu =
        [
            {label: 'En cours', eventKey: 'paie'},
            {label: 'Terminées', eventKey: 'depense'},
        ]
 const contextMenuAdmin =
        [
            {label: 'Actives', eventKey: 'active'},
            {label: 'En attentes', eventKey: 'waiting'},
            {label: 'Cloturées', eventKey: 'finish'},
        ]
    const handleStart = (e) => {
        setStart(e.target.value)
    }
    const handleEnd = (e) => {
        setEnd(e.target.value)
    }
    const handleExternal = (e) => {
        e==="external" ? setIsExternal(true) : setIsExternal(false);
    }
  const handleExternalLink = (e) => {
        let link=e.target.value;
        if(link.includes('https') || link.includes('http') || link.includes('www') ) {
            setExternalLink(link)
        }else{
            link='http://'+link
            setExternalLink(link)
        }
    }
 const handleName = (e) => {
        setName(e.target.value)
    }

    const handleDescriptionName = (e) => {
        setDescription(e.target.value)
    }

    const requestDataBuilder = () => (
        {
            "name": name,
            "description": description,
            "external":isExternal,
            "external_link":externalLink,
            "sio_channel": "",
            "attendees_target": attendees_target,
            "attendees_target_id": attendees_target_id,
            "start_date": start,
            "end_date": end,
        }
    )
    const editFormation = () => {
        let type_data = requestDataBuilder()
        type_data['id'] = current_row.id
        type_data['name'] = name
        type_data['description'] = description
        type_data['external'] = isExternal
        type_data['external_link'] = externalLink
        props.action_updateFormation(type_data)
        setShow(null)
        props.action_loading(true)
        setCurrentRow()
    }

    const createFormation= () => {
        let o = requestDataBuilder()
        props.action_createFormation(o)
        setShow(null)
        props.action_loading(true)
    }
    const proceed = () => {
        if(current_row?.id) {
            editFormation()
        } else {
            createFormation()
        }
        resetFormations();
    }

    const updateFormationType = row_data => {
        console.log('row => ', row_data)
        setCurrentRow(row_data)
        setShow('typedepense')
    }
    const deleteTypeFormation = row_data => {
        console.log('row => ', row_data)
        setCurrentRow(row_data)
        setShow('confirm')
    }
    const deleteFormationType = () => {
        let data = requestDataBuilder()
        data['id'] = current_row.id
        props.action_deleteFormationType(data)
        console.log('data', data)
        setShow(null)
        props.action_loading(true)
        setCurrentRow()
    }

    return <div id={"Formations"}>
        <Container fluid>
            <Row>
                <Col sm={"12"} className={'text-left'}>
                    <Button className={"btn btn-light btn-sm"} onClick={e => history.push({pathname:'/dash/courses'})}>
                        <BI.BiChevronLeft style={{color: '#240265', fontWeight: '600',}} size={25}/> {t("list_training")}
                    </Button>
                </Col>
                {isAdmin(role) ?
                <Col>
                    <div className={"p-3 border-0"} style={{borderRadius:10}}>
                        <Row>
                            {  props.rwState.formations && props.rwState.formations.filter(r=>r.status==='active').length > 0  ?
                                props.rwState.formations.map((p => {
                                    if(p._id===id_formation)  return <Col md={6}><SingleFormation modules={props.rwState.all_module_formations && props.rwState.all_module_formations.filter(res=>res.formation_info._id===p._id)} employee={props.rwState.colleagues} formation={p}/></Col>
                                } ))
                                :
                                <h6 className={"col-12 alert alert-warning"}> {t("no_formation_founded")} </h6>
                            }
                        </Row>
                    </div>
                </Col>
                    :
                    <Col>
                        <Row>
                            {  props.rwState.formations && props.rwState.formations.filter(r=>r.status==='active').length > 0 ?
                                   props.rwState.formations.map((p => {
                                      if(p._id===id_formation)  return <Col md={6}><SingleFormation modules={props.rwState.all_module_formations && props.rwState.all_module_formations.filter(res=>res.formation_info._id===p._id)} employee={props.rwState.colleagues} formation={p}/></Col>
                                    }))
                                    :
                                    <h6 className={"col-12 alert alert-warning"}> {t("no_formation_founded")} </h6>
                              }
                        </Row>
                    </Col>
                    }
            </Row>

        </Container>

        {/*  modals */}
        <div>
            <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
                <Modal.Header closeButton className={'border-0'}>
                    <Modal.Title>{t("confirmation")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"text-center"}>
                       {t("confirme_irreversile_deletion_text")}
                    </div>
                </Modal.Body>
                <Modal.Footer className={'text-center border-0'}>
                    <Button variant="primary" onClick={deleteFormationType}>
                        {t("yes")}
                    </Button>
                    <Button variant="light" onClick={() => setShow(null)}>
                        {t("no")}
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
        <Modal centered show={show === 'create'} onHide={handleClose} size={"lg"}>
            <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
                {
                    current_row ? (<Modal.Title className='w-100'>Mise à jour</Modal.Title>) : (<Modal.Title className='w-100'>Ajout de formation</Modal.Title>)
                }
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <Form.Group>
                    <Form.Label>{t("type_formation")}</Form.Label> <br/>
                    <div key={`inline-external`} className="mb-3">
                        <Form.Check
                            size={"lg"}
                            type={"radio"}
                            name={"external"}
                            label={"Interne"}
                            value={"internal"}
                            id={"inline-external-2"}
                            inline
                            checked
                            onClick={()=>handleExternal('internal')}
                        />
                    <Form.Check
                        size={"lg"}
                        type={"radio"}
                        name={"external"}
                        label={"Externe"}
                        value={"external"}
                        id={"inline-external-1"}
                        inline
                        onClick={()=>handleExternal('external')}
                    />

                    </div>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("formation_title_name")}</Form.Label> <br/>
                    <Form.Control
                        size={"lg"}
                        placeholder={'Titre'}
                        defaultValue={current_row?.name}
                        onChange={handleName}
                    />
                </Form.Group>
                {isExternal ?
                    <Form.Group>
                        <Form.Label>{t("external_formation_link")}</Form.Label> <br/>
                        <Form.Control
                            size={"lg"}
                            placeholder={'Lien externe'}
                            defaultValue={current_row?.external_link}
                            onChange={handleExternalLink}
                        />
                    </Form.Group>
                    : ""
                }
                <Form.Group>
                    <Form.Label>{t("formation_description")}</Form.Label> <br/>
                    <Form.Control
                        size={"lg"}
                        as={"textarea"}
                        placeholder={'Description'}
                        onChange={handleDescriptionName}
                        defaultValue={current_row?.description}
                    />
                </Form.Group>
                <Form.Group>
                    <div key={`default-assign`} className="mb-3">
                        <Form.Check
                            type={"checkbox"}
                            id={`default-assign`}
                            label={"assigner"}
                            onChange={handleCheckAssign}
                        />
                    </div>
                </Form.Group>
                <div hidden={!checkAssign}>
                <Form.Group>
                <Form.Label>{t("assigned_to")} </Form.Label> <br/>
                    <Form.Group>
                        <Select
                            options={affectations_type}
                            defaultValue={affect}
                            styles={{
                                control: base => ({
                                    ...base,
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("add_to")}
                            name='affect_to'
                            onChange={handleAffectation_type}
                        />
                    </Form.Group>
                    <Form.Group hidden={affect ? affect.value!=='branches'  : !affect}>
                        <Select
                            options={props.rwState.branches}
                            getOptionLabel={o => o.name}
                            getOptionValue={o => o._id}
                            defaultValue={affect && affect.value==='branches' && current_row && current_row.attendees_target_id ? current_row .attendees_target_id.map(res=>{return res}) : ""}
                            styles={{
                                control: base => ({
                                    ...base,
                                    minHeight: 48,
                                    // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("branch_title_unique")}
                            isMulti
                            onChange={handleSelectAssign}
                        />
                    </Form.Group>
                    <Form.Group hidden={affect ? affect.value!=='departments'  : !affect}>
                        <Select
                            options={props.rwState.departments}
                            getOptionLabel={o => o.name}
                            getOptionValue={o => o._id}
                            defaultValue={affect && affect.value==='departments' && current_row && current_row.attendees_target_id ? current_row.attendees_target_id.map(res=>{return res}) : ""}
                            styles={{
                                control: base => ({
                                    ...base,

                                    minHeight: 48,
                                    // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("department_title_unique")}
                            isMulti
                            onChange={handleSelectAssign}
                        />
                    </Form.Group>
                    <Form.Group hidden={affect ? affect.value!=='positions'  : !affect}>
                        <Select
                            options={props.rwState.positions}
                            getOptionLabel={o => o.title}
                            getOptionValue={o => o._id}
                            defaultValue={affect && affect.value!=='positions' && current_row && current_row.attendees_target_id ? current_row.attendees_target_id.map(res=>{return res}) : ""}
                            styles={{
                                control: base => ({
                                    ...base,

                                    minHeight: 48,
                                    // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("position_title_unique")}
                            isMulti
                            onChange={handleSelectAssign}
                        />
                    </Form.Group>
                    <Form.Group hidden={affect ? affect.value!=='employee' : !affect}>
                        <Select
                            options={props.rwState.colleagues.filter(r=>r.activated)}
                            getOptionLabel={o => o.lastname+' '+o.firstname}
                            getOptionValue={o => o._id}
                            defaultValue={affect && affect.value==='employee' && current_row && current_row.attendees_target_id ? current_row.attendees_target_id.map(res=>{return res}) : ""}
                            styles={{
                                control: base => ({
                                    ...base,
                                    minHeight: 48,
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("employee_text")}
                            isMulti
                            onChange={handleSelectAssign}
                        />
                    </Form.Group>
                </Form.Group>
                <Row>
                    <Col sm={6}>
                    <Form.Group>
                        <Form.Label>{t("start_")}</Form.Label> <br/>
                        <Form.Control
                            onChange={handleStart}
                            value={start}
                            type={"datetime-local"}/>
                    </Form.Group>
                    </Col>
                    <Col sm={6}>
                    <Form.Group>
                        <Form.Label>{t("end_")}</Form.Label> <br/>
                        <Form.Control
                            onChange={handleEnd}
                            min={start}
                            value={end}
                            type={"datetime-local"}/>
                    </Form.Group>
                    </Col>
                </Row>
                </div>
            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("cancel")}
                </Button>
                <Button variant="primary" onClick={proceed}>
                    {t("save")}
                </Button>
            </Modal.Footer>
        </Modal>


    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(FormationsFind);
