import React, {useEffect, useState} from 'react';
import './RecruitmentApplication.css'
import {Button, Col, Container, Form, FormGroup, Row} from "react-bootstrap";
import axios from "axios";
import env from "../../env";
import {useParams} from 'react-router-dom'
import Select from "react-select";
import Emitter from "../../service/events";
import {validateFile} from "../../helpers/helper";
import {uploadOnCdn} from "../../service";
import { useTranslation } from 'react-i18next';

const RecruitmentApplication = (props) => {
    const { t } = useTranslation();
    const contextMenu = [
        // {label:'Mot de passe Perdu', href:'/auth/resetpass'},
    ]
    const { recruitment_slug } = useParams()
    const [recruitment_id, setRecruitmentId] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [email, setEmail] = useState()
    const [firsname, setFirstname] = useState()
    const [lastname, setLastname] = useState()
    const [comment, setComment] = useState()
    const [url, setUrl] = useState()
    const [motiv_letter_name, setMotivLetterName] = useState()
    const [motiv_letter, setMotivLetter] = useState()
    const [cv_name, setCVName] = useState()
    const [cv, setCV] = useState()
    const [lang, setLang] = useState()
    const [applicationError,setApplicationError] = useState()
    const [CvfileError,setCvFileError] = useState(false);
    const [LetterfileError,setLetterFileError] = useState(false);
    const [recruitment, setRecruitment] = useState();
    const lang_options = [{ label: "Français", value: "fr" }, { label: 'Anglais', value: 'en' }];
    //var recruitment;

    useEffect( () => {
        let r;
        if (recruitment_slug) {
            axios.get(env.DEV.apiUrl + "/recruitment/webform/by/slug?slug="+recruitment_slug).then((response)=>{
                setRecruitmentId(response.data?._id)
                 localStorage.setItem("recruitment_id",response.data?._id)
                setRecruitment(response.data);
                r = response.data;
            })
        }
        Emitter.on('application-res-received',(data)=>{
            if (data.status===200){
               /* let ap = {
                    "recruitment":recruitment_id,
                    "applicant":data.id,
                    "company":r.company.id,
                    "sio_channel":"applicantApplication_"+recruitment_id
                };
                console.log('data ==>',ap);
                var myHeaders = new Headers();
                var myInit = { method: 'POST',
                    headers: myHeaders,
                    mode: 'cors',
                    cache: 'default' ,
                    data:ap
                };
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(ap)
                };*/
                //fetch(env.DEV.apiUrl + "/application",requestOptions);
              //  axios.post(,ap);

                setLoading(false);
                setSuccess(true);
            }

        });
        Emitter.on('applicant-res-received',(data)=>{
            setLoading(false);
        })
    }, []);

    const handleFirstname = (e) => {
        setFirstname(e.target.value)
    }
    const handleLastname = (e) => {
        setLastname(e.target.value)
    }

    const handleEmail = e => {
        setEmail(e.target.value)
    }
    const handleUrl = e => {
        setUrl(e.target.value)
    }
    const handleComment = e => {
        setComment(e.target.value)
    }
    const handleLang = e => {
        console.log("lang",e)
        setLang(e.value)
    };
    const handleFile = (e) => {
        if(e) {
            if (validateFile(e.target.files[0].type)){
                if (e.target.name==="cv"){
                    setCvFileError(false);
                    let file_data = e.target.files[0]
                    setCV(file_data)
                    setCVName(file_data.name)
                } else{
                    setLetterFileError(false);
                    let file_data = e.target.files[0]
                    setMotivLetter(file_data)
                    setMotivLetterName(file_data.name)
                }

            } else{
                if (e.target.name==="cv"){
                    setCvFileError(true)
                }else{
                    setLetterFileError(true)
                }
                }
        }
    };

    const postulate = async e => {
        let o = {
            "email": email,
            "firstname": firsname,
            "lastname": lastname,
            "language":lang,
            "cv_url": url,
            "recruitment_id":recruitment_id,
        };

        if (cv){
            let resp = await uploadOnCdn(cv);
            setCV(resp?.filename);
            o.cv_url=resp?.filename;
        }
        if (motiv_letter){
            let resp = await uploadOnCdn(motiv_letter);
            setMotivLetter(resp?.filename);
            o.motivation_letter_url=resp?.filename;
        }

        o.sio_channel = "recruitmentApplication_"+recruitment_id;
        setLoading(true);
        axios.post(env.DEV.apiUrl + "/recruitment/webform",o).then(s=>{
            setLoading(false);
            if (s.status!==200){
                setSuccess(false);
                setApplicationError(true)
            }
        }).catch((e)=>{
            setLoading(false);
            setApplicationError(true);
        });
    };
    return <div id={'RecruitmentApplication'}>
        <Container>
            <Row hidden={success}>
                <Col md={6}>
                    <Row>
                        <div className={"r-info-container ml-3"}>
                            <h1>{recruitment?.title}</h1>
                            <h6 className={"comp-name p-1"}>{recruitment?.company.name}</h6>
                            <div>{recruitment?.description}</div>
                            <div className={"d-inline-flex align-items-center mb-3"}>
                                {recruitment?.tags?.map((t,k)=><div key={k} className={"refby p-2 mr-2"}>{t.label?t.label:t.toString()}</div>)}
                            </div>
                        </div>

                    </Row>
                    <Form.Group>
                        <Form.Control
                            size={"lg"}
                            placeholder={t('email')}
                            type={'email'}
                            onChange={handleEmail}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            size={"lg"}
                            placeholder={t('first_name')}
                            onChange={handleFirstname}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            size={"lg"}
                            placeholder={t('last_name')}
                            onChange={handleLastname}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            size={"lg"}
                            placeholder={t('file_asking')}
                            type={'url'}
                            onChange={handleUrl}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t('upload_cv')}</Form.Label>
                        <Form.File id="formcheck-api-custom" custom>
                            <Form.File.Input name={"cv"} isInvalid={CvfileError}  onChange={handleFile} />
                            <Form.File.Label data-browse={t('choose')}>
                                {cv_name}
                            </Form.File.Label>
                            <small hidden={!CvfileError} className={"text-danger"}>{t('invalid_file')}</small>
                        </Form.File>
                    </Form.Group>
                    {
                        recruitment?.has_motiv_letter &&
                        <Form.Group>
                            <Form.Label>Lettre de motivation (.pdf/.docx/.doc)</Form.Label>
                            <Form.File id="formcheck-api-custom" custom>
                                <Form.File.Input name={"letter"} isInvalid={LetterfileError}  onChange={handleFile} />
                                <Form.File.Label data-browse="choose">
                                    {motiv_letter_name}
                                </Form.File.Label>
                                <small hidden={!LetterfileError} className={"text-danger"}>{t('invalid_file')}</small>
                            </Form.File>
                        </Form.Group>
                    }
                    <Form.Group>
                        <Form.Label>Langue</Form.Label>
                        <Select
                            options={lang_options}
                            styles={{
                                control: base => ({
                                    ...base,
                                    minHeight: 48
                                })
                            }}

                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t('language')}
                            name='gender'
                            onChange={handleLang}
                        />

                    </Form.Group>
                    <div className={'mt-3'}>
                        <p className={'text-danger'} hidden={!applicationError}><strong>{t('error_occure')}<br/><small>{t('verify_account')}</small></strong></p>
                        <Button disabled={loading} block size={"lg"} onClick={postulate}>{loading ? t('submitting') : t('apply_for_position')}</Button>
                    </div>
                </Col>
                <Col md={6}>
                    <div className={"align-items-center"}>
                        <img  src={"https://www.inry.com/hubfs/INRY/IMG/apps/ATS/applicant-tracking-system.png"} style={{width:"100%", marginTop:"65px"}} />
                    </div>
                </Col>

            </Row>
            <Row hidden={!success}>
                <div className={"text-center"}>
                    <div>
                        <img style={{width:"15%"}} src={"https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Circle-icons-check.svg/1200px-Circle-icons-check.svg.png"} alt={"Success"}/>
                    </div>
                    <h6 style={{color:"#19183f"}} className={"text-center  mt-5"}>
                        {t('apply_succesfully_done')}
                    </h6>
                    <div>
                        <Button size={"lg mt-5"} href={'/'}>{t('return')}</Button>
                    </div>
                </div>
            </Row>

        </Container>
    </div>;
};

export default RecruitmentApplication;
