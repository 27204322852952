import React from 'react';
import './BalanceWidget.css'
import WidgetTop from "../WidgetTop";
import {Button} from "react-bootstrap";
const BalanceWidget = () => {
  return <div id={'BalanceWidget'}>
    <WidgetTop title={'Balance'} action={'...'}/>
    <div className={'text-center'}>
      <div className={'label mb-2'}>Le transport</div>
      <div className={'amount'}>3405$</div>
      <div className={'icon mt-3'}>
        <img src={''} alt="icon"/>
      </div>
      <div className={'mt-4'}>
        <Button variant={"outline-light"}>Voir</Button>
      </div>
    </div>
    <div className={'mt-3 mb-3'}>
      <div className={'listItem d-flex align-items-center justify-content-between p-3'}>
        <div className={'d-flex align-items-center'}>
          <div className={'itemIcon'}>
            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-truck" fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd"
                    d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
            </svg>
          </div>
          <div className={'ml-2'}>
            <div className={'itemLabel'}>Transport</div>
            <div className={'itemSubLabel'}>Aujourd ‘hui</div>
          </div>
        </div>
        <div>
          <div className={'itemAmount'}>338$</div>
        </div>
      </div>
    </div>
  </div>;
};

export default BalanceWidget;
