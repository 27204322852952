import React from 'react';
import './SearchBar.css'
import {FormGroup,Form} from "react-bootstrap";
import searchIconSvg from '../../../assets/svg/search.svg'
const SearchBar = () => {
  return <div id={'SearchBar'}>
    <FormGroup>
      <Form.Control
          size={"lg"}
          className={'rounded-pill'}
          placeholder={'Rechercher quelque chose'}
      />
      <img src={searchIconSvg} alt=""/>
    </FormGroup>
  </div>;
};

export default SearchBar;
