import React, {useState} from 'react';
import "./ReferCandidate.css"
import {Button, Col, Form, Modal} from "react-bootstrap";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import Select from "react-select";
import {uploadOnCdn} from "../../../service";
import {validateFile} from "../../../helpers/helper";
import { useTranslation } from 'react-i18next';
const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const ReferCandidate = (props) => {
    const { t } = useTranslation();
    const [email, set_email] = useState()
    const [firsname, set_firstname] = useState()
    const [lastname, set_lastname] = useState()
    const [comment, set_comment] = useState()
    const [url, set_url] = useState()
    const [lang, setLang] = useState()
    const lang_options = [{ label: t("french"), value: "fr" }, { label: t("english"), value: 'en' }];
    const [m_file,setMFile] = useState();
    const [m_file_name,setMFIleName] = useState();
    const [fileError,setFileError] = useState(false);
    const handleEmail = e => {
        set_email(e.target.value)
    }
    const handleFirstname = e => {
        set_firstname(e.target.value)
    }
    const handleLastname = e => {
        set_lastname(e.target.value)
    }
    const handleComment = e => {
        set_comment(e.target.value)
    }
    const handleUrl = e => {
        set_url(e.target.value)
    }
    const handleLang = e => {
        console.log("lang",e)
        setLang(e.value)
    }
    const handleFile = (e) => {
        if(e) {
            if (validateFile(e.target.files[0].type)){
                setFileError(false);
                let file_data = e.target.files[0]
                setMFile(file_data)
                setMFIleName(file_data.name)
            } else{
                setFileError(true)
            }
        }
    };
    const handleRefere = async e => {
        let o = {
            "email": email,
            "firstname": firsname,
            "lastname": lastname,
            "comment": comment,
            "recruitment": props.post._id,
            "cv_url": url,
            "language":lang,
        }
        if (m_file){
                let resp = await uploadOnCdn(m_file);
                console.log({resp})
            setMFile(resp?.filename);
            o.cv_url=resp?.filename;
        }
        props.action_refere(o)
        props.action_loading(true);
        props.onHide()
    }

    return <div id={'ReferCandidate'}>
        <Form.Group>
            <Form.Control
                size={"lg"}
                placeholder={t("email")}
                type={'email'}
                onChange={handleEmail}
            />
        </Form.Group>
        <Form.Group>
            <Form.Control
                size={"lg"}
                placeholder={t("first_name")}
                onChange={handleFirstname}
            />
        </Form.Group>
        <Form.Group>
            <Form.Control
                size={"lg"}
                placeholder={t("last_name")}
                onChange={handleLastname}
            />
        </Form.Group>
        <Form.Group>
            <Form.Control
                size={"lg"}
                as={'textarea'}
                placeholder={t("comment")}
                onChange={handleComment}
            />
        </Form.Group>
        <Form.Group>
            <Form.Control
                size={"lg"}
                placeholder={t("cv_url")}
                type={'url'}
                onChange={handleUrl}
            />
        </Form.Group>
        <Form.Group>
            <Select
                options={lang_options}
                styles={{
                    control: base => ({
                        ...base,
                        minHeight: 48
                    })
                }}

                theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: '#6600CC',
                        primary25: '#6600CC25',
                        primary75: '#6600CC75',
                        primary50: '#6600CC50'
                    },
                })}
                placeholder={t("language")}
                name='gender'
                onChange={handleLang}
            />

        </Form.Group>
        <Form.Group>
            <Form.Label>{t("upload_cv")}</Form.Label>
                <Form.File id="formcheck-api-custom" custom>
                    <Form.File.Input isInvalid={fileError}  onChange={handleFile} />
                    <Form.File.Label data-browse="Choisir">
                        {m_file_name}
                    </Form.File.Label>
                    <small hidden={!fileError} className={"text-danger"}>{t("invalid_file")}</small>
                </Form.File>
        </Form.Group>

        <div className={'p-3 text-center'}>
            <Button onClick={handleRefere}>{t("referenced")}</Button>
        </div>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferCandidate);
