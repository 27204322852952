import React, {useEffect, useState} from 'react';
import './SpecialEvent.css'
import {Button, Form, Modal} from "react-bootstrap";
import * as MD from 'react-icons/md'
import * as BS from 'react-icons/bs'
import * as BI from 'react-icons/bi'
import mSVG from '../../../assets/gender/m.png'
import {getFormatedDate} from "../../../helpers/helper";
import {getFileUrl} from "../../../service";
import Select from "react-select";
import { useTranslation } from 'react-i18next';
const SpecialEvent = (props) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [participants, setParticipants] = useState(false);
    const [interviewers, setInterviewers] = useState([]);
    let r = [];
    props.interview?.interviewers?.forEach((i)=>{r.push(i._id)});
    //setInterviewers(r);
    const handleClose = () => setShow(false);
    const handleSelect = (e) => {
        setParticipants(e)
    };
    const addParticipant = ()=>{
      let arr = [];
      participants.forEach((p)=>{arr.push(p._id)});

      let data = {participants:arr,interview:props?.interview._id}
      if (arr.length>0)
      props.addParticipant(data);
      setShow(null);
    };
    const cancelInterview = ()=>{
        props.cancelInterview(props.interview?._id)
        setShow(null);

    };
    const goToExternalLink = (lien) => {
        const url = lien,
              link = document.createElement('a');
        link.href = url
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
  }
    useEffect(() => {
      setInterviewers(r)
    }, []);
    const color = '#FF796B'
  return <div id={'SpecialEvent'} className={"col-12 col-lg-12"} style={{background: color+'0f'}}>
    <div className={'p-3 d-flex flex-column justify-content-between'} style={{width:"inherit"}}>
      <div>
        <div className={'title'} style={{color:color}}>{props.interview?.title}</div>
        <div className={'subTitle mt-4'} style={{color:color}}>{getFormatedDate(props.interview?.date)}</div>
      </div>
      <div className={"align-content-end d-flex flex-column w-100"}>
          <div className={"mt-3 display-inline"}><BI.BiUser size={24}/> {props.interview?.application?.firstname+ " "+props.interview?.application?.lastname}</div>
        <div className={'invitees float-right text-right p-2 align-items-center display-inline'}>
            {props.interview?.interviewers?.map(i =>
              i.image_url?<img title={i.firstname+" "+i.lastname} src={getFileUrl(i.image_url)} alt=""/>:<img title={i.firstname+" "+i.lastname} src={mSVG} alt=""/>
            )}
          <div className={'add'} hidden={props.interview?.status===-1} style={{background: color,cursor:'pointer'}} onClick={()=>{setShow('affecte')}}><BS.BsPlus size={24}/></div>
        </div>
        <div className={'actions d-flex align-items-center justify-content-between'}>
            {
                props.interview?.status===-1?
                    ''
                    :
                    <div className={'typeAction'} style={{color:color}} onClick={() => setShow("confirm")}>
                        <BI.BiBlock size={15}/> {t("cancel")}
                    </div>
            }
{
        props.interview && props.interview?.status!==-1 && props.interview.type && props.interview.type.includes('visio') && props.interview.visio ? 
          <div className={'d-flex align-items-center bottom pl-2'}
           onClick={()=>goToExternalLink(props.interview.visio.link)} 
           style={{background: color+'22',cursor:'pointer'}}>
            <div className={'type'} style={{background: color}}>
                {
                props.interview.type && props.interview.type.includes('visio') ? t("visio_conf_") : t("physic")
                }
                </div>
            <div className={'icon d-flex align-items-center justify-content-center'}><MD.MdCall/></div>
          </div>
          : ''
          }
        </div>
      </div>
    </div>
      <Modal centered show={show === 'affecte'} onHide={handleClose}>
          <Modal.Header className={"bg-primary"}>
              <h4 className={"col-lg-12 text-white text-center"}>{t("add_new_participants")} :</h4>
          </Modal.Header>
          <Modal.Body className={'p-4'}>
              <Form.Group>
                  <Select
                      options={props.colleagues?.filter((c)=>!interviewers.includes(c._id) && c.activated)}
                      getOptionLabel={o => o.lastname+' '+o.firstname}
                      getOptionValue={o => o._id}
                      styles={{
                          control: base => ({
                              ...base,
                              minHeight: 48,
                          })
                      }}
                      theme={theme => ({
                          ...theme,
                          colors: {
                              ...theme.colors,
                              primary: '#6600CC',
                              primary25: '#6600CC25',
                              primary75: '#6600CC75',
                              primary50: '#6600CC50'
                          },
                      })}
                      placeholder={t('participants')}
                      isMulti
                      onChange={handleSelect}
                  />
              </Form.Group>
          </Modal.Body>
          <Modal.Footer className={'border-0 pt-0'}>
              <Button variant="light" onClick={handleClose}>
                  {t("cancel")}
              </Button>
              <Button variant="primary" onClick={addParticipant}>
                  {t("add")}
              </Button>
          </Modal.Footer>
      </Modal>
      <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
          <Modal.Header closeButton className={'border-0'}>
              <Modal.Title>{t("confirmation")}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
              <div className={"text-center"}>
                 {t("notif_cancel_meet")}
              </div>
          </Modal.Body>
          <Modal.Footer className={'text-center border-0'}>
              <div>
                  <div className={"text-center"}>
                      <Button variant="primary" onClick={cancelInterview}>
                          {t("continous")}
                      </Button>
                      <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                          {t("cancel")}
                      </Button>
                  </div>
              </div>

          </Modal.Footer>
      </Modal>

  </div>;
};

export default SpecialEvent;
