import React from 'react';
import "./DashBestDep.css"
import {Col, ProgressBar, Row} from "react-bootstrap";
import {Doughnut, Line} from 'react-chartjs-2';
import 'chart.js'

const data = {
    labels: ['1', '2', '3', '4', '5', '6'],
    datasets: [
        {
            label: '# évolution',
            data: [12, 19, 3, 5, 2, 3],
            fill: false,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: '#fff',
        },
    ],
};
const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
};

const DashBestDep = () => {
    return <div id={"DashBestDep"}>
        <div className={'d-flex mb-4 align-items-center'}>
            <div className={"title"}>Meilleur département</div>
            <div style={{
                width: "128px",
                borderBottom: "3px solid #ffffff",
                opacity: 0.19,
                marginLeft: 15,
                marginRight: 15,
                marginBottom: -8
            }}/>
        </div>
        <div>
            <Col  style={{boxShadow:"0px 3px 30px #8b93a564",}}  xs={12} >
                <Line style={{borderColor:'#fff'}} data={data} options={options} />
            </Col>
        </div>
    </div>;
};

export default DashBestDep;
