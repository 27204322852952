import React, {useEffect, useState} from 'react';
import "./SearchPostes.css"
import Select from "react-select";
import * as HI from "react-icons/hi"
import * as BS from "react-icons/bs"
import connect from "react-redux/es/connect/connect";
import * as actions from "../../../actions";
import { Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}
const SearchPostes = (props) => {
    const { t } = useTranslation();
    useEffect(() => {
    }, []);
    const [cities,setCities] = useState();
    const [titles,setTitles] = useState();
    const [types,setTypes] = useState();
    const handleCities = (e)=>{
        console.log(e,'****')
        if (e) {
            let  arr = e.map((c)=>c.value)
            setCities(arr)
        }else{
            setCities(null)
        }
    }
    const handleTitles = (e)=>{
        console.log({e})
        if (e){
            let  arr = e.map((t)=>t.title)
            setTitles(arr)
        }else{
            setTitles(null)
        }
    }
    const handleTypes = (e)=>{
        if (e){
            let  arr = e.map((t)=>t.value)
            setTypes(arr)
        }else{
            setTypes(null)
        }
    }
    const handleSearch = ()=>{
        let o = {
            cities:cities,
            types:types,
            titles:titles
        }
        props.action_searchPost(o);
    }

    const posts = props.posts;
    return <div id={"SearchPostes"}>
        <div className={"d-flex align-items-center justify-content-between m-3"}>
            <div className={"triCompos d-flex align-items-center justify-content-between w-100 pl-4 pr-4"}>
                <div className={"d-flex align-items-center"}>
                    <BS.BsSearch color={"#6600CC"}/>
                    <Select noOptionsMessage={() => t("no_data_available")}
                        options={posts.filter(r=>r.status!=='archived')}
                        getOptionLabel={o => o.title}
                        getOptionValue={o => o._id}
                        isMulti
                        placeholder={t("position_name_text")}
                        onChange={handleTitles}
                        styles={{
                            option: (provided, state) => ({
                                ...provided,
                            }),
                            control: (provided, state) => ({
                                width: 200,
                                border:"solid 0",
                                display:"flex"
                            }),
                            multiValue: (provided, state) => ({
                                ...provided,
                                background:"white",
                                borderRadius:5,
                                border:"solid 1px #6600CC"
                            }),
                            multiValueLabel: (provided, state) => ({
                                ...provided,
                                color:"#6600CC"
                            }),
                            multiValueRemove: (provided, state) => ({
                                ...provided,
                                color:"#6600CC"
                            }),
                            dropdownIndicator: (provided, state) => ({
                               display:"none"
                            }),
                            indicatorSeparator: (provided, state) => ({
                               display:"none"
                            }),
                        }}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: '#6600CC25',
                                primary50: '#6600CC50',
                                primary75: '#6600CC75',
                                primary: '#6600CC',
                            },
                        })}
                    />

                </div>
                <div style={{margin: "0 10px",border: "solid 1px #A3A8B82B",height: 48}}/>
                <div className={"d-flex align-items-center"}>
                    <HI.HiOutlineLocationMarker color={"#6600CC"}/>
                    <Select noOptionsMessage={() => t("no_data_available")}
                        options={posts.filter(res=>res && res.status!=='archived' && res.city).map(
                            r=>
                            {
                              return {label: r.city,value: r.city}
                             }
                            )}
                        onChange={handleCities}
                        isMulti
                        styles={{
                            option: (provided, state) => ({
                                ...provided,
                            }),
                            control: (provided, state) => ({
                                width: 200,
                                border:"solid 0",
                                display:"flex"
                            }),
                            singleValue: (provided, state) => ({
                                ...provided,
                                color:"#6600CC",
                                border:"solid 0px"
                            }),
                            dropdownIndicator: (provided, state) => ({
                                display:"none"
                            }),
                            indicatorSeparator: (provided, state) => ({
                                display:"none"
                            }),
                        }}
                        isSearchable={false}
                        placeholder={t("city")}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: '#6600CC25',
                                primary50: '#6600CC50',
                                primary75: '#6600CC75',
                                primary: '#6600CC',
                            },
                        })}
                    />
                </div>
                <div style={{margin: "0 10px",border: "solid 1px #A3A8B82B",height: 48}}/>
                {/*<div className={"d-flex align-items-center"}>*/}
                {/*    <BS.BsBriefcase color={"#6600CC"}/>*/}
                {/*    <Select noOptionsMessage={() => t("no_data_available")}*/}
                {/*        options={[{label:"Asdadadasdasdas",value:Math.random()},{label:"dfs sfd f fs",value:Math.random()},{label:"Asdasdf das",value:Math.random()}]}*/}
                {/*        styles={{*/}
                {/*            option: (provided, state) => ({*/}
                {/*                ...provided,*/}
                {/*            }),*/}
                {/*            control: (provided, state) => ({*/}
                {/*                width: 200,*/}
                {/*                border:"solid 0",*/}
                {/*                display:"flex"*/}
                {/*            }),*/}
                {/*            singleValue: (provided, state) => ({*/}
                {/*                ...provided,*/}
                {/*                color:"#6600CC",*/}
                {/*                border:"solid 0px"*/}
                {/*            }),*/}
                {/*            dropdownIndicator: (provided, state) => ({*/}
                {/*                display:"none"*/}
                {/*            }),*/}
                {/*            indicatorSeparator: (provided, state) => ({*/}
                {/*                display:"none"*/}
                {/*            }),*/}
                {/*        }}*/}
                {/*        isSearchable={false}*/}
                {/*        placeholder={"Compétences"}*/}
                {/*        theme={theme => ({*/}
                {/*            ...theme,*/}
                {/*            borderRadius: 0,*/}
                {/*            colors: {*/}
                {/*                ...theme.colors,*/}
                {/*                primary25: '#6600CC25',*/}
                {/*                primary50: '#6600CC50',*/}
                {/*                primary75: '#6600CC75',*/}
                {/*                primary: '#6600CC',*/}
                {/*            },*/}
                {/*        })}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div style={{margin: "0 10px",border: "solid 1px #A3A8B82B",height: 48}}/>*/}
                <div className={"d-flex align-items-center"}>
                    <BS.BsAward color={"#6600CC"}/>
                    <Select noOptionsMessage={() => t("no_data_available")}
                        options={[{label:t("cdd"),value:"cdd"},{label: t("cdi"),value:"cdi"},{label:t("freelance"),value:"freelance"},{label:t("part_time"),value:"part_time"},{label:t("full_time"),value:"full_time"}]}
                        isMulti
                        onChange={handleTypes}
                        styles={{
                            option: (provided, state) => ({
                                ...provided,
                            }),
                            control: (provided, state) => ({
                                width: 200,
                                border:"solid 0",
                                display:"flex"
                            }),
                            multiValue: (provided, state) => ({
                                ...provided,
                                background:"white",
                                borderRadius:5,
                                border:"solid 1px #6600CC"
                            }),
                            multiValueLabel: (provided, state) => ({
                                ...provided,
                                color:"#6600CC"
                            }),
                            multiValueRemove: (provided, state) => ({
                                ...provided,
                                color:"#6600CC"
                            }),
                            dropdownIndicator: (provided, state) => ({
                                display:"none"
                            }),
                            indicatorSeparator: (provided, state) => ({
                                display:"none"
                            }),
                        }}
                        isSearchable={false}
                        placeholder={t("type")}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: '#6600CC25',
                                primary50: '#6600CC50',
                                primary75: '#6600CC75',
                                primary: '#6600CC',
                            },
                        })}
                    />
                </div>
            </div>
            <Button onClick={handleSearch} className={"valider"}>{t("validate")}</Button>
        </div>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPostes);
