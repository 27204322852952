import React from 'react';
import './ContextMenu.css'
import {Nav} from "react-bootstrap";
import {NavLink} from "react-router-dom";

const ContextMenu = (props) => {
  return <div id={'ContextMenu'}>
    <Nav defaultActiveKey="/home" as="ul">
      {props.menu.map(m=>
          <Nav.Item as="li" key={m.label}>
            {m.href && <NavLink to={m.href}>{m.label}</NavLink>}
            {m.eventKey && <Nav.Link eventKey={m.eventKey}>{m.label}</Nav.Link>}
          </Nav.Item>
      )}
    </Nav>
  </div>;
};

export default ContextMenu;
