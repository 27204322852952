import React, { useState } from 'react';
import './UploadPersoDoc.css'
import { BsFileEarmarkArrowUp } from "react-icons/bs";
import { Button, Modal } from "react-bootstrap";
import { MdAddAPhoto } from 'react-icons/md'
import FileViewer from 'react-file-viewer'
import Webcam from "react-webcam";
import { useTranslation } from 'react-i18next';

const UploadPersoDoc = (props) => {
  const { t } = useTranslation();
  const [playing, setPlaying] = useState(false);
  const webcamRef = React.useRef(null);
  const [show, setShow] = useState(false);
  const [captionImag, setCaptionImag] = useState('')
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      console.log(imageSrc)
      setCaptionImag(imageSrc)
      props.data.handler(imageSrc)
      handleClose(false)
    },
    [webcamRef]
  );
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };


  // fonctionne avec condition


  const testTake = function () {

    const HEIGHT = 500;
    const WIDTH = 500;

    const startVideo = () => {
      setPlaying(true);
      navigator.getUserMedia(
        {
          video: true,
        },
        (stream) => {
          let video = document.getElementsByClassName('app__videoFeed')[0];
          if (video) {
            video.srcObject = stream;
          }
        },
        (err) => console.error(err)
      );
    };

    const stopVideo = () => {
      setPlaying(false);
      let video = document.getElementsByClassName('app__videoFeed')[0];
      video.srcObject.getTracks()[0].stop();
    };

    return (
      <div>
        <div className="app__container">
          <video
            height={HEIGHT}
            width={WIDTH}
            muted
            autoPlay
            className="app__videoFeed"
          ></video>
        </div>
        <div className="app__input">
          {playing ? (
            <button onClick={stopVideo}>Arrêter</button>
          ) : (
              <button onClick={startVideo}>Démarrer</button>
            )}
        </div>
      </div>
    )

  }



  return (
    <div id={'UploadPersoDoc'}>
      <div className={'mt-3 title mb-2'}>{props.data.label}</div>
      <div className={'dropzone mb-3 d-flex flex-column align-items-center justify-content-around'}>
        {
          props.data.preview ? (
            <div style={{ width: 170, height: 170 }}>
              {
                props.data.type === 'pdf' ? (<FileViewer fileType={props.data.type} filePath={props.data.preview} onError={props.data.previewErrorHandler} />) : (<img src={props.data.preview} width="100%" height="100%" />)
              }
            </div>
          ) : (
              <>
                <div className={'text-center'}>
                  <label htmlFor={props.data.label}>
                    <BsFileEarmarkArrowUp size={42} color={'#A3A8B9'} />
                    <div className={'pl-4 pr-4'} style={{ fontSize: 10 }}>{t("import_file_by_click")} <span style={{ color: '#6600CC' }}>( {t("here")} )</span></div>
                  </label>
                  <input type="file" onChange={props.data.handler} id={props.data.label} style={{ display: 'none' }} />
                </div>
                <p style={{ fontSize: 10, color: '#6600CC' }}>{t("or")}</p>
                <div style={{ width: '150px' }}>
                  <Button variant={'purple'} onClick={handleShow} ><MdAddAPhoto /> <span style={{ fontSize: 10 }}>{t("take_picture")}</span></Button>
                </div>
              </>
            )
        }
      </div>

      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t("take_picture_of")} {props.data.label}</Modal.Title>
          </Modal.Header>
          <div className='mt-3 mb-3'>
            <Webcam
              audio={false}
              height={200}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={500}
              videoConstraints={videoConstraints}
            />

          </div>
          <Modal.Footer>

            <Button variant="primary" onClick={capture}>
              {t("save")}
          </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  )
};

export default UploadPersoDoc;
