import React, {useEffect, useState} from 'react';
import './Outtegration.css'
import { Button, Col, Form, Modal, Row, Dropdown} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {connect} from "react-redux";
import * as actions from "../../../actions";
import mSVG from "../../../assets/gender/m.png";
import * as RI from "react-icons/ri";
import * as BS from "react-icons/bs";
import * as moment from "moment";
import Select from "react-select";
import {getFileUrl, uploadOnCdn} from "../../../service";
import {useHistory} from 'react-router-dom'
import {getFormatedDate,handleValidation} from "../../../helpers/helper";
import {Document, Page} from "react-pdf/dist/umd/entry.webpack";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
  return {rwState: state}
}
const mapDispatchToProps = {...actions}

const Outtegration = (props) => {
  const { t } = useTranslation();
    const history = useHistory();
    const [show, setShow] = useState([])
  const [depart, setDepart] = useState()
  const [description, setDescription] = useState()
  const [employee, setEmployee] = useState({})
  const [isUpdate, setIsUpdate] = useState(false)
  const [current_row, setCurrentRow] = useState({})
  const [checkTransfer, setCheckTransfere] = useState(false)
  const [checkCoupure, setCheckCoupure] = useState(false)
  const [checkRemise, setCheckRemise] = useState(false)
  const [send_sondage, setSendSondage] = useState(false)
  const [depart_volontaire, setDepartVolontaire] = useState(false)
  const [lettreDemission, setLettreDemission] = useState(undefined)
  const [lettreDemissionName, setLettreDemissionName] = useState(t("resignation_letter_text"))
  const [readlettreDemission, setReadLettreDemission] = useState(undefined)
  const [formError, setFormError] = useState({})
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const resetDepartStatus= async  () => {
      setCheckTransfere(false)
      setCheckCoupure(false)
      setCheckRemise(false)
      setDescription('')
    }
    const resetDepart= async  () => {
      setFormError({})
      setEmployee({})
      setDepart('')
      setDescription('')
      setDepartVolontaire(false)
      setSendSondage(false)
      setLettreDemission(undefined)
      setLettreDemissionName(t("resignation_letter_text"))
      setReadLettreDemission(undefined)
    }
    const onDocumentLoadSuccess=({ numPages })=> {
        setNumPages(numPages);
    }
   const showLettreDemaission =(data)=>{
        setReadLettreDemission(data);
       setShow('lettre_demission')
   }
  let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
  let user = JSON.parse(window.localStorage.getItem('glowhr_user')).user;
  useEffect(() => {
      props.action_loading(true)
      props.action_listColleagues();
    props.action_listDepart();
      props.action_setTitle(t("departure_title"))
  }, [])
    const handleTransfer =(e)=>{
     !checkTransfer ?  setCheckTransfere(e.target.value) : setCheckTransfere(false)
            let o =current_row;
             o['tranfere']=checkTransfer;
             setCurrentRow(o);
    }
   const handleCoupure =(e)=>{
    !checkCoupure ?   setCheckCoupure(e.target.value) : setCheckTransfere(false)
       let o =current_row;
       o['coupure']=checkCoupure;
       setCurrentRow(o);
    }
   const handleRemise =(e)=>{
     !checkRemise  ?  setCheckRemise(e.target.value) : setCheckRemise(false)
       let o =current_row;
       o['remise']=checkRemise;
       setCurrentRow(o);
    }
   const handleSendSondage =(e)=>{
     setSendSondage(e.target.checked)
       let o =current_row;
       o['send_sondage']=send_sondage;
       setCurrentRow(o);
    }
   const handleDepartVolonataire =(e)=>{
     setDepartVolontaire(e.target.checked)
       let o =current_row;
       o['depart_volontaire']=depart_volontaire;
       setCurrentRow(o);
    }
  const handleDepart = (e) => {
    setDepart(e.target.value)
    let data =handleValidation(t,{type:"date",name:'depart','value':e.target.value,required:true,min:new Date()});
    let form=formError;
            form[data.name]=data   
            setFormError(form)
  }
 const handleDescription = (e) => {
    setDescription(e.target.value)
  }
  const handleEmployee = (e) => {
      setEmployee(e)
      let data =handleValidation(t,{type:"text",name:'employee','value':e._id,required:true});
      let form=formError;
              form[data.name]=data   
              setFormError(form)
  }
  const handleFile = (e) => {
    let file_data = e.target.files[0]
  if(file_data) {
    let data =handleValidation(t,{type:"text",name:'file','value':file_data.name,required:true});
    let form=formError;
            form[data.name]=data   
            setFormError(form)
      setLettreDemission(file_data)
      setLettreDemissionName(file_data.name)
  }
  };
 
  const createDepart= async  () => {
    let  file=lettreDemission;
    setLettreDemission(undefined)
    let o = {
      "employee_info": employee._id,
      "description": description,
      "date": depart,
      "sio_channel": "",
      "send_sondage":send_sondage,
      "depart_volontaire":depart_volontaire,
      "id": current_row?._id
    };
    if(file){
      uploadOnCdn(file)
          .then(resp => {
            setLettreDemission(resp?.filename);
            o.lettre_demission=resp?.filename;
            if (!isUpdate)
              props.action_createDepart(o)
            else
              props.action_updateDepart(o);
          })
    }else{
      if (!isUpdate)
        props.action_createDepart(o)
      else
        props.action_updateDepart(o);
    }
    setShow(null)
    resetDepart()
    props.action_loading(true)
  }
  const updateElement = (target,id,data)=>{
      setCurrentRow({input:target,_id:id,defaultData:data})
      setShow('updateElement')
  }
 const updateStatus = (target,id)=>{
      setCurrentRow({input:target,_id:id})
      setShow('depart')
  }

  const updateDepartElement=()=>{
      const o={_id:current_row._id}
      if(current_row.input==='date'){  o.date=depart
          props.action_updateDepart(o)
      }
      if(current_row.input==='lettre_demission') {
          let  file=lettreDemission;
          setLettreDemission(undefined)
          if(file){
              uploadOnCdn(file)
                  .then(resp => {
                      setLettreDemission(resp?.filename);
                      o.lettre_demission=resp?.filename;
                          props.action_updateDepart(o)
                  })
          }
      }
      setShow(null)
      resetDepart()
      props.action_loading(true)
  }

  const updateDepartStatus=()=>{
      const o={_id:current_row._id}
      if(current_row.input==='setDepart'){
          o.tranfere=checkTransfer;
          o.coupure=checkTransfer;
          o.remise=checkTransfer;
          o.description=description;
          props.action_updateStatusDepart(o)
      }
      setShow(null)
      resetDepartStatus()
      props.action_loading(true)
  }

  const deleteDepart=(o)=>{
      setCurrentRow(o)
      setShow('confirm')
  }
  const showDetails = (o)=>{
      setCurrentRow(o)
      setShow('details')
  }
  const setCloseDetails = ()=>{
      setCurrentRow({});
      setShow(null)
  }
const confirmDeleteDepart=()=>{
       props.action_deleteDepart(current_row);
    setShow(null)
    props.action_loading(true)
  }
    {/*<div style={{borderImage:"linear-gradient(to right, #EFC300 65%, #C7C7C7 35%) 4",borderBottom:"solid 3px", width:56}}> </div>*/}

    const outListColumns = [
    {
      name:t("name_and_profession"),
      selector:'firstname',
      cell: row => {
        return <div className={'d-flex align-items-center pt-3 mb-3'}>
          <div className={'mr-3'}>
            <img src={mSVG} style={{width:38,height:38}}/>
          </div>
          <div>
            <div>{row.employee_info.firstname + ' ' + row.employee_info.lastname}</div>
            </div>
        </div>
      }
    },
    {
      name:t("function"),
      selector:'role_info',
      cell: row => {
        return <div className={'colleagueInfo'}> {row.employee_info.position?.title} </div>
      }
    },
    {
      name:t("departure_date"),
      selector:'date',
      cell:row => <strong className={'text-primary'}>{moment(row.date).format('DD/MM/YYYY')}</strong>
    },
    {
      name: t("status"),
      cell: row =>
          <div>
              {!row.status ?
                  <Button onClick={() => updateStatus("setDepart", row._id)} className={"btn btn-light btn-sm"}>{t("validation_require_btn")}</Button>
                  :( !row.send_sondage ?
                  <div style={{
                      borderImage: "linear-gradient(to right, #17A324 99%, #C7C7C7 1%) 4",
                      borderBottom: "solid 3px",
                      width: 100
                  }}></div>
                  :( !row.survey_response ?<span className={"badge badge-light"}>{t("waiting_survey")}</span>
                              :<div style={{
                                  borderImage: "linear-gradient(to right, #17A324 99%, #C7C7C7 1%) 4",
                                  borderBottom: "solid 3px",
                                  width: 56
                              }}></div>)
                  )
              } </div>,
    },
   {
      name:'',
      cell: row =>
          <div className={"text-right"} style={{display:"inline-grid",width:"100%"}}>
              {!row.status ?
                  <Dropdown className={"float-right"}>
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                          <BS.BsThreeDotsVertical/>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                          <Dropdown.Item eventKey="1" onClick={e => history.push({pathname: '/dash/user/inout/out/' + row._id})}>{t("calendar_title")}
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2" onClick={() => updateElement('date', row._id, row.date)}>{t("update")}
                              {t("departure_date")}
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3" onClick={() => updateElement('lettre_demission', row._id)}>{t("update")} {t("resignation_letter_text")}
                          </Dropdown.Item>
                            <Dropdown.Item href="#/action-5" onClick={() => showLettreDemaission(row)}> {t("read_resignation_letter")}
                            </Dropdown.Item>
                          <Dropdown.Item href="#/action-4" onClick={() => deleteDepart(row)}>{t("delete")}</Dropdown.Item>
                      </Dropdown.Menu>
                  </Dropdown>
                  :
                  <Dropdown className={"float-right"}>
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                          <BS.BsThreeDotsVertical/>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                          <Dropdown.Item hidden={!row.survey_response} href="#/action-4" onClick={() => history.push(location.origin+'/dash/survey/'+row.survey_info)}>{t("survey_response")}</Dropdown.Item>
                          <Dropdown.Item eventKey="1" onClick={e =>  showDetails(row)}> {t("calendar_details")}
                          </Dropdown.Item>
                      </Dropdown.Menu>
                  </Dropdown>

              }
          </div>,
    }
  ]

  return <div id={'Outtegration'}>
     <Row>
        <Col>
          <Col className={"mb-4 pb-4"}>
            <Button onClick={e=>setShow('create')} className={"float-right"}>{t("new_departure")}</Button>
          </Col>
          <div className={'mb-4'}>
            {props.rwState.departs &&props.rwState.departs.length > 0 ?
            <DataTable
                title={t("list_departure_programmation")}
                noHeader
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                columns={outListColumns}
                data={props.rwState.departs}
                highlightOnHover
                pointerOnHover
                noDataComponent={t("no_departure_programmation")}
                paginationComponentOptions={
                  {
                   rowsPerPageText: t("line_per_page"),
                    rangeSeparatorText: t("for"),
                     noRowsPerPage: false,
                      selectAllRowsItem: false,
                      selectAllRowsItemText: t("all")} 
                  }
            /> : 
            <div className={'mt-3 bg-warning rounded text-white p-4 text-center'}>
              {t("no_departure_programmation")}
            </div>}
          </div>
          </Col>
      </Row>
    {/*Processus de départ*/}
    <Modal centered show={show === 'depart'} >
      <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
        <Modal.Title className='w-100'>{t("departure_processus_validation")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-4'}>
        <Form.Group>
          <Form.Label>{t("confirm_action_text")}</Form.Label> <br/>
            <div key={"defaultTransfere"} className="mb-2">
                <Form.Check type="checkbox" onChange={handleTransfer} id={"defaultTransfere"} aria-label="radio 1" value={true} label={t("knowledge_transfert")} />
            </div>
            <div key={"defaultCoupure"} className="mb-2">
            <Form.Check type="checkbox" id={"defaultCoupure"} onChange={handleCoupure} aria-label="radio 1" value={true} label={t("access_cut_off")} />
            </div>
            <div key={"defaultRemise"} className="mb-2">
            <Form.Check type="checkbox" id={"defaultRemise"} onChange={handleRemise} aria-label="radio 1" value={true} label={t("return_company_property")}/>
            </div>
        </Form.Group>
  <Form.Group>
          <Form.Label>{t("note_text")}</Form.Label> <br/>
          <Form.Control
              as={'textarea'}
              onChange={handleDescription}
              placeholder={'votre texte'}
              type={"text"}/>
  </Form.Group>
          <p>
              {t("clic_to_validate_departure")}
          </p>

      </Modal.Body>
      <Modal.Footer className={'border-0 pt-0'}>
        <Button variant="light" onClick={()=>setShow(null)}>
          {t("cancel")}
        </Button>
        <Button variant="primary" disabled={!checkRemise || !checkCoupure || !checkTransfer} onClick={updateDepartStatus}>
          {t("validate")}
        </Button>
      </Modal.Footer>
    </Modal>
 {/*Détails départ*/}
    <Modal centered show={show === 'details'} >
      <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
        <Modal.Title className='w-100'>{t("details_departure_process")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-4'}>

          {current_row.employee_info ?
                <div className="row">
              <h6 className={"col-md-12"}>{t("personnal_info_text")}</h6>
              <div className="col-md-6 mb-2">{t("last_name")} : <br/> <strong>{current_row.employee_info.firstname}</strong></div>
              <div className="col-md-6 mb-2">{t("first_name")} : <br/><strong> {current_row.employee_info.lastname} </strong></div>
              <div className="col-md-6 mb-2"> {t("department_title_unique")} : <br/> <strong> {current_row.employee_info.department ? current_row.employee_info.department.name : ""} </strong></div>
              <div className="col-md-6 mb-2"> {t("branch_title_unique")} : <br/> <strong> {current_row.employee_info.branch ? current_row.employee_info.branch.name : ""} </strong></div>
              <div className="col-md-6 mb-2"> {t("position_title_unique")} : <br/> <strong> {current_row.employee_info.position ? current_row.employee_info.position.title : ""} </strong></div>
              </div>
              : ''
          }
          <hr/>
          {current_row.employee_info ?
              <div className="row">
                  <h6 className={"col-md-12"}>{t("departure_process_information_title")}</h6>
                  <div className="col-md-12 mb-2">{t("resignation_letter_text")}
                      : <br/> <strong>{current_row.employee_info.firstname}</strong></div>
                  <hr/>
                  <div className="col-md-12 mb-2">{t("knowledge_transfert")}
                      : <br/> <strong> {getFormatedDate(current_row.transfere_connaissance.date)} </strong></div>
                  <div className="col-md-12 mb-2"> {t("access_cut_off")} : <br/> <strong> {getFormatedDate(current_row.coupure_access.date)} </strong></div>
                  <div className="col-md-12 mb-2"> {t("return_company_property")}
                      : <br/> <strong> {getFormatedDate(current_row.remise_affaire.date)} </strong></div>
                  <hr/>
                  <div className="col-md-12 mb-2"> {t("note_text")}
                      : <br/> <strong> {current_row.description} </strong></div>
                <div className="col-md-12 mb-2"> {t("departure_processus_validation")}
                      : <br/> <strong> {getFormatedDate(current_row.updated_date)} </strong></div>
              </div>
              : ''
          }

      </Modal.Body>
      <Modal.Footer className={'border-0 pt-0'}>
        <Button variant="light" onClick={setCloseDetails}>
          {t("cancel")}
        </Button>

      </Modal.Footer>
    </Modal>

    {/*  lettre de demission*/}
      <Modal centered show={show === 'lettre_demission'} size={"lg"}>
          {readlettreDemission ?
              <Modal.Header className='bg-primary text-white font-weight-bold text-center'>

                  <Modal.Title className='w-100'>{t("reading_resignation_letter")}
                      {readlettreDemission.employee_info.firstname} {readlettreDemission.employee_info.lastname}
                  </Modal.Title>

              </Modal.Header>
              :""
          }
          <Modal.Body className={'p-4'}>
              <div className={"col-12"}>
                  { readlettreDemission && readlettreDemission.lettre_demission
                      ?
                      <div className={"col-12 text-center"}>
                          <Document
                              className={"tdocs"}
                              loading={t("loading_init_text")}
                              width={"100%"}
                              file={getFileUrl(readlettreDemission.lettre_demission)}
                              onLoadSuccess={onDocumentLoadSuccess}
                          >
                              <Page pageNumber={pageNumber}/>
                          </Document>
                          <p className={"mt-3"}>
                              { pageNumber>1 ?
                                  <span className={"btn btn-light btn-sm mr-2"} onClick={() => {
                                      setPageNumber((pageNumber - 1))
                                  }}>{t("previous")}</span>
                                  : ""
                              }
                              Page {pageNumber} of {numPages}

                              { pageNumber < numPages ?
                                  <span className={"btn btn-light btn-sm ml-2"} onClick={() => {
                                      setPageNumber((pageNumber + 1))
                                  }}>{t("next")}</span>
                                  : ""
                              }
                          </p>
                      </div>
                      : <div className={"col-12 text-center p-5"}> {t("no_resignation_letter_found")} </div>
                  }

              </div>

          </Modal.Body>
          <Modal.Footer className={'border-0 pt-0'}>
              <Button variant="light" onClick={()=>setShow(null)}>
                  {t("cancel")}
              </Button>
          </Modal.Footer>
      </Modal>

      {/*create new user*/}
    <Modal centered show={show === 'create'} >
      <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
        <Modal.Title className='w-100'>{t("departure_management_title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-4'}>
          {props.rwState.colleagues && props.rwState.colleagues.filter(res=>  res.user!==user._id && !res.unemployed && res.onboarded && res.user ).length>0 ?
      <div>
        <Form.Group>
          <Form.Label>{t("choose_an_employee")}</Form.Label> <br/>
          <Select noOptionsMessage={() => t("no_data_available")}
              options={props.rwState.colleagues.filter(res=>  res.user!==user._id && !res.unemployed && res.onboarded && res.user)}
              getOptionLabel={o => o.lastname+''+o.firstname}
              getOptionValue={o => o._id}
              styles={{
                control: base => ({
                  ...base,
                  minHeight: 48,
                })
              }}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#6600CC',
                  primary25: '#6600CC25',
                  primary75: '#6600CC75',
                  primary50: '#6600CC50'
                },
              })}
              onChange={(e)=>handleEmployee(e)}
              placeholder={t("employee_text")}
              className={(formError && formError.employee && formError.employee.errors && !formError.employee.formIsValid) ? 'inputValidationError' : ''}
              />
                     {(formError && formError.employee && formError.employee.errors && !formError.employee.formIsValid) ? <span className={"validationError"}>{formError.employee.errors.employee}</span> : ''}
    </Form.Group>
          <Form.Group>
              <div key={"defaultDepartVolontaire"} className="mb-2">
                  <Form.Check type="checkbox" onChange={handleDepartVolonataire} id={"defaultDepartVolontaire"} aria-label="radio 1" value={true} label={t("volontary_departure")} />
              </div>

          </Form.Group>

          <Form.Group>
          <Form.Label>{t("insert_resignation_letter")}</Form.Label> <br/>
          <Form.File id="formcheck-api-custom" custom>
            <Form.File.Input onChange={handleFile} accept={".pdf"} 
             className={(formError && formError.file && formError.file.errors && !formError.file.formIsValid) ? 'inputValidationError' : ''}
          />
            <Form.File.Label data-browse="Choisir un fichier">
                {lettreDemissionName}
            </Form.File.Label>
            <Form.Control.Feedback type="valid">5MB</Form.Control.Feedback>
          </Form.File>
                    {(formError && formError.file && formError.file.errors && !formError.file.formIsValid) ? <span className={"validationError"}>{formError.file.errors.file}</span> : ''}
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("departure_date")}</Form.Label> <br/>
          <Form.Control
              onChange={handleDepart}
              min={new Date('today')}
              value={depart}
              type={"datetime-local"}
              className={(formError && formError.depart && formError.depart.errors && !formError.depart.formIsValid) ? 'inputValidationError' : ''}
              />
                     {(formError && formError.depart && formError.depart.errors && !formError.depart.formIsValid) ? <span className={"validationError"}>{formError.depart.errors.depart}</span> : ''}
     </Form.Group>
  <Form.Group>
          <Form.Label>{t("note_text")}</Form.Label> <br/>
          <Form.Control
              as={'textarea'}
              onChange={handleDescription}
              placeholder={t("have_you_an_appreciation_request")}
              type={"text"}/>
        </Form.Group>
          <Form.Group>
              <div key={"defaultSendSondage"} className="mb-2">
                  <Form.Check type="checkbox" onChange={handleSendSondage} id={"defaultSendSondage"} aria-label="radio 1" value={true} label={t("send_departure_survey")} />
              </div>
 </Form.Group>
              </div>
:
              <h4 className="col-12 text-center mt-5 pt-5">
                 {t("no_employee_available_for_departure")}
              </h4>
              }
      </Modal.Body>
      <Modal.Footer className={'border-0 pt-0'}>
        <Button variant="light" onClick={()=>setShow(null)}>
          {t("cancel")}
        </Button>
        <Button variant="primary"
        disabled={
          !formError?.file?.formIsValid || 
          !formError?.depart?.formIsValid ||
          !formError?.employee?.formIsValid
        }
        onClick={createDepart}>
          {t("save")}
        </Button>
      </Modal.Footer>
    </Modal>

  {/*Modification*/}
      <Modal centered show={show === 'updateElement'} >
          <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
              <Modal.Title className='w-100'>{t("change_of")}  {current_row.input}</Modal.Title>
          </Modal.Header>
          <Modal.Body className={'p-4'}>
              {current_row.input==='lettre_demission' ?
                  <Form.Group>
                      <Form.Label>{t("upload_resignation_letter")}</Form.Label> <br/>
                      <Form.File id="formcheck-api-custom" custom>
                          <Form.File.Input onChange={handleFile} accept={".pdf"}
                                       className={(formError && formError.file && formError.file.errors && !formError.file.formIsValid) ? 'inputValidationError' : ''}
     />
                          <Form.File.Label data-browse={t("choose_a_file")}>
                              {lettreDemissionName}
                          </Form.File.Label>
                          <Form.Control.Feedback type="valid">5MB</Form.Control.Feedback>
                      </Form.File>
                      {(formError && formError.file && formError.file.errors && !formError.file.formIsValid) ? <span className={"validationError"}>{formError.file.errors.file}</span> : ''}
                  </Form.Group>
                  : ''}
              {current_row.input==='date' ?
                  <Form.Group>
                      <Form.Label>{t("departure_date")}</Form.Label> <br/>
                      <Form.Control
                          onChange={handleDepart}
                          min={new Date('today')}
                          value={depart}
                          defaultValue={current_row.defaultData}
                          type={"datetime-local"}
                          className={(formError && formError.depart && formError.depart.errors && !formError.depart.formIsValid) ? 'inputValidationError' : ''}
                          />
                                 {(formError && formError.depart && formError.depart.errors && !formError.depart.formIsValid) ? <span className={"validationError"}>{formError.depart.errors.depart}</span> : ''}
                </Form.Group>
                  : ''}

          </Modal.Body>
          <Modal.Footer className={'border-0 pt-0'}>
              <Button variant="light" onClick={()=>setShow(null)}>
                  {t("cancel")}
              </Button>
              <Button variant="primary"
                disabled={
                  !formError?.depart?.formIsValid || !formError?.file?.formIsValid
                }
              onClick={updateDepartElement}>
                  {t("save")}
              </Button>
          </Modal.Footer>
      </Modal>

      {/*confirm*/}
      <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
          <Modal.Header closeButton className={'border-0'}>
              <Modal.Title>{t("confirmation")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className={"text-center"}>
                  {t("confirme_irreversile_deletion_text")}
              </div>
          </Modal.Body>
          <Modal.Footer className={'text-center border-0'}>
              <Button variant="primary" onClick={confirmDeleteDepart}>
                  {t("yes")}
              </Button>
              <Button variant="light" onClick={() => setShow(null)}>
                  {t("no")}
              </Button>
          </Modal.Footer>
      </Modal>


  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Outtegration);
