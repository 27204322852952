import React from 'react';
import './SuggestedHolidays.css'
import * as RI from 'react-icons/ri'
import * as BI from 'react-icons/bi'
import * as BS from 'react-icons/bs'
import {Button, Col, Row} from "react-bootstrap";
import Emitter from "../../../service/events";

const SuggestedHolidays = (props) => {

    return <div id={'SuggestedHolidays'}>
        <Row className={'p-4 d-flex align-items-center'}>
            <Col md={2} className={""}>
                <BS.BsCalendar className={"icon p-2 mr-2 "} size={45}/>
            </Col>
            <Col md={6} style={{margin:"5px"}}>
                <div className={'title mb-2'}>{props.leave.name}</div>
                <div className={'subtitle'}>{props.leave.days} jour(s)</div>
            </Col>
            {/*<Col md={1} className={"mr-2"}>
                <BS.BsPersonPlus size={32} color={'#FF796B'}/>
            </Col>*/}
            <Col md={2}>
                <Button  onClick={()=>Emitter.emit('edit-leave',props.leave)} variant={"light"} size={"sm"}><BI.BiEdit size={22}/></Button>
                <Button  onClick={()=>Emitter.emit('delete-leave',props.leave)} variant={"light"} size={"sm"}><BI.BiTrash size={22}/></Button>
            </Col>
        </Row>

    </div>;
};

export default SuggestedHolidays;
