import React, {useEffect, useState} from 'react';
import './VisioConf.css'
import {Button, Col, Row, Form, Modal, Container, Dropdown, Card} from "react-bootstrap";
import * as BS from 'react-icons/bs'
import * as BI from 'react-icons/bi'
import { getFormatedDay} from "../../../helpers/helper";
import {useHistory} from "react-router-dom";
import moment from "moment";
import { useTranslation } from 'react-i18next';

const VisioConf = (props) => {
  const { t } = useTranslation();
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    const history = useHistory();

    const color = '#6600CC'
  return <div id={'VisioConf'} className={'col-12'}>
    <div className={'p-4'} style={{width:"inherit"}}>
          <Dropdown className={"float-right manage-dropdown"}>
              <Dropdown.Toggle variant="white" id="dropdown-basic">
                  <BS.BsThreeDotsVertical/>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                  <Dropdown.Item onClick={props.edit}>
                      <BI.BiEdit style={{color: '#240265', fontWeight: '600',}} size={25}/> {t("update")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={ props.deleteVisio}>
                      <BI.BiTrash style={{color: 'red', fontWeight: '600',}} size={25}/> {t("delete")}
                  </Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>
          <div className={'title'} style={{color:color}}>{props.visio?.title}</div>
          <div className={'subTitle mt-3'} style={{color:color}}>
            <span style={{color: '#0e0e0e'}}>{t("program_for")} :</span> <br/>
           <div className={"mt-1"}> <span className={"mt-2"}>{getFormatedDay(props.visio?.conf_date)} {t("to_")} {props.visio?.conf_time}
             </span>
           </div>
              <br/>
              <span className={"badge bg-white mt-2"}>
              <BS.BsClock size={15}/>&nbsp;&nbsp;{props.visio.duration} min
            </span>
          </div>
          <Row className={"mt-2"} hidden={moment(props.visio.conf_date).isBefore()}>
              <Col className={"mt-2"}>
                  <Button variant={"light"}>{t("later")}</Button>
              </Col>
              <Col className={"mt-2"}>
                  <Button disabled={moment(props.visio.conf_date).isBefore()} onClick={e => window.open(props.visio.link,'_blank')
                      /*history.push('/dash/conference/'+props.visio?._id)*/} >{t("join")}</Button>
              </Col>
          </Row>

    </div>
  </div>;
};

export default VisioConf;
