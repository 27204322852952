import React, {useEffect, useState} from 'react';
import './CurrentUser.css'
import mSvg from '../../../assets/gender/m.png'
import fSvg from '../../../assets/gender/f.png'
import {Col,Dropdown} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {getFileUrl} from "../../../service";
import {limitText} from "../../../helpers/helper";
import { useTranslation } from 'react-i18next';

const CurrentUser = () => {
  const { t } = useTranslation();
  const [data,setData] = useState();
  const [ppImage,setPpImage]=useState(mSvg)
  const logout = () => {
    window.localStorage.removeItem('glowhr_user')
    window.location='/auth'
  }
  const goToProfile = () => {
  window.location='/dash/profile' 
  }
    useEffect(()=>{
        let data = JSON.parse(window.localStorage.getItem('glowhr_user'));
        setData(data)
        setPpImage(data?.user.image_url.length>0?getFileUrl(data?.user.image_url):ppImage)

    },[window.localStorage.getItem('glowhr_user')])
  return <div id={'CurrentUser'}>
      <div className="d-flex align-items-center">
        <div className={'position-relative'}>
          <img src={ppImage} alt="" className={'rounded-circle'} height={37} width={37}/>
          <div className={'userRole'}>*</div>
        </div>
        <div className={'ml-3 d-none d-sm-none d-md-block'}>
        <Dropdown>
            <Dropdown.Toggle  id="dropdown-custom-components" variant="light" style={{background: 'transparent',textAlign:'justify',border:'none'}}>
            <div className={'userName'} title={data?.user.firstname + ' ' +data?.user.lastname || 'John '+ 'Doe'}>{ limitText(data?.user.firstname + ' ' +data?.user.lastname || 'John '+ 'Doe',15)} </div>
          <div className={'userPoste'}>{data?.user.role_info.name || 'Super Admin'}</div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="1" onClick={goToProfile}>{t("employee_profil")}</Dropdown.Item>
              <Dropdown.Item eventKey="2" onClick={logout}>{t("logout")}</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
      
         {/*  <div className={'userName'} title={data?.user.firstname + ' ' +data?.user.lastname || 'John '+ 'Doe'}>{ limitText(data?.user.firstname + ' ' +data?.user.lastname || 'John '+ 'Doe',15)} </div>
          <div className={'userPoste'}>{data?.user.role_info.name || 'Super Admin'}</div>
         */}
         </div>
         <div className={"ml-1 d-none d-sm-none d-md-block"}>
         <a className="nav-link" href="/dash" title="Quitter" onClick={logout}>
                    <div id={'power'}>
                      <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-power" fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M5.578 4.437a5 5 0 1 0 4.922.044l.5-.866a6 6 0 1 1-5.908-.053l.486.875z"/>
                        <path fillRule="evenodd" d="M7.5 8V1h1v7h-1z"/>
                      </svg>
                    </div>
                  </a>
         </div>
      </div>
     
  </div>;
};

export default CurrentUser;
