import React, {useEffect, useState} from 'react';
import {useParams,useHistory} from 'react-router-dom'
import "./SurveyResponse.css"
import {Form,  Button, Col, Container, Row, Badge, ListGroup, ProgressBar} from "react-bootstrap";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import * as RI from "react-icons/ri";
import * as BI from "react-icons/bi";
import * as BS from "react-icons/bs";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import FormControl from "@material-ui/core/FormControl/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Radio from "@material-ui/core/Radio/Radio";
import Emitter from "../../../service/events";
import env from "../../../env";
import axios from "axios";
import { useTranslation } from 'react-i18next';

axios.defaults.withCredentials = true;
const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const SurveyResponse = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    let { id } = useParams();
    let params_id=id;

    useEffect(()=>{
        props.action_loading(true);
        props.action_oneSurvey(id);
        props.action_setTitle(t("survey_answer"))
        if (!props.survey)
            props.action_loading(true);
        else
            props.action_loading(false);
        if (props.rwState.survey)
            setQuestions(props.rwState.survey.questions)
        if (props.rwState.survey.questions){
            let arr = [];
            props.rwState.survey?.questions.forEach((v)=>{
                let ansValue = {};
                ansValue[v._id] = [];
                arr.push(ansValue)
            });
            setAnswers(arr);
        }

        Emitter.on('submit-response-received',(datas)=>{
            setSuccess(datas.error);
        });

    },[]);


    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    const [show, setShow] = useState([])
    const [answers, setAnswers] = useState([])
    const [title, setTitle] = useState()
    const [curentSurvey, setcurentSurvey] = useState()
    const [question, setQuestion] = useState()
    const [questions, setQuestions] = useState()
    const [propositions, setPropositions] = useState(0)
    const [status, setStatus] = useState()
    const [comment, setComment] = useState();
    const [current_row, setCurrentRow] = useState();
    const [selectedNote, setSelectedNote] = useState(0);
    const [isUpdate, setHasUpdate] = useState(false);
    const q_types = [
        { label: t("qcm_title"), value: "qcm" },
        { label: t("qcu_title"), value: "qcu" },
        { label: t("free_response_title"), value: 'response' },
        { label: t("true_or_false_title"), value: 'true_or_false' },
        { label: t("yes_or_no_title"), value: 'yes_or_no' },
        { label: 'Note', value: 'note' },
    ];
    const [seletectedType, setSelectedType] = useState(q_types[0]);
    const [type, setType] = useState(seletectedType.value);
    const [success,setSuccess]=useState(true);
    const [inputList, setInputList] = useState([]);

    const [pos, setPos] = useState(0);
    const handleClose = () => {
        setShow(null)
    }

    const handleTitle = (e) => {
        setTitle(e.target.value)
    };

    const handleTypeSelect = (e) => {
      //  console.log('type ==> ',e.target.value)
        setType(e.value)
    };
    const handleComment = (e) => {
        setComment(e.target.value)
    };
    const handlePropositions = (e,position) => {
        if (inputList.length<=4){
            const list = [...inputList];
            list[position] = e.target.value;
            setInputList(list);
        }
    };
    const  preselectType= (val)=>{
      let count=-1,number=undefined;
        q_types.forEach(r=>{
            count++;
            if(r.value===val) number=count;
        })
      return number;
    };
    const handleQuestion = (e) => {setQuestion(e.target.value)};
    const editSurvey = (survey)=>{
        setCurrentRow(survey);
      let type_number =  preselectType(survey.type)
        setQuestion(survey.question);
        setSelectedType(q_types[type_number]);
        setType(survey.type)
        // setPropositions(survey.propositions);
        setInputList(survey.propositions)
        setHasUpdate(true);
        setShow('create')
    };
    const deleteSurveyConfirm = (survey)=>{
        setcurentSurvey(survey);
        setShow('confirm')
    };
    const reinializeSurveyForm = ()=>{
        setQuestion('');
        setSelectedType(q_types[0]);
        setType(seletectedType.value);
        setInputList([]);
    }
    const showSurveyQuestion = (survey)=>{
      setcurentSurvey(survey)
        setShow('details')
    };
    const addNewProp = ()=>{
        if (inputList.length<=3){
            setInputList([...inputList, inputList.length]);
            console.log(inputList)
        }
    };
    const removeProp = index => {
       const list = [...inputList];
        list.splice(index-1, 1);
        setInputList(list);
    };

    const handleNextBtn = (pos) => {
        setPos(pos)
    };
    const handleAnswer = (e,question,index) => {
        let key = question._id;
        let tempAnswers= answers;
        if(!tempAnswers[index]){
            tempAnswers[index] = {};
            tempAnswers[index][key] = [];
            setAnswers(tempAnswers)
        }
        let tempAnsArr =  tempAnswers[index][key];
        console.log(index,tempAnswers)
        console.log({tempAnsArr})
        if (question.type==="note")                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       if (question.type==="note")
            setSelectedNote(e.target.value+1)
        if (tempAnsArr && !tempAnsArr.includes(e.target.value)){
            if (question.type==="qcm")
            tempAnsArr.push(e.target.value);
            else
                tempAnsArr[0] = e.target.value
        } else{
            if (tempAnsArr) 
            tempAnsArr = tempAnsArr.filter(a=>a!==e.target.value)
        }
        tempAnswers[index][key] = tempAnsArr;
        setAnswers(tempAnswers)
    };

    const submitAnswers = ()=>{

        let datas = {
            answers : answers,
            comment : comment,
            survey:props.rwState.survey
        };
        props.action_submitSurvey(datas);
        props.action_loading(true);
    }

    const progressStage = ()=>(pos/props.rwState.questions?.length)*100;
    const viewBuilder = (q,index)=>{
        let view = "";
        switch(q.type){
            case "note":
                view = (
                    <Row className={"mb-5 mt-5"}>
                        {
                            Array.apply(null, {length: 10}).map((Number,N)=>{
                                let e = {target:{value:N+1}};
                                return  <Col><Button onClick={()=>{handleAnswer(e,q,index)}}  className={selectedNote<=(N+1)?"unselected-btn":"selected-btn"} variant={'light'}>{1+N}</Button></Col>
                            })
                        }
                    </Row>
                )
                break;
            case "qcm":
                view = (
                    <div>
                        {
                            q.propositions.map((value,i)=>{ console.log(q._id,index); return (
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value={value}
                                                name={q._id+t("proposition")+i}
                                                color="primary"/>
                                        }
                                        label={value}
                                        defaultValue={""}
                                        onChange={(e)=>{handleAnswer(e,q,index)}}
                                    />

                                </div>
                            )})
                        }
                        </div>
                );
                break;
            case "qcu":
                view = (
                    <FormControl component="fieldset" className={"text-left ml-4"}>
                        <RadioGroup>
                            {
                                q.propositions.map((value)=>{return(
                                    <FormControlLabel  value={value} control={<Radio color={"primary"} />} label={value} onChange={(e)=>{handleAnswer(e,q,index)}}/>
                                )})
                            }
                        </RadioGroup>
                    </FormControl>
                );
                break;
            case "true_or_false":
                view = (
                    <FormControl component="fieldset" className={"text-left ml-4"}>
                        <RadioGroup>
                            <FormControlLabel  value="Vrai" control={<Radio color={"primary"} />} label={t("true")} onChange={(e)=>{handleAnswer(e,q,index)}}/>
                            <FormControlLabel  value="Faux" control={<Radio color={"primary"} />} label={t("false")} onChange={(e)=>{handleAnswer(e,q,index)}}/>
                        </RadioGroup>
                    </FormControl>
                );
                break;
            case "yes_or_no":
                view = (
                    <FormControl component="fieldset" className={"text-left ml-4"}>
                        <RadioGroup>
                            <FormControlLabel  value="Oui" control={<Radio color={"primary"} />} label={t("yes")} onChange={(e)=>{handleAnswer(e,q,index)}} />
                            <FormControlLabel  value="Non" control={<Radio color={"primary"} />} label={"no"} onChange={(e)=>{handleAnswer(e,q,index)}} />
                        </RadioGroup>
                    </FormControl>
                );
                break;
            case "response":
                view = (
                    <div className={"mb-4"}>
                        <Form.Control
                            onChange={(e)=>{handleAnswer(e,q,index)}}
                            as={"textarea"}
                            placeholder={t("your_answer")}
                        />
                    </div>
                );
                break;
        }
        return view;
    };
    const goupResponses = (responses)=>{
        //const arr = ["foo", "bar", "foo", "bar", "bar", "bar", "zoom"];
        const data = Object.assign({}, ...Array.from(new Set(responses), key => ({[key]: responses.filter(value => value === key).length})));
        return data;
    }
    const buildResponseView = (q)=>{

        switch (q.type) {
            case 'qcm':
            case 'qcu':
                return (
                    <ListGroup variant="flush">
                        {
                            q.propositions.map((p)=>{
                                return   (
                                    <ListGroup.Item>{p} <Badge variant={"primary"} className={"float-right"}><BS.BsFillPeopleFill/> {goupResponses(q.responses)[p]||0}</Badge></ListGroup.Item>
                                )
                            })
                        }
                    </ListGroup>
                );

            case 'note':
                return (
                    <ListGroup variant="flush">
                        {
                            Array.apply(null, {length: 10}).map((Number,N)=>{
                                let total = goupResponses(q.responses)[N+1]||0;
                                return   (
                                    <ListGroup.Item hidden={total===0}>Note de {N+1} <Badge variant={"primary"} className={"float-right"}><BS.BsFillPeopleFill/> {total}</Badge></ListGroup.Item>
                                )
                            })
                        }
                    </ListGroup>
                );
            case 'true_or_false':
                return (
                    <ListGroup variant="flush">
                        <ListGroup.Item>{t("true")} <Badge variant={"primary"} className={"float-right"}><BS.BsFillPeopleFill/> {goupResponses(q.responses)['Vrai']||0}</Badge></ListGroup.Item>
                        <ListGroup.Item>{t("false")} <Badge variant={"primary"} className={"float-right"}><BS.BsFillPeopleFill/> {goupResponses(q.responses)['Faux']||0}</Badge></ListGroup.Item>
                    </ListGroup>
                )
            case 'yes_or_no':
                return (
                    <ListGroup variant="flush">
                        <ListGroup.Item>{t("yes")} <Badge variant={"primary"} className={"float-right"}><BS.BsFillPeopleFill/> {goupResponses(q.responses)['Oui']||0}</Badge></ListGroup.Item>
                        <ListGroup.Item>{t("no")} <Badge variant={"primary"} className={"float-right"}><BS.BsFillPeopleFill/> {goupResponses(q.responses)['Non']||0}</Badge></ListGroup.Item>
                    </ListGroup>
                )
            case 'response':
                return(
                    <ListGroup variant="flush">
                        {
                            q.responses.map((r)=>{
                                return   (
                                    <ListGroup.Item>{r} </ListGroup.Item>
                                )
                            })
                        }
                    </ListGroup>
                )
        }
    }
    const goTo=(link)=>{
        window.location.href=window.location.origin+'/'+link;
    }
    return <div id={"SurveysDetails"}>
        <Container fluid>

            <Row>
                <div className="offset-lg-2 col-lg-8 col-12 mt-4 mb-4" style={{backgroundColor:"#f8f8f8",borderRadius:"20px"}}>
                    <Col sm={"12"} className={'text-left mb-3 mt-4'} hidden={!success}>
                        <h2>{t("departure_questions")}</h2>
                        <p>{t("departure_questions_label")}</p>

                    </Col>
                {
                    props.rwState.questions &&
                        <>
                             <Col hidden={!success}>
                                <div>

                                    <span style={{color:"#6600CC"}}>
                                        {pos<props.rwState.questions?.length ? <>{t("question")} {pos<props.rwState.questions?.length?pos+1:pos}</> : <>{t("comments")}</>}
                                        </span>
                                    <br/>
                                    <div  style={{marginLeft:progressStage()-2+"%",color:"#6600CC"}}>
                                        <BS.BsFillCaretDownFill/>
                                    </div>
                                    <ProgressBar style={{maxHeight:"5px",}} variant={"primary"} now={progressStage()}/>
                                </div>
                                <div className={"mt-4"}>
                                    {
                                    props.rwState.questions?.map((q,index)=>{
                                        return (
                                            <div className={"col-md-12"} style={{margin:"auto"}} hidden={pos!==index}>
                                                <h3 className={"text-primary"}>{q.question}</h3>
                                                <div className={"text-left"}>
                                                    {  viewBuilder(q,index)}
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                    {<div className={"col-md-12 mb-4"} style={{margin: "auto"}} hidden={pos !== props.rwState.questions?.length}>
                                        <h3 className={"text-primary mb-4"}>{t("leave_a_comment")}</h3>
                                        <Form.Control
                                            onChange={handleComment}
                                            as={"textarea"}
                                            placeholder={t("leave_a_comment")}
                                        />
                                    </div>
                                    }
                                </div>
                                <br/>
                                <div className={"col-lg-12 mt-3 mb-4"}>

                                            <Button className={"btn btn-dark m-2"} size={"sm"} onClick={e => {goTo('')}}>
                                                <BI.BiWindowClose/> {t("cancel")}
                                            </Button>
                                            <Button hidden={pos===0} className={"m-2"} variant="light" size={"sm"}  onClick={()=>{setPos(pos-1)}}>
                                                <BS.BsChevronLeft/> {t("previous")}
                                            </Button>
                                            <Button hidden={pos===props.rwState.questions?.length} className={"m-2"} variant="primary" size={"sm"}  onClick={()=>{handleNextBtn(pos+1)}}>
                                                {t("next")} <BS.BsChevronRight/>
                                            </Button>
                                            <Button   hidden={pos<props.rwState.questions.length} className={"m-2"} variant="primary" size={"sm"}  onClick={submitAnswers}>
                                                <BS.BsCheck/> {t("validate")}
                                            </Button>
                                </div>
                            </Col>
                            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '70vh',padding:"25px",textAlign:"center"}} hidden={success}>
                                <h4>{t("succesfully_submit_response_text")} <br/> {t("see_you_soon")}</h4>
                            </div>
                        </>
                }
                </div>
            </Row>
        </Container>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyResponse);
// export default SurveyResponse;
