import React, {useEffect, useState} from 'react';
import './Organigram.css'
import { Col, Container, Row} from "react-bootstrap";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import OrgGraph from "../../widgets/OrgGraph";
import {getFileUrl} from "../../../service";
import mSvg from "../../../assets/gender/m.png";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
};
const mapDispatchToProps = {...actions};

const Organigram = (props) => {
    const { t } = useTranslation();
    const [org,setOrg] = useState([])

    useEffect(() => {
       // props.action_organigram()
         props.action_listColleagues();
        props.action_setTitle(t("organization_chart_title"))
    }, []);

    const  convert =(rows)=>{

        function exists(rows, Parent) {
            for (var i = 0; i < rows.length; i++) {
                if (rows[i].key == Parent) return true;
            }
            return false;
        }
        var nodes = [];
        // get the top level nodes
        for (var i = 0; i < rows.length; i++) {
            var row = rows[i];
            if (!exists(rows, row.parent)) {
                nodes.push({
                    key: row.key,
                    name: row.name,
                    title:row.title,
                    image_url:row.image_url
                });
            }
        }
        var toDo = [];
        for (var i = 0; i < nodes.length; i++) {
            toDo.push(nodes[i]);
        }
        while (toDo.length) {
            var node = toDo.shift();
            // the parent node
            // get the children nodes
            for (var i = 0; i < rows.length; i++) {
                var row = rows[i];
                if (row.parent == node.key) {
                    var child = {
                        key: row.key,
                        name: row.name,
                        title:row.title,
                        image_url:row.image_url
                    };
                    if (node.children) {
                        node.children.push(child);
                    } else {
                        node.children = [child];
                    }
                    toDo.push(child);
                }
            }
        }
        return nodes;
    };
    useEffect(() => {
        if(props.rwState.colleagues && props.rwState.colleagues.filter(r=>r.activated) && props.rwState.colleagues.filter(r=>r.activated).length >0){
           let organogram=[];
           props.rwState.colleagues.filter(r=>r.activated).forEach((e)=>{
                let row = {};
                row.key = e._id;
                row.image_url = e.image_url && e.image_url!=="" ? getFileUrl(e.image_url) : mSvg;
                row.title = e.position?e.position.title:"";
                row.name = e.firstname+ " "+e.lastname;
                if (e.job_info && e.job_info[0] && e.job_info[0].reports_to) {
                    row.parent = e.job_info[0].reports_to
                }
                organogram.push(row)
            })
            setOrg(convert(organogram))
        }
      /*  if(props.rwState.colleagues){
            console.log('************** orgs set')
            let employees=props.rwState.colleagues.filter(r=>r.activated),orgs=[],newEmployeeList=[];
            let i=0;
            for(const employee of employees.reverse()){
                console.log(employee,'**** employee')
                orgs[i]=  {
                    id:employee._id,
                    "name": employee.firstname+' '+ employee.lastname,
                }
                let childs=employees.reverse().filter(r=>r.job_info[0]?.reports_to===employee.user)
                console.log(childs,'*********** childs data')
                if(childs){
                    for (const child of childs){
                        let indx= employees.indexOf(child)
                        employees.splice(indx, 1);
                        orgs[i].children=[{id:child._id,name:child.firstname+' '+ child.lastname }]
                    }
                }
            i++;
            }
            console.log(orgs,'************** orgs')
            setOrg(orgs)
        }*/
    }, [props.rwState.colleagues]);

    return <div id={'Branches'}>
        <Container fluid>
                       <Row>
                            <Col>
                                {org && org.length>0 ? <OrgGraph employee={org}/> : ''}
                            </Col>
                        </Row>
        </Container>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Organigram);
