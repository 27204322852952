import {Button,Dropdown, Col, Row} from "react-bootstrap";
import * as BS from "react-icons/bs";
import * as BI from "react-icons/bi";
import {limitText} from "../../../helpers/helper";
import {getFileUrl} from "../../../service";
import mSVG from "../../../assets/gender/m.png";
import React from "react";
import "./Goal.css"
import {useHistory} from "react-router-dom"
import { useTranslation } from 'react-i18next';

function Goal(props) {
    const { t } = useTranslation();
    const history = useHistory();
    return <div className={"goal-row"}>
        <Row>
        <Col md={1} className={"text-right"}>
            <Dropdown className={"float-right"}>
                        <Dropdown.Toggle variant="white" id="dropdown-basic">
                            <BS.BsThreeDotsVertical/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={e=>history.push('/dash/performance/'+props.goal._id)}>
                                <BS.BsFillEyeFill style={{color: '#240265', fontWeight: '600',}} size={25}/> {t("show")}
                            </Dropdown.Item>
                           <Dropdown.Item onClick={props.edit}>
                                <BI.BiEdit style={{color: '#240265', fontWeight: '600',}} size={25}/> {t("update")}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={props.deleteGoal}>
                                <BI.BiTrash style={{color: 'red', fontWeight: '600',}} size={25}/> {t("delete")}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
             </Col>
     
          {/*   <Col md={1}>
                <BS.BsFillAwardFill className={"award-icon"} size={40} color={"white"}/>
            </Col> */}
            <Col md={8} className={""}>
                <h6>{props.goal.title}</h6>
                <p className={"mb-0 pb-0"}>{limitText(props.goal.description,20)}</p>
            </Col>

            <Col md={3} >
                <div className={'invitees  text-right p-2 align-items-center'}>
                    {props.goal?.employees?.map(e =>
                        e.image_url
                        ?<img title={e.firstname+ " "+e.lastname} src={getFileUrl(e.image_url)} alt=""/>
                        :<img title={e.firstname+ " "+e.lastname}  src={mSVG} alt=""/>
                    )}
                </div>

        </Col>
            

        </Row>
    </div>;
}

export default Goal;