import React, { useCallback, useEffect } from 'react';
import './style.scss'
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Backdoor from "./components/Backdoor";
import Dashboard from "./components/Dashboard";
import Lost from "./components/Lost";
import Auth from "./components/Auth";
import Activation from "./components/Activation";
import SocketContainer from "./components/SocketContainer";
import Loading from "./components/Loading";
import io from "socket.io-client";
import { useDispatch } from 'react-redux'
import JoinCompany from './components/JoinCompany'
import RecruitmentApplication from "./components/RecruitmentApplication/RecruitmentApplication";
import { Notifications } from 'react-push-notification';
import SurveyResponse from "./components/sections/SurveyResponse";
import ExpireDiscount from "./components/widgets/expireDiscount";
import {isAdmin} from "./helpers/helper";
import consts from './consts'

function App(props) {
          const socket = io(consts.baseUrl,{
         //const socket = io('https://api-dev.glow-hr.com',{
        //path:"/glow"
    });
    const dispatch = useDispatch()
    const updateSocketInstance = useCallback(socket => dispatch({ type: 'UPDATE_SOCKET_INSTANCE', payload: socket }), [dispatch])

    useEffect(() => {
        updateSocketInstance(socket);
        return () => {
          /** Clear Socket Instance */
          socket.removeEventListener();
          socket.close();
        };
      }, [updateSocketInstance, socket]);

    return (
        <div className={'AppShell'} id={'AppShell'}>

            <div className="app">
                <Notifications />
            </div>
            <Router>
                <Switch>
                    <Route exact path="/">  
                        {/*<Welcome/>*/}
                        <Dashboard />
                    </Route>
                    <Route path="/backdoor">
                        <Backdoor />
                    </Route>
                    <Route path="/auth">
                        <Auth />
                    </Route>
                    <Route path="/activate">
                        <Activation />
                    </Route>
                    <Route path="/recruitment/application/:recruitment_slug">
                        <RecruitmentApplication />
                    </Route>
                    <Route path="/surveys/response/:id">
                        <SurveyResponse />
                    </Route>
                    <Route path="/dash">
                        <Dashboard />
                    </Route>
                    <Route path="/join-company/:key">
                        <JoinCompany />
                    </Route>
                    <Route path="*">
                        <Lost />
                    </Route>
                </Switch>
            </Router>
            <ToastContainer />
            <SocketContainer socket={socket} />
            <Loading />
        </div>
    );
}

export default App;
