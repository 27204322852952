import React, {useEffect, useState} from 'react';
import "./Performances.css"
import {Tab, Nav, Form, Modal, Button, Col, Container, Row, Card, Spinner, Badge} from "react-bootstrap";
import ContextMenu from "../../menus/ContextMenu";
import mSVG from "../../../assets/gender/m.png";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import {BsPlus} from "react-icons/bs";
import Select from "react-select";
import {formatDate, parseDate} from "react-day-picker/moment";
import SingleSurvey from "../../widgets/SingleSurvey/SingleSurvey";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
import {limitText,
    handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
import {getFileUrl} from "../../../service";
import Goal from "../../widgets/Goal/Goal";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
};
const mapDispatchToProps = {...actions};

const Performances = (props) => {
    const { t } = useTranslation();
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    const [show, setShow] = useState([])
    const [duration, setDuration] = useState()
    const [title, setTitle] = useState()
    const [status, setStatus] = useState()
    const [description, setDescription] = useState()
    const [current_row, setCurrentRow] = useState()
    const [isUpdate, setHasUpdate] = useState(false)
    const surveys_types = [{ label: t("qcm_title"), value: "qcm" }, { label: t("notes"), value: 'note' },]
    const [type, setType] = useState(surveys_types[0])
    const [obs, setObs] = useState()
    const [to, setTo] = useState()
    const [from, setFrom] = useState()
    const [employees, setEmployees] = useState([])
    const [selectedEmployees, setSelectedEmployees] = useState([])
    const [findEmployees, setFindEmployees] = useState([])
    const [search, setSearch] = useState()
    const [formError, setFormError] = useState({})

    const modifiers = {start: from, end: to};
    const history = useHistory();

    useEffect(() => {
        props.action_loading(true);
        props.action_listSurveysReview();
        props.action_listGoals();
        props.action_listColleagues();
        props.action_setTitle(t("performances"))
        let data =handleValidation(t,{type:"text",name:'typeSelect','value':type.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }, []);
    const handleClose = () => {
        if (isUpdate){
            setHasUpdate(false);
            setCurrentRow(null);
        }
        setShow(null)
        setFormError({})
    };
    const handleDuration = (e) => {
        setDuration(e.target.value)
        let data =handleValidation(t,{type:"text",name:'duration','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handleTitle = (e) => {
        setTitle(e.target.value)
        let data =handleValidation(t,{type:"text",name:'title','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handleTypeSelect = (e) => {
        console.log('type ==> ',e)
   setType(e)
   let data =handleValidation(t,{type:"text",name:'typeSelect','value':e.value,required:true});
   let form=formError;
           form[data.name]=data   
           setFormError(form)
    };
    const handleSelectEmployee = (e) => {
        setEmployees(e)
        let data =handleValidation(t,{type:"text",name:'employee','value':e,required:true,isArray:true});
   let form=formError;
           form[data.name]=data   
           setFormError(form)
    };
    const handleDescription = (e) => {
        setDescription(e.target.value)
    };
    const editSurvey = (survey)=>{
        setCurrentRow(survey);
        setDuration(survey.duration);
        setTo(new Date(survey.end_date), 'DD/MM/YYYY');
        setFrom(new Date(survey.start_date), 'DD/MM/YYYY');
        setDescription(survey.description);
        setTitle(survey.title);
        setType(surveys_types.find(d=>d.value===survey.type));
        setHasUpdate(true);
        setShow('create')
        let data=  handleFinaleValidation(t,[
            {type:"text",name:'duration','value':survey.duration,required:true},
            {type:"text",name:'title','value':survey.title,required:true},
            {type:"text",name:'typeSelect','value':survey.type,required:true},
            {type:"date",name:'fromChange','value':survey.start_date,required:true},
            {type:"date",name:'toChange','value':survey.end_date,required:true}
            ])
                  setFormError(data)
    };
    const createSurvey = () => {
        let o = {
            "duration": duration,
            "description": description,
            "title": title,
            "type": type.value,
            "status": status,
            "sio_channel": "",
            "category":"review",
            "start_date":from,
            "end_date":to
        };
        if (!isUpdate)
            props.action_createSurvey(o)
        else{
            o.id = current_row._id;
            props.action_updateSurvey(o);
            setHasUpdate(false);
            setCurrentRow(null);
        }
        setShow(null);
        props.action_loading(true)
        props.action_listSurveysReview();
        setFormError({})
    };
    const deleteSurvey = () => {
        props.action_deleteSurvey(current_row._id);
        setShow(null);
        setCurrentRow(null);
        props.action_loading(true)
    };
    const editGoal = (goal)=>{
        console.log({goal})
        setCurrentRow(goal);
        setFrom(goal.start_date);
        setTo(goal.end_date);
        setTitle(goal.title);
        setObs(goal.description);
        setSelectedEmployees(props.rwState.colleagues.filter((emp)=>goal.employees.find((c)=>c._id===emp._id)));
        setEmployees(selectedEmployees);
        setHasUpdate(true);
        setShow('create_goal')
        let data=  handleFinaleValidation(t,[
            {type:"date",name:'fromChange','value':goal.start_date,required:true,min:new Date()},
            {type:"date",name:'toChange','value':goal.end_date,required:true,min:goal.start_date},
            {type:"text",name:'title','value':goal.title,required:true},
            {type:"textarea",name:'observation','value':goal.description,required:true},
            {type:"text",name:'employee','value':goal.employees,required:true,isArray:true},
            ])
                  setFormError(data)
    };
    const createGoal = () => {
        let arr = [];
        employees.forEach((a)=>{arr.push(a._id)});
        let o = {
            "start_date": moment(from).format('YYYY-MM-DD'),
            "end_date": moment(to).format('YYYY-MM-DD'),
            "description": obs,
            "title": title,
            "employees":arr
        };
        if (!isUpdate)
            props.action_createGoal(o)
        else{
            o.id = current_row._id;
            props.action_updateGoal(o);
            setHasUpdate(false);
            setCurrentRow(null);
        }
        setShow(null);
        props.action_loading(true)
        setFormError({})
    };
    const deleteGoal = (id) => {
        props.action_deleteGoal(id);
        setShow(null);
        props.action_loading(true)
    };
    const handleSearch = (e) => {
        setSearch(e.target.value);
        employeeSearch(e.target.value);
    };
   const employeeSearch = (text) => {
        let employees=[],oldEmployee=props.rwState.colleagues.filter(r=>r.activated);
            oldEmployee.forEach(res=>{
                if(res.firstname.includes(text) ||
                   res.lastname.includes(text) ||
                   res.department?.name.includes(text) ||
                   res.branch?.name.includes(text) ||
                   res.position?.title.includes(text) 
                   ){
                    employees.push(res);
                }
            })
            setFindEmployees(employees)
    };
   const resetEmployeeSearch = () => {
            setFindEmployees(props.rwState.colleagues.filter(r=>r.activated))
    };
    const contextMenu =
        [
            {label: t("performances_review_title"), eventKey: 'survey'},
            {label: t("goals_title"), eventKey: 'perf'},
            {label: t("disciplines"), eventKey: 'depart'},
        ]
    const handleFromChange = (from) => {
        setFrom(from)
        let data =handleValidation(t,{type:"date",name:'fromChange','value':from,required:true,min:new Date()});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleToChange = (to) => {
        setTo(to)
        let data =handleValidation(t,{type:"date",name:'toChange','value':to,required:true,min:from});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleObservation = (e) => {
        setObs(e.target.value)
        let data =handleValidation(t,{type:"textarea",name:'observation','value':e.target.value,required:true});
   let form=formError;
           form[data.name]=data   
           setFormError(form)
    }
    const pColumns = [
        {
            name: t("name_and_profession"),
            selector: 'firstname',
            cell: row => {
                return <div className={'d-flex align-items-center pt-3 mb-3'}>
                        <div className={'mr-3'}>
                            <img src={mSVG} style={{width: 38, height: 38}}/>
                        </div>
                    <div>
                        <div>{row.firstname + ' ' + row.lastname}</div>
                        <div>{row.position?.title}</div>
                    </div>
                </div>
            }
        },
        {
            name: t("branche"),
            selector: 'employee_info',
            cell: row => <div>{row.branch?.name}</div>
        },
        {
            name: t("department_title_unique"),
            selector: 'employee_info',
            cell: row => <div>{row.department?.name}</div>
        },
        {
            name: '',
            cell: row => <div style={{width:'100%',textAlign:'right'}}> 
                <Button size={"sm"} onClick={e=>history.push('/dash/discipline/'+row._id)} style={{backgroundColor:'transparent', border: 'solid 1px #FF796B',borderRadius: 6, color:"#FF796B",padding:'5px 15px'}}>Voir</Button>
                </div>
        }
    ];

    useEffect(() => {
        if(props.rwState.colleagues && props.rwState.colleagues.filter(r=>r.activated) && props.rwState.colleagues.filter(r=>r.activated).length > 0) {
            setFindEmployees(props.rwState.colleagues.filter(r=>r.activated))
        }
       }, [props.rwState.colleagues]);
    
    
  //****************************************** paginate filter data */
 
useEffect(() => {
    setSurveyList([])
    setGoalList([])
    if(props.rwState.surveys && props.rwState.surveys.length > 0) {
        let mylist = props.rwState.surveys;
        setSurveyList(mylist)
    }
   }, [props.rwState.surveys]);

  useEffect(() => {
    if(props.rwState.goals && props.rwState.goals.length > 0) {
        let mylist = props.rwState.goals;
        setGoalList(mylist)
    }
   }, [props.rwState.goals]);

   
/********************************************* pending Begin paginating */
const [surveyList, setSurveyList] = useState(undefined);
const [CurrentSurveyLists, setCurrentSurveyLists] = useState(undefined);
const [surveyPageCount, setSurveyPageCount] = useState(0);
const [surveyItemOffset, setSurveyItemOffset] = useState(0);
const [surveyItemsPerPage, setSurveyItemsPerPage] = useState(8);

useEffect(() => {
  if(surveyList) {
      const endOffset = surveyItemOffset + surveyItemsPerPage;
      setCurrentSurveyLists(surveyList.slice(surveyItemOffset, endOffset));
      setSurveyPageCount(Math.ceil(surveyList.length / surveyItemsPerPage));
  }
 }, [surveyItemOffset, surveyItemsPerPage, surveyList]);

 const handleSurveyPageClick = (event) => {
  const newOffset = (event.selected * surveyItemsPerPage) % surveyList.length;
  setSurveyItemOffset(newOffset);
};
//*********************************************  survey Active End paginating */
 
/********************************************* goal Begin paginating */
const [goalList, setGoalList] = useState(undefined);
const [CurrentGoalLists, setCurrentGoalLists] = useState(undefined);
const [goalPageCount, setGoalPageCount] = useState(0);
const [goalItemOffset, setGoalItemOffset] = useState(0);
const [goalItemsPerPage, setGoalItemsPerPage] = useState(8);

useEffect(() => {
  if(goalList ) {
      const endOffset = goalItemOffset + goalItemsPerPage;
      setCurrentGoalLists(goalList.slice(goalItemOffset, endOffset));
      setGoalPageCount(Math.ceil(goalList.length / goalItemsPerPage));
  }
 }, [goalItemOffset, goalItemsPerPage, goalList]);

 const handleGoalPageClick = (event) => {
  const newOffset = (event.selected * goalItemsPerPage) % goalList.length;
  setGoalItemOffset(newOffset);
};
//********************************************* goal Active End paginating */

    return <div id={"Surveys"}>
        <Container fluid>
            <Row>
                <Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={'perf'}>
                        <div className="d-flex align-items-start justify-content-between">
                            <ContextMenu menu={contextMenu}/>
                        </div>

                        <Tab.Content>
                            <Tab.Pane className={"lastRollL"} eventKey="perf">
                                <Row style={{marginBottom:"25px"}}>
                                <Col md={12}>
                                <Button className={"mb-4"}  onClick={() => {setShow('create_goal')}} ><BsPlus/>{t("create_goal")}</Button>
                                </Col>
                                    <Col md={12}>
                                        <Card style={{border:"none",maxHeight:"320px",overflow:"auto",paddingBottom:"25px"}}>
                                            <Card.Body>
                                                <Card.Title>
                                                    <span style={{color: "#6600CC", fontWeight: "bold"}}>{t("list_of_goals")}</span>
                                                </Card.Title>
                                                <Card.Text>
                                                       <div>
                                                           {
                                                               CurrentGoalLists && CurrentGoalLists.length > 0 ?
                                                               CurrentGoalLists.map((a => {
                                                                       return <Goal edit={()=>editGoal(a)} deleteGoal={()=>deleteGoal(a._id)} goal={a}/>
                                                                   }))
                                                                   :
                                                                   <h4 className={"m-4 text-center"}>{t("no_goals_available")}</h4>
                                                           }
                                                            <Col md={12} className={"mt-4"}>
                                        <div hidden={!CurrentGoalLists || (CurrentGoalLists && CurrentGoalLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleGoalPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={goalPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div></Col>
                                                       </div>
                                                </Card.Text>
                                                <div style={{marginTop:"25px",height:"15px"}}> </div>
                                            </Card.Body>
                                        </Card>

                                    </Col>
                                 
                                </Row>
                            </Tab.Pane>
                             <Tab.Pane className={"lastRollL"} eventKey="survey">
                                <div className={"p-3 border-0"} style={{borderRadius: 10}}>
                                    <Button className={"mb-4"}  onClick={() => {setShow('create')}} ><BsPlus/>{t("create_an_evaluation")}</Button>
                                    <Row>
                                        {  CurrentSurveyLists && CurrentSurveyLists.length > 0 ?
                                            CurrentSurveyLists.map((p => {
                                                return <Col  md={6} className={'mt-3'}>
                                                    <SingleSurvey
                                                        editSurvey={()=>{editSurvey(p)}}
                                                        deleteSurvey={()=>{setCurrentRow(p);setShow('confirm')}}
                                                        branches={props.rwState.branches}
                                                        departments={props.rwState.departments}
                                                        positions={props.rwState.positions}
                                                        employees={props.rwState.colleagues.filter(r=>r.activated)}
                                                        survey={p}/>
                                                </Col>
                                            }))
                                            :
                                            <h4 className={"m-4 text-center"}>{t("no_evaluation_evailable")}</h4>
                                        }
                                         <Col md={12} className={"mt-4"}>
                                        <div hidden={!CurrentSurveyLists || (CurrentSurveyLists && CurrentSurveyLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleSurveyPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={surveyPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div></Col>
                                    </Row>
                                </div>
                              </Tab.Pane>
                            <Tab.Pane className={"lastRollL"} eventKey="depart">
                            <div className={"col-12"}>
                            <div className={"row"}>
                            <div className={"col-6"}>
                                    <Form.Group>
                                    <Form.Label>{t("search_employee_text")}</Form.Label>
                                           <Form.Control
                                                required
                                                onChange={handleSearch}
                                               defaultValue={search}
                                                type={"text"}/>
                                    </Form.Group>
                                    </div>
                                   
                                    </div>
                                    </div>
                                    <div className={"col-12"}>
                                <Card className={"p-4 border-0"} style={{borderRadius:10}}>
                                    <div>
                                        <DataTable
                                          noHeader
                                          fixedHeader
                                          fixedHeaderScrollHeight="600px"
                                          pagination
                                            columns={pColumns}
                                            data={findEmployees ? findEmployees : []}
                                            highlightOnHover
                                            pointerOnHover
                                            noDataComponent={t("no_discipline_evailable")}
                                            paginationComponentOptions={
                                                {
                                               rowsPerPageText: t("line_per_page"),
                                                rangeSeparatorText: t("for"),
                                                 noRowsPerPage: false,
                                                  selectAllRowsItem: false,
                                                   selectAllRowsItemText: t("all")} 
                                               }
                                        />
                                    </div>
                                </Card>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Col>
            </Row>

        </Container>

        {/*  modals */}
        <Modal centered show={show === 'create'} onHide={handleClose}>
            <Modal.Header className={"bg-primary"}>
                <h4 className={"col-lg-12 text-white text-center"}>{t("new_evaluation")}</h4>
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <Form.Group>
                    <Col>
                        <Form.Label>{t("type_evaluation")}</Form.Label>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={surveys_types}
                            defaultValue={type}
                            styles={{
                                control: base => ({
                                    ...base,
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={'Type de sondage'}
                            name='type'
                            onChange={handleTypeSelect}
                            className={handleinputValidationError(formError,'typeSelect')}
                            />
                                   {handleTextValidationError(formError,'typeSelect')}
                 
                    </Col>

                </Form.Group>
                <Form.Group>
                    <Col>
                        <Form.Label>{t("title_evaluation")} <span className={'required_box'}>*</span></Form.Label>
                        <Form.Control
                            onChange={handleTitle}
                            defaultValue={title}
                            type={"text"}
                            className={handleinputValidationError(formError,'title')}
                            />
                                   {handleTextValidationError(formError,'title')}
                   </Col>
                </Form.Group>
                <Form.Group>
                <Col>
                              <Row>
                              <Col>
                                  <Form.Label>Date de début <span className={'required_box'}>*</span></Form.Label>
                                  <DayPickerInput
                                      required
                                      inputProps={{className: 'form-control'}}
                                      format="DD/MM/YYYY"
                                      formatDate={formatDate}
                                      placeholder={`${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                      value={from}
                                      parseDate={parseDate}
                                      dayPickerProps={{
                                          selectedDays: [from, {from, to}],
                                          disabledDays: {after: to},
                                          toMonth: to,

                                      }}
                                      onDayChange={handleFromChange}
                                      className={handleinputValidationError(formError,'fromChange')}
                                      />
                                             {handleTextValidationError(formError,'fromChange')}
                           
                              </Col>
                              <Col>
                                  <Form.Label>{t("start_date")} <span className={'required_box'}>*</span></Form.Label>
                                  <DayPickerInput
                                      disabled={!type}
                                      required
                                      inputProps={{className: 'form-control'}}
                                      format="DD/MM/YYYY"
                                      formatDate={formatDate}
                                      placeholder={`${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                      value={to}
                                      parseDate={parseDate}
                                      dayPickerProps={{
                                          selectedDays: [from, {from, to}],
                                          disabledDays: [{before: from}],
                                          modifiers,
                                      }}
                                      onDayChange={handleToChange}
                                      className={handleinputValidationError(formError,'toChange')}
                                      />
                                             {handleTextValidationError(formError,'toChange')}
                           
                              </Col>
                              </Row>
                              </Col>

                            </Form.Group>
                <Form.Group>
                    <Col>
                        <Form.Label>{t("evaluation_duration")} <span className={'required_box'}>*</span></Form.Label>
                        <Form.Control
                            onChange={handleDuration}
                            defaultValue={duration}
                            type={"number"}
                            className={handleinputValidationError(formError,'duration')}
                            />
                                   {handleTextValidationError(formError,'duration')}
                 
                    </Col>
                </Form.Group>
                <Form.Group>
                    <Col>
                        <Form.Label>{t("evaluation_description")}</Form.Label>
                        <Form.Control
                            onChange={handleDescription}
                            defaultValue={description}
                            as={"textarea"}/>
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("cancel")}
                </Button>
                <Button variant="primary"
                 disabled={
                     handleDisabledBtnValidationError(formError,
                    ['title','duration','typeSelect'])
                }
                onClick={createSurvey}>
                    {t("save")}
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("confirmation")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={"text-center"}>
                    {t("confirme_irreversile_deletion_text")}
                </div>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>
                        <Button variant="primary" onClick={deleteSurvey}>
                            {t("yes_delete")}
                        </Button>
                        <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                            {t("cancel")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
        <Modal centered show={show === 'create_goal'} onHide={handleClose}>
            <Modal.Header closeButton className={"border-0"}>
                <Col>
                <h5 className={"text-center"}>{t("new_goals")}</h5>
                </Col>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                     <Form.Group>
                     <Form.Label>{t("choose_an_employee")} <span className={'required_box'}>*</span></Form.Label>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={props.rwState.colleagues.filter(r=>r.activated)}
                            getOptionLabel={o => o.lastname+''+o.firstname}
                            getOptionValue={o => o._id}
                            defaultValue={selectedEmployees}
                            styles={{
                                control: base => ({
                                    ...base,
                                    minHeight: 48,
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("employee_text")}
                            isMulti
                            onChange={handleSelectEmployee}
                            className={handleinputValidationError(formError,'employee')}
                            />
                                   {handleTextValidationError(formError,'employee')}
                 
                    </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>{t("title")} <span className={'required_box'}>*</span></Form.Label>
                            <Form.Control
                                size={"lg"}
                                value={title}
                                onChange={handleTitle}
                                className={handleinputValidationError(formError,'title')}
                                />
                                       {handleTextValidationError(formError,'title')}
                     
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>{t("start_date")} <span className={'required_box'}>*</span></Form.Label>
                            <DayPickerInput
                                inputProps={{className: 'form-control form-control-lg'}}
                                format="DD/MM/YYYY"
                                formatDate={formatDate}
                                placeholder={`${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                value={from}
                                parseDate={parseDate}
                                dayPickerProps={{
                                    selectedDays: [from, {from, to}],
                                    disabledDays: {after: to},
                                    toMonth: to,
                                    modifiers,
                                    //onDayClick: () => this.to.getInput().focus(),
                                }}
                                onDayChange={handleFromChange}
                                className={handleinputValidationError(formError,'formChange')}
                                />
                                       {handleTextValidationError(formError,'formChange')}
                     
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>{t("end_date")} <span className={'required_box'}>*</span></Form.Label>
                            <DayPickerInput
                                inputProps={{className: 'form-control form-control-lg'}}
                                format="DD/MM/YYYY"
                                formatDate={formatDate}
                                placeholder={`${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                value={to}
                                parseDate={parseDate}
                                dayPickerProps={{
                                    selectedDays: [from, {from, to}],
                                    disabledDays: {before: from},
                                    modifiers,
                                    month: from,
                                    fromMonth: from,
                                }}
                                onDayChange={handleToChange}
                                className={handleinputValidationError(formError,'toChange')}
                                />
                                       {handleTextValidationError(formError,'toChange')}
                     
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>{t("observations")} <span className={'required_box'}>*</span></Form.Label>
                            <Form.Control
                                as={'textarea'}
                                size={"lg"}
                                onChange={handleObservation}
                                value={obs}
                                className={handleinputValidationError(formError,'observation')}
                                />
                                       {handleTextValidationError(formError,'observation')}
                     
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="primary"
                disabled={
                    handleDisabledBtnValidationError(formError,
                    ['observation','title','fromChange','toChange','employee']) 
                }
                block size={"lg"} onClick={createGoal}>
                    {t("save")}
                </Button>
            </Modal.Footer>
        </Modal>

    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Performances);
