import React, {useEffect,useState} from 'react';
import "./Settings.css"

import {Tab, Col, Container, Row, Card} from "react-bootstrap";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import {formatDate, parseDate} from "react-day-picker/moment";
import Setting from "../../widgets/Setting";

const mapStateToProps = (state) => {
    return {rwState: state}
}

const mapDispatchToProps = {...actions}

const Settings = (props) => {
    useEffect(() => {
        props.action_listSetting();
        props.action_setTitle("Paramètre")
    }, []);
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    return <div id={"Settings"}>
        <Container fluid>
            <Row>
                <Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={role.includes('Employee')?'depense':'paie'}>
                        <Tab.Content>
                            <Tab.Pane className={"lastRollL"} eventKey="paie">
                                <Card className={"p-4 border-0"} style={{borderRadius:10}}>
                                    <div className={"d-flex align-items-center justify-content-between"}>
                                        <div></div>
                                    </div>

                                </Card>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Col>
            </Row>

        </Container>

    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
