import React, {useEffect, useState} from 'react';
import './SectionTitle.css'
import { format,formatRelative } from 'date-fns'
import { fr, ru } from 'date-fns/locale'
import {Col} from "react-bootstrap";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import { useTranslation } from 'react-i18next';
const mapStateToProps = (state) => {
  return {rwState: state}
}
const mapDispatchToProps = {...actions}

const SectionTitle = (props) => {
  const { t } = useTranslation();
  const [title,setTitle] = useState()
  const [subTitle,setSubTitle] = useState()
  useEffect(()=>{
    setTitle(props.title|| t("title"))
    setSubTitle('')
  },[props.title])
  return <div id={'SectionTitle'}>
    <div className={'title'}>{props.rwState.sectionTitle || title}</div>
    <div className={'subTitle'}>{subTitle}</div>
    <div className={'currentTime'}>
      <span className={'d-none d-sm-none d-md-inline'}>{format(new Date(),'EEEE', { locale: fr})}, </span>
       {format(new Date(),'PPP', { locale: fr })} </div>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionTitle);
