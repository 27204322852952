import React, {useEffect, useState} from 'react';
import "./Discipline.css"
import {useParams,useHistory} from 'react-router-dom'
import {Tab,  Form, Modal, Button, Col, Container, Row, Card, Spinner, Badge} from "react-bootstrap";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import * as RI from "react-icons/ri"
import * as BI from "react-icons/bi"
import Select from "react-select";
import {formatDate, parseDate} from "react-day-picker/moment";
import DataTable from "react-data-table-component";
import ContextMenu from "../../menus/ContextMenu/ContextMenu";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import SingleSurvey from "../../widgets/SingleSurvey/SingleSurvey";
import {isAdmin,
    handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}


const Discipline = (props) => {
    const { t } = useTranslation();

    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    const [show, setShow] = useState([])
    const avertissement_types = [{ label: t("minor"), value: 1 }, { label: t("serious"), value: 2 },{ label: t("very_serious"), value: 3 },]
    const [beginDate, setBeginDate] = useState();
    const [endDate, setEndDate] = useState();
    const [date, setDate] = useState();
    const [current_row, setCurrentRow] = useState()
    const [comment, setComment] = useState()
    const [severity, setSeverity] = useState()
    const [title, setTitle] = useState()
    const modifiers = {start: beginDate, end: endDate};
    const history = useHistory();
    const {id} = useParams();
    const [formError, setFormError] = useState({})

    const contextMenu = isAdmin(role)?
        [
            {label: t("performance_survey"), eventKey: 'surveys'},
            {label: t("improvement_plan"), eventKey: 'plan'},
            {label: t('notices'), eventKey: 'warn'},
        ]:[
            {label: t("performance_survey"), eventKey: 'surveys'},
            {label: t("improvement_plan"), eventKey: 'plan'},
            {label: t('notices'), eventKey: 'warn'},
        ];
    const handleClose = () => {
        setShow(null)
        setFormError({})
        reset();
    }
    const handleTitle = e => {
        setTitle(e.target.value)
        let data =handleValidation(t,{type:"text",name:'title','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handleComment = e =>{
         setComment(e.target.value)
         let data =handleValidation(t,{type:"textarea",name:'comment','value':e.target.value,required:true});
         let form=formError;
                 form[data.name]=data   
                 setFormError(form)
        };
    const handleFromChange = (from) => {
        setBeginDate(from)
        let data =handleValidation(t,{type:"date",name:'fromChange','value':from,required:true,min: new Date()});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleDateChange = (d) => {
        setDate(d)
        let data =handleValidation(t,{type:"date",name:'dateChange','value':d,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleSeverity = (e) => {
        setSeverity(e)
        let data =handleValidation(t,{type:"text",name:'severity','value':e.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleToChange = (to) => {
        setEndDate(to)
        let data =handleValidation(t,{type:"date",name:'toChange','value':to,required:true,min:new Date(beginDate)});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const confirmRemove = (row)=>{
        setCurrentRow(row);
        setShow('confirm_remove');
    }
    const getSeverity = (sev)=>{
        let str = "";
        switch (sev) {
            case 1:
                str = "mineur";
                break;
            case 2:
                str = "Grave";
                break;
            case 3:
                str = "Très grave";
                break;
        }
        return str;
    }
    const dColumns = [
        {
            name: t('title'),
            selector: 'firstname',
            cell: row => <div>{row.title}</div>
        },
        {
            name: t('start_date'),
            selector: 'employee_info',
            cell: row => <strong>{new Date(row.start_date).toLocaleDateString()}</strong>
        },
        {
            name: t('end_date'),
            selector: 'employee_info',
            cell: row => <strong>{new Date(row.end_date).toLocaleDateString()}</strong>
        },
        {
            name: t('comment'),
            selector: 'hired_on',
            cell: row => <strong><Button onClick={()=>{setComment(row.comment);setShow('comment')}}  variant={"link"} size={"sm"}><BI.BiDetail size={22}/> Voir</Button></strong>
        },
        {
            name: t("status_text"),
            selector: 'employee_info',
            cell: row => <Form.Check
                type="switch"
                id={"p-"+row._id}
                label={row.is_visible?"Visible":"Non visible"}
                checked={row.is_visible}
                onChange={()=>{changePlanState(row)}}
            />
        },
        {
            name:'',
            cell: row => <>
                <Button onClick={()=>editPlan(row)}  variant={"link"} size={"sm"}><BI.BiEdit size={22}/></Button>
                <Button onClick={()=>confirmRemove(row)}  variant={"link"} size={"sm"}><BI.BiTrash color={"red"} size={22}/></Button>
                </>,
            grow:0
        }
    ]
    const aColumns = [
        {
            name: toString('title'),
            selector: 'firstname',
            cell: row => <div>{row.title}</div>
        },
        {
            name: t("notice_date"),
            selector: 'employee_info',
            cell: row => <strong>{new Date(row.date).toLocaleDateString()}</strong>
        },
        {
            name: t('gravity'),
            selector: 'employee_info',
            cell: row => <strong>{getSeverity(row.severity)}</strong>
        },
        {
            name: t('comment'),
            selector: 'hired_on',
            cell: row => <strong><Button onClick={()=>{setComment(row.comment);setShow('comment')}}  variant={"link"} size={"sm"}><BI.BiDetail size={22}/> Voir</Button></strong>


        },
        {
            name: t("status_text"),
            selector: 'employee_infos',
            cell: row => <Form.Check
                type="switch"
                label={row.is_visible?"Visible":"Non visible"}
                id={"aver-"+row._id}
                checked={row.is_visible}
                onChange={()=>changeAvertissementState(row)}
            />
        },
        {
            name:'',
            cell: row => <>
                <Button onClick={()=>editAvertissement(row)}  variant={"link"} size={"sm"}><BI.BiEdit  size={22}/></Button>
                <Button onClick={()=>confirmRemove(row)}  variant={"link"} size={"sm"}><BI.BiTrash color={"red"} size={22}/></Button>
                </>,
            grow:0
        }
    ]
    //employee
    const dColumns_emp = [
        {
            name: t('title'),
            selector: 'firstname',
            cell: row => <div>{row.title}</div>
        },
        {
            name: t('start_date'),
            selector: 'employee_info',
            cell: row => <strong>{new Date(row.start_date).toLocaleDateString()}</strong>
        },
        {
            name: t('end_date'),
            selector: 'employee_info',
            cell: row => <strong>{new Date(row.end_date).toLocaleDateString()}</strong>
        },
        {
            name: t('comment'),
            selector: 'hired_on',
            cell: row => <strong><Button onClick={()=>{setComment(row.comment);setShow('comment')}}  variant={"link"} size={"sm"}><BI.BiDetail size={22}/> Voir</Button></strong>
        },

    ]
    const aColumns_emp = [
        {
            name: 'title',
            selector: 'firstname',
            cell: row => <div>{row.title}</div>
        },
        {
            name: t("notice_date"),
            selector: 'employee_info',
            cell: row => <strong>{new Date(row.date).toLocaleDateString()}</strong>
        },
        {
            name: t("gravity"),
            selector: 'employee_info',
            cell: row => <strong>{getSeverity(row.severity)}</strong>
        },
        {
            name: t("comment"),
            selector: 'hired_on',
            cell: row => <strong><Button onClick={()=>{setComment(row.comment);setShow('comment')}}  variant={"link"} size={"sm"}><BI.BiDetail size={22}/> Voir</Button></strong>


        },

    ]
    const editAvertissement = (row) =>{
        setCurrentRow(row);
        setComment(row.comment);
        setTitle(row.title);
        setDate(row.date);
        setSeverity(avertissement_types.find(s=>s.value===row.severity));
        setShow('create_avert')
        let data=  handleFinaleValidation(t,[
            {type:"text",name:'title','value':row.title,required:true},
            {type:"text",name:'comment','value':row.comment,required:true},
            {type:"text",name:'dateChange','value':row.date,required:true},
            {type:"text",name:'severity','value':row.severity,required:true}
           ])
                setFormError(data)
    }
    const reset = (row) =>{
        setCurrentRow();
        setComment('');
        setTitle('');
        setDate('');
        setSeverity('');
        setBeginDate('');
        setEndDate('');
    }
    const editPlan = (row) =>{
        setCurrentRow(row);
        setComment(row.comment);
        setBeginDate(row.start_date);
        setEndDate(row.end_date);
        setTitle(row.title);
        setShow('create_plan');
        let data=  handleFinaleValidation(t,[
            {type:"text",name:'title','value':row.title,required:true},
            {type:"text",name:'fromChange','value':row.start_date,required:true},
            {type:"text",name:'toChange','value':row.end_date,required:true},
            {type:"text",name:'comment','value':row.comment,required:true},
           ])
                setFormError(data)
    }
    const createOrUpdateAvertissement = () => {
        let l = {
            "title": title,
            "date": date,
            "comment": comment,
            "severity": severity.value,
            "employee": id,
        };
        if (current_row){
            l.id = current_row._id;
            props.action_updateAvertissment(l)
        } else{
            props.action_createAvertissment(l)
        }
        handleClose();
        props.action_loading(true);
        setFormError({})
        reset();
    };
    const deleteAvertissement = () => {
        let d = {id:current_row._id,employee:id}
        props.action_deleteAvertissment(d);
        setShow(null);
        setCurrentRow(null);
        props.action_loading(true)
    };
    const changeAvertissementState = (row) => {
        let d = {id:row._id,employee:id}
        props.action_changeAvertissmentState(d);
        setShow(null);
        setCurrentRow(null);
        props.action_loading(true)
    };
    const createOrUpdatePlan = () => {
        let l = {
            "title":title,
            "start_date": moment(beginDate).format('YYYY-MM-DD'),
            "end_date": moment(endDate).format('YYYY-MM-DD'),
            "comment": comment,
            "employee": id,
        };
        if (current_row){
            l.id = current_row._id;
            props.action_updatePlanAmelioration(l)
        } else{
            props.action_createPlanAmelioration(l)
        }
        handleClose();
        props.action_loading(true);
        setFormError({})
        reset();
    };
    const deletePlan = () => {
        let d = {id:current_row._id,employee:id}
        props.action_deletePlanAmelioration(d);
        setShow(null);
        setCurrentRow(null);
        props.action_loading(true)
    };
    const changePlanState = (row) => {
        let d = {id:row._id,employee:id}
        props.action_changePlanAmeliorationState(d);
        setShow(null);
        setCurrentRow(null);
        props.action_loading(true)
    };
    useEffect(() => {
        props.action_loading(true);
        props.action_listAvertissments(id);
        props.action_listPlanAmeliorations(id);
        props.action_listSurveysReview();
        props.action_setTitle("Performance")
    }, []);

    
    
  //****************************************** paginate filter data */
 
useEffect(() => {
    setSurveyList([])
    if(props.rwState.surveys && props.rwState.surveys.length > 0) {
        let mylist = props.rwState.surveys;
        setSurveyList(mylist)
    }
   }, [props.rwState.surveys]);

/********************************************* pending Begin paginating */
const [surveyList, setSurveyList] = useState(undefined);
const [CurrentSurveyLists, setCurrentSurveyLists] = useState(undefined);
const [surveyPageCount, setSurveyPageCount] = useState(0);
const [surveyItemOffset, setSurveyItemOffset] = useState(0);
const [surveyItemsPerPage, setSurveyItemsPerPage] = useState(8);

useEffect(() => {
  if(surveyList ) {
      const endOffset = surveyItemOffset + surveyItemsPerPage;
      setCurrentSurveyLists(surveyList.slice(surveyItemOffset, endOffset));
      setSurveyPageCount(Math.ceil(surveyList.length / surveyItemsPerPage));
  }
 }, [surveyItemOffset, surveyItemsPerPage, surveyList]);

 const handleSurveyPageClick = (event) => {
  const newOffset = (event.selected * surveyItemsPerPage) % surveyList.length;
  setSurveyItemOffset(newOffset);
};
//*********************************************  survey Active End paginating */
 

    return <div id={"Discipline"}>
        <Container fluid>
            <Row>
                <Col hidden={role.toLowerCase()==="employee"} sm={"12"} className={'text-left'}>
                    <Button className={"btn btn-light btn-sm"} onClick={e => history.goBack()}>
                        <BI.BiChevronLeft style={{color: '#240265', fontWeight: '600',}} size={25}/> {t("return")}
                    </Button>
                </Col>
                <Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={isAdmin(role)?'surveys':'plan'}>
                        <div className="d-flex align-items-start justify-content-between">
                            <ContextMenu menu={contextMenu}/>
                        </div>

                        <Tab.Content>
                            <Tab.Pane className={"lastRollL"} eventKey="surveys">
                                <Row>
                                {  CurrentSurveyLists && CurrentSurveyLists.length > 0 ?
                                            CurrentSurveyLists.map((p => {
                                                return <Col  md={6}>
                                                <SingleSurvey
                                                    editSurvey={()=>{editSurvey(p)}}
                                                    deleteSurvey={()=>{setCurrentRow(p);setShow('confirm')}}
                                                    branches={props.rwState.branches}
                                                    departments={props.rwState.departments}
                                                    positions={props.rwState.positions}
                                                    employees={props.rwState.colleagues.filter(r=>r.activated)}
                                                    survey={p}/>
                                            </Col>
                                        }))
                                        :
                                        <h4 className={"m-4"}>{t("no_survey_available")}</h4>
                                    }
                                      <Col md={12} className={"mt-4"}>
                                        <div hidden={!CurrentSurveyLists || (CurrentSurveyLists && CurrentSurveyLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleSurveyPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={surveyPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div></Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane className={"lastRollL"} eventKey="plan">
                                <Card className={"p-4 border-0"} style={{borderRadius:10}}>
                                    <Col>
                                        <Button style={{float:'right'}} hidden={!isAdmin(role)} onClick={()=>{setShow('create_plan')}}>{t("create_improvement_Plan_btn")}</Button>
                                    </Col>
                                    <div>
                                        <DataTable
                                            noHeader
                                            fixedHeader
                                            fixedHeaderScrollHeight="600px"
                                            pagination
                                            columns={!isAdmin(role)?dColumns_emp:dColumns}
                                            data={props.rwState.plan_ameliorations}
                                            highlightOnHover
                                            pointerOnHover
                                            noDataComponent={t("no_improvement_Plan")}
                                            paginationComponentOptions={
                                                {
                                                    rowsPerPageText: t('line_per_page'),
                                                    rangeSeparatorText: t('for'),
                                                     noRowsPerPage: false,
                                                      selectAllRowsItem: false,
                                                       selectAllRowsItemText: t('all')}  
                                               }
                                        />
                                    </div>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane className={"lastRollL"} eventKey="warn">
                                <Card className={"p-4 border-0"} style={{borderRadius:10}}>
                                    <Col>
                                        <Button style={{float:'right'}} hidden={!isAdmin(role)} onClick={()=>{setShow('create_avert')}}>{t("create_notice_btn")}</Button>
                                    </Col>
                                    <div>
                                        <DataTable
                                            noHeader
                                            fixedHeader
                                            fixedHeaderScrollHeight="600px"
                                            pagination
                                            columns={!isAdmin(role)?aColumns_emp:aColumns}
                                            data={props.rwState.avertissments}
                                            highlightOnHover
                                            pointerOnHover
                                            noDataComponent={t("no_notice_funded")}
                                            paginationComponentOptions={
                                                {
                                                    rowsPerPageText: t('line_per_page'),
                                                    rangeSeparatorText: t('for'),
                                                     noRowsPerPage: false,
                                                      selectAllRowsItem: false,
                                                       selectAllRowsItemText: t('all')} 
                                               }
                                        />
                                    </div>
                                </Card>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Col>
            </Row>

        </Container>


        {/*  modals */}
        <Modal show={show === "confirm_remove"} onHide={() => setShow(null)} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("confirmation")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={"text-center"}>
                {t("confirme_irreversile_deletion_text")}
                </div>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>
                        <Button variant="primary" onClick={current_row?.severity?deleteAvertissement:deletePlan}>
                        {t("yes_delete")}
                        </Button>
                        <Button className={"ml-3"} variant="light" onClick={() => setShow(null)}>
                        {t("delete")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
        <Modal show={show === "comment"} onHide={() => setShow(null)} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("comment")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={"text-center"}>
                    {comment}
                </div>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>

                        <Button className={"ml-3"} variant="light" onClick={() => setShow(null)}>
                            {t("close")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
        <Modal centered show={show === 'create_plan'} onHide={handleClose}>
            <Modal.Header closeButton className={"border-0"} style={{marginBottom: -42, zIndex: 1}}/>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>{t("title")} <span className={'required_box'}>*</span> </Form.Label>
                            <Form.Control
                                size={"lg"}
                                value={title}
                                onChange={handleTitle}
                                className={handleinputValidationError(formError,'title')}
                                />
                                       {handleTextValidationError(formError,'title')}
                     
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>{t("start_date")} <span className={'required_box'}>*</span> </Form.Label>
                            <DayPickerInput
                                inputProps={{className: 'form-control form-control-lg'}}
                                format="DD/MM/YYYY"
                                formatDate={formatDate}
                                placeholder={`${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                value={beginDate}
                                parseDate={parseDate}
                                dayPickerProps={{
                                    selectedDays: [beginDate, {beginDate, endDate}],
                                    disabledDays: {after: endDate},
                                    toMonth: endDate,
                                    modifiers,
                                    //onDayClick: () => this.to.getInput().focus(),
                                }}
                                onDayChange={handleFromChange}
                                className={handleinputValidationError(formError,'fromChange')}
                                />
                                       {handleTextValidationError(formError,'fromChange')}
                     
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>{t("end_date")} <span className={'required_box'}>*</span> </Form.Label>
                            <DayPickerInput
                                inputProps={{className: 'form-control form-control-lg'}}
                                format="DD/MM/YYYY"
                                formatDate={formatDate}
                                placeholder={`${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                value={endDate}
                                parseDate={parseDate}
                                dayPickerProps={{
                                    selectedDays: [beginDate, {beginDate, endDate}],
                                    disabledDays: {before: beginDate},
                                    modifiers,
                                    month: beginDate,
                                    fromMonth: beginDate,
                                }}
                                onDayChange={handleToChange}
                                className={handleinputValidationError(formError,'toChange')}
                                />
                                       {handleTextValidationError(formError,'toChange')}
                     
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>{t("note_add_btn")} <span className={'required_box'}>*</span> </Form.Label>
                            <Form.Control
                                as={'textarea'}
                                size={"lg"}
                                value={comment}
                                onChange={handleComment}
                                className={handleinputValidationError(formError,'comment')}
                                />
                                       {handleTextValidationError(formError,'comment')}
                      </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="primary"
                disabled={
                    handleDisabledBtnValidationError(formError,
                        ['toChange','fromChange','title','comment'])
                }
                block size={"lg"} onClick={createOrUpdatePlan}>
                    {t("save")}
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal centered show={show === 'create_avert'} onHide={handleClose}>
            <Modal.Header closeButton className={"border-0"} style={{marginBottom: -42, zIndex: 1}}/>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>{t("title")} <span className={'required_box'}>*</span> </Form.Label>
                            <Form.Control
                                value={title}
                                onChange={handleTitle}
                                className={handleinputValidationError(formError,'title')}
                                />
                                       {handleTextValidationError(formError,'title')}
                   
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                        <Form.Label>{t("date")} <span className={'required_box'}>*</span> </Form.Label>
                          
                            <DayPickerInput style={{width:"100%"}}
                                inputProps={{className: 'form-control form-control-lg'}}
                                format="DD/MM/YYYY"
                                formatDate={formatDate}
                                placeholder={`${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                value={date}
                                parseDate={parseDate}
                                onDayChange={handleDateChange}
                                className={handleinputValidationError(formError,'dateChange')}
                                />
                                       {handleTextValidationError(formError,'dateChange')}
                   
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                                <Form.Label>{t("gravity")} <span className={'required_box'}>*</span> </Form.Label>
                                <Select noOptionsMessage={() => t("no_data_available")}
                                    options={avertissement_types}
                                    defaultValue={severity}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                        })
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#6600CC',
                                            primary25: '#6600CC25',
                                            primary75: '#6600CC75',
                                            primary50: '#6600CC50'
                                        },
                                    })}
                                    placeholder={'Gravité'}
                                    name='type'
                                    onChange={handleSeverity}
                                    className={handleinputValidationError(formError,'severity')}
                                    />
                                           {handleTextValidationError(formError,'severity')}
                       
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>{t("note_add_btn")} <span className={'required_box'}>*</span> </Form.Label>
                            <Form.Control
                                as={'textarea'}
                                value={comment}
                                onChange={handleComment}
                                className={handleinputValidationError(formError,'comment')}
                                />
                                       {handleTextValidationError(formError,'comment')}
                     </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button
                 disabled={
                    handleDisabledBtnValidationError(formError,
                        ['dateChange','severity','title','comment'])
                }
                variant="primary"
                block size={"lg"} 
                onClick={createOrUpdateAvertissement}>
                    {t("save")}
                </Button>
            </Modal.Footer>
        </Modal>

    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Discipline);
