import React, {useEffect, useState} from 'react';
import "./ExpenseDetails.css"
import {Button, Col, Container, Row} from "react-bootstrap";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import {getFormatedDate,DownloadFfromExternalLink} from "../../../helpers/helper";
import {useParams} from 'react-router-dom'
import { useHistory } from "react-router-dom";
import * as RI from "react-icons/ri";
import * as BI from "react-icons/bi";
import { useTranslation } from 'react-i18next';

import {Document, Page} from "react-pdf/dist/umd/entry.webpack";
import {getFileUrl} from "../../../service";



const mapStateToProps = (state) => {
    return {rwState: state}
}
const renderStatus=(q)=>{
    if(q ===0){
        return " Valider"
    }else if(q ===1){
        return "Approuver"
    }
    else if(q ===2){
        return "Terminer"
    }

};
const renderMethode=(q)=>{
    if(q ===0){
        return " approbateExpense"
    }else if(q ===1){
        return "Approuver"
    }
    else if(q ===2){
        return "Terminer"
    }

};


const ids = ['1'];

const mapDispatchToProps = {...actions}


const ExpenseDetails = (props) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const onDocumentLoadSuccess=({ numPages })=> {
        setNumPages(numPages);
    }
    const history = useHistory();
    const approbateExpense = () => {
        props.action_appobateExpense(id);
        props.action_loading(true)
    };
    useEffect(() => {
        props.action_expensebyId(id);
    }, []);
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    return <div id={"PayrollDetails"}>
        <Container className='App' fluid>
            <Col sm={"12"} className={'text-left'}>
                <Button className={"btn btn-light btn-sm"}
                        onClick={e => history.push({pathname:'/dash/payroll'})}>
                    <BI.BiChevronLeft style={{color: '#240265', fontWeight: '600',}} size={25}/> {t("return")}
                </Button>
            </Col>

            <Row>
                <Col>
                    <div style={{marginTop:"4%"}} className="row">
                        <div className="col-md-6">
                            <div className="col-12" style={{background: '#ffffff',paddingTop:"10px",paddingBottom:"10px"}}>
                                    <div  className="col-12 mt-3 mb-3">
                                       <Row>
                                               <h4 className="payslip-title col-12 text-left text-uppercase"> {t("expense_of")} {props.rwState.expense?.employee_info[0].lastname} {props.rwState.expense?.employee_info[0].firstname}
                                                   <hr/>
                                               </h4>

                                               <Col xs={12} className={"mt-2"}>
                                            {t("expense_date")}: {getFormatedDate(props.rwState.expense?.date_depense)}
                                           </Col>
                                           <Col xs={12} className={"mt-2"}>
                                               {t("amount")}: {props.rwState.expense?.amount}
                                           </Col>
                                           <Col xs={12} className={"mt-2"}>
                                            {t("expense_type")}: {props.rwState.expense?.expensetype_info?props.rwState.expense?.expensetype_info[0]?.name:'---'}
                                           </Col>
                                           <Col xs={12} className={"mt-3"}>
                                               {t("description")} :{props.rwState.expense?.comments}
                                           </Col>

                                       </Row>
                                        <div>
                                            <Button className={'m-2 d-flex align-items-right float-right'} size={"sm"} onClick={approbateExpense} >
                                                {renderStatus(props.rwState.expense?.status)}
                                            </Button>
                                        </div>
                                    </div>

                                    </div>
                            <div>
                            </div>


                        </div>

                    {/*Read document */}
                    <div className="col-lg-12 mb-5">
                        <div className={"col-12 text-center mt-3 pt-5 pb-5"} onClick={() => DownloadFfromExternalLink('depense-'+props.rwState.expense?.employee_info[0].lastname.replace(' ','-').toString()+'-'+props.rwState.expense?.employee_info[0].firstname.replace(' ','-').toString()+'-'+getFormatedDate(props.rwState.expense?.date_depense).replace(' ','-').toString()+'.pdf',getFileUrl(props.rwState.expense?.recu))} style={{cursor:"pointer",background:"#ffffff"}}>
                           <h6> {t("expense_receipt_slip")} <br/>
                               <small>{t("click_to_download")}</small>
                           </h6>
                            <Document
                                className={"tdocs"}
                                loading={t("loading_init_text")}
                                width={"100%"}
                                file={getFileUrl(props.rwState.expense?.recu)}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber}/>
                            </Document>
                            <p className={"mt-3"}>
                                { pageNumber>1 ?
                                    <span className={"btn btn-light btn-sm mr-2"} onClick={() => {
                                        setPageNumber((pageNumber - 1))
                                    }}>{t("previous")}</span>
                                    : ""
                                }
                                Page {pageNumber} of {numPages}

                                { pageNumber < numPages ?
                                    <span className={"btn btn-light btn-sm ml-2"} onClick={() => {
                                        setPageNumber((pageNumber + 1))
                                    }}>{t("next")}</span>
                                    : ""
                                }
                            </p>
                        </div>
                    </div>
                    </div>
                </Col>
            </Row>

        </Container>


    </div>
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseDetails);
