import React, {useEffect, useState} from 'react';
import "./FormationTestDetails.css"
import { Form, Button, Col, Container, Row, Card, Spinner} from "react-bootstrap";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import * as RI from "react-icons/ri";
import * as BI from "react-icons/bi";
import {useParams,useHistory} from 'react-router-dom'
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Radio from "@material-ui/core/Radio/Radio";
import Jumbotron from "react-bootstrap/Jumbotron";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const FormationTestDetails = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    let user = JSON.parse(window.localStorage.getItem('glowhr_user')).user;
    let { id } = useParams();
    let params_id=id;
   useEffect(() => {
       props.action_listTestsFormations(params_id);
       setTimeout(function(){
            setIsReady(false)
        },5000)
       props.action_setTitle(t("tests_formation_title_name"))
   }, [])
    const [show, setShow] = useState([])
    const [isReady, setIsReady] = useState(true)
    const [disableBtn, setDisabledBtn] = useState(false)
    const [disableBtn2, setDisabledBtn2] = useState(false)
    const [responses, setResponses] = useState([])
    const [questions, setQuestion] = useState([])
    const [position, setQuestionPosition] = useState(0)
  const handleResponse=(type,id,e,index)=>{
       let data=responses;
       if (type === 'qcm') {
           if(data[index]) {
               if (data[index][id] && data[index][id].length > 0) {
                  if(data[index][id].indexOf(e.target.value) < 0){ data[index][id].push(e.target.value)}else{data[index][id].splice(data[index][id].indexOf(e.target.value),1)
                  }
               }else {
                   data[index][id]=[e.target.value]
               }
           }else {
               data[index] = {};
               data[index][id] = [e.target.value]
           }
       }else{
           if(data[index]) {
               if (data[index][id]){
                   data[index][id] = [e.target.value]
               }
           }else{
               data[index]={};
               data[index][id]=[e.target.value]
           }
       }
        setResponses(data)
  }
  const checkHasSetAllResponse=(responses,questions)=>{
       console.log('is set')
       let yes=true;
      if((position+1)>=(questions.length-1)) {
           if (responses.length > 0) {
               questions.map(quest => {
                   let has=false
                   responses.forEach(resp => {
                       if(resp[quest._id]){
                           if (resp[quest._id].length > 0) has = true
                       }  })
                   if(!has) yes=false;
               })

           } else {
               yes = false;
           }
          console.log('response***',yes,responses,position,questions.length)
          if(!yes)  setDisabledBtn2(true);
       }
      return yes;
    }

    const handleTestBtnNext=(quests)=>{
        let pos=quests.length,
            actuPos=position;
        console.log('response***',position+1,pos-1)
        if((pos-1)<=(position+1)) checkHasSetAllResponse(responses,quests);
        if((pos-1)>=position) {
           actuPos = position + 1;
           setQuestionPosition((actuPos))
       }else{
               setDisabledBtn(true)
               let o = {
                   "formation_info": getFormation(props.rwState.tests_formations)._id,
                   "responses": responses,
                   "sio_channel": "",
               };
               props.action_createTestsDoneFormation(o)
               props.action_loading(true)
       }
    }
    const handleTestBtnPrevious=(quests)=>{
        let pos=quests.length,
            actuPos=position;
        if(position>0) {
            setDisabledBtn2(false);
            actuPos = position -1;
            setQuestionPosition((actuPos))
        }
    }
    const checkUserHasFinish=(test_formations)=>{
        let test_formation=test_formations[0],
            resp =false;
       if(test_formation){
            if( test_formation.formation_info.test_finisher_info && test_formation.formation_info.test_finisher_info.length>0){
                test_formation.formation_info.test_finisher_info.forEach(response=>{
                    let check=  response[test_formation.formation_info.assign_to.find(p=>p.employee_info.user===user._id)?.employee_info._id]
                    if(check){ resp=true; return; }
                })
            }
            }
        return resp;
    }
    const getUserNote=(test_formations)=>{
        let test_formation=test_formations[0],
            resp =false,
        note=0;
        if(test_formation){
            if( test_formation.formation_info.test_finisher_info && test_formation.formation_info.test_finisher_info.length>0){
                test_formation.formation_info.test_finisher_info.forEach(response=>{
                    let check_results=  response[test_formation.formation_info.assign_to.find(p=>p.employee_info.user===user._id)?.employee_info._id]
                    check_results.forEach(res=>{
                        note+=res.result;
                    })
                    return;
                })
            }
        }
        return note;
    }
    const countResult=(test_formations)=>{
        let test_formation=test_formations[0],
            total=0;
        if(test_formation){
            if( test_formation.formation_info.test_finisher_info && test_formation.formation_info.test_finisher_info.length>0){
                test_formation.formation_info.test_finisher_info.forEach(response=>{
                    let check_results=  response[test_formation.formation_info.assign_to.find(p=>p.employee_info.user===user._id)?.employee_info._id]
                     total= check_results.length;
                    return;
                })
            }
        }
        return total;
    }
    const checkHasFinishedModule=(test_formations)=>{
        let test_formation=test_formations[0],
            response=false;
        if(test_formation) {
            if (test_formation.formation_info) {
                if (test_formation.formation_info.assign_to && test_formation.formation_info.assign_to.length > 0) {
                    response = test_formation.formation_info.assign_to.find(p => p.employee_info.user === user._id)?.status
                }
            }
        }
        return response;
    }
   const getFormation=(test_formations)=>{
        let test_formation=test_formations && test_formations.length >0 ? test_formations[0] : undefined,
         response ={};
       if(test_formation) if( test_formation.formation_info) response=test_formation.formation_info;
        return response;
    }

    // ************************************* question **********************
    const viewQuestionBuilder = (q,index)=>{
        let view = "";
        switch(q.type){
            case "qcm":
                view = (
                    <div>
                        {
                            q.propositions.map((value,i)=>{return(
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value={value}
                                                name={q._id+t("proposition")+i}
                                                color="primary"/>
                                        }
                                        onChange={(e)=>{handleResponse(q.type,q._id,e,index)}}
                                        label={value}
                                    />
                                </div>
                            )})
                        }
                    </div>
                );
                break;
            case "qcu":
                view = (
                    <FormControl component="fieldset" className={"text-left"}>
                        <RadioGroup>
                            {
                                q.propositions.map((value,i)=>{return(
                                    <FormControlLabel  value={value}  name={q._id+t("answer")+i} control={<Radio color={"primary"} />}  label={value}
                                      onChange={(e)=>{handleResponse(q.type,q._id,e,index)}}
                                    />
                                )})
                            }
                        </RadioGroup>
                    </FormControl>
                );
                break;
            case "true_or_false":
                view = (
                    <FormControl component="fieldset" className={"text-left"}>
                        <RadioGroup>
                            <FormControlLabel  value="true" control={<Radio color={"primary"} />} label={"Vraie"} onChange={(e)=>{handleResponse(q.type,q._id,e,index)}}/>
                            <FormControlLabel  value="false" control={<Radio color={"primary"} />} label={"Faux"} onChange={(e)=>{handleResponse(q.type,q._id,e,index)}}/>
                        </RadioGroup>
                    </FormControl>
                );
                break;
            case "yes_or_no":
                view = (
                    <FormControl component="fieldset" className={"text-left"}>
                        <RadioGroup>
                            <FormControlLabel  value="yes" control={<Radio color={"primary"} />} label={"Oui"} onChange={(e)=>{handleResponse(q.type,q._id,e,index)}}/>
                            <FormControlLabel  value="no" control={<Radio color={"primary"} />} label={"Non"} onChange={(e)=>{handleResponse(q.type,q._id,e,index)}}/>
                        </RadioGroup>
                    </FormControl>
                );
                break;
            case "response":
                view = (
                    <div>
                        <Form.Control
                            onChange={(e)=>{handleResponse(q.type,q._id,e,index)}}
                            as={"textarea"}
                            placeholder={t("your_answer")}
                        />
                    </div>
                );
                break;
        }
        return view;
    };
    return <div id={"FormationDetails"}>
        <Container fluid>
            <Row>
                <Col sm={"12"} className={'text-left'}>
                    <Button className={"btn btn-light btn-sm"}
                            onClick={e => history.push({pathname:'/dash/courses/'+getFormation(props.rwState.tests_formations)._id})}>
                        <BI.BiChevronLeft style={{color: '#240265', fontWeight: '600',}} size={25}/> Retour
                    </Button>
                </Col>
                <Col sm={12}>
                    <Row>
                        <Col sm={12} className={"mt-3 mb-3"}>
                            <h4>{t("end_formation_test")} </h4>
                            <h6>{t("formation_title")} : <strong>{getFormation(props.rwState.tests_formations).name}</strong></h6>

                        </Col>
                        {  props.rwState.tests_formations
                            ?
                            (props.rwState.tests_formations.length > 0 ?
                                (checkHasFinishedModule (props.rwState.tests_formations) ?
                                    ( checkUserHasFinish(props.rwState.tests_formations) ?
                                        <Col sm={12} className={"text-center pt-5 mt-5"} sty>
                                            <img src={'../../../success.png'} className={'mt-5 mb-2'} alt="" width={"100px"}/>
                                            <h4 className={"col-12"}> Test finalisé
                                                . <br/> Vous avez reçu la note de <span>{getUserNote(props.rwState.tests_formations) +' / '+countResult(props.rwState.tests_formations) }</span></h4>
                                        </Col>
                                    :
                                    <Col md={12}>
                                        <Jumbotron>
                                            {   props.rwState.tests_formations.map((p,i)=>{
                                        return <Card style={{border:'none',padding:"10px 20px",background:'none'}} hidden={i!==position}>
                                            <Card.Title className={"pl-3"}>
                                                    {p.question}
                                                </Card.Title>
                                                <Card.Body>
                                                    {viewQuestionBuilder(p,i)}
                                                </Card.Body>
                                         </Card>
                                        })}
                                            <Col md={12} className={"pl-4"}>
                                        <Button as="input" type="button" disabled={disableBtn} hidden={position===0} value={t("previous")} className={"ml-3 btn btn-sm btn-light"} onClick={!disableBtn ? ()=>{ handleTestBtnPrevious(props.rwState.tests_formations)} : ''}/>{' '}

                                        <Button  type="button" disabled={disableBtn2 || disableBtn} value={props.rwState.tests_formations && props.rwState.tests_formations.length===(position+1) ? t("validate") :t("continous")} className={"ml-3 btn-sm"}
                                                 onClick={!disableBtn ? (!disableBtn2 ? ()=>{ handleTestBtnNext(props.rwState.tests_formations)} : '') : ''}>

                                            {disableBtn ?  <Spinner animation="border" role="status">
                                                    <span> validation...</span>
                                                </Spinner>
                                                : (props.rwState.tests_formations && props.rwState.tests_formations.length===(position+1) ? t("validate") :t("continous"))
                                            }
                                        </Button>
                                    </Col>
                                    </Jumbotron>
                                    </Col>
                                    )
                              :
                                    <Col sm={12} className={"text-center pt-5 mt-5"}>
                                        <img src={'../../../empty.png'} className={'mt-5'} alt="" width={"100px"}/> <br/>
                                        <h6 className="">{t("notif_should_end_module")}</h6>
                                    </Col>
                                )
                            :
                             <Col sm={12} className={"text-center pt-5 mt-5"}>
                                        <img src={'../../../empty.png'} className={'mt-5'} alt="" width={"100px"}/>
                                            <h6 className="">{t("no_question_associate_to_test")}</h6>
                                    </Col>
                            )
                            :
                            <Col sm={12} className={"text-center pt-5 mt-5"} style={{color: "#171717"}}>
                                <Spinner animation="border" role="status" className={"mt-5"} size={"lg"}>
                                    <span className="">{t("loading_init_text")}...</span>
                                </Spinner>
                            </Col>
                        }


                    </Row>
                </Col>

            </Row>
          </Container>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(FormationTestDetails);
