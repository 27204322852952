import React from 'react';
import './NotifIcon.css'
import {Badge, Dropdown, OverlayTrigger} from "react-bootstrap";
import {useParams,useHistory} from 'react-router-dom'
import notifIconSvg from '../../../assets/svg/notification.svg'
import profileIcon from "../../../assets/menu/profile.svg"
import docsIcon from "../../../assets/menu/documents.svg"
import datesIcon from "../../../assets/menu/calendar.svg"
import colleagueIcon from "../../../assets/menu/colleagues.svg"
import orgaIcon from "../../../assets/menu/organigramme.svg"
import inoutIcon from "../../../assets/menu/inout.svg"
import commIcon from "../../../assets/menu/communication.svg"
import holidayIcon from "../../../assets/menu/holidays.svg"
import recruitIcon from "../../../assets/menu/recruit.svg"
import formIcon from "../../../assets/menu/formation.svg"
import payrollIcon from "../../../assets/menu/payroll.svg"
import payIcon from "../../../assets/menu/pay.svg"
import analyticsIcon from "../../../assets/menu/analytics.svg"
import sondIcon from "../../../assets/menu/sondage.svg"
import suggIcon from "../../../assets/menu/suggestions.svg"
import perfIcon from "../../../assets/menu/performance.svg"
import settingsSvg from "../../../assets/svg/settings.svg";
import msgIcon from '../../../assets/msg.png'
import { useTranslation } from 'react-i18next';
const NotifIcon = (props) => {
  const { t } = useTranslation();
    const history = useHistory();
    const countNotification=(data)=>{
        let count=0; let newData=[];
    if(data) newData= data.filter(res=>res.read_at===undefined || !res.read_at);
   if(newData) count=newData.length;
   return count > 9 ? '+9' : (count <=0 ? '' : '0'+count);
  }
  const limitDataDisplaying=(data)=>{
    let newData=[];
    if(data) newData=data.filter(res=>res.read_at===undefined || !res.read_at);
      newData = newData.slice(0,5)
    return  newData;
  }

  const getIcon=(module)=>{
        let icon='event'
        switch (module) {
            case 'formation' :
                icon=formIcon;
                break;
           case 'event' :
                icon=datesIcon;
                break;
           case 'conge' :
                icon=holidayIcon;
                break;
           case 'integration' :
                icon=inoutIcon;
                break;
           case 'performance' :
                icon=perfIcon;
                break;
          case 'recrutement' :
                icon=recruitIcon;
                break;
          case 'payroll' :
                icon=payrollIcon;
                break;
          case 'sondage' :
                icon=sondIcon;
                break;
           case 'suggestion' :
                icon=suggIcon;
                break;
           case 'communication' :
                icon=commIcon;
                break;
            default:
                icon=datesIcon;
                break
        }
        return icon;
    }
  return <div id={'NotifIcon'}>
    <Dropdown>
        {
            props.notifications?
                <Dropdown.Toggle variant="light" size={"md"} id="notifDropdown">
                    <Badge pill variant="danger">
                        {countNotification(props.notifications)}
                    </Badge>
                    <img src={props.notifications?notifIconSvg:msgIcon} alt="Notifs" width="20px"/>
                </Dropdown.Toggle>
                :
                <Dropdown.Toggle variant="light" id="notifDropdown" size={"md"} onClick={()=>{history.push('/dash/communications')}}>
                    <Badge pill variant="danger">
                        7
                    </Badge>
                    <img src={props.notifications?notifIconSvg:msgIcon} alt="Notifs" width="20px"/>
                </Dropdown.Toggle>

        }


      <Dropdown.Menu hidden={!props.notifications}>
        {limitDataDisplaying(props.notifications) && limitDataDisplaying(props.notifications).length > 0 ?
            limitDataDisplaying(props.notifications).map(res=>{
              return  <Dropdown.Item onClick={()=>history.push({pathname:res.link})}>
                <div>
                 <div style={{display:'inline'}} className={"mr-2"}>
                     {res.module ?
                         <img src={getIcon(res.module)} alt="icon" width={22} height={22} />
                     :
                         <img src={datesIcon} alt="icon" width={22} height={22} />
                     }
                 </div>
                 <div style={{display:'inline'}}>
                     {res.title} <br/>
                     <small>{res.message}</small>
                 </div>
                </div>
              </Dropdown.Item>
            })
            : <Dropdown.Item>{t("no_notification_available")}</Dropdown.Item>
        }
           <Dropdown.Item style={{background: "#dddddd",textAlign:"center"}} onClick={()=>history.push({pathname:"/dash/notifications"})}>{t("view_more")}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </div>;
};

export default NotifIcon;
