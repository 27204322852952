import React, {useEffect, useState} from 'react';
import "./DashCalendar.css"
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from "moment/min/moment-with-locales";
import * as actions from "../../../actions";
import {Button, Card, Col, Container, Form, Modal, Row, Tab} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
moment.locale('fr')
moment().format("ll")
const localizer = momentLocalizer(moment)
const mapStateToProps = (state) => {
  return {rwState: state}
}
const mapDispatchToProps = {...actions}

const DashCalendar = ({ current_events,all_events}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState([])
  const [close, setClose] = useState([])
  const [type_event, setEventType] = useState()
  const [location, setLocation] = useState()
  const [attendees_target_id, setAttendeesTargetId] = useState([])
  const [attendees_target, setAttendeesTarget] = useState("all")
  const [event_description, setEventDescription] = useState()
  const [isEventUpdate, setIsEventUpdate] = useState(false)
  const [title, setTitle] = useState()
  const [start, setStart] = useState()
  const [event_target, setEventTarget] = useState([])
  const [end, setEnd] = useState()
  const [current_row, setCurrent_row] = useState({})
  const [current_event_type_row, setCurrent_event_type_row] = useState({})
  const affectations_type =
      [{ label: t('all'), value: 'all' },
      { label: t("departments"), value: "departments"},
      { label: t('branches'), value: 'branches'},
      { label: t('positions'), value: 'positions'},
      { label: t('specific_employee'), value: 'employee'}
]
  const [affect, setAffect] = useState(affectations_type[0])
  const [assignTo, setAssignTo] = useState([])

  const MyTimeSlotWrapper = (propsS) => {
    return <div
      className={"rbc-time-slot"}
      style={{
        paddingLeft: 15,
        paddingRight: 15,
      }}
    >
      {moment(propsS.value).format('HH:mm')}
    </div>
  }

  let components = {
    // eventWrapper: MyEventWrapper,
    toolbar: e => true,
    // header: e => false,
    // timeSlotWrapper: MyTimeSlotWrapper
  }

  const formatDateNow = (date) => {
    Number.prototype.AddZero= function(b,c){
        var  l= (String(b|| 10).length - String(this).length)+1;
        return l> 0? new Array(l).join(c|| '0')+this : this;
    }//to add zero to less than 10,
    var d = new Date(date);
    return [d.getFullYear(),
            (d.getMonth() + 1).AddZero(),
            d.getDate().AddZero()].join('-') + 'T' +
        [d.getHours().AddZero(),
            d.getMinutes().AddZero()].join(':');
}
  let formats = {
    dateFormat: "DD/MM/YYYY HH:mm",
    dayRangeHeaderFormat: ({ start, end }, culture, lz) =>
      lz.format(start, { date: 'short' }, culture) + ' /// ' +
      lz.format(end, { date: 'short' }, culture)
  }
  const editEvent=(event)=>{
   if(all_events && all_events.length > 0){
    let events=all_events;
   let data = events.find(r=>r._id===event.id)
    if(data){
        let affect=affectations_type.find(r=>r.value===data.attendees_target)
        setAffect(affect)
        setStart(formatDateNow(data.start_date))
        setAttendeesTargetId(data.attendees_target_id)
        setAttendeesTarget(data.attendees_target)
        setEnd(formatDateNow(data.end_date))
        setTitle(data.title)
        setEventDescription(data.description)
        setLocation(data.location)
        setEventType(data.event_type_info)
        setCurrent_row(data)
        setShow('details_event')
        console.log('hiii i\'m working',data)
      }
    }
}
const resetEvent =()=>{
  setTitle("")
   setAffect(affectations_type[0])
  setEventDescription("")
  setStart("")
  setEnd("")
  setEventDescription("")
  setLocation("")
  setEventType("")
  setAttendeesTargetId([])
  setEventTarget([]);
  setCurrent_row([]);
  setIsEventUpdate(false)
}
const handleClose = () => {
  setShow(null)
resetEvent();
}
  return <div id={"DashCalendar"}>
    <div className={'d-flex mb-4 align-items-center'}>
      <div className={"title"}>{t("calendar_title")}</div>
      <div style={{ width: "128px", borderBottom: "3px solid #240265", opacity: 0.19, marginLeft: 15, marginRight: 15, marginBottom: -8 }} />
      <div className={'secondTitle'}>{t("day_planification")}</div>
    </div>
    <div >
       <Calendar
       selectable
       culture={'fr'}
       components = {components}
        events={current_events}
        localizer={localizer}
        defaultView={Views.WEEK}
        views={['day','week']}
        showMultiDayTimes
        step={120}
        onSelectEvent={event => editEvent(event)}
       />
    </div>
    {current_row ? 
    <Modal centered show={show === 'details_event'} onHide={handleClose} backdrop="static" size={"lg"}>
          <Modal.Header close className={"bg-primary"}>
              <h4 className={"col-lg-12 text-white text-center"}>{t("event_title")}</h4>
          </Modal.Header>
          <Modal.Body className={'p-4'}>
              <Row>
          <Col sm={12}>
              <Form.Group>
                  <Form.Label>{t("event_type_text")}</Form.Label>
                  <Form.Control
                      disabled={true}
                      defaultValue={current_row.event_type_info? current_row.event_type_info.name : ""}
                      type={"text"}/>
              </Form.Group>

              <Form.Group>
                      <Form.Label>{t("title")}</Form.Label>
                      <Form.Control
                          disabled={true}
                          defaultValue={current_row?.subject}
                          type={"text"}/>
              </Form.Group>
          </Col>

              <Col sm={12} md={6}>
             <Form.Group>
                      <Form.Label>{t("start_")}</Form.Label> <br/>
                 <Form.Control
                     disabled={true}
                     value={start}
                     type={"datetime-local"}/>
              </Form.Group>
              </Col>
              <Col sm={12} md={6}>
              <Form.Group>
                      <Form.Label>{t("end_")}</Form.Label> <br/>
                  <Form.Control
                      disabled={true}
                      min={start}
                      value={end}
                      type={"datetime-local"}/>
              </Form.Group>
              </Col>
              <Col sm={12}>
              <Form.Group>
                      <Form.Label>{t("description")}</Form.Label>
                      <Form.Control as="textarea" rows={3}
                                    disabled={true}
                                    defaultValue={current_row?.description}
                          type={"text"}/>
              </Form.Group>
               <Form.Group>
                      <Form.Label>{t("location")}</Form.Label>
                      <Form.Control as={"input"}
                                    disabled={true}
                                    defaultValue={current_row?.location}
                          type={"text"}/>
              </Form.Group>
              </Col>
                  { current_row.attendees && current_row.attendees.length >0 ?
                      <Col sm={12}>
                          <Form.Label>{t("participants")}</Form.Label> <br/>
                          {
                              current_row.attendees.map(p => {
                                  return <span
                                      className={"badge badge-light ml-3"}> {p.lastname + ' ' + p.firstname}
                                      ({p.position ? p.position.title : ""})</span>
                              })
                          }
                      </Col>
                      : ""
                  }
              </Row>
          </Modal.Body>
          <Modal.Footer className={'border-0 pt-0'}>
              <Button variant="light" onClick={handleClose}>
                  {t("close")}
              </Button>
          </Modal.Footer>
      </Modal>
: ''}
  </div>;
};

export default DashCalendar;
