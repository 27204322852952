import React from 'react';
import './SideMenu.css'
import settingsSvg from '../../../assets/svg/settings.svg'
import commentsSvg from '../../../assets/svg/comments.svg'
import ganttSvg from '../../../assets/svg/gantt chart.svg'
import {MdApps} from "react-icons/md";

const SideMenu = (props) => {
  const logout = () => {
    window.localStorage.removeItem('glowhr_user')
  }
  return <div id={'SideMenu'}>
    <ul className="nav flex-column">
      <li className="nav-item">
        <a className="nav-link" href="#menu" title="" onClick={e=>props.toggleMenu(!props.menuState)}>
          <MdApps size={32} color={"#ffffff"}/>
        </a>
      </li>
    </ul>
    <ul className="nav flex-column">
      <li className="nav-item">
        <a className="nav-link" href="/dash" title="">
          <img src={ganttSvg} alt=""/>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/dash" title="">
          <img src={settingsSvg} alt=""/>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/dash" title="">
          <img src={commentsSvg} alt=""/>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/dash" title="logout" onClick={logout}>
          <div id={'power'}>
            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-power" fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M5.578 4.437a5 5 0 1 0 4.922.044l.5-.866a6 6 0 1 1-5.908-.053l.486.875z"/>
              <path fillRule="evenodd" d="M7.5 8V1h1v7h-1z"/>
            </svg>
          </div>
        </a>
      </li>
    </ul>
  </div>;
};

export default SideMenu;
