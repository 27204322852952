import React, {useEffect,useState} from 'react';
import "./PayrollList.css"

import {Tab, Form, Modal, Button, Col, Container, Row, Card} from "react-bootstrap";
import DataTable from "react-data-table-component";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import {useParams} from 'react-router-dom'
import * as RI from "react-icons/ri"
import * as BI from "react-icons/bi"
import * as BS from "react-icons/bs"
import {BsPlus} from "react-icons/bs";
import Select from "react-select";
import {formatDate, parseDate} from "react-day-picker/moment";
import { useHistory } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";


const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions};
const optionMois = [
    { value: 'Janvier', label: 'Janvier' },
    { value: 'Février', label: 'Février' },
    { value: 'Mars', label: 'Mars' },
    { value: 'Avril', label: 'Avril' },
    { value: 'Mais', label: 'Mais' },
    { value: 'Juin', label: 'Juin' },
    { value: 'Juillet', label: 'Juillet' },
    { value: 'Août', label: 'Août' },
    { value: 'Septembre', label: 'Septembre' },
    { value: 'Octobre', label: 'Octobre' },
    { value: 'Novembre', label: 'Novembre' },
    { value: 'Decembre', label: 'Decembre' },
];
const OptionAnnee = [
    { value: '2015', label: '2015' },
    { value: '2016', label: '2016' },
    { value: '2017', label: '2017' },
    { value: '2018', label: '2018' },
    { value: '2019', label: '2019' },
    { value: '2020', label: '2020' },
    { value: '2021', label: '2021' },
    { value: '2022', label: '2022' },
    { value: '2023', label: '2023' },
    { value: '2024', label: '2024' },
    { value: '2025', label: '2025' },
    { value: '2026', label: '2026' },
    { value: '2027', label: '2027' },
    { value: '2028', label: '2028' },
    { value: '2029', label: '2029' },
    { value: '2030', label: '2030' },
];


const PayrollList = (props) => {
    const history = useHistory();
    const { employee_id } = useParams();
    useEffect(() => {
        props.action_listPayrolls(employee_id);
        props.action_listSetting();
        props.action_setTitle("Payroll")
    }, []);
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    const [show, setShow] = useState([]);
    useState(formatDate(new Date(), 'DD-MM-YYYY HH:MM:SS', 'fr'));
    const [mois, setMois] = useState()
    const [annee, setAnnee] = useState()
    const [jour, setJour] = useState()
    const [current_row, setCurrentRow] = useState()
    const [element, setElement] = useState()
    const [primes, setPrimes] = useState([])
    const [taxes, setTaxes] = useState([])
    const [nombre, setNombre] = useState()
    const handleMois = (e) => {
        setMois(e.value)
    };
    const handleJour = (e) => {
        setJour(e.target.value)
        console.log(e.target.value)
    };

    const handleAnnee = (e) => {
        setAnnee(e.value)
    };
    const handleNombre = (e) => {
        setNombre(e.target.value)
    };
    const handleElement = (e) => {
        if( e[e.length-1].type==="prime"){
            let arr=primes;
            arr.push(e[e.length-1]._id);
            setPrimes(arr)
        }else{
            let arr=taxes;
            arr.push(e[e.length-1]._id);
            setTaxes(arr)
        }
    };
    const handleClose = () => {
        setShow(null)
    };

    const pColumns = [
        {
            name: 'Année',
            selector: 'firstname',
            cell: row => {
                return <div className={'d-flex align-items-center pt-3 mb-3'}>
                    <div>
                        <div>{row.annee}</div>
                    </div>
                </div>
            }
        },
        {
            name: 'Mois',
            selector: 'employee_info',
            cell: row => {
                return <div className={'d-flex align-items-center pt-3 mb-3'}>
                    <div>
                        <div>{row.mois}</div>
                    </div>
                </div>

            }
        },
        {
            name: 'Salaire net',
            selector: 'employee_info',
            cell: row => {
                return <div className={'d-flex align-items-center pt-3 mb-3'}>
                    <div>
                        <div>{row.salaire_net} F CFA</div>
                    </div>
                </div>

            }
        },
        {
            cell: row => <div  onClick={e=>history.push('/dash/payroll/details/'+row._id)} className={"btn btn-light btn-sm"}><BS.BsFileEarmarkArrowDown/> Fiche de paie</div>,

        }
    ];
    const requestDataBuilder = () => (

        {
            "mois": mois,
            "jour": jour,
            "annee": annee,
            "taxes": taxes,
            "primes": primes,
            "employee_id": employee_id,
            "sio_channel": ""
        }
    );
    const editPayroll = () => {
        let payroll = requestDataBuilder();
        payroll['id'] = current_row._id;
        payroll['mois'] = mois;
        payroll['jour'] = jour;
        payroll['annee'] = annee;
        payroll['element'] = element;
        console.log('data', payroll);
        props.action_updateExpenseType(payroll);
        setShow(null);
        props.action_loading(true);
        setCurrentRow()
    };

    const createPayroll= () => {
        let o = requestDataBuilder();
        props.action_createPayroll(o);
        setShow(null);
        props.action_loading(true)
    };
    const proceed = () => {
        if(current_row && current_row._id) {
            console.log('update');
            editPayroll()
        } else {
            console.log('save payroll');
            createPayroll()
        }
    };

    const updatepayroll = row_data => {
        console.log('row => ', row_data);
        setCurrentRow(row_data);
        setMois(row_data.mois);
        setAnnee(row_data.annee);
        setJour(row_data.jour);
        setElement(row_data.element);
        setShow('fiche_de_paie')
    };
    const deletePayroll = row_data => {
        console.log('row => ', row_data);
        setCurrentRow(row_data);
        setShow('confirm')
    };
    const deleteConfiPayroll = () => {
        console.log('delete');
        let data = requestDataBuilder();
        data['id'] = current_row._id;
        props.action_deleteExpenseType(data);
        console.log('data', data);
        setShow(null)
        props.action_loading(true);
        setCurrentRow()
    };
    const renderElement=()=>{
        if(jour){
            return <Col>
                    <Form.Label>Nombre de jour de travail</Form.Label>
                    <Form.Control
                        onChange={handleNombre}
                        defaultValue={nombre}
                        type={"number"}/>
                </Col>
        }
        return null;
    };


    return <div id={"Payroll"}>
        <Container fluid>
            <Row>
                <Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={role.includes('Employee')?'depense':'paie'}>
                        <Col sm={"12"} className={'text-left'}>
                            <Button className={"btn btn-light btn-sm"}
                                    onClick={e => history.push({pathname:'/dash/payroll'})}>
                                <BI.BiChevronLeft style={{color: '#240265', fontWeight: '600',}} size={25}/> Retour
                            </Button>
                        </Col>
                        <div className="d-flex align-items-end justify-content-end">
                            <div className={"float-right"} style={{floatRight:'right'}}>

                                <Button className={'m-2'} size={"sm"} onClick={e => setShow('fiche_de_paie')}>
                                    <BsPlus/> Creer une fiche de paie
                                </Button>

                            </div>
                                </div>

                        <Tab.Content>
                            <Tab.Pane className={"lastRollL"} eventKey="paie">
                                <Card className={"p-4 border-0"} style={{borderRadius:10}}>
                                    <div className={"d-flex align-items-center justify-content-between"}>
                                        <div className={"tableLabel"}>Liste des fiches de paie de {props.rwState.payrolls[0]?.employee.lastname}  {props.rwState.payrolls[0]?.employee.firstname}</div>
                                        <div></div>
                                    </div>
                                    <div>
                                        <DataTable
                                            columns={pColumns}
                                            data={props.rwState.payrolls}
                                            highlightOnHover
                                            pointerOnHover
                                        />
                                    </div>
                                </Card>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Col>
            </Row>

        </Container>
        <Modal size={"lg"}  centered show={show === 'fiche_de_paie'} onHide={handleClose}>
            <Modal.Header className={"bg-primary"}>
                <h4 className={"col-lg-12 text-white text-center"}>Creer une fiche de paie</h4>
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Label>Mois</Form.Label>
                            <Select
                                styles={{
                                    control: base => ({
                                        ...base,
                                        height: 48,
                                        minHeight: 48,
                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={'Selectionner le mois'}
                                defaultValue={current_row?.mois}
                                onChange={handleMois}
                                options={optionMois}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Année</Form.Label>
                            <Select
                                styles={{
                                    control: base => ({
                                        ...base,
                                        height: 48,
                                        minHeight: 48,
                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={'Selectionner l\'année'}
                                defaultValue={current_row?.annee}
                                onChange={handleAnnee}
                                options={OptionAnnee}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Label>Prime et taxe</Form.Label>
                            <Select
                                options={props.rwState.settings}
                                getOptionLabel={o => o.libelle}
                                getOptionValue={o => o._id}
                                styles={{
                                    control: base => ({
                                        ...base,
                                        height: 48,
                                        minHeight: 48,
                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                isMulti
                                defaultValue={element}
                                placeholder={'Prime et taxe'}
                                onChange={handleElement}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"/>
                                    }
                                    label="Defnir le nombre de jour du travail"
                                    defaultValue={current_row?.jour}
                                    value={1}
                                    onChange={handleJour}

                                />
                            </div>


                        </Col>
                    </Row>
                    <Row>
                        {renderElement()}
                    </Row>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("cancel")}
                </Button>
                <Button onClick={createPayroll} variant="primary" >
                    {t("save")}
                </Button>
            </Modal.Footer>
        </Modal>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollList);
