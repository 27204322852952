import React, {useEffect, useState} from 'react';
import './Departement.css'
import {Button, Form, Modal, Tab} from "react-bootstrap";
import * as RI from "react-icons/ri";
import * as BI from "react-icons/bi";
import DataTable from "react-data-table-component";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import { useTranslation } from 'react-i18next';

import {
  handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
const mapStateToProps = (state) => {
  return {rwState: state}
};
const mapDispatchToProps = {...actions};

const Departement = (props) => {
  const { t } = useTranslation();
  let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
  const [company,setCompany]=useState();
  const [departmentName, setDepartmentName] = useState('');
  const [show, setShow] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [current_row,setCurrentRow] = useState();
  const [formError, setFormError] = useState({})

  useEffect(() => {
    props.action_listDepartments();
  }, []);
  const handleDepartmentName = (e) => {
    setDepartmentName(e.target.value)
    let data =handleValidation(t,{type:"text",name:'name','value':e.target.value,required:true});
    let form=formError;
            form[data.name]=data   
            setFormError(form)
  };
  const handleClose = () => {
    setShow(null)
  };
  //Departement methode
  const createDepartment = () => {
    let o = requestDataBuilderDepartement();
    props.action_createDepartment(o);
    setIsLoading(false)
    resetDepartement();
  };
  const requestDataBuilderDepartement = () => (
      {
        "name": departmentName,
        "settings": {}
      }
  );
  const resetDepartement = () => {
    setDepartmentName('')
    setFormError({})
  }
  const editDepartement = d => {
    let departement = requestDataBuilderDepartement();
    departement['id'] = current_row._id;
    departement['name'] =departmentName;
    console.log('data', departement);
    props.action_updateDepartement(departement);
    setShow(null);
    props.action_loading(true);
    setCurrentRow()
  };
  const updateDepartement = d => {
    console.log('row => ', d);
    setCurrentRow(d);
    setDepartmentName(d.name);
    setShow('department')
  };
  const confirmDeleteDepartement = d => {
    console.log('row => ', d);
    setCurrentRow(d)
    setShow('confirmdepartement')
  };
  const deleteDepartement = () => {
    console.log('delete')
    let data = requestDataBuilderDepartement();
    data['id'] = current_row._id;
    props.action_deleteDepartement(data);
    console.log('data', data);
    setShow(null);
    props.action_loading(true);
    setCurrentRow()
  };
  const proceedDepartement = () => {
    setIsLoading(true)
    props.action_loading(true);
    createDepartment()
  };
  const departementsColumns = [
    {
      name: t("last_name"),
      selector: 'name',
      cell: row => <div><div style={{width:'100%'}}>{row.name}</div></div>,
    },
    {
      name: '',
      cell: row => <div className={'text-right'} style={{width:'100%'}}>
        <Button onClick={() => confirmDeleteDepartement(row)}  size={"sm"} variant={'danger'} className={"ml-2"}>
          <BI.BiTrash /> supprimer
        </Button>
      </div>,

    }
  ];

  useEffect(()=>{
  let data = JSON.parse(window.localStorage.getItem('glowhr_user')).user.company;
    setCompany(data)
  },[window.localStorage.getItem('glowhr_user')])
  return <div id={t("department_title_unique")}>
    <h2 className=" mt-4 mb-3">
      {t("management_of_companie_department")}
    </h2>
    <div className="col-12 mt-4" style={{background: "#eee"}}>
      <div className="row">
        <h4 className="col-12 mb-3 mt-3">
          {t("add_new_department")}
        </h4>

        <div className="col-6">
          <Form.Group>
            <Form.Control
                size={"sm"}
                placeholder={t("department_name_text")}
                value={departmentName}
                onChange={handleDepartmentName}
                className={handleinputValidationError(formError,'name')}
                />
                       {handleTextValidationError(formError,'name')}
          </Form.Group>
        </div>
        <div className="col-2">
          <Button variant="light" disabled={ 
            handleDisabledBtnValidationError(formError,
                    ['name'])
                     || isLoading} size={"sm"} onClick={proceedDepartement}>
            {t("save")}
          </Button>
        </div>
      </div>

    </div>
    <DataTable
        noHeader
        title={t("list_departments")}
        columns={departementsColumns}
        data={props.rwState.departments}
        pagination
    />
    <Modal show={show==="confirmdepartement"} onHide={() => setShow(null)} centered size={"md"}>
      <Modal.Header closeButton className={'border-0'}>
        <Modal.Title>{t("confirmation")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={"text-center"}>
          {t("confirme_irreversile_deletion_text")}
        </div>
      </Modal.Body>
      <Modal.Footer className={'text-center border-0'}>
        <Button variant="primary" onClick={deleteDepartement}>
        {t("yes")}
        </Button>
        <Button variant="light" onClick={() => setShow(null)}>
        {t("no")}
        </Button>
      </Modal.Footer>
    </Modal>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Departement);

