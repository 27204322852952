import React, {useEffect, useState} from 'react';
import "./FormationDetails.css"
import {
    Tab,
    Nav,
    Form,
    Modal,
    Button,
    Col,
    Container,
    Row,
    Card,
    ListGroup,
} from "react-bootstrap";
import ContextMenu from "../../menus/ContextMenu";
import * as actions from "../../../actions";
import {limitText,isAdmin,
    handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
import {connect} from "react-redux";
import Select from "react-select";
import * as RI from "react-icons/ri";
import * as BI from "react-icons/bi";
import * as BS from "react-icons/bs";
import {useParams,useHistory} from 'react-router-dom'
import {uploadOnCdn} from '../../../service'
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const FormationDetails = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    let user = JSON.parse(window.localStorage.getItem('glowhr_user')).user;
    let { id } = useParams();
    let params_id=id;
   useEffect(() => {
       props.action_loading(true);
       props.action_listModuleFormations(params_id);
       props.action_listParcoursFormations(params_id);
       props.action_listTestsFormations(params_id);
       props.action_setTitle(t("formation_details_title"))
   }, [])
    const [show, setShow] = useState([])
    const [actionData, setActionData] = useState({})
    const [duration, setDuration] = useState()
    const [title, setTitle] = useState()
    const [cours, setCours] = useState(undefined)
    const [coursName, setCoursName] = useState(t("no_file_choose"))
    const [restrein, setRestrein] = useState('.pdf')
    const [name, setName] = useState()
    const [question, setQuestion] = useState()
    const [responses, setResponses] = useState()
    const [status, setStatus] = useState()
    const [description, setDescription] = useState();
    const [current_row, setCurrentRow] = useState();
    const [current_module_row, setCurrentModuleRow] = useState();
    const [current_test_row, setCurrentTestRow] = useState();
    const [isUpdate, setHasUpdate] = useState(false);
    const [isTestFormationUpdate, setTestFormationHasUpdate] = useState(false);
    const q_types = [
        { label: t("qcm_title"), value: "qcm" },
        { label: t("qcu_title"), value: "qcu" },
        { label: t("free_response_title"), value: 'response' },
        { label: t("true_or_false_title"), value: 'true_or_false' },
        { label: t("yes_or_no_title"), value: 'yes_or_no' },
    ];
    const [seletectedType, setSelectedType] = useState(q_types[0]);
    const [type, setType] = useState(seletectedType.value);
    const q_typesModule = [
        { label: "Document", value: "document" },
        { label: "Video", value: "video" },
        { label: 'Audio', value: 'audio' },
        { label: 'Image', value: 'image' },
    ];
    const [seletectedTypeModule, setSelectedTypeModule] = useState(q_typesModule[0]);
    const [typeModule, setTypeModule] = useState(seletectedTypeModule?.value);
    const [inputList, setInputList] = useState([]);
    const [employee, setEmployee] = useState()
    const [post, setPoste] = useState()
    const [formError, setFormError] = useState({})
    const handleTypeModuleFormat=(type)=>{
        let moduleType=type;
        let restrein=".pdf";
        switch (moduleType) {
            case 'video':
                restrein="video/*"
                break;
         case 'audio':
                restrein="audio/*"
                break;
         case 'image':
                restrein="image/*"
                break;
         case 'document':
                restrein=".pdf"
                break;
        }
        setRestrein(restrein)
    }
    const handleClose = () => {
        setShow(null)
        setFormError({})
    }
    const handleTitle = (e) => {
        setTitle(e.target.value)
        let data =handleValidation(t,{type:"text",name:'title','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handleName = (e) => {
        setName(e.target.value)
        let data =handleValidation(t,{type:"text",name:'name','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handleQuestion = (e) => {{setQuestion(e.target.value)
        let data =handleValidation(t,{type:"text",name:'question','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }};
    const handleResponses = (e) => {{setResponses(e.target.value)}
    let data =handleValidation(t,{type:"text",name:'response','value':e.target.value,required:true});
    let form=formError;
            form[data.name]=data   
            setFormError(form)
};
    const handleTypeSelect = (e) => {
      //  console.log('type ==> ',e.target.value)
        setType(e.value)
        let data =handleValidation(t,{type:"text",name:'typeSelect','value':e.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
   const handleTypeSelectModule = (e) => {
      //  console.log('type ==> ',e.target.value)
        setTypeModule(e.value)
       handleTypeModuleFormat(e.value)
       let data =handleValidation(t,{type:"text",name:'typeSelectModule','value':e.value,required:true});
       let form=formError;
               form[data.name]=data   
               setFormError(form)
    };
    const handleDescription = (e) => {
        setDescription(e.target.value)
    };

   const handleFile = (e) => {
       if(e) {
           let file_data = e.target.files[0]
           setCours(file_data)
           setCoursName(file_data.name)
           let data =handleValidation(t,{type:"text",name:'file','value':file_data.name,required:true});
           let form=formError;
                   form[data.name]=data   
                   setFormError(form)
       }
    };
    const handlePropositions = (e,position) => {
        if (inputList.length<=3){
            const list = [...inputList];
            list[position] = e.target.value;
            setInputList(list);
            let data =handleValidation(t,{type:"text",name:'propositions','value':list.name,required:true,isArray:true});
           let form=formError;
                   form[data.name]=data   
                   setFormError(form)
        }
    };
    const handleSelectEmployee = e => setEmployee(e);
    const handleSelectPoste = e => setPoste(e);
    const addNewProp = ()=>{
        if (inputList.length<=3){
            setInputList([...inputList, inputList.length]);
        }
    };
    const removeProp = index => {
        const list = [...inputList];
        list.splice(index-1, 1);
        setInputList(list);
    };
    const  preselectType= (val)=>{
        let count=-1,number=undefined;
        q_types.forEach(r=>{
            count++;
            if(r.value===val) number=count;
        })
        return number;
    };
    const readTestFormation= (module) => {
        setCurrentTestRow(module)
        setShow('showTest')
    }
    const resetTestFormation= () => {
        setCurrentTestRow({})
        setQuestion("");
        setResponses("");
        setSelectedType(q_types[0]);
        setType(seletectedType.value)
        setInputList([]);
        setTestFormationHasUpdate(false);
        setFormError({})
    }
    const editTestFormation= (module) => {
        setCurrentTestRow(module)
        setQuestion(module.question);
        setResponses(module.responses);
        let type_number =  preselectType(module.type)
        setSelectedType(q_types[type_number]);
        setType(module.type)
        setInputList(module.propositions)
        // setPropositions(module.propositions);
        setTestFormationHasUpdate(true);
        setShow('create_question')
        let data=  handleFinaleValidation(t,[
            {type:"text",name:'question','value':module.question,required:true},
            {type:"text",name:'responses','value':module.responses,required:true},
            {type:"text",name:'typeSelect','value':module.type,required:true},
            {type:"text",name:'propositions','value':module.propositions,required:true}
           ])
                setFormError(data)
    }
    const resetModuleFormation= () => {
        setCurrentModuleRow({})
        setTitle("");
        setSelectedTypeModule(q_typesModule[0]);
        setTypeModule(seletectedTypeModule.type)
        setDescription("");
        setHasUpdate(false);
        setShow(null)
        setFormError({})
    };
    const editModuleFormation= (module) => {
        setCurrentModuleRow(module)
        setTitle(module.title);
        let type_number =  preselectType(module.type)
        setSelectedTypeModule(q_typesModule.find(t=>t.value===module.type));
        setTypeModule(module.type)
        setDescription(module.description);
        setCoursName(module.cours);
        setHasUpdate(true);
        setShow('create_module')
        let data=  handleFinaleValidation(t,[
            {type:"text",name:'title','value':module.title,required:true},
            {type:"text",name:'typeSelectModule','value':module.type,required:true},
            {type:"text",name:'file','value':module.cours,required:true}
           ])
                setFormError(data)
    };
   const createTestsFormation= () => {
        let o = {
            "question": question,
            "type": type,
            "propositions": inputList,
            "responses": responses,
            "formation_info": params_id,
            "sio_channel": "",
            "id":current_test_row?._id
        };
        if (!isTestFormationUpdate)
        props.action_createTestsFormation(o)
        else
        props.action_updateTestsFormation(o);
        setShow(null)
        props.action_loading(true)
       resetTestFormation();
    }
   const confirmAction=(data)=>{
       setShow('confirm')
       setActionData(data);
   }
   const confirmDeleteAction=()=>{
       let data=actionData;
       switch (data.target) {
           case 'module':
               props.action_deleteModuleFormation(data.o)
               break
           case 'test':
               props.action_deleteTestsFormation(data.o)
               break
       }
       props.action_loading(true)
       setShow(null)
   }
    const checkUserHasFinish=(formation)=>{
        let response =false;
        if(formation){
            if( formation.finisher_info && formation.finisher_info.length>0){
                let check = formation.finisher_info.find((p => p.user === user._id))
                if(check) response=true;
            }
        }
        return response;
    }
    const checkUserHasFinishTest=(test_formations)=>{
        let test_formation=test_formations[0],
            resp =false;
        if(test_formation){
            if( test_formation.formation_info.test_finisher_info && test_formation.formation_info.test_finisher_info.length>0){
                test_formation.formation_info.test_finisher_info.forEach(response=>{
                    let check=  response[test_formation.formation_info.assign_to.find(p=>p.employee_info.user===user._id)?.employee_info._id]
                    if(check){ resp=true; return; }
                })
            }
        }
        return resp;
    }
    const createModuleFormation= async  () => {
      let  file=cours;
      setCours(undefined)
    let o = {
        "type": typeModule,
        "title": title,
        "description": description,
        "formation_info": params_id,
        "sio_channel": "",
        "id": current_module_row?._id
    };

    if(file){
             uploadOnCdn(file)
                .then(resp => {
                    console.log('cdn upload pp response : ', resp);
                    setCours(resp?.filename);
                   o.cours=resp?.filename;
                    if (!isUpdate)
                        props.action_createModuleFormation(o)
                    else
                        props.action_updateModuleFormation(o);
                })
        }else{
        if (!isUpdate)
            props.action_createModuleFormation(o)
        else
            props.action_updateModuleFormation(o);
        }

    setShow(null)
    props.action_loading(true)
    resetModuleFormation()
    }
 const createParoursFormation= () => {
        let o = {
            "name": name,
            "employee": employee,
            "post": post,
            "formation_info": params_id,
            "sio_channel": "",
            "id":current_module_row?._id
        };
        if (!isUpdate)
        props.action_createParcoursFormation(o)
        else
        props.action_updateParcoursFormation(o);
        setShow(null)
        props.action_loading(true)
        setFormError({})
    }
    const createSurvey = () => {
        let o = {
            "duration": duration,
            "description": description,
            "title": title,
            "status": status,
            "sio_channel": "",
        };
        if (!isUpdate)
            props.action_createExpense(o)
        else{
            o.id = current_row.id;
            props.action_updateExpense(o);
        }
        setShow(null);
        props.action_loading(true)
        setFormError({})
    };
    const deleteSurvey = () => {
        props.action_deleteExpense();
        setShow(null);
        props.action_loading(true)
    };
    const contextMenu = [
            {label: t("modules"), eventKey: 'stats'},
            // {label: 'Parcours de formation', eventKey: 'comments'},
            {label: t("test"), eventKey: 'questions'},
        ];
    const checkHasFinishedModule=(test_formations)=>{
        let test_formation=test_formations[0],
            response=false;
        if(test_formation) {
            if (test_formation.formation_info) {
                if (test_formation.formation_info.assign_to && test_formation.formation_info.assign_to.length > 0) {
                    response = test_formation.formation_info.assign_to.find(p => p.employee_info.user === user._id)?.status
                }
            }
        }
        return response;
    }
    const formatResponseData=(res)=>{
        let data=[];
        if(res.includes('|')){
         data=res.split('|')
        }else{
            data[0]=res;
        }
        return data
    }
    const getUserNote=(test_formations)=>{
        let test_formation=test_formations[0],
            resp =false,
            note=0;
        if(test_formation){
            if( test_formation.formation_info.test_finisher_info && test_formation.formation_info.test_finisher_info.length>0){
                test_formation.formation_info.test_finisher_info.forEach(response=>{
                    let check_results=  response[test_formation.formation_info.assign_to.find(p=>p.employee_info.user===user._id)?.employee_info._id]
                    check_results.forEach(res=>{
                        note+=res.result;
                    })
                    return;
                })
            }
        }
        return note;
    }
    const countResult=(test_formations)=>{
        let test_formation=test_formations[0],
            total=0;
        if(test_formation){
            if( test_formation.formation_info.test_finisher_info && test_formation.formation_info.test_finisher_info.length>0){
                test_formation.formation_info.test_finisher_info.forEach(response=>{
                    let check_results=  response[test_formation.formation_info.assign_to.find(p=>p.employee_info.user===user._id)?.employee_info._id]
                    total= check_results.length;
                    return;
                })
            }
        }
        return total;
    }

    return <div id={"FormationDetails"}>
        <Container fluid>
            <Row>
                <Col sm={"12"} className={'text-left'}>
                    <Button className={"btn btn-light btn-sm"} onClick={e => history.push({pathname:'/dash/courses'})}>
                        <BI.BiChevronLeft style={{color: '#240265', fontWeight: '600',}} size={25}/> {t("return")}
                    </Button>
                </Col>
                <Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={'stats'}>
                        <div className="d-flex align-items-start justify-content-between">
                            <ContextMenu menu={contextMenu}/>
                        </div>

                        <Tab.Content>
                            <Tab.Pane className={"lastRollL"} eventKey="stats">
                                {isAdmin(role) ?
                                    <Row>
                                        <Col>
                                            <Button className={"float-right mb-4"} onClick={() => {
                                                setShow('create_module')
                                            }}>{t("new_module")}</Button>
                                        </Col>
                                    </Row>
                                    : ""
                                }
                                     <Row>
                                        {  props.rwState.module_formations && props.rwState.module_formations.length > 0 ?
                                            props.rwState.module_formations.map((p => {
                                                 return  <Col lg={"4"} className={"mb-2"}>
                                            <Card style={{border:"none",background: checkUserHasFinish(p) ? '#f8f8f8' : 'white',boxShadow:"0px 3px 40px #8b93a564"}}>
                                                <Card.Body>
                                                    <Card.Title>{p.title}
                                                    <span className={"float-right"}>
                                                        <BS.BsDisplayFill hidden={p.type!=="video"} variant={"primary"}/>
                                                        <BS.BsMusicNoteBeamed hidden={p.type!=="audio"} variant={"primary"}/>
                                                        <BS.BsBook hidden={p.type!=="document"} variant={"primary"}/>
                                                        <BS.BsCardImage hidden={p.type!=="image"} variant={"primary"}/>
                                                    </span>
                                                    </Card.Title>
                                                    <Card.Text>{limitText(p.description,150)}</Card.Text>
                                                    <Row style={{marginTop: '35 px'}}>
                                                        {isAdmin(role) ?
                                                    <Col md={2} className={"float-right"}>
                                                        <Button onClick={() => {editModuleFormation(p)}}  style={{backgroundColor:"transparent",border:"none",padding:"3px",marginTop:"-5px"}}>
                                                            <BI.BiEdit  style={{color: '#240265',fontWeight:'600',}} size={25}/>
                                                        </Button>
                                                    </Col>
                                                            : ""}
                                                        {isAdmin(role) ?
                                                    <Col md={2} className={"float-right"}>
                                                        <Button style={{backgroundColor:"transparent",border:"none",padding:"3px",marginTop:"-5px"}} onClick={()=>{confirmAction({target:'module',o:p})}}>
                                                            <BI.BiTrash  style={{color: 'red',fontWeight:'600',}} size={25}/>
                                                        </Button>
                                                    </Col>
                                                            : ""}

                                                            <Col md={isAdmin(role) ? 8 : 6} className={isAdmin(role) ? "text-right" : "text-left"}>
                                                            <Button className={"btn btn-light btn-sm"}
                                                                    onClick={e => history.push({pathname:'/dash/courses/learning/'+p._id})}>
                                                                visualiser
                                                            </Button>
                                                        </Col>
                                                        {!isAdmin(role) ?
                                                        <Col md={6} className={"text-right"}>
                                                            { checkUserHasFinish(p) ? <div className={"badge badge-outline-success badge-sm"} style={{color:"green"}}><BI.BiCheckCircle size={15}/> Terminé</div> : ""}
                                                        </Col>
                                                            : ""}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                            } ))
                                            :
                                            <h6 className={"col-12 alert alert-warning"}> {t("no_module_added")} </h6>
                                            }
                                    </Row>
                            </Tab.Pane>
                            {/*<Tab.Pane className={"lastRollL"} eventKey="comments">*/}
                            {/*    <Row>*/}
                            {/*        <Col>*/}
                            {/*            <Button className={"float-right mb-4"} onClick={()=>{setShow('parcours')}}>Nouveau Parcours</Button>*/}
                            {/*        </Col>*/}
                            {/*    </Row>*/}
                            {/*        <Row>*/}
                            {/*            {  props.rwState.parcours_formations && props.rwState.parcours_formations.length > 0 ?*/}
                            {/*                props.rwState.parcours_formations.map((p => {*/}
                            {/*                    return  <Col lg={"4"}>*/}
                            {/*                <Card className={"p-2 border-0"} style={{borderRadius:10}}>*/}
                            {/*                    <h6 style={{color: '#240265'}} >{p.name}</h6>*/}
                            {/*                    /!*<div>{p.description}</div>*!/*/}
                            {/*                    <Row style={{marginTop: '35px'}}>*/}
                            {/*                        <Col sm={"auto"}  style={{color: '#240265'}}><BI.BiUserPin size={22}/> 12 personnes</Col>*/}
                            {/*                        <Col sm={"auto"}>*/}
                            {/*                            <Button style={{backgroundColor:"transparent",border:"none",padding:"3px",marginTop:"-5px"}}>*/}
                            {/*                                <BI.BiEdit  style={{color: '#240265',fontWeight:'600',}} size={25}/>*/}
                            {/*                            </Button>*/}
                            {/*                        </Col>*/}
                            {/*                        <Col sm={"auto"} >*/}
                            {/*                            <Button style={{backgroundColor:"transparent",border:"none",padding:"3px",marginTop:"-5px"}}>*/}
                            {/*                                <BS.BsEye onClick={()=>{setShow('details_parours')}} style={{color: '#240265',fontWeight:'600',}} size={25}/>*/}
                            {/*                            </Button>*/}
                            {/*                        </Col>*/}
                            {/*                        <Col sm={"auto"} >*/}
                            {/*                            <Button style={{backgroundColor:"transparent",border:"none",padding:"3px",marginTop:"-5px"}}>*/}
                            {/*                                <BI.BiTrash  style={{color: 'red',fontWeight:'600',}} size={25}/>*/}
                            {/*                            </Button>*/}
                            {/*                        </Col>*/}
                            {/*                    </Row>*/}
                            {/*                </Card>*/}
                            {/*            </Col>*/}
                            {/*                } ))*/}
                            {/*                :*/}
                            {/*                <h6 className={"col-12 alert alert-warning"}> Aucun parcours disponible </h6>*/}
                            {/*            }*/}
                            {/*        </Row>*/}
                            {/*</Tab.Pane>*/}
                            <Tab.Pane className={"lastRollL"} eventKey="questions">
                                {isAdmin(role) ?
                                    <Row>
                                        <Col>
                                            <Button className={"float-right mb-4"} onClick={() => {
                                                setShow('create_question')
                                            }}>{t("add_new_question")}</Button>
                                        </Col>
                                    </Row>
                                    : ""
                                }
                                <div className={"p-3 border-0"} style={{borderRadius:10}}>
                                    {isAdmin(role) ?
                                        <Row>
                                            {props.rwState.tests_formations && props.rwState.tests_formations.length > 0 ?
                                                    props.rwState.tests_formations.map((p => {
                                                        return <Col md={4}>
                                                            <Card className={"p-2 border-0 mt-4"}
                                                                  style={{borderRadius: 10,boxShadow:"0px 3px 40px #8b93a564"}}>
                                                                <div style={{color: '#240265'}}
                                                                     className={"name"}>{p.question}</div>
                                                                <Row style={{marginTop: '20px'}}>
                                                                    <Col md={6} className={'text'}
                                                                         style={{color: '#240265'}}><BI.BiComment
                                                                        size={22} className={"icon"}/>
                                                                        {p.type}
                                                                    </Col>
                                                                    <Col md={2} classMane={"float-right"}>
                                                                        <Button onClick={() => {
                                                                            editTestFormation(p)
                                                                        }} style={{
                                                                            backgroundColor: "transparent",
                                                                            border: "none",
                                                                            padding: "3px",
                                                                            marginTop: "-5px"
                                                                        }}>
                                                                            <BI.BiEdit style={{
                                                                                color: '#240265',
                                                                                fontWeight: '600',
                                                                            }} size={25}/>
                                                                        </Button>
                                                                    </Col>
                                                                    <Col md={2} classMane={"float-right"}>
                                                                        <Button style={{
                                                                            backgroundColor: "transparent",
                                                                            border: "none",
                                                                            padding: "3px",
                                                                            marginTop: "-5px"
                                                                        }} onClick={() => {
                                                                            readTestFormation(p)
                                                                        }}>
                                                                            <BS.BsEye onClick={() => {
                                                                                setShow('details')
                                                                            }} style={{
                                                                                color: '#240265',
                                                                                fontWeight: '600',
                                                                            }} size={25}/>
                                                                        </Button>
                                                                    </Col>
                                                                    <Col md={2} classMane={"float-right"}>
                                                                        <Button style={{
                                                                            backgroundColor: "transparent",
                                                                            border: "none",
                                                                            padding: "3px",
                                                                            marginTop: "-5px"
                                                                        }} onClick={() => {
                                                                            confirmAction({target: 'test', o: p})
                                                                        }}>
                                                                            <BI.BiTrash style={{
                                                                                color: 'red',
                                                                                fontWeight: '600',
                                                                            }} size={25}/>
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                        </Col>
                                                    }))
                                                    :
                                                <h6 className={"col-12 alert alert-warning"}> {t("no_test_added")} </h6>
                                            }
                                        </Row>
                                        :
                                        <Row>
                                            {props.rwState.tests_formations && props.rwState.tests_formations.length > 0 ?
                                                checkHasFinishedModule(props.rwState.tests_formations) ?
                                                    checkUserHasFinishTest(props.rwState.tests_formations) ?
                                                        <Col sm={12} className={"text-center pt-5 mt-5"} sty>
                                                            <img src={'../../../success.png'} className={'mt-5 mb-2'} alt="" width={"100px"}/>
                                                            <h4 className={"col-12"}> {t("test_ended")}
                                                                . <br/> {t("received_note")} <span>{getUserNote(props.rwState.tests_formations) +' / '+countResult(props.rwState.tests_formations) }</span></h4>
                                                        </Col>
                                                        :
                                                   <div className={"col-12 text-center mt-5 pt-5"}>
                                                       <Button className={"btn btn-light btn-sm"}
                                                               onClick={e => history.push({pathname:'/dash/courses/test/'+params_id})}>
                                                           <BI.BiBook style={{color: '#240265', fontWeight: '600',}} size={25}/> {t("start_test")}
                                                       </Button>
                                                   </div>

                                                    :
                                                    <h6 className={"col-12 mt-5 pt-5 text-center"}>
                                                        <BI.BiBlock style={{color: '#240265', fontWeight: '600',}} size={125}/>
                                                        <br/>
                                                       {t("notification_test_before")}
                                                    </h6>
                                                :
                                                <h6 className={"col-12 mt-5 pt-5 text-center"}>
                                                    <BI.BiBlock style={{color: '#240265', fontWeight: '600',}} size={125}/>
                                                    <br/>
                                                    {t("warning_no_text_available_on_formation")} </h6>
                                            }
                                        </Row>
                                    }
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Col>
            </Row>
          </Container>

        {/*  modals */}

        {/*create new question*/}
        <Modal centered show={show === 'create_question'} onHide={handleClose}>
            <Modal.Header className={"bg-primary"}>
                <h4 className={"col-lg-12 text-white text-center"}>{t("new_question_btn")}</h4>
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <Form.Group>
                    <Col>
                    <Form.Label>{t("type_question_label")}</Form.Label>
                    <Select noOptionsMessage={() => t("no_data_available")}
                        options={q_types}
                        defaultValue={seletectedType}
                        styles={{
                            control: base => ({
                                ...base,
                            })
                        }}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: '#6600CC',
                                primary25: '#6600CC25',
                                primary75: '#6600CC75',
                                primary50: '#6600CC50'
                            },
                        })}
                        placeholder={t("type_question_label")}
                        name='type'
                        onChange={handleTypeSelect}
                        className={handleinputValidationError(formError,'typeSelect')}
                        />
                               {handleTextValidationError(formError,'typeSelect')}
             
                    </Col>

                </Form.Group>
                <Form.Group>
                    <Col>
                        <Form.Label>{t("the_question")}</Form.Label>
                        <Form.Control
                            onChange={handleQuestion}
                            defaultValue={question}
                            type={"text"}
                            className={handleinputValidationError(formError,'question')}
                            />
                                   {handleTextValidationError(formError,'question')}
                 
                    </Col>
                </Form.Group>
                <div hidden={type!=="qcm" && type!=="qcu"}>
                    <Button onClick={addNewProp} style={{borderRadius:"100%"}} className={"foat-right"}><BS.BsPlus/></Button>
                    <Form.Group>
                        <Col>
                            <Form.Label>{t("proposition")} 1 </Form.Label>
                            <Form.Control
                                onChange={(e)=>handlePropositions(e,0)}
                                type={"text"} defaultValue={inputList && inputList.length>0 ? inputList[0] : ''}/>
                        </Col>
                    </Form.Group>
                    {inputList.map((pos,index)=>{
                        return( <Form.Group key={index}>
                            <Col>
                                <Form.Label>{t("proposition")} {isUpdate ? index + 1 :index + 2  }</Form.Label>
                                <Form.Control
                                    onChange={(e) => handlePropositions(e, !isUpdate  ? index + 1 : index)}
                                    key={pos}
                                    defaultValue={inputList[isUpdate ? index : index+1]}
                                    type={"text"}/>
                                <Button onClick={() => removeProp(index + 1)} variant={"danger"}
                                        style={{borderRadius: "100%"}}
                                        className={"float-right"}><BI.BiMinus/></Button>
                            </Col>
                        </Form.Group>)
                        })
                    }
                </div>
                <Form.Group>
                    <Col>
                        <Form.Label>{t("good_answers")}</Form.Label> <br/>
                        <small>{t("good_answers_label")}</small>
                        <br/>
                        <small style={{color:"#54c018"}}>{t("possible_answers")} :
                            {type==='response' ? t("free_answer_exemple_type") : ''}
                            {type==='true_or_false' ? " `true` ou `false`" : ''}
                            {type==='yes_or_no' ? " `yes` ou `no`" : ''}
                            {type==='qcm' || type==='qcu'  ? t("qcm_exemple_type") : ''}
                        </small>
                        <Form.Control
                            onChange={handleResponses}
                            defaultValue={responses}
                            type={"text"}/>
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("cancel")}
                </Button>
                <Button variant="primary" 
                 disabled={
                    handleDisabledBtnValidationError(formError,
                    ['typeSelect','question'])
                }
                onClick={createTestsFormation}>
                    {t("save")}
                </Button>
            </Modal.Footer>
        </Modal>
        {/*create new modules*/}
        <Modal centered show={show === 'create_module'} onHide={handleClose}>
            <Modal.Header className={"bg-primary"}>
                <h4 className={"col-lg-12 text-white text-center"}>{t("new_module")}</h4>
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <Form.Group>
                    <Col>
                        <Form.Label>{t("module_type")} <span className={'required_box'}>*</span></Form.Label>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={q_typesModule}
                            defaultValue={seletectedTypeModule}
                            styles={{
                                control: base => ({
                                    ...base,
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("module_type")}
                            name='type'
                            onChange={handleTypeSelectModule}
                            className={handleinputValidationError(formError,'typeSelectModule')}
                            />
                                   {handleTextValidationError(formError,'typeSelectModule')}
                 
                    </Col>

                </Form.Group>
                <Form.Group>
                    <Col>
                        <Form.Label>{t("module_title_text")}  <span className={'required_box'}>*</span></Form.Label>
                        <Form.Control
                            onChange={handleTitle}
                            defaultValue={current_module_row?.title}
                            type={"text"}
                            className={handleinputValidationError(formError,'title')}
                            />
                                   {handleTextValidationError(formError,'title')}
                 
                    </Col>
                </Form.Group>
                <Form.Group>
                    <Col>
                        <Form.Label>{t("module_description")}</Form.Label>
                        <Form.Control
                            onChange={handleDescription}
                            defaultValue={current_module_row?.description}
                            as={"textarea"}/>
                    </Col>
                </Form.Group>
                <Form.Group>
                    <Col>
                        <Form.File id="formcheck-api-custom" custom>
                            <Form.File.Input onChange={handleFile} accept={restrein} 
                            className={handleinputValidationError(formError,'file')}
                            />
                            <Form.File.Label data-browse={t("choose_a_file")}>
                                {coursName}
                            </Form.File.Label>
                            <Form.Control.Feedback type="valid">5MB</Form.Control.Feedback>
                        </Form.File>
                            
                                   {handleTextValidationError(formError,'file')}
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("cancel")}
                </Button>
                <Button variant="primary"
                 disabled={
                  handleDisabledBtnValidationError(formError,
                    ['title','typeSelectModule','file']) 
                }
                onClick={createModuleFormation}>
                    {t("save")}
                </Button>
            </Modal.Footer>
        </Modal>
        {/*read Module details*/}
        <Modal centered show={show === 'showTest' && current_test_row} onHide={handleClose}>
            <Modal.Header close className={"bg-primary"}>
                <h4 className={"col-lg-12 text-white text-center"}>{t("module_title")}</h4>
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <div className={"col-12"}>
                    <div><strong>{t("type")} :</strong> {current_test_row ? current_test_row.type :''}</div>
                    <br/>
                    <h6><strong>{t("question")} :</strong></h6>
                    <p>{current_test_row ? current_test_row.question :''}</p>
                </div>
                <hr/>
                {current_test_row && current_test_row.responses && formatResponseData(current_test_row.responses).length > 0 ?
                    < div className = {"col-12"} >
                        <h6><strong> {t("Reponse possible")} </strong> </h6>
                        <ListGroup variant="flush">
                            { formatResponseData(current_test_row.responses).map((surv,i) => {
                                return <ListGroup.Item className={"ml-0 pl-0"}>{surv}</ListGroup.Item>
                            })}
                        </ListGroup>
                    </div>
                    :
                    <span>{t("no_question_proposed")}</span>
                }
                <hr/>
                {current_test_row && current_test_row.propositions && current_test_row.propositions.length > 0 ?
                    <div className = {"col-12"} >
                        <h6><strong> {t("propositions")}</strong> </h6>
                        <ListGroup variant="flush">
                            { current_test_row.propositions.map((surv,i) => {
                                return <ListGroup.Item className={"ml-0 pl-0"}>{surv}</ListGroup.Item>
                            })}
                        </ListGroup>
                    </div>
                    :
                    <span>{t("no_proposition_maked")}</span>
                }

            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("close")}
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("confirmation")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={"text-center"}>
                    {t("confirme_irreversile_deletion_text")}
                </div>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>
                        <Button variant="primary" onClick={confirmDeleteAction}>
                            {t("yes_delete")}
                        </Button>
                        <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                            {t("cancel")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
        {/*show Test  details*/}
        <Modal centered show={show === 'showTest' && current_test_row} onHide={handleClose}>
            <Modal.Header close className={"bg-primary"}>
                <h4 className={"col-lg-12 text-white text-center"}>{t("Détails")}</h4>
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <div className={"col-12"}>
                    <div><strong>{t("type")} :</strong> {current_test_row ? current_test_row.type :''}</div>
                    <br/>
                    <h6><strong>{t("question")} :</strong></h6>
                    <p>{current_test_row ? current_test_row.question :''}</p>
                </div>
                <hr/>
                {current_test_row && current_test_row.responses && formatResponseData(current_test_row.responses).length > 0 ?
                    < div className = {"col-12"} >
                        <h6><strong> {t("possible_answers")}</strong> </h6>
                        <ListGroup variant="flush">
                            { formatResponseData(current_test_row.responses).map((surv,i) => {
                                return <ListGroup.Item className={"ml-0 pl-0"}>{surv}</ListGroup.Item>
                            })}
                        </ListGroup>
                    </div>
                    :
                    <span>{t("no_question_proposed")}</span>
                }
                <hr/>
                {current_test_row && current_test_row.propositions && current_test_row.propositions.length > 0 ?
                    <div className = {"col-12"} >
                        <h6><strong> {t("propositions")}</strong> </h6>
                        <ListGroup variant="flush">
                            { current_test_row.propositions.map((surv,i) => {
                                return <ListGroup.Item className={"ml-0 pl-0"}>{surv}</ListGroup.Item>
                            })}
                        </ListGroup>
                    </div>
                    :
                    <span>{t("no_proposition_maked")}</span>
                }

            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("close")}
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal centered show={show === 'details_parours'} onHide={handleClose}>
            <Modal.Header close className={"bg-primary"}>
                <h4 className={"col-lg-12 text-white text-center"}>{t("Détails")}</h4>
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <div>
                    <h6 className={"text-primary"}>{t("employeed")}</h6>
                    <ListGroup variant="flush">
                        <ListGroup.Item>Cras justo odio</ListGroup.Item>
                        <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                    </ListGroup>
                </div>
                <div className={"mt-2"}>
                    <h6 className={"text-primary"}>{t("formations_title")}</h6>
                    <ListGroup variant="flush">
                        <ListGroup.Item>Cras justo odio</ListGroup.Item>
                        <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                    </ListGroup>
                </div>

            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("close")}
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal centered show={show === 'parcours'} onHide={handleClose}>
            <Modal.Header className={"bg-primary"}>
                <h4 className={"col-lg-12 text-white text-center"}>{t("new_parcours_formation")}</h4>
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <Col>
                    <Form.Group>
                            <Form.Label>{t("parcours_name_label")}</Form.Label>
                            <Form.Control
                                onChange={handleName}
                                defaultValue={name}
                                type={"text"}/>
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group>
                        <Form.Label>{t("parcours_employed_target")}</Form.Label>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={props.rwState.colleagues.filter(r=>r.activated)}
                            getOptionLabel={o => o.firstname+ " "+o.lastname}
                            getOptionValue={o => o.id}
                            styles={{
                                control: base => ({
                                    ...base,
                                    minHeight: 38
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            isMulti
                            placeholder={t("parcours_employed_target")}
                            onChange={e => handleSelectEmployee(e)}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>{t("parcours_poste_target")} </Form.Label>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={props.rwState.positions}
                            getOptionLabel={o => o.title}
                            getOptionValue={o => o.id}
                            styles={{
                                control: base => ({
                                    ...base,
                                    minHeight: 38
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            isMulti
                            placeholder={t("position_title_unique")}
                            onChange={e => handleSelectPoste(e)}
                        />
                    </Form.Group>
                </Col>
            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("cancel")}
                </Button>
                <Button variant="primary" onClick={createParoursFormation}>
                    {t("create")}
                </Button>
            </Modal.Footer>
        </Modal>

    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(FormationDetails);
