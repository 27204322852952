import React, {useEffect, useState} from 'react';
import './Profile.css'
import {Button, Col, Container, Form, Modal, Row, Tab} from "react-bootstrap";
import ContextMenu from "../../menus/ContextMenu";
import * as BS from "react-icons/bs";
import { FiDownload, FiTrash, FiEye} from "react-icons/fi";
import {BsFileText} from "react-icons/bs";
import {connect} from "react-redux";
import * as actions from "../../../actions";
import Select from "react-select";
import countries from "../../../assets/countries";
import {formatDate} from 'react-day-picker/moment';
import UploadPersoDoc from "../../widgets/UploadPersoDoc";
import {
    deleteProfileDocument,
    getFileFromCdn,
    getFileUrl,
    updateProfileDocuments,
    updateProfileInformations,
    uploadOnCdn
} from '../../../service'
import { useTranslation } from 'react-i18next';
import {useSocket} from '../../Custom-Hooks'
import {v1 as uuidv1} from 'uuid'
import mSvg from "../../../assets/gender/m.png";
import {BsUpload} from "react-icons/bs";
import {toast} from "react-toastify";
import {handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError} from "../../../helpers/helper";
import DayPickerInput from "react-day-picker/DayPickerInput";
const mapStateToProps = (state) => {
    return { rwState: state }
}
const mapDispatchToProps = { ...actions }

const Profile = (props) => {
    const { t } = useTranslation();
    const [user, setUser] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [cv, setCv] = useState({ key: 'cv', title: t('curriculum_vitae'), preview: '', raw: '', type: '', name: '' })
    const [rib, setRib] = useState({ key: 'rib', title: t("rib"), preview: '', raw: '', type: '', name: '' })
    const [cin, setCin] = useState({ key: 'cin', title: t("cni"), preview: '', raw: '', type: '', name: '' })
    const [doc, setDoc] = useState({ key: 'doc', title: t("doc"), preview: '', raw: '', type: '', name: '' })
     const [SIO_CHANNEL_UPDATE_DOCS] = useState('update_profile_doc_' + uuidv1())
    const [SIO_CHANNEL_DELETE_DOC] = useState('delete_profile_doc_' + uuidv1())
    const [SIO_CHANNEL_UPDATE_PROFIL_PERSO] = useState('update_profil_perso_' + uuidv1())
    const [SIO_CHANNEL_UPDATE_PROFIL_IMAGE] = useState('update_profil_image_' + user.session_id)
    const [cdn_documents, setCdn_documents] = useState([])
    const [infos_documents, setInfos_documents] = useState([])
    const socketIoInstance = useSocket()
    const [current_documents, setCurrent_documents] = useState([])
    const [perso_form, setPerso_form] = useState({})
    const [personal, setPersonal] = useState()
    const [maritial, setMaritial] = useState()
    const personal_names = ['gender', 'birthdate','ethnie', 'nationality', 'physical_address', 'urgency_contact', 'nationalid_type', 'nationalid_num','health_state']
    const maritial_names = ['spouse', 'children', 'children_names']
    const gender_options = [{ label: t("man"), value: "H" }, { label: t("woman"), value: 'F' }, { label: t("other"), value: 'A' }]
    const ethnie_select_options = [
        {label: t("black_african"), value: 'noir'},
        {label: t("aboriginal"), value: 'autochtones'},
        {label: t("white"), value: 'blanc'},
        {label: t("middle_est"), value: 'moyen-orient'},
        {label: t("asian"), value: 'asiatique'},
        {label: ("latino"), value: 'hispanique-latino'},
    ]
    const [selectedBranch, setSelectedBranch] = useState()
    const [selectedDepartment, setSelectedDepartment] = useState()

    const [selectedPosition, setSelectedPosition] = useState()
    const [selectedSuperieur, setSelectedSuperieur] = useState()
    const [type_salary, setTypeSalaire] = useState(undefined)
    const [salary, setSalary] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [hiredDate, setHiredDate] = useState()
    const [proStartDate, setProStartDate] = useState()
    const [probEndDate, setProbEndDate] = useState()
    const [mobile, setMobile] = useState()

    const [selected_ethnie, setSelectedEthnie] = useState()
    const statut_matrimonial = [{ label: t("celibate"), value: 0 }, { label: t("maried"), value: 1 }, { label: t("other"), value: 9 }]
    const [selectedGender, setSelectedGender] = useState()
    const [selectedNationality, setSelectedNationality] = useState()
    const [selectedStatutMatrimonial, setSelectedStatutMatrimonial] = useState()
    const [tabFileViewer, setTabFileViewer] = useState([]);
    const [ppImage,setPpImage]=useState(mSvg)
    const [birthday_visible,setBirthDayVisible]=useState()
    const [formError, setFormError] = useState({})
    useEffect(() => {
        props.action_listColleagues()
        props.action_listDepartments();
        props.action_listBranches();
        props.action_listPositions();
        props.action_listRoles();
    }, [])

 useEffect(() => {
        let connected_user = JSON.parse(window.localStorage.getItem('glowhr_user'))
     console.log(connected_user,'**** collegie data ***')
        setUser(connected_user)
        setp(Math.floor(Math.random()*100));
        setPpImage(connected_user.user.image_url.length>0?getFileUrl(connected_user.user.image_url):ppImage)
        props.action_setTitle(t("profile_text"))
        setPersonal(connected_user.user.personal)
        setMaritial(connected_user.user.maritial)
        // setEthnie(connected_user.user?.personal?.ethnie)

         setSelectedPosition(connected_user.user.position)
         setSelectedBranch(connected_user.user?.branch)
         setSelectedDepartment(connected_user.user.department)
         setSelectedSuperieur(connected_user.user?.job_info[0]?.reports_to)
         setProStartDate(connected_user.user?.job_info[0]?.probation?.probation_start);
         setProbEndDate(connected_user.user?.job_info[0]?.probation?.probation_end);
         setSalary(connected_user.user?.job_info[0]?.salary)
         setHiredDate(connected_user.user.hired_on)
         setMobile(connected_user.user.mobile)
         setStartDate(connected_user.user?.job_info[0]?.start_date);
         setEndDate(connected_user.user?.job_info[0]?.end_date);
         setTypeSalaire(connected_user.user?.job_info[0]?.type_salary);

        setCurrent_documents(connected_user.user.documents ? connected_user.user.documents : [])
        setSelectedNationality(countries.find(option => option.alpha3Code === connected_user?.user?.personal?.nationality))
        setSelectedGender(gender_options.map(option => {
                if(option.value === connected_user?.user?.personal?.gender) {
                    return option
                }
            }))
        setSelectedEthnie(ethnie_select_options.find(option => option.value === connected_user?.user?.personal?.ethnie))
        setSelectedStatutMatrimonial(statut_matrimonial.find(option => option.value === connected_user?.user?.maritial?.spouse))
        setBirthDayVisible(connected_user?.user?.birthday_visible);
        let data=  handleFinaleValidation(t,[
            {type:"text",name:'gender','value':connected_user?.user?.personal?.gender,required:true},
            {type:"text",name:'nationality','value':connected_user?.user?.personal?.nationality,required:true},
            {type:"text",name:'ethnie','value':connected_user?.user?.personal?.ethnie,required:true},
            {type:"text",name:'firstname','value':connected_user?.user?.firstname,required:true},
            {type:"text",name:'lastname','value':connected_user?.user?.lastname,required:true},
            {type:"date",name:'birthdate','value':connected_user?.user?.personal?.birthdate,required:true},
            {type:"email",name:'email','value':connected_user?.user?.email,required:true},
            {type:"tel",name:'mobile','value':connected_user?.user?.mobile,required:true},
            {type:"text",name:'physical_address','value':connected_user?.user?.personal.physical_address,required:true},
            {type:"tel",name:'urgency_contact','value':connected_user?.user?.personal?.urgency_contact,required:true}
           ])
                setFormError(data)
    }, [])

    useEffect(() => {
        if (infos_documents.length > 0 && infos_documents.length === cdn_documents.length) {
            listenUpdateProfileResponse()
            updateProfileDocuments(infos_documents, SIO_CHANNEL_UPDATE_DOCS)
                .then(resp => {
                    setCdn_documents([])
                    setInfos_documents([])
                    reset()
                })
                .catch(err => console.log(err))
        }
    }, [infos_documents])

    const handleSelectBranchFilter = (e) => {
        (e && e.length>0) ? setSelectedBranchFilter(e.map(res=> {return res._id})) : setSelectedBranchFilter([]);
    };
    const handleSelectDepartmentFilter = (e) => {
        (e && e.length>0) ? setSelectedDepartmentFilter(e.map(res=> {return res._id})) : setSelectedDepartmentFilter([]);
    };

    const logout = () => {
        window.localStorage.removeItem('glowhr_user')
        window.location='/auth'
    }

    const contextMenu = [
        { label: t('personnal_profil'), eventKey: 'perso' },
        { label: t("profil_professionnel"), eventKey: 'pro' },
        { label: t('doc_perso_text'), eventKey: 'docs' }
    ]

    const handleCvChange = e => {
        let stateE = typeof e
        if(e.target){
        if (stateE !== 'string') {
            let cv_data = e.target.files[0]
            const name = cv_data.name
            const type = name.split('.').pop()
            let preview = URL.createObjectURL(cv_data)
            let test = { ...cv, preview, raw: cv_data, type, name }
            setCv(test)
            setCdn_documents([...cdn_documents, { key: cv.key, title: cv.title, raw: cv_data }])
            setTabFileViewer(test)
        } else {
            let preview = e
            let test = { ...cv, preview }
            setCv(test)
            setCdn_documents([...cdn_documents, { key: cv.key, title: cv.title }])
            setTabFileViewer(test)
        }
    }else{
        toast(t("no_doc_file"),{type:"warning"});
    }
    }

    const handleRibChange = e => {
        let stateE = typeof e
        if(e.target){
        if (stateE !== 'string') {
            let rib_data = e.target.files[0]
            let name = rib_data.name
            let type = name.split('.').pop()
            let new_cdn_documents_state = [...cdn_documents, { key: rib.key, title: rib.title, raw: rib_data }]
            setCdn_documents(new_cdn_documents_state)
            let preview_url = URL.createObjectURL(rib_data)
            let new_rib_state = { ...rib, preview: preview_url, raw: rib_data, type, name }
            setRib(new_rib_state)
        } else {
            let new_cdn_documents_state = [...cdn_documents, { key: rib.key, title: rib.title }]
            setCdn_documents(new_cdn_documents_state)
            let preview_url = e
            let new_rib_state = { ...rib, preview: preview_url }
            setRib(new_rib_state)
        }
    }else{
        toast(t("no_doc_file"),{type:"warning"});
    }
    }

    const handleCinChange = e => {
        let stateE = typeof e
        if(e.target){
        if (stateE !== 'string') {
            let cin_data = e.target.files[0]
            const name = cin_data.name
            const type = name.split('.').pop()
            let preview_url = URL.createObjectURL(cin_data)
            setCin({ ...cin, preview: preview_url, raw: cin_data, type, name })
            setCdn_documents([...cdn_documents, { key: cin.key, title: cin.title, raw: cin_data }])
        } else {
            let preview_url = e
            setCin({ ...cin, preview: preview_url })
            setCdn_documents([...cdn_documents, { key: cin.key, title: cin.title }])
        }
    }else{
        toast(t("no_doc_file"),{type:"warning"});
    }

    }

    const handleDocChange = e => {
        let stateE = typeof e
        if(e.target){
        if (stateE !== 'string') {
            let doc_data = e.target.files[0]
            console.log('doc_data : ', doc_data)
            const name = doc_data.name
            const type = name.split('.').pop()
            let preview_url = URL.createObjectURL(doc_data)
            setDoc({ ...doc, preview: preview_url, raw: doc_data, type, name })
            setCdn_documents([...cdn_documents, { key: doc.key, title: doc.title, raw: doc_data }])
        } else {
            let preview_url = e
            setDoc({ ...doc, preview: preview_url })
            setCdn_documents([...cdn_documents, { key: doc.key, title: doc.title }])
        }
    }else{
        toast(t("no_doc_file"),{type:"warning"});
    }
    }

    const previewError = e => {
        console.log(e, 'error in file-viewer')
    }

    const reset = () => {
        setCv({ key: 'cv', title: 'Curriculum Vitae', preview: '', raw: '', type: '', name: '' })
        setRib({ key: 'rib', title: 'RIB', preview: '', raw: '', type: '', name: '' })
        setCin({ key: 'cin', title: 'CNI', preview: '', raw: '', type: '', name: '' })
        setDoc({ key: 'doc', title: 'Doc', preview: '', raw: '', type: '', name: '' })
        setCdn_documents([])
        setInfos_documents([])
    }

    const save_files = () => {
        props.action_loading(true)
        let new_infos_documents_state = [...infos_documents]
        cdn_documents.forEach(value => {
            uploadOnCdn(value.raw)
                .then(resp => {
                    new_infos_documents_state = [...new_infos_documents_state, { type: value.key, title: value.title, image_name: resp.filename }]
                    setInfos_documents(new_infos_documents_state)
                })
                .catch((error )=>{
                    console.log(error);
                })
        })
    }

    const listenUpdateProfileResponse = () => {
        socketIoInstance.on(SIO_CHANNEL_UPDATE_DOCS, response => {
            if (response && response.status === 200) {
                const documents = response.data.documents
                 let new_state = []
                documents.forEach(val => new_state = [...new_state, val])
                setCurrent_documents(new_state);
                user.user = response.data.user
                window.localStorage.setItem('glowhr_user', JSON.stringify(user))
                setUser(user);
                toast(t("profil_successfully_updates"),{type:"success"});
            }
            socketIoInstance.off(SIO_CHANNEL_UPDATE_DOCS)
            props.action_loading(false)
        })
    }

    const listenDeleteOneProfileDocumentResponse = () => {
        socketIoInstance.on(SIO_CHANNEL_DELETE_DOC, response => {
            if (response && response.status === 200) {
                const documents = response.data.documents
                setCurrent_documents(documents)
                user.user = response.data.user
                window.localStorage.setItem('glowhr_user', JSON.stringify(user))
                setUser(user);
                toast(t("profil_successfully_updates"),{type:"success"});
            }
            socketIoInstance.off(SIO_CHANNEL_DELETE_DOC)
            props.action_loading(false)
        })
    }

    const downloadDocument = image_key => {
        getFileFromCdn(image_key)
            .then(response => {
                let type = image_key.split('.').pop()
                if(type!=='pdf') type='image/'+type;
                const blob = new Blob([response], { type: type })
                const url = URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.download = image_key
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .catch(err => console.log('error get from cdn : ', err))
    }
  const showDocument = image_key => {
        // getFileFromCdn(image_key)
        //     .then(response => {
        //         console.log(response,'****** rets')
        //         const blob = new Blob([response], { type: 'image/jpeg' })
        //     }).catch(err => console.log('error get from cdn : ', err))
            const url = getFileUrl(image_key),
                  link = document.createElement('a');
            link.href = url
            link.target = '_blank'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
    }

    const deleteDocument = image_key => {
        props.action_loading(true)
        console.log(`image_key = ${image_key}`)
        listenDeleteOneProfileDocumentResponse()
        deleteProfileDocument(image_key, SIO_CHANNEL_DELETE_DOC)
    }


    const persoInfosChange = e => {
        let name = e.target.name
        let value = e.target.value
        if (personal_names.includes(name)) {
            let personal_new_state = { ...personal, [name]: value }
            setPersonal(personal_new_state)
        } else if (maritial_names.includes(name)) {
            let maritial_new_state = { ...maritial, [name]: value }
            setMaritial(maritial_new_state)
        } else if (name === 'name') {
            let maritial_new_state = { ...maritial, spouse_details: { [name]: value } }
            setMaritial(maritial_new_state)
        } else {
            setPerso_form({ ...perso_form, [name]: value })
        }
         //Faire un controle suivant chaque champs necessitant des restrictions
         let data={};
         switch(name){
             case 'birthdate':
                 const d = new Date();
                 d.setFullYear(d.getFullYear()-18);
                 data =handleValidation(t,{type:"date",name: name,'value':value,required:true,'max':new Date(d)});  
                 break
             case 'mobile' || 'urgency_contact' || 'nationalid_num':
                 data =handleValidation(t,{type:"tel",name: name,'value':value,required:true});
                 break
             case 'email' :
                 data =handleValidation(t,{type:"email",name: name,'value':value,required:true});
                 break
             case 'children' :
                 data =handleValidation(t,{type:"number",name: name,'value':value,required:true});
                 break
             default:
                     data =handleValidation(t,{type:"text",name: name,'value':value,required:true});
                 break;
         }
         let form=formError;
         form[data.name]=data   
         setFormError(form)
    }

    const updateProfilPerso = () => {
        setIsLoading(true)
        props.action_loading(true);
        const userPersonal = user.user.personal
        if (Object.getOwnPropertyNames(personal).length < Object.getOwnPropertyNames(userPersonal).length) {
            console.log(personal)
            if (Object.getOwnPropertyNames(personal).length >= 0) {
                for (const user in userPersonal) {
                    if (personal.hasOwnProperty(user) === false) {
                        personal[user] = userPersonal[user]
                        console.log(personal)
                    }
                }
                callService();
                props.action_loading(false)
                setIsLoading(false)
            }

        } else {
            callService()
            props.action_loading(false)
            setIsLoading(false)
        }
    }

    const callService = () => {
        props.action_loading(true);

        let perso_form_state = { ...perso_form, personal, maritial }
        const form_data = { ...user.user, ...perso_form_state }
        listenUpdateProfilPersoResponse()
        updateProfileInformations(form_data, SIO_CHANNEL_UPDATE_PROFIL_PERSO)
            .then(resp => {
                setPerso_form({})
                setPersonal({})
                setMaritial({})
            })
            .catch(err => console.log('err => ', err))
    }

    const listenUpdateProfilPersoResponse = () => {
        socketIoInstance.on(SIO_CHANNEL_UPDATE_PROFIL_PERSO, response => {
            if (response && response.status === 200) {
                user.user = response.data.user
                window.localStorage.setItem('glowhr_user', JSON.stringify(user))
                setUser(user);
                toast(t("profil_successfully_updates"),{type:"success"});
            }
            socketIoInstance.off(SIO_CHANNEL_UPDATE_PROFIL_PERSO)
            props.action_loading(false)
        })
    }

    const handleDayChange = (selectedDay, modifiers, dayPickerInput) => {
        const input = dayPickerInput.getInput()
        const name = input.name
        const value = input.value
        if (name === 'birthdate') {
            setPersonal({ ...personal, [name]: value })
        }
    }

    const handleSelectChange = (selected_option, action) => {
        const name = action.name;let value=undefined;
        if (name === 'gender') {
            setSelectedGender(selected_option)
            setPersonal({ ...personal, [name]: selected_option.value })
            value=selected_option.value;
        } else if (name === 'nationality') {
            setSelectedNationality(selected_option)
            setPersonal({ ...personal, [name]: selected_option.alpha3Code })
            value=selected_option.alpha3Code;
        } else if (name === 'spouse') {
            setSelectedStatutMatrimonial(selected_option)
            setMaritial({ ...maritial, [name]: selected_option.value })
            value=selected_option.value;
        } else if (name === 'ethnie') {
            setSelectedEthnie(selected_option)
            setPersonal({ ...personal, [name]: selected_option.value })
            value=selected_option.value;
        }else{
            setSelectedEthnie(selected_option)
            setPersonal({ ...personal, [name]: selected_option.value })
            value=selected_option.value;
        }
       let data = handleValidation(t,{type:"text",name: name,'value':value,required:true}),
         form=formError;
        form[data.name]=data   
        setFormError(form)
    }

    const [show,setShow]= useState();
    const [fileInput,setFileInput]= useState();
    const [imageFile,setImageFile]= useState();
    const getSupervisor = supervisor_id => {
        let supervisor = props.rwState.colleagues.filter(option => option._id === supervisor_id)
        supervisor = supervisor[0]
        let full_name = `${supervisor.firstname} ${supervisor.lastname}`
        console.log(full_name)
        return full_name
    }
    const [pourcent,setp] = useState()
    const pclass = 'c100 p'+pourcent

    //console.log(user?.user?.personal.gender.label)
    const handlePPSelect = (e)=>{
        setImageFile(e.target.files[0]);
        setShow('confirm');
    };
    const updatePpImage = ()=>{
        props.action_loading(true);
        const url = URL.createObjectURL(imageFile);
        setPpImage(url)
        uploadOnCdn(imageFile)
            .then(resp => {
                let filename = resp.filename;
                let e = {
                    target :{value:filename,name:"image_url"}
                };
                const form_data = { ...user.user, ...{image_url:filename}}
                listenUpdateProfilPersoResponse();
                updateProfileInformations(form_data,SIO_CHANNEL_UPDATE_PROFIL_PERSO)
                setShow(null)
            })
            .catch(error => console.log(error))

    };
    const changeBirthdayStatus = () =>{
        props.action_loading(true);
        props.action_changeBirthdayStatus({employee:user?.user?._id});
        setBirthDayVisible(!birthday_visible)

    }

    const handHiredDate = e => {setHiredDate(e.target.value)
        let data =handleValidation(t,{type:"text",name:'hiredDate','value':e.target.value,required:true});
        let form=formError;
        form[data.name]=data
        setFormError(form)
    }

    const handStartDate = e => {setStartDate(e.target.value)
        let data =handleValidation(t,{type:"text",name:'startDate','value':e.target.value,required:true});
        let form=formError;
        form[data.name]=data
        setFormError(form)
    }

    const handleEndDate = (e) =>  {setEndDate(e.target.value)
        let data =handleValidation(t,{type:"text",name:'endDate','value':e.target.value,required:true});
        let form=formError;
        form[data.name]=data
        setFormError(form)
    }

    const handleProStartDate = e => {setProStartDate(e.target.value)
        let data =handleValidation(t,{type:"text",name:'proStartDate','value':e.target.value,required:true});
        let form=formError;
        form[data.name]=data
        setFormError(form)
    }

    const handleProbEndDate = e => {setProbEndDate(e.target.value)
        let data =handleValidation(t,{type:"text",name:'proEndDate','value':e.target.value,required:true});
        let form=formError;
        form[data.name]=data
        setFormError(form)
    }

    const handlePosition = e =>{ setSelectedPosition(e)
        let data =handleValidation(t,{type:"text",name:'position','value':e,required:true,isArray:true});
        let form=formError;
        form[data.name]=data
        setFormError(form)
    }

    const handleSup = e =>{ setSelectedSuperieur(e)
        let data =handleValidation(t,{type:"text",name:'superieur','value':e,required:true,isArray:true});
        let form=formError;
        form[data.name]=data
        setFormError(form)
    }

    const handleDepartment = e => {setSelectedDepartment(e)
        let data =handleValidation(t,{type:"text",name:'department','value':e,required:true,isArray:true});
        let form=formError;
        form[data.name]=data
        setFormError(form)
    }
    const handleTypeSalaire = (e) => {
        setTypeSalaire(e);
    }
    const handleSalary = e => {setSalary(e.target.value)
        let data =handleValidation(t,{type:"number",name:'salary','value':e.target.value,required:true});
        let form=formError;
        form[data.name]=data
        setFormError(form)
    }

    const handleMobile = e => {setMobile(e.target.value)
        let data =handleValidation(t,{type:"number",name:'mobile','value':e.target.value,required:true});
        let form=formError;
        form[data.name]=data
        setFormError(form)
    }

    const handleBranch = e => {setSelectedBranch(e)
        let data =handleValidation(t,{type:"text",name:'branch','value':e,required:true,isArray:true});
        let form=formError;
        form[data.name]=data
        setFormError(form)}

    const requestDataBuilder = () => (
        {
            "branch": selectedBranch?._id,
            "department": selectedDepartment?._id,
            "mobile": mobile,
            "hired_on": hiredDate,
            "position": selectedPosition?._id,
            "job": {
                "start_date": startDate,
                "end_date": endDate,
                "type_salary": type_salary,
                "salary": salary,
                "reports_to": selectedSuperieur?._id,
                "employment_type": 3,
                "probation": {
                    "status": 1,
                    "probation_start": proStartDate,
                    "probation_end": probEndDate
                }
            },
        }
    )
    const saveUpdate = () => {
        setIsLoading(true)
        let colleague_data = requestDataBuilder();
        colleague_data['employee'] = user.user?._id;
        props.action_updateColleague(colleague_data);
        setTimeOut(()=>{
            setIsLoading(false)
            logout()
        },2000)
    }
    return <div id={'Profile'}>

        <Container fluid>
            <Row>
                <Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="perso">
                        <Row>
                            <Col>
                                <div className="d-flex align-items-start justify-content-between">
                                    <ContextMenu menu={contextMenu} />
                                </div>

                                <Tab.Content>
                                    <Tab.Pane eventKey="perso">
                                        <Row>
                                            <Col>
                                            <div className={'clearfix d-flex justify-content-center'}>
                                                  <label htmlFor={"pp"}>
                                                        <span>
                                                        <img style={{border:"3px solid red"}} src={ppImage} alt="" height={150} width={150} className={'rounded-circle'}/>
                                                    </span>
                                                      <Button onClick={()=>fileInput.click()} style={{position:"absolute",bottom:"-5%",right:"44%",borderRadius:"50%"}}><BsUpload/></Button>
                                                </label>
                                                <input ref={input => setFileInput(input)} type="file" onChange={handlePPSelect} id={"pp"} accept={"image/*"} style={{ display: 'none' }} />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t("last_name")} <span className={'required_box'}>*</span> </Form.Label>
                                                    <Form.Control name='lastname' onChange={persoInfosChange} size={"lg"}
                                                     defaultValue={user?.user?.lastname}
                                                     className={handleinputValidationError(formError,'lastname')}
                                                     />
                                                            {handleTextValidationError(formError,'lastname')}
                                          
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t("first_name")} <span className={'required_box'}>*</span> </Form.Label>
                                                    <Form.Control name='firstname' onChange={persoInfosChange} 
                                                    size={"lg"} defaultValue={user?.user?.firstname} 
                                                    className={handleinputValidationError(formError,'firstname')}
                                                    />
                                                           {handleTextValidationError(formError,'firstname')}
                                         
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t("sex")} <span className={'required_box'}>*</span> </Form.Label>
                                                    <Select noOptionsMessage={() => t("no_data_available")}
                                                        options={gender_options}
                                                        // value={gender_options.map(option => {
                                                        //     if(option.value === user?.user?.personal?.gender) {
                                                        //         return option
                                                        //     }
                                                        // })}
                                                        value={selectedGender}
                                                        styles={{
                                                            control: base => ({
                                                                ...base,
                                                                minHeight: 48
                                                            })
                                                        }}

                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: '#6600CC',
                                                                primary25: '#6600CC25',
                                                                primary75: '#6600CC75',
                                                                primary50: '#6600CC50'
                                                            },
                                                        })}
                                                        placeholder={t("sex")}
                                                        name='gender'
                                                        onChange={handleSelectChange}
                                                        className={handleinputValidationError(formError,'gender')}
                                                        />
                                                               {handleTextValidationError(formError,'gender')}
                                             
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Row>
                                                        <Col md={11}>
                                                            <Form.Group>
                                                                <Form.Label>{t("birthday")} <span className={'required_box'}>*</span> </Form.Label>
                                                                <Form.Control
                                                                    type={'date'}
                                                                   defaultValue={user && user?.user?.personal?.birthdate ? formatDate(user?.user?.personal?.birthdate , 'YYYY-MM-DD') : ''}
                                                                    name='birthdate'
                                                                    onChange={persoInfosChange} size={"lg"}
                                                                    className={handleinputValidationError(formError,'birthdate')}
                                                                    />
                                                                           {handleTextValidationError(formError,'birthdate')}
                                                         
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={1} style={{marginTop:"15px",marginLeft:"-15px"}} className={"d-flex justify-content-center align-items-center"}>
                                                            <Button title={JSON.parse(window.localStorage.getItem('glowhr_user')).user.birthday_visible?"Rendre ma date de naissance privée":"Rendre ma date de naissance public"} onClick={changeBirthdayStatus} variant={"link"} size={"sm"}>
                                                                {
                                                                    JSON.parse(window.localStorage.getItem('glowhr_user')).user.birthday_visible ?<BS.BsEyeSlash size={22}/> : <BS.BsEye size={22}/>
                                                                }
                                                            </Button>
                                                        </Col>
                                                    </Row>

                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t("nationnality")} <span className={'required_box'}>*</span> </Form.Label>
                                                    <Select noOptionsMessage={() => t("no_data_available")}
                                                        options={countries}
                                                        getOptionLabel={o => o.name}
                                                        getOptionValue={o => o.alpha3Code}
                                                        value={selectedNationality}
                                                        styles={{
                                                            control: base => ({
                                                                ...base,
                                                                minHeight: 48,
                                                            })
                                                        }}
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: '#6600CC',
                                                                primary25: '#6600CC25',
                                                                primary75: '#6600CC75',
                                                                primary50: '#6600CC50'
                                                            },
                                                        })}
                                                        placeholder={t("nationnality")}
                                                        name='nationality'
                                                        onChange={handleSelectChange}
                                                        className={handleinputValidationError(formError,'nationality')}
                                                        />
                                                               {handleTextValidationError(formError,'nationality')}
                                             
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t("ethni")} <span className={'required_box'}>*</span> </Form.Label>
                                                    <Select noOptionsMessage={() => t("no_data_available")}
                                                        options={ethnie_select_options}
                                                        value={selected_ethnie}
                                                        styles={{
                                                            control: base => ({
                                                                ...base,
                                                                minHeight: 48
                                                            })
                                                        }}

                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: '#6600CC',
                                                                primary25: '#6600CC25',
                                                                primary75: '#6600CC75',
                                                                primary50: '#6600CC50'
                                                            },
                                                        })}
                                                        placeholder={t("ethni")}
                                                        name='ethnie'
                                                        onChange={handleSelectChange}
                                                        className={handleinputValidationError(formError,'ethnie')}
                                                        />
                                                               {handleTextValidationError(formError,'ethnie')}
                                             
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t("email")} <span className={'required_box'}>*</span> </Form.Label>
                                                    <Form.Control name='email' onChange={persoInfosChange} size={"lg"}
                                                     defaultValue={user?.user?.email} type={'email'}
                                                     className={handleinputValidationError(formError,'email')}
                                                     />
                                                            {handleTextValidationError(formError,'email')}
                                          
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t("tel")} <span className={'required_box'}>*</span> </Form.Label>
                                                    <Form.Control name='mobile' onChange={persoInfosChange} size={"lg"}
                                                     defaultValue={user?.user?.mobile} type={'number'}
                                                     className={handleinputValidationError(formError,'mobile')}
                                                     />
                                                            {handleTextValidationError(formError,'mobile')}
                                          
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t("address_physic")} <span className={'required_box'}>*</span> </Form.Label>
                                                    <Form.Control
                                                        size={"lg"}
                                                        defaultValue={user?.user?.personal.physical_address} name='physical_address'
                                                         onChange={persoInfosChange} 
                                                         className={handleinputValidationError(formError,'physical_address')}
                                                         />
                                                                {handleTextValidationError(formError,'physical_address')}
                                              
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t("health_status")}</Form.Label>
                                                    <Form.Control size={"lg"} name='health_state' defaultValue={user?.user?.personal?.health_state} onChange={persoInfosChange} />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t("nationnal_number")}</Form.Label>
                                                    <Form.Control
                                                        size={"lg"} type={'number'}
                                                        defaultValue={user?.user?.personal?.nationalid_num}
                                                        name='nationalid_num'
                                                        onChange={persoInfosChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t("marital_status")}</Form.Label>
                                                    <Select noOptionsMessage={() => t("no_data_available")}
                                                        options={statut_matrimonial}
                                                        name='spouse'
                                                        value={selectedStatutMatrimonial}
                                                        styles={{
                                                            control: base => ({
                                                                ...base,

                                                                minHeight: 48,
                                                            })
                                                        }}
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: '#6600CC',
                                                                primary25: '#6600CC25',
                                                                primary75: '#6600CC75',
                                                                primary50: '#6600CC50'
                                                            },
                                                        })}
                                                        placeholder={t("marital_status")}
                                                        onChange={handleSelectChange}
                                                    />
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label> {t("spouse_name")} </Form.Label>
                                                    <Form.Control type={'text'}
                                                        size={"lg"}
                                                        defaultValue={user?.user?.maritial?.spouse_details?.name}
                                                        name='name'
                                                        onChange={persoInfosChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label> {t("number_children")} </Form.Label>
                                                    <Form.Control size={"lg"} type={'number'}
                                                        defaultValue={user?.user?.maritial.children}
                                                        name='children'
                                                        onChange={persoInfosChange} />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t("children_names")}</Form.Label>
                                                    <Form.Control size={"lg"} type={'text'}
                                                        defaultValue={user?.user?.maritial.children_names} name='children_names' onChange={persoInfosChange} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4} xs={12}>
                                                <Form.Group>
                                                    <Form.Label> {t("urgent_contact_name")} <span className={'required_box'}>*</span> </Form.Label>
                                                    <Form.Control size={"lg"} type={'text'} name='urgency_contact_name'
                                                     defaultValue={user?.user?.personal?.urgency_contact_name}
                                                      onChange={persoInfosChange} 
                                                      className={handleinputValidationError(formError,'urgency_contact_name')}
                                                      />
                                                             {handleTextValidationError(formError,'urgency_contact_name')}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} xs={12}>
                                                <Form.Group>
                                                    <Form.Label> {t("urgent_contact")} <span className={'required_box'}>*</span> </Form.Label>
                                                    <Form.Control size={"lg"} type={'number'} name='urgency_contact'
                                                     defaultValue={user?.user?.personal?.urgency_contact}
                                                      onChange={persoInfosChange} 
                                                      className={handleinputValidationError(formError,'urgency_contact')}
                                                      />
                                                             {handleTextValidationError(formError,'urgency_contact')}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-end mt-4'>
                                            <Col sm='auto'>
                                               <Button loading={isLoading}
                                                 disabled={
                                                    handleDisabledBtnValidationError(
                                                        formError,
                                                        ['gender','nationality','ethnie','firstname','lastname','birthdate','email','mobile','physical_address','urgency_contact']
                                                        )
                                                         || isLoading
                                                }
                                                onClick={updateProfilPerso}>{isLoading ? t("changing") : t("change_profil")}</Button>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="pro">
                                        <Row>
                                            <Col>
                                                {/*<Form.Group>
                                                    <Form.Label>{t("hired_date")}</Form.Label>
                                                    <Form.Control disabled size={"lg"} value={formatDate(user.user?.job_info[0]?.created_date, 'DD/MM/YYYY', 'fr')} />
                                                </Form.Group>*/}
                                                <Form.Group>
                                                    <label>{t("recruitment_date")}</label> <br/>
                                                    <Form.Control
                                                        type={'date'}
                                                        onChange={handHiredDate}
                                                        defaultValue={startDate ? formatDate(startDate, 'YYYY-MM-DD') : ''}
                                                        name='start_date'
                                                        className={(formError && formError.startDate && formError.startDate.errors && !formError.startDate.formIsValid) ? 'inputValidationError' : ''}
                                                    />
                                                    {(formError && formError.startDate && formError.startDate.errors && !formError.startDate.formIsValid) ? <span className={"validationError"}>{formError.startDate.errors.startDate}</span> : ''}

                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                {/* <Form.Group>
                                                    <Form.Label>{t("departure_date")}</Form.Label>
                                                    <Form.Control disabled size={"lg"} value={user.user?.job_info[0]?.start_date ? formatDate(new Date(user.user?.job_info[0]?.start_date), 'DD/MM/YYYY', 'fr') : ''} />
                                                </Form.Group>*/}
                                                <Form.Group>
                                                    <label>{t("end_of_contract_date")}</label> <br/>
                                                    <Form.Control
                                                        type={'date'}
                                                        onChange={handleEndDate}
                                                        defaultValue={endDate ? formatDate(endDate, 'YYYY-MM-DD') : ''}
                                                        name='end_date'
                                                        className={(formError && formError.endDate && formError.endDate.errors && !formError.endDate.formIsValid) ? 'inputValidationError' : ''}
                                                    />
                                                    {(formError && formError.endDate && formError.endDate.errors && !formError.endDate.formIsValid) ? <span className={"validationError"}>{formError.endDate.errors.endDate}</span> : ''}

                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                {/*<Form.Group>
                                                    <Form.Label>{t("direct_supervisor")}</Form.Label>
                                                    <Form.Control disabled size={"lg"} defaultValue={user.user?.job_info[0]?.reports_to && props.rwState.colleagues ? props.rwState.colleagues.filter(elt => elt._id === user.user?.job_info[0]?.reports_to).map(e => e.firstname + ' ' + e.lastname).join('') : ''} />
                                                </Form.Group>*/}
                                                <Form.Group>
                                                    <label>{t("hierarchic_supe")}</label> <br/>
                                                    <Select noOptionsMessage={() => t("no_data_available")}
                                                            options={props.rwState.colleagues}
                                                            getOptionLabel={o => o.firstname + ' ' + o.lastname}
                                                            getOptionValue={o => o._id}
                                                            value={selectedSuperieur}
                                                            styles={{
                                                                control: base => ({
                                                                    ...base,
                                                                    minHeight: 38,
                                                                })
                                                            }}
                                                            theme={theme => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary: '#6600CC',
                                                                    primary25: '#6600CC25',
                                                                    primary75: '#6600CC75',
                                                                    primary50: '#6600CC50'
                                                                },
                                                            })}
                                                            placeholder={t("hierarchic_supe")}
                                                            onChange={e => handleSup(e)}
                                                    />
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>{t("branch_title_unique")} <span className={'required_box'}>*</span> </label>
                                                    <Select noOptionsMessage={() => t("no_data_available")}
                                                            options={props.rwState.branches}
                                                            getOptionLabel={o => o.name}
                                                            getOptionValue={o => o._id}
                                                            value={selectedBranch}
                                                            styles={{
                                                                control: base => ({
                                                                    ...base,
                                                                    minHeight: 38
                                                                })
                                                            }}
                                                            theme={theme => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary: '#6600CC',
                                                                    primary25: '#6600CC25',
                                                                    primary75: '#6600CC75',
                                                                    primary50: '#6600CC50'
                                                                },
                                                            })}
                                                            placeholder={t("branch_title_unique")}
                                                            onChange={e => handleBranch(e)}
                                                            className={(formError && formError.branch && formError.branch.errors && !formError.branch.formIsValid) ? 'inputValidationError' : ''}
                                                    />
                                                    {(formError && formError.branch && formError.branch.errors && !formError.branch.formIsValid) ? <span className={"validationError"}>{formError.branch.errors.branch}</span> : ''}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                               {/* <Form.Group>
                                                    <Form.Label>{t("department")}</Form.Label>
                                                    <Form.Control disabled size={"lg"} defaultValue={props.rwState.departments.filter(option => option._id === user.user?.department._id).map(e => e.name).join('')} />
                                                </Form.Group>*/}
                                                <Form.Group>
                                                    <label>{t("department_title_unique")}</label>
                                                    <Select noOptionsMessage={() => t("no_data_available")}
                                                            options={props.rwState.departments}
                                                            getOptionLabel={o => o.name}
                                                            getOptionValue={o => o._id}
                                                            value={selectedDepartment}
                                                            styles={{
                                                                control: base => ({
                                                                    ...base,
                                                                    minHeight: 38
                                                                })
                                                            }}
                                                            theme={theme => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary: '#6600CC',
                                                                    primary25: '#6600CC25',
                                                                    primary75: '#6600CC75',
                                                                    primary50: '#6600CC50'
                                                                },
                                                            })}
                                                            placeholder={t("department_title_unique")}
                                                            onChange={e => handleDepartment(e)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                {/*<Form.Group>
                                                    <Form.Label>{("position_held")}</Form.Label>
                                                    <Form.Control disabled size={"lg"}
                                                                  value={
                                                                      user.user?.position
                                                                          ? user.user?.position?.title
                                                                          : ''} />
                                                </Form.Group>*/}
                                                <Form.Group>
                                                    <label>{t("position_title_unique")}</label>
                                                    <Select noOptionsMessage={() => t("no_data_available")}
                                                            options={props.rwState.positions}
                                                            getOptionLabel={o => o.title}
                                                            getOptionValue={o => o._id}
                                                            value={selectedPosition}
                                                            styles={{
                                                                control: base => ({
                                                                    ...base,
                                                                    minHeight: 38
                                                                })
                                                            }}
                                                            theme={theme => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary: '#6600CC',
                                                                    primary25: '#6600CC25',
                                                                    primary75: '#6600CC75',
                                                                    primary50: '#6600CC50'
                                                                },
                                                            })}
                                                            placeholder={t("position_title_unique")}
                                                            onChange={e => handlePosition(e)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={"mt-3"}>
                                                <Form.Group>
                                                    <Form.Label>{t("salary")}</Form.Label> <br/>
                                                    <div key={`inline-external`} className="mb-3">
                                                        <Form.Check
                                                            size={"lg"}
                                                            type={"radio"}
                                                            name={"type_salary"}
                                                            label={"mensuel"}
                                                            value={"mensuel"}
                                                            id={"inline-external-2"}
                                                            inline
                                                            checked={type_salary ? (type_salary === 'mensuel') : true}
                                                            onClick={()=>handleTypeSalaire('mensuel')}
                                                        />
                                                        <Form.Check
                                                            size={"lg"}
                                                            type={"radio"}
                                                            name={"type_salary"}
                                                            label={"horraire"}
                                                            value={"horraire"}
                                                            id={"inline-external-1"}
                                                            inline
                                                            checked={type_salary ? (type_salary === 'horraire') : false}
                                                            onClick={()=>handleTypeSalaire('horraire')}
                                                        />

                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                {/*<Form.Group>
                                                    <Form.Label>{t("salary")}</Form.Label>
                                                    <Form.Control disabled size={"lg"}  defaultValue={user.user?.job_info[0].salary} />
                                                </Form.Group>*/}
                                                <Form.Group>
                                                    <Form.Label>{t("salary")}</Form.Label>
                                                <Form.Control
                                                    placeholder={'Salaire'}
                                                    type={'number'}
                                                    min={0}
                                                    maxlength={10}
                                                    onChange={handleSalary}
                                                    value={salary ? salary : ''}
                                                    className={(formError && formError.salary && formError.salary.errors && !formError.salary.formIsValid) ? 'inputValidationError' : ''}
                                                />
                                                {(formError && formError.salary && formError.salary.errors && !formError.salary.formIsValid) ? <span className={"validationError"}>{formError.salary.errors.salary}</span> : ''}
                                            </Form.Group>
                                            </Col>

                                            <Col>
                                                {/*<Form.Group>
                                                    <Form.Label>{t("probationary_period")}</Form.Label>
                                                    <Form.Control disabled size={"lg"} value={formatDate(new Date(user.user?.job_info[0].probation?.probation_start), 'DD/MM/YYYY', 'fr')} />
                                                </Form.Group>*/}
                                                <Form.Group>
                                                    <label>{t("probation_start")}</label> <br/>
                                                    <Form.Control
                                                        type={'date'}
                                                        onChange={handleProStartDate}
                                                        defaultValue={proStartDate ? formatDate(proStartDate, 'YYYY-MM-DD') : ''}
                                                        name='probation_start'
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                {/*<Form.Group>
                                                    <Form.Label>{t("probationary_period")}</Form.Label>
                                                    <Form.Control disabled size={"lg"} value={formatDate(new Date(user.user?.job_info[0].probation?.probation_start), 'DD/MM/YYYY', 'fr')} />
                                                </Form.Group>*/}
                                                <Form.Group>
                                                    <label>{t("probation_end")}</label> <br/>
                                                    <Form.Control
                                                        type={'date'}
                                                        onChange={handleProbEndDate}
                                                        defaultValue={probEndDate ? formatDate(probEndDate, 'YYYY-MM-DD') : ''}
                                                        name='probation end'
                                                        // placeholder={formatDate(new Date(), 'DD/MM/YYYY', 'fr')}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-end mt-4'>
                                            <Col sm='auto'>
                                                <Button loading={isLoading}
                                                        disabled={isLoading}
                                                        onClick={saveUpdate}>{isLoading ? t("changing") : t("change_profil")}</Button>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="docs">
                                        <div>
                                            <div className={'sectionTitle mb-3'}>{t("upload_your_docs")}</div>
                                        </div>
                                        <Row>
                                            <Col xs={"auto"}>
                                                <UploadPersoDoc data={{ label: t('CV'), doctype: 'pdf', handler: handleCvChange, preview: cv.preview, raw: cv.raw, type: cv.type, name: cv.name, previewErrorHandler: previewError }} />
                                            </Col>
                                            <Col xs={"auto"}>
                                                <UploadPersoDoc data={{ label: t("banking_data"), doctype: 'jpg', handler: handleRibChange, preview: rib.preview, raw: rib.raw, type: rib.type, name: rib.name, previewErrorHandler: previewError }} />
                                            </Col>
                                            <Col xs={"auto"}>
                                                <UploadPersoDoc data={{ label: t("idenity_doc"), doctype: 'jpg', handler: handleCinChange, preview: cin.preview, raw: cin.raw, type: cin.type, name: cin.name, previewErrorHandler: previewError }} />
                                            </Col>
                                            <Col xs={"auto"}>
                                                <UploadPersoDoc data={{ label: t("other_docs"), doctype: 'doc', handler: handleDocChange, preview: doc.preview, raw: doc.raw, type: doc.type, name: doc.name, previewErrorHandler: previewError }} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='mt-4'>
                                                <Button onClick={save_files}>{t("change_profil")}</Button>
                                            </Col>
                                        </Row>
                                        <div id="persoDocs" className={"mt-5 mb-4"} >
                                            <div className={'title p-3 pb-0'}>{t("list_upload_docs")}</div>
                                            <div>
                                                {current_documents && current_documents.length > 0 ?
                                                    current_documents.map((curr_doc, index) => (
                                                        <div className={'docItem d-flex align-items-center mb-1'} key={index}>
                                                            <div className={'docIcon pl-3 pt-0 pr-1 pt-1'}>
                                                                <BsFileText size={20} />
                                                            </div>
                                                            <div
                                                                className="d-flex align-content-center justify-content-between w-100">
                                                                <div>
                                                                    <div className={'docName mb-3 mt-3 pt-2'}>{curr_doc.title}
                                                                    </div>
                                                                    <div className={'docDetails'}> 
                                                                    </div>
                                                                </div>
                                                                <div className={'p-3 pr-4'}>
                                                                    <FiTrash style={{ cursor:'pointer' }} className={'btn-box'} onClick={() => deleteDocument(curr_doc.image_name)} color={'#FC5565'} size={22} />
                                                                    <FiDownload style={{ cursor:'pointer' }} onClick={() => downloadDocument(curr_doc.image_name)} className="ml-2 btn-box" color={'#240A63'} size={22} />
                                                                    <FiEye style={{ cursor:'pointer' }} onClick={() => showDocument(curr_doc.image_name)} className="ml-2 btn-box" color={'#240A63'} size={22} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    <div className={"col-12 col-lg-12 mt-5 mb-5 pb-5"}>
                                                        <div className={"alert alert-warning"}>
                                                            {t("no_doc_found")}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                       
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Col>
            </Row>
        </Container>
        <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("confirmation")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"text-center"}>
                    {t("notif_change_profil_image")}
                </div>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>
                        <Button variant="primary" onClick={updatePpImage}>
                         {t("yes_update")}
                        </Button>
                        <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                            {t("cancel")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
