
import EventEmitter from 'eventemitter3';

import {toast} from "react-toastify";
const eventEmitter = new EventEmitter();
const notify = (message,type) => toast(message,{type:type});

export const getFormatedDate = (date)=>{let d = typeof date && date!=null && date!==undefined?new Date(date):new Date();return new Intl.DateTimeFormat('fr-FR', { dateStyle: 'full', timeStyle: 'short' }).format(d);};
export const getFormatedDay = (date)=>{let d = typeof date && date!=null && date!==undefined?new Date(date):new Date();return new Intl.DateTimeFormat('fr-FR', {weekday: "long", year: "numeric", month: "long", day: "numeric"}).format(d);};
export const userIsEmployee =()=> JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name.toLowerCase().includes('employee');
export   const isAdmin=(role)=>{let response =false;if( role==="Administrator" || role==="RH") response=true;return response;}
export   const isOnlyRH=(role)=>{let response =false;if(role==="RH") response=true;return response;}
export   const isOnlyAdmin=(role)=>{let response =false;if( role==="Administrator") response=true;return response;}
export const limitText = (text, length)=>{return text? text.slice(0, length) + (text.length > length ? "..." : ""):"";};
export const goToExternalLink = (link)=>{notify(t('external_rediection'),'info');setTimeout(function(){window.open(link,'_blank');},3000)}
export const DownloadFfromExternalLink = (name,lien)=>{const link = document.createElement('a');link.href = lien;link.setAttribute("download", name);link.setAttribute("target", '_blank');document.body.appendChild(link);link.click();link.parentNode.removeChild(link);};
export const validateFile = (ext)=>{return ["image",'application/pdf','application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(ext)}
export const generateVisioUrl = ()=>"https://meet.jit.si/"+Math.random().toString(36).substr(2, 5);
export const todayDateFormatForInput =() =>{
  var today = new Date();
var dd = today.getDate();
var mm = today.getMonth()+1; //January is 0 so need to add 1 to make it 1!
var yyyy = today.getFullYear();
var hour=today.getHours();
var minutes=today.getMinutes();
if(dd<10){
  dd='0'+dd
} 
if(mm<10){
  mm='0'+mm
} 

return today = yyyy+'-'+mm+'-'+dd+'T'+hour+':'+minutes;
}
export const handleValidation =(t,fields) =>{
 
   //fileds is an object that contains input type, input value, input name and input required as true or false , isMulti as true or false.
    let errors = {};
    let formIsValid = true;
    //Name
    if(fields["value"]){
    if(fields['isArray']){
      if(!fields["value"] || (fields["value"] && fields["value"].length <= 0)){
        if(fields["required"]){
          formIsValid = false;
          errors[fields["name"]] = t("required_input");
          }
      }
    }else{
    if (!fields["value"] || fields["value"].toString().trim().replace(' ','').length <= 0) {
      if(fields["required"]){
      formIsValid = false;
      errors[fields["name"]] = t("required_input");
      }
    }
  }
  if(fields["minlength"]){
    let value=fields["value"]; 
    let minlength=fields["minlength"]; 
    if(value.length > minlength){ 
      formIsValid = false;
      errors[fields["name"]] = t("should_be_less_than")+minlength;
    }
  } 
  if(fields["maxlength"]){
    let value=fields["value"]; 
    let maxlength=fields["maxlength"]; 
    if(value.length > maxlength){ 
      formIsValid = false;
      errors[fields["name"]] = t("should_be_greater_than")+maxlength;
    }
  }
    /* if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["name"] = "Only letters";
      }
    } */
switch(fields["type"]){
  case 'date' : 
  if(fields["min"]){
    let value=new Date(fields["value"]); 
    let min=new Date(fields["min"]); 
    if(value.getTime() < min.getTime()){ 
      formIsValid = false;
      errors[fields["name"]] = t("value_should_be_less_than")+getFormatedDate(min);
    }
  }
if(fields["max"]){
    let value=new Date(fields["value"]); 
    let max=new Date(fields["max"]); 
    if(value.getTime() > max.getTime()){ 
      formIsValid = false;
      errors[fields["name"]] = t("value_should_be_greater_than")+getFormatedDate(max);
    }
  }
break  
case 'tel' : 
var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        if(!re.test(fields["value"])){  
            formIsValid = false;
            errors[fields["name"]] = t("error_phone_number");
        }
break
case 'text' : 
        if(fields["value"].length > 255){  
            formIsValid = false;
            errors[fields["name"]] = t("value_too_long");
        }
break

case 'number' : 
        if(isNaN(fields["value"])){  
            formIsValid = false;
            errors[fields["name"]] = t("should_contain_only_number");
        }
        if(fields["max"]){
          let value=fields["value"]; 
          let max=fields["max"]; 
          if(value > max){ 
            formIsValid = false;
            errors[fields["name"]] = t("number_should_be_less_than")+max;
          }
        }
        if(fields["min"]){
          let value=fields["value"]; 
          let min=fields["min"]; 
          if(value < min){ 
            formIsValid = false;
            errors[fields["name"]] = t("number_should_be_greater_than")+min;
          }
        }

break
case 'password' : 
        if(fields["value"].length < 8){  
            formIsValid = false;
            errors[fields["name"]] = t("number_should_be_greater_than_height");
        }
break
case 'cpassword' : 
        if(fields["value"].length < 8){  
            formIsValid = false;
            errors[fields["name"]] = t("number_should_be_greater_than_height");
        }
        else if(fields["value"] !== fields["cvalue"]){  
            formIsValid = false;
            errors[fields["name"]] = t("password_error");
        }
break
case 'email' :  
        let lastAtPos = fields["value"].lastIndexOf("@");
        let lastDotPos = fields["value"].lastIndexOf(".");
        if (
        !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fields["value"].indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            fields["value"].length - lastDotPos > 2
        )
        ) {
        formIsValid = false;
        errors[fields["name"]] = "Email invalide";
        }else if(fields["emails"] && fields["emails"].length > 0 && fields["emails"].indexOf(fields["value"]) >= 0){
          formIsValid = false;
          errors[fields["name"]] = t("email_already_used");
        }
break
}
    }else{
      formIsValid = false;
      errors[fields["name"]] = t("required_input");
     
    }
    return {'formIsValid':formIsValid,'errors' : errors,'name' : fields["name"]};
}
export const handleFinaleValidation =(t,datas) =>{
  let response ={};
  for(const fields of datas){
  //fileds is an object that contains input type, input value, input name and input required as true or false , isMulti as true or false.
    let errors = {};
    let formIsValid = true;
    //Name
    if(fields["value"]){
    if(fields['isArray']){
      if(!fields["value"] || (fields["value"] && fields["value"].length <= 0)){
        if(fields["required"]){
          formIsValid = false;
          errors[fields["name"]] = t("required_input");
          }
      }
    }else{
    if (!fields["value"] || fields["value"].toString().trim().replace(' ','').length <= 0) {
      if(fields["required"]){
      formIsValid = false;
      errors[fields["name"]] = t("required_input");
      }
    }
  }
  if(fields["minlength"]){
    let value=fields["value"]; 
    let minlength=fields["minlength"]; 
    if(value.length > minlength){ 
      formIsValid = false;
      errors[fields["name"]] = t("should_be_less_than")+minlength;
    }
  } 
  if(fields["maxlength"]){
    let value=fields["value"]; 
    let maxlength=fields["maxlength"]; 
    if(value.length > maxlength){ 
      formIsValid = false;
      errors[fields["name"]] = t("should_be_greater_than")+maxlength;
    }
  }
    /* if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["name"] = "Only letters";
      }
    } */
switch(fields["type"]){
  case 'date' : 
        if(fields["min"]){
          let value=new Date(fields["value"]); 
          let min=new Date(fields["min"]); 
          if(value.getTime() < min.getTime()){ 
            formIsValid = false;
            errors[fields["name"]] = t("value_should_be_less_than")+getFormatedDate(min);
          }
        }
 if(fields["max"]){
          let value=new Date(fields["value"]); 
          let max=new Date(fields["max"]); 
          if(value.getTime() > max.getTime()){ 
            formIsValid = false;
            errors[fields["name"]] = t("value_should_be_greater_than")+getFormatedDate(max);
          }
        }
break  
case 'tel' : 
var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        if(!re.test(fields["value"])){  
            formIsValid = false;
            errors[fields["name"]] = t("error_phone_number");
        }
break
case 'text' : 
        if(fields["value"].length > 255){  
            formIsValid = false;
            errors[fields["name"]] = t("value_too_long");
        }
break
case 'number' : 
        if(isNaN(fields["value"])){  
            formIsValid = false;
            errors[fields["name"]] = t("should_contain_only_number");
        }
        if(fields["max"]){
          let value=fields["value"]; 
          let max=fields["max"]; 
          if(value > max){ 
            formIsValid = false;
            errors[fields["name"]] = t("number_should_be_less_than")+max;
          }
        }
 if(fields["min"]){
          let value=fields["value"]; 
          let min=fields["min"]; 
          if(value < min){ 
            formIsValid = false;
            errors[fields["name"]] = t("number_should_be_greater_than")+min;
          }
        }
break
case 'password' : 
        if(fields["value"].length < 8){  
            formIsValid = false;
            errors[fields["name"]] = toString("number_should_be_greater_than_height");
        }
break
case 'cpassword' : 
        if(fields["value"].length < 8){  
            formIsValid = false;
            errors[fields["name"]] = t("number_should_be_greater_than_height");
        }
        else if(fields["value"] !== fields["cvalue"]){  
            formIsValid = false;
            errors[fields["name"]] = t("password_error");
        }
break
case 'email' :  
        let lastAtPos = fields["value"].lastIndexOf("@");
        let lastDotPos = fields["value"].lastIndexOf(".");
        if (
        !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fields["value"].indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            fields["value"].length - lastDotPos > 2
        )
        ) {
        formIsValid = false;
        errors[fields["name"]] = "Email invalide";
        }else if(fields["emails"] && fields["emails"].length > 0 && fields["emails"].indexOf(fields["value"]) >= 0){
          formIsValid = false;
          errors[fields["name"]] = t("email_already_used");
        }
break
}
}else{
  formIsValid = false;
  errors[fields["name"]] = t("required_input");
 
}
     response[fields["name"]]={'formIsValid':formIsValid,'errors' : errors,'name' : fields["name"]};
}
return response;
  }

export const handleinputValidationError =(formError,data) =>{
  let response='';
  if(formError && formError[data] && formError[data].errors && !formError[data].formIsValid) response= 'inputValidationError';
  return response;
}

export const handleTextValidationError =(formError,data) =>{
  let response='';
  if(formError && formError[data] && formError[data].errors && !formError[data].formIsValid) response = <span className={"validationError"}>{formError[data].errors[data]}</span>;
  return response;
}

 
export const handleValidationError =(formError,data) =>{
  let response =handleValidation(data);
       return formError[data.name]=response;
}

  
export const handleDisabledBtnValidationError =(formError,datas) =>{
  let active=0;
  for(const data of datas){
    if(formError && formError[data] && formError[data].formIsValid){
      active++;
    }
  }
  return active!==datas.length;
}

  
