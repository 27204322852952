import React, {useEffect, useState} from 'react';
import './ColleguesList.css'
import {connect} from "react-redux";
import * as actions from "../../../actions";
import {Col, Container, Row, Form, Button, Badge, Modal, Tab, Dropdown} from "react-bootstrap";
import DataTable, { defaultThemes } from "react-data-table-component";
import mSVG from '../../../assets/gender/m.png'
import Select from "react-select";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {formatDate} from "react-day-picker/moment";
import {isAdmin, isOnlyAdmin, isOnlyRH,
    limitText,handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
import {useHistory} from "react-router-dom";
import ContextMenu from "../../menus/ContextMenu";
import {toast} from "react-toastify";
import {uploadOnCdn,getFileUrl,getExportFileUrl,getExportExcelFileFromCdn} from '../../../service'
var fileDownload = require('js-file-download');
import { useTranslation } from 'react-i18next';
import { RiAddLine, RiCloseLine, RiDownloadLine, RiEditLine, RiFilterLine, RiPhoneLine, RiUploadLine } from 'react-icons/ri';
import { BsThreeDotsVertical } from 'react-icons/bs';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

const ColleguesList = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    let connectedUser = JSON.parse(window.localStorage.getItem('glowhr_user'));
    const [show, setShow] = useState()
    const [showStep, setShowStep] = useState(1)
    const [showBranch, setShowBranch] = useState(false)
    const [showGestionRole, setShowGestionRole] = useState(false)
    const [showDepartment, setShowDepartment] = useState(false)
    const [current_row, setCurrentRow] = useState()
    const rh_type_branch = [{label: t('all_branches'), value: 'all'}, {label:t('some_branches'), value: 'branches'}]
    const rh_type_department = [{label: t('all_departments'), value: 'all'}, {label:t('some_departments'), value: 'departments'}]
    const gender_select_options = [{label: t('man'), value: 'H'}, {label:t('woman'), value: 'F'}, {label: t('other'), value: 'A'}]
    const ethnie_select_options = [
        {label: t('black_african'), value: 'noir'},
        {label:t('aboriginal'), value: 'autochtones'},
        {label: t('white'), value: 'blanc'},
        {label: t('middle_est'), value: 'moyen-orient'},
        {label: t('asian'), value: 'asiatique'},
        {label: t('latino'), value: 'hispanique-latino'},
        ]
    const employee_statuts = [{label: t('employee'), value: 'S'}, {label:t('employee_temporary'), value: 'ET'}, {label: t('contractual'), value: 'C'}]
    const [formError, setFormError] = useState({})
    const [firstName, setFirstName] = useState()
    const [lastname, setLastName] = useState()
    const [email, setEmail] = useState()
    const [salary, setSalary] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [hiredDate, setHiredDate] = useState()
    const [proStartDate, setProStartDate] = useState()
    const [probEndDate, setProbEndDate] = useState()
    const [mobile, setMobile] = useState()
    const [selectedRhTypeDepartment, setSelectedRhTypeDepartment] = useState(rh_type_department[0])
    const [selectedRhDepartment, setSelectedRhDepartment] = useState()
    const [selectedRhTypeBranch, setSelectedRhTypeBranch] = useState(rh_type_branch[0])
    const [selectedRhBranch, setSelectedRhBranch] = useState()
    const [selectedBranch, setSelectedBranch] = useState()
    const [selectedDepartment, setSelectedDepartment] = useState()
    const [selectedRole, setSelectedRole] = useState()
    const [selectedPosition, setSelectedPosition] = useState()
    const [selectedBranchFilter, setSelectedBranchFilter] = useState([])
    const [selectedDepartmentFilter, setSelectedDepartmentFilter] = useState([])
    const [selectedRoleFilter, setSelectedRoleFilter] = useState([])
    const [selectedPositionFilter, setSelectedPositionFilter] = useState([])
    const [selectedGenderFilter, setSelectedGenderFilter] = useState([])
    const [selectedEthnieFilter, setSelectedEthnieFilter] = useState([])
    const [selectedStatutEmpFilter, setSelectedStatutEmpFilter] = useState([])
    const [selectedGender, setSelectedGender] = useState()
    const [ethnie, setSelectedEthnie] = useState()
    const [selectedSuperieur, setSelectedSuperieur] = useState()
    const [selectedEmpStatut, setSelectedEmpStatut] = useState(employee_statuts[0])
    const [validated, setValidated] = useState(false)
    const [type_salary, setTypeSalaire] = useState(undefined)
    const [btnLoading, setBtnLoading] = useState(false)
    const [employees, setEmployees] = useState(undefined)
    const [attendees_target_id, setAttendeesTargetId] = useState([])
    const [attendees_target, setAttendeesTarget] = useState("all")
    const [attendees_target_idFilter, setAttendeesTargetIdFilter] = useState([])
    const [attendees_targetFilter, setAttendeesTargetFilter] = useState("all")
    const [fileName, setFileName] = useState(t("no_import_file"))
    const [file, setFile] = useState([]);
    const [restrein, setRestrein] = useState('.xlsx')

    const affectations_type =
        [{ label: t('all'), value: 'all' },
            { label: t("departments"), value: "departments"},
            { label: t('branches'), value: 'branches'},
            { label: t('positions'), value: 'positions'},
            { label: t('specific_employee'), value: 'employee'}
        ]
    const [affect, setAffect] = useState(affectations_type[0])
    const [assignTo, setAssignTo] = useState([])
    const [filtrer, setFilter] = useState(false)
    const [affectFilter, setAffectFilter] = useState(affectations_type[0])
    const [assignToFilter, setAssignToFilter] = useState([])
    const [current_rowFilter, setCurrent_rowFilter] = useState({})
    const handleAffectation_typeFilter = (e) => {
        setAssignToFilter([]);
        setAffectFilter(e)
        setAttendeesTargetFilter(e.value)
    };
    const handleSelectAssignFilter = (e) => {
        if(e) {
            setAssignToFilter(e)
            setAttendeesTargetIdFilter(e.filter(res => {
                return res._id
            }))
        }else{
            setAttendeesTargetIdFilter([])
        }
    };
    const handleSelectPosteFilter = (e) => {
        (e && e.length>0) ?  setSelectedPositionFilter(e.map(res=> {return res._id})) : setSelectedPositionFilter([]);
    };
    const handleSelectBranchFilter = (e) => {
       (e && e.length>0) ? setSelectedBranchFilter(e.map(res=> {return res._id})) : setSelectedBranchFilter([]);
    };
    const handleSelectDepartmentFilter = (e) => {
        (e && e.length>0) ? setSelectedDepartmentFilter(e.map(res=> {return res._id})) : setSelectedDepartmentFilter([]);
    };
    const handleSelectRoleFilter = (e) => {
        (e && e.length>0) ? setSelectedRoleFilter(e.map(res=> {return res._id})) : setSelectedRoleFilter([]);
    };
 const handleSexeFilter = (e) => {
        (e && e.length>0) ? setSelectedGenderFilter(e.map(res=> {return res.value})) : setSelectedGenderFilter([]);
    };
 const handleEthnieFilter = (e) => {
        (e && e.length>0) ? setSelectedEthnieFilter(e.map(res=> {return res.value})) : setSelectedEthnieFilter([]);
    };
 const handleStatutEmpFilter = (e) => {
        (e && e.length>0) ? setSelectedStatutEmpFilter(e.map(res=> {return res.value})) : setSelectedStatutEmpFilter([]);
    };

    const filterEmployee = () => {
        let employees=props.rwState.colleagues.filter(r=>r.activated);
        if(employees){
        if(selectedPositionFilter && selectedPositionFilter.length> 0){
            employees=employees.filter(res=>res?.position && selectedPositionFilter.indexOf(res?.position._id)>=0)
        }
       if(selectedDepartmentFilter && selectedDepartmentFilter.length > 0){
            employees=employees.filter(res=>res.department && selectedDepartmentFilter.indexOf(res?.department._id)>=0)
        }
       if(selectedBranchFilter && selectedBranchFilter.length > 0){
            employees=employees.filter(res=>res.branch && selectedBranchFilter.indexOf(res?.branch._id)>=0)
      }
       if(selectedRoleFilter && selectedRoleFilter.length > 0){
            employees=employees.filter(res=>res.role_info && selectedRoleFilter.indexOf(res.role_info?._id)>=0)
       }
       if(selectedGenderFilter && selectedGenderFilter.length > 0){
           employees=employees.filter(res=> selectedGenderFilter.indexOf(res?.personal?.gender)>=0);
       }
        if(selectedEthnieFilter && selectedEthnieFilter.length > 0){
            employees=employees.filter(res=> selectedEthnieFilter.indexOf(res?.personal?.ethnie)>=0)
        }
       if(selectedStatutEmpFilter && selectedStatutEmpFilter.length > 0){
           employees=employees.filter(res=> selectedStatutEmpFilter.indexOf(res?.employee_statut)>=0);
       }
       setEmployees(employees)
       // console.log(employees);
        return ;
     }
    }

    const handleAffectation_type = (e) => {
        setAssignTo([]);
        setAffect(e)
        setAttendeesTarget(e.value)
    };

    const handleClose = () => {
        setShow(null)
        resetForm();
        setShow(null)
        setBtnLoading(false)
        setShowStep(1)
        setFormError([])
    }

      const showDetail = (row_data) => {
          setSelectedBranch(props.rwState.branches.filter(option => option._id === row_data?.branch._id))
          setSelectedDepartment(props.rwState.departments.filter(option => option._id === row_data?.department._id))
          setSelectedRole(props.rwState.roles?.filter(option => option._id === row_data.role_info?._id))
          setSelectedPosition(props.rwState.positions.filter(option => option._id === row_data?.position?._id))
          setSelectedGender(gender_select_options.filter(option => option.value === row_data?.personal?.gender))
          setSelectedEmpStatut(employee_statuts.filter(option => option.value === row_data?.employee_statut))
          setSelectedSuperieur(props.rwState.colleagues.filter(option => option._id === row_data?.job_info[0]?.reports_to ? ({label: option.firstname + ' ' + option.lastname, value: option._id}) : null))
          setSelectedEthnie(ethnie_select_options.find(option => option.value === row_data?.personal?.ethnie))
          setCurrentRow(row_data)
        setShow('details')
    }

    useEffect(()=>{
        if(!props.rwState.colleagues || props.rwState.colleagues.length<=0) {
            props.action_loading(true);
            props.action_listColleagues()
        }
        props.action_listDepartments();
        props.action_setTitle(t("colleagues"))
        props.action_listBranches();
        props.action_listPositions();
        props.action_listRoles();
    },[])

  useEffect(()=>{
        if(props.rwState.colleagues) {
          setEmployees(props.rwState.colleagues)
        }
    },[props.rwState.colleagues])

    const updateCollegueInformation = row_data => {
        setSelectedBranch(props.rwState.branches.find(option => option._id === row_data?.branch._id))
        setSelectedDepartment(props.rwState.departments.find(option => option._id === row_data?.department._id))
        setSelectedRole(props.rwState.roles?.find(option => option._id === row_data.role_info?._id))
        setSelectedPosition(props.rwState.positions.find(option => option._id === row_data?.position?._id))
        setSelectedGender(gender_select_options.find(option => option.value === row_data?.personal?.gender))
        setSelectedEmpStatut(employee_statuts.find(option => option.value === row_data?.employee_statut))
        setSelectedSuperieur(props.rwState.colleagues.find(option => option._id === row_data?.job_info[0]?.reports_to))
        setSelectedEthnie(ethnie_select_options.find(option => option.value === row_data?.personal?.ethnie))
        setCurrentRow(row_data)
        setEmail(row_data.email);
        setFirstName(row_data.firstname);
        setLastName(row_data.lastname);
        setProStartDate(row_data?.job_info[0]?.probation?.probation_start);
        setProbEndDate(row_data?.job_info[0]?.probation?.probation_end);
        setSalary(row_data?.job_info[0]?.salary)
        setHiredDate(row_data.hired_on)
        setMobile(row_data.mobile)
        setStartDate(row_data?.job_info[0]?.start_date);
        setEndDate(row_data?.job_info[0]?.end_date);
        setTypeSalaire(row_data?.job_info[0]?.type_salary);
        setSelectedRhTypeBranch(rh_type_branch.find(res=>res.value===row_data.rh_type_branch))
        if(row_data.rh_type_branch && row_data.rh_type_branch!=='all'){
            setShowBranch(true)
            setSelectedRhBranch(props.rwState.branches.filter(res=>{
                if(row_data.rh_type_branch && row_data.rh_type_branch.length>0){
                    for(const r of row_data.rh_type_branch){
                        if(r===res._id){
                            return res;
                        }
                    }
                }
            }))
        }else{
            setShowBranch(false)
        }
    setSelectedRhTypeDepartment(rh_type_department.find(res=>res.value===row_data.rh_type_department))
        if(row_data.rh_type_department && row_data.rh_type_department!=='all'){
            setShowDepartment(true)
            setSelectedRhDepartment(props.rwState.departments.filter(res=>{
                if(row_data.rh_type_department && row_data.rh_type_department.length>0){
                    for(const r of row_data.rh_type_department){
                        if(r===res._id){
                            return res;
                        }
                    }
                }
            }))
        }else{
            setShowDepartment(false)
        }
        setShow('create')
    }
    const exportCollegue=()=>{
        props.action_exportEmployeeExcel();
    }
    const resetForm=()=>{
        setEmail('');
        setFirstName('');
        setLastName('');
        setProStartDate('');
        setProbEndDate('');
        setSalary('')
        setHiredDate('')
        setSelectedRhTypeBranch('')
        setSelectedRhBranch('')
        setSelectedRhDepartment('')
        setSelectedRhTypeDepartment('')
        setMobile('')
        setStartDate('');
        setEndDate();
        setTypeSalaire(undefined);
        setSelectedBranch('')
        setSelectedRole('')
        setSelectedPosition('')
        setSelectedGender('')
        setSelectedEmpStatut([0])
        setSelectedSuperieur('')
        setSelectedEthnie('')
        setFormError([])
    }
    const requestDataBuilder = () => (
        {
            "email": email,
            "firstname": firstName,
            "lastname": lastname,
            "branch": selectedBranch?._id,
            "department": selectedDepartment?._id,
            "mobile": mobile,
            "role": selectedRole?._id,
            'rh_type_branch':selectedRhTypeBranch?.value,
            'rh_branch':selectedRhBranch && selectedRhBranch.length > 0 ? selectedRhBranch?.map(r=> {
                return r._id
            }) : [],
            'rh_type_department':selectedRhTypeDepartment?.value,
            'rh_department':selectedRhDepartment && selectedRhDepartment.length > 0 ? selectedRhDepartment?.map(r=> {
                return r._id
            }) : [],
            "personal": {'ethnie':ethnie?.value,"gender": selectedGender?.value},
            "employee_statut": selectedEmpStatut?.value,
            "hired_on": hiredDate,
            "position": selectedPosition?._id,
            "job": {
                "start_date": startDate,
                "end_date": endDate,
                "type_salary": type_salary,
                "salary": salary,
                "reports_to": selectedSuperieur?._id,
                "employment_type": 3,
                "probation": {
                    "status": 1,
                    "probation_start": proStartDate,
                    "probation_end": probEndDate
                }
            },
            "language": "fr"
        }
    )

    const checkTimeLinkWasSend = (link_date) => {
       let response =false;
       if(link_date) {
           var oneHour = 60 * 60 * 1000;
           let today = new Date();
           var date = new Date(link_date);
            if ((date.getTime()+oneHour) <= today.getTime()) response = true;
       }
        return response;
    }
    const createColleague = () => {
        let colleague_data = requestDataBuilder();
        props.action_createColleague(colleague_data);
        setShow(null);
    }
   const sendlinkToColleague = (collegue) => {
       props.action_loading(true)
       let colleague_data={
            _id:collegue._id
        }
        props.action_sendLinkToColleague(colleague_data);
    }

    const saveUpdate = () => {
        let colleague_data = requestDataBuilder();
        colleague_data['employee'] = current_row?._id;
        props.action_updateColleague(colleague_data);
        setShow(null);
    }

    const proceed = (event) => {
        setBtnLoading(true)
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setBtnLoading(false)
            return
        }
        props.action_loading(true)
        if(current_row) {
            saveUpdate();
            setCurrentRow(null);
        } else {
            createColleague();
        }
        resetForm();
        setShow(null)
        setBtnLoading(false)
        setShowStep(1)
    }

    const colleaguesListColumns = isAdmin(role) ?[
        {
            name:t('name_and_profession'),
            selector:'firstname',
            cell: row => {
                return <div className={'d-flex align-items-center pt-3 mb-3'}>
                    <div className={'mr-3'}>
                        <img src={mSVG} style={{width:38,height:38}}/>
                    </div>
                    <a href="#" onClick={e => history.push({pathname:'/dash/colleague/'+row.user})}>
                        <div className={'colleagueName'}>{row.firstname + ' ' + row.lastname}</div>
                        <div className={'colleagueDetails'}>{row.position?.title}  {row.role_info?.name}</div>
                    </a>
                </div>
            },sortable: true,
        },
        {
            name:t('branch_title_unique'),
            selector:'branch',
            cell:row => {
                return <div className={'colleagueInfo'}>{row.branch?.name}</div>;
            },
            sortable: true,
        },
        {
            name:t('department_title_unique'),
            selector:'department',
            cell:row => {
                return <div className={'colleagueInfo'}>{row.department?.name}</div>;
            },
            sortable: true,
        },
        {
            name:t('role'),
            selector:'role_info',
            cell:row => {
                return <div className={'colleagueInfo'}>{row?.role_info?.name}</div>;
            },
            sortable: true,
        },
       {
            name:t('tel'),
            selector:'mobile',
            cell: row => {
                return <div className={'colleagueInfo'}><RiPhoneLine/> {row.mobile} </div>
            }
        },
        {
            name:t('email'),
            selector:'email',
            cell:row => <div className={'colleagueInfo'}>{row.email}</div>
        },

        {
            name:'',
            cell: row =>
                isOnlyAdmin(role) ?
                    <Button onClick={() => updateCollegueInformation(row)} variant={"link"} size={"sm"}><RiEditLine size={22}/></Button>
                    : (
                        isOnlyRH(role) && row.job_info[0]?.reports_to && row.job_info[0]?.reports_to===connectedUser.job_info[0]?.employee  ?
                            <Button onClick={() => updateCollegueInformation(row)} variant={"link"} size={"sm"}><RiEditLine size={22}/></Button>
                    : ''
                    ),
            grow:0
        }
    ] :
        [
            {
                name:t('name_and_profession'),
                selector:'firstname',
                cell: row => {
                    return <div className={'d-flex align-items-center pt-3 mb-3'}>
                        <div className={'mr-3'}>
                            <img src={mSVG} style={{width:38,height:38}}/>
                        </div>
                        <a href="#" onClick={e => history.push({pathname:'/dash/colleague/'+row.user})}>
                            <div className={'colleagueName'}>{row.firstname + ' ' + row.lastname}</div>
                            <div className={'colleagueDetails'}>{row.position?.title}  {row.role_info?.name}</div>
                        </a>
                    </div>
                },sortable: true,
            },
            {
                name:t('branch_title_unique'),
                selector:'branch',
                cell:row => {
                    return <div className={'colleagueInfo'}>{row.branch?.name}</div>;
                },
                sortable: true,
            },
            {
                name:t('department_title_unique'),
                selector:'department',
                cell:row => {
                    return <div className={'colleagueInfo'}>{row.department?.name}</div>;
                },
                sortable: true,
            },
            {
                name:t('tel'),
                selector:'mobile',
                cell: row => {
                    return <div className={'colleagueInfo'}><RiPhoneLine/> {row.mobile} </div>
                }
            },
            {
                name:t('email'),
                selector:'email',
                cell:row => <div className={'colleagueInfo'}>{row.email}</div>
            },


        ];
     const colleaguesAttenteListColumns = [
        {
            name:t('name_and_profession'),
            selector:'firstname',
            cell: row => {
                return <div className={'d-flex align-items-center pt-3 mb-3'}>
                    <div className={'mr-3'}>
                        <img src={mSVG} style={{width:38,height:38}}/>
                    </div>
                       <div className={'colleagueName'}>{row.firstname + ' ' + row.lastname}</div> 
                       <br/><div className={'colleagueDetails'}>{row.position?.title}  {row.role_info?.name}</div>
                </div>
            },sortable: true,
        },
        {
            name:t('department_title_unique'),
            selector:'department',
            cell:row => {
                return <div className={'colleagueInfo'}>{row.department?.name}</div>;
            },
            sortable: true,
        },
        {
            name:t('email'),
            selector:'email',
            cell:row => <div className={'colleagueInfo'}>{row.email}</div>
        },
        {
            name:'',
            cell: row =>

                isOnlyAdmin(role) && row.last_link_send_date && checkTimeLinkWasSend(row.last_link_send_date) ?
                    <div> <Button onClick={() => sendlinkToColleague(row)} variant={"warning"} size={"sm"} style={{whiteSpace:'nowrap'}}>Relancer invitation</Button></div>
                    : (
                        isOnlyRH(role) && row.last_link_send_date && checkTimeLinkWasSend(row.last_link_send_date) && row.job_info[0]?.reports_to && row.job_info[0]?.reports_to===connectedUser.job_info[0]?.employee  ?
                            <div><Button onClick={() => sendlinkToColleague(row)} variant={"warning"} size={"sm"} style={{whiteSpace:'nowrap'}}>Relancer invitation</Button></div>
                    : ''
                    )
                ,
            grow:0
        }
    ]
    const contextMenu =
        [
            {label: t('actif_text'), eventKey: 'actif'},
            {label: t('waiting_activation_text'), eventKey: 'attente'},
        ]
    const handleTypeSalaire = (e) => {
        setTypeSalaire(e);
    }
    const handleFirstName = (e) => {
        setFirstName(e.target.value)
    let data =handleValidation(t,{type:"text",name:'firstName','value':e.target.value,required:true});
    let form=formError;
            form[data.name]=data   
            setFormError(form)
          }

    const handleLastName = e => {
        setLastName(e.target.value)
        let data =handleValidation(t,{type:"text",name:'lastName','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
        
    const handleEmail = e => {
        setEmail(e.target.value)
       if(props.rwState.colleagues && props.rwState.colleagues.length > 0)
       { let emails=props.rwState.colleagues.map(r=>{return r.email;});
        let data ={};
        current_row ?  data =handleValidation(t,{type:"email",name:'email','value':e.target.value,required:true})
     :  data =handleValidation(t,{type:"email",name:'email','value':e.target.value,required:true,emails:emails});
        let form=formError;
                form[data.name]=data   
                setFormError(form)}
    }
    
    const handleSalary = e => {setSalary(e.target.value)
        let data =handleValidation(t,{type:"number",name:'salary','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    
    const handleMobile = e => {setMobile(e.target.value)
        let data =handleValidation(t,{type:"number",name:'mobile','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
        
    const handleBranch = e => {setSelectedBranch(e)
        let data =handleValidation(t,{type:"text",name:'branch','value':e,required:true,isArray:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)}

    const handleRhTypeBranch = e => {
        setSelectedRhTypeBranch(e)
        e.value==='all' ? setShowBranch(false) : setShowBranch(true);
    }

    const handleRhBranch = e => setSelectedRhBranch(e)

    const handleSup = e =>{ setSelectedSuperieur(e)
        let data =handleValidation(t,{type:"text",name:'superieur','value':e,required:true,isArray:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }

    const handleDepartment = e => {setSelectedDepartment(e)
        let data =handleValidation(t,{type:"text",name:'department','value':e,required:true,isArray:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }

    const handleRhTypeDepartment = e => {
        setSelectedRhTypeDepartment(e)
        e.value==='all' ? setShowDepartment(false) : setShowDepartment(true);

    }

    const handleRhDepartment = e =>{ setSelectedRhDepartment(e)}

    const handlePosition = e =>{ setSelectedPosition(e)
        let data =handleValidation(t,{type:"text",name:'position','value':e,required:true,isArray:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    
    const handleSexe = e => {setSelectedGender(e)
        let data =handleValidation(t,{type:"text",name:'sexe','value':e,required:true,isArray:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleEthnie = e => {setSelectedEthnie(e)
        let data =handleValidation(t,{type:"text",name:'ethnie','value':e,required:true,isArray:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleStatutEmp = e =>{ 
        setSelectedEmpStatut(e)
        let data =handleValidation(t,{type:"text",name:'empStatut','value':e,required:true,isArray:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }

    const handleRole = e => {
        setSelectedRole(e)
        if(e.name==='RH') {
            setShowGestionRole(true)
        }else{
            setShowGestionRole(false)
        }
        let data =handleValidation(t,{type:"text",name:'role','value':e,required:true,isArray:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
        
    const handHiredDate = e => {setHiredDate(e.target.value)
        let data =handleValidation(t,{type:"text",name:'hiredDate','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
        
    const handStartDate = e => {setStartDate(e.target.value)
        let data =handleValidation(t,{type:"text",name:'startDate','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }

    const handleEndDate = (e) =>  {setEndDate(e.target.value)
        let data =handleValidation(t,{type:"text",name:'endDate','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }

    const handleProStartDate = e => {setProStartDate(e.target.value)
        let data =handleValidation(t,{type:"text",name:'proStartDate','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
        
    const handleProbEndDate = e => {setProbEndDate(e.target.value)
        let data =handleValidation(t,{type:"text",name:'proEndDate','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }

    const closeFilter = () => {
        setEmployees(props.rwState.colleagues)
        setFilter(false);
    }

    const customStyles = {
        // header: {
        //     style: {
        //         minHeight: '56px',
        //         background: 'transparent'
        //     },
        // },
        // headRow: {
        //     style: {
        //         borderTopStyle: 'solid',
        //         borderTopWidth: '1px',
        //         borderTopColor: defaultThemes.default.divider.default,
        //     },
        // },
        // headCells: {
        //     style: {
        //         '&:not(:last-of-type)': {
        //             borderRightStyle: 'solid',
        //             borderRightWidth: '1px',
        //             borderRightColor: defaultThemes.default.divider.default,
        //         },
        //     },
        // },
        // cells: {
        //     style: {
        //         '&:not(:last-of-type)': {
        //             borderRightStyle: 'solid',
        //             borderRightWidth: '1px',
        //             borderRightColor: defaultThemes.default.divider.default,
        //         },
        //     },
        // },
    };
    const handleFile = (e) => {
        if(e) {
            let file_data = e.target.files[0]
            setFile(file_data)
            setFileName(file_data.name)
            let data =handleValidation(t,{type:"text",name:'excelFile','value':file_data.name,required:true});
            let form=formError;
                    form[data.name]=data   
                    setFormError(form)
        }
    };

    const importExcel= async  () => {
        let  files=file;
        setFile(undefined)
      let o = {};
      if(files){
               uploadOnCdn(files)
                  .then(resp => {
                      setFile(resp?.filename);
                     o.file=resp?.filename;
                          props.action_createEmployeeExcel(o)
                  })
          }else{
            toast(t("no_doc_file"),{type:"warning"})
          }
      
      setShow(null)
      props.action_loading(true)
      setFormError([])
      setFile()
      setFileName(t("no_import_file"))
      }
      const downloadDocument = () => {
        getExportExcelFileFromCdn('excel_import_employe_data.xlsx')
        .then(response => {
            fileDownload(response,'excel_import_employe_data.xlsx')
        })
        .catch(err => console.log('error get from cdn : ', err))
       
}
      
    return <div id={'ColleguesList'}>
        <Container fluid>
            {isAdmin(role) ?
            <Tab.Container id="left-tabs-example" defaultActiveKey={'actif'}>
                <div className="d-flex align-items-start justify-content-between">
                    <ContextMenu menu={contextMenu}/>
                    <Col>
                    <Button onClick={e=>setShow('importExcel')}  className={"float-right btn btn-warning mr-2 ml-2"}>
                            <RiAddLine/> Importer
                        </Button>
                        <Button onClick={e=>setShow('create')} className={"float-right"}>
                            <RiAddLine/> Créer un profile
                        </Button>
                   
                    </Col>
                </div>
                <Tab.Content>
                    <Tab.Pane className={"lastRollL"} eventKey="actif">
                        <div className={"col-12"}>
                            <div className="mt-2 mb-4 col-lg-12">
                                <a href="#" className={"btn btn-light btn-sm"} onClick={()=>setFilter(true)} hidden={filtrer}>
                                    <RiFilterLine style={{color: '#240265', fontWeight: '600'}} size={25}/>
                                    {t("filter_text")}
                                </a>
                <Dropdown className={"float-right"}>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <BsThreeDotsVertical/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-2" onClick={()=>downloadDocument()}>
                        <RiDownloadLine style={{color: '#240265', fontWeight: '600'}} size={20}/> &nbsp;&nbsp;
                                     {t("model_import")}
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-4"onClick={()=>exportCollegue()}>
                        <RiUploadLine style={{color: '#240265', fontWeight: '600'}} size={20}/> &nbsp;&nbsp;
                                    {t("export_on_xlsx")}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                            </div>
                            <Form.Group style={{background: '#fff',padding: '2%'}} hidden={!filtrer}>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <Form.Label> {t("filter_by")} : </Form.Label>
                                        <RiCloseLine onClick={e=>{closeFilter()}} style={{cursor:'pointer', color: '#240265', fontWeight: '600',float:'right'}} size={25}/>
                                    </div>

                                    <div className="col-4" >
                                        <Form.Group >
                                            <Select noOptionsMessage={() => t('no_data_available')}
                                                options={props.rwState.branches}
                                                getOptionLabel={o => o.name}
                                                getOptionValue={o => o._id}
                                                defaultValue={selectedBranchFilter}
                                                styles={{
                                                    control: base => ({
                                                        ...base,
                                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                                    })
                                                }}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: '#6600CC',
                                                        primary25: '#6600CC25',
                                                        primary75: '#6600CC75',
                                                        primary50: '#6600CC50'
                                                    },
                                                })}
                                                placeholder={t('branch_title_unique')}
                                                isMulti
                                                onChange={handleSelectBranchFilter}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-4" >
                                        <Form.Group >
                                            <Select noOptionsMessage={() => t("no_data_available")}
                                                options={props.rwState.departments}
                                                getOptionLabel={o => o.name}
                                                getOptionValue={o => o._id}
                                                defaultValue={selectedDepartmentFilter}
                                                styles={{
                                                    control: base => ({
                                                        ...base,

                                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                                    })
                                                }}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: '#6600CC',
                                                        primary25: '#6600CC25',
                                                        primary75: '#6600CC75',
                                                        primary50: '#6600CC50'
                                                    },
                                                })}
                                                placeholder={t('department_title_unique')}
                                                isMulti
                                                onChange={handleSelectDepartmentFilter}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-4" >
                                        <Form.Group >
                                            <Select noOptionsMessage={() => t("no_data_available")}
                                                options={props.rwState.positions}
                                                getOptionLabel={o => o.title}
                                                getOptionValue={o => o._id}
                                                defaultValue={selectedPositionFilter}
                                                styles={{
                                                    control: base => ({
                                                        ...base,

                                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                                    })
                                                }}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: '#6600CC',
                                                        primary25: '#6600CC25',
                                                        primary75: '#6600CC75',
                                                        primary50: '#6600CC50'
                                                    },
                                                })}
                                                placeholder={t('position_title_unique')}
                                                isMulti
                                                onChange={handleSelectPosteFilter}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-4" >
                                        <Form.Group>
                                            <label>{t('role')}</label>
                                            <Select noOptionsMessage={() => t("no_data_available")}
                                                options={props.rwState.roles}
                                                getOptionLabel={o => o.name}
                                                getOptionValue={o => o._id}
                                                defaultValue={selectedRoleFilter}
                                                styles={{
                                                    control: base => ({
                                                        ...base,
                                                        minHeight: 38
                                                    })
                                                }}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: '#6600CC',
                                                        primary25: '#6600CC25',
                                                        primary75: '#6600CC75',
                                                        primary50: '#6600CC50'
                                                    },
                                                })}
                                                placeholder={t('role')}
                                                isMulti
                                                onChange={e => handleSelectRoleFilter(e)}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-4" >
                                        <Form.Group>
                                            <label>{t('sex')}</label>
                                            <Select noOptionsMessage={() => t("no_data_available")}
                                                options={gender_select_options}
                                                defaultValue={selectedGenderFilter}
                                                getOptionLabel={o => o.label}
                                                getOptionValue={o => o.value}
                                                styles={{
                                                    control: base => ({
                                                        ...base,
                                                        minHeight: 38
                                                    })
                                                }}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: '#6600CC',
                                                        primary25: '#6600CC25',
                                                        primary75: '#6600CC75',
                                                        primary50: '#6600CC50'
                                                    },
                                                })}
                                                isMulti
                                                placeholder={t('sex')}
                                                onChange={e => handleSexeFilter(e)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-4" >
                                        <Col>
                                            <Form.Group>
                                                <label>{t("ethni")}</label>
                                                <Select noOptionsMessage={() => t("no_data_available")}
                                                    options={ethnie_select_options}
                                                    defaultValue={selectedEthnieFilter}
                                                    getOptionLabel={o => o.label}
                                                    getOptionValue={o => o.value}
                                                    styles={{
                                                        control: base => ({
                                                            ...base,
                                                            minHeight: 38
                                                        })
                                                    }}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: '#6600CC',
                                                            primary25: '#6600CC25',
                                                            primary75: '#6600CC75',
                                                            primary50: '#6600CC50'
                                                        },
                                                    })}
                                                    isMulti
                                                    placeholder={t('ethni')}
                                                    onChange={e => handleEthnieFilter(e)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </div>
                                    <div className="col-4" >
                                        <Form.Group>
                                            <label>{t("employee_status_text")}</label>
                                            <Select noOptionsMessage={() => t("no_data_available")}
                                                options={employee_statuts}
                                                defaultValue={selectedStatutEmpFilter}
                                                getOptionLabel={o => o.label}
                                                getOptionValue={o => o.value}
                                                styles={{
                                                    control: base => ({
                                                        ...base,
                                                        minHeight: 38
                                                    })
                                                }}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: '#6600CC',
                                                        primary25: '#6600CC25',
                                                        primary75: '#6600CC75',
                                                        primary50: '#6600CC50'
                                                    },
                                                })}
                                                isMulti
                                                placeholder={t('employee_status')}
                                                onChange={e => handleStatutEmpFilter(e)}
                                            />
                                        </Form.Group>
                                    </div>
                                   <div className="col-12 pt-1">
                                        <button className="btn btn-outline-primary btn-sm" onClick={e=>{filterEmployee()}}>{t("filter_text")}</button>
                                    </div>
                                </div>
                            </Form.Group>
                        </div>
                        <Col>
                            {employees ?
                                ( employees.filter(r=>r.activated).length>0 ?
                                        <div  className={'mt-3 pt-4 col-12 '} style={{background: '#fff'}}>
                                            <h3>{t("result")} : <span> {employees.filter(r=>r.activated).length} {t("collegue")}</span> </h3>
                                            <DataTable
                                                title={t('company_employee')}
                                                noHeader
                                                fixedHeader
                                                fixedHeaderScrollHeight="600px"
                                                pagination
                                                columns={colleaguesListColumns}
                                                data={employees.filter(r=>r.activated)}
                                                highlightOnHover
                                                pointerOnHover
                                                // customStyles={customStyles}
                                            />
                                        </div>
                                        : <div class="col-12 mt-5  mb-5 text-center">{t("no_employee_available")}</div>
                                )
                                : <div class="col-12 mt-5  mb-5 text-center"><img src="../../../../public/loader.gif" alt=""/></div>
                            }
                        </Col>
                    </Tab.Pane>
                    <Tab.Pane className={"lastRollL"} eventKey="attente">
                        <Col>
                            {props.rwState.colleagues ?
                                ( props.rwState.colleagues.filter(r=>!r.activated).length>0 ?
                                        <div className={'mt-3'}>
                                            <DataTable
                                                title={t('employee_list')}
                                                noHeader
                                                fixedHeader
                                                fixedHeaderScrollHeight="600px"
                                                pagination
                                                columns={colleaguesAttenteListColumns}
                                                data={props.rwState.colleagues.filter(r=>!r.activated)}
                                                highlightOnHover
                                                pointerOnHover
                                                // customStyles={customStyles}
                                            />
                                        </div>
                                        : <div class="col-12 mt-5  mb-5 text-center">{t("no_employee_available")}</div>
                                )
                                : <div class="col-12 mt-5  mb-5 text-center"><img src="../../../../public/loader.gif" alt=""/></div>
                            }
                        </Col>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
                :
                <Col>
                    {employees ?
                        ( employees.filter(r=>r.activated).length>0 ?
                                <div className={'mt-3'}>
                                    <DataTable
                                        title={t('employee_list')}
                                        noHeader
                                        fixedHeader
                                        fixedHeaderScrollHeight="600px"
                                        pagination
                                        columns={colleaguesListColumns}
                                        data={employees.filter(r=>r.activated)}
                                        highlightOnHover
                                        pointerOnHover
                                        noDataComponent={t('no_data_available')}
                                        paginationComponentOptions={
                                            {
                                           rowsPerPageText: t('line_per_page'),
                                            rangeSeparatorText: t('for'),
                                             noRowsPerPage: false,
                                              selectAllRowsItem: false,
                                               selectAllRowsItemText: t('all')} 
                                           }
                                        // customStyles={customStyles}
                                    />
                                </div>
                                : <div class="col-12 mt-5  mb-5 text-center">{t("no_employee_available")}</div>
                        )
                        : <div class="col-12 mt-5  mb-5 text-center"><img src="../../../../public/loader.gif" alt=""/></div>
                    }
                </Col>
            }
            <Row>
           
            </Row>
        </Container>

        <Modal size={"lg"} centered show={show === 'create'} onHide={handleClose}>
            <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
                {
                    current_row ? (<Modal.Title className='w-100'>{t("update_employee_text")}</Modal.Title>) : (<Modal.Title className='w-100'>{t("created_employee_text")}</Modal.Title>)
                }
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <Form noValidate validated={validated} onSubmit={proceed}>
                  <fieldset hidden={showStep!==1}>
                      <h2>{t("personnal_info_text")} </h2>
                      <Row>
                        <Col>
                            <Form.Group>
                                <label>{t("first_name_text")} <span className={'required_box'}>*</span> </label>
                                <Form.Control
                                    placeholder={t('first_name')}
                                    onChange={handleFirstName}
                                    defaultValue={firstName ? firstName : ''}
                                    className={(formError && formError.firstName && formError.firstName.errors && !formError.firstName.formIsValid) ? 'inputValidationError' : ''}
                                />
                           {(formError && formError.firstName && formError.firstName.errors && !formError.firstName.formIsValid) ? <span className={"validationError"}>{formError.firstName.errors.firstName}</span> : ''}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <label>{t("last_name_text")} <span className={'required_box'}>*</span> </label>
                                <Form.Control
                                    placeholder={t("last_name")}
                                    onChange={handleLastName}
                                    defaultValue={lastname?lastname : ''}
                                    className={(formError && formError.lastName && formError.lastName.errors && !formError.lastName.formIsValid) ? 'inputValidationError' : ''}
                                    />
                               {(formError && formError.lastName && formError.lastName.errors && !formError.lastName.formIsValid) ? <span className={"validationError"}>{formError.lastName.errors.lastName}</span> : ''}
                                 </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <label>{t("sex")} <span className={'required_box'}>*</span> </label>
                                <Select noOptionsMessage={() => t("no_data_available")}
                                    options={gender_select_options}
                                    value={selectedGender}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            minHeight: 38
                                        })
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#6600CC',
                                            primary25: '#6600CC25',
                                            primary75: '#6600CC75',
                                            primary50: '#6600CC50'
                                        },
                                    })}
                                    placeholder={t("sex")}
                                    onChange={e => handleSexe(e)}
                                    className={(formError && formError.sexe && formError.sexe.errors && !formError.sexe.formIsValid) ? 'inputValidationError' : ''}
                                    />
                               {(formError && formError.sexe && formError.sexe.errors && !formError.sexe.formIsValid) ? <span className={"validationError"}>{formError.sexe.errors.sexe}</span> : ''}
                               </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <label>{t("ethni")} <span className={'required_box'}>*</span> </label>
                                <Select noOptionsMessage={() => t("no_data_available")}
                                    options={ethnie_select_options}
                                    value={ethnie}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            minHeight: 38
                                        })
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#6600CC',
                                            primary25: '#6600CC25',
                                            primary75: '#6600CC75',
                                            primary50: '#6600CC50'
                                        },
                                    })}
                                    placeholder={t("ethni")}
                                    onChange={e => handleEthnie(e)}
                                    className={(formError && formError.ethnie && formError.ethnie.errors && !formError.ethnie.formIsValid) ? 'inputValidationError' : ''}
                                    />
                               {(formError && formError.ethnie && formError.ethnie.errors && !formError.ethnie.formIsValid) ? <span className={"validationError"}>{formError.ethnie.errors.ethnie}</span> : ''}
                               </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <label>{t("email")} <span className={'required_box'}>*</span> </label>
                                <Form.Control
                                    placeholder={t("email")}
                                    onChange={handleEmail}
                                    type={"email"}
                                    defaultValue={email ? email : ''}
                                    className={(formError && formError.email && formError.email.errors && !formError.email.formIsValid) ? 'inputValidationError' : ''}
                                    />
                               {(formError && formError.email && formError.email.errors && !formError.email.formIsValid) ? <span className={"validationError"}>{formError.email.errors.email}</span> : ''}
                                </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <label>{t("tel")} <span className={'required_box'}>*</span> </label>
                                <Form.Control
                                    placeholder={t("tel")}
                                    onChange={handleMobile}
                                    type={"tel"}
                                    defaultValue={mobile ? mobile : ''}
                                    className={(formError && formError.mobile && formError.mobile.errors && !formError.mobile.formIsValid ) ? 'inputValidationError' : ''}
                                    />
                               {(formError 
                                && formError.mobile && formError.mobile.errors 
                                && !formError.mobile.formIsValid) ? <span className={"validationError"}>{formError.mobile.errors.mobile}</span> : ''}
                               </Form.Group>
                        </Col>

                    </Row>
                      <div className="row">
                          <div className="col-12 text-right">
                              <Button variant="dark" class={"mr-2"} onClick={handleClose}>
                                  {t("cancel")}
                              </Button>&nbsp;&nbsp;
                              <Button  type="button" variant="primary" disabled={(
                                !formError?.mobile?.formIsValid
                                 || !formError?.lastName?.formIsValid 
                                 || !formError?.email?.formIsValid 
                                 || !formError?.sexe?.formIsValid 
                                 || !formError?.ethnie?.formIsValid 
                                 || !formError?.firstName?.formIsValid )}  onClick={e=>{setShowStep(2)}}>
                                  {t("continous")}
                              </Button>
                          </div>
                      </div>
                  </fieldset>
                    <fieldset hidden={showStep!==2}>
                    <h2>{t("administrative_info_text")} </h2>
                    <Row>
                        <Col>
                            <Form.Group>
                                <label>{t("branch_title_unique")} <span className={'required_box'}>*</span> </label>
                                <Select noOptionsMessage={() => t("no_data_available")}
                                    options={props.rwState.branches}
                                    getOptionLabel={o => o.name}
                                    getOptionValue={o => o._id}
                                    value={selectedBranch}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            minHeight: 38
                                        })
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#6600CC',
                                            primary25: '#6600CC25',
                                            primary75: '#6600CC75',
                                            primary50: '#6600CC50'
                                        },
                                    })}
                                    placeholder={t("branch_title_unique")}
                                    onChange={e => handleBranch(e)}
                                    className={(formError && formError.branch && formError.branch.errors && !formError.branch.formIsValid) ? 'inputValidationError' : ''}
                                    />
                               {(formError && formError.branch && formError.branch.errors && !formError.branch.formIsValid) ? <span className={"validationError"}>{formError.branch.errors.branch}</span> : ''}
                                </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <label>{t("department_title_unique")} <span className={'required_box'}>*</span> </label>
                                <Select noOptionsMessage={() => t("no_data_available")}
                                    options={props.rwState.departments}
                                    getOptionLabel={o => o.name}
                                    getOptionValue={o => o._id}
                                    value={selectedDepartment}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            minHeight: 38
                                        })
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#6600CC',
                                            primary25: '#6600CC25',
                                            primary75: '#6600CC75',
                                            primary50: '#6600CC50'
                                        },
                                    })}
                                    placeholder={t("department_title_unique")}
                                    onChange={e => handleDepartment(e)}
                                    className={(formError && formError.department && formError.department.errors && !formError.department.formIsValid) ? 'inputValidationError' : ''}
                                    />
                               {(formError && formError.department && formError.department.errors && !formError.department.formIsValid) ? <span className={"validationError"}>{formError.department.errors.department}</span> : ''}
                               </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <label>{t("position_title_unique")} <span className={'required_box'}>*</span> </label>
                                <Select noOptionsMessage={() => t("no_data_available")}
                                    options={props.rwState.positions}
                                    getOptionLabel={o => o.title}
                                    getOptionValue={o => o._id}
                                    value={selectedPosition}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            minHeight: 38
                                        })
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#6600CC',
                                            primary25: '#6600CC25',
                                            primary75: '#6600CC75',
                                            primary50: '#6600CC50'
                                        },
                                    })}
                                    placeholder={t("position_title_unique")}
                                    onChange={e => handlePosition(e)}
                                    className={(formError && formError.position && formError.position.errors && !formError.position.formIsValid) ? 'inputValidationError' : ''}
                                    />
                               {(formError && formError.position && formError.position.errors && !formError.position.formIsValid) ? <span className={"validationError"}>{formError.position.errors.position}</span> : ''}
                                </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <label>{t("hierarchic_supe")} <span className={'required_box'}>*</span> </label>
                                <Select noOptionsMessage={() => t("no_data_available")}
                                    options={props.rwState.colleagues}
                                    getOptionLabel={o => o.firstname+' '+o.lastname}
                                    getOptionValue={o => o._id}
                                    value={selectedSuperieur}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            minHeight: 38,
                                        })
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#6600CC',
                                            primary25: '#6600CC25',
                                            primary75: '#6600CC75',
                                            primary50: '#6600CC50'
                                        },
                                    })}
                                    placeholder={t("hierarchic_supe")}
                                    onChange={e => handleSup(e)}
                                    className={(formError && formError.superieur && formError.superieur.errors && !formError.superieur.formIsValid) ? 'inputValidationError' : ''}
                                    />
                               {(formError && formError.superieur && formError.superieur.errors && !formError.superieur.formIsValid) ? <span className={"validationError"}>{formError.superieur.errors.superieur}</span> : ''}
                               </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <label>{t("employee_status_text")} <span className={'required_box'}>*</span> </label>
                                <Select noOptionsMessage={() => t("no_data_available")}
                                    options={employee_statuts}
                                    value={selectedEmpStatut}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            minHeight: 38
                                        })
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#6600CC',
                                            primary25: '#6600CC25',
                                            primary75: '#6600CC75',
                                            primary50: '#6600CC50'
                                        },
                                    })}
                                    placeholder={t("employee_status_text")}
                                    onChange={e => handleStatutEmp(e)}
                                    className={(formError && formError.empStatut && formError.empStatut.errors && !formError.empStatut.formIsValid) ? 'inputValidationError' : ''}
                                    />
                               {(formError && formError.empStatut && formError.empStatut.errors && !formError.empStatut.formIsValid) ? <span className={"validationError"}>{formError.empStatut.errors.empStatut}</span> : ''}
                                </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <label>{t("role")} <span className={'required_box'}>*</span> </label>
                                <Select noOptionsMessage={() => t("no_data_available")}
                                    options={props.rwState.roles}
                                    getOptionLabel={o => o.name}
                                    getOptionValue={o => o._id}
                                    value={selectedRole}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            minHeight: 38
                                        })
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#6600CC',
                                            primary25: '#6600CC25',
                                            primary75: '#6600CC75',
                                            primary50: '#6600CC50'
                                        },
                                    })}
                                    placeholder={t("role")}
                                    onChange={e => handleRole(e)}
                                    className={(formError && formError.role && formError.role.errors && !formError.role.formIsValid) ? 'inputValidationError' : ''}
                                    />
                               {(formError && formError.role && formError.role.errors && !formError.role.formIsValid) ? <span className={"validationError"}>{formError.role.errors.role}</span> : ''}
                               </Form.Group>
                        </Col>
                    </Row>
                        <Row hidden={!showGestionRole}>
                            <Col sm={12} md={6}>
                                <Form.Group>
                                    <label>{t("branch_to_manage")} <span className={'required_box'}>*</span> </label>
                                    <Select noOptionsMessage={() => t("no_data_available")}
                                        options={rh_type_branch}
                                        value={selectedRhTypeBranch}
                                        styles={{
                                            control: base => ({
                                                ...base,
                                                minHeight: 38
                                            })
                                        }}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#6600CC',
                                                primary25: '#6600CC25',
                                                primary75: '#6600CC75',
                                                primary50: '#6600CC50'
                                            },
                                        })}
                                        placeholder={t('type_branch')}
                                        onChange={e => handleRhTypeBranch(e)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6} hidden={!showBranch}>
                                <Form.Group>
                                    <label>{t("choose_branch")} <span className={'required_box'}>*</span> </label>
                                    <Select noOptionsMessage={() => t("no_data_available")}
                                        options={props.rwState.branches}
                                        getOptionLabel={o => o.name}
                                        getOptionValue={o => o._id}
                                        value={selectedRhBranch}
                                        styles={{
                                            control: base => ({
                                                ...base,
                                                minHeight: 38
                                            })
                                        }}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#6600CC',
                                                primary25: '#6600CC25',
                                                primary75: '#6600CC75',
                                                primary50: '#6600CC50'
                                            },
                                        })}
                                        isMulti
                                        placeholder={t('choose_one_more_branch')}
                                        onChange={e => handleRhBranch(e)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group>
                                    <label>{t("department_to_manage")} <span className={'required_box'}>*</span> </label>
                                    <Select noOptionsMessage={() => t("no_data_available")}
                                        options={rh_type_department}
                                        value={selectedRhTypeDepartment}
                                        styles={{
                                            control: base => ({
                                                ...base,
                                                minHeight: 38
                                            })
                                        }}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#6600CC',
                                                primary25: '#6600CC25',
                                                primary75: '#6600CC75',
                                                primary50: '#6600CC50'
                                            },
                                        })}
                                        placeholder={t('type_department')}
                                        onChange={e => handleRhTypeDepartment(e)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6} hidden={!showDepartment}>
                                <Form.Group>
                                    <label>{t("choose_department")} <span className={'required_box'}>*</span> </label>
                                    <Select noOptionsMessage={() => t("no_data_available")}
                                        options={props.rwState.departments}
                                        getOptionLabel={o => o.name}
                                        getOptionValue={o => o._id}
                                        value={selectedRhDepartment}
                                        styles={{
                                            control: base => ({
                                                ...base,
                                                minHeight: 38
                                            })
                                        }}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#6600CC',
                                                primary25: '#6600CC25',
                                                primary75: '#6600CC75',
                                                primary50: '#6600CC50'
                                            },
                                        })}
                                        isMulti
                                        placeholder={t('choose_one_more_department')}
                                        onChange={e => handleRhDepartment(e)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                       <div className="row">
                           <div className="col-12 text-right">
                               <Button variant="light" class={"mr-2"} onClick={e=>{setShowStep(1)}}>
                                   {t("previous")}
                               </Button>&nbsp;&nbsp;
                               <Button  type="button" variant="primary" disabled={
                                   !formError?.role?.formIsValid
                                || !formError?.empStatut?.formIsValid
                                || !formError?.superieur?.formIsValid
                                || !formError?.position?.formIsValid
                                || !formError?.department?.formIsValid
                                || !formError?.branch?.formIsValid
                               }  onClick={e=>{setShowStep(3)}}>
                                   {t("continous")}
                               </Button>
                           </div>
                       </div>

                    </fieldset>
                    <fieldset hidden={showStep!==3}>
                        <h2>{t("recrutment_info")} </h2>
                    <Row>
                        <Col>
                            <Form.Group>
                                <label>{t("recruitment_date")} <span className={'required_box'}>*</span> </label>
                                <Form.Control
                                    type={'date'}
                                    onChange={handHiredDate}
                                    defaultValue={hiredDate ? formatDate(hiredDate, 'YYYY-MM-DD') : ''}
                                    name='end_date'
                                    className={(formError && formError.hiredDate && formError.hiredDate.errors && !formError.hiredDate.formIsValid) ? 'inputValidationError' : ''}
                                    />
                               {(formError && formError.hiredDate && formError.hiredDate.errors && !formError.hiredDate.formIsValid) ? <span className={"validationError"}>{formError.hiredDate.errors.hiredDate}</span> : ''}
                              
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <label>{t("first_day_of_work")} <span className={'required_box'}>*</span> </label>
                                <Form.Control
                                    type={'date'}
                                    onChange={handStartDate}
                                    defaultValue={startDate ? formatDate(startDate, 'YYYY-MM-DD') : 4}
                                    name='end_date'
                                    className={(formError && formError.startDate && formError.startDate.errors && !formError.startDate.formIsValid) ? 'inputValidationError' : ''}
                                    />
                               {(formError && formError.startDate && formError.startDate.errors && !formError.startDate.formIsValid) ? <span className={"validationError"}>{formError.startDate.errors.startDate}</span> : ''}
                              
                            </Form.Group>
                        </Col>

                        <Col hidden={!selectedEmpStatut || selectedEmpStatut?.value==='S'}>
                            <Form.Group>
                                <label>{t("end_of_contract_date")} <span className={'required_box'}>*</span> </label>
                                <Form.Control
                                    type={'date'}
                                    onChange={handleEndDate}
                                    defaultValue={endDate ? formatDate(endDate, 'YYYY-MM-DD') : ''}
                                    name='end_date'
                                    className={(formError && formError.endDate && formError.endDate.errors && !formError.endDate.formIsValid) ? 'inputValidationError' : ''}
                                    />
                               {(formError && formError.endDate && formError.endDate.errors && !formError.endDate.formIsValid) ? <span className={"validationError"}>{formError.endDate.errors.endDate}</span> : ''}
                             
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <label>{t("probation_start")}</label> <br/>
                                <Form.Control
                                    type={'date'}
                                    onChange={handleProStartDate}
                                    defaultValue={proStartDate ? formatDate(proStartDate, 'YYYY-MM-DD') : ''}
                                    name='probation_start'
                                 />
                              
                            </Form.Group>
                        </Col>
                    <Col>
                        <Form.Group>
                            <label>{t("probation_end")}</label> <br/>
                            <Form.Control
                                type={'date'}
                                onChange={handleProbEndDate}
                                defaultValue={probEndDate ? formatDate(probEndDate, 'YYYY-MM-DD') : ''}
                                name='probation end'
                                // placeholder={formatDate(new Date(), 'DD/MM/YYYY', 'fr')}
                            />
                            {/*<DayPickerInput*/}
                            {/*    inputProps={{className: 'form-control'}}*/}
                            {/*    format="DD/MM/YYYY"*/}
                            {/*    formatDate={formatDate}*/}
                            {/*    placeholder={current_row?.job_info[0]?.probation?.probation_end ? formatDate(new Date(current_row?.job_info[0]?.probation?.probation_end), 'DD/MM/YYYY', 'fr') : 'Date de fin probation '+`${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}*/}
                            {/*    onDayChange={handleProbEndDate}*/}
                            {/*    dayPickerProps = {{ selectedDays: new Date(current_row?.job_info[0]?.probation?.probation_end) }}*/}
                            {/*/>*/}
                        </Form.Group>
                    </Col>
                        <Col className={"mt-3"}>
                            <Form.Group>
                                <Form.Label>{t("salary")}</Form.Label> <br/>
                                <div key={`inline-external`} className="mb-3">
                                    <Form.Check
                                        size={"lg"}
                                        type={"radio"}
                                        name={"type_salary"}
                                        label={"mensuel"}
                                        value={"mensuel"}
                                        id={"inline-external-2"}
                                        inline
                                        checked={type_salary ? (type_salary === 'mensuel') : true}
                                        onClick={()=>handleTypeSalaire('mensuel')}
                                    />
                                    <Form.Check
                                        size={"lg"}
                                        type={"radio"}
                                        name={"type_salary"}
                                        label={"horraire"}
                                        value={"horraire"}
                                        id={"inline-external-1"}
                                        inline
                                        checked={type_salary ? (type_salary === 'horraire') : false}
                                        onClick={()=>handleTypeSalaire('horraire')}
                                    />

                                </div>
                            </Form.Group>
                        </Col>
                </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <label>{t("mensual_salary")} <span className={'required_box'}>*</span> </label>
                                <Form.Control
                                    placeholder={'Salaire'}
                                    type={'number'}
                                    min={0}
                                    maxlength={10}
                                    onChange={handleSalary}
                                    value={salary ? salary : ''}
                                    className={(formError && formError.salary && formError.salary.errors && !formError.salary.formIsValid) ? 'inputValidationError' : ''}
                                    />
                               {(formError && formError.salary && formError.salary.errors && !formError.salary.formIsValid) ? <span className={"validationError"}>{formError.salary.errors.salary}</span> : ''}
                                </Form.Group>
                        </Col>
                    </Row>

                        <div className="row">
                            <div className="col-12 text-right">
                                <Button variant="dark" class={"mr-2"} onClick={handleClose}>
                                    {t("cancel")}
                                </Button>&nbsp;&nbsp;
                                <Button variant="light" class={"mr-2"} onClick={e=>{setShowStep(2)}}>
                                    {t("previous")}
                                </Button>&nbsp;&nbsp;
                                <Button  type="submit" disabled={
                                   selectedEmpStatut && selectedEmpStatut?.value==='S' ?
                                    !formError?.salary?.formIsValid
                                    ||  !formError?.startDate?.formIsValid
                                    ||  !formError?.hiredDate?.formIsValid
                                    || btnLoading
                                    :
                                    !formError?.salary?.formIsValid
                                    ||  !formError?.endDate?.formIsValid
                                    ||  !formError?.startDate?.formIsValid
                                    ||  !formError?.hiredDate?.formIsValid
                                    || btnLoading
                                    } variant="primary" loading={btnLoading}>
                                    {t("save")}
                                </Button>
                            </div>
                        </div>
                    </fieldset>

                </Form>
            </Modal.Body>

        </Modal>

    {/*Details collegues */}
        {
            current_row ?
                <Modal size={"lg"} centered show={show === 'details'} onHide={handleClose}>

                    <Modal.Body className={'p-4'}>
                        <Form noValidate>
                            <Row>
                                <Col>
                                    <div className={'text-center mb-4'}>
                                        <img src={mSVG} style={{width:100,height:100}}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <label>{t("first_name")}</label>
                                        <Form.Control
                                             readOnly={true}
                                            placeholder={t("first_name")}
                                            onChange={handleFirstName}
                                            defaultValue={current_row?.firstname}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <label>{t("last_name")}</label>
                                        <Form.Control
                                             readOnly={true}
                                            placeholder={t("last_name")}
                                            onChange={handleLastName}
                                            defaultValue={current_row?.lastname}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <label>{t("sex")}</label>
                                        <Select noOptionsMessage={() => t("no_data_available")}
                                             isDisabled={true}
                                            options={gender_select_options}
                                            value={selectedGender}
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    minHeight: 38
                                                })
                                            }}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#6600CC',
                                                    primary25: '#6600CC25',
                                                    primary75: '#6600CC75',
                                                    primary50: '#6600CC50'
                                                },
                                            })}
                                            placeholder={t("sex")}
                                            onChange={e => handleSexe(e)}
                                        />
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <label>{t("ethni")}</label>
                                        <Select noOptionsMessage={() => t("no_data_available")}
                                             isDisabled={true}
                                            options={ethnie_select_options}
                                            value={ethnie}
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    minHeight: 38
                                                })
                                            }}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#6600CC',
                                                    primary25: '#6600CC25',
                                                    primary75: '#6600CC75',
                                                    primary50: '#6600CC50'
                                                },
                                            })}
                                            placeholder={t("ethni")}
                                            onChange={e => handleEthnie(e)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <label>{t("email")}</label>
                                        <Form.Control
                                             readOnly={true}
                                            placeholder={t("email")}
                                            onChange={handleEmail}
                                            type={"email"}
                                            defaultValue={current_row?.email}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <label>{t("tel")}</label>
                                        <Form.Control
                                             readOnly={true}
                                            placeholder={t("tel")}
                                            onChange={handleMobile}
                                            type={"tel"}
                                            defaultValue={current_row?.mobile}
                                        />
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <label>{t("branch_title_unique")}</label>
                                        <Select noOptionsMessage={() => t("no_data_available")}
                                             isDisabled={true}
                                            options={props.rwState.branches}
                                            getOptionLabel={o => o.name}
                                            getOptionValue={o => o._id}
                                            value={selectedBranch}
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    minHeight: 38
                                                })
                                            }}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#6600CC',
                                                    primary25: '#6600CC25',
                                                    primary75: '#6600CC75',
                                                    primary50: '#6600CC50'
                                                },
                                            })}
                                            placeholder={t("branch_title_unique")}
                                            onChange={e => handleBranch(e)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <label>{t("department_title_unique")}</label>
                                        <Select noOptionsMessage={() => t("no_data_available")}
                                             isDisabled={true}
                                            options={props.rwState.departments}
                                            getOptionLabel={o => o.name}
                                            getOptionValue={o => o._id}
                                            value={selectedDepartment}
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    minHeight: 38
                                                })
                                            }}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#6600CC',
                                                    primary25: '#6600CC25',
                                                    primary75: '#6600CC75',
                                                    primary50: '#6600CC50'
                                                },
                                            })}
                                            placeholder={t("department_title_unique")}
                                            onChange={e => handleDepartment(e)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <label>{t("position_title_unique")}</label>
                                        <Select noOptionsMessage={() => t("no_data_available")}
                                             isDisabled={true}
                                            options={props.rwState.positions}
                                            getOptionLabel={o => o.title}
                                            getOptionValue={o => o._id}
                                            value={selectedPosition}
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    minHeight: 38
                                                })
                                            }}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#6600CC',
                                                    primary25: '#6600CC25',
                                                    primary75: '#6600CC75',
                                                    primary50: '#6600CC50'
                                                },
                                            })}
                                            placeholder={t("position_title_unique")}
                                            onChange={e => handlePosition(e)}
                                        />
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <label>{t("role")}</label>
                                        <Select noOptionsMessage={() => t("no_data_available")}
                                             isDisabled={true}
                                            options={props.rwState.roles}
                                            getOptionLabel={o => o.name}
                                            getOptionValue={o => o._id}
                                            value={selectedRole}
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    minHeight: 38
                                                })
                                            }}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#6600CC',
                                                    primary25: '#6600CC25',
                                                    primary75: '#6600CC75',
                                                    primary50: '#6600CC50'
                                                },
                                            })}
                                            placeholder={t("role")}
                                            onChange={e => handleRole(e)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <label>{t("hierarchic_supe")}</label>
                                        <Select noOptionsMessage={() => t("no_data_available")}
                                             isDisabled={true}
                                            options={props.rwState.colleagues}
                                            getOptionLabel={o => o.firstname + ' ' + o.lastname}
                                            getOptionValue={o => o._id}
                                            value={selectedSuperieur}
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    minHeight: 38,
                                                })
                                            }}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#6600CC',
                                                    primary25: '#6600CC25',
                                                    primary75: '#6600CC75',
                                                    primary50: '#6600CC50'
                                                },
                                            })}
                                            placeholder={t("hierarchic_supe")}
                                            onChange={e => handleSup(e)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <label>{t("employee_status_text")}</label>
                                        <Select noOptionsMessage={() => t("no_data_available")}
                                             isDisabled={true}
                                            options={employee_statuts}
                                            value={selectedEmpStatut}
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    minHeight: 38
                                                })
                                            }}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#6600CC',
                                                    primary25: '#6600CC25',
                                                    primary75: '#6600CC75',
                                                    primary50: '#6600CC50'
                                                },
                                            })}
                                            placeholder={t("employee_status_text")}
                                            onChange={e => handleStatutEmp(e)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <label>{t("recruitment_date")}</label>
                                        <DayPickerInput
                                            inputProps={{className: 'form-control',readOnly:true}}
                                            format="DD/MM/YYYY"
                                            formatDate={formatDate}
                                            placeholder={current_row?.job_info[0]?.created_date ? formatDate(new Date(current_row?.job_info[0]?.created_date), 'DD/MM/YYYY', 'fr') : t("recruitment_date")+' ' + `${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                            onDayChange={handHiredDate}
                                            dayPickerProps={{selectedDays: new Date(current_row?.job_info[0]?.created_date)}}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <label>{t("first_day_of_work")}</label>
                                        <DayPickerInput
                                            inputProps={{className: 'form-control',readOnly:true}}
                                            format="DD/MM/YYYY"
                                            formatDate={formatDate}
                                            placeholder={current_row?.job_info[0]?.start_date ? formatDate(new Date(current_row?.job_info[0]?.start_date), 'DD/MM/YYYY', 'fr') : t("first_day_of_work")+' '  + `${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                            onDayChange={handStartDate}
                                            dayPickerProps={{selectedDays: new Date(current_row?.job_info[0]?.start_date)}}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <label>{t("end_of_contract_date")}</label>
                                        <DayPickerInput
                                            inputProps={{className: 'form-control',readOnly:true}}
                                            format="DD/MM/YYYY"
                                            formatDate={formatDate}
                                            placeholder={current_row?.job_info[0]?.end_date ? formatDate(new Date(current_row?.job_info[0]?.end_date), 'DD/MM/YYYY', 'fr') : t("end_of_contract_date")+' ' + `${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                            onDayChange={handleEndDate}
                                            dayPickerProps={{selectedDays: new Date(current_row?.job_info[0]?.end_date)}}
                                        />
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <label>{t("probation_start")}</label> <br/>
                                        <DayPickerInput
                                            inputProps={{className: 'form-control',readOnly:true}}
                                            format="DD/MM/YYYY"
                                            formatDate={formatDate}
                                            placeholder={current_row?.job_info[0]?.probation?.probation_start ? formatDate(new Date(current_row?.job_info[0]?.probation?.probation_start), 'DD/MM/YYYY', 'fr') : t("probation_start")+' ' + `${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                            onDayChange={handleProStartDate}
                                            dayPickerProps={{selectedDays: new Date(current_row?.job_info[0]?.probation?.probation_start)}}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <label>{t("probation_end")}</label> <br/>
                                        <DayPickerInput
                                            inputProps={{className: 'form-control',readOnly:true}}
                                            format="DD/MM/YYYY"
                                            formatDate={formatDate}
                                            placeholder={current_row?.job_info[0]?.probation?.probation_end ? formatDate(new Date(current_row?.job_info[0]?.probation?.probation_end), 'DD/MM/YYYY', 'fr') : t("probation_end")+' ' + `${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                            onDayChange={handleProbEndDate}
                                            dayPickerProps={{selectedDays: new Date(current_row?.job_info[0]?.probation?.probation_end)}}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <label>{t("type_salary")} </label>
                                        <Form.Control
                                             readOnly={true}
                                            placeholder={t("type_salary")}
                                            type={'number'}
                                            defaultValue={current_row?.job_info[0]?.type_salary}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <label>{t("mensual_salary")}</label>
                                        <Form.Control
                                             readOnly={true}
                                            placeholder={t("mensual_salary")}
                                            type={'number'}
                                            defaultValue={current_row?.job_info[0]?.salary}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Modal.Footer className={'border-0 pt-0'}>
                                <Button variant="light" onClick={handleClose}>
                                    {t("cancel")}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal.Body>

                </Modal>
                : ''
        }

        
      <Modal show={show==="importExcel"} onHide={() => setShow(null)} centered size={"md"}>
          <Modal.Body>
              <div className={"col-lg-12"}>
                <Form.Group>
                 <Col>
                 <h3 className={"text-left mt-4"}>{t("import_excel_file")}</h3>
                    <Form.File id="formcheck-api-custom" custom>
                        <Form.File.Input onChange={handleFile} accept={restrein} 
                        className={handleinputValidationError(formError,'excelFile')}
                        />
                        <Form.File.Label data-browse={t("import_file")}>
                            {fileName}
                        </Form.File.Label>
                        <Form.Control.Feedback type="valid">50MB</Form.Control.Feedback>
                    </Form.File>{handleTextValidationError(formError,'excelFile')}
                    <div className={"text-right mt-4"}>
                      <Button variant="warning"
                      disabled={
                        handleDisabledBtnValidationError(formError,
                        ['excelFile'])} onClick={()=> importExcel() }>
                          {t("import_now")}
                      </Button>
                  </div>
                </Col>
    </Form.Group>
              </div>
          </Modal.Body>
      </Modal>

        </div>;

};

export default connect(mapStateToProps, mapDispatchToProps)(ColleguesList);
