import React, {useState, useEffect, useCallback} from 'react'
import {useParams} from 'react-router-dom'
import './JoinCompany.css'
import ContextMenu from "../menus/ContextMenu"
import {Button, Col, Container, Form, FormGroup, Row} from "react-bootstrap"
import {verifyColleagueLink, activeColleagueAccount} from '../../service'
import { v1 as uuidv1 } from 'uuid'
import { useSocket } from '../Custom-Hooks'
import { useTranslation } from 'react-i18next';

const JoinCompany = () => {
    const { t } = useTranslation();
    const { key } = useParams()
    const contextMenu = [{label: t('join_company'), href: `/join-company/${key}`}]
    const [loading, setLoading] = useState(false)
    const [SIO_CHANNEL_VERIFY_COLLEAGUE_LINK_ID] = useState('verify_colleague_link_' + uuidv1())
    const [SIO_CHANNEL_ACTIVE_COLLEAGUE_ACCOUNT] = useState('active_colleague_account_' + uuidv1())
    const socketIoInstance = useSocket()
    const [colleague, setColleague] = useState()
    const [form_data, setFormData] = useState({})
    const [err_message, setErrorMessage] = useState()
    const [success, setSuccess] = useState(false)
    const [activateError, setActivateError] = useState(false)

    const listenVerifyColleagueLinkResponse = useCallback(() => {
        socketIoInstance?.on(SIO_CHANNEL_VERIFY_COLLEAGUE_LINK_ID, response => {
            // console.log('Verify link socket response', response)
            if(response.status === 200) {
                setColleague(response.data)
            }
            socketIoInstance.off(SIO_CHANNEL_VERIFY_COLLEAGUE_LINK_ID)
        })
    }, [socketIoInstance])
    
    const listenActiveColleagueAccountResponse = useCallback(() => {
        socketIoInstance?.on(SIO_CHANNEL_ACTIVE_COLLEAGUE_ACCOUNT, response => {
            if(response.status === 200){
                setLoading(false)
                setSuccess(true)
            }
            socketIoInstance.off(SIO_CHANNEL_ACTIVE_COLLEAGUE_ACCOUNT)
        })
    }, [socketIoInstance])

    /*useEffect(() => {
        if(key){
            listenVerifyColleagueLinkResponse()
            verifyColleagueLink(SIO_CHANNEL_VERIFY_COLLEAGUE_LINK_ID, key)
            .then(response => console.log('Verify Colleague Link HTTP response => ', response))
            .catch(err => console.log('Verify Colleague Link HTTP err => ', err))
        }
    }, [key])*/

    const inputHandler = evt => {
        const name = evt.target.name
        const value = evt.target.value
        setFormData({...form_data, [name]: value})
    }

    const activateAccount = () => {
        setLoading(true)
        const password = form_data.password
        const confirm_password = form_data.confirm_password
        if(password !== confirm_password){
            setErrorMessage(t('password_not_same'))
            setLoading(false)
            return
        }
        listenActiveColleagueAccountResponse()
        activeColleagueAccount(SIO_CHANNEL_ACTIVE_COLLEAGUE_ACCOUNT, key, password)
        .then(response => {
            setLoading(false)
            if (response.status===200){
            setSuccess(true)
        }else{
                setActivateError(true)
            }
        })
        .catch(err => console.log('Active Colleague Account HTTP err => ', err))
    }

    return (
        <div id={'JoinCompany'}>
            <Container>
                <Row>
                    <Col>
                        <ContextMenu menu={contextMenu}/>
                    </Col>
                </Row>
                <Row hidden={success}>
                    <Col md={6} >
                        <div className={'mt-1 mb-3'}>
                            <h4 className={' mb-3'}>{t('fill_for_account_activation_text')}</h4>
                            <FormGroup>
                            <Form.Label>{t('label_password')}</Form.Label>
                                <Form.Control name='password' onChange={inputHandler}  type={'password'} placeholder={t('password')} />
                            </FormGroup>
                            <FormGroup>
                            <Form.Label>{t('confirm_new_password_text')}</Form.Label>
                                <Form.Control name='confirm_password' onChange={inputHandler} type={'password'} placeholder={t('confirm_new_password_text')} />
                            </FormGroup>
                            <p className={'text-danger'} hidden={!activateError}><strong>{t('redo_full_text')}</strong></p>
                            <Button disabled={loading} block onClick={activateAccount} >{loading ? t('saving') : t('save')}</Button>
                        </div>
                    </Col>
                </Row>
                <Row hidden={!success}>
                    <Col md={6}>
                        <div className={'mt-3'}>
                            <div className={'mt-2 mb-3'}>
                                <h2>{t('activate_account')} !</h2>
                                <p>{t('thanks')}</p>
                            </div>
                            <Button href={'/auth/signin'}>{t('login_btn')}</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default JoinCompany