import React, {useEffect, useState} from 'react';
import "./Surveys.css"
import {Tab, Nav, Form, Modal, Button, Col, Container, Row, Card, Spinner, Badge} from "react-bootstrap";
import ContextMenu from "../../menus/ContextMenu";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import Select from "react-select";
import {formatDate, parseDate} from "react-day-picker/moment";
import SingleSurvey from "../../widgets/SingleSurvey/SingleSurvey";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
import {isAdmin,
    handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const Surveys = (props) => {
    const { t } = useTranslation();
    useEffect(() => {
        props.action_loading(true);
        props.action_listSurveys();
        props.action_listColleagues();
        props.action_listDepartments();
        props.action_listBranches();
        props.action_listPositions();
        props.action_listRoles();
        props.action_setTitle(t("surveys"))
    }, []);

    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    const [show, setShow] = useState([])
    const [duration, setDuration] = useState()
    const [title, setTitle] = useState()
    const [status, setStatus] = useState(0)
    const [description, setDescription] = useState()
    const [current_row, setCurrentRow] = useState()
    const [isUpdate, setHasUpdate] = useState(false)
    const surveys_types = [{ label: t("qcm_title"), value: "qcm" }, { label: t("notes"), value: 'note' },]
    const [type, setType] = useState(surveys_types[0])
    const [validated, setValidated] = useState(false);
    const [to, setTo] = useState()
    const [from, setFrom] = useState()
    const [modifiers,setModifiers] = useState({start: from, end: to});
    const [formError, setFormError] = useState({})

    const handleClose = () => {
        if (isUpdate){
            setHasUpdate(false);
            setCurrentRow(null);
        }
        setShow(null)
        setFormError({})
    };

    const handleDuration = (e) => {
        setDuration(e.target.value)
        let data =handleValidation(t,{type:"number",name:'duration','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };

    const handleTitle = (e) => {
        setTitle(e.target.value)
        let data =handleValidation(t,{type:"text",name:'title','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };

    const handleStatus = (e) => {
        //setStatus(e.target.value)
    };

    const handleTypeSelect = (e) => {
        console.log('type ==> ',e)
        //setStatus(e.target.value)
    };

    const handleDescription = (e) => {
        setDescription(e.target.value)
    };

    const handleFromChange = (from) => {
        setFrom(from)
        let data =handleValidation(t,{type:"date",name:'fromChange','value':from,required:true,'min':new Date()});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }

    const handleToChange = (to) => {
        setTo(to)
        let data =handleValidation(t,{type:"date",name:'toChange','value':to,required:true,'min':new Date(from)});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }

    const editSurvey = (survey)=>{
        console.log(survey,"edit")
        setCurrentRow(survey);
        setDuration(survey.duration);
        setDescription(survey.description);
        setTitle(survey.title);
        setTo(new Date(survey.end_date), 'DD/MM/YYYY');
        setFrom(new Date(survey.start_date), 'DD/MM/YYYY');
        setType(surveys_types.find(d=>d.value===survey.type));
        setHasUpdate(true);
        setShow('create')
        let data=  handleFinaleValidation(t,[
            {type:"number",name:'duration','value':survey.duration,required:true},
            {type:"text",name:'title','value':survey.title,required:true},
            {type:"date",name:'fromChange','value':survey.start_date,required:true},
            {type:"date",name:'toChange','value':survey.end_date,required:true}
           ])
                setFormError(data)
    };

    const resetSurvey = () => {
setDuration('');
setDescription('');
setTitle('');
setStatus(0);
setFrom('');
setTo('');
setFormError({})
setType(surveys_types[0]);
    }
    const createSurvey = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //     event.stopPropagation();
        //     return;
        // }
        let o = {
            "duration": duration,
            "description": description,
            "title": title,
            "type": type.value,
            "status": status,
            "sio_channel": "",
            "category":"standard",
            "start_date":from,
            "end_date":to
        };
        if (!isUpdate)
            props.action_createSurvey(o)
        else{
            o.id = current_row._id;
            o.category = current_row.category;
            props.action_updateSurvey(o);
            setHasUpdate(false);
            setCurrentRow(null);
        }
        resetSurvey()
        // setValidated(true);
        setShow(null);
        props.action_loading(true)
    };
 const closeSurvey = (data) => {
        let o = {
            "sio_channel": "",
            "is_passed":true
        };
       
            o.id = data._id;
            props.action_updateSurvey(o);
            setHasUpdate(false);
            setCurrentRow(null);
        resetSurvey()
        props.action_loading(true)
    };

    const deleteSurvey = () => {
        props.action_deleteSurvey(current_row._id,"standard");
        setShow(null);
        setCurrentRow(null);
        props.action_loading(true)
    };

    const contextMenu =
        !isAdmin(role)
            ?
            [
                {label: t("my_surveys"), eventKey: 'pending'},
                {label: t("male_closed"), eventKey: 'passed'},
            ]
            :
            [
                {label: t("on_waiting"), eventKey: 'pending'},
                {label: t("suspend"), eventKey: 'in_progress'},
                {label: t("male_closed"), eventKey: 'passed'},
            ];

  //****************************************** paginate filter data */
  useEffect(() => {
      setPassedSurveyList([]);
      setPendingSurveyList([]);
      setInprogressSurveyList([]);
    if(props.rwState.surveys && props.rwState.surveys.length > 0 && props.rwState.surveys.filter(r=>r.status === 0 && moment(r.end_date).isAfter() && !r.is_passed).length > 0) {
        let mylist = props.rwState.surveys.filter(r=>r.status===0 && moment(r.end_date).isAfter()  && !r.is_passed);
        setPendingSurveyList(mylist)
    }
   if(props.rwState.surveys && props.rwState.surveys.length > 0 && props.rwState.surveys.filter(r=>r.status===1 && moment(r.end_date).isAfter() && !r.is_passed).length > 0) {
         let mylists = props.rwState.surveys.filter(r=>r.status===1 && moment(r.end_date).isAfter()  && !r.is_passed);
         setInprogressSurveyList(mylists)
    }
   if(props.rwState.surveys && props.rwState.surveys.length > 0 && props.rwState.surveys.filter(r=> r.is_passed || moment(r.end_date).isBefore()).length > 0) {
        let mylistis = props.rwState.surveys.filter(r=> r.is_passed || moment(r.end_date).isBefore());
        setPassedSurveyList(mylistis)
    }
   }, [props.rwState.surveys]);

/********************************************* pending Begin paginating */
    const [pendingSurveyList, setPendingSurveyList] = useState(undefined);
      const [CurrentPendingSurveyLists, setCurrentPendingSurveyLists] = useState(undefined);
      const [pendingSurveyPageCount, setPendingSurveyPageCount] = useState(0);
      const [pendingSurveyItemOffset, setPendingSurveyItemOffset] = useState(0);
      const [pendingSurveyItemsPerPage, setPendingSurveyItemsPerPage] = useState(8);

      useEffect(() => {
        if(pendingSurveyList) {
            const endOffset = pendingSurveyItemOffset + pendingSurveyItemsPerPage;
            setCurrentPendingSurveyLists(pendingSurveyList.slice(pendingSurveyItemOffset, endOffset));
            setPendingSurveyPageCount(Math.ceil(pendingSurveyList.length / pendingSurveyItemsPerPage));
        }
       }, [pendingSurveyItemOffset, pendingSurveyItemsPerPage, pendingSurveyList]);
   
       const handlePendingSurveyPageClick = (event) => {
        const newOffset = (event.selected * pendingSurveyItemsPerPage) % pendingSurveyList.length;
        setPendingSurveyItemOffset(newOffset);
    };
    //********************************************* pending survey Active End paginating */

    /********************************************* in_progress Begin paginating */
    const [in_progressSurveyList, setInprogressSurveyList] = useState(undefined);
      const [CurrentInprogressSurveyLists, setCurrentInprogressSurveyLists] = useState(undefined);
      const [in_progressSurveyPageCount, setInprogressSurveyPageCount] = useState(0);
      const [in_progressSurveyItemOffset, setInprogressSurveyItemOffset] = useState(0);
      const [in_progressSurveyItemsPerPage, setInprogressSurveyItemsPerPage] = useState(8);

      useEffect(() => {
        if(in_progressSurveyList) {
            const endOffset = in_progressSurveyItemOffset + in_progressSurveyItemsPerPage;
            setCurrentInprogressSurveyLists(in_progressSurveyList.slice(in_progressSurveyItemOffset, endOffset));
            setInprogressSurveyPageCount(Math.ceil(in_progressSurveyList.length / in_progressSurveyItemsPerPage));
        }
       }, [in_progressSurveyItemOffset, in_progressSurveyItemsPerPage, in_progressSurveyList]);
   
       const handleInprogressSurveyPageClick = (event) => {
        const newOffset = (event.selected * in_progressSurveyItemsPerPage) % in_progressSurveyList.length;
        setInprogressSurveyItemOffset(newOffset);
    };
    //********************************************* in progress survey Active End paginating */

     /********************************************* passed Begin paginating */
     const [passedSurveyList, setPassedSurveyList] = useState(undefined);
     const [CurrentPassedSurveyLists, setCurrentPassedSurveyLists] = useState(undefined);
     const [passedSurveyPageCount, setPassedSurveyPageCount] = useState(0);
     const [passedSurveyItemOffset, setPassedSurveyItemOffset] = useState(0);
     const [passedSurveyItemsPerPage, setPassedSurveyItemsPerPage] = useState(8);

     useEffect(() => {
       if(passedSurveyList) {
           const endOffset = passedSurveyItemOffset + passedSurveyItemsPerPage;
           setCurrentPassedSurveyLists(passedSurveyList.slice(passedSurveyItemOffset, endOffset));
           setPassedSurveyPageCount(Math.ceil(passedSurveyList.length / passedSurveyItemsPerPage));
       }
      }, [passedSurveyItemOffset, passedSurveyItemsPerPage, passedSurveyList]);
  
      const handlePassedSurveyPageClick = (event) => {
       const newOffset = (event.selected * passedSurveyItemsPerPage) % passedSurveyList.length;
       setPassedSurveyItemOffset(newOffset);
   };
   //********************************************* passed survey End paginating */

    return <div id={"Surveys"}>
        <Container fluid>
            <Row>
                <Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={'pending'}>
                        <div className="d-flex align-items-start justify-content-between">
                            <ContextMenu menu={contextMenu}/>
                            <Button hidden={!isAdmin(role)} onClick={()=>{setShow('create')}}>{t("create_survey_btn")}</Button>
                        </div>

                        <Tab.Content>
                            <Tab.Pane className={"lastRollL"} eventKey="pending">
                                <div className={"p-3 border-0"} style={{borderRadius:10}}>
                                    <Row>
                                        {  CurrentPendingSurveyLists && CurrentPendingSurveyLists.length > 0 ?
                                            CurrentPendingSurveyLists.map((p => {
                                                return <Col  md={6}>
                                                    <SingleSurvey
                                                        editSurvey={()=>{editSurvey(p)}}
                                                        closeSurvey={()=>closeSurvey(p)}
                                                        deleteSurvey={()=>{setCurrentRow(p);setShow('confirm')}}
                                                        branches={props.rwState.branches}
                                                        departments={props.rwState.departments}
                                                        positions={props.rwState.positions}
                                                        employees={props.rwState.colleagues.filter(r=>r.activated)}
                                                        survey={p}/>
                                                </Col>
                                            }))
                                            :
                                            <h4>{t("no_survey_available")}</h4>
                                        }
                                         <Col md={12} className={"mt-4"}>
                                        <div hidden={!CurrentPendingSurveyLists || (CurrentPendingSurveyLists && CurrentPendingSurveyLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handlePendingSurveyPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pendingSurveyPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div></Col>
                                    </Row>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className={"lastRollL"} eventKey="in_progress">
                                <div className={"p-3 border-0"} style={{borderRadius:10}}>
                                    <Row>
                                        { CurrentInprogressSurveyLists && CurrentInprogressSurveyLists.length>0  ?
                                           CurrentInprogressSurveyLists.map((p => {
                                                return <Col md={6}>
                                                    <SingleSurvey
                                                        editSurvey={()=>{editSurvey(p)}}
                                                        closeSurvey={()=>closeSurvey(p)}
                                                        deleteSurvey={()=>{setCurrentRow(p);setShow('confirm')}}
                                                        branches={props.rwState.branches}
                                                        departments={props.rwState.departments}
                                                        positions={props.rwState.positions}
                                                        employees={props.rwState.colleagues.filter(r=>r.activated)}
                                                        survey={p}/>
                                                </Col>
                                            }))
                                            :
                                            <h4>{t("no_survey_available")}</h4>
                                        }
                                         <Col md={12} className={"mt-4"}>
                                        <div hidden={!CurrentInprogressSurveyLists || (CurrentInprogressSurveyLists && CurrentInprogressSurveyLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleInprogressSurveyPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={in_progressSurveyPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div>
                                             </Col>
                                    </Row>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className={"lastRollL"} eventKey="passed">
                                <div className={"p-3 border-0"} style={{borderRadius:10}}>
                                    <Row>
                                        {  CurrentPassedSurveyLists  && CurrentPassedSurveyLists.length > 0 ?
                                            CurrentPassedSurveyLists.map((p => {
                                                return <Col md={6}>
                                                    <SingleSurvey
                                                        editSurvey={()=>{editSurvey(p)}}
                                                        closeSurvey={()=>closeSurvey(p)}
                                                        deleteSurvey={()=>{setCurrentRow(p);setShow('confirm')}}
                                                        branches={props.rwState.branches}
                                                        departments={props.rwState.departments}
                                                        positions={props.rwState.positions}
                                                        employees={props.rwState.colleagues.filter(r=>r.activated)}
                                                        survey={p}/>
                                                </Col>
                                            }))
                                            :
                                            <h4>{t("no_survey_available")}</h4>
                                        }
                                         <Col md={12} className={"mt-4"}>
                                        <div hidden={!CurrentPassedSurveyLists || (CurrentPassedSurveyLists && CurrentPassedSurveyLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handlePassedSurveyPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={passedSurveyPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div>
                                             </Col>
                                    </Row>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Col>
            </Row>

        </Container>

        {/*  modals */}
        <Modal centered show={show === 'create'} onHide={handleClose}>
            <Modal.Header className={"bg-primary"}>
                <h4 className={"col-lg-12 text-white text-center"}>{t("new_survey")}</h4>
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <Form noValidate validated={validated}  onSubmit={createSurvey}>
                    <Form.Group>
                            <Form.Label> {t("survey_title")} <span className={'required_box'}>*</span></Form.Label>
                            <Form.Control
                                required
                                onChange={handleTitle}
                                defaultValue={title}
                                type={"text"}
                                className={handleinputValidationError(formError,'title')}
                                />
                                       {handleTextValidationError(formError,'title')}
                     
                    </Form.Group>
                          <Form.Group>
                              <Row>
                              <Col>
                                  <Form.Label>{t("start_date")} <span className={'required_box'}>*</span></Form.Label>
                                  <DayPickerInput
                                      required
                                      inputProps={{className: 'form-control'}}
                                      format="DD/MM/YYYY"
                                      formatDate={formatDate}
                                      placeholder={`${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                      value={from}
                                      parseDate={parseDate}
                                      dayPickerProps={{
                                          selectedDays: [from, {from, to}],
                                          disabledDays: {after: to},
                                          toMonth: to,

                                      }}
                                      onDayChange={handleFromChange}
                                      className={handleinputValidationError(formError,'fromChange')}
                                      />
                                             {handleTextValidationError(formError,'fromChange')}
                           
                              </Col>
                              <Col>
                                  <Form.Label> {t("end_date")} <span className={'required_box'}>*</span></Form.Label>
                                  <DayPickerInput
                                      disabled={!type}
                                      required
                                      inputProps={{className: 'form-control'}}
                                      format="DD/MM/YYYY"
                                      formatDate={formatDate}
                                      placeholder={`${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                      value={to}
                                      parseDate={parseDate}
                                      dayPickerProps={{
                                          selectedDays: [from, {from, to}],
                                          disabledDays: [{before: from}],
                                          modifiers,
                                      }}
                                      onDayChange={handleToChange}
                                      className={handleinputValidationError(formError,'toChange')}
                                      />
                                             {handleTextValidationError(formError,'toChange')}
                           
                              </Col>
                              </Row>

                            </Form.Group>

                    <Form.Group>
                            <Form.Label> {t("survey_duration")} <span className={'required_box'}>*</span></Form.Label>
                            <Form.Control
                                onChange={handleDuration}
                                defaultValue={duration}
                                type={"number"}
                                className={handleinputValidationError(formError,'duration')}
                                />
                                       {handleTextValidationError(formError,'duration')}
                     
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t("survey_description")} </Form.Label>
                        <Form.Control
                            onChange={handleDescription}
                            defaultValue={description}
                            as={"textarea"}/>
                </Form.Group>
                    <Modal.Footer className={'border-0 pt-0'}>
                        <Button variant="light" onClick={handleClose}>
                            {t("cancel")}
                        </Button>
                        <Button variant="primary"
                         disabled={
                            handleDisabledBtnValidationError(formError,
                                ['title','duration','fromChange','toChange'])
                        }
                        type={"submit"}>
                            {t("save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>

        </Modal>
        <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("confirmation")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={"text-center"}>
                    {t("confirme_irreversile_deletion_text")}
                </div>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>
                        <Button variant="primary" onClick={deleteSurvey}>
                            {t("yes_delete")}
                        </Button>
                        <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                            {t("cancel")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Surveys);
