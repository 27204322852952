import React, {useEffect, useState} from 'react';
import './VisioRoom.css'
import { Button, Col, Container, Form, Modal, Row,Tab} from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import {formatDate, parseDate} from 'react-day-picker/moment';
import {connect} from "react-redux";
import * as actions from "../../../actions";
import Emitter from "../../../service/events";
import ContextMenu from "../../menus/ContextMenu/ContextMenu";
import * as RI from "react-icons/ri";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import slugify from "slugify";
import VisioConf from "../../widgets/VisioConf";
import {generateVisioUrl} from "../../../helpers/helper";
import {useParams} from "react-router-dom";

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const VisioRoom = (props) => {
    const { id } = useParams();

    useEffect(()=>{
        props.action_loading(true);
        props.action_oneVisioConf(id);
        props.action_setTitle("Vidéo conférence")
        console.log("====",props.rwState.visio_conf)

        if ( props.rwState.visio_conf){
            console.log("====",props.rwState.visio_conf)
            alert(props.rwState.visio_conf?.title)
            const domain = "meet.jit.si"//'meet.glow-hr.com';
            const options = {
                roomName: props.rwState.visio_conf.title,
                //width: 700,
                //height: 700,
                parentNode: document.querySelector('#Visio')
            };
            console.log("conf",props.rwState.visio_conf)
            new JitsiMeetExternalAPI(domain, options);
        }

    },[]);

    return <div id={'Visio'}>
        <Container fluid>

        </Container>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(VisioRoom);
