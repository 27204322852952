import React from 'react';

const DottedButton = (props) => {

  return <div onClick={props.click} className={'DottedButton d-flex align-items-center justify-content-around'} id={'addCollab'}>
    <div className={'p-2'}>
      <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-square-fill" fill="currentColor"
           xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd"
              d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
      </svg>
    </div>
    {props.text}
  </div>;
};

export default DottedButton;
