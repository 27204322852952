import React, {useEffect, useState} from 'react';
import "./PerformanceDetails.css"
import {useParams,useHistory} from 'react-router-dom'
import { Form, Modal, Button, Col, Container, Row, Card, Spinner, Badge} from "react-bootstrap";
import mSVG from "../../../assets/gender/m.png";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import * as RI from "react-icons/ri"
import * as BI from "react-icons/bi"
import Select from "react-select";
import DataTable from "react-data-table-component";
import { useTranslation } from 'react-i18next';


const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}


const PerformanceDetails = (props) => {
    const { t } = useTranslation();
    const [show, setShow] = useState([])
    const [note, setNote] = useState()
    const [comment, setComment] = useState()
    const [employees, setEmployees] = useState()
    const [employee_id, setEmployee_id] = useState()
    const notes = [t("reach"),t("partially_reach"),t("not_reach")];
    const history = useHistory();
    const {id} = useParams();

    const handleClose = () => {
        setShow(null)
    }

    const handleSelectEmployee = e => setEmployees(e);
    const handleComment = e => setComment(e.target.value);
    const handleNote = (e,employee_id) => {setNote(e.target.value);setShow('note_modal');setEmployee_id(employee_id)};
    const deleteSurvey = () => {
        props.action_deleteExpense();
        setShow(null);
        props.action_loading(true)
    };
    const assignGoal = () => {
        let arr = [];
        employees.forEach((e)=>arr.push(e._id))
        let o = {
            employees:arr,
            id:props.rwState.goal._id
        };
        props.action_assignGoal(o);
        setShow(null);
        props.action_loading(true)
    };
    const removeEmployee = () => {
        let o = {
            employee:employee_id,
            goal_id:props.rwState.goal._id
        }
        props.action_removeGoalEmployee(o);
        setShow(null);
        props.action_loading(true)
    };
    const noteEmployee = () => {
        let o = {
            employee:employee_id,
            goal_id:props.rwState.goal._id,
            note:note,
            comment:comment
        }
        props.action_appreciateGoal(o);
        setShow(null);
        props.action_loading(true)
    };
    const confirmRemove = (employee_id)=>{
        setEmployee_id(employee_id);
        setShow('confirm_remove');
    }

    const dColumns = [
        {
            name: t("name_and_profession"),
            selector: 'firstname',
            cell: row => {
                return <div className={'d-flex align-items-center pt-3 mb-3'}>
                    <div className={'mr-3'}>
                        <img src={mSVG} style={{width: 38, height: 38}}/>
                    </div>
                    <div>
                        <div>{row.firstname + ' ' + row.lastname}</div>
                    </div>
                </div>
            }
        },
        {
            name: t("start_date"),
            selector: 'employee_info',
            cell: row => <strong>{new Date(props.rwState?.goal?.start_date).toLocaleDateString()}</strong>
        },
        {
            name: t("end_date"),
            selector: 'employee_info',
            cell: row => <strong>{new Date(props.rwState?.goal?.end_date).toLocaleDateString()}</strong>
        },
        {
            name: t("appreciation"),
            selector: 'hired_on',
            cell: row => <Form.Control as="select" style={{width:"50%"}} onChange={(e)=>{handleNote(e,row._id)}}>
                {
                    notes.map((value)=><option value={value} selected={getEmployeeNote(row._id)===value}>{value}</option>)
                }

            </Form.Control>

            },
        {
            name:'',
            cell: row => <Button onClick={()=>confirmRemove(row._id)}  variant={"link"} color={"danger"} size={"sm"}><BI.BiTrash size={22}/></Button>,
            grow:0
        }
    ]

    const getEmployeeNote = (employee_id)=>{
        return props.rwState.goal.appreciations.find((ap)=>ap.employee === employee_id)
        ?props.rwState.goal.appreciations.find((ap)=>ap.employee === employee_id).note:t("not_reach");
    };
    useEffect(() => {
        props.action_loading(true)
        props.action_getOneGoals(id);
        props.action_listColleagues();
        props.action_setTitle(t("performance_details"))

    }, []);

    return <div id={"Surveys"}>
        <Container fluid>

            <Row>
                <Col md={"auto"} className={'text-left'}>
                    <Button className={"btn btn-light btn-sm"} onClick={e => history.goBack()}>
                        <BI.BiChevronLeft style={{color: '#240265', fontWeight: '600',}} size={25}/> {t("return")}
                    </Button>
                </Col>
            </Row>
            <div>
                <h4  className={"ml-4"}>{props.rwState?.goal?.title}</h4>
            </div>
                <Card className={"p-4 border-0"} style={{borderRadius:10}}>
                    <div className={"d-flex align-items-center justify-content-between"}>
                        <div className={"tableLabel"}>{t("list_of_assigned_employee_text")}</div>
                        <div>
                            <Col md={"auto"} className={"text-right"}>
                            <Button onClick={e=>setShow('assign')} className={"pull-right"}>{t("assigned_action")}</Button>
                        </Col>
                        </div>
                    </div>
                    <div>
                        <DataTable
                            columns={dColumns}
                            data={props.rwState?.goal?.employees}
                            highlightOnHover
                            pointerOnHover
                        />
                    </div>
                </Card>

        </Container>

        {/*  modals */}
        <Modal centered show={show === 'assign'} onHide={handleClose}>
            <Modal.Header className={"bg-primary"}>
                <h4 className={"col-lg-12 text-white text-center"}>{t("assignation")}</h4>
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                {/*<Col>
                    <FormControl component="fieldset" className={"text-left ml-4"}>
                        <RadioGroup>
                            <Row>
                                <Col>
                                    <FormControlLabel onChange={e=>setTarget('employee')} value="employee" checked={target==='employee'} control={<Radio color={"primary"} />} label={"À un employé"} />
                                </Col>
                                <Col>
                                    <FormControlLabel  onChange={e=>setTarget('group')} value="group" checked={target!=='employee'} control={<Radio color={"primary"} />} label={"À un groupe d'employé"} />
                                </Col>
                            </Row>
                        </RadioGroup>
                    </FormControl>
                </Col>*/}
                <Col>
                    <Form.Group>
                        <Select
                            options={props.rwState?.colleagues.filter((c)=>props.rwState?.goal?.employees?.find((e)=>e._id===c._id)==null)}
                            getOptionLabel={o => o.firstname+ " "+o.lastname}
                            getOptionValue={o => o._id}
                            styles={{
                                control: base => ({
                                    ...base,
                                    minHeight: 38
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            isMulti
                            placeholder={t("employee_text")}
                            onChange={e => handleSelectEmployee(e)}
                        />
                    </Form.Group>
                </Col>
               {/* <Col hidden={target==='employee'}>
                    <Form.Group>
                    <Select
                        options={props.rwState.positions}
                        getOptionLabel={o => o.title}
                        getOptionValue={o => o.id}
                        value={selectedPosition}
                        styles={{
                            control: base => ({
                                ...base,
                                minHeight: 38
                            })
                        }}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: '#6600CC',
                                primary25: '#6600CC25',
                                primary75: '#6600CC75',
                                primary50: '#6600CC50'
                            },
                        })}
                        placeholder={t("position_title_unique")}
                        onChange={e => handlePosition(e)}
                    />
                </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Date de début</Form.Label>
                                <DayPickerInput
                                    inputProps={{className: 'form-control form-control-lg'}}
                                    format="DD/MM/YYYY"
                                    formatDate={formatDate}
                                    placeholder={`${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                    value={beginDate}
                                    parseDate={parseDate}
                                    dayPickerProps={{
                                        selectedDays: [beginDate, {beginDate, endDate}],
                                        disabledDays: {after: endDate},
                                        toMonth: endDate,
                                        modifiers,
                                    }}
                                    onDayChange={handleBeginDate}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Date de fin</Form.Label>
                                <DayPickerInput
                                    inputProps={{className: 'form-control form-control-lg'}}
                                    format="DD/MM/YYYY"
                                    formatDate={formatDate}
                                    placeholder={`${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                    value={endDate}
                                    parseDate={parseDate}
                                    dayPickerProps={{
                                        selectedDays: [beginDate, {beginDate, endDate}],
                                        disabledDays: {before: beginDate},
                                        modifiers,

                                    }}
                                    onDayChange={handleEndDate}
                                />
                            </Form.Group>
                        </Col>

                    </Row>
                    </Form.Group>
                </Col>*/}
            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("cancel")}
                </Button>
                <Button variant="primary" onClick={assignGoal}>
                    {t("assigned_action")}
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={show === "confirm"} onHide={() => setShow(null)} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("confirmation")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={"text-center"}>
                    {t("confirme_irreversile_deletion_text")}
                </div>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>
                        <Button variant="primary" onClick={deleteSurvey}>
                            {t("yes_delete")}
                        </Button>
                        <Button className={"ml-3"} variant="light" onClick={() => setShow(null)}>
                            {t("cancel")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
        <Modal show={show === "confirm_remove"} onHide={() => setShow(null)} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("confirmation")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={"text-center"}>
                    {t("note_remove_employee_from_Perfome")}
                </div>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>
                        <Button variant="primary" onClick={removeEmployee}>
                            {t("remove")}
                        </Button>
                        <Button className={"ml-3"} variant="light" onClick={() => setShow(null)}>
                            {t("cancel")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
        <Modal show={show === "note_modal"} onHide={() => setShow(null)} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("give_a_grade")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <Form.Group>
                    <Col>
                        <Form.Label>{t("comment")}</Form.Label>
                        <Form.Control
                            onChange={handleComment}
                            defaultValue={comment}
                            as={"textarea"}/>
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>
                        <Button variant="primary" onClick={noteEmployee}>
                            {t("note_text")}
                        </Button>
                        <Button className={"ml-3"} variant="light" onClick={() => setShow(null)}>
                            {t("cancel")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceDetails);
