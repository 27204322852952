import React, {useEffect, useState} from 'react';
import './Branches.css'
import {Tab, Form, Modal, Button, Col, Container, Row, Spinner} from "react-bootstrap";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import Select from "react-select";
import countries from "../../../assets/countries";
import ContextMenu from "../../menus/ContextMenu";
import DataTable from 'react-data-table-component';
import { handleValidation, handleFinaleValidation} from "../../../helpers/helper";
import { useTranslation } from 'react-i18next';
import {BsPlus} from "react-icons/bs";
import { BiPencil, BiTrash } from 'react-icons/bi';

const mapStateToProps = (state) => {
    return {rwState: state}
};
const mapDispatchToProps = {...actions};

const Branches = (props) => {
    const { t } = useTranslation();
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    useEffect(() => {
        props.action_listDepartments();
        props.action_listBranches();
        props.action_listPositions();
        props.action_setTitle("Compagnie")
    }, []);
    const [show, setShow] = useState([]);
    const [branches, setBranches] = useState([]);
    const [departments, setDepartements] = useState([]);
    const [branchName, setBranchName] = useState();
    const [departmentName, setDepartmentName] = useState();
    const [city, setCity] = useState();
    const [country, setCountry] = useState();
    const [posTitle, setPosTitle] = useState();
    const [postDesc, setPosDesc] = useState();
    const [posQualifs, setPosQualifs] = useState();
    const [current_row,setCurrentRow] = useState();
    const [formError, setFormError] = useState({})

    const handleClose = () => {
        setShow(null)
        setCurrentRow()
        setFormError({})
    };
    const handleCountry = (e) => {
        setCountry(e.alpha2Code)
        let data =handleValidation(t,{type:"text",name:'country','value':e.alpha2Code,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handleCity = (e) => {
        setCity(e.name)
        let data =handleValidation(t,{type:"text",name:'city','value':e.name,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handleBranchName = (e) => {
        setBranchName(e.target.value)
        let data =handleValidation(t,{type:"text",name:'branchName','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handleDepartmentName = (e) => {
        setDepartmentName(e.target.value)
        let data =handleValidation(t,{type:"text",name:'departmentName','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handlePosTitle = (e) => {
        setPosTitle(e.target.value)
        let data =handleValidation(t,{type:"text",name:'postTitle','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handlePosDesc = (e) => {
        setPosDesc(e.target.value)
    };
    const handlePosQualifs = (e) => {
        setPosQualifs(e.target.value)
    };

    useEffect(() => {
        if (country) props.action_getCities(country)
    }, [country]);

    const requestDataBuilderBranch = () => (
        {
            "name": branchName,
            "city": city,
            "country": country,
            "settings": {},
            "sio_channel": ""
        }
    );
    const requestDataBuilderDepartement = () => (
        {
            "name": departmentName,
            "settings": {}
        }
    );
    const requestDataBuilderPost = () => (
        {
            "title": posTitle,
            "description": postDesc,
            "qualifications": posQualifs,
        }
    );

    //Branch methode
    const createBranch = () => {
        let o = requestDataBuilderBranch();
        props.action_createBranch(o);
        setShow(null);
        props.action_loading(true);
        setFormError({})
    };
    const editBranch = b => {
        let branch = requestDataBuilderBranch();
        branch['id'] = current_row._id;
        branch['name'] =branchName;
        branch['city'] =city;
        branch['country'] =country;
        console.log('data', branch);
        props.action_updateBranch(branch);
        setShow(null);
        props.action_loading(true);
        setCurrentRow()
        setFormError({})
    };
    const updateBranch = async b => {
        console.log('row => ', b);
        setCurrentRow(b);
        setBranchName(b.name);
        setCountry(countries.find(c=>c.alpha2Code === b.country));
       await props.action_getCities(country?.alpha2Code);
        setCity(props.rwState.cities && b.city ? props.rwState.cities.find(c=>c.name.toLowerCase() === b.city.toLowerCase()) : '');
        setShow('branch')
      let data=  handleFinaleValidation(t,[
            {type:"text",name:'branchName','value':b.name,required:true},
            {type:"text",name:'city','value':b.city,required:true},
            {type:"text",name:'country','value':b.country,required:true}
        ])
        setFormError(data)
    };
    const confirmDeletebranch = b => {
        console.log('row => ', b);
        setCurrentRow(b);
        setShow('confirmbranch')
    };
    const deleteBranch = () => {
        console.log('delete');
        let data = requestDataBuilderBranch();
        data['id'] = current_row._id;
        props.action_deleteBranch(data);
        console.log('data', data);
        setShow(null);
        props.action_loading(true);
        setCurrentRow()
    };
    const proceedBranch = () => {
        if(current_row && current_row._id) {
            console.log('update');
            editBranch()
        } else {
            console.log('save');
            createBranch()
        }
        setFormError({})
    };

    //Departement methode
    const createDepartment = () => {
        let o = requestDataBuilderDepartement();
        props.action_createDepartment(o);
        setShow(null);
        props.action_loading(true);
        setFormError({})
    };
    const editDepartement = d => {
        let departement = requestDataBuilderDepartement();
        departement['id'] = current_row._id;
        departement['name'] =departmentName;
        console.log('data', departement);
        props.action_updateDepartement(departement);
        setShow(null);
        props.action_loading(true);
        setCurrentRow()
        setFormError({})
    };
    const updateDepartement = d => {
        console.log('row => ', d);
        setCurrentRow(d);
        setDepartmentName(d.name);
       let data= handleFinaleValidation(t,[{type:"text",name:'departmentName','value':d.name,required:true}])
       setFormError(data)
       setShow('department')
    };
    const confirmDeleteDepartement = d => {
        console.log('row => ', d);
        setCurrentRow(d)
        setShow('confirmdepartement')
    };
    const deleteDepartement = () => {
        console.log('delete')
        let data = requestDataBuilderDepartement();
        data['id'] = current_row._id;
        props.action_deleteDepartement(data);
        console.log('data', data);
        setShow(null);
        props.action_loading(true);
        setCurrentRow()
    };
    const proceedDepartement = () => {
        if(current_row && current_row
            ._id) {
            console.log('update');
            editDepartement()
        } else {
            console.log('save');
            createDepartment()
        }
    };

    //Position methode
    const createPosition = () => {
        let o = requestDataBuilderPost();
        props.action_createPosition(o);
        setShow(null);
        props.action_loading(true)
    };
    const editPosition = p => {
        let post = requestDataBuilderPost();
        post['id'] = current_row._id;
        post['title'] = posTitle;
        post['description'] = postDesc;
        post['qualifications'] = posQualifs;
        console.log('data', post);
        props.action_updatePosition(post);
        setShow(null);
        props.action_loading(true);
        setCurrentRow()
    };
    const updatePosition = p => {
        console.log('row => ', p);
        setCurrentRow(p);
        setPosTitle(p.title);
        setPosDesc(p.description);
        setPosQualifs(p.qualifications);
        setShow('position')
      let data=  handleFinaleValidation(t,[
            {type:"text",name:'branchName','value':p.title,required:true},
             ])
             setFormError(data)
    };
    const confirmDeletePosition = p => {
        console.log('row => ', p);
        setCurrentRow(p);
        setShow('confirmpositon')
    };
    const deletePosition = () => {
        console.log('delete');
        let data = requestDataBuilderPost();
        data['id'] = current_row._id;
        props.action_deletePosition(data);
        console.log('data', data);
        setShow(null);
        props.action_loading(true);
        setCurrentRow()
    };
    const proceedPostion = () => {
        if(current_row && current_row._id) {
            console.log('update');
            editPosition()
        } else {
            console.log('save');
            createPosition()
        }
        setFormError({})
    };


    const contextMenu = [
        {label: t('branch_title'), eventKey: 'branches'},
        {label: t('department_title'), eventKey: 'departments'},
        {label: t("position_title"), eventKey: 'positions'},
    ];
    const branchesColumns = [
        {
            name: t('name'),
            selector: 'name',
            cell: row => <div><div style={{width:'100%'}}>{row.name}</div></div>,

        },
        {
            name: t('city'),
            selector: 'city',
            cell: row => <div><div style={{width:'100%'}}>{row.city}</div></div>,

        },
        {
            name: t('country'),
            selector: 'country',
            cell: row => <div><div style={{width:'100%'}}>{row.country}</div></div>,

        },
        {
            name: '',
            cell: row => <div style={{width:'100%'}} className={'text-right'}>
                <Button onClick={() => updateBranch(row)}  size={"sm"} variant={'warning'} className={"ml-2"}><BiPencil/>{t("update")}</Button>
                <Button onClick={() => confirmDeletebranch(row)} variant={"danger"}  size={"sm"} className={"ml-2"}><BiPencil/>{t("delete")}</Button>
            </div>,

        }
    ];
    const departementsColumns = [
        {
            name: t('name'),
            selector: 'name',
            cell: row => <div><div style={{width:'100%'}}>{row.name}</div></div>,
        },
        {
            name: '',
            cell: row => <div style={{width:'100%'}} className={'text-right'}>
                <Button onClick={() => updateDepartement(row)}  size={"sm"} variant={'warning'} className={"ml-2"}>
                    <BiPencil /> {t("update")}
                </Button>
                <Button onClick={() => confirmDeleteDepartement(row)}  size={"sm"} variant={'danger'} className={"ml-2"}>
                    <BiTrash /> {t("delete")}
                </Button>
            </div>,

}
    ];
    const positionsColumns = [
        {
            name: t('title'),
            selector: 'title',
            cell: row => <div><div style={{width:'100%'}}>{row.title}</div></div>,

        },
        {
            name: t('description'),
            selector: 'description',
            cell: row => <div><div style={{width:'100%'}}>{row.description}</div></div>,


        },
        {
            name: t('qualifications'),
            selector: 'qualifications',
            cell: row => <div><div style={{width:'100%'}}>{row.description}</div></div>,

        },
        {
            name:'',
            cell: row =>
                <div style={{width:'100%'}} className={'text-right'}>
                    <Button onClick={() => updatePosition(row)} variant={"warning"} size={"sm"} className={"ml-2"}><BiPencil />{t("update")}</Button>
                    <Button  onClick={ ()=>confirmDeletePosition(row)}  variant={"danger"} size={"sm"} className={"ml-2"}><BiTrash/>{t("delete")}</Button>
                </div>,
        },

    ];
    return <div id={'Branches'}>
        <Container fluid>
            <Row>
                <Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={"branches"}>
                        <Row>
                            <Col>
                                <div className="d-flex align-items-start justify-content-between">
                                    <ContextMenu menu={contextMenu}/>
                                    <div  hidden={role.toLowerCase().includes('employee')}>
                                        <Button className={'m-2'} size={"sm"} onClick={e => setShow('branch')}>
                                            <BsPlus/> {t('branche')}
                                        </Button>
                                        <Button className={'m-2'} size={"sm"} onClick={e => setShow('department')}>
                                            <BsPlus/> {t('department')}
                                        </Button>
                                        <Button className={'m-2'} size={"sm"} onClick={e => setShow('position')}>
                                            <BsPlus/> {t('poste')}
                                        </Button>
                                    </div>
                                </div>

                                <Tab.Content>
                                    <Tab.Pane eventKey="branches">

                                            <DataTable
                                                 noHeader
                                                 fixedHeader
                                                 pagination
                                                columns={branchesColumns}
                                                data={props.rwState.branches}
                                                paginationComponentOptions={
                                                     {
                                                    rowsPerPageText: t('line_per_page'),
                                                     rangeSeparatorText: t('for'),
                                                      noRowsPerPage: false,
                                                       selectAllRowsItem: false,
                                                        selectAllRowsItemText: t('all')} 
                                                    }
                                            />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="departments">

                                            <DataTable
                                                noHeader
                                                fixedHeader
                                                pagination
                                                columns={departementsColumns}
                                                data={props.rwState.departments}
                                                paginationComponentOptions={
                                                    {
                                                        rowsPerPageText: t('line_per_page'),
                                                        rangeSeparatorText: t('for'),
                                                         noRowsPerPage: false,
                                                          selectAllRowsItem: false,
                                                           selectAllRowsItemText: t('all')}  
                                                   }
                                            />

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="positions">
                                        <DataTable
                                            noHeader
                                            fixedHeader
                                            pagination
                                            columns={positionsColumns}
                                            data={props.rwState.positions}
                                            paginationComponentOptions={
                                                {
                                                    rowsPerPageText: t('line_per_page'),
                                                    rangeSeparatorText: t('for'),
                                                     noRowsPerPage: false,
                                                      selectAllRowsItem: false,
                                                       selectAllRowsItemText: t('all')} 
                                               }
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Col>
            </Row>
        </Container>

        {/*  modals */}
        <div>
            <Modal show={show==="confirmbranch"} onHide={() => setShow(null)} centered size={"md"}>
                <Modal.Header closeButton className={'border-0'}>
                    <Modal.Title>{t('confirmation')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"text-center"}>
                        {t('confirme_deletion_text')}
                    </div>
                </Modal.Body>
                <Modal.Footer className={'text-center border-0'}>
                    <Button variant="primary" onClick={deleteBranch}>
                        {t('yes')}
                    </Button>
                    <Button variant="light" onClick={() => setShow(null)}>
                        {t('no')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show==="confirmdepartement"} onHide={() => setShow(null)} centered size={"md"}>
                <Modal.Header closeButton className={'border-0'}>
                    <Modal.Title>{t('confirmation')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"text-center"}>
                    {t('confirme_deletion_text')}
                    </div>
                </Modal.Body>
                <Modal.Footer className={'text-center border-0'}>
                    <Button variant="primary" onClick={deleteDepartement}>
                    {t('yes')}
                    </Button>
                    <Button variant="light" onClick={() => setShow(null)}>
                    {t('no')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show==="confirmpositon"} onHide={() => setShow(null)} centered size={"md"}>
                <Modal.Header closeButton className={'border-0'}>
                    <Modal.Title>{t('confirmation')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"text-center"}>
                    {t('confirme_deletion_text')}
                    </div>
                </Modal.Body>
                <Modal.Footer className={'text-center border-0'}>
                    <Button variant="primary" onClick={deletePosition}>
                    {t('yes')}
                    </Button>
                    <Button variant="light" onClick={() => setShow(null)}>
                    {t('no')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
        <Modal centered show={show === 'branch'} onHide={handleClose}>
            <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
                {
                    current_row ? (<Modal.Title className='w-100'>{t("update_branch_text")}</Modal.Title>) : (<Modal.Title className='w-100'>{t("add_branch_text")}</Modal.Title>)
                }
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <Form.Group>
                    {/*<Form.Label>Nom de la branche</Form.Label>*/}
                    <Form.Control
                        size={"lg"}
                        placeholder={t('branch_name_text')}
                        defaultValue={current_row?.name}
                        onChange={handleBranchName}
                        className={(formError && formError.branchName && formError.branchName.errors && !formError.branchName.formIsValid) ? 'inputValidationError' : ''}
                        />
                               {(formError && formError.branchName && formError.branchName.errors && !formError.branchName.formIsValid) ? <span className={"validationError"}>{formError.branchName.errors.branchName}</span> : ''}
                </Form.Group>
                <Row>
                    <Col>
                        <Form.Group>
                            <Select noOptionsMessage={() => t('no_data_available')}
                                options={countries}
                                getOptionLabel={o => o.name}
                                getOptionValue={o => o.alpha2Code}
                                styles={{
                                    control: base => ({
                                        ...base,
                                        height: 48,
                                        minHeight: 48,
                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t('country')}
                                defaultValue={country}
                                onChange={handleCountry}
                                className={(formError && formError.country && formError.country.errors && !formError.country.formIsValid) ? 'inputValidationError' : ''}
                                />
                                       {(formError && formError.country && formError.country.errors && !formError.country.formIsValid) ? <span className={"validationError"}>{formError.country.errors.country}</span> : ''}
                         </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Select noOptionsMessage={() => t('no_data_available')}
                                options={props.rwState.cities}
                                getOptionLabel={o => o.name}
                                getOptionValue={o => o.name}
                                styles={{
                                    control: base => ({
                                        ...base,
                                        height: 48,
                                        minHeight: 48,
                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                defaultValue={city}
                                placeholder={t('city')}
                                onChange={handleCity}
                                className={(formError && formError.city && formError.city.errors && !formError.city.formIsValid) ? 'inputValidationError' : ''}
                                />
                                       {(formError && formError.city && formError.city.errors && !formError.city.formIsValid) ? <span className={"validationError"}>{formError.city.errors.city}</span> : ''}
                        </Form.Group>
                    </Col>
                </Row>


            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t('cancel')}
                </Button>
                <Button variant="primary" disabled={!formError?.city?.formIsValid 
                    || !formError?.country?.formIsValid || !formError?.branchName?.formIsValid} 
                    onClick={proceedBranch}>
                    {t('save')}
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal centered show={show === 'department'} onHide={handleClose}>
            <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
                {
                    current_row ? (<Modal.Title className='w-100'>{t("department_update_text")}</Modal.Title>) : (<Modal.Title className='w-100'>{t("department_created_text")}</Modal.Title>)
                }
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <Form.Group>
                    <Form.Control
                        size={"lg"}
                        placeholder={t("department_name_text")}
                        defaultValue={current_row?.name}
                        onChange={handleDepartmentName}
                        className={(formError && formError.departmentName && formError.departmentName.errors && !formError.departmentName.formIsValid) ? 'inputValidationError' : ''}
                        />
                               {(formError && formError.departmentName && formError.departmentName.errors && !formError.departmentName.formIsValid) ? <span className={"validationError"}>{formError.departmentName.errors.departmentName}</span> : ''}
                </Form.Group>

            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("cancel")}
                </Button>
                <Button variant="primary" disabled={!formError?.departmentName?.formIsValid} onClick={proceedDepartement}>
                    {t("save")}
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal centered show={show === 'position'} onHide={handleClose}>
            <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
                {
                    current_row ? (<Modal.Title className='w-100'>{t("position_updated_text")}</Modal.Title>) : (<Modal.Title className='w-100'>{t("position_created_text")}</Modal.Title>)
                }
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <Form.Group>
                    <Form.Control
                        size={"lg"}
                        placeholder={t("position_name_text")}
                        onChange={handlePosTitle}
                        defaultValue={current_row?.title}
                        className={(formError && formError.postTitle && formError.postTitle.errors && !formError.postTitle.formIsValid) ? 'inputValidationError' : ''}
                        />
                               {(formError && formError.postTitle && formError.postTitle.errors && !formError.postTitle.formIsValid) ? <span className={"validationError"}>{formError.postTitle.errors.postTitle}</span> : ''}
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        size={"lg"}
                        as={'textarea'}
                        placeholder={t("description")}
                        onChange={handlePosDesc}
                        defaultValue={current_row?.description}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("cancel")}
                </Button>
                <Button variant="primary" disabled={!formError?.postTitle?.formIsValid} onClick={proceedPostion}>
                    {t("save")}
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal size={"sm"} centered show={props.rwState.loadingG} onHide={handleClose}>
            <Modal.Body>
                <Spinner animation="border" role="status">
                    <span className="sr-only">{t("loading_text")}</span>
                </Spinner>
            </Modal.Body>
        </Modal>

    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Branches);
