import React, {useState} from 'react';
import { useHistory,useParams } from "react-router-dom";
import './Auth.css';
import { useCookies } from "react-cookie";
import {Col, Container, FormGroup, Form, Row, Button} from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel'
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ContextMenu from "../menus/ContextMenu";
import Select from "react-select";
import countries from "../../assets/countries";
import axios from "axios";
import axios2 from "axios";
import env from "../../env";
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

// {
//   "name": "Jungle Technologies",
//     "country": "BJ",
//     "website": "https://www.testcompany.com",
//     "admin_email": "tester@test.com",
//     "language": "fr"
// }
//axios.defaults.withCredentials = true;
axios2.defaults.withCredentials = true;
// axios2.defaults.baseURL =env.DEV.docApiUrl;
const Auth = () => {
  const { t,i18n } = useTranslation();
  const contextMenu = [
    {label:t('login'), href:'/auth/signin'},
    {label:t('inscription'), href:'/auth/signup'},
    // {label:'Mot de passe Perdu', href:'/auth/resetpass'},
  ]

  const history = useHistory();
  const location = useLocation();
  let queryStr = queryString.parse(location.search);
  const token = queryStr.passreset;
const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
  const [loading,setLoading] = useState(false)
  const [success,setSuccess] = useState(false)
  const [loginError,setLoginError] = useState()
  const [signUpError,setSignUpError] = useState()
  const [signUpErrorMsg,setSignUpErrorMsg] = useState(t('redo_full_text'))
  const [pwdError,setPwdError] = useState(false)
  const [pwdSuccess,setPwdSuccess] = useState(false)
  const [pwdErrorMsg,setPwdErrorMsg] = useState('')
  const [email,setEmail] = useState()
  const [emailVerif,setEmailVerif] = useState()
  const [emailIsPro,setEmailIsPro] = useState(false)
  const [pass,setPass] = useState()
  const [passVerif,setPassVerif] = useState()
  const [company,setCompany] = useState()
  const [companyVerif,setCompanyVarif] = useState()
  const [country,setCountry] = useState()
  const [countryVerif,setCountryVarif] = useState()
  const [website,setWebsite] = useState()
  const [websiteVerif,setWebsiteVarif] = useState()
  const [langue,setLangue] = useState()
  const [langueVerif,setLangueVarif] = useState();
  const [validated, setValidated] = useState(false);
  const [passMismatch, setPassMisMatch] = useState(false);
    const professionalEmlReg =  /^([\w-.]+@(?!gmail\.com)(?!yahoo\.com)(?!yahoo\.fr)(?!hotmail\.com)(?!hotmail\.fr)(?!outlook\.com)(?!outlook\.fr)([\w-]+.)+[\w-]{2,4})?$/;
    const handleEmail = (e) => {
    setEmail(e.target.value)
        setEmailIsPro(true)
  }
  const handlePass = (e) => {
    setPass(e.target.value);
    setPassMisMatch(false)
  }
  const handlePassVerif = (e) => {
    setPassVerif(e.target.value)
      setPassMisMatch(false)

  }
  const handleCompany = (e) => {
    setCompany(e.target.value)
  }
  const handleCountry = (e) => {
    setCountry(e.alpha2Code)
  }
  const handleWebsite = (e) => {
    setWebsite(e.target.value)
  }
  const handleLangues = (e) => {
    setLangue(e.value)
    i18n.changeLanguage(e.value)
  }
  const signin = () => {
    setLoginError()
    if(email,pass){
      setLoading(true)
      setEmailVerif(false)
      setPassVerif(false)
      axios.post(
              env.DEV.apiUrl+env.urls.login,
              {email:email,password:pass},
              {headers:{'Content-Type':'application/json'},},
          )
          .then(s=>{
            if (s.status===200){
                setLoading(false)
                setLoginError(false)
                window.localStorage.setItem('glowhr_user', JSON.stringify(s.data))
                window.location = '/dash';
              }else {
                setLoginError(true)
                setLoading(false)
            }
          })
          .catch(e=>{
            console.log(e)
            setLoading(false)
              setLoginError(true)
          })
    }else {
      setEmailVerif(!email)
      setPassVerif(!pass)
    }
  }
  
  const signup = () => {
    if(email && company && country  && langue) {
      setLoading(true)
      axios
          .post(
              env.DEV.apiUrl + env.urls.register,
              {
                "name": company,
                "country": country,
                "website": website,
                "admin_email": email,
                "language": langue
              },
              {
                  headers: {'Content-Type': 'application/json'},
              },
          )
          .then(s => {
            console.log('result',s);
              switch (s.status) {
                  case 1007:
                      setSignUpError(true);
                        setSignUpErrorMsg(t('email_use_text'));
                      break;
                  case 200:
                      setSuccess(true)
                      break;
                  case 400:
                      setSignUpError(true);
                      setSignUpErrorMsg(t("input_valid_text"));
                      break;
                  default:
                      setSignUpError(true);
                      setSignUpErrorMsg(t('redo_full_text'));
                      break;
              }

            setLoading(false)
          })
          .catch(e => {
            console.log("registration err ",e)
            setLoading(false);
              setSignUpError(true);
              setSignUpErrorMsg(t("check_inofrmation_validity"));
          })
    }else {
      setEmailVerif(email?null:true)
      setCompanyVarif(company?null:true)
      setCountryVarif(country?null:true)
      setLangueVarif(langue?null:true)
      setSignUpErrorMsg(t("check_all_information_validity"));
    }
  }
  const resetpass = (event) => {
      event.preventDefault();
      setPwdError(false);
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
          return;
      }
      if (token){
        if (pass!==passVerif){
          setPassMisMatch(true);
            return;
        }
          setLoading(true)
          axios
              .post(
                  env.DEV.apiUrl + env.urls.passwordReset+token,{"password": pass},{headers: {'Content-Type': 'application/json'}})
              .then(s => {
                  console.log('result',s)
                  if (s.status===200){
                      setPwdSuccess(true)
                  }else{
                      switch (s.status) {
                          case 1001:
                              setPwdErrorMsg(t('pwd_error_msg'));
                              break;
                          case 400:
                              setPwdErrorMsg(t('pwd_error_msg'));
                              break;
                          case 1020:
                              setPwdErrorMsg(t('pwd_error_msg'));
                              break;
                          default:
                              setPwdErrorMsg(t('redo_full_text'))
                              break;

                      }
                      setPwdError(true);
                  }
                  setLoading(false)
              })
              .catch(e => {
                  console.log("registration err ",e)
                  setLoading(false)
              })
      }else{
          setLoading(true)
          axios.post(env.DEV.apiUrl + env.urls.passwordForgot, {"email": email,}, {headers: {'Content-Type': 'application/json'},})
              .then(s => {
                  console.log('result',s)
                  if (s.status===200){
                      setPwdSuccess(true)
                  }else{
                      if (s.status===500){
                          setPwdErrorMsg(t('redo_full_text'))
                      }else{
                          setPwdErrorMsg(t('redo_full_text'))
                      }
                      setPwdError(true);
                  }
                  setLoading(false)
              })
              .catch(e => {
                  console.log("registration err ",e)
                  setLoading(false)
              })
      }
      setValidated(true);
  }
 
  return <div id={'Auth'}>
    <Col>
    <Row>
    <Col md={5} className={'auth-box pl-lg-5 pl-md-4 pl-sm-3 pr-lg-5 pr-md-4 pr-sm-3'}>
    <Row>
        <h2 className={'col-lg-12 mt-sm-5'}>
          {t('welcome_text')}
        </h2>
        <p  className={'col-lg-12'}>
          {t('login_text')}
        </p>
      </Row>
      <Row>
        <Col>
          <ContextMenu menu={contextMenu}/>
        </Col>
      </Row>
      <Row hidden={success}>    
        <Switch>
          <Route path="/auth/signin">
            <Col md={12}>
              <div className={'mt-3'}>
                {/*<div>*/}
                {/*  <div className="form-group specialFormControl">*/}
                {/*    <small>Email</small>*/}
                {/*    <input type="email" className="form-control"/>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <FormGroup className={"specialFormControl"}>
                  <Form.Label>{t("email")}</Form.Label>
                  <Form.Control isInvalid={emailVerif} type={'email'} placeholder={t("email")} size={"lg"} onChange={handleEmail}/>
                </FormGroup>
                <FormGroup className={"specialFormControl"}>
                  <Form.Label>{t('password')}</Form.Label>
                  <Form.Control isInvalid={passVerif} placeholder={t('password')} type={'password'} size={"lg"} onChange={handlePass}/>
                </FormGroup>
                <p className={'text-danger'} hidden={!loginError}><strong>{t('error_occure')}. <br/><small>{t('redo')}.</small></strong></p>
                <Button className={'m-0'} disabled={loading} block size={"lg"} onClick={signin}>{loading ? t('loginning') : t('login_btn')}</Button>
                <a className={"mt-2"} href={""} onClick={e=>{e.preventDefault();history.push('/auth/resetpass');}}>{t('password_forget_text')}?</a>
              </div>
            </Col>
          </Route>
          <Route path="/auth/resetpass">
            <Col md={12}>
              <Form noValidate validated={validated} >
                {
                  !token ?
                      <div className={'mt-3'}>
                        <p className={'text-success'} hidden={!pwdSuccess}>{t('reinitialisation_email_sent')}</p>
                        <p hidden={pwdSuccess}> {t('reinitialisation_link_sent')}. </p>
                          <FormGroup className={"specialFormControl"}>
                              <Form.Label>{t('email')}</Form.Label>
                              <Form.Control required type={'email'} placeholder={t('email')} size={"lg"} onChange={handleEmail}/>
                          </FormGroup>
                          <Button  disabled={loading} block size={"lg"} onClick={resetpass}>{loading?t('sending_link'):t('send_link')}</Button>

                      </div>
                      :
                      <>
                      <p className={'text-success'} hidden={!pwdSuccess}>{t('password_reinitialisation_success')}</p>
                      <div className={'mt-3'} hidden={pwdSuccess}>
                        <FormGroup className={"specialFormControl"} >
                              <Form.Label>{t('new_password_text')}</Form.Label>
                              <Form.Control required type={'password'} placeholder={t('new_password_text')} size={"lg"} onChange={handlePass}/>
                          </FormGroup>
                          <FormGroup className={"specialFormControl"}>
                              <Form.Label>{t('confirm_new_password_text')}</Form.Label>
                              <Form.Control required type={'password'} placeholder={t('confirm_new_password_text')} size={"lg"} onChange={handlePassVerif}/>
                          </FormGroup>
                          <Button  disabled={loading} block size={"lg"} onClick={resetpass}>{loading?t('reinitialisation_init'):t('reinitialisate')}</Button>
                          <p className={'text-danger'} hidden={!passMismatch}>{t('password_not_same')}</p>
                      </div>
                      </>
                }
                  <p className={'text-danger'} hidden={!pwdError}>{pwdErrorMsg}</p>

              </Form>
            </Col>
            <Col md={"auto"}></Col>
          </Route>
          <Route path="/auth/signup">
            <Col md={12} className={'mt-3'}>
              <Form noValidate validated={validated}>
                 
                  <Row>
                    <FormGroup className={"specialFormControl col-lg-12 col-12"}>
                      <Form.Label>{t('company_name')}</Form.Label>
                      <Form.Control isInvalid={companyVerif} type={'text'} placeholder={t('company_name')} size={"lg"} onChange={handleCompany}/>
                    </FormGroup>
                    <FormGroup className={"specialFormControl col-lg-6 col-12"}>
                      <Form.Label>{t('country')}</Form.Label>
                      <Select
                          options={countries}
                          getOptionLabel={opt=>opt.name}
                          getOptionValue={opt=>opt.alpha2Code}
                          placeholder={t('country')}
                          styles={{
                            control: base => ({
                              ...base,

                              minHeight: 64,
                              borderColor:countryVerif?'#dc3545':'hsl(0,0%,80%)'
                            }),
                            singleValue: base => ({
                              ...base,
                              paddingLeft:5,
                              marginTop:7,
                              font: "normal normal 600 16px/16px Poppins",
                              color: "#240265 !important"
                            }),
                            placeholder: base => ({
                              ...base,
                              paddingLeft:5,
                              marginTop:7,
                              font: "normal normal 600 16px/16px Poppins",
                            })
                          }}
                          theme={theme => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: '#6600CC',
                              primary25: '#6600CC25',
                              primary75:'#6600CC75',
                              primary50:'#6600CC50'
                            },
                          })}
                          onChange={handleCountry}
                      />
                    </FormGroup>
                    <FormGroup className={"specialFormControl col-lg-6 col-12"}>
                      <Form.Label>{t('email')}</Form.Label>
                      <Form.Control isInvalid={emailVerif} type={'email'} placeholder={t('email')} size={"lg"} onChange={handleEmail}/>
                    </FormGroup>

                      <FormGroup className={"specialFormControl col-lg-6 col-12"}>
                      <Form.Label>{t('language')}</Form.Label>
                      <Select
                          options={[{label:'Français',value:'fr'},{label:'English',value:'en'}]}
                          placeholder={t('language')}
                          styles={{
                            control: base => ({
                              ...base,

                              minHeight: 64,
                              borderColor:langueVerif?'#dc3545':'hsl(0,0%,80%)'
                            }),
                            singleValue: base => ({
                              ...base,
                              paddingLeft:5,
                              marginTop:7,
                              font: "normal normal 600 16px/16px Poppins",
                              color: "#240265 !important"
                            }),
                            placeholder: base => ({
                              ...base,
                              paddingLeft:5,
                              marginTop:7,
                              font: "normal normal 600 16px/16px Poppins",
                            })
                          }}
                          theme={theme => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: '#6600CC',
                              primary25: '#6600CC25',
                              primary75:'#6600CC75',
                              primary50:'#6600CC50'
                            },
                          })}
                          onChange={handleLangues}
                      />
                    </FormGroup>
                    <FormGroup className={"specialFormControl  col-lg-6 col-12"}>
                      <Form.Label>{t('web_site')}</Form.Label>
                      <Form.Control  type={'url'} placeholder={t('web_site')} size={"lg"} onChange={handleWebsite}/>
                    </FormGroup>
                   <Col className={'col-lg-12'}>
                       <p className={'text-danger'} hidden={!signUpError}>{signUpErrorMsg||""}</p>
                     <Button  disabled={loading} block size={"lg"} onClick={signup}>{loading ? t('saving') : t('save')}</Button>
                    </Col>
                  </Row>
              </Form>
            </Col>
          </Route>
          <Route path="/auth/">
            <Redirect to="/auth/signin" />
          </Route>
          <Route path="/">
            <Redirect to="/auth/signin" />
          </Route>
        </Switch>
      </Row>
      <Row hidden={!success}>
        <Col md={6}>
          <div className={'mt-3'}>
            <div className={'mt-2 mb-3'}>
              <h2>{t('company_successfully_save')}!</h2>
              <p>{t('company_successfully_text')}.</p>
              <p>{t('thanks')}.</p>
            </div>
            <Button size={"lg"} href={'/auth/signin'}>{t('login_btn')}</Button>
          </div>
        </Col>

      </Row>
    </Col>
    <Col md={7} className={'login-left-side text-center d-none d-sm-none d-md-flex p-0 justify-content-center'}>
      <Carousel indicators={false} className='carousel-100vh'>
        <Carousel.Item>
          <img  className="d-block mx-auto w-100 h-100" src="/banner/banner09.png" alt="First slide"/>
        </Carousel.Item>
        <Carousel.Item>
          <img  className="d-block mx-auto w-100 h-100"  src="/banner/banner06.png" alt="Second slide"/>
        </Carousel.Item>
        <Carousel.Item>
          <img  className="d-block mx-auto w-100 h-100" src="/banner/banner07.jpg" alt="Third slide"/>
        </Carousel.Item>
      </Carousel>
    </Col>
    </Row>
    </Col>
  </div>;
};

export default Auth;
