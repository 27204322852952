import React, {useEffect, useState} from 'react';
import "./Payments.css"
import { Card, Col, Container, Row} from "react-bootstrap";
import DataTable from 'react-data-table-component';
import {getFormatedDate, isAdmin} from "../../../helpers/helper";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const Payments = (props) => {
    const { t } = useTranslation();
    const options = {
        month: [
            {label: "options month 1", available: true},
            {label: "options month 2", available: false},
            {label: "options month 3", available: true}
        ],
        year: [
            {label: "options year 1", available: true},
            {label: "options year 2", available: false},
        ]
    }
    const prices = [
        {
            id: 0, label: "start ups", bonus: "Save - 50%", price: {
                month: 20,
                year: 17
            },
            currency: "XOF"
        },
        {
            id: 1, label: "standard", bonus: "Save - 60%", price: {
                month: 50,
                year: 47
            },
            currency: "XOF"
        },
    ]
    const [period, setPeriod] = useState("month")
    const [payment, setPayment] = useState(prices[0])
    const [cumuleState, setCumuleState] = useState(false)
    const [statusCodeBtn,setStatusCodeBtn]=useState(true);
    const [errorCodeBtn,setErrorCodeBtn]=useState(false);
    const [code,setCode]=useState();
    const [company,setCompany]=useState(undefined);
    
    useEffect(()=>{
        props.action_loading(true)
        props.action_listCompanyAbonnement()
        props.action_setTitle("Abonnements")
        let user=JSON.parse(window.localStorage.getItem('glowhr_user'))?.user;
       console.log(user)

       if(user){
        setCompany(user.company);
       }

    },[])

    const handleCodes = (e) => {
        let data=e.target.value.replace(' ','')
      if(data.length >= 300){
          setErrorCodeBtn(false)
          setStatusCodeBtn(false)
          setCode(e.target.value)
      }else{
          setErrorCodeBtn(true)
          setStatusCodeBtn(true)
      }
    } 
    
    const upDateCompanyCodes = (e) => {
        let data={
             code:code,
         }
         setStatusCodeBtn(true)
         props.action_updateCompanyData(data);
         props.action_loading(true)
         setCode('');
       }
    const abonnementColumns = [
        {
            name: t("subscription_text"),
            selector: 'abonnement_pricing_info',
            cell: row => <div><div style={{width:'100%'}}>{row.abonnement_pricing_info.name}</div></div>,

        },
        {
            name: t("payment_mode_text"),
            selector: 'paiment_mode',
            cell: row => <div><div style={{width:'100%'}}>{row.paiment_mode}</div></div>,

        },
          {
            name: t("status"),
            selector: 'status',
            cell: row => <div>
                <div style={{width:'100%'}}>{
                 row.status===0 ? <span class="badge badge-warning">{t("not_used")}</span> 
                :
                (company.abonnement_code_info ===row._id  ?
                     <span class="badge badge-success">{t("used")}</span> : 
                     <span class="badge badge-primary">{t("using")}</span>
                     )
                    }</div>
                </div>,

        },
        {
            name: t("aquisition_date"),
            selector: 'created_at',
            cell: row => <div><div style={{width:'100%'}}>{getFormatedDate(row.created_at)}</div></div>,

        },
        
    ];
    return <div id={"Payments"}>
{ props.rwState.abonnements ? 
(props.rwState.abonnements.length > 0 ?
<Container fluid>
            <Row>
                <Col>
                    <h2 className={"text-uppercase mt-3 mb-4"}>{t("subscription_param_text")}</h2>
                </Col>
            </Row>
            <Row>
                <Col md={"7"}>
                    <Card className={"p-4 border-0"}>
                        <div className={"mt-4"}>
                           <div className={"col-12 col-md-12"}>

                           {company.account==='live' ? 
                           <div className={"row"}  hidden={cumuleState}>
                           
                                    <h3 className={"col-12 col-md-12"}>{t("current_subscription")}</h3>
                                    <p className={"col-12 col-md-12"}>
                                  <h2 className={"mt-2 mb-2 text-uppercase"}>  {company ? 
                                            props.rwState.abonnements.find(r=>r._id===company.abonnement_code_info)?.abonnement_pricing_info?.name : ''}
                                       </h2>
                                            {company ? 
                                            props.rwState.abonnements.find(r=>r._id===company.abonnement_code_info)?.code : ''}
                                    </p>
                                    <div className={"col-12 col-md-12"}>
                                        <button className={"btn btn-light btn-sm"} onClick={e=>{setCumuleState(true)}}>{t("cumulate")}</button>
                                    </div>
                           </div>
                          : 
                          <div className={"row"}  hidden={cumuleState}>
                          <div className={"col-12 col-md-12"}>
                              <button className={"btn btn-primary btn-sm"} onClick={e=>{setCumuleState(true)}}>{t("switch_live_account")}</button>
                          </div>
                 </div>
               }
                          <div className={"row"}  hidden={!cumuleState}>
                            <div className={"form-group col-12 col-md-12"}>
                <label>{t("insert_code_notification")}</label>
                <textarea onChange={handleCodes} style={{ border: errorCodeBtn ? '1px solid red' : '' ,minHeight:'200px'}} className={"form-control"} row={"10"} name={"codes"} placeholder={t("your_code_here")}></textarea>
              {errorCodeBtn ? <span style={{ color : 'red' }}> Code Invalide </span> : ''}
               <div className={"text-right"}>
               <button type="button" onClick={e=>{setCumuleState(false)}} className={"btn btn-light btn-sm mt-2"} > {t("cancel")}</button> &nbsp;&nbsp;&nbsp;
               <button type="button" onClick={e=>{upDateCompanyCodes()}} className={"btn btn-primary btn-sm mt-2"} disabled={statusCodeBtn}> {t("code_validation_btn")}</button>
               </div>
            </div>
            <p>
              <strong>NB : </strong> {t("where_to_get_code")}
             <br/>
             <br/>
             <a href="https://glowhr.com" className={"link"}> {t("buy_new_code")}</a> 
         
            </p>
                           </div>
                           </div>
                        </div>
                    </Card>
                </Col>
                <Col md={"5"}>
                   
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2 className={"text-uppercase mt-5 mb-4"}>{t("subscription_history")}</h2>
                </Col>
            </Row>
            <div className={"mt-2 "}>
            <DataTable
                                                 noHeader
                                                 fixedHeader
                                                 fixedHeaderScrollHeight="600px"
                                                 pagination
                                                columns={abonnementColumns}
                                                data={props.rwState.abonnements}
                                                noDataComponent={t("no_subscription_found")}
                                                paginationComponentOptions={
                                                    {
                                                   rowsPerPageText: t("line_per_page"),
                                                    rangeSeparatorText: t("for"),
                                                     noRowsPerPage: false,
                                                      selectAllRowsItem: false,
                                                       selectAllRowsItemText: t("all")} 
                                                   }
                                            />
            </div>
        </Container>
: <Col sm={12} className={"text-center mt-5 pt-5"}>
       <div class="alert alert-warning"> {t("no_subscription_found")} </div>
</Col>)
:  
                            <Col sm={12} className={"text-center mt-5 pt-5"}>
                                <img src={"../../../loader.gif"} width={"100px"}/>
                            </Col>
                            }
      {/*   <Container fluid>
            <Row>
                <Col>
                    <div className={"sectionTitle mt-3 mb-5"}>Paramètre d'abonnement</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className={"p-4 border-0"} style={{borderRadius:20}}>
                        <div className={"mt-4"}>
                            <Button variant={period === 'month' ? "purple" : "white"}
                                    onClick={e => setPeriod('month')}>Mensuel</Button>
                            <Button variant={period === 'year' ? "purple" : "white"}
                                    onClick={e => setPeriod('year')}>Annuel</Button>
                        </div>
                        <div>
                            {options[period].map(o =>
                                <div className={o.available ? "optionTrue" : "optionFalse"}>
                                    <div className={"d-flex align-items-center justify-content-between mt-4"}>
                                        <div>{o.label}</div>
                                        <div>{o.available ? <RI.FaCheckCircle/> : <RI.BsXCircleFill/>}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Card>
                </Col>
                <Col>
                    <div>
                        {prices.map(p =>
                            <div key={p.id} className={p.id===payment?.id?"payment active":"payment"} onClick={e=>setPayment(p)}>
                                <div className={"d-flex align-items-center p-3"}>
                                    <div>{p.id===payment?.id?<RI.FaCheckCircle size={32}/>:<RI.BsCircle size={32}/>}</div>
                                    <div className={"ml-3 w-100 mr-3"}>
                                        <div>Pack <strong>{p.label}</strong></div>
                                        <div>{p.bonus}</div>
                                    </div>
                                    <div className={"d-flex align-items-center"}>
                                        <div className={"price"}>{p.price[period]}</div>
                                        <div style={{minWidth:70}}>
                                            <div>{p.currency}</div>
                                            <div>/{period.toUpperCase()}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
            <div className={"text-right mt-5"}>
                <div className={" mt-5"}>
                    <Button size={"lg"} variant={"purple"}>Proceder au paiement</Button>
                </div>
            </div>
        </Container>
   */}
 
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
