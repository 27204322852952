const initialState = {
    articles: [],
    remoteArticles: [],
    something:null,
    loading:false,
    branches:[],
    departments:[],
    positions:[],
    settings:[],
    posts:[],
    applicants:[],
    references:[],
    formations:[],
    formation_assigned:[],
    module_formations:[],
    module_formation:[],
    all_module_formations:[],
    parcours_formations:[],
    tests_formations:[],
    surveys:[],
    surveys_questions:[],
    event_types:[],
    cities:[],
    colleagues:[],
    colleague:undefined,
    organigram:[],
    events:[],
    expensetypes:[],
    myLeaves:[],
    askingLeaves:[],
    leaveTypes:[],
    sectionTitle:'',
    payrolls: [],
    roles: [],
    expenses: [],
    mySuggestions: [],
    error:undefined,
    survey:{},
    questions:undefined,
    interviews:[],
    goals:[],
    avertissments:[],
    plan_ameliorations:[],
    goal:{},
    departs:[],
    paginator:{},
    zulip_url:undefined,
    visio_confs:[],
    visio_conf: undefined,
    news: [],
    newsletters: [],
    abonnements: undefined,
};

function rootReducer(state = initialState, action) {
    if (!state) {
        return null;
    }
    //console.log(action);
    switch (action.type) {
        case "DO_SOMETHING": {
            // console.log("state : ", { ...state });
            // console.log(state);
            // console.log(action);
            return {...state, something: action.payload};
        }
        case "ADD_ARTICLE": {
            return Object.assign({}, state, {
                articles: state.articles.concat(action.payload)
            });
        }
        case "DATA_LOADED": {
            return {...state, remoteArticles: action.payload};
        }
        case "LOADING": {
            return {...state, loading: action.payload};
        }
        case "SECTITLE": {
            return {...state, sectionTitle: action.payload};
        }
        case "BRANCHES": {
            return {...state, branches: action.payload};
        }
        case "SETTINGS": {
            return {...state, settings: action.payload};
        }
        case "DEPARTMENTS": {
            return {...state, departments: action.payload};
        }
        case "POSITIONS": {
            return {...state, positions: action.payload};
        }
        case "REFERENCES": {
            return {...state, references: action.payload};
        }
        case "POSTS": {
            return {...state, posts: action.payload};
        }
        case "APPLICANTS": {
            return {...state, applicants: action.payload};
        }
        case "CITIES": {
            return {...state, cities: action.payload};
        }
        case "COLLEAGUES": {
            return {...state, colleagues: action.payload};
        }
         case "COLLEAGUE": {
            return {...state, colleague: action.payload};
        }
        case "ORGANIGRAM": {
            return {...state, organigram: action.payload};
        }
        case "EVENTS": {
            return {...state, events: action.payload};
        }
         case "MYEVENTS": {
            return {...state, my_events: action.payload};
        }
        case "MYLEAVES": {
            return {...state, myLeaves: action.payload};
        }
        case "ASKINGLIVES": {
            return {...state, askingLeaves: action.payload};
        }
        case "LEAVETYPES": {
            return {...state, leaveTypes: action.payload};
        }
        case "SUGGESTIONS": {
            return {...state, suggestions: action.payload};
        }
        case "MY_SUGGESTIONS": {
            return {...state, mySuggestions: action.payload};
        }
        case "UPDATE_SOCKET_INSTANCE": {
            return {...state, socketIoInstance: action.payload}
        }
        case "UPDATE_PROFILE_DOCS": {
            return {...state, documents: action.payload}
        }
        case "ROLES": {
            return {...state, roles: action.payload}
        }
        case "EXPENSES": {
            return {...state, expenses: action.payload}
        }
        case "ERROR": {
            return {...state, error: action.payload};
        }
        case "TYPE_DEPENSE": {
            return {...state, expensetypes: action.payload}
        }
        case "PAYROLL": {
            return {...state, payrolls: action.payload}
        }
        case "PAYR": {
            return {...state, payroll: action.payload}
        }
        case "EXPENSE": {
            return {...state, expense: action.payload}
        }
       case "FORMATIONS": {
            return {...state, formations: action.payload}
        }
       case "USERFORMATIONS": {
            return {...state, user_formations: action.payload}
        }
        case "MODULEFORMATIONS": {
            return {...state, module_formations: action.payload}
        }
         case "ALLMODULEFORMATIONS": {
            return {...state, all_module_formations: action.payload}
        }
        case "GETMODULEFORMATION": {
            return {...state, module_formation: action.payload}
        }
        case "PARCOURSFORMATIONS": {
            return {...state, parcours_formations: action.payload}
        }
       case "TESTSFORMATIONS": {
            return {...state, tests_formations: action.payload}
        }
       case "ASSIGNFORMATIONS": {
            return {...state, formation_assigned: action.payload}
        }
      case "SURVEYS": {
            return {...state, surveys: action.payload}
        }
      case "SURVEY": {
            return {...state, survey: action.payload}
        }
       case "QUESTIONS": {
            return {...state, questions: action.payload}
        }
        case "INTERVIEWS": {
            return {...state, interviews: action.payload}
        }
        case "EVENTTYPES": {
            return {...state, event_types: action.payload}
        }
        case "NOTIFICATIONS": {
            return {...state, notifications: action.payload}
        }
         case "PARAMETERS": {
            return {...state, parameters: action.payload}
        }
        case "GOALS": {
            return {...state, goals: action.payload}
        }
        case "GOAL": {
            return {...state, goal: action.payload}
        }
        case "AVERTISSMENTS": {
            return {...state, avertissments: action.payload}
        }
        case "PLAN_AMELIORATIONS": {
            return {...state, plan_ameliorations: action.payload}
        }
         case "DEPART": {
            return {...state, departs: action.payload}
        }
        case "PAGINATOR": {
            return {...state, paginator: action.payload}
        }
        case "ZULIP_URL": {
            return {...state, zulip_url: action.payload}
        }
        case "VISIO_CONFS": {
            return {...state, visio_confs: action.payload}
        }
        case "VISIO_CONF": {
            return {...state, visio_conf: action.payload}
        }
       case "NEWS": {
            return {...state, news: action.payload}
        }
        case "NEWSLETTERS": {
            return {...state, newsletters: action.payload}
        }
         case "ABONNEMENTS": {
            return {...state, abonnements: action.payload}
        }
         case "DOCUMENTS": {
            return {...state, documents: action.payload}
        }
        default:
            return state;
    }
}

export default rootReducer;
