import React, {useEffect} from 'react';
import "./DashComm.css"
import moment from "moment";
import msvg from "../../../assets/gender/m.png"
import {getFileUrl} from "../../../service";
import {limitText} from "../../../helpers/helper";
import {BsPlus} from "react-icons/bs";
import {connect} from "react-redux";
import * as actions from "../../../actions";
import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
  return {rwState: state}
}
const mapDispatchToProps = {...actions}
const DashComm = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    props.action_loading(true);
    props.action_listNews();
  }, []);
  return <div id={'DashComm'} className={"mt-4"}>
  
      {props.rwState.news && props.rwState.news.length>0 ?
          <div className={"col-12 col-md-12"} style={{ background:'#fff', borderRadius: '33px', height: 'auto', padding: '32px 15px' }}>
              <div className="mb-4 mt-2 d-flex mb-4 align-items-center">
                  <h3 className={"title"}>{t("available_news_title")} </h3>
                  <div style={{width:"128px",borderBottom: "3px solid #240265",opacity: 0.19,marginLeft:15,marginRight:15,marginBottom:-8}}/>
              </div>
         <div class="row">
      {props.rwState.news && props.rwState.news.length>0 ?
          props.rwState.news.slice(0,3).map(p=>{
            return   <div className="col-12 col-md-4 col-lg-3">
              <div className="card" style={{border:'1px solid #f8f8f8',borderRadius:'10px', background:'#ddd'}}>
                <div className="card-body">
                  <img src={getFileUrl(p.filename)} alt="" style={{width:'100%'}}/>
                  <p className={"mt-2 mb-3"}>{limitText(p.resume,50)}</p>
                  <div>
                    <button className="btn btn-light btn-sm" onClick={()=> history.push({pathname:'/dash/communications/'+p._id})}><BsPlus/>{t("wiew_more")}</button>
                  </div>
                </div>
              </div>
            </div>
          })
         
          :''
      }
      </div>
       <div>
          {props.rwState.news && props.rwState.news.length>0 ? <div className="col-12 text-center">
          <button className="btn btn-light btn-sm mt-5 mb-5" onClick={()=>history.push(location.origin+'/dash/communications')}>{t("wiew_more_news")}</button>
        </div>
        :''}
        </div>
       </div> : ''
      }
     
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(DashComm);
