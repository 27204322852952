import React, { useEffect } from 'react';
import './SocketContainer.css'
import { connect, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import * as actions from "../../actions";
import {toast, ToastContainer} from "react-toastify";
import Emitter from "../../service/events";
import axios from "axios";
import env from "../../env";
import addNotification from 'react-push-notification';
import {useHistory} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
  return { rwState: state }
}
const mapDispatchToProps = { ...actions };
const music = new Audio('/notification/song1.mp3');


const SocketContainer = (props) => {
  const { t } = useTranslation();

  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
    const history = useHistory();

  let dispatch = useDispatch();

  useEffect(() => {
      let user = JSON.parse(window.localStorage.getItem('glowhr_user')) || {};
    if (user.session_id) {
        //common datas
        // props.action_listDepartments();
        // props.action_listBranches();
        // props.action_listPositions();
        // props.action_listRoles();
    }
  }, []);

    const notify = (data) => toast(data.message,{type:data.type});
    const loadOpData = (data)=>{
        let error = {};
        let toastType = "";
        if (data.status===200){
            error.error = false;
            if (data.title==="REQUEST_QUEUED"){
                error.message = t("operation_init");
                toastType = "warning";
            }else{
                error.message = data.message;
                toastType="success";
            }
        }else{
            error.error = true;
            error.message = data.message ? (data.message.length > 11 ? data.message : t("redo_full_text")) : t("redo_full_text");
           // error.message = "Oups! une erreur est survenue lors du traitement, veuillez réessayer";
            toastType="error";
        }
        notify({message:error.message,type:toastType});
        dispatch({ type: 'ERROR', payload: error });
        dispatch({ type: 'LOADING', payload: false });
    };
    useEffect(() => {
    const { socket } = props;
    let user = JSON.parse(window.localStorage.getItem('glowhr_user')) || {};

    setCookie('sid', user.session_id, { domain: 'glow-hr.com', path: '/', secure: false })
 
    socket.on('connect', function () {
      console.log('socket connected')
    });

    socket.on('test-glow', function (data) {
      alert(data)
    });
    socket.on('updatecompanyAbonnement_' + user.session_id, function (data) {
      dispatch({ type: 'LOADING', payload: false })
     if(data.status===200)   data.message=data.data.frmessage;
      loadOpData(data)
      if(data.status===200){
      setTimeout(function(){
          window.localStorage.clear();
          window.location.reload();
      },2000)
    }
    });
 
    socket.on('listCompanyAbonnement_' + user.session_id, function (data) {
      dispatch({ type: 'LOADING', payload: false })
     console.log(data)
      dispatch({ type: 'ABONNEMENTS', payload: data.data.abonnementCodes });
    });
  socket.on('newBranch_' + user.session_id, function (data) {
      console.log(data);
      dispatch({ type: 'LOADING', payload: false })
     if(data.status===200)   data.message = t("branch_added");
      loadOpData(data)
    });

     socket.on('updateUserData_' + user.session_id, function (data) {
      console.log(data);
      dispatch({ type: 'LOADING', payload: false })
    });

    socket.on('newDepartment_' + user.session_id, function (data) {
      dispatch({ type: 'LOADING', payload: false })
       if(data.status===200)   data.message = t("department_added");
        loadOpData(data)
    });

   socket.on('newDocument_' + user.session_id, function (data) {
      dispatch({ type: 'LOADING', payload: false })
       if(data.status===200)   data.message = data.data.frmessage;
        loadOpData(data)
    });

    socket.on('newPosition_' + user.session_id, function (data) {
      console.log(data);
      dispatch({ type: 'LOADING', payload: false })
       if(data.status===200)   data.message = t("position_added");
        loadOpData(data)
    });

    socket.on('listDepartments_' + user.session_id, function (data) {
      console.log(data);
      dispatch({ type: 'DEPARTMENTS', payload: data.data.departments });
      dispatch({ type: 'LOADING', payload: false })
    });
 socket.on('listDocuments_' + user.session_id, function (data) {
      console.log(data);
      dispatch({ type: 'DOCUMENTS', payload: data.data.documents });
      dispatch({ type: 'LOADING', payload: false })
    });

    socket.on('listBranches_' + user.session_id, function (data) {
      console.log('liste branche',data);
      dispatch({ type: 'BRANCHES', payload: data.data.branches });
      dispatch({ type: 'LOADING', payload: false })
    });

    socket.on('listPositions_' + user.session_id, function (data) {
      console.log(data);
      dispatch({ type: 'POSITIONS', payload: data.data.positions });
      dispatch({ type: 'LOADING', payload: false })
    });

        socket.on('newPost_' + user.session_id, function (data) {
           if(data.status===200)   data.message = t("position_created");
            loadOpData(data);
        });

        socket.on('updatePost_' + user.session_id, function (data) {
           if(data.status===200)   data.message = t("position_updated");
            loadOpData(data);
        });
        socket.on('deletePost_' + user.session_id, function (data) {
           if(data.status===200)   data.message = t("position_deleted");
            loadOpData(data);
        });
    socket.on('listPost_' + user.session_id, function (data) {
        console.log('list recruitments',data.data)
      dispatch({ type: 'POSTS', payload: data.data.recruitments});
      dispatch({ type: 'PAGINATOR', payload: data.data.paginator});
      dispatch({ type: 'LOADING', payload: false })
    });

  socket.on('listPostNoPaginate_' + user.session_id, function (data) {
      dispatch({ type: 'POSTS', payload: data.data});
      dispatch({ type: 'LOADING', payload: false })
    });



// newsletters socket *************************************************************

        socket.on('listNewsletters_' + user.session_id, function (data) {
            dispatch({ type: 'NEWSLETTERS', payload: data.data.newsletters })
            dispatch({ type: 'LOADING', payload: false })
        });

        socket.on('newNewsletters_' + user.session_id, function (data) {
           if(data.status===200) data.message = t("newletter_programm");
            loadOpData(data);
        });

 socket.on('updateNewsletters_' + user.session_id, function (data) {
           if(data.status===200) data.message = t("newletter_updated");
            loadOpData(data);
        });

        socket.on('deleteNewsletters_' + user.session_id, function (data) {
          if(data.status===200) data.message = t("newletter_deleted");
            loadOpData(data);
        });

//  ************************************************************************************


// actualité socket *************************************************************

        socket.on('listNews_' + user.session_id, function (data) {
            dispatch({ type: 'NEWS', payload: data.data.news })
            dispatch({ type: 'LOADING', payload: false })
        });

        socket.on('newNews_' + user.session_id, function (data) {
          if(data.status===200)   data.message = t("actuality_added");
            loadOpData(data);
        });

 socket.on('updateNews_' + user.session_id, function (data) {
           if(data.status===200)   data.message = t("actuality_updated");
            loadOpData(data);
        });

        socket.on('deleteNews_' + user.session_id, function (data) {
           if(data.status===200)   data.message = t("actuality_deleted");
            loadOpData(data);
        });

//  ************************************************************************************


// Employee  socket *************************************************************

        socket.on('hiredEmployee_' + user.session_id, function (data) {
           if(data.status===200)   data.message = t("employee_hire");
            loadOpData(data);
        });
   socket.on('updateEmployee_' + user.session_id, function (data) {
       let user= JSON.parse(window.localStorage.getItem('glowhr_user'));
           user.user.first_time = false;
           window.localStorage.setItem('glowhr_user', JSON.stringify(user));
           if(data.data.user.first_time){
               localStorage.setItem('first_come'+user?.user?._id,'no')
           }
       dispatch({ type: 'LOADING', payload: false })
   });

// Notification  socket *************************************************************
      if(user && user.user) {
          socket.on('NewNotification_' + user?.user._id, function (datas) {
              let data = datas.data.notification;
              let use = user?.user;
              if (data.user === use._id) {
                  dispatch({type: 'NOTIFICATIONS', payload: datas.data.notifications})
                  notify({message: data.title, type: data.type});
                  addNotification({
                      title: data?.title,
                      onClick: () => {
                          history.push({pathname:data.link})
                           // data.link ? location.href = location.origin+data.link : "/"
                      },
                      message: data?.message,
                      theme: 'darkblue',
                      closeButton: t("close"),
                      native: true // when using native, your OS will handle theming.
                  });
                  music.play();
              }
          });
      }
        socket.on('listNotification_'+ user.session_id, function (data) {
                dispatch({ type: 'NOTIFICATIONS', payload: data.data.notifications})
            dispatch({ type: 'LOADING', payload: false })
        });
        socket.on('updateNotifications_'+ user.session_id, function () {
                dispatch({ type: 'LOADING', payload: false })
        });

//************************************************************************************

//Depart socket *************************************************************

socket.on('listDepart_' + user.session_id, function (data) {
    console.log('liste depart', data)
      dispatch({ type: 'DEPART', payload: data.data.departs})
      dispatch({ type: 'LOADING', payload: false })
    });

socket.on('newDepart_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("departure");
    loadOpData(data);
});

socket.on('updateDepart_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("departure_updated");
    loadOpData(data);
});

socket.on('updateDepartStatus_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("departure_confirmed");
    loadOpData(data);
});

socket.on('deleteDepart_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("departure_deleted");
    loadOpData(data);
});

//  ************************************************************************************

//Parameters socket *************************************************************

socket.on('listParameters_' + user.session_id, function (data) {
    console.log('liste paiement parameters', data)
      dispatch({ type: 'PARAMETERS', payload: data.data.parameters})
      dispatch({ type: 'LOADING', payload: false })
    });

socket.on('updateParameters_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("params_updated");
    loadOpData(data);
});
//  ************************************************************************************


// formations socket *************************************************************

socket.on('listFormation_' + user.session_id, function (data) {
      dispatch({ type: 'FORMATIONS', payload: data.data.formations })
      dispatch({ type: 'LOADING', payload: false })
    });

        socket.on('userListFormation_' + user.session_id, function (data) {
            dispatch({ type: 'USERFORMATIONS', payload: data.data.formations })
            dispatch({ type: 'LOADING', payload: false })
        });

socket.on('newFormation_' + user.session_id, function (data) {
  if(data.status===200) {
  if(data.data && data.data.formation ) {
        data.data.formation.status==='waiting' ?
            data.message = t("formation_added")
            :
            data.message = t("formation_actived");
    }
  }
    loadOpData(data);
});

socket.on('changeFormationStatus_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("formation_status_updated");
    loadOpData(data);
});

socket.on('updateFormation_' + user.session_id, async function (data) {
       if(data.status===200)   data.message = t("formation_updated");
        loadOpData(data);
});

socket.on('deleteFormation_' + user.session_id, function (data) {
       if(data.status===200)   data.message = t("formation_deleted");
        loadOpData(data);
});

socket.on('acceptReference_' + user.session_id, function (data) {
       if(data.status===200)   data.message = t("reference_added");
        loadOpData(data);
});
socket.on('rejectReference_' + user.session_id, function (data) {
       if(data.status===200)   data.message = t("reference_rejected");
        loadOpData(data);
});

//  ************************************************************************************


// formations socket *************************************************************

socket.on('allListModuleFormation_' + user.session_id, function (data) {
     dispatch({ type: 'ALLMODULEFORMATIONS', payload: data.data.module_formations })
    });


socket.on('listModuleFormation_' + user.session_id, function (data) {
     dispatch({ type: 'MODULEFORMATIONS', payload: data.data.module_formations })
      dispatch({ type: 'LOADING', payload: false })
    });


socket.on('newModuleFormation_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("module_added");
    loadOpData(data);});

socket.on('userHasFinishModuleFormation_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("operation_added");
    loadOpData(data);});

socket.on('updateModuleFormation_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("module_updated");
    loadOpData(data);
});

socket.on('getModuleFormation_' + user.session_id, function (data) {
    dispatch({ type: 'GETMODULEFORMATION', payload: data.data.module_formation })
    dispatch({ type: 'LOADING', payload: false })
});

socket.on('deleteModuleFormation_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("module_deleted");
    loadOpData(data);
});

//  ************************************************************************************

// parcours formations socket *************************************************************

socket.on('listParcoursFormation_' + user.session_id, function (data) {
      dispatch({ type: 'PARCOURSFORMATIONS', payload: data.data.parcours_formations })
      dispatch({ type: 'LOADING', payload: false })
    });

socket.on('newParcoursFormation_' + user.session_id, function (data) {
  dispatch({ type: 'LOADING', payload: false })
});

socket.on('updateParcoursFormation_' + user.session_id, function (data) {
  dispatch({ type: 'LOADING', payload: false })
});

//  ************************************************************************************

// tests formations socket *************************************************************

socket.on('listTestsFormation_' + user.session_id, function (data) {
      dispatch({ type: 'TESTSFORMATIONS', payload: data.data.test_formation })
      dispatch({ type: 'LOADING', payload: false })
    });

socket.on('newTestsFormation_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("test_added");
    loadOpData(data);
});

socket.on('newTestsDoneFormation_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("test_save");
    loadOpData(data);
});

socket.on('updateTestsFormation_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("test_updated");
    loadOpData(data);});

socket.on('deleteTestsFormation_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("test_deleted");
    loadOpData(data);
});
//  ************************************************************************************

// event  socket *************************************************************

socket.on('listEvent_' + user.session_id, function (data) {
      dispatch({ type: 'EVENTS', payload: data.data.event_types })
      dispatch({ type: 'LOADING', payload: false })
    });
socket.on('filterEvent_' + user.session_id, function (data) {
    dispatch({ type: 'EVENTS', payload: data.data.event_types })
      dispatch({ type: 'LOADING', payload: false })
    });
socket.on('get_event_list_' + user.session_id, function (data) {
    dispatch({ type: 'MYEVENTS', payload: data.data.events })
    });


socket.on('newEvent_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("event_save");
    loadOpData(data);
});

socket.on('updateEvent_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("event_updated");
    loadOpData(data);});

socket.on('deleteEvent_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("event_deleted");
    loadOpData(data);
});
//  ************************************************************************************

// event type socket *************************************************************

socket.on('listEventType_' + user.session_id, function (data) {
      dispatch({ type: 'EVENTTYPES', payload: data.data.event_types })
      dispatch({ type: 'LOADING', payload: false })
    });

socket.on('newEventType_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("event_type_save");
    loadOpData(data);
});

socket.on('updateEventType_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("event_type_updated");
    loadOpData(data);});

socket.on('deleteEventType_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("event_type_deleted");
    loadOpData(data);
});
//  ************************************************************************************

// assign formations socket *************************************************************

socket.on('listAssignFormation_' + user.session_id, function (data) {
      dispatch({ type: 'ASSIGNFORMATIONS', payload: data.data.assigned })
      dispatch({ type: 'LOADING', payload: false })
    });

socket.on('newAssignFormation_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("assingation_done");
    loadOpData(data);
});

socket.on('deleteAssignFormation_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("assingation_deleted");
    loadOpData(data);});

//  ************************************************************************************

// survey socket *************************************************************

socket.on('listSurvey_' + user.session_id, function (data) {
    console.log('list surveys ',data)
      dispatch({ type: 'SURVEYS', payload: data.data.surveys })
      dispatch({ type: 'LOADING', payload: false })
    });
socket.on('oneSurvey_' + user.session_id, function (data) {
      dispatch({ type: 'SURVEY', payload: data.data.survey })
      dispatch({ type: 'QUESTIONS', payload: data.data.survey.questions })
      dispatch({ type: 'LOADING', payload: false })
    });

socket.on('newSurvey_' + user.session_id, function (data) {
  dispatch({ type: 'LOADING', payload: false })
   if(data.status===200)   data.message = t("survey_created");
    loadOpData(data);
});

socket.on('updateSurvey_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("survey_updated");
    loadOpData(data);
});
socket.on('deleteSurvey_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("survey_deleted");
    loadOpData(data);
});
socket.on('deleteSurveyQuestion_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("survey_question_deleted");
    loadOpData(data);
});

//  ************************************************************************************

// survey questions socket *************************************************************

socket.on('listSurveyQuestion_' + user.session_id, function (data) {
      dispatch({ type: 'SURVEYQUESTIONS', payload: data.data.survey_questions })
      dispatch({ type: 'LOADING', payload: false })
    });

socket.on('newSurveyQuestion_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("question_added");
    loadOpData(data);
});
socket.on('newPayroll_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("payroll_added");
    loadOpData(data);
});

socket.on('updateSurveyQuestion_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("question_updated");
    loadOpData(data);
});
socket.on('submitSurvey_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("response_added");
    loadOpData(data);
    Emitter.emit('submit-response-received',data.data);
});
socket.on('assignSurvey_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("survey_assigned");
    loadOpData(data);
});
socket.on('changeSurveyStatus_' + user.session_id, function (data) {
   if(data.status===200)   data.message = data.data.survey.status===1 ?
    t("survey_waiting"):
    t("survey_wait");
    loadOpData(data);
});
socket.on('reviveSurvey_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("rappel_assigned");
    loadOpData(data);
});
socket.on('newInterview_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("interview_programmed");
    loadOpData(data);
});
socket.on('cancelInterview_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("interview_cancelled");
    loadOpData(data);
});
socket.on('engageCandidate_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("application_approved");
    loadOpData(data);
});
socket.on('candidated_' + user.session_id, function (data) {
   if(data.status===200)   data.message = data.data.frmessage;
    loadOpData(data);
});
socket.on('rejectCandidate_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("application_reject");
    loadOpData(data);
});
socket.on('addParticipantToInterview_' + user.session_id, function (data) {
   if(data.status===200)   data.message = t("participant_added");
    loadOpData(data);
});

//  ************************************************************************************

    socket.on('listInterviews_' + user.session_id, function (data) {
      console.log("interviews ==> ",data);
      dispatch({ type: 'INTERVIEWS', payload: data.data.interviews })
    });

    socket.on('listPostApplicants_' + user.session_id, function (data) {
      console.log("Application ==> ",data);
      dispatch({ type: 'APPLICANTS', payload: data.data.applications })
      dispatch({ type: 'LOADING', payload: false });
    });

    socket.on('newReference_' + user.session_id, function (data) {
      console.log(data);
       if(data.status===200)   data.message = t("recommendation_added");
        loadOpData(data);
    });

    socket.on('listReferences_' + user.session_id, function (data) {
      // console.log("references list",data);
      dispatch({ type: 'REFERENCES', payload: data.data.references })
    });

    socket.on('listColleagues_' + user.session_id, function (data) {
        // console.log("colleagues",data.data.employees)
      dispatch({ type: 'COLLEAGUES', payload: data.data.employees })
      dispatch({ type: 'LOADING', payload: false });

    });
     socket.on('detailsColleague_' + user.session_id, function (data) {
      dispatch({ type: 'COLLEAGUE', payload: data.data.employee })
      dispatch({ type: 'LOADING', payload: false });
    });
    socket.on('listPayroll_' + user.session_id, function (data) {
        // console.log("list of payrolls",data)
        dispatch({ type: 'PAYROLL', payload: data.data.payrolls })
        dispatch({ type: 'LOADING', payload: false });
    });
    socket.on('Payroll_' + user.session_id, function (data) {
        // console.log("payroll",data)
        // dispatch({ type: 'PAYR', payload: data.data.payroll })
        dispatch({ type: 'LOADING', payload: false });
    });
    socket.on('Expense_' + user.session_id, function (data) {
        // console.log("expense one",data)
        dispatch({ type: 'EXPENSE', payload: data.data.expense })
        dispatch({ type: 'LOADING', payload: false });
    });

    socket.on('organigram_' + user.session_id, function (data) {
      dispatch({ type: 'ORGANIGRAM', payload: data.data.organogram})
    });

    socket.on('myevents_' + user.session_id, function (data) {
       dispatch({ type: 'EVENTS', payload: data.data.events })
    });
    socket.on('newLeaveType_' + user.session_id, function (data) {
      console.log(data);
       if(data.status===200)   data.message = t("holiday_added");
        loadOpData(data);
    });
    socket.on('updateLeaveType_' + user.session_id, function (data) {
      console.log("update leav result",data);
       if(data.status===200)   data.message = t("holiday_updated");
        loadOpData(data);
    });
   socket.on('updateAskLeave_' + user.session_id, function (data) {
       if(data.status===200)   data.message = t("updated_done");
        loadOpData(data);
    });
    socket.on('updateSettings_' + user.session_id, function (data) {
      console.log("update setting payrool",data);
       if(data.status===200)   data.message = t("params_updated_");
        loadOpData(data);
    });
    socket.on('deleteLeaveType_' + user.session_id, function (data) {
      console.log("delete leav result",data);
       if(data.status===200)   data.message = t("holiday_deleted");
        loadOpData(data);
    });
    socket.on('deleteSetting_' + user.session_id, function (data) {
      console.log("delete settings result",data);
       if(data.status===200)   data.message = t("params_deleted");
        loadOpData(data);
    });

    socket.on('listLeaveType_' + user.session_id, function (data) {
      console.log("list of leaves type",data)
      dispatch({ type: 'LEAVETYPES', payload: data.data.leave_types })
      dispatch({ type: 'LOADING', payload: false });

    });
    socket.on('listSettings_' + user.session_id, function (data) {
      console.log("list of setings payrool ",data)
      dispatch({ type: 'SETTINGS', payload: data.data.settings })
      dispatch({ type: 'LOADING', payload: false });

    });

    socket.on('newAskLeave_' + user.session_id, function (data) {
      console.log(data);
     if(data.status===200)   data.message = t("leave_added");
      loadOpData(data);
    });
    socket.on('newSetting_' + user.session_id, function (data) {
      console.log(data);
     if(data.status===200)   data.message = t("params_added");
      loadOpData(data);
    });

    socket.on('listLeave_' + user.session_id, function (data) {
      dispatch({ type: 'ASKINGLIVES', payload: data.data.leaves })
    });
    socket.on('listMyLeave_' + user.session_id, function (data) {
      dispatch({ type: 'MYLEAVES', payload: data.data.leaves })
    });
    socket.on('approveLeave_' + user.session_id, function (data) {
      let msg = "";
      switch (data.data.leave.status) {
          case -1:
              msg = t("leave_reject");
              break;
          case 1:
              msg = t("leave_approved");
              break;
          case -2:
                  msg = t("leave_cancelled");
              break;
      }
       if(data.status===200)   data.message = msg;
        loadOpData(data);
    });

    socket.on('newSuggestion_' + user.session_id, function (data) {
      console.log(data);
      dispatch({ type: 'LOADING', payload: false })
       if(data.status===200)   data.message = t("suggestion_added");
        loadOpData(data)
    });
    socket.on('deleteSuggestion_' + user.session_id, function (data) {
      dispatch({ type: 'LOADING', payload: false })
       if(data.status===200)   data.message = t("suggestion_deleted");
        loadOpData(data)
    });
    socket.on('assignSuggestion_' + user.session_id, function (data) {
      console.log(data);
     if(data.status===200)   data.message = t("suggestion_assigned");
        loadOpData(data);
    });
    socket.on('updateSuggestion_' + user.session_id, function (data) {
      console.log(data);
     if(data.status===200)   data.message = t("suggestion_status_updated");
        loadOpData(data);
    });
    socket.on('newSuggestionComment_' + user.session_id, function (data) {
       if(data.status===200)   data.message = t("comments_posted");
            dispatch({ type: 'LOADING', payload: false })
        });
    socket.on('deleteSuggestionComment_' + user.session_id, function (data) {
       if(data.status===200)   data.message = t("comments_deleted");
            dispatch({ type: 'LOADING', payload: false })
        });

    socket.on('listSuggestions_' + user.session_id, function (data) {
      console.log("les sugges ==> ",data);
        dispatch({ type: 'SUGGESTIONS', payload: data.data.suggestions });
        dispatch({ type: 'LOADING', payload: false })
    });
    socket.on('zulip_url_' + user.session_id, function (data) {
      console.log(data);
        dispatch({ type: 'ZULIP_URL', payload: data.data.zulip_url });
        dispatch({ type: 'LOADING', payload: false })
    });

    socket.on('listMySuggestions_' + user.session_id, function (data) {
      console.log(data);
      dispatch({ type: 'MY_SUGGESTIONS', payload: data.data.suggestions })
    });

    socket.on('listRoles_' + user.session_id, function (data) {
      dispatch({ type: 'ROLES', payload: data.data.roles })
      dispatch({ type: 'LOADING', payload: false })
    });

    socket.on('newColleague_' + user.session_id, function(data) {
       if(data.status===200)   data.message = t("invitation_added");
        loadOpData(data);
    });
   socket.on('linkToColleague_' + user.session_id, function(data) {
       if(data.status===200)   data.message = t("invitation_sent");
       loadOpData(data);
    });
    socket.on('posteChangeState_' + user.session_id, function(data) {
       if(data.status===200)   data.message = t("advertisement_status_updated");
        loadOpData(data);
    });
    socket.on('changeBirthdayStatus_' + user.session_id, function(data) {
       if(data.status===200)   data.message = t("birthday_visibility_updated");
        if(data.status===200){
            let connected_user = JSON.parse(window.localStorage.getItem('glowhr_user'));
            connected_user.user.birthday_visible = !connected_user.user.birthday_visible;
            localStorage.setItem('glowhr_user',JSON.stringify(connected_user));

        }
        loadOpData(data);
    });

    socket.on('updateColleague_' + user.session_id, function(data) {
      console.log(data);
       if(data.status===200)   data.message = t("employee_updated");
        loadOpData(data);
    });

      socket.on('newExpense_' + user.session_id, function(data) {
          console.log('expense added ',data);
         if(data.status===200)   data.message = t("expense_added");
          loadOpData(data)
      });

    socket.on('updateExpense_' + user.session_id, function(data) {
          console.log('expense updated ',data);
         if(data.status===200)   data.message = t("expense_updated");
          loadOpData(data)
      });
    socket.on('deleteExpenses_' + user.session_id, function(data) {
          console.log('expense deleted ',data);
         if(data.status===200)   data.message = t("expense_deleted");
          loadOpData(data)
      });
    socket.on('validExpenses_' + user.session_id, function(data) {
          console.log('expense valid ',data);
         if(data.status===200)   data.message = t("expense_validate");
          loadOpData(data)
      });
      socket.on('listExpenses_' + user.session_id, function (data) {
          console.log(" expenses list ==> ",data);
          dispatch({ type: 'EXPENSES', payload: data.data.expenses });
          dispatch({ type: 'LOADING', payload: false })
      });
    socket.on('newTypeDepense_' + user.session_id, function (data) {
      dispatch({ type: 'LOADING', payload: false });
      console.log(data);
       if(data.status===200)   data.message = t("expense_type_added");
        loadOpData(data)
    });
    socket.on('deleteExpenseType_' + user.session_id, function (data) {
      dispatch({ type: 'LOADING', payload: false });
      console.log(data);
       if(data.status===200)   data.message = t("expense_type_deleted");
        loadOpData(data)
    });
    socket.on('deletedepartement_' + user.session_id, function (data) {
      dispatch({ type: 'LOADING', payload: false });
      console.log(data);
       if(data.status===200)   data.message = t("department_deleted");
        loadOpData(data)
    });
    socket.on('updatedepartement_' + user.session_id, function (data) {
      dispatch({ type: 'LOADING', payload: false });
      console.log(data);
       if(data.status===200)   data.message = t("department_updated");
        loadOpData(data)
    });
   socket.on('deletedocument_' + user.session_id, function (data) {
      dispatch({ type: 'LOADING', payload: false });
      console.log(data);
       if(data.status===200)   data.message = data.data.frmessage;
        loadOpData(data)
    });
    socket.on('updatedocument_' + user.session_id, function (data) {
      dispatch({ type: 'LOADING', payload: false });
      console.log(data);
      if(data.status===200)   data.message = data.data.frmessage;
      loadOpData(data)
    });
    socket.on('updateTypeDepense_' + user.session_id, function (data) {
      dispatch({ type: 'LOADING', payload: false });
      console.log(data);
       if(data.status===200)   data.message = t("expense_type_updated");
        loadOpData(data)
    });
    socket.on('updatebranch_' + user.session_id, function (data) {
      dispatch({ type: 'LOADING', payload: false });
      console.log(data);
       if(data.status===200)   data.message = t("branch_updated");
        loadOpData(data)
    });
    socket.on('updateposition_' + user.session_id, function (data) {
      dispatch({ type: 'LOADING', payload: false });
      console.log(data);
       if(data.status===200)   data.message = t("position_updated_");
        loadOpData(data)
    });
    socket.on('deletebranch_' + user.session_id, function (data) {
      dispatch({ type: 'LOADING', payload: false });
      console.log(data);
       if(data.status===200)   data.message = t("branch_deleted");
        loadOpData(data)
    });
    socket.on('deletePosition_' + user.session_id, function (data) {
      dispatch({ type: 'LOADING', payload: false });
      console.log(data);
       if(data.status===200)   data.message = t("position_deleted_");
        loadOpData(data)
    });
    socket.on('listTypeDepense_' + user.session_id, function (data) {
      console.log('Liste type depense',data);
      dispatch({ type: 'TYPE_DEPENSE', payload: data.data.expensetypes});
      dispatch({ type: 'LOADING', payload: false })
    });

      socket.on('recruitmentApplication_'+localStorage.getItem("recruitment_id"), function(data) {
          Emitter.emit('application-res-received',data);
          dispatch({ type: 'APPLICATION_RECRUITMENT', payload: false })
      });
      socket.on('applicantApplication_'+localStorage.getItem("recruitment_id"), function(data) {
          console.log("recruitmentApplication",data);
          Emitter.emit('applicant-res-received',data);
          dispatch({ type: 'APPLICATION_RECRUITMENT', payload: false })
      });
        socket.on('listGoals_' + user.session_id, function (data) {
            dispatch({ type: 'GOALS', payload: data.data.goals });
            dispatch({ type: 'LOADING', payload: false });
        });
        socket.on('oneGoal_' + user.session_id, function (data) {
            dispatch({ type: 'GOAL', payload: data.data.goal });
            dispatch({ type: 'LOADING', payload: false });
        });
        socket.on('newGoal_' + user.session_id, function (data) {
            dispatch({ type: 'LOADING', payload: false });
           if(data.status===200)   data.message = t("goal_added");
            loadOpData(data)
        });
        socket.on('updateGoal_' + user.session_id, function (data) {
            dispatch({ type: 'LOADING', payload: false });
           if(data.status===200)   data.message = t("goal_updated");
            loadOpData(data)
        });
        socket.on('deleteGoal_' + user.session_id, function (data) {
            dispatch({ type: 'LOADING', payload: false });
           if(data.status===200)   data.message = t("goal_deleted");
            loadOpData(data)
        });
        socket.on('removeGoalEmployee_' + user.session_id, function (data) {
            dispatch({ type: 'LOADING', payload: false });
           if(data.status===200)   data.message = t("employee_removed");
            loadOpData(data)
        });
        socket.on('assignGoal_' + user.session_id, function (data) {
            dispatch({ type: 'LOADING', payload: false });
           if(data.status===200)   data.message = t("employee_assigned");
            loadOpData(data)
        });
        socket.on('appreciateGoal_' + user.session_id, function (data) {
            dispatch({ type: 'LOADING', payload: false });
           if(data.status===200)   data.message = t("employee_noted");
            loadOpData(data)
        });
        socket.on('newPlanAmelioration_' + user.session_id, function (data) {
            dispatch({ type: 'LOADING', payload: false });
           if(data.status===200)   data.message = t("plan_created");
            loadOpData(data)
        });
        socket.on('updatePlanAmelioration_' + user.session_id, function (data) {
            dispatch({ type: 'LOADING', payload: false });
           if(data.status===200)   data.message = t("plan_updated");
            loadOpData(data)
        });
        socket.on('changePlanAmeliorationState_' + user.session_id, function (data) {
            dispatch({ type: 'LOADING', payload: false });
           if(data.status===200)   data.message = t("plan_updated");
            loadOpData(data)
        });
        socket.on('deletePlanAmelioration_' + user.session_id, function (data) {
            dispatch({ type: 'LOADING', payload: false });
           if(data.status===200)   data.message = t("plan_deleted");
            loadOpData(data)
        });
        socket.on('newAvertissment_' + user.session_id, function (data) {
            dispatch({ type: 'LOADING', payload: false });
           if(data.status===200)   data.message = t("warning_created");
            loadOpData(data)
        });
        socket.on('updateAvertissment_' + user.session_id, function (data) {
            dispatch({ type: 'LOADING', payload: false });
           if(data.status===200)   data.message = t("warning_updated");
            loadOpData(data)
        });
        socket.on('changeAvertissmentState_' + user.session_id, function (data) {
            dispatch({ type: 'LOADING', payload: false });
           if(data.status===200)   data.message = t("warning_updated");
            loadOpData(data)
        });
        socket.on('deleteAvertissment_' + user.session_id, function (data) {
            dispatch({ type: 'LOADING', payload: false });
           if(data.status===200)   data.message = "Avertissemnt supprimé avec succès";
            loadOpData(data)
        });
        socket.on('listRoles_' + user.session_id, function (data) {
            console.log(data);
            dispatch({ type: 'ROLES', payload: data.data.roles })
            dispatch({ type: 'LOADING', payload: false })
        });
        socket.on('listAvertissments_' + user.session_id, function (data) {
            console.log(data);
            dispatch({ type: 'AVERTISSMENTS', payload: data.data.avertissments })
            dispatch({ type: 'LOADING', payload: false })
        });
        socket.on('listPlanAmeliorations_' + user.session_id, function (data) {
            dispatch({ type: 'PLAN_AMELIORATIONS', payload: data.data.planAmeliorations });
            dispatch({ type: 'LOADING', payload: false })
        });
        socket.on('listVisioConf_' + user.session_id, function (data) {
            dispatch({ type: 'VISIO_CONFS', payload: data.data.visio_confs });
            dispatch({ type: 'LOADING', payload: false })
        });
        socket.on('oneVisioConf_' + user.session_id, function (data) {
            console.log("data ==> ",data)
            dispatch({ type: 'VISIO_CONF', payload: data.data.visio_conf });
            dispatch({ type: 'LOADING', payload: false })
        });
        socket.on('newVisioConf_' + user.session_id, function(data) {
           if(data.status===200)   data.message = t("visio_program");
            loadOpData(data)
        });
        socket.on('updateVisioConf_' + user.session_id, function(data) {
           if(data.status===200)   data.message = t("visio_program_updated");
            loadOpData(data)
        });
        socket.on('deleteVisioConf_' + user.session_id, function(data) {
           if(data.status===200)   data.message = t("visio_deleted");
            loadOpData(data)
        });
    socket.on('disconnect', function () {
      console.log('socket disconnected')
    });
    
  }, []);
  return (
      <div id={'SocketContainer'}>
          real time
          <ToastContainer />
      </div>



      )

};

export default connect(mapStateToProps, mapDispatchToProps)(SocketContainer);
