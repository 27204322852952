import React, { useState } from 'react';
import './MainMenu.css';
import SideMenu from "../SideMenu";
import { NavLink } from "react-router-dom";
import menuIconSvg from '../../../assets/dash-logo.svg'
import { Badge, OverlayTrigger, Button, Tooltip } from "react-bootstrap";
import homeIcon from "../../../assets/menu/home.svg"
import profileIcon from "../../../assets/menu/profile.svg"
import docsIcon from "../../../assets/menu/documents.svg"
import datesIcon from "../../../assets/menu/calendar.svg"
import colleagueIcon from "../../../assets/menu/colleagues.svg"
import orgaIcon from "../../../assets/menu/organigramme.svg"
import inoutIcon from "../../../assets/menu/inout.svg"
import commIcon from "../../../assets/menu/communication.svg"
import holidayIcon from "../../../assets/menu/holidays.svg"
import recruitIcon from "../../../assets/menu/recruit.svg"
import formIcon from "../../../assets/menu/formation.svg"
import payrollIcon from "../../../assets/menu/payroll.svg"
import payIcon from "../../../assets/menu/pay.svg"
import analyticsIcon from "../../../assets/menu/analytics.svg"
import sondIcon from "../../../assets/menu/sondage.svg"
import suggIcon from "../../../assets/menu/suggestions.svg"
import perfIcon from "../../../assets/menu/performance.svg"
import settingsSvg from "../../../assets/svg/settings.svg";
import confIcon from "../../../assets/menu/organigramme.svg"
import { useTranslation } from 'react-i18next';

const MainMenu = (props) => {
    const { t } = useTranslation();
    const mainMenu = [
        { label: t('home'), route: "/dash", icon: homeIcon, role: ["Administrator", "Manager", "Employee", "RH"], hoverIcon: "", external: null },
        // {label: "BrancheInit / Dpartements / Postes", route: "/dash/branches", icon: "icon-logo.svg", hoverIcon: "",external:null},
        { label: t('employee_profil'), route: "/dash/profile", role: ["Administrator", "Manager", "Employee", "RH"], icon: profileIcon, hoverIcon: "", external: null },
        { label: t('company'), route: "/dash/company", role: ["Administrator",  "RH"], icon: profileIcon, hoverIcon: "", external: null },
        { label: t('documents'), route: "/dash/documents", role: ["Administrator", "Manager", "Employee", "RH"], icon: docsIcon, hoverIcon: "", external: null },
        { label: t('calendar'), route: "/dash/calendar", role: ["Administrator", "Manager", "Employee", "RH"], icon: datesIcon, hoverIcon: "", external: null },
        // {label: "Calendrier", route: "https://nextcloud-dev.glow-hr.com/apps/calendar", icon: "icon-logo.svg", hoverIcon: "",external:true},
        { label: t("colleagues"), route: "/dash/colleagues", role: ["Administrator", "Manager","Employee", "RH"], icon: colleagueIcon, hoverIcon: "", external: null },
        { label: t('organization_chart'), route: "/dash/hierarchy", role: ["Administrator", "Manager", "RH","Employee"], icon: orgaIcon, hoverIcon: "", external: null },
        { label: t('integration_departure'), route: "/dash/inout", role: ["Administrator", "Manager"], icon: inoutIcon, hoverIcon: "", external: null },
        { label: t("communication"), route: "/dash/communications", role: ["Administrator", "Manager", "RH","Employee"], icon: commIcon, hoverIcon: "", external: null },
         {label: t('visio_conf'), route: "/dash/conference", role: ["Administrator", "Manager", "RH","Employee"], icon: confIcon, hoverIcon: "",external:null},
        { label: t('holidays'), route: "/dash/holidays", role: ["Administrator", "Manager", "RH","Employee"], icon: holidayIcon, hoverIcon: "", external: null },
         {label: t('performs'), route: "/dash/performance",role: ["Administrator",  "RH"], icon: perfIcon, hoverIcon: "",external:null},
         {label: t('performs'), route: "/dash/discipline",role: ["Manager","Employee"], icon: perfIcon, hoverIcon: "",external:null},
        { label: t('recruitments'), route: "/dash/recruit", role: ["Administrator", "Manager", "RH","Employee"], icon: recruitIcon, hoverIcon: "", external: null },
        { label: t('formation'), route: "/dash/courses", role: ["Administrator", "Manager", "RH","Employee"], icon: formIcon, hoverIcon: "", external: null },
        { label: t('payment'), route: "/dash/payments", role: ["Administrator"], icon: payIcon, hoverIcon: "", external: null },
        { label: t("payroll"), route: "/dash/payroll", role: ["Administrator", "Manager", "RH","Employee"], icon: payrollIcon, hoverIcon: "", external: null },
        { label: t('analytics'), route: "/dash/analytics", role: ["Administrator", "RH"], icon: analyticsIcon, hoverIcon: "", external: null },
        { label: t('survey'), route: "/dash/surveys", role:  ["Administrator", "Manager", "RH","Employee"], icon: sondIcon, hoverIcon: "", external: null },
        // {label: "Migration", route: "/dash/migrations", icon: "icon-logo.svg", hoverIcon: "",external:null},
        // {label: "Team Building", route: "/dash/teams", icon: "icon-logo.svg", hoverIcon: "",external:null},
        { label: t('suggestion'), route: "/dash/suggestions", role: ["Administrator","Employee","Manager","RH"], icon: suggIcon, hoverIcon: "", external: null },
        // {label: "Informations générales", route: "/dash/infos", icon: "icon-logo.svg", hoverIcon: "",external:null},
        {label: t('settings'), route: "/dash/general/settings", role: ["Administrator","RH"],icon: settingsSvg, hoverIcon: "",external:null},
        // {label: "Support technique", route: "/dash/help", icon: "icon-logo.svg", hoverIcon: "",external:null},
    ]

    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name
    const [showMenu, setShowMenu] = useState(false)
    const handleClick = () => {
        props.toggleMenu(!props.menuState)
    }


    return <div id={'MainMenu'}>

        <div className="row" style={{height:'100%',overflow:'auto'}}>
            <div className="sidemenupostion d-none d-sm-block">

                {/* {showMenu ? <Button variant={"dark"} className={"shadow ml-2"} onClick={() => setShowMenu(false)}>x</Button> : <Button variant={"dark"} className={"shadow ml-2"} onClick={() => setShowMenu(true)}> + </Button>}

              
                {showMenu && <SideMenu toggleMenu={setShowMenu} menuState={showMenu} />} */}
                <SideMenu toggleMenu={setShowMenu} menuState={showMenu} />
            </div>

            <div className="col-12">
                <div className={"pt-3 pb-2"}>
                    <img src={menuIconSvg} style={{ width: "100%", height: "auto" }} alt={"logo"}/>
                </div>
                <ul className="nav nav-pills flex-column">
                    {mainMenu.map(m =>
                        m.role.includes(role) ? (<li className="nav-item" key={m.label}>
                            {m.external === true ? (
                                <a href={m.route} className={"nav-link d-flex"} target={"_blank"} onClick={handleClick}>
                                    <div className={'menuIcon'}>
                                        <OverlayTrigger
                                            key={'right'}
                                            placement={'right'}
                                            overlay={
                                                <Tooltip id={m.route}>
                                                    {m.label}
                                                </Tooltip>}>
                                            <img src={m.icon} alt="icon" width={22} height={22} />
                                        </OverlayTrigger>
                                    </div>
                                    <div className={'menuText  ml-3'}>
                                        {m.label}
                                        {/*<sup>*/}
                                        {/*    <Badge pill variant="danger">*/}
                                        {/*        {Math.floor(Math.random()*100)}*/}
                                        {/*    </Badge>*/}
                                        {/*</sup>*/}
                                    </div>
                                </a>
                            ) : (
                                    <NavLink exact to={m.route} className="nav-link d-flex" onClick={handleClick}>
                                        <div className={'menuIcon'}>
                                            <OverlayTrigger
                                                key={'right'}
                                                placement={'right'}
                                                overlay={
                                                    <Tooltip id={m.route}>
                                                        {m.label}
                                                    </Tooltip>}>
                                                <img src={m.icon} alt="icon" width={22} height={22} />
                                            </OverlayTrigger>
                                        </div>
                                        <div className={'menuText  ml-3'}>
                                            {m.label}
                                            {/*<sup>*/}
                                            {/*    <Badge pill variant="danger">*/}
                                            {/*        {Math.floor(Math.random()*100)}*/}
                                            {/*    </Badge>*/}
                                            {/*</sup>*/}
                                        </div>
                                    </NavLink>
                                )}
                        </li>) : null


                    )}
                </ul>
            </div>
            <div className="col-2">
                <Button variant={"dark"} className={"shadow mr-1 d-block d-sm-none"} onClick={() => props.toggleMenu(false)}>x</Button>
            </div>
        </div>
    </div>;
};

export default MainMenu;
