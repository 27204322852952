import React, {useEffect, useState} from 'react';
import "./PayrollDetails.css"
import { Button, Col, Container, Row} from "react-bootstrap";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import mSVG from "../../../assets/gender/m.png";
import {useParams} from 'react-router-dom'
import { useHistory } from "react-router-dom";
import Printer, { print } from 'react-pdf-print'
import { NumberToLetter } from 'convertir-nombre-lettre';
import * as RI from "react-icons/ri";
import * as BI from "react-icons/bi";
import * as MD from "react-icons/md";
import {getFileUrl} from "../../../service";
import { useTranslation } from 'react-i18next';



const mapStateToProps = (state) => {
    return {rwState: state}
}
const renderIndice=(q)=>{
    if(q && q.indice ==="Pourcentage"){
        return "%"
    }else{
        return "F CFA"
    }
};
const renderPosition=(q)=>{
    if(q && q.position ){
        return q.position.title
    }else{
        return "Administrateur"
    }
};
const ids = ['1'];

const mapDispatchToProps = {...actions}


const PayrollDetails = (props) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();

    const [ppImage,setPpImage]=useState(mSVG)
    const [company,setCompany]=useState(mSVG)

    useEffect(() => {
        props.action_Payroll(id);
        let data = JSON.parse(window.localStorage.getItem('glowhr_user'));
        setPpImage(data?.user.image_url.length>0?getFileUrl(data?.user.image_url):ppImage)
        let company = JSON.parse(window.localStorage.getItem('glowhr_user')).user.company;
        setCompany(company);
        props.action_setTitle(t("payroll_details_title"))
    }, []);
    return <div id={"PayrollDetails"}>
        <Container className='App' fluid>
            <Col sm={"12"} className={'text-left'}>
                <Button className={"btn btn-light btn-sm"}
                        onClick={e => history.push({pathname:'/dash/payroll_list/'+props.rwState.payroll?.employee._id})}>
                    <BI.BiChevronLeft style={{color: '#240265', fontWeight: '600',}} size={25}/> {t("return")}
                </Button>
            </Col>
            <div className={"btn btn-light btn-sm"}  onClick={() => print(ids)}>
                <MD.MdFileDownload />
               <span style={{textDecoration:'none black',marginRight:"10px"}}>{t("download")}</span>
            </div>
            <Printer>
            <Row id={ids[0]} style={{ width:'210mm', height: '297mm' }}>
                <Col>
                    <div className="d-flex align-content-center justify-content-between">

                    </div>
                    <div style={{marginTop:"4%"}} className="row">
                        <div className="col-md-12">
                            <div className="card" style={{border:'none'}}>
                                <div className="card-body">
                                    <h4  className="payslip-title text-center col-12 mb-4"> {t("payroll_paid_slip_title")} {props.rwState.payroll?.mois} {props.rwState.payroll?.annee}</h4>
                                    <div className="row">
                                        <div className="col-sm-7 m-b-20">
                                                <img src={ppImage} style={{width:"50px",height:"50px"}}/>&nbsp;&nbsp;

                                                   <strong>{props.rwState.payroll?.employee.lastname} {props.rwState.payroll?.employee.firstname}</strong>
                                            <br/>
                                            { company ?
                                                <ul  style={{marginTop:"4%"}} className="list-unstyled mb-0">
                                                    <li>{t("companie")} : {company.name}</li>
                                                    <li>{t("web_site")} : {company.website}</li>
                                                    <li>{t("country")} : {company.name}</li>
                                                </ul>
                                                : ""}
                                        </div>
                                        <div className="col-sm-5 m-b-20">
                                            <div style={{marginLeft:"40%"}} className="invoice-details">
                                                <h5  className="text-uppercase">{t("payroll_paid_slip_title_thin")} </h5>
                                                <ul className="list-unstyled">
                                                    <li>{t("payroll_salary_paid")} : <span>{props.rwState.payroll?.mois}, {props.rwState.payroll?.annee}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div  style={{marginTop:"2%"}} className="row">
                                        <div className="col-lg-12 m-b-20">


                                            <ul className="list-unstyled">
                                                <li className="mb-1"><span>{t("function")}: {renderPosition(props.rwState.payroll?.employee)}</span></li>
                                                <li className="mb-1">{t("tel")}: {props.rwState.payroll?.employee.mobile}</li>
                                                <li className="mb-1">{t("payroll_payment_date")}: </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div>
                                                <h4 className="m-b-10"><strong>{t("payroll_premiums")}</strong></h4>
                                                <table className="table table-bordered">
                                                    <tbody>
                                                    <tr>
                                                        {
                                                            props.rwState.payroll?.primes.map((q)=>{
                                                                return   (
                                                                    <div style={{border:"0.2px solid #ccc",padding:"10px"}} >
                                                                            <strong>{q.libelle}</strong>
                                                                            <span className="float-right">{q.value} {renderIndice(q)}</span>
                                                                    <br/>
                                                                    </div>

                                                                )
                                                            })
                                                        }

                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div>
                                                <h4 className="m-b-10"><strong>{t("payroll_taxes")}</strong></h4>
                                                <table className="table table-bordered">
                                                    <tbody>
                                                    <tr>
                                                        {
                                                            props.rwState.payroll?.taxes.map((q)=>{
                                                                return   (
                                                                    <div style={{border:"0.2px solid #ccc",padding:"10px"}}>
                                                                            <strong>{q.libelle}</strong>
                                                                            <span className="float-right">{q.value} {renderIndice(q)}</span>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <p><strong>{t("payroll_net_salary")} :  {props.rwState.payroll?.salaire_net} F CFA
                                            </strong>
                                                {props.rwState.payroll?.salaire_net ?
                                                 (NumberToLetter(parseInt(props.rwState.payroll?.salaire_net)))
                                                :  "" }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            </Printer>
        </Container>


    </div>
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollDetails);
