import React, {useEffect, useState} from 'react';
import "./Formations.css"

import {Tab, Nav, Form, Modal, Button, Col, Container, Row, Card, Spinner, Badge} from "react-bootstrap";
import ContextMenu from "../../menus/ContextMenu";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import {isAdmin,
    handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
import SingleFormation from "../../widgets/SingleFormation/SingleFormation";
import Select from "react-select";
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}
function Items({ currentItems }) {
    return (
        <>
            {currentItems &&
            currentItems.map((item) => (
                <div>
                    <h3>Item #{item}</h3>
                </div>
            ))}
        </>
    );
}


const Formations = (props) => {
    const { t } = useTranslation();
    useEffect(() => {
        props.action_loading(true);
        props.action_listFormations();
        props.action_listColleagues()
        props.action_allListModuleFormations()
        props.action_listDepartments();
        props.action_listBranches();
        props.action_listPositions();
        props.action_listRoles();
        props.action_setTitle(t("formations_title"))
    }, []);
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    let user = JSON.parse(window.localStorage.getItem('glowhr_user')).user;
    const [show, setShow] = useState([])
    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const [current_row, setCurrentRow] = useState()
    const [isUpdate, setHasUpdate] = useState(false)
    const [isExternal, setIsExternal] = useState(false)
    const [externalLink, setExternalLink] = useState("")
    const [start, setStart] = useState()
    const [end, setEnd] = useState()
    const affectations_type =
        [{ label: t('all'), value: 'all' },
            { label: t("departments"), value: "departments"},
            { label: t('branches'), value: 'branches'},
            { label: t('positions'), value: 'positions'},
            { label: t('specific_employee'), value: 'employee'}
        ]
    const [affect, setAffect] = useState(affectations_type[0])
    const [assignTo, setAssignTo] = useState([])
    const [checkAssign, setCheckAssign] = useState(false)
    const [attendees_target_id, setAttendeesTargetId] = useState([])
    const [attendees_target, setAttendeesTarget] = useState()
    const [formError, setFormError] = useState({})

    const handleAffectation_type = (e) => {
        setAssignTo([]);
        setAffect(e)
        setAttendeesTarget(e.value)
        let data =handleValidation(t,{type:"text",name:'affectationType','value':e.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };

    const handleCheckAssign = () => {
           checkAssign ? setCheckAssign(false) : setCheckAssign(true);
    };
    const handleSelectAssign = (e) => {
        if(e) {
            setAssignTo(e)
            setAttendeesTargetId(e.filter(res => {
                return res._id
            }))
        }else{
            setAttendeesTargetId([])
        }
    };
    const checkUserHasFinish=(test_formation)=>{
        let resp =false;
        if(test_formation){
            if( test_formation.test_finisher_info && test_formation.test_finisher_info.length>0){
                test_formation.test_finisher_info.forEach(response=>{
                    let check=  response[test_formation.assign_to.find(p=>p.employee_info.user===user._id)?.employee_info._id]
                    if(check){ resp=true; return; }
                })
            }
        }
        return resp;
    }

   const checkFinishFormations=(test_formations)=>{
       let resp =[],checkHas=false;
       test_formations.map(test_formation=>{
        if(test_formation){
            if( test_formation.test_finisher_info && test_formation.test_finisher_info.length>0){
                test_formation.test_finisher_info.forEach(response=>{
                    let check=  response[test_formation.assign_to.find(p=>p.employee_info && p.employee_info.user && p.employee_info.user===user._id)?.employee_info?._id]
                    if(check) checkHas=true;
                })
            }
            if(test_formation.assign_to) {
                let check=  test_formation.assign_to.find(p=>p.employee_info && p.employee_info.user && p.employee_info.user===user._id)?.employee_info?._id
                if (checkHas && check && test_formation.status==="active") resp.push(test_formation)
            }
        }
        })
        return resp;
    }

    const checkNotBeginFormations=(test_formations)=>{
       let resp =[],checkHas=true;
       test_formations.map(test_formation=>{
        if(test_formation){
            if( test_formation.test_finisher_info){
                test_formation.test_finisher_info.forEach(response=>{
                    let check=  response[test_formation.assign_to.find(p=>p.employee_info && p.employee_info.user && p.employee_info.user===user._id)?.employee_info?._id]
                    if(check)  checkHas=false;
                })
            }
            if(test_formation.assign_to) {
                let check=  test_formation.assign_to.find(p=>p.employee_info && p.employee_info.user && p.employee_info.user===user._id)?.employee_info?._id
                if (checkHas && check && test_formation.status==="active") resp.push(test_formation)
            }
        }

        })
        return resp;
    }

    const handleClose = () => {
        setShow(null)
        setFormError({})
    }

    const editFormations = (formation)=>{
        setCurrentRow(formation);
        setName(formation.name);
        setDescription(formation.description);
        setHasUpdate(true);
        setShow('formation_modal')
        let data=  handleFinaleValidation(t,[
            {type:"text",name:'name','value':formation.name,required:true}
           ])
                setFormError(data)
    };
    const resetFormations = ()=>{
        setCurrentRow({});
        setName("");
        setDescription("");
        setHasUpdate(false);
        setShow(null)
        setCheckAssign(false)
        setAffect(affectations_type[0])
        setStart("")
        setEnd("")
    };
    const createFormations = () => {
        let o = {
            "name": name,
            "description": description,
            "sio_channel": "",
            "id":current_row?.id,
            "attendees_target": attendees_target,
            "attendees_target_id": attendees_target_id,
            "start_date": start,
            "end_date": end,
        };
        if (!isUpdate)
            props.action_createFormation(o)
        else
            props.action_updateFormation(o);
        setShow(null)
        props.action_loading(true)
        resetFormations()
    };

    const handleShowDetails = (expense)=>{
        setShow('show_detail');
        setCurrentRow(expense);
    }
    const contextMenu =
        [
            {label: t("waiting"), eventKey: 'paie'},
            {label: t("finished"), eventKey: 'depense'},
        ]
    const contextMenuAdmin =
        [
            {label: t('actives'), eventKey: 'active'},
            {label: t("waiting"), eventKey: 'waiting'},
            {label: t("closed"), eventKey: 'finish'},
        ]
    const handleStart = (e) => {
        setStart(e.target.value)
        let data =handleValidation(t,{type:"date",name:'start','value':e.target.value,required:true,min:new Date()});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleEnd = (e) => {
        setEnd(e.target.value)
        let data =handleValidation(t,{type:"date",name:'end','value':e.target.value,required:true,min:start});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleExternal = (e) => {
        e==="external" ? setIsExternal(true) : setIsExternal(false);
    }
    const handleExternalLink = (e) => {
        let link=e.target.value;
        if(link.includes('https') || link.includes('http') || link.includes('www') ) {
            setExternalLink(link)
        }else{
            link='http://'+link
            setExternalLink(link)
        }
        let data =handleValidation(t,{type:"text",name:'externalLink','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleName = (e) => {
        setName(e.target.value)
        let data =handleValidation(t,{type:"text",name:'name','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }

    const handleDescriptionName = (e) => {
        setDescription(e.target.value)
    }

    const requestDataBuilder = () => (
        {
            "name": name,
            "description": description,
            "external":isExternal,
            "external_link":externalLink,
            "sio_channel": "",
            "attendees_target": attendees_target,
            "attendees_target_id": attendees_target_id,
            "start_date": start,
            "end_date": end,
        }
    )
    const editFormation = () => {
        let type_data = requestDataBuilder()
        type_data['id'] = current_row.id
        type_data['name'] = name
        type_data['description'] = description
        type_data['external'] = isExternal
        type_data['external_link'] = externalLink
        props.action_updateFormation(type_data)
        setShow(null)
        props.action_loading(true)
        setCurrentRow()
    }

    const createFormation= () => {
        let o = requestDataBuilder()
        props.action_createFormation(o)
        setShow(null)
        props.action_loading(true)
     }
    const proceed = () => {
        if(current_row?.id) {
            editFormation()
        } else {
            createFormation()
        }
        resetFormations();
        setFormError({})
    }

    const updateFormationType = row_data => {
        console.log('row => ', row_data)
        setCurrentRow(row_data)
        setShow('typedepense')
    }
    const deleteTypeFormation = row_data => {
        console.log('row => ', row_data)
        setCurrentRow(row_data)
        setShow('confirm')
    }
    const deleteFormationType = () => {
        let data = requestDataBuilder()
        data['id'] = current_row.id
        props.action_deleteFormationType(data)
        console.log('data', data)
        setShow(null)
        props.action_loading(true)
        setCurrentRow()
    }

    //******************************************paginate filter data */
    useEffect(() => {
        setActiveFormationList([])
        setWaitingFormationList([])
        setCloseFormationList([])
        setCheckNotBeginFormationsFormationList([])
        setCheckFinishFormationsFormationList([])
        if(props.rwState.formations && props.rwState.formations.length > 0 && props.rwState.formations.filter(re=>re.status==='active' || re.status==='en_cours').length > 0) {
            let mylist = props.rwState.formations.filter(re=>re.status==='active' || re.status==='en_cours');
            setActiveFormationList(mylist)
        }
        if(props.rwState.formations && props.rwState.formations.length > 0 && props.rwState.formations.filter(re=>re.status==='waiting').length > 0) {
            let mylist = props.rwState.formations.filter(re=>re.status==='waiting');
            setWaitingFormationList(mylist)
        }
       if(props.rwState.formations && props.rwState.formations.length > 0 && props.rwState.formations.filter(re=>re.status==='finish').length > 0) {
            let mylist = props.rwState.formations.filter(re=>re.status==='finish');
            setCloseFormationList(mylist)
        }
       if(props.rwState.formations && props.rwState.formations.length > 0 && checkNotBeginFormations(props.rwState.formations).length > 0) {
           let mylist = checkNotBeginFormations(props.rwState.formations);
            setCheckNotBeginFormationsFormationList(mylist)
        }
      if(props.rwState.formations && props.rwState.formations.length > 0 && checkFinishFormations(props.rwState.formations).length > 0) {
             let mylist = checkFinishFormations(props.rwState.formations);
            setCheckFinishFormationsFormationList(mylist)
        }
       }, [props.rwState.formations]);
   
    /*********************************************Formation Active Begin paginating */
      //Formation Active
      const [activeFormationList, setActiveFormationList] = useState(undefined);
      const [CurrentActiveLists, setCurrentActiveLists] = useState(undefined);
      const [activePageCount, setActivePageCount] = useState(0);
      const [activeItemOffset, setActiveItemOffset] = useState(0);
      const [activeItemsPerPage, setActiveItemsPerPage] = useState(8);

      useEffect(() => {
        if(activeFormationList) {
            const endOffset = activeItemOffset + activeItemsPerPage;
            setCurrentActiveLists(activeFormationList.slice(activeItemOffset, endOffset));
            setActivePageCount(Math.ceil(activeFormationList.length / activeItemsPerPage));
        }
       }, [activeItemOffset, activeItemsPerPage, activeFormationList]);
   
       const handlePageClick = (event) => {
        const newOffset = (event.selected * activeItemsPerPage) % activeFormationList.length;
        setActiveItemOffset(newOffset);
    };
    //*********************************************Formation Active End paginating */

  /*********************************************Formation Waiting Begin paginating */
      //Formation Active
      const [waitingFormationList, setWaitingFormationList] = useState(undefined);
      const [CurrentWaitingLists, setCurrentWaitingLists] = useState(undefined);
      const [waitingPageCount, setWaitingPageCount] = useState(0);
      const [waitingItemOffset, setWaitingItemOffset] = useState(0);
      const [waitingItemsPerPage, setWaitingItemsPerPage] = useState(8);

      useEffect(() => {
        if(waitingFormationList ) {
            const endOffset = waitingItemOffset + waitingItemsPerPage;
            setCurrentWaitingLists(waitingFormationList.slice(waitingItemOffset, endOffset));
            setWaitingPageCount(Math.ceil(waitingFormationList.length / waitingItemsPerPage));
        }
       }, [waitingItemOffset, waitingItemsPerPage, waitingFormationList]);
   
       const handleWaitingPageClick = (event) => {
        const newOffset = (event.selected * waitingItemsPerPage) % waitingFormationList.length;
        setWaitingItemOffset(newOffset);
    };
    //*********************************************Formation Waiting End paginating */

   //*********************************************Formation Close Begin paginating */
      //Formation Close
      const [closeFormationList, setCloseFormationList] = useState(undefined);
      const [CurrentCloseLists, setCurrentCloseLists] = useState(undefined);
      const [closePageCount, setClosePageCount] = useState(0);
      const [closeItemOffset, setCloseItemOffset] = useState(0);
      const [closeItemsPerPage, setCloseItemsPerPage] = useState(8);

      useEffect(() => {
        if(closeFormationList) {
            const endOffset = closeItemOffset + closeItemsPerPage;
            setCurrentCloseLists(closeFormationList.slice(closeItemOffset, endOffset));
            setClosePageCount(Math.ceil(closeFormationList.length / closeItemsPerPage));
        }
       }, [closeItemOffset, closeItemsPerPage, closeFormationList]);
   
       const handleClosePageClick = (event) => {
        const newOffset = (event.selected * closeItemsPerPage) % closeFormationList.length;
        setCloseItemOffset(newOffset);
    };
    //*********************************************Formation Close End paginating */

   //*********************************************Formation Employee Start Begin paginating */
      //Formation checkNotBeginFormations
      const [checkNotBeginFormationsFormationList, setCheckNotBeginFormationsFormationList] = useState(undefined);
      const [CurrentCheckNotBeginFormationsLists, setCurrentCheckNotBeginFormationsLists] = useState(undefined);
      const [checkNotBeginFormationsPageCount, setCheckNotBeginFormationsPageCount] = useState(0);
      const [checkNotBeginFormationsItemOffset, setCheckNotBeginFormationsItemOffset] = useState(0);
      const [checkNotBeginFormationsItemsPerPage, setCheckNotBeginFormationsItemsPerPage] = useState(8);

      useEffect(() => {
        if(checkNotBeginFormationsFormationList) {
            const endOffset = checkNotBeginFormationsItemOffset + checkNotBeginFormationsItemsPerPage;
            setCurrentCheckNotBeginFormationsLists(checkNotBeginFormationsFormationList.slice(checkNotBeginFormationsItemOffset, endOffset));
            setCheckNotBeginFormationsPageCount(Math.ceil(checkNotBeginFormationsFormationList.length / checkNotBeginFormationsItemsPerPage));
        }
       }, [checkNotBeginFormationsItemOffset, checkNotBeginFormationsItemsPerPage, checkNotBeginFormationsFormationList]);
   
       const handleCheckNotBeginFormationsPageClick = (event) => {
        const newOffset = (event.selected * checkNotBeginFormationsItemsPerPage) % checkNotBeginFormationsFormationList.length;
        setCheckNotBeginFormationsItemOffset(newOffset);
    };
    //*********************************************Formation checkNotBeginFormations End paginating */

  //*********************************************Formation Employee Finish Begin paginating */
      //Formation checkFinishFormations
      const [checkFinishFormationsFormationList, setCheckFinishFormationsFormationList] = useState(undefined);
      const [CurrentCheckFinishFormationsLists, setCurrentCheckFinishFormationsLists] = useState(undefined);
      const [checkFinishFormationsPageCount, setCheckFinishFormationsPageCount] = useState(0);
      const [checkFinishFormationsItemOffset, setCheckFinishFormationsItemOffset] = useState(0);
      const [checkFinishFormationsItemsPerPage, setCheckFinishFormationsItemsPerPage] = useState(8);

      useEffect(() => {
        if(checkFinishFormationsFormationList ) {
            const endOffset = checkFinishFormationsItemOffset + checkFinishFormationsItemsPerPage;
            setCurrentCheckFinishFormationsLists(checkFinishFormationsFormationList.slice(checkFinishFormationsItemOffset, endOffset));
            setCheckFinishFormationsPageCount(Math.ceil(checkFinishFormationsFormationList.length / checkFinishFormationsItemsPerPage));
        }
       }, [checkFinishFormationsItemOffset, checkFinishFormationsItemsPerPage, checkFinishFormationsFormationList]);
   
       const handleCheckFinishFormationsPageClick = (event) => {
        const newOffset = (event.selected * checkFinishFormationsItemsPerPage) % checkFinishFormationsFormationList.length;
        setCheckFinishFormationsItemOffset(newOffset);
    };
    //*********************************************Formation checkFinishFormations End paginating */

    return <div id={"Formations"}>
        <Container fluid>
            <Row>
                {isAdmin(role) ?
                <Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={'active'}>
                        <div className="d-flex align-items-start justify-content-between">
                            <ContextMenu menu={contextMenuAdmin}/>
                            <Col>
                                <Button onClick={e=>setShow('create')} className={"float-right"}>{t("new_formation_title")}</Button>
                            </Col>
                        </div>
                        <Tab.Content>
                            <Tab.Pane className={"lastRollL"} eventKey="active">
                                <div className={"p-3 border-0"} style={{borderRadius:10}}>
                                    <Row>
                                    {  CurrentActiveLists && CurrentActiveLists.length > 0  ?
                                        CurrentActiveLists.map((p => {
                                       return <Col md={6}>
                                           <SingleFormation modules={props.rwState.all_module_formations && props.rwState.all_module_formations.filter(res=>res.formation_info && res.formation_info._id && res.formation_info._id===p._id)} employee={props.rwState.colleagues} formation={p}/>
                                           </Col>
                                     } ))
                                          :
                                                <h6 className={"col-12 alert alert-warning"}> {t("no_formation_founded")} </h6>
                                        }
                                        <Col md={12} className={"mt-3"}>
                                        <div hidden={!CurrentActiveLists || (CurrentActiveLists && CurrentActiveLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={activePageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div></Col>
                                    </Row>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className={"lastRollL"} eventKey="waiting">
                                <div className={"p-3 border-0"} style={{borderRadius:10}}>
                                    <Row>
                                    { ( CurrentWaitingLists && CurrentWaitingLists.length > 0  ?
                                        CurrentWaitingLists.map((p => {
                                            return <Col xs={"auto"} lg={6}> <SingleFormation employee={props.rwState.colleagues} modules={props.rwState.all_module_formations && props.rwState.all_module_formations.filter(res=>res.formation_info && res.formation_info._id && res.formation_info._id===p._id)} formation={p}/></Col>
                                        }))
                                            :
                                        <h6 className={"col-12 alert alert-warning"}> {t("no_formation_founded")} </h6>)
                                    }
                                     <Col md={12} className={"mt-3"}>
                                        <div hidden={!CurrentWaitingLists || (CurrentWaitingLists && CurrentWaitingLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleWaitingPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={waitingPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div></Col>
                                    </Row>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className={"lastRollL"} eventKey="finish">
                                <div className={"p-3 border-0"} style={{borderRadius:10}}>
                                    <Row>
                                    { ( CurrentCloseLists && CurrentCloseLists.length > 0  ?
                                        CurrentCloseLists.map((p => {
                                            return <Col xs={"auto"} lg={4}> <SingleFormation modules={props.rwState.all_module_formations && props.rwState.all_module_formations.filter(res=>res.formation_info && res.formation_info._id && res.formation_info._id===p._id)} employee={props.rwState.colleagues} formation={p}/></Col>
                                        }))
                                             :
                                        <h6 className={"col-12 alert alert-warning"}> {t("no_formation_founded")} </h6>)
                                    }
                                     <Col md={12} className={"mt-3"}>
                                        <div hidden={!CurrentCloseLists || (CurrentCloseLists && CurrentCloseLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleClosePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={closePageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div></Col>
                                    </Row>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Col>
                    :
                    <Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={'paie'}>
                    <div className="d-flex align-items-start justify-content-between">
                    <ContextMenu menu={contextMenu}/>
                    </div>
                    <Tab.Content>
                    <Tab.Pane className={"lastRollL"} eventKey="paie">
                    <div className={"p-3 border-0"} style={{borderRadius:10}}>
                    <div className={"d-flex align-items-center justify-content-between"}>
                    <div className={"tableLabel"}><h6>{t("list_formation_on")}</h6></div>
                    <div></div>
                    </div>
                    <Row>
                    { CurrentCheckNotBeginFormationsLists  ?
                        CurrentCheckNotBeginFormationsLists.length >0 ?
                        CurrentCheckNotBeginFormationsLists.map((p => {
                                return <Col md={6}><SingleFormation modules={props.rwState.all_module_formations && props.rwState.all_module_formations.filter(res=>res.formation_info && res.formation_info._id && res.formation_info._id===p._id)} employee={props.rwState.colleagues} formation={p}/></Col>
                            } ))
                            :
                            <h6 className={"col-12 alert alert-warning"}> {t("no_formation_founded")} </h6>
                        :
                        <h6 className={"col-12 alert alert-warning"}> {t("no_formation_founded")} </h6>
                    }
                     <Col md={12} className={"mt-3"}>
                                        <div hidden={!CurrentCheckNotBeginFormationsLists || (CurrentCheckNotBeginFormationsLists && CurrentCheckNotBeginFormationsLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleCheckNotBeginFormationsPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={checkNotBeginFormationsPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div></Col>
                    </Row>
                    </div>
                    </Tab.Pane>
                    <Tab.Pane className={"lastRollL"} eventKey="depense">
                    <div className={"p-3 border-0"} style={{borderRadius:10}}>
                    <div className={"d-flex align-items-center justify-content-between"}>
                    <div className={"tableLabel"}><h6>{t("list_formation_closed")}</h6></div>
                    <div></div>
                    </div>
                    <Row>
                    { ( CurrentCheckFinishFormationsLists > 0  ?
                       CurrentCheckFinishFormationsLists.length > 0 ?
                        CurrentCheckFinishFormationsLists.map((p => {
                                return <Col xs={"auto"} lg={6}> <SingleFormation modules={props.rwState.all_module_formations && props.rwState.all_module_formations.filter(res=>res.formation_info && res.formation_info._id && res.formation_info._id===p._id)} employee={props.rwState.colleagues} formation={p}/></Col>
                            }))
                            :
                            <h6 className={"col-12 alert alert-warning"}> {t("no_formation_founded")} </h6>
                        :
                        <h6 className={"col-12 alert alert-warning"}> {t("no_formation_founded")} </h6>)
                    }
                     <Col md={12} className={"mt-3"}>
                                        <div hidden={!CurrentCheckFinishFormationsLists || (CurrentCheckFinishFormationsLists && CurrentCheckFinishFormationsLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleCheckFinishFormationsPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={checkFinishFormationsPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div></Col>
                    </Row>
                    </div>
                    </Tab.Pane>
                    </Tab.Content>
                    </Tab.Container>
                    </Col>
                    }
            </Row>

        </Container>

        {/*  modals */}
        <div>
            <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
                <Modal.Header closeButton className={'border-0'}>
                    <Modal.Title>{t("confirmation")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"text-center"}>
                        {t("confirme_irreversile_deletion_text")}
                    </div>
                </Modal.Body>
                <Modal.Footer className={'text-center border-0'}>
                    <Button variant="primary" onClick={deleteFormationType}>
                        {t("yes")}
                    </Button>
                    <Button variant="light" onClick={() => setShow(null)}>
                        {t("no")}
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
        <Modal centered show={show === 'create'} onHide={handleClose} size={"lg"}>
            <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
                {
                    current_row ? (<Modal.Title className='w-100'>{t("update_text")}</Modal.Title>) : (<Modal.Title className='w-100'>{t("new_formation_title_text")}</Modal.Title>)
                }
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <Form.Group>
                    <Form.Label>{t("type_formation")}</Form.Label> <br/>
                    <div key={`inline-external`} className="mb-3">
                        <Form.Check
                            size={"lg"}
                            type={"radio"}
                            name={"external"}
                            label={"Interne"}
                            value={"internal"}
                            id={"inline-external-2"}
                            inline
                            checked
                            onClick={()=>handleExternal('internal')}
                        />
                    <Form.Check
                        size={"lg"}
                        type={"radio"}
                        name={"external"}
                        label={"Externe"}
                        value={"external"}
                        id={"inline-external-1"}
                        inline
                        onClick={()=>handleExternal('external')}
                    />

                    </div>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("formation_title_name")}  <span className={'required_box'}>*</span></Form.Label> <br/>
                    <Form.Control
                        size={"lg"}
                        placeholder={'Titre'}
                        defaultValue={current_row?.name}
                        onChange={handleName}
                        className={handleinputValidationError(formError,'name')}
                        />
                               {handleTextValidationError(formError,'name')}
                </Form.Group>
                {isExternal ?
                    <Form.Group>
                        <Form.Label>{t("external_formation_link")}  <span className={'required_box'}>*</span></Form.Label> <br/>
                        <Form.Control
                            size={"lg"}
                            placeholder={'Lien externe'}
                            defaultValue={current_row?.external_link}
                            onChange={handleExternalLink}
                            className={handleinputValidationError(formError,'externalLink')}
                            />
                                   {handleTextValidationError(formError,'externalLink')}
                  
                    </Form.Group>
                    : ""
                }
                <Form.Group>
                    <Form.Label>{t("formation_description")} </Form.Label> <br/>
                    <Form.Control
                        size={"lg"}
                        as={"textarea"}
                        placeholder={'Description'}
                        onChange={handleDescriptionName}
                        defaultValue={current_row?.description}
                    />
                </Form.Group>
                { isExternal ?
                    <div>
                <Form.Group>
                    <div key={`default-assign`} className="mb-3">
                        <Form.Check
                            type={"checkbox"}
                            id={`default-assign`}
                            label={"assigner"}
                            onChange={handleCheckAssign}
                        />
                    </div>
                </Form.Group>
                <div hidden={!checkAssign}>
                <Form.Group>
                <Form.Label>{t("assigned_to")} </Form.Label> <br/>
                    <Form.Group>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={affectations_type}
                            defaultValue={affect}
                            styles={{
                                control: base => ({
                                    ...base,
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("add_to")}
                            name='affect_to'
                            onChange={handleAffectation_type}
                            className={handleinputValidationError(formError,'affectationType')}
                            />
                                   {handleTextValidationError(formError,'affectationType')}
                    </Form.Group>
                    <Form.Group hidden={affect ? affect.value!=='branches'  : !affect}>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={props.rwState.branches}
                            getOptionLabel={o => o.name}
                            getOptionValue={o => o._id}
                            defaultValue={affect && affect.value==='branches' && current_row && current_row.attendees_target_id ? current_row .attendees_target_id.map(res=>{return res}) : ""}
                            styles={{
                                control: base => ({
                                    ...base,
                                    minHeight: 48,
                                    // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("branch_title_unique")}
                            isMulti
                            onChange={handleSelectAssign}
                        />
                    </Form.Group>
                    <Form.Group hidden={affect ? affect.value!=='departments'  : !affect}>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={props.rwState.departments}
                            getOptionLabel={o => o.name}
                            getOptionValue={o => o._id}
                            defaultValue={affect && affect.value==='departments' && current_row && current_row.attendees_target_id ? current_row.attendees_target_id.map(res=>{return res}) : ""}
                            styles={{
                                control: base => ({
                                    ...base,

                                    minHeight: 48,
                                    // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("department_title_unique")}
                            isMulti
                            onChange={handleSelectAssign}
                        />
                    </Form.Group>
                    <Form.Group hidden={affect ? affect.value!=='positions'  : !affect}>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={props.rwState.positions}
                            getOptionLabel={o => o.title}
                            getOptionValue={o => o._id}
                            defaultValue={affect && affect.value!=='positions' && current_row && current_row.attendees_target_id ? current_row.attendees_target_id.map(res=>{return res}) : ""}
                            styles={{
                                control: base => ({
                                    ...base,

                                    minHeight: 48,
                                    // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("position_title_unique")}
                            isMulti
                            onChange={handleSelectAssign}
                        />
                    </Form.Group>
                    <Form.Group hidden={affect ? affect.value!=='employee' : !affect}>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={props.rwState.colleagues.filter(r=>r.activated)}
                            getOptionLabel={o => o.lastname+''+o.firstname}
                            getOptionValue={o => o._id}
                            defaultValue={affect && affect.value==='employee' && current_row && current_row.attendees_target_id ? current_row.attendees_target_id.map(res=>{return res}) : ""}
                            styles={{
                                control: base => ({
                                    ...base,
                                    minHeight: 48,
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("employee_text")}
                            isMulti
                            onChange={handleSelectAssign}
                        />
                    </Form.Group>
                </Form.Group>
                <Row>
                    <Col sm={6}>
                    <Form.Group>
                        <Form.Label>{t("start_")}  <span className={'required_box'}>*</span></Form.Label> <br/>
                        <Form.Control
                            onChange={handleStart}
                            value={start}
                            type={"datetime-local"}
                            className={handleinputValidationError(formError,'start')}
                            />
                                   {handleTextValidationError(formError,'start')}
                  
                    </Form.Group>
                    </Col>
                    <Col sm={6}>
                    <Form.Group>
                        <Form.Label>{t("end_")}  <span className={'required_box'}>*</span></Form.Label> <br/>
                        <Form.Control
                            onChange={handleEnd}
                            min={start}
                            value={end}
                            type={"datetime-local"}
                            className={handleinputValidationError(formError,'end')}
                            />
                                   {handleTextValidationError(formError,'end')}
                  
                    </Form.Group>
                    </Col>
                </Row>
                </div>
                    </div>
                : ''
                }
            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("cancel")}
                </Button>
                <Button variant="primary"
                 disabled={
                    isExternal && checkAssign ? handleDisabledBtnValidationError(formError,
                    ['externalLink','name','affectationType','start','end']) :
                    (isExternal && !checkAssign ?
                    handleDisabledBtnValidationError(formError,
                        ['externalLink','name']) :  handleDisabledBtnValidationError(formError,
                            ['name']))
                }
                onClick={proceed}>
                    {t("save")}
                </Button>
            </Modal.Footer>
        </Modal>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Formations);
