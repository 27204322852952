import React, {useState} from 'react';
import "./Announcement.css"
import * as HI from "react-icons/hi";
import * as BI from "react-icons/bi";
import * as BS from "react-icons/bs";
import { Dropdown, Button, Modal,Form, Col, Container} from "react-bootstrap";
import ReferCandidate from "../ReferCandidate";
import Candidate from "../Candidate";
import {LinkedinShareButton} from "react-share";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import * as actions from "../../../actions";
import {uploadOnCdn,getFileUrl,getExportFileUrl,getExportExcelFileFromCdn} from '../../../service'
import {
    handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
var fileDownload = require('js-file-download');
import { useTranslation } from 'react-i18next';
const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}
const Announcement = (props) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(null);
    const [recruitmentData, setRecruitmentData] = useState(undefined);
    const [restrein, setRestrein] = useState('.xlsx')
    const [formError, setFormError] = useState({})

    const [fileName, setFileName] = useState(t("no_import_file"))
    const [file, setFile] = useState([]);
    const [link,setLink] = useState( location.origin+"/recruitment/application/"+props.post?.slug);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCopyLink = ()=>{
        navigator.clipboard.writeText(link).then(function() {
            toast(t("link_copied"),{type:"success"});
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    };

    const archivePost = (r)=>
    {
        let data = {status:r.status==="active"?"archived":"active",recruitment:r._id};
        props.action_changePostState(data);
        props.action_loading(true);
        setShow(null)
    }
    
    const handleFile = (e) => {
        if(e) {
            let file_data = e.target.files[0]
            setFile(file_data)
            setFileName(file_data.name)
            let data =handleValidation(t,{type:"text",name:'excelFile','value':file_data.name,required:true});
            let form=formError;
                    form[data.name]=data   
                    setFormError(form)
        }
    };

    

    const showImportationPanel= async  (p) => {
       setRecruitmentData(p);
        setShow('importExcel')
    }
    const importExcel= async  () => {
        let  files=file;
        setFile(undefined)
      let o = {recruitment: recruitmentData._id};
      if(files){
               uploadOnCdn(files)
                  .then(resp => {
                      setFile(resp?.filename);
                     o.file=resp?.filename;
                          props.action_create_candidature_excel(o)
                  })
          }else{
            toast(t("no_doc_file"),{type:"warning"})
          }
      
      setShow(null)
      props.action_loading(true)
      setFormError([])
      setFile()
      setFileName(t("no_import_file"))
      }
      const downloadDocument = () => {
        getExportExcelFileFromCdn('candidatures.xlsx')
        .then(response => {
            fileDownload(response,'candidatures.xlsx')
        })
        .catch(err => console.log('error get from cdn : ', err))
       
}
      
    return <div id={'Announcement'} >
        <div className={"p-3"}>
            <div className={'float-right'}>
                <Dropdown>
                    <Dropdown.Toggle variant="white" id="dropdown-basic">
                        <BS.BsThreeDotsVertical/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item hidden={props.post.status==='archived'}  onClick={() => setShow('share')} className={"text-primary"}><BI.BiShare/>
                         <LinkedinShareButton url={link} summary={props.post.description} source={"Glow HR"} title={props.post.title} >{t("share")}</LinkedinShareButton></Dropdown.Item>
                        <Dropdown.Item hidden={props.post.status==='archived'} onClick={handleCopyLink} className={"text-primary"}> <BI.BiCopy/> {t("copy_link")}</Dropdown.Item>
                        <Dropdown.Item hidden={props.post.status==='archived'}  onClick={e=>showImportationPanel(props.post)} className={"text-primary"}><BS.BsUpload/> {t("import_candidat")}</Dropdown.Item>
                        <Dropdown.Item hidden={props.post.status==='archived'}  onClick={() => setShow('refer')} className={"text-primary"}><BS.BsPerson/> {t("referenced")}</Dropdown.Item>
                        <Dropdown.Item hidden={props.post.status==='archived'}  onClick={props.editPost} className={"text-primary"}><BI.BiEdit/> {t("update")}</Dropdown.Item>
                        <Dropdown.Item onClick={() => {setShow('confirm');}} className={"text-danger"}><BI.BiTrash/> {props.post?.status==="active"? t("archived"):t("remove_from_archived")}</Dropdown.Item>
                        {/*<Dropdown.Item onClick={() => setShow('confirm')} className={"text-danger"}><BI.BiTrash/> {t("delete")}</Dropdown.Item>*/}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className={"d-flex align-items-center mb-2"}>
                <div>
                    <div className={"text-purple"}>
                       <span> <BS.BsBriefcase size={15}/>  </span>
                     <span className={"text-uppercase mt-1 pt-1"}> {props.post?.contract_type}</span></div>
                </div>
                <div className={"ml-3"}>
                    <div className={"poste"}>{props.post?.position_info?.[0]?.title}</div>
                </div>

            </div>
            <div className={"poste"}>{props.post?.title}</div><br/>

            <div className={"d-inline-flex align-items-center mb-3"}>
                {props.post.tags?.map((t,k)=><div key={k} className={"refby p-2 mr-2"}>{t.label?t.label:t.toString()}</div>)}

                <span className={"pull-right"}><HI.HiOutlineLocationMarker color={"#6600CC"}/>
                    {props.post.city||"---"}
                </span>
                </div>
            <h6 className={"stats-cands"}>
                ({props.candidats.length}) {t("application")} - ({props.references.length}) {t("references")}
            </h6>

            <div hidden={props.view === "post"}>
                    <div className={"pr-2"}><Button onClick={() => setShow('view')} variant={"light"}
                                                         block>{t("show")}</Button></div>
            </div>
        </div>
        <div>
            <Modal show={show === 'refer'} onHide={handleClose} centered>
                <Modal.Header closeButton className={'border-0'}>
                    <Modal.Title>{t("referenced")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReferCandidate post={props.post} onHide={handleClose} />
                </Modal.Body>
            </Modal>
            <Modal show={show === 'view'} onHide={handleClose} centered size={"lg"}>
                <Modal.Header closeButton className={'border-0'}>
                    <Modal.Title>{t("poste_details")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <strong>{t("intitule")}: </strong> {props.post?.title}
                    </div>
                    <div>
                        <strong>{t("description")}: </strong> {props.post?.description}
                    </div>
                    <div>
                        <strong>{t("limit_date")}: </strong> {props.post?.closed_date}
                    </div>
                    <div>
                        <strong>{t("tags")}: </strong> {props.post?.tags?.map((t,k)=><span className="badge badge-primary mr-2" key={k}>{t.label?t.label:t} </span>)}
                    </div>
                    <div>
                        <strong>{t("localisation")}: </strong> {props.post?.locations?.map((l,k)=><span key={k}>{l}</span>)}
                    </div>
                    <div>
                        <strong>{t("companie")}: </strong> {props.post?.company?.name}
                    </div>
                    {/*<div>*/}
                    {/*    <strong></strong> {props.post?.position}*/}
                    {/*</div>*/}
                    <div>
                        <strong>{t("section_companie")}: </strong> {props.post?.departments?.map((d,k)=><span key={k}>{d.name}</span>)}
                    </div>
                </Modal.Body>
                <Modal.Footer className={'border-0'}>
                    <Button variant="light" onClick={() => setShow('share')}>
                        <LinkedinShareButton url={link} summary={props.post.description} source={"Glow HR"} title={props.post.title} >{t("share")}</LinkedinShareButton>
                    </Button>
                    {/*<Button variant="primary" onClick={() => setShow('candidate')}>
                        Postuler
                    </Button>*/}
                    <Button variant="primary" onClick={() => setShow('refer')}>
                        {t("referenced")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show === 'candidate'} onHide={handleClose} centered>
                <Modal.Header closeButton className={'border-0'}>
                    <Modal.Title>{t("apply_at")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Candidate post={props.post} onHide={handleClose}/>
                </Modal.Body>
            </Modal>
            <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
                <Modal.Header closeButton className={'border-0'}>
                    <Modal.Title>{t("confirmation")}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className={"text-center"}>
                        {t("notif_continous_announce")}
                    </div>
                </Modal.Body>
                <Modal.Footer className={'text-center border-0'}>
                    <div>
                        <div className={"text-center"}>
                            <Button variant="primary" onClick={()=>{archivePost(props.post)}}>
                                {t("yes_continous")}
                            </Button>
                            <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                                {t("cancel")}
                            </Button>
                        </div>
                    </div>

                </Modal.Footer>
            </Modal>

            <Modal show={show==="importExcel"} onHide={() => setShow(null)} centered size={"md"}>
          <Modal.Body>
              <div className={"col-lg-12"}>
                <Form.Group>
                 <Col>
                 
                 <a href="#" className={"text-left mt-4"}  onClick={()=>downloadDocument()}>{t("download_Import_model")}</a>
                
                   
                 <h3 className={"text-left mt-2"}>{t("import_excel_file")}
                 
                 </h3>
                   <Form.File id="formcheck-api-custom" custom>
                        <Form.File.Input onChange={handleFile} accept={restrein} 
                        className={handleinputValidationError(formError,'excelFile')}
                        />
                        <Form.File.Label data-browse={t("import_file")}>
                            {fileName}
                        </Form.File.Label>
                        <Form.Control.Feedback type="valid">50MB</Form.Control.Feedback>
                    </Form.File>{handleTextValidationError(formError,'excelFile')}
                     <div className={"text-right mt-4"}>
                      <Button variant="warning"
                      disabled={
                        handleDisabledBtnValidationError(formError,
                        ['excelFile'])} onClick={()=> importExcel() }>
                          {t("import_now")}
                      </Button>
                  </div>
                </Col>
    </Form.Group>
              </div>
          </Modal.Body>
      </Modal>

        </div>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Announcement);
