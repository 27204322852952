import React from 'react';
import './RightPane.css'
import ProfileWidget from "../widgets/ProfileWidget";
import ColleguesWidget from "../widgets/ColleguesWidget";
import ProjectsWidget from "../widgets/ProjectsWidget";
import MyNotesWidget from "../widgets/MyNotesWidget";
import EventWidget from "../widgets/EventWidget";
import EventsCategoryWidget from "../widgets/EventsCategoryWidget";
import EventDetailWidget from "../widgets/EventDetailWidget";
import CreateEventWidget from "../widgets/CreateEventWidget";
import BalanceWidget from "../widgets/BalanceWidget";
import OutProgressWidget from "../widgets/OutProgressWidget";
const RightPane = () => {
  return <div id={'RightPane'}>
    <div className={'p-3'}>
      <ProfileWidget />
      <hr />
      <ColleguesWidget />
      <hr />
      <ProjectsWidget />
      <hr />
      <MyNotesWidget />
      <hr />
      <EventWidget />
      <hr />
      <EventsCategoryWidget />
      <hr />
      <EventDetailWidget />
      <hr />
      <CreateEventWidget />
      <hr />
      <BalanceWidget />
      <hr />
      <OutProgressWidget />
      <hr />
    </div>
  </div>;
};

export default RightPane;
