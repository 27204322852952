import React, {useEffect, useState} from 'react';
import './Integration.css'
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import DataTable from "react-data-table-component";
import mSVG from "../../../assets/gender/m.png";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import {useHistory} from 'react-router-dom'
import { useTranslation } from 'react-i18next';


const mapStateToProps = (state) => {
  return {rwState: state}
}
const mapDispatchToProps = {...actions}


const Integration = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    let user = JSON.parse(window.localStorage.getItem('glowhr_user')).user;
    const [show, setShow] = useState(null)
    const [current_row, setCurrentRow] = useState([])

    useEffect(()=>{
    props.action_listColleagues()
            props.action_setTitle(t("integration_title"))
    // props.action_organigram()
  },[])
    const [employee, setEmployee] = useState()

    const filterEmployee = (data)=> {
      let empl=[];
        if(employee!=="" && employee!==undefined){
            empl=data.filter(res=>res.firstname.includes(employee) || res.lastname.includes(employee) || res.role_info.name.includes(employee) || res.position?.title.includes(employee))
        }else{
           empl=data;
        }
        return empl;
  };

    const confirmEmbauch= ()=>{
        let o={_id:current_row._id};
        o.onboarded_at=new Date();
        o.onboarded=true;
        props.action_hiredEmployee(o)
        setShow(null)
        props.action_loading(true)
    }

    const embauch=(o)=>{
        setCurrentRow(o)
        setShow('confirm')
    }

  const handleEmployeeSearch = (e)=>{
      setEmployee(e.target.value)
  }

  const colleaguesListColumns = [
    {
      name:t("first_last_name"),
      selector:'firstname',
      cell: row => {
        return <div className={'d-flex align-items-center pt-3 mb-3'}>
          <div className={'mr-3'}>
            <img src={mSVG} style={{width:38,height:38}}/>
          </div>
          <div>
            <div className={'colleagueName'}>{row.firstname + ' ' + row.lastname}</div>
          </div>
        </div>
      }
    },

    {
      name:t("profession"),
      selector:'position',
      cell: row => {
        return <div className={'colleagueInfo'}> {row.position? row.position?.title : ""} </div>
      }
    },
    {
      name:t("role"),
      selector:'role _info',
      cell: row => {
        return <div className={'colleagueInfo'}> {row.role_info ? row.role_info?.name : ""} </div>
      }
    },
      {
          name:t("status"),
          cell: row =>
              !row.onboarded ? <Button  className={"btn btn-light btn-sm"} onClick={()=>embauch(row)}>Valider intégration</Button> : <div style={{borderImage:"linear-gradient(to right, #17A324 99%, #C7C7C7 1%) 4",borderBottom:"solid 3px", width:56}}> </div>,
      },
    {
      name:'',
      cell: row =>{return <div className={'colleagueInfo'}> <Button onClick={e => history.push({pathname:'/dash/user/inout/'+row._id})} className={"btn btn-sm btn-light"} size={"sm"}>Gérer l'intégration</Button> </div>},

    }
  ]

  return <div id={'Integration'}>
     <Row>
        <Col>
          <div className={'mb-3'}>

              <Row>
                  <Col sm={4}>
                      <Form.Group >
                          <Form.Label>{t("search_employee_text")}</Form.Label>
                          <Form.Control as={"input"}
                                        defaultValue={employee}
                                        type={"text"}
                                        onChange={handleEmployeeSearch}
                          />
                      </Form.Group>
                  </Col>
                 <Col sm={12}>
                  <div className={'mt-3'}>
                  {filterEmployee(
                            props.rwState.colleagues.filter(res=>res.user!==user._id && !res.onboarded && res.user)) && 
                            filterEmployee(
                              props.rwState.colleagues.filter(res=>res.user!==user._id && !res.onboarded && res.user)).length>0 ? 
                
                    <DataTable
                       noHeader
                       fixedHeader
                       fixedHeaderScrollHeight="600px"
                       pagination
                        // title={'Choisir un utilisateur pour demarrer son integration dans la compagnie'}
                        // props.rwState.colleagues.filter(res=>res.user!==user._id && !res.hired_on && res.onboarded)
                      columns={colleaguesListColumns}
                        data={filterEmployee(
                            props.rwState.colleagues.filter(res=>res.user!==user._id && !res.onboarded && res.user))}
                        highlightOnHover
                        pointerOnHover
                        noDataComponent={t("no_integration_initiate")}
                        paginationComponentOptions={
                          {
                         rowsPerPageText: t("line_per_page"),
                          rangeSeparatorText: t("for"),
                           noRowsPerPage: false,
                            selectAllRowsItem: false,
                             selectAllRowsItemText: t("all")} 
                         }
                    /> : 
                      <div className={'mt-3 bg-warning rounded text-white p-4 text-center'}>
                        {t("no_integration_initiate")}
                      </div>}
                  </div>
                </Col>
              </Row>
          </div>
         </Col>
      </Row>

  {/* confirm   */}
      <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
          <Modal.Header closeButton className={'border-0'}>
              </Modal.Header>
          <Modal.Body>
              <div className={"text-center"}>
                  <h4>{t("confirmation")}</h4>
                  {t("validate_integration_notification")}
              </div>
          </Modal.Body>
          <Modal.Footer className={'text-center border-0'}>
              <Button variant="primary" onClick={confirmEmbauch}>
                  {t("yes")}
              </Button>
              <Button variant="light" onClick={() => setShow(null)}>
              {t("no")}
              </Button>
          </Modal.Footer>
      </Modal>

  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Integration);

