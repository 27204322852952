import React, {useState} from 'react';
import "./Referer.css"
import mSVG from "../../../assets/gender/m.png"
import {Button, Modal} from "react-bootstrap";
import ViewRefered from "../ViewRefered";
import {connect} from "react-redux";
import * as actions from "../../../actions";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const Referer = (props) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return <div id={"Referer"}>
        <div className={"p-4"}>
            <div className={"d-flex align-items-center mb-2"}>
                <div>
                    <img hidden={props.view === "post"} src={mSVG} alt="avatar" style={{width: 64}}/>
                </div>
                <div className={"ml-3"}>
                    <div hidden={props.view === "post"}
                         className={"name"}>{props.reference.firstname} {props.reference.lastname}</div>
                    <div hidden={props.view === "post"}
                         className={"poste"}>{props.reference?.recruitment?.title}</div>
                    {/*<strong>{props.reference.email}</strong>*/}
                </div>
            </div>
            <div className={"desc pt-3 pb-3"}>
                <strong>{t("comment")}:</strong>
                <p>{props.reference.comment}</p>
            </div>
            {props.listing && (
                <div className={"d-inline-flex align-items-center mb-3"}>
                    <div className={"refby p-2"}>Full Time</div>
                </div>
            )}
            <div hidden={props.view === "post"}>
                <div className={"d-flex align-items-center mb-3"}>
                    <div className={"refby p-2"}>{t("reference_by")} :</div>
                    <div className={"d-flex align-items-center pl-3"}>
                        <img src={mSVG} alt="avatar" style={{width: 32}}/>
                        <div className={"pl-2"}>
                            <div
                                className={"refname"}>{props.reference.reference_info ? props.reference.reference_info[0].firstname : ''} {props.reference.reference_info ? props.reference.reference_info[0].lastname : ''}</div>
                            <div className={"refrole"}>{props.reference.reference_info ? props.reference.reference_info[0].position?.title : ''}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div hidden={props.view === "post"}>
                <div className={"xd-flex align-items-center justify-content-between"}>
                    <Button onClick={() => setShow('view')} variant={"purple"} block>{t("show")}</Button></div>
            </div>
        </div>
        <div>
            <Modal show={show === 'view'} onHide={handleClose} centered size={"lg"}>
                <Modal.Header closeButton className={'border-0'}>
                    <Modal.Title>{t("reference_details")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ViewRefered reference={props.reference} onHide={handleClose}/>
                </Modal.Body>
            </Modal>
        </div>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Referer);
