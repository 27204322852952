import React, {useEffect, useState} from 'react';
import {Badge, Button, Col, Row, Modal, Form} from "react-bootstrap";
import {connect, useStore} from "react-redux";
import * as actions from "../../../actions";
import {getFileUrl} from "../../../service";
import * as BS from "react-icons/bs";
import {
    handleDisabledBtnValidationError, handleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const ViewRefered = (props) => {
    const { t } = useTranslation();
    const store = useStore()
    const [show, setShow] = useState(false);
    const [comment, setComment] = useState("");
    const [formError, setFormError] = useState({})


    const handleClose = () => setShow(false);
    const handleComment = (e) => {
        setComment(e.target.value)
        let data =handleValidation(t,{type:"text",name:'comment','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };

    const [recruitment, setRecruitment] = useState()
    const handle = () => {
        props.onHide()
    }

    useEffect(() => {
    }, [props.reference]);


    const handleAccept = e => {
        let o = {
            email: props.reference.email,
            firstname: props.reference.firstname,
            lastname: props.reference.lastname,
            language:props.reference.language,
            cv_url:  props.reference.url,
            recruitment:props.reference.recruitment._id,
            reference:props.reference._id,
        };
        props.action_reference_accept(o);
        props.action_loading(true);
        props.onHide()
    }
    const getFilePath = (filename)=>{
        return filename.includes('file-')?
            getFileUrl(filename):filename;
    };

    const handleReject = () => {
        let o = {
            status: -1,
            comment: comment,
            id:props.reference._id
        };
        props.action_reference_status(o)
        //props.action_loading(true);
        props.onHide()
    }
    return <div id={'ViewRefered'}>
        <Row>
            <Col md={6}>
                <div>
                    <strong>{t("first_name")}:</strong> {props.reference.firstname}</div>
                <div>
                    <strong>{t("last_name")}:</strong> {props.reference.lastname}</div>
                <div>
                    <strong>{t("email")}:</strong> {props.reference.email}</div>
                    {props.reference.comment && props.reference.comment!=="" ? 
                <div>
                    <strong>{t("reject_motif")} :</strong>
                    <p>{props.reference.comment}</p>
                </div>
                : ""}
                {props.reference.cv_url ?
                 <div>
                    <strong>{t("cv_url_")}: </strong> 
                     <a href={getFilePath(props.reference.cv_url)}> 
                        {t("see_now")}</a></div>
                   : ''}
                   <div>
                    <strong>
                        <span>{t("application_status")}:  </span>
                        {props.reference.status===0 && (<Badge variant="dark">{t("waiting")}</Badge>)}
                        {props.reference.status===1 && (<Badge variant="success">{t("recruit")}</Badge>)}
                        {props.reference.status===-1 && (<Badge variant="secondary">{t("rejected_")}</Badge>)}
                    </strong>
                </div>
                <hr/>
                <strong>{t("referenced_by")} : </strong>

                {props.reference.reference_info ? props.reference.reference_info[0].firstname +" ": ''}
                {props.reference.reference_info ? props.reference.reference_info[0].lastname : ''}
            </Col>
            <Col md={6}>
            <div style={{ background:'#f8f8f8' }} className="p-4">
            <h2>{t("poste_details")}</h2>
                <div>
                    <strong>{t("annonce")}:</strong> {props.reference.recruitment?.title}
                </div>
                <strong>{t("description")}:</strong>
                <p>{props.reference.recruitment?.description}</p>
                </div>
            </Col>

        </Row>
        <div  hidden={props.reference.status!==0} className={props.reference.status!==0?'':'p-3 text-center d-flex justify-content-center'}>
            <div className="p-2">
                <Button onClick={handleAccept}><BS.BsCheckCircle/> {t("accept")}</Button>
            </div>
            <div className="p-2">
              <Button variant={"danger"} onClick={()=>setShow('comment')}><BS.BsTrash/> {t("rejected_action")}</Button>
          </div>
      </div>

      <Modal size={"md"} centered show={show === 'comment'} onHide={handleClose}>
            <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
                {t("motif")}
            </Modal.Header>
            <Modal.Body className={'p-5'}>
                <Form>
                <Form.Row>
                    <Form.Group style={{width:"100%"}}>
                            <Form.Label>{t("reject_motif")}</Form.Label>
                            <Form.Control
                                required
                                onChange={handleComment}
                                defaultValue={comment}
                                as={"textarea"}  
                                className={handleinputValidationError(formError,'comment')}
                                />
                                       {handleTextValidationError(formError,'comment')}
                    </Form.Group>
                </Form.Row>
                    <br/>
                        <div className={"d-flex align-items-end justify-content-end"}>
                        <Button variant="light" onClick={handleClose}>
                            {t("cancel")}
                        </Button> &nbsp;
                        <Button variant="primary" type="button"
                        disabled={
                            handleDisabledBtnValidationError(formError,
                             ['comment'])
                         }
                        onClick={()=>handleReject()}>
                            {t("validate")}
                        </Button>
                        </div>
                </Form>

            </Modal.Body>

        </Modal>
  
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewRefered);
