import React, {useEffect, useState} from 'react';
import './Holidays.css'
import { Button, Col, Container, Form, Modal, Row,Tab} from "react-bootstrap";
import AskingHolidays from "../../widgets/AskingHolidays";
import SuggestedHolidays from "../../widgets/SuggestedHolidays";
import DottedButton from "../../widgets/DottedButton";
import Select from "react-select";
import {connect} from "react-redux";
import * as actions from "../../../actions";
import Emitter from "../../../service/events";
import ContextMenu from "../../menus/ContextMenu/ContextMenu";
import {isAdmin,
    handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const Holidays = (props) => {
    const { t } = useTranslation();
    const role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    let connectedUser = JSON.parse(window.localStorage.getItem('glowhr_user'));
    console.log(connectedUser,'******* connected User Holidays**********')
    const [to, setTo] = useState()
    const [from, setFrom] = useState()
    const [show, setShow] = useState(null)
    const [libelle, setLbelle] = useState()
    const [desc, setDesc] = useState()
    const [target, setTarget] = useState()
    const [type, setType] = useState(null)
    const [obs, setObs] = useState()
    const [nDays, setNDays] = useState()
    const [selectedTargetType, setSelectedTargetType] = useState()
    const [selectedTargetLibelle, setSelectedTargetLibelle] = useState()
    const [selectedTarget, setSelectedTarget] = useState()
    const [current_row, setCurrentRow] = useState()
    const [validated, setValidated] = useState(false);
    const [modifiers,setModifiers] = useState({start: from, end: to});
    const [formError, setFormError] = useState({})

    const target_type_options =
            [
                { label: t('all'), value: 'all' },
                { label: t("departments"), value: "departments"},
                { label: t('branches'), value: 'branches'},
                { label: t('positions'), value: 'positions'},
                { label: t('specific_employee'), value: 'employee'}
            ]
      const target_libelle_options =
        [{ label: t("sick_leave"), value: 'conge_maladie' },
            { label: t("unpaid_leave"), value: "conge_sans_solde"},
            { label: t("recognition_leave"), value: 'conge_de_reconnaissance'},
            { label: t("annual_leave"), value: 'conge_annuel'},
            { label: t("other"), value: 'autre'}
        ]
    const [target_libelle, setTargetLibelle] = useState(target_libelle_options[0])
    const [target_type, setTargetType] = useState(target_type_options[0])
    const resetForm = ()=>{
        setTargetType(target_type_options[0]);
        setTargetLibelle(target_libelle_options[0])
        setLbelle('');
        setDesc('');
        setNDays('');
        setFormError({})
    }
 
    const handleClose = () => {
        setShow(null)
        setFormError({})
    };

    const requestDataBuilder = () => {
        let tar = [];
        target.forEach((t)=>{tar.push(t._id)});
        return {
            "name": libelle ,
            "target_name": target_libelle,
            "description": desc,
            "days": nDays,
            "target_type": target_type,
            "target": tar,
        }
    };

    const createOrUpdateHoliday = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return
        }
        let l = requestDataBuilder();
        if (current_row){
            l.id = current_row._id;
            props.action_updateLeaveType(l)
        } else{
            props.action_createLeaveType(l)
        }
        setValidated(true);
        handleClose();
        props.action_loading(true);
        resetForm()
    };
    const deleteLeaveType = () => {
        props.action_deleteLeaveType(current_row._id);
        setShow(null);
        props.action_loading(true)
    };
    const askLeave = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return
        }
        if (!type){
            return;
        }
       
        let o = {
            "start_date": from,
            "end_date": to,
            "description": obs,
            "type": type._id,
        };
        setValidated(true);
        props.action_askLeave(o);
        handleClose()
        resetHolidayForm()
    }
   

    const resetHolidayForm = ()=>{
        setObs('');
        setType('');
        setTo('');
        setFrom('');
        setFormError({})
    }

    const handleFromChange = (e) => {
        setFrom(e.target.value)
        let data =handleValidation(t,{type:"date",name:'fromChange','value':from,required:true,min:new Date()});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }

    const handleToChange = (e) => {
        const selectedToDate = new Date(e.target.value);
        setTo(e.target.value);
    
        // Validate the 'to' date
        const validationData = handleValidation(t, {
            type: "date",
            name: 'toChange',
            value: selectedToDate,
            required: true,
            min: new Date(from),
        });
    
        setFormError(prev => ({ ...prev, [validationData.name]: validationData }));
    
        if (validationData.formIsValid && from) {
            let diffInDays = (selectedToDate - new Date(from)) / (1000 * 60 * 60 * 24);
            diffInDays = Math.ceil(diffInDays);
            let totalLeaveDays = 0;
            let usedLeaveDays = 0;
    
            const leaveType = type;
    
            if (leaveType) {
                totalLeaveDays = leaveType.days;
    
                const leaveRequests = props.rwState.askingLeaves.filter(
                    leave => leave.type_info[0]._id === type._id && leave.status==1
                );
                for(const leave of leaveRequests){
                    usedLeaveDays += (new Date(leave.end_date) - new Date(leave.start_date)) / (1000 * 60 * 60 * 24);
                }
                usedLeaveDays=Math.ceil(usedLeaveDays);
                console.log(leaveRequests, usedLeaveDays, diffInDays,'*** difff days ****')
            }
            const remainingDays = totalLeaveDays - (usedLeaveDays + diffInDays);
    
            if (remainingDays < 0) {
                setFormError(prev => ({
                    ...prev,
                    toChange: {
                        formIsValid: false,
                        errors: { toChange: t("total_days_must_be_less_than_total_holidays") },
                        name: 'toChange',
                    }
                }));
            }
        }
    };

    const handleLibelle = (e) => {
        setLbelle(e.target.value)
        let data =handleValidation(t,{type:"text",name:'libelle','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }

    const handleDesc = (e) => {
        setDesc(e.target.value)
    }

    const handleLeaveType = (e) => {
        setType(e);
        setTo(from)
        let data =handleValidation(t,{type:"text",name:'leaveType','value':e,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleObservation = (e) => {
        setObs(e.target.value)
        let data =handleValidation(t,{type:"textarea",name:'observation','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleNDays = (e) => {
        setNDays(e.target.value)
        let data =handleValidation(t,{type:"number",name:'nDays','value':e.target.value,required:true,min:1});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleTargetType = (e) => {
        setTarget([]);
        setTargetType(e.value)
    };
   const handleTargetLibelle = (e) => {
       e.value==='autre' ? setLbelle('') : setLbelle(e.label);
       setTargetLibelle(e.value)
       let data =handleValidation(t,{type:"text",name:'targetLibelle','value':e.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handleSelect = (e) => {
        setTarget(e)
    };
    const handleSelectLibelle = (e) => {
        setLbelle(e)
    };

    const contextMenu =
        !isAdmin(role)
            ?
            [
                {label: t("my_leaves_request"), eventKey: 'my_requests'},
            ]
            :
            ( props.rwState.leaveTypes && props.rwState.leaveTypes.length>0 ?
            [
                {label: t('requests'), eventKey: 'all_requests'},
                {label: t("my_leaves_request"), eventKey: 'my_requests'},
                {label: t("leaves_type"), eventKey: 'leaves'},
            ]
                    :
                [
                    {label: t("leaves_type"), eventKey: 'leaves'},
                ]
            )
    useEffect(()=>{
        props.action_loading(true);
        props.action_listLeaveType();
        props.action_listLeave();
        props.action_listMyLeave();
        props.action_listColleagues();
        props.action_listDepartments();
        props.action_listBranches();
        props.action_listPositions();
        props.action_setTitle(t("leaves"))
        //events listeners
        Emitter.on('edit-leave',(leave)=>{
            console.log('leave up',leave)
            setSelectedTarget(leave.target);
            setLbelle(leave.name);
            setTargetLibelle(leave.target_name);
            setSelectedTargetLibelle(target_libelle_options.find((t)=>t.value === leave.target_name))
             setDesc(leave.description);
            setNDays(leave.days);
            setTarget(leave.target);
            setTargetType(leave.target_type)
            setSelectedTargetType(target_type_options.find((t)=>t.value === leave.target_type))
            setCurrentRow(leave);
            setShow('create');
            let data=  handleFinaleValidation(t,[
                {type:"text",name:'targetLibelle','value':leave.target_name,required:true},
                {type:"text",name:'libelle','value':leave.name,required:true},
                {type:"date",name:'nDays','value':leave.days,required:true},
                {type:"text",name:'targetType','value':leave.target_type,required:true}
               ])
                    setFormError(data)
        });
        Emitter.on('delete-leave',(leave)=>{
            setCurrentRow(leave);
            setShow('confirm');
            console.log('leave to destroy',leave)
        });
    },[]);
    
  //****************************************** paginate filter data */
  useEffect(() => {
    setRequestLeavesList([])
    setLeaveTypesList([])
    setMyLeavesList([])
    if(props.rwState.askingLeaves && props.rwState.askingLeaves.length > 0 ) {
        let mylist = props.rwState.askingLeaves;
        setRequestLeavesList(mylist)
    }
   }, [props.rwState.askingLeaves]);

 useEffect(() => {
   if(props.rwState.leaveTypes && props.rwState.leaveTypes.length > 0 ) {
        let mylist = props.rwState.leaveTypes;
        setLeaveTypesList(mylist)
    }
   }, [props.rwState.leaveTypes]);

 useEffect(() => {
   if(props.rwState.myLeaves && props.rwState.myLeaves.length > 0 ) {
        let mylist = props.rwState.myLeaves;
        setMyLeavesList(mylist)
    }
   }, [props.rwState.myLeaves]);

/********************************************* request leave Begin paginating */
    const [requestLeavesList, setRequestLeavesList] = useState(undefined);
      const [CurrentRequestLeavesLists, setCurrentRequestLeavesLists] = useState(undefined);
      const [requestLeavesPageCount, setRequestLeavesPageCount] = useState(0);
      const [requestLeavesItemOffset, setRequestLeavesItemOffset] = useState(0);
      const [requestLeavesItemsPerPage, setRequestLeavesItemsPerPage] = useState(8);

      useEffect(() => {
        if(requestLeavesList && requestLeavesList.length > 0) {
            const endOffset = requestLeavesItemOffset + requestLeavesItemsPerPage;
            setCurrentRequestLeavesLists(requestLeavesList.slice(requestLeavesItemOffset, endOffset));
            setRequestLeavesPageCount(Math.ceil(requestLeavesList.length / requestLeavesItemsPerPage));
        }
       }, [requestLeavesItemOffset, requestLeavesItemsPerPage, requestLeavesList]);
   
       const handleRequestLeavesPageClick = (event) => {
        const newOffset = (event.selected * requestLeavesItemsPerPage) % requestLeavesList.length;
        setRequestLeavesItemOffset(newOffset);
    };
    //********************************************* request leave Active End paginating */
 
/********************************************* leave types Begin paginating */
const [leaveTypesList, setLeaveTypesList] = useState(undefined);
const [CurrentLeaveTypesLists, setCurrentLeaveTypesLists] = useState(undefined);
const [leaveTypesPageCount, setLeaveTypesPageCount] = useState(0);
const [leaveTypesItemOffset, setLeaveTypesItemOffset] = useState(0);
const [leaveTypesItemsPerPage, setLeaveTypesItemsPerPage] = useState(8);

useEffect(() => {
  if(leaveTypesList && leaveTypesList.length > 0) {
      const endOffset = leaveTypesItemOffset + leaveTypesItemsPerPage;
      setCurrentLeaveTypesLists(leaveTypesList.slice(leaveTypesItemOffset, endOffset));
      setLeaveTypesPageCount(Math.ceil(leaveTypesList.length / leaveTypesItemsPerPage));
  }
 }, [leaveTypesItemOffset, leaveTypesItemsPerPage, leaveTypesList]);

 const handleLeaveTypesPageClick = (event) => {
  const newOffset = (event.selected * leaveTypesItemsPerPage) % leaveTypesList.length;
  setLeaveTypesItemOffset(newOffset);
};
//********************************************* leave types Active End paginating */


/********************************************* my leave types Begin paginating */
const [myLeavesList, setMyLeavesList] = useState(undefined);
const [CurrentMyLeavesLists, setCurrentMyLeavesLists] = useState(undefined);
const [myLeavesPageCount, setMyLeavesPageCount] = useState(0);
const [myLeavesItemOffset, setMyLeavesItemOffset] = useState(0);
const [myLeavesItemsPerPage, setMyLeavesItemsPerPage] = useState(8);

useEffect(() => {
  if(myLeavesList && myLeavesList.length > 0) {
      const endOffset = myLeavesItemOffset + myLeavesItemsPerPage;
      setCurrentMyLeavesLists(myLeavesList.slice(myLeavesItemOffset, endOffset));
      setMyLeavesPageCount(Math.ceil(myLeavesList.length / myLeavesItemsPerPage));
  }
 }, [myLeavesItemOffset, myLeavesItemsPerPage, myLeavesList]);

 const handleMyLeavesPageClick = (event) => {
  const newOffset = (event.selected * myLeavesItemsPerPage) % myLeavesList.length;
  setMyLeavesItemOffset(newOffset);
};
//********************************************* my leave types Active End paginating */


    return <div id={'Holidays'}>
        <Container fluid>

            <Tab.Container  id="left-tabs-example" defaultActiveKey={!isAdmin(role)?'my_requests':(props.rwState.leaveTypes && props.rwState.leaveTypes.length>0 ?'all_requests' :'leaves')}>
                <div className="d-flex align-items-start justify-content-between">
                    <ContextMenu menu={contextMenu}/>
                </div>
                <Tab.Content>
                    <Tab.Pane className={"lastRollL"} eventKey="all_requests">
                        <Row hidden={!isAdmin(role)}>
                                    {CurrentRequestLeavesLists && CurrentRequestLeavesLists.length > 0?
                                    CurrentRequestLeavesLists.map((l) =>
                                        <Col xs={"12"} lg="4">
                                            <AskingHolidays leave={l}/>
                                        </Col>
                                    ):
                                        <Col md={11} className={"alert alert-warning m-4"}>{t("no_request")}.</Col>
                                    }
                                    <Col md={12} className={"mt-3"}>
                                        <div hidden={!CurrentRequestLeavesLists || (CurrentRequestLeavesLists && CurrentRequestLeavesLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleRequestLeavesPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={requestLeavesPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                        </div>
                                    </Col>
                        </Row>
                    </Tab.Pane>
                    <Tab.Pane className={"lastRollL"} eventKey="leaves">
                        <Row hidden={!isAdmin(role)}>
                            <Col>
                                <Row hidden={!isAdmin(role)}>
                                    <Col md={12}>
                                        <DottedButton className={"float-right"} text={'Créer un congé'} click={(e)=>{ setShow('create'); setCurrentRow(null)}}/>
                                    </Col>
                                </Row>
                                <Row>
                                            {CurrentLeaveTypesLists && CurrentLeaveTypesLists.length>0 ?
                                                CurrentLeaveTypesLists.map((l) =>
                                                <Col xs={"auto"}>
                                                    <SuggestedHolidays leave={l}/>
                                                </Col>)
                                                :
                                                <Col md={11} className={"alert alert-warning m-4"}> {t("no_leaves")}</Col>
                                            }
                                              <Col md={12} className={"mt-3"}>
                                        <div hidden={!CurrentLeaveTypesLists || (CurrentLeaveTypesLists && CurrentLeaveTypesLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleLeaveTypesPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={leaveTypesPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div></Col>
                                </Row>

                            </Col>
                        </Row>
                    </Tab.Pane>
                    <Tab.Pane className={"lastRollL"} eventKey="my_requests">
                        <Row>
                            <Col>
                                <Button className={"ml-4 float-right"} onClick={e => setShow('ask')}>{t("make_request")}</Button>
                            </Col>
                        </Row>
                        <Row>
                                    {CurrentMyLeavesLists && CurrentMyLeavesLists.length > 0 ?
                                    CurrentMyLeavesLists.map((l) =>
                                        <Col xs={"12"} lg="4">
                                            <AskingHolidays view={t("employee_text")} leave={l}/>
                                        </Col>
                                    ):
                                        <Col md={11} className={"alert alert-warning m-4"}>{t("no_request_submit")}</Col>
                                    }
                                      <Col md={12} className={"mt-3"}>
                                        <div hidden={!CurrentMyLeavesLists || (CurrentMyLeavesLists && CurrentMyLeavesLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleMyLeavesPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={myLeavesPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div>
                                        </Col>
                        </Row>
                    </Tab.Pane>

                </Tab.Content>
            </Tab.Container>

            <Modal centered show={show === 'create'} onHide={handleClose}>
                <Modal.Header closeButton className={"border-0"} style={{marginBottom: -42, zIndex: 1}}/>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={createOrUpdateHoliday}>
                        <Form.Group>
                            <Form.Label>{t("leaves_type")}  <span className={'required_box'}>*</span></Form.Label>
                            <Select noOptionsMessage={() => t("no_data_available")}
                                options={target_libelle_options}
                                defaultValue={selectedTargetLibelle}
                                styles={{
                                    control: base => ({
                                        ...base,
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("leaves_type")}
                                name='target_libelle'
                                onChange={handleTargetLibelle}
                                className={handleinputValidationError(formError,'targetLibelle')}
                                />
                                {handleTextValidationError(formError,'targetLibelle')}
                     
                        </Form.Group>
                        { target_libelle === 'autre'
                            ?
                        <Form.Group>
                            <Form.Label>{t("libelle")}  <span className={'required_box'}>*</span></Form.Label>
                            <Form.Control
                                required
                                size={"md"}
                                onChange={handleLibelle}
                                defaultValue={libelle}
                                className={handleinputValidationError(formError,'libelle')}
                                />
                                       {handleTextValidationError(formError,'libelle')}
                       </Form.Group>
                            : ''
                        }
                        <Form.Group>
                            <Form.Label>{t("description")}</Form.Label>
                            <Form.Control
                                required
                                as={'textarea'}
                                size={"lg"}
                                onChange={handleDesc}
                                defaultValue={desc}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t("number_of_days")}  <span className={'required_box'}>*</span></Form.Label>
                            <Form.Control
                                required
                                type={"number"}
                                size={"md"}
                                min={1}
                                onChange={handleNDays}
                                defaultValue={nDays}
                                className={handleinputValidationError(formError,'nDays')}
                                />
                                       {handleTextValidationError(formError,'nDays')}
                     
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t("assign_to")} </Form.Label>
                            <Select noOptionsMessage={() => t("no_data_available")}
                                options={target_type_options}
                                defaultValue={selectedTargetType}
                                styles={{
                                    control: base => ({
                                        ...base,
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("assign_to")}
                                name='target_type'
                                onChange={handleTargetType}
                            />
                        </Form.Group>
                        <Form.Group hidden={target_type!=='branches'}>
                            <Form.Label>{t("branch_title")} </Form.Label>
                            <Select noOptionsMessage={() => t("no_data_available")}
                                options={props.rwState.branches}
                                getOptionLabel={o => o.name}
                                getOptionValue={o => o._id}
                                defaultValue={props.rwState.branches.filter((b)=>selectedTarget?.includes(b._id))}
                                styles={{
                                    control: base => ({
                                        ...base,

                                        minHeight: 48,
                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("branch_title_unique")}
                                isMulti
                                onChange={handleSelect}
                            />
                        </Form.Group>
                        <Form.Group hidden={target_type!=='departments'}>
                            <Select noOptionsMessage={() => t("no_data_available")}
                                options={props.rwState.departments}
                                getOptionLabel={o => o.name}
                                getOptionValue={o => o._id}
                                defaultValue={props.rwState.departments.filter((b)=>selectedTarget?.includes(b._id))}
                                styles={{
                                    control: base => ({
                                        ...base,

                                        minHeight: 48,
                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("department_title_unique")}
                                isMulti
                                onChange={handleSelect}
                            />
                        </Form.Group>
                        <Form.Group hidden={target_type!=='positions'}>
                            <Select noOptionsMessage={() => t("no_data_available")}
                                options={props.rwState.positions}
                                getOptionLabel={o => o.title}
                                getOptionValue={o => o._id}
                                defaultValue={props.rwState.positions.filter((b)=>selectedTarget?.includes(b._id))}
                                styles={{
                                    control: base => ({
                                        ...base,

                                        minHeight: 48,
                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("position_title_unique")}
                                isMulti
                                onChange={handleSelect}
                            />
                        </Form.Group>
                        <Form.Group hidden={target_type!=='employee'}>
                            <Select noOptionsMessage={() => t("no_data_available")}
                                options={props.rwState.colleagues.filter(r=>r.activated)}
                                getOptionLabel={o => o.lastname+' '+o.firstname}
                                getOptionValue={o => o._id}
                                defaultValue={props.rwState.colleagues.filter((b)=>selectedTarget?.includes(b._id))}
                                styles={{
                                    control: base => ({
                                        ...base,
                                        minHeight: 48,
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("employee_text")}
                                isMulti
                                onChange={handleSelect}
                            />
                        </Form.Group>
                        <Modal.Footer className={'border-0 pt-0'}>
                            <Button type={"submit"}
                             disabled={
                                target_libelle === 'autre'? handleDisabledBtnValidationError(formError,
                                ['nDays','targetLibelle','libelle']) :
                                handleDisabledBtnValidationError(formError,
                                    ['nDays','targetLibelle'])
                            }
                            variant={"primary"} size={"lg"} block >
                                {t("save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>

            </Modal>
            <Modal centered show={show === 'ask'} onHide={handleClose}>
                <Modal.Header closeButton className={"border-0"} style={{marginBottom: -42, zIndex: 1}}/>
                <Modal.Body>
                    <Form  noValidate validated={validated} onSubmit={askLeave}>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>{t("leaves_type")}  <span className={'required_box'}>*</span></Form.Label>
                                <Select noOptionsMessage={() => t("no_data_available")}
                                    required
                                    isValid={false}
                                    options={props.rwState.leaveTypes}
                                    getOptionLabel={o => o.name}
                                    getOptionValue={o => o._id}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            minHeight: 48,
                                            // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                        })
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#6600CC',
                                            primary25: '#6600CC25',
                                            primary75: '#6600CC75',
                                            primary50: '#6600CC50'
                                        },
                                    })}
                                    placeholder={t("leaves_type")}
                                    onChange={handleLeaveType}
                                    className={handleinputValidationError(formError,'leaveType')}
                                    />
                                           {handleTextValidationError(formError,'leaveType')}
                         
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col  lg="6" sm="12">
                            <Form.Group>
                                <Form.Label>{("start_date")}  <span className={'required_box'}>*</span></Form.Label>
                                {/* <DayPickerInput
                                    required
                                    inputProps={{className: 'form-control form-control-lg'}}
                                    format="DD/MM/YYYY"
                                    formatDate={formatDate}
                                    placeholder={`${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                    value={from}
                                    parseDate={parseDate}
                                    dayPickerProps={{
                                        selectedDays: [from, {from, to}],
                                        disabledDays: {after: to},
                                        toMonth: to,
                                    }}
                                    onDayChange={handleFromChange}
                                    className={handleinputValidationError(formError,'fromChange')}
                                    /> */}

                                    <Form.Control
                                        onChange={handleFromChange}
                                        value={from}
                                        type={"datetime-local"}
                                        className={handleinputValidationError(formError,'fromChange')}
                                    />

                                           {handleTextValidationError(formError,'fromChange')}
                          </Form.Group>
                        </Col>
                        <Col lg="6" sm="12">
                            <Form.Group>
                                <Form.Label>{("end_date")}  <span className={'required_box'}>*</span></Form.Label>
                                <Form.Control
                                    onChange={handleToChange}
                                    min={from}
                                    value={to}
                                    type={"datetime-local"}
                                    className={handleinputValidationError(formError,'toChange')}
                                    />
                               {/*  <DayPickerInput
                                    disabled={!type}
                                    required
                                    inputProps={{className: 'form-control form-control-lg'}}
                                    format="DD/MM/YYYY"
                                    formatDate={formatDate}
                                    placeholder={`${formatDate(new Date(), 'DD/MM/YYYY', 'fr')}`}
                                    value={to}
                                    parseDate={parseDate}
                                    dayPickerProps={{
                                        selectedDays: [from, {from, to}],
                                        disabledDays: [{before: from},{after:new Date(moment(from).add('days', type?.days))}],
                                        modifiers,
                                    }}
                                    onDayChange={handleToChange}
                                    className={handleinputValidationError(formError,'toChange')}
                                    /> */}
                                           {handleTextValidationError(formError,'toChange')}
                         
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Observations  <span className={'required_box'}>*</span></Form.Label>
                                <Form.Control
                                    required
                                    as={'textarea'}
                                    size={"lg"}
                                    defaultValue={obs}
                                    onChange={handleObservation}
                                    className={handleinputValidationError(formError,'observation')}
                                    />
                                           {handleTextValidationError(formError,'observation')}
                         
                            </Form.Group>
                        </Col>
                    </Row>
                    <Modal.Footer className={'border-0 p-0 m-0'}>
                            <Button 
                             disabled={
                                handleDisabledBtnValidationError(formError,
                                ['observation','fromChange','toChange','leaveType']) || type===null}
                            type={"submit"} variant="primary" block size={"lg"} className={'m-0'}>
                                {t("save")}
                                
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>

            </Modal>
            <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
                <Modal.Header closeButton className={'border-0'}>
                    <Modal.Title>{t("confirmation")}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className={"text-center"}>
                        {t("confirme_irreversile_deletion_text")}
                    </div>
                </Modal.Body>
                <Modal.Footer className={'text-center border-0'}>
                    <div>
                        <div className={"text-center"}>
                            <Button variant="primary" onClick={deleteLeaveType}>
                                {t("yes_delete")}
                            </Button>
                            <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                                {t("cancel")}
                            </Button>
                        </div>
                    </div>

                </Modal.Footer>
            </Modal>

        </Container>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Holidays);
