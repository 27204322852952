import React, {useEffect, useState} from 'react';
import './Notifications.css'
import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import mSVG from "../../../assets/gender/m.png";
import {getFormatedDate} from "../../../helpers/helper"
import * as actions from "../../../actions";
import {connect} from "react-redux";
import {useHistory} from 'react-router-dom'
import {formatDate} from "react-datetime-picker/src/shared/dateFormatter";
import {getFileUrl} from "../../../service";
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';


const mapStateToProps = (state) => {
  return {rwState: state}
}
const mapDispatchToProps = {...actions}


const Notifications = (props) => {
    const { t } = useTranslation();
  const history = useHistory();
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    let user = JSON.parse(window.localStorage.getItem('glowhr_user')).user;
    const [show, setShow] = useState(null)
    const [current_row, setCurrentRow] = useState([])
    const [ppImage,setPpImage]=useState(mSVG)

    useEffect(()=>{
        props.action_loading(true)
        props.action_listNotification()
        props.action_setTitle(t("notification_title"))
    },[])
    const [employee, setEmployee] = useState()

    const redirectUser=(data,id)=>{
        setIsAlmostRead(id);
        setTimeout(function(){
        window.location.href=data
        },2000)
    }
    const setIsAlmostRead=(id)=>{
        let o={_id:id};
        o.read_at=new Date();
        props.action_updateNotification(o)
        props.action_loading(true)
    }
        //******************************************paginate filter data */
        useEffect(() => {
            setNotificationsFormationList([])
            if(props.rwState.notifications &&  props.rwState.notifications.length > 0) {
                let mylist = props.rwState.notifications;
                setNotificationsFormationList(mylist)
            }
          
           }, [props.rwState.notifications]);
       
        /*********************************************Notifications Begin paginating */
          // Notifications
          const [notificationsList, setNotificationsFormationList] = useState(undefined);
          const [CurrentNotificationsLists, setCurrentNotificationsLists] = useState(undefined);
          const [notificationsPageCount, setNotificationsPageCount] = useState(0);
          const [notificationsOffset, setNotificationsItemOffset] = useState(0);
          const [notificationsPerPage, setNotificationsItemsPerPage] = useState(8);
    
          useEffect(() => {
            if(notificationsList ) {
                const endOffset = notificationsOffset + notificationsPerPage;
                setCurrentNotificationsLists(notificationsList.slice(notificationsOffset, endOffset));
                setNotificationsPageCount(Math.ceil(notificationsList.length / notificationsPerPage));
            }
           }, [notificationsOffset, notificationsPerPage, notificationsList]);
       
           const handleNotificationsPageClick = (event) => {
            const newOffset = (event.selected * notificationsPerPage) % notificationsList.length;
            setNotificationsItemOffset(newOffset);
        };
        //********************************************* Notifications End paginating */
    
  return <div id={'Integration'} className={"col-12"}>
     <Row>
        <Col sm={12}>
          <div className={'mb-3 col-lg-12'}>
              <Row>
                  { CurrentNotificationsLists ?
                     CurrentNotificationsLists.map(p=>{
                     return  <Col sm={12} className={"mt-1"} id={"#"+p._id} style={{cursor:"pointer"}}>
                          <div className={'mt-3 notification col-12 p-4'} style={{background: p.read_at ? '#ffffff' : '#ececec'}} onClick={()=>redirectUser(p.link,p._id)}>

                              <h6 style={{fontSize:'10pt'}}>
                                  { p.isSystem ?
                                      <span><img src="../../../../dash-logo.png" alt="" width={"30px"}/> {t("administration")}</span>
                                      :
                                     <span> <img src={p.created_by ?( p.created_by.image_url.length>0?getFileUrl(p.created_by.image_url) : ppImage ) : ""} alt="" width={"30px"}/>
                                     &nbsp;&nbsp;&nbsp; {p.created_by.firstname} {p.created_by.lastname}
                                     </span>
                                  }
                                   <small className={"float-right"}>  {getFormatedDate(p.created_at)}</small></h6>
                              <h6> {p.title}</h6>
                             <p> {p.message} </p>
                          </div>
                      </Col>
                      })
                      : <Col sm={12} className={"text-center p-5"}>
                          {t("no_notification_available")}
                      </Col>
                  }
                   <Col md={12} className={"mt-3"}>
                                        <div hidden={!CurrentNotificationsLists || (CurrentNotificationsLists && CurrentNotificationsLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleNotificationsPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={notificationsPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div></Col>
              </Row>
          </div>
         </Col>
      </Row>


  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);

