import consts from './consts'
const env = {
    'DEV':{
         //apiUrl:'https://api-dev.glow-hr.com/api/v1',
         apiUrl: consts.baseUrl+'/api/v1',
        // docApiUrl:'https://doc-dev.glow-hr.com:4445',
        //docApiUrl:'http://localhost:8000',
    },
    urls:{
        zulip_url:'/zulip_url',
        login:'/auth/login',
        passwordReset:'/auth/password/reset/',
        passwordForgot:'/auth/password/forgot',
        register:'/auth/register',
        activation:'/auth/activate?linkid=',
        createBranch:'/branch/create',
        hiredEmployee:'/employee/hired',
        listNotification:'/notifications',
        updateNotifications:'/update/notification',
        listEvent:'/events',
        listParameters:'/parameters',
        updateParameters:'/parameters/update',
        createEvent:'/event/create',
        updateEvent:'/event/update',
        deleteEvent:'/event/delete/',
        listEventType:'/event/type',
        createDepart:'/depart/create',
        updateDepart:'/depart/update',
        deleteDepart:'/depart/delete/',
        listDepart:'/departs',
        createEventType:'/event/type/create',
        updateEventType:'/event/type/update',
        deleteEventType:'/event/type/delete/',
        createTypeDepense:'/expense/type/create',
        updateTypeDepense:'/expense/type/update',
        userFormation:'/user/formations',
        createFormation:'/formation/create',
        updateFormation:'/formation/update',
        changeFormationStatus:'/formation/update/status',
        deleteFormation:'/formation/delete/',
        createModuleFormation:'/modules/formation/create',
        updateModuleFormation:'/modules/formation/update',
        deleteModuleFormation:'/modules/formation/delete/',
        getModuleFormation:'/modules/formation/',
        userHasFinishModuleFormation:'/modules/formation/finish',
        createParcoursFormation:'/parcours/formation/create',
        updateParcoursFormation:'/parcours/formation/update',
        createTestsFormation:'/tests/formation/create',
        createTestsDoneFormation:'/tests/formation/done/create',
        updateTestsFormation:'/tests/formation/update',
        deleteTestsFormation:'/tests/formation/delete/',
        createAssignFormation:'/assign/formation/create',
        deleteAssignFormation:'/assign/formation/delete/',
        createSurvey:'/surveys/create',
        reviveSurvey:'/surveys/revive',
        updateSurvey:'/surveys/update',
        assignSurvey:'/surveys/assign',
        changeSurveyStatus:'/surveys/change/status',
        createSurveyQuestion:'/surveys/question/create',
        updateSurveyQuestion:'/surveys/question/update',
        deleteSurveyQuestion:'/surveys/question/delete/',
        deleteSurvey:'/surveys/delete/',
        updatePosition:'/position/update/',
        updateDepartement:'/department/update',
        updateBranch:'/branch/update',
        deleteTypeDepense:'/expense/type/delete/',
        deletePosition:'/position/delete/',
        deleteBranch:'/branch/delete/',
        deleteDepartement:'/department/delete/',
        listTypeDepense:'/expense/type',
        listFormation:'/formations',
        listModuleFormation:'/modules/formations',
        alllistModuleFormation:'/modules/formations/all',
        listParcoursFormation:'/parcours/formations',
        listTestsFormation:'/tests/formations',
        listAssignFormation:'/assign/formations/',
        listSurvey:'/surveys',
        oneSurvey:'/surveys/',
        submitSurvey:'/surveys/submit',
        listSurveyQuestion:'/surveys/questions',
        listBranch:'/branch',
        createDepartment:'/department/create',
        listDepartment:'/department',
        createPosition:'/position/create',
        createPost:'/recruitment/create',
        deletePost:'/recruitment/delete/',
        updatePost:'/recruitment/update',
        postChangeState:'/recruitment/change/state',
        listPositions:'/position',
        listPost:'/recruitment',
        listPostNoPaginate:'/recruitment/nopaginate',
        searchPost:'/recruitment/search',
        listPostApplicants:'/applicants/company',
        createCandidatureExcel:'/application/create/excel',
        exportCandidatureExcel:'/application/export/excel',
        detailsColleague:'/employee/',
        listColleagues:'/employee',
        createColleague:'/employee/create',
        createColleagueExcel:'/employee/create/excel',
        exportColleagueExcel:'/employee/export/excel',
        sendLinkToColleague:'/employee/sendCreatedLink',
        simpleUpdate:'/employee/simpleUpdate',
        organigram:'/employee/organogram',
        events:'/events',
        filterEvent:'/events/filter',
        leaveType:'/leave/type',
        visioConf:'/visio_conf',
        leave:'/leave',
        editLeave:'/edit/leave',
        suggestions:'/suggestion/create',
        suggestionUpdate:'/suggestion/update',
        deleteSuggestion:'/suggestion/delete/',
        commentSuggestion:'/suggestion/comment',
        deleteSuggestionComment:'/suggestion/comment/delete/',
        listSuggestions:'/suggestion',
        application:'/application',
        applicationReject:'/application/reject',
        applicationEngage:'/application/engage',
        references:'/application/references',
        referencesAccept:'/application/references/accept',
        referencesReject:'/application/references/reject',
        ping:'ping',
        upload_to_cdn: '/cdn/create',
        update_profil_doc: '/employee',
        update_profil_infos: '/employee',
        get_from_cdn: '/cdn',
        update_profile_documents: '/employee/add_documents',
        delete_profile_document: '/employee/remove_document',
        employee_birthday_status: '/employee/birthday_visibility',
        get_calendar_event: '/user/events',
        listRoles: '/roles',
        updateColleagues: '/employee',
        verifyColleagueLink: '/auth/employee/activate',
        activateColleagueAccount: '/auth/employee/activate',
        expenses:'/expense/create',
        expenseById:'/expense/detail/',
        settings:'/setting/create',
        payroll:'/payroll/create',
        listSettings:'/setting',
        deleteSettings:'/setting/delete/',
        listExpenses:'/expense',
        listPayroll:'/payroll/',
        Payroll:'/payroll/detail/',
        deleteExpense:'/expense/delete',
        validExpense:'/expense/valid',
        approbateExpense:'/expense/approbate',
        updateExpense:'/expense/update',
        updateSetting:'/setting/update',
        listInterView:'/interviews',
        createInterView:'/interviews/create',
        updateInterView:'/interviews/update',
        addParticipantInterView:'/interviews/participants/add',
        cancelInterView:'/interviews/cancel',
        listGoals:'/goals',
        getOneGoal:'/goals/',
        createGoal:'/goals/create',
        updateGoal:'/goals/update',
        deleteGoal:'/goals/delete/',
        assignGoal:'/goals/assign',
        appreciateGoal:'/goals/appreciate',
        removeEmpGoal:'/goals/remove_employee',
        createPlanAmelioration:'/plan_ameliorations',
        updatePlanAmelioration:'/plan_ameliorations',
        deletePlanAmelioration:'/plan_ameliorations/',
        listPlanAmelioration:'/plan_ameliorations',
        changeStatePlanAmelioration:'/plan_ameliorations',
        createAvertissment:'/avertissments',
        updateAvertissment:'/avertissments',
        deleteAvertissment:'/avertissments/',
        listAvertissment:'/avertissments',
        changeStateAvertissment:'/avertissments',
        createNews:'/news/create',
        listNews:'/news',
        changeNewsStatus:'',
        updateNews:'/news/update',
        deleteNews:'/news/delete/',
        createNewsletters:'/newsletters/create',
        listNewsletters:'/newsletters',
        changeNewslettersStatus:'',
        updateNewsletters:'/newsletters/update',
        deleteNewsletters:'/newsletters/delete/',
        updateUserData:'/user/update/data',
        companyAbonnement:'/abonnement/codes/update',
        listCompanyAbonnement:'/abonnement/codes/list',
        createDocument:'/document/create',
        listDocument:'/document',
        updateDocument:'/document/update',
        deleteDocument:'/document/delete/',
    }
}
export default env
