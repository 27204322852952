import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const Candidate = (props) => {
    const { t } = useTranslation();
    const [session, setSession] = useState({})
    useEffect(() => {
        const s = JSON.parse(localStorage.getItem('glowhr_user'))
        setSession(s)
    }, [])

    const handleApply = () => {
        let o = {
            "applicant": session.user.id,
            "company": props.post.company,
            "recruitment": props.post.id,
        }
        props.action_candidate(o)
        props.onHide()
    };

    return <div id={'Candidate'}>
        {t("application_on_post")} <strong>{props.post.title}</strong>?
        <div className={'mt-4 text-center'}>
            <Button variant="light" onClick={props.onHide}>
                {t("cancel")}
            </Button>
            <Button variant="primary" onClick={handleApply}>
                {t("apply_at")}
            </Button>
        </div>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Candidate);
