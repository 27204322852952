import React, {useEffect, useState} from 'react';
import './DetailsCollegues.css'
import {Button, Col, Container, Form, Modal, Row, Tab} from "react-bootstrap";
import ContextMenu from "../../menus/ContextMenu";
import * as RI from "react-icons/ri";
import * as FI from "react-icons/fi";
import * as BI from "react-icons/bi";
import * as BS from "react-icons/bs";
import {connect} from "react-redux";
import * as actions from "../../../actions";
import Select from "react-select";
import {useParams,useHistory} from 'react-router-dom'
import countries from "../../../assets/countries";
import {formatDate,} from 'react-day-picker/moment';
import {
    deleteProfileDocument,
    getFileFromCdn,
    getFileUrl,
    updateProfileDocuments,
    updateProfileInformations,
    uploadOnCdn
} from '../../../service'
import {useSocket} from '../../Custom-Hooks'
import {v1 as uuidv1} from 'uuid'
import mSvg from "../../../assets/gender/m.png";
import {BsUpload} from "react-icons/bs";
import {toast} from "react-toastify";
import {isAdmin,handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError} from "../../../helpers/helper";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return { rwState: state }
}
const mapDispatchToProps = { ...actions }

const DetailsCollegues = (props) => {
    const { t } = useTranslation();

    const history = useHistory();
    let { id } = useParams();
    let params_id=id;
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    const [user, setUser] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [cv, setCv] = useState({ key: 'cv', title: t('curriculum_vitae'), preview: '', raw: '', type: '', name: '' })
    const [rib, setRib] = useState({ key: 'rib', title: t("rib"), preview: '', raw: '', type: '', name: '' })
    const [cin, setCin] = useState({ key: 'cin', title: t("cni"), preview: '', raw: '', type: '', name: '' })
    const [doc, setDoc] = useState({ key: 'doc', title: t("doc"), preview: '', raw: '', type: '', name: '' })
    const [SIO_CHANNEL_UPDATE_DOCS] = useState('update_profile_doc_' + uuidv1())
    const [SIO_CHANNEL_DELETE_DOC] = useState('delete_profile_doc_' + uuidv1())
    const [SIO_CHANNEL_UPDATE_PROFIL_PERSO] = useState('update_profil_perso_' + uuidv1())
    const [SIO_CHANNEL_UPDATE_PROFIL_IMAGE] = useState('update_profil_image_' + user?.session_id)
    const [cdn_documents, setCdn_documents] = useState([])
    const [infos_documents, setInfos_documents] = useState([])
    const socketIoInstance = useSocket()
    const [current_documents, setCurrent_documents] = useState([])
    const [perso_form, setPerso_form] = useState({})
    const [personal, setPersonal] = useState()
    const [maritial, setMaritial] = useState()
    const personal_names = ['gender', 'birthdate','ethnie', 'nationality', 'physical_address', 'urgency_contact', 'nationalid_type', 'nationalid_num','health_state']
    const maritial_names = ['spouse', 'children', 'children_names']
    const gender_options = [{ label: t("man"), value: "H" }, { label: t("woman"), value: 'F' }, { label: t("other"), value: 'A' }]
    const ethnie_select_options = [
        {label: t("black_african"), value: 'noir'},
        {label: t("aboriginal"), value: 'autochtones'},
        {label: t("white"), value: 'blanc'},
        {label: t("middle_est"), value: 'moyen-orient'},
        {label: t("asian"), value: 'asiatique'},
        {label: ("latino"), value: 'hispanique-latino'},
    ]
    const rh_type_branch = [{label: t('all_branches'), value: 'all'}, {label:t('some_branches'), value: 'branches'}]
    const rh_type_department = [{label: t('all_departments'), value: 'all'}, {label:t('some_departments'), value: 'departments'}]
    const [selected_ethnie, setSelectedEthnie] = useState()
    const statut_matrimonial = [{ label: t("celibate"), value: 0 }, { label: t("maried"), value: 1 }, { label: t("other"), value: 9 }]
    const [selectedGender, setSelectedGender] = useState()
    const [selectedNationality, setSelectedNationality] = useState()
    const [selectedStatutMatrimonial, setSelectedStatutMatrimonial] = useState()
    const [tabFileViewer, setTabFileViewer] = useState([]);
    const [ppImage,setPpImage]=useState(mSvg)
    const [birthday_visible,setBirthDayVisible]=useState()
    const [selectedRhTypeDepartment, setSelectedRhTypeDepartment] = useState(rh_type_department[0])
    const [selectedRhDepartment, setSelectedRhDepartment] = useState()
    const [selectedRhTypeBranch, setSelectedRhTypeBranch] = useState(rh_type_branch[0])
    const [selectedRhBranch, setSelectedRhBranch] = useState()
    const [selectedBranch, setSelectedBranch] = useState()
    const [selectedDepartment, setSelectedDepartment] = useState()
    const [selectedRole, setSelectedRole] = useState()
    const [formError, setFormError] = useState({})

    useEffect(() => {
        props.action_loading(true);
        props.action_detailsColleague(params_id)
        props.action_listColleagues()
        props.action_listDepartments();
        props.action_listBranches();
        props.action_listPositions();
        props.action_listRoles();
    }, [])
 useEffect(() => {
     props.action_setTitle(t("collegue_detail_text"))
     if(props.rwState.colleague){
         let connected_user={};
          connected_user.user=props.rwState.colleague;
         setUser(connected_user)
     }
    }, [props.rwState.colleague])

 useEffect(() => {
     if(user){
         let connected_user=user;
         setp(Math.floor(Math.random() * 100));
         setPpImage(connected_user.user.image_url.length > 0 ? getFileUrl(connected_user.user.image_url) : ppImage)
         setPersonal(connected_user.user.personal)
         setMaritial(connected_user.user.maritial)
         setCurrent_documents(connected_user.user.documents ? connected_user.user.documents : [])
         setSelectedNationality(countries.find(option => option.alpha3Code === connected_user?.user?.personal?.nationality))
         setSelectedGender(gender_options.find(option => option.value === connected_user?.user?.personal?.gender))
         setSelectedEthnie(ethnie_select_options.find(option => option.value === connected_user?.user?.personal?.ethnie))
         setSelectedStatutMatrimonial(statut_matrimonial.find(option => option.value === connected_user?.user?.maritial?.spouse))
         setBirthDayVisible(connected_user?.user?.birthday_visible);
         setSelectedBranch(props.rwState.branches.filter(option => option._id === connected_user?.user?.branch._id))
         setSelectedDepartment(props.rwState.departments.filter(option => option._id === connected_user?.user?.department._id))
         setSelectedRole(props.rwState.roles?.filter(option => option._id === connected_user?.user?.role_info?._id))
         let data=  handleFinaleValidation(t,[
            {type:"text",name:'gender','value':connected_user?.user?.personal?.gender,required:true},
            {type:"text",name:'nationality','value':connected_user?.user?.personal?.nationality,required:true},
            {type:"text",name:'ethnie','value':connected_user?.user?.personal?.ethnie,required:true},
            {type:"text",name:'firstname','value':connected_user?.user?.firstname,required:true},
            {type:"text",name:'lastname','value':connected_user?.user?.lastname,required:true},
            {type:"text",name:'birthdate','value':connected_user?.user?.personal?.birthdate,required:true},
            {type:"text",name:'email','value':connected_user?.user?.email,required:true},
            {type:"text",name:'mobile','value':connected_user?.user?.mobile,required:true},
            {type:"text",name:'physical_address','value':connected_user?.user?.personal.physical_address,required:true},
            {type:"text",name:'urgency_contact','value':connected_user?.user?.personal?.urgency_contact,required:true}
           ])
                setFormError(data)
        }
    }, [user])

    useEffect(() => {
        if (infos_documents.length > 0 && infos_documents.length === cdn_documents.length) {
            listenUpdateProfileResponse()
            updateProfileDocuments(infos_documents, SIO_CHANNEL_UPDATE_DOCS)
                .then(resp => {
                    setCdn_documents([])
                    setInfos_documents([])
                    reset()
                })
                .catch(err => console.log(err))
        }
    }, [infos_documents])

    const contextMenu = isAdmin(role) ? [
        { label: t('profil'), eventKey: 'perso' },
            { label: t("profil_professionnel"), eventKey: 'pro' },
            { label: t('documents'), eventKey: 'docs' }
        ]

        : [ { label: t('profil'), eventKey: 'perso' }
    ]

    const handleCvChange = e => {
        let stateE = typeof e
        if (stateE !== 'string') {
            let cv_data = e.target.files[0]
            const name = cv_data.name
            const type = name.split('.').pop()
            let preview = URL.createObjectURL(cv_data)
            let test = { ...cv, preview, raw: cv_data, type, name }
            setCv(test)
            setCdn_documents([...cdn_documents, { key: cv.key, title: cv.title, raw: cv_data }])
            setTabFileViewer(test)
            console.log(tabFileViewer)
        } else {
            let preview = e
            let test = { ...cv, preview }
            setCv(test)
            setCdn_documents([...cdn_documents, { key: cv.key, title: cv.title }])
            setTabFileViewer(test)
        }
    }

    const handleRibChange = e => {
        let stateE = typeof e

        if (stateE !== 'string') {
            let rib_data = e.target.files[0]
            let name = rib_data.name
            let type = name.split('.').pop()
            let new_cdn_documents_state = [...cdn_documents, { key: rib.key, title: rib.title, raw: rib_data }]
            setCdn_documents(new_cdn_documents_state)

            let preview_url = URL.createObjectURL(rib_data)
            let new_rib_state = { ...rib, preview: preview_url, raw: rib_data, type, name }
            setRib(new_rib_state)
        } else {
            let new_cdn_documents_state = [...cdn_documents, { key: rib.key, title: rib.title }]
            setCdn_documents(new_cdn_documents_state)

            let preview_url = e
            let new_rib_state = { ...rib, preview: preview_url }
            setRib(new_rib_state)
        }

    }

    const handleCinChange = e => {
        let stateE = typeof e

        if (stateE !== 'string') {
            let cin_data = e.target.files[0]
            const name = cin_data.name
            const type = name.split('.').pop()
            let preview_url = URL.createObjectURL(cin_data)
            setCin({ ...cin, preview: preview_url, raw: cin_data, type, name })
            setCdn_documents([...cdn_documents, { key: cin.key, title: cin.title, raw: cin_data }])
            console.log(cin)
            console.log(tabFileViewer)
        } else {
            let preview_url = e
            setCin({ ...cin, preview: preview_url })
            setCdn_documents([...cdn_documents, { key: cin.key, title: cin.title }])
        }


    }

    const handleDocChange = e => {
        let stateE = typeof e

        if (stateE !== 'string') {
            let doc_data = e.target.files[0]
            const name = doc_data.name
            const type = name.split('.').pop()
            let preview_url = URL.createObjectURL(doc_data)
            setDoc({ ...doc, preview: preview_url, raw: doc_data, type, name })
            setCdn_documents([...cdn_documents, { key: doc.key, title: doc.title, raw: doc_data }])
        } else {
            let preview_url = e
            setDoc({ ...doc, preview: preview_url })
            setCdn_documents([...cdn_documents, { key: doc.key, title: doc.title }])
        }

    }

    const previewError = e => {
        console.log(e, 'error in file-viewer')
    }

    const reset = () => {
        setCv({ key: 'cv', title: t('curriculum_vitae'), preview: '', raw: '', type: '', name: '' })
        setRib({ key: 'rib', title: t('rib'), preview: '', raw: '', type: '', name: '' })
        setCin({ key: 'cin', title: t('cni'), preview: '', raw: '', type: '', name: '' })
        setDoc({ key: 'doc', title: t('doc'), preview: '', raw: '', type: '', name: '' })
        setCdn_documents([])
        setInfos_documents([])
    }

    const save_files = () => {
        props.action_loading(true)
        let new_infos_documents_state = [...infos_documents]
        cdn_documents.forEach(value => {
            console.log('current elt : ', value)
            uploadOnCdn(value.raw)
                .then(resp => {
                    new_infos_documents_state = [...new_infos_documents_state, { type: value.key, title: value.title, image_name: resp.filename }]
                    setInfos_documents(new_infos_documents_state)
                })
                .catch((error )=>{
                    console.log(error);
                })
        })
    }

    const listenUpdateProfileResponse = () => {
        socketIoInstance.on(SIO_CHANNEL_UPDATE_DOCS, response => {
            if (response && response.status === 200) {
                const documents = response.data.documents
                let new_state = []
                documents.forEach(val => new_state = [...new_state, val])
                setCurrent_documents(new_state);
                user.user = response.data.user
               // window.localStorage.setItem('glowhr_user', JSON.stringify(user))
                setUser(user);
                toast(t("profil_successfully_updates"),{type:"success"});
            }
            socketIoInstance.off(SIO_CHANNEL_UPDATE_DOCS)
            props.action_loading(false)
        })
    }

    const listenDeleteOneProfileDocumentResponse = () => {
        socketIoInstance.on(SIO_CHANNEL_DELETE_DOC, response => {
            if (response && response.status === 200) {
                console.log('data : ', response.data)
                const documents = response.data.documents
                setCurrent_documents(documents)
               // user.user = response.data.user
               // window.localStorage.setItem('glowhr_user', JSON.stringify(user))
                setUser(user);
                toast(t("profil_successfully_updates"),{type:"success"});
            }
            socketIoInstance.off(SIO_CHANNEL_DELETE_DOC)
            props.action_loading(false)
        })
    }

    const downloadDocument = image_key => {
        console.log('image_key : ', image_key)
        getFileFromCdn(image_key)
            .then(response => {
                const blob = new Blob([response], { type: 'image/jpeg' })
                const url = URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.download = image_key
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .catch(err => console.log('error get from cdn : ', err))
    }

    const deleteDocument = image_key => {
        props.action_loading(true)
        console.log(`image_key = ${image_key}`)
        listenDeleteOneProfileDocumentResponse()
        deleteProfileDocument(image_key, SIO_CHANNEL_DELETE_DOC)
    }


    const persoInfosChange = e => {
        let name = e.target.name
        let value = e.target.value
        // console.log('name : ', name)
        if (personal_names.includes(name)) {
            let personal_new_state = { ...personal, [name]: value }
            setPersonal(personal_new_state)
        } else if (maritial_names.includes(name)) {
            let maritial_new_state = { ...maritial, [name]: value }
            setMaritial(maritial_new_state)
        } else if (name === 'name') {
            let maritial_new_state = { ...maritial, spouse_details: { [name]: value } }
            setMaritial(maritial_new_state)
        } else {
            setPerso_form({ ...perso_form, [name]: value })
        }
        //Faire un controle suivant chaque champs necessitant des restrictions
        let data={};
        switch(name){
            case 'birthdate':
                const d = new Date();
                d.setFullYear(d.getFullYear()-18);
                data =handleValidation(t,{type:"date",name: name,'value':value,required:true,'max':new Date(d)});  
                break
            case 'mobile' || 'urgency_contact' || 'nationalid_num':
                data =handleValidation(t,{type:"tel",name: name,'value':value,required:true});
                break
            case 'email' :
                data =handleValidation(t,{type:"email",name: name,'value':value,required:true});
                break
            case 'children' :
                data =handleValidation(t,{type:"number",name: name,'value':value,required:true});
                break
            default:
                    data =handleValidation(t,{type:"text",name: name,'value':value,required:true});
                break;
        }
        let form=formError;
        form[data.name]=data   
        setFormError(form)
    }

    const updateProfilPerso = () => {
        setIsLoading(true)
        props.action_loading(true);
        const userPersonal = user?.user.personal
       if (Object.getOwnPropertyNames(personal).length < Object.getOwnPropertyNames(userPersonal).length) {
            if (Object.getOwnPropertyNames(personal).length >= 0) {
                for (const user in userPersonal) {
                    if (personal.hasOwnProperty(user) === false) {
                        personal[user] = userPersonal[user]
                         }
                }
                callService();
                props.action_loading(false)
                setIsLoading(false)
            }

        } else {
            callService()
            props.action_loading(false)
            setIsLoading(false)
        }
    }

    const callService = () => {
        props.action_loading(true);

        let perso_form_state = { ...perso_form, personal, maritial }
        const form_data = { ...user?.user, ...perso_form_state }
        listenUpdateProfilPersoResponse()
        updateProfileInformations(form_data, SIO_CHANNEL_UPDATE_PROFIL_PERSO)
            .then(resp => {
                console.log('resp => ', resp)
                setPerso_form({})
                setPersonal({})
                setMaritial({})
            })
            .catch(err => console.log('err => ', err))
    }

    const listenUpdateProfilPersoResponse = () => {
        socketIoInstance.on(SIO_CHANNEL_UPDATE_PROFIL_PERSO, response => {
            if (response && response.status === 200) {
                user.user = response.data.user
                window.localStorage.setItem('glowhr_user', JSON.stringify(user))
                setUser(user);
                toast(t("profil_successfully_updates"),{type:"success"});
            }
            socketIoInstance.off(SIO_CHANNEL_UPDATE_PROFIL_PERSO)
            props.action_loading(false)
        })
    }

    const handleDayChange = (selectedDay, modifiers, dayPickerInput) => {
        const input = dayPickerInput.getInput()
        const name = input.name
        const value = input.value
        if (name === 'birthdate') {
            setPersonal({ ...personal, [name]: value })
        }
    }

    const handleSelectChange = (selected_option, action) => {
        const name = action.name;let value=undefined;
        if (name === 'gender') {
            setSelectedGender(selected_option)
            setPersonal({ ...personal, [name]: selected_option.value })
            value=selected_option.value;
        } else if (name === 'nationality') {
            setSelectedNationality(selected_option)
            setPersonal({ ...personal, [name]: selected_option.alpha3Code })
            value=selected_option.alpha3Code;
        } else if (name === 'spouse') {
            setSelectedStatutMatrimonial(selected_option)
            setMaritial({ ...maritial, [name]: selected_option.value })
            value=selected_option.value;
        } else if (name === 'ethnie') {
            setSelectedEthnie(selected_option)
            setPersonal({ ...personal, [name]: selected_option.value })
            value=selected_option.value;
        }
      let  data =handleValidation(t,{type:"text",name: name,'value':value,required:true});
        let form=formError;
        form[data.name]=data   
        setFormError(form)
    }

    const [show,setShow]= useState();
    const [fileInput,setFileInput]= useState();
    const [imageFile,setImageFile]= useState();
    const getSupervisor = supervisor_id => {
        let supervisor = props.rwState.colleagues.filter(option => option._id === supervisor_id)
        supervisor = supervisor[0]
        let full_name = `${supervisor.firstname} ${supervisor.lastname}`
        console.log(full_name)
        return full_name
    }
    const [pourcent,setp] = useState()
    const pclass = 'c100 p'+pourcent

    //console.log(user?.user?.personal.gender.label)
    const handlePPSelect = (e)=>{
        setImageFile(e.target.files[0]);
        setShow('confirm');
    };
    const updatePpImage = ()=>{
        props.action_loading(true);
        const url = URL.createObjectURL(imageFile);
        setPpImage(url)
        uploadOnCdn(imageFile)
            .then(resp => {
                let filename = resp.filename;
                let e = {
                    target :{value:filename,name:"image_url"}
                };
                const form_data = { ...user?.user, ...{image_url:filename}}
                listenUpdateProfilPersoResponse();
                updateProfileInformations(form_data,SIO_CHANNEL_UPDATE_PROFIL_PERSO)
                setShow(null)
            })
            .catch(error => console.log(error))

    };
    const changeBirthdayStatus = () =>{
        props.action_loading(true);
        props.action_changeBirthdayStatus({employee:user?.user?._id});
        setBirthDayVisible(!birthday_visible)

    }
    return <div id={'Profile'}>
        <Container fluid>
            <Row>
                <Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="perso">
                        <Row>
                            <Col sm={"12"} className={'text-left'}>
                                <Button className={"btn btn-light btn-sm"} onClick={e => history.push({pathname:'/dash/colleagues'})}>
                                    <BI.BiChevronLeft style={{color: '#240265', fontWeight: '600',}} size={25}/> Retour
                                </Button>
                            </Col>
                            <Col sm={"12"}>
                                <div className="d-flex align-items-start justify-content-between">
                                    <ContextMenu menu={contextMenu} />
                                </div>
                                { isAdmin(role)
                                    ?
                                    <Tab.Content>
                                        <Tab.Pane eventKey="perso">
                                            <Row>
                                                <Col>
                                                    <div className={'clearfix d-flex justify-content-center'}>
                                                        <label htmlFor={"pp"}>
                                                        <span>
                                                        <img style={{border:"3px solid red"}} src={ppImage} alt="" height={150} width={150} className={'rounded-circle'}/>
                                                    </span>
                                                            <Button onClick={()=>fileInput.click()} style={{position:"absolute",bottom:"-5%",right:"44%",borderRadius:"50%"}}><BsUpload/></Button>
                                                        </label>
                                                        <input ref={input => setFileInput(input)} type="file" onChange={handlePPSelect} id={"pp"} accept={"image/*"} style={{ display: 'none' }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("last_name")}</Form.Label>
                                                        <Form.Control name='lastname' disabled type={"text"} onChange={persoInfosChange} size={"lg"} defaultValue={user?.user?.lastname} 
                                                         className={handleinputValidationError(formError,'lastname')}
                                                         />
                                                                {handleTextValidationError(formError,'lastname')}
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("first_name")}</Form.Label>
                                                        <Form.Control name='firstname' disabled onChange={persoInfosChange} size={"lg"} defaultValue={user?.user?.firstname} 
                                                         className={handleinputValidationError(formError,'firstname')}
                                                         />
                                                                {handleTextValidationError(formError,'firstname')}
                                                    </Form.Group>
                                                </Col>

                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("sex")}</Form.Label>
                                                        <Select noOptionsMessage={() => t("no_data_available")}
                                                            options={gender_options}
                                                            // value={gender_options.map(option => {
                                                            //     if(option.value === user?.user?.personal?.gender) {
                                                            //         return option
                                                            //     }
                                                            // })}
                                                            value={selectedGender}
                                                            styles={{
                                                                control: base => ({
                                                                    ...base,
                                                                    minHeight: 48
                                                                })
                                                            }}

                                                            theme={theme => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary: '#6600CC',
                                                                    primary25: '#6600CC25',
                                                                    primary75: '#6600CC75',
                                                                    primary50: '#6600CC50'
                                                                },
                                                            })}
                                                            placeholder={t("sex")}
                                                            name='gender' disabled
                                                            onChange={handleSelectChange}
                                                            className={handleinputValidationError(formError,'gender')}
                                                            />
                                                                   {handleTextValidationError(formError,'gender')}

                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Row>
                                                            <Col md={11}>
                                                                <Form.Group>
                                                                    <Form.Label>{t("birthday")}</Form.Label>
                                                                    <Form.Control
                                                                        type={'date'} disabled
                                                                        defaultValue={user && user?.user?.personal?.birthdate ? formatDate(user?.user?.personal?.birthdate , 'YYYY-MM-DD') : ''}
                                                                        name='birthdate'
                                                                        onChange={persoInfosChange} size={"lg"}
                                                                        className={handleinputValidationError(formError,'birthdate')}
                                                                        />
                                                                               {handleTextValidationError(formError,'birthdate')}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={1} style={{marginTop:"15px",marginLeft:"-15px"}} className={"d-flex justify-content-center align-items-center"}>
                                                                <Button title={JSON.parse(window.localStorage.getItem('glowhr_user')).user.birthday_visible ? t("set_birthday_visibility_to_private"):t("set_birthday_visibility_to_public")} onClick={changeBirthdayStatus} variant={"link"} size={"sm"}>
                                                                    {
                                                                        JSON.parse(window.localStorage.getItem('glowhr_user')).user.birthday_visible ?<BS.BsEyeSlash size={22}/> : <BS.BsEye size={22}/>
                                                                    }
                                                                </Button>
                                                            </Col>
                                                        </Row>

                                                    </Form.Group>
                                                </Col>

                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("nationnality")}</Form.Label>
                                                        <Select noOptionsMessage={() => t("no_data_available")}
                                                            options={countries}
                                                            getOptionLabel={o => o.name}
                                                            getOptionValue={o => o.alpha3Code}
                                                            value={selectedNationality}
                                                            styles={{
                                                                control: base => ({
                                                                    ...base,
                                                                    minHeight: 48,
                                                                })
                                                            }}
                                                            theme={theme => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary: '#6600CC',
                                                                    primary25: '#6600CC25',
                                                                    primary75: '#6600CC75',
                                                                    primary50: '#6600CC50'
                                                                },
                                                            })} disabled
                                                            placeholder={t("nationnality")}
                                                            name='nationality'
                                                            onChange={handleSelectChange}
                                                            className={handleinputValidationError(formError,'nationality')}
                                                            />
                                                                   {handleTextValidationError(formError,'nationality')}
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("ethni")}</Form.Label>
                                                        <Select noOptionsMessage={() => t("no_data_available")}
                                                            options={ethnie_select_options}
                                                            value={selected_ethnie}
                                                            styles={{
                                                                control: base => ({
                                                                    ...base,
                                                                    minHeight: 48
                                                                })
                                                            }}

                                                            theme={theme => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary: '#6600CC',
                                                                    primary25: '#6600CC25',
                                                                    primary75: '#6600CC75',
                                                                    primary50: '#6600CC50'
                                                                },
                                                            })}
                                                            placeholder={t("ethni")}
                                                            name='ethnie' disabled
                                                            onChange={handleSelectChange}
                                                            className={handleinputValidationError(formError,'ethnie')}
                                                            />
                                                                   {handleTextValidationError(formError,'ethnie')}
                                                 

                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("email")}</Form.Label>
                                                        <Form.Control name='email' onChange={persoInfosChange} disabled size={"lg"} defaultValue={user?.user?.email} 
                                                         className={handleinputValidationError(formError,'email')}
                                                         />
                                                                {handleTextValidationError(formError,'email')}
                                              
                                                    </Form.Group>
                                                </Col>

                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("tel")}</Form.Label>
                                                        <Form.Control name='mobile' type={'number'} maxlength={14} disabled onChange={persoInfosChange} size={"lg"} defaultValue={user?.user?.mobile} 
                                                         className={handleinputValidationError(formError,'mobile')}
                                                         />
                                                                {handleTextValidationError(formError,'mobile')}
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("address_physic")}</Form.Label>
                                                        <Form.Control
                                                            size={"lg"}
                                                            defaultValue={user?.user?.personal.physical_address} disabled name='physical_address' onChange={persoInfosChange} 
                                                            className={handleinputValidationError(formError,'physical_address')}
                                                            />
                                                                   {handleTextValidationError(formError,'physical_address')}
                                                 
                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("health_status")}</Form.Label>
                                                        <Form.Control size={"lg"} name='health_state' disabled defaultValue={user?.user?.personal?.health_state} onChange={persoInfosChange} />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("nationnal_number")}</Form.Label>
                                                        <Form.Control
                                                            size={"lg"} disabled
                                                            type={'number'} max={14}
                                                            defaultValue={user?.user?.personal?.nationalid_num}
                                                            name='nationalid_num'
                                                            onChange={persoInfosChange}
                                                            className={handleinputValidationError(formError,'nationalid_num')}
                                                            />
                                                                   {handleTextValidationError(formError,'nationalid_num')}
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("marital_status")}</Form.Label>
                                                        <Select noOptionsMessage={() => t("no_data_available")}
                                                            options={statut_matrimonial}
                                                            name='spouse'
                                                            value={selectedStatutMatrimonial}
                                                            styles={{
                                                                control: base => ({
                                                                    ...base,

                                                                    minHeight: 48,
                                                                })
                                                            }}
                                                            theme={theme => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary: '#6600CC',
                                                                    primary25: '#6600CC25',
                                                                    primary75: '#6600CC75',
                                                                    primary50: '#6600CC50'
                                                                },
                                                            })} disabled
                                                            placeholder={t("marital_status")}
                                                            onChange={handleSelectChange}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("spouse_name")}</Form.Label>
                                                        <Form.Control
                                                            size={"lg"}
                                                            defaultValue={user?.user?.maritial?.spouse_details?.name}
                                                            name='name' disabled
                                                            onChange={persoInfosChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("number_children")}</Form.Label>
                                                        <Form.Control size={"lg"}
                                                        type={'number'} min={'0'}
                                                                      defaultValue={user?.user?.maritial.children}
                                                                      name='children' disabled
                                                                      onChange={persoInfosChange} />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("children_names")}</Form.Label>
                                                        <Form.Control size={"lg"}
                                                                      defaultValue={user?.user?.maritial.children_names} name='children_names' onChange={persoInfosChange} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                            <Col lg={4} xs={12}>
                                                <Form.Group>
                                                    <Form.Label>{t("urgent_contact_name")} <span className={'required_box'}>*</span> </Form.Label>
                                                    <Form.Control size={"lg"} type={'text'} name='urgency_contact_name' disabled
                                                     defaultValue={user?.user?.personal?.urgency_contact_name}
                                                      onChange={persoInfosChange} 
                                                      className={handleinputValidationError(formError,'urgency_contact_name')}
                                                      />
                                                             {handleTextValidationError(formError,'urgency_contact_name')}
                                                </Form.Group>
                                            </Col>
                                                <Col lg={4} xs={12}>
                                                    <Form.Group>
                                                        <Form.Label>{t("urgent_contact")}</Form.Label>
                                                        <Form.Control size={"lg"} type={'number'} disabled name='urgency_contact' defaultValue={user?.user?.personal?.urgency_contact} onChange={persoInfosChange} 
                                                          className={handleinputValidationError(formError,'urgency_contact')}
                                                          />
                                                                 {handleTextValidationError(formError,'urgency_contact')}
                                                  
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {/* <Row className='justify-content-end mt-4'>
                                                <Col sm='auto'>
                                                    <Button className='mr-2' disabled={isLoading} variant="light" onClick={e => history.push({pathname:'/dash/colleagues'})}>Retour</Button>
                                                    <Button loading={isLoading} 
                                                     disabled={
                                                        handleDisabledBtnValidationError(
                                                            formError,
                                                            ['gender','nationality','ethnie','firstname','lastname','birthdate','email','mobile','physical_address','urgency_contact']
                                                            )
                                                             || isLoading
                                                    }  onClick={updateProfilPerso}>{isLoading ? 'en cours' : 'Mettre à jour'}</Button>
                                                </Col>
                                            </Row> */}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="pro">
                                        <Row>
                                         <div className={'col-12'}>
                                         <div  className={'col-12 mt-4 p-4 mb-4'} style={{background: '#fff'}}>
                                             <h6>{t("assigned_branches")} :</h6>
                                             <div>
                                                 {user?.user?.rh_type_branch ?
                                                    (user?.user?.rh_type_branch==='all' ? 
                                                    <span className={'badge badge-success'}>{t("all_branches")}</span> :
                                                    user?.user?.rh_branch ? 
                                                        (user?.user?.rh_branch.length>0 ? 
                                                            user?.user?.rh_branch.map(res=>{
                                                                return <span>{props.rwState.branches.find(br=>br._id===res)?.name}</span>
                                                            })
                                                            : <span className={'badge badge-warning'}>{t("no_assigned_branches")}</span>) 
                                                        :'')
                                                 : ''
                                                 }
                                             </div>
                                         </div>
                                         </div>
                                     </Row>
                                     <Row>
                                         <div className={'col-12 mt-4 mb-4'} >
                                             <div  className={'col-12 p-4 mb-4'} style={{background: '#fff'}}>
                                             <h6>{t("assigned_departments")} :</h6>
                                             <div>
                                                 {user?.user?.rh_type_department ?
                                                    (user?.user?.rh_type_department==='all' ? 
                                                    <span className={'badge badge-success'}>{t("all_departments")}</span> :
                                                    user?.user?.rh_department ? 
                                                        (user?.user?.rh_department.length>0 ? 
                                                            user?.user?.rh_department.map(res=>{
                                                                return <span>{props.rwState.departments.find(br=>br._id===res)?.name}</span>
                                                            })
                                                            : <span className={'badge badge-warning'}>{t("no_assigned_departments")}</span>) 
                                                        :'')
                                                 : ''
                                                 }
                                             </div>
                                             </div>
                                         </div>
                                     </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("branch_title_unique")}</Form.Label>
                                                        <Form.Control disabled size={"lg"} defaultValue={props.rwState.branches.filter(option => option._id === user?.user?.branch._id).map(e => e.name).join('')} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("hired_date")}</Form.Label>
                                                        <Form.Control disabled size={"lg"} value={formatDate(user?.user?.job_info[0]?.created_date, 'DD/MM/YYYY', 'fr')} />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("direct_supervisor")}</Form.Label>
                                                        <Form.Control disabled size={"lg"} defaultValue={user?.user?.job_info[0]?.reports_to && props.rwState.colleagues ? props.rwState.colleagues.filter(elt => elt._id === user.user?.job_info[0]?.reports_to).map(e => e.firstname + ' ' + e.lastname).join('') : ''} />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("departure_date")}</Form.Label>
                                                        <Form.Control disabled size={"lg"} value={user?.user?.job_info[0]?.start_date ? formatDate(new Date(user?.user?.job_info[0]?.start_date), 'DD/MM/YYYY', 'fr') : ''} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("department_title_unique")}</Form.Label>
                                                        <Form.Control disabled size={"lg"} defaultValue={props.rwState.departments.filter(option => option._id === user?.user?.department._id).map(e => e.name).join('')} />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("probationary_period")}</Form.Label>
                                                        <Form.Control disabled size={"lg"} value={formatDate(new Date(user?.user?.job_info[0].probation?.probation_start), 'DD/MM/YYYY', 'fr')} />
                                                    </Form.Group>
                                                </Col>

                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("position_held")}</Form.Label>
                                                        <Form.Control disabled size={"lg"}
                                                                      value={
                                                                          user?.user?.position
                                                                              ? user?.user?.position?.title
                                                                              : ''} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("tel")}</Form.Label>
                                                        <Form.Control disabled size={"lg"} defaultValue={user?.user?.mobile} />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>{t("salary")}</Form.Label>
                                                        <Form.Control disabled size={"lg"} defaultValue={user?.user?.job_info[0].salary} />
                                                    </Form.Group>
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                    
                                     
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="docs">
                                            <div id="persoDocs" className={"mt-5 mb-4"} >
                                                <div className={'title p-3'}>{t("documents")}</div>
                                                <div>
                                                    {
                                                        current_documents.map((curr_doc, index) => (
                                                            <div className={'docItem d-flex align-items-center'} key={index}>
                                                                <div className={'docIcon p-3'}>
                                                                    <BsFileText size={48} />
                                                                </div>
                                                                <div
                                                                    className="d-flex align-content-center justify-content-between w-100">
                                                                    <div>
                                                                        <div className={'docName mb-3'}>{curr_doc.title}</div>
                                                                        <div className={'docDetails'}></div>
                                                                    </div>
                                                                    <div className={'p-3 pr-4'}>
                                                                        <RI onClick={() => deleteDocument(curr_doc.image_name)} color={'#FC5565'} size={22} />
                                                                        <RI.RiDownloadLine onClick={() => downloadDocument(curr_doc.image_name)} className="ml-2" color={'#240A63'} size={22} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                    :
                                <Tab.Content>
                                    <Tab.Pane eventKey="perso">
                                        <Row>
                                            <Col>
                                            <div className={'clearfix d-flex justify-content-center'}>
                                                  <label htmlFor={"pp"}>
                                                        <span>
                                                        <img style={{border:"3px solid red"}} src={ppImage} alt="" height={150} width={150} className={'rounded-circle'}/>
                                                    </span>
                                                </label>
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t("last_name")}</Form.Label>
                                                    <Form.Control disabled name='lastname' onChange={persoInfosChange} size={"lg"} defaultValue={user?.user?.lastname} />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t("first_name")}</Form.Label>
                                                    <Form.Control disabled name='firstname' onChange={persoInfosChange} size={"lg"} defaultValue={user?.user?.firstname} />
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t("sex")}</Form.Label>
                                                    <Select noOptionsMessage={() => t("no_data_available")}
                                                        isDisabled={true}
                                                        options={gender_options}
                                                        value={gender_options.map(option => {
                                                            if(option.value === user?.user?.personal?.gender) {
                                                                return option
                                                            }
                                                        })}
                                                        styles={{
                                                            control: base => ({
                                                                ...base,
                                                                minHeight: 48
                                                            })
                                                        }}

                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: '#6600CC',
                                                                primary25: '#6600CC25',
                                                                primary75: '#6600CC75',
                                                                primary50: '#6600CC50'
                                                            },
                                                        })}
                                                        placeholder={t("sex")}
                                                        name='gender'
                                                        onChange={handleSelectChange}
                                                    />

                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Row>

                                            <Col hidden={!user?.user?.birthday_visible}>
                                                <Form.Group>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>{t("birthday")}</Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    type={'date'}
                                                                    onDayChange={handleDayChange}
                                                                    defaultValue={formatDate(user?.user?.personal?.birthdate , 'YYYY-MM-DD')}
                                                                    name='birthdate'
                                                                    onChange={persoInfosChange} size={"lg"}
                                                                    // placeholder={formatDate(new Date(), 'DD/MM/YYYY', 'fr')}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t("email")}</Form.Label>
                                                    <Form.Control disabled name='email' onChange={persoInfosChange} size={"lg"} defaultValue={user?.user?.email} />
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t("tel")}</Form.Label>
                                                    <Form.Control disabled name='mobile' onChange={persoInfosChange} size={"lg"} defaultValue={user?.user?.mobile} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                      <Row>
                                          <Col>
                                              <Form.Group>
                                                  <Form.Label>{t("direct_supervisor")}</Form.Label>
                                                  <Form.Control disabled size={"lg"}
                                                                defaultValue={user?.user?.job_info[0]?.reports_to  && props.rwState.colleagues ?
                                                                    props.rwState.colleagues.filter(elt => elt._id === user?.user?.job_info[0]?.reports_to).map(e => e.firstname + ' ' + e.lastname).join('') : ''} />
                                              </Form.Group>
                                          </Col>
                                          <Col>
                                              <Form.Group>
                                                  <Form.Label>{t("department_title_unique")}</Form.Label>
                                                  <Form.Control disabled size={"lg"} defaultValue={props.rwState.departments.filter(option => option._id === user?.user?.department._id).map(e => e.name).join('')} />
                                              </Form.Group>
                                          </Col>
                                          <Col>
                                              <Form.Group>
                                                  <Form.Label>{t("position_held")}</Form.Label>
                                                  <Form.Control disabled size={"lg"}
                                                                value={
                                                                    user?.user?.position
                                                                        ? user?.user?.position?.title
                                                                        : ''} />  </Form.Group>
                                          </Col>
                                      </Row>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="docs">
                                        <div id="persoDocs" className={"mt-5 mb-4"} >
                                            <div className={'title p-3'}>{t("documents")}</div>
                                            <div>
                                                {
                                                    current_documents.map((curr_doc, index) => (
                                                        <div className={'docItem d-flex align-items-center'} key={index}>
                                                            <div className={'docIcon p-3'}>
                                                                <BsFileText size={48} />
                                                            </div>
                                                            <div
                                                                className="d-flex align-content-center justify-content-between w-100">
                                                                <div>
                                                                    <div className={'docName mb-3'}>{curr_doc.title}</div>
                                                                    <div className={'docDetails'}></div>
                                                                </div>
                                                                <div className={'p-3 pr-4'}>
                                                                    <FI.FiTrash onClick={() => deleteDocument(curr_doc.image_name)} color={'#FC5565'} size={22} />
                                                                    <RI.RiDownloadLine onClick={() => downloadDocument(curr_doc.image_name)} className="ml-2" color={'#240A63'} size={22} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                                      }
                            </Col>
                        </Row>
                    </Tab.Container>
                </Col>
            </Row>
        </Container>
        <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("confirmation")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"text-center"}>
                    {t("confirme_irreversile_deletion_text")}
                </div>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>
                        <Button variant="primary" onClick={updatePpImage}>
                            {t("yes_update")}
                        </Button>
                        <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                            {t("cancel")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsCollegues);
