import React, {useEffect, useState} from 'react';
import './Activation.css'
import {Button, Col, Container, Form, FormGroup, Row} from "react-bootstrap";
import ContextMenu from "../menus/ContextMenu";
import countries from "../../assets/countries";
import axios from "axios";
import env from "../../env";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { useTranslation } from 'react-i18next';

const Activation = () => {
    const { t } = useTranslation();
    const contextMenu = [
        {label: t('login'), href: '/auth/signin'},
        {label: t('activation_text'), href: '/activate'},
        // {label:'Mot de passe Perdu', href:'/auth/resetpass'},
    ]
    const [loading, setLoading] = useState(false)
    const [cKey, setCKey] = useState()
    const [activationError, setActivationError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [company, setCompany] = useState()
    const [firstname, setFirstname] = useState()
    const [firstnameVerif, setFirstnameVerif] = useState()
    const [lastname, setLastname] = useState()
    const [lastnameVerif, setLastnameVerif] = useState()
    const [phone, setPhone] = useState()
    const [phoneVerif, setPhoneVerif] = useState()
    const [password, setPassword] = useState()
    const [passwordVerif, setPasswordVerif] = useState()

    useEffect(() => {
        let param = window.location
        let key = param.toString().split('activate/')[1]
        setCKey(key)
        if (key) {
            setLoading(true)
            axios
                .get(env.DEV.apiUrl + env.urls.activation+key)
                .then(s => {
                    console.log('result',s);
                    if (s.status === 200) {
                        setCompany(s.data)
                        setLoading(false)
                    } else setActivationError(true)
                    setLoading(false);
                })
                .catch(e => {
                    console.log(e,'**************** error')
                    setActivationError(true)
                    setLoading(false)
                });



        }

    }, [])

    const handleFirstname = (e) => {
        setFirstname(e.target.value)
    }
    const handleLastname = (e) => {
        setLastname(e.target.value)
    }
    const handlePhone = () => {
        let number=window.document.getElementById('telInput').value;
        setPhone(number)
    }
    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const activate = () => {
        if (firstname && lastname && phone && password){
            setLoading(true);
            axios
                .post(
                    env.DEV.apiUrl + env.urls.activation,
                    {
                        "linkid": cKey,
                        "password": password,
                        "admin_firstname": firstname,
                        "admin_lastname": lastname,
                        "admin_phone": phone
                    },
                    {headers: {'Content-Type': 'application/json'}}
                )
                .then(s => {
                    console.log('activation result ',s)
                    if (s.status === 200) {
                        setSuccess(true)
                        setLoading(false)
                    } else setActivationError(true)
                    setLoading(false)
                })
                .catch(e => {
                    console.log(e,'error activation');
                    setLoading(false)
                })
        } else {
            setFirstnameVerif(true)
            setLastnameVerif(true)
            setPhoneVerif(true)
            setPasswordVerif(true)
        }
    }
    return <div id={'Activation'}>
        <Container>
            <Row>
                <Col>
                    <ContextMenu menu={contextMenu}/>
                </Col>
            </Row>
            <Row hidden={success}>
                <Col md={6} hidden={!company}>
                    <div>
                        <h2>{t('user_infos_text')}</h2>
                        <p className={"mb-2"}>{t('activation_subtext')} <strong>{company?.name}</strong></p>
                        <FormGroup>
                            {/*<Form.Label>Nom ou Raison Sociale</Form.Label>*/}
                            <Form.Control isInvalid={firstnameVerif} type={'text'} placeholder={t('first-_name')} size={"lg"}
                                          onChange={handleFirstname}/>
                        </FormGroup>
                        <FormGroup>
                            {/*<Form.Label>Nom ou Raison Sociale</Form.Label>*/}
                            <Form.Control isInvalid={lastnameVerif} type={'text'} placeholder={t('last_name')} size={"lg"}
                                          onChange={handleLastname}/>
                        </FormGroup>
                        <FormGroup>
                            {/*<Form.Label>Nom ou Raison Sociale</Form.Label>*/}
                            {/*<Form.Control isInvalid={phoneVerif} type={'tel'} placeholder={'Téléphone'} size={"lg"}*/}
                            {/*              onChange={handlePhone}/>*/}
                            <IntlTelInput
                                          onPhoneNumberChange={handlePhone}
                                          placeholder={"+334578521255"}
                                          fieldId={"telInput"}
                                          size={"lg"}
                                          style={{width:'100%'}}
                                containerClassName="intl-tel-input"
                                inputClassName="form-control"
                            />
                        </FormGroup>
                        <FormGroup>
                            {/*<Form.Label>Nom ou Raison Sociale</Form.Label>*/}
                            <Form.Control isInvalid={passwordVerif} type={'password'} placeholder={t('password')}
                                          size={"lg"} onChange={handlePassword}/>
                        </FormGroup>
                        <p className={'text-danger'} hidden={!activationError}><strong>{t('error_occure')} <br/><small>{t('already_active')}</small></strong></p>

                        <Button disabled={loading} block size={"lg"}
                                onClick={activate}>{loading ? t('activation_init') : t('activate_btn')}</Button>
                    </div>
                </Col>
                <Col md={"auto"}>
                    <div hidden={company}>{t('loading_text')}</div>
                    <div hidden={!company}>
                        <div className={"card mt-3"}>
                            <div className="card-body">
                        <h2>{t('company_info')}</h2>
                                <hr/>
                        <h6 hidden={!company?.name}>{t('company')} : <strong>{company?.name}</strong></h6>
                        <h6 hidden={!company?.country}>{t('country')} : <strong>{countries.filter(c=>c.alpha2Code===company?.country)[0]?.name.toUpperCase()}</strong></h6>
                        <h6 hidden={!company?.website}>{t('web_site')} <strong>{company?.website}</strong></h6>
                       <h6 hidden={!company?.admin_email} >{t('email')} : <strong>{company?.admin_email}</strong></h6>
                        </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row hidden={!success}>
                <Col md={6}>
                    <div className={'mt-3'}>
                        <div className={'mt-2 mb-3'}>
                            <h2 style={{color:'green'}}>{t('activate_account')}!</h2>
                            <p>{t('activate_account_text')}</p>
                        </div>
                        <Button size={"lg"} href={'/auth/signin'}>{t('connect')}</Button>
                    </div>
                </Col>

            </Row>
        </Container>
    </div>;
};

export default Activation;
