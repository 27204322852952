import React, {useState} from 'react';
import "./Applicant.css"
import { Form, Button,Modal} from "react-bootstrap";
import * as BS from "react-icons/bs";
import * as BI from "react-icons/bi";
import mSVG from "../../../assets/gender/m.png";
import ViewCandidate from "../ViewCandidate";
import Select from "react-select";
import slugify from "slugify";
import { useTranslation } from 'react-i18next';

const Applicant = (props) => {
    const { t } = useTranslation();
    const user = JSON.parse(window.localStorage.getItem('glowhr_user')).user;

    const [show, setShow] = useState(false);
    const [employee, setEmployee] = useState()
    const [interviewDate, setInterviewDate] = useState(new Date());
    const [title, setTitle] = useState()
    const [duration, setDuration] = useState()
    const interview_types = [{ label: t("visio_conf_"), value: "visio" }, { label: t("physic"), value: 'physique' },]
    const [type, setType] = useState(interview_types[0])
    const [validated, setValidated] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSelectEmployee = e => setEmployee(e)
    const handleInterviewDate = (e) => {
        setInterviewDate(e.target.value)
    }
    const handleTitle = (e) => {
        setTitle(e.target.value)
    }
    const handleDuration = (e) => {
        setDuration(e.target.value)
    };
    const handleTypeSelect = (e) => {
        setType(e)
    };

    const program = (event)=>{
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            return
        }
        setValidated(true);
        if (!employee) return;
        let arr = [];
            employee.forEach((emp)=>{arr.push(emp._id)});
        arr.push(props.colleagues.find(r=>r.user && user && r.user._id===user?._id));
        let dateInter=new Date(interviewDate);
        let d = {
            application:props.applicant._id,
            participants: arr,
            date:interviewDate,
            type:type.value,
            duration:duration,
            title:title,
            "description": '',
            "conf_date": dateInter.getDate(),
            "conf_time": dateInter.getTime(),
            "target_type": 'employee',
            "target": arr,
            "link": 'https://meet.glow-hr.com/'+slugify(title+dateInter.getTime() || "interview"+dateInter.getTime()),
        };
        props.programInterview(d);
        setShow(null);
  };
    const getAppStatus = (status)=>{
        let str = '';
        switch (status) {
            case 0 :
                str = 'Programmer';
                break;
            case 1 :
                str = 'Déjà programmé';
                break;
            case 2:
                str = "Déjà programmé";
                break;
        }
        return str;
    }
  return <div id={'Applicant'}>
    <div className={"pl-4 pr-4 pb-4 pt-4"}>
     
      <div className={"d-flex"}>
        <div>
          <img src={mSVG} height={64}/>
            <BI.BiBadgeCheck className={"referred"} size={25} title={t("recommand_candidat")}/>
        </div>
        <div className={"ml-3 mt-3"}>
          <div className={"text"}>{props.applicant.firstname +' '+props.applicant.lastname}</div>
          <div className={"poste pt-2 pb-2"}>Poste : {props.applicant.recruitment?.title}</div>
          {/*<div className={"text"}>Expérience : 5ans</div>*/}
        </div>
       
      </div>
      {/* <div className={"text-left col-12"}>
        {
            props.applicant.status===0
            ?
                <Button disabled={props.applicant.programmed>1} variant={"light"} size={"sm"} className={"border-0 rounded-pill text-primary"} onClick={()=>{setShow('program')}}>
                    <BS.BsClock/> {getAppStatus(props.applicant.programmed)}
                </Button>
                :
                <Button disabled={true} variant={props.applicant.status===1?"success":"danger"} size={"sm"} className={"border-0 rounded-pill text-white"}>
                    {props.applicant.status===1?"Candidat approuvé":"Candidat rejeté"}
                </Button>
        }

</div> */}
      <div className={"pt-4"}>
        <Button variant={"light"} onClick={() => setShow('view')}><BS.BsEye/> Voir</Button> &nbsp;&nbsp;
        <Button hidden={props.applicant.status!==0} disabled={props.applicant.programmed===2} onClick={()=>{setShow('program')}}>
        <BS.BsClock/>  {props.applicant.programmed>=2 ? t("alreday_programs"): t("programs")}</Button>
      </div>
    </div>
    <div>
    </div>
      <div>
          <Modal show={show === 'view'} onHide={handleClose} centered size={"lg"}>
              <Modal.Header closeButton className={'border-0'}>
                  <Modal.Title><h2 className="pl-4 pt-4 pb-0"> {t("application_details")} </h2></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <ViewCandidate applicant={props.applicant} onHide={handleClose}/>
              </Modal.Body>
          </Modal>
      </div>
    <div>
        <Modal size={"md"} centered show={show === 'program'} onHide={handleClose}>
            <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
                {t("program_interview")}
            </Modal.Header>
            <Modal.Body className={'p-5'}>
                <Form noValidate validated={validated} onSubmit={program}>
                    <Form.Row>
                        <Form.Group style={{width:"100%"}}>
                            <Form.Label>{t("interview_title")} </Form.Label>
                            <Form.Control
                                required
                                onChange={handleTitle}
                                defaultValue={title}
                                type={"text"}/>
                        </Form.Group>
                    </Form.Row>
                <Form.Row>
                    <Form.Group style={{width:"100%"}}>
                            <Form.Label>{t("interview_type")}</Form.Label>
                            <Select
                                required
                                options={interview_types}
                                defaultValue={type}
                                styles={{
                                    control: base => ({
                                        ...base,
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("interview_type")}
                                name='type'
                                onChange={handleTypeSelect}
                            />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                        <Form.Group style={{width:"100%"}}>
                                <Select
                                    required
                                    options={props.colleagues.filter(r=>r.activated)}
                                    getOptionLabel={o => o.lastname+' '+o.firstname}
                                    getOptionValue={o => o._id}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            minHeight: 48,
                                        })
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#6600CC',
                                            primary25: '#6600CC25',
                                            primary75: '#6600CC75',
                                            primary50: '#6600CC50'
                                        },
                                    })}
                                    placeholder={t("participants")}
                                    isMulti
                                    onChange={handleSelectEmployee}
                                />
                            </Form.Group>

                </Form.Row>
                    <br/>
                    <Form.Row>
                    <Form.Label>{t("date_time_interview")}</Form.Label>
                        <Form.Control
                            required
                            onChange={handleInterviewDate}
                            defaultValue={interviewDate}
                            type={"datetime-local"}/>
                </Form.Row>
                <br/>
                <Form.Row>
                    <Form.Group style={{width:"100%"}}>
                            <Form.Label>{t("interview_duration")}</Form.Label>
                            <Form.Control
                                required
                                onChange={handleDuration}
                                defaultValue={duration}
                                min={5}
                                type={"number"}/>
                    </Form.Group>
                </Form.Row>
                    <br/>
                        <div className={"d-flex align-items-end justify-content-end"}>
                        <Button variant="light" onClick={handleClose}>
                            {t("cancel")}
                        </Button>
                        <Button variant="primary" type="submit">
                            {t("programs")}
                        </Button>
                        </div>
                </Form>

            </Modal.Body>

        </Modal>
    </div>
  </div>;
};

export default Applicant;
