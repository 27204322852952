import React, {useEffect, useState, Fragment} from 'react';
import "./OrgGraph.css"
import {connect} from "react-redux";
import * as actions from "../../../actions";
import { useTranslation } from 'react-i18next';

import randomcolor from "randomcolor";
import faker from "faker";
import data from "./data.json";
function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}
const Card = (props) => {
    const { t } = useTranslation();
    const levelColor = randomcolor();

    return (
        <ul>
            {props.data?.map((item,index) => (
                <Fragment key={item.name}>
                    <li>
                        <div className="icard">
                            <div className="image">
                                <img
                                    src={item.image_url ? item.image_url : ''}
                                    alt="Profile"
                                    style={{ borderColor: levelColor }}
                                />
                            </div>
                            <div className="icard-body">
                                <h4 className={"mt-3"}>{item.name}</h4>
                                <p>{item.title}</p>
                            </div>

                        </div>
                        {item.children?.length && <Card data={item.children} />}
                    </li>
                </Fragment>
            ))}
        </ul>
    );
};
const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const OrgGraph = (props) => {
    const [org,setOrg] = useState([])

    return <div id={"OrgGh"}>
        <div className="org-tree">
            {props.employee && props.employee.length > 0 ?
                <Card data={props.employee} />
                : <div className={"mt-5 mb-5 text-center"}>{t("loading_init_text")}</div>}
        </div>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgGraph);
