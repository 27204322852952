import React, {useState} from 'react';
import "./DashTrainProgress.css"
import {Carousel} from "react-bootstrap";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {limitText} from "../../../helpers/helper";
import { useTranslation } from 'react-i18next';

const DashTrainProgress = ({progress_formations}) => {
    const { t } = useTranslation();
    const percentage = Math.floor(Math.random()*100);
    const checkTotalProgress=(total_module,total_module_finis,test)=>{
        let test_done=0;
        if(!test) test_done=10;
        return ((total_module_finis/total_module)*100)-test_done;
    }
    return <div id={'DashTrainProgress'}>
        <div className={'d-flex mb-4 align-items-center'}>
        <div className={"title"}>{t("formations_title")}</div>
      <div style={{ width: "128px", borderBottom: "3px solid #ffff", opacity: 0.19, marginLeft: 15, marginRight: 15, marginBottom: -8 }}></div>
      <div className={'secondTitle'}>{t("current_training_courses")}</div>
         </div>  
            {progress_formations && progress_formations.length > 0 ?
            <Carousel controls={false} >
                {  progress_formations.map(res=> {
                    return <Carousel.Item>
                        <Carousel.Caption>
                            <div style={{width: 85, height: 85, marginBottom: 15}}>
                                <CircularProgressbar background
                                                     value={checkTotalProgress(res.total_module, res.total_module_finis, res.test_done)}
                                                     text={`${checkTotalProgress(res.total_module, res.total_module_finis, res.test_done)}%`}
                                                     styles={buildStyles({
                                                         strokeLinecap: 'butt',
                                                         textSize: '24px',
                                                         textColor: '#240265',
                                                         trailColor: 'transparent',
                                                         backgroundColor: 'white',
                                                         pathColor: '#EFC300'
                                                     })}/>
                            </div>

                            {res.total_module === res.total_module_finis && !res.test_done ?
                                <div className={'badge badge-warning badge-sm '}> {t("evaluation_waiting")}</div>
                                :
                                <div className={'status'}>{!res.test_done ? t("on_it") : t("finish")} </div>
                            }
                            <div className={'label'}>{res.formation.name}</div>
                            <p>{limitText(res.formation.description, 100)}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                })
                }
            </Carousel>

                : <div className={"col-12 text-center p-5 mt-5"} style={{color: '#fff'}}>
                {t("no_current_training")}
            </div>
                }
      
    </div>;
};

export default DashTrainProgress;
