import React, {useEffect, useState} from 'react';
import './BrancheInit.css'
import {Button, Col, Form, Modal, Row, Tab} from "react-bootstrap";
import * as RI from "react-icons/ri";
import * as BI from "react-icons/bi";
import DataTable from "react-data-table-component";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import countries from "../../../assets/countries";
import Select from "react-select";
import {
  handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
import { useTranslation } from 'react-i18next';
const mapStateToProps = (state) => {
  return {rwState: state}
};
const mapDispatchToProps = {...actions};

const BrancheInit = (props) => {
  const { t } = useTranslation();
  const [branchName, setBranchName] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [show, setShow] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [current_row,setCurrentRow] = useState();
  const [formError, setFormError] = useState({})
  useEffect(() => {
    props.action_listBranches();
  }, []);
  const handleCountry = (e) => {
    setCountry(e.alpha2Code)
    let data =handleValidation(t,{type:"text",name:'country','value':e.alpha2Code,required:true});
    let form=formError;
            form[data.name]=data   
            setFormError(form)
  };
  const handleCity = (e) => {
    setCity(e.name)
    let data =handleValidation(t,{type:"text",name:'city','value':e.name,required:true});
    let form=formError;
            form[data.name]=data   
            setFormError(form)
  };
  const handleBranchName = (e) => {
    setBranchName(e.target.value)
    let data =handleValidation(t,{type:"text",name:'name','value':e.target.value,required:true});
    let form=formError;
            form[data.name]=data   
            setFormError(form)
  };
  const handleClose = () => {
    setShow(null)
  };
  useEffect(() => {
    if (country) props.action_getCities(country)
  }, [country]);

  const requestDataBuilderBranch = () => (
      {
        "name": branchName,
        "city": city,
        "country": country,
        "settings": {},
        "sio_channel": ""
      }
  );
  const resetBranch = () => {
    setBranchName('')
    setCity('')
    setCountry('')
    setFormError({})
  }
  //Branch methode
  const createBranch = () => {
    let o = requestDataBuilderBranch();
    props.action_createBranch(o);
    resetBranch();
    setIsLoading(false)
    props.action_loading(true);
  };
  const confirmDeletebranch = b => {
    console.log('row => ', b);
    setCurrentRow(b);
    setShow('confirmbranch')
  };
  const deleteBranch = () => {
    console.log('delete');
    let data = requestDataBuilderBranch();
    data['id'] = current_row._id;
    props.action_deleteBranch(data);
    console.log('data', data);
    setShow(null);
    props.action_loading(true);
    setCurrentRow()
  };
  const proceedBranch = () => {
    setIsLoading(true)
    createBranch()
  };
  const branchesColumns = [
    {
      name: t("last_name"),
      selector: 'name',
      cell: row => <div><div style={{width:'100%'}}>{row.name}</div></div>,

    },
    {
      name: t("city"),
      selector: 'city',
      cell: row => <div><div style={{width:'100%'}}>{row.city}</div></div>,

    },
    {
      name: t("country"),
      selector: 'country',
      cell: row => <div><div style={{width:'100%'}}>{row.country}</div></div>,

    },
    {
      name: '',
      cell: row => <div className={'text-right'} style={{width:'100%'}}>
        <Button onClick={() => confirmDeletebranch(row)} variant={"danger"}  size={"sm"} className={"ml-2"}><BI.BiPencil/>{t("delete")}</Button>
      </div>,

    }
  ];

  return <div id={'Branches'}>
    <h2 className=" mt-4 mb-3 text-uppercase">
      {t("manages_companies_branches")}
    </h2>

    <div className="col-12 mt-4" style={{background: "#eee"}}>
      <div className="row">
        <h4 className="col-12 mb-3 mt-3">
          {t("add_new_branche")}
        </h4>

        <div className="col-4">
          <Form.Group>
            {/*<Form.Label>Nom de la branche</Form.Label>*/}
            <Form.Control
                size={"lg"}
                placeholder={t("branche_name")}
                value={branchName}
                onChange={handleBranchName}
                className={handleinputValidationError(formError,'name')}
                />
                       {handleTextValidationError(formError,'name')}
          </Form.Group>
        </div>
        <div className="col-8">
          <Row>
            <Col>
              <Form.Group>
                <Select noOptionsMessage={() => t("no_data_available")}
                    options={countries}
                    getOptionLabel={o => o.name}
                    getOptionValue={o => o.alpha2Code}
                    styles={{
                      control: base => ({
                        ...base,
                        height: 48,
                        minHeight: 48,
                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                      })
                    }}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#6600CC',
                        primary25: '#6600CC25',
                        primary75: '#6600CC75',
                        primary50: '#6600CC50'
                      },
                    })}
                    size={"sm"}
                    placeholder={t("country")}
                    defaultValue={country}
                    onChange={handleCountry}
                    className={handleinputValidationError(formError,'country')}
                    />
                           {handleTextValidationError(formError,'country')}
              </Form.Group>
            </Col>

            {props.rwState.cities && props.rwState.cities.length > 0 ?
                <Col>
                  <Form.Group>
                    <Select noOptionsMessage={() => t("no_data_available")}
                        size={"sm"}
                        options={props.rwState.cities}
                        getOptionLabel={o => o.name}
                        getOptionValue={o => o.name}
                        styles={{
                          control: base => ({
                            ...base,
                            height: 48,
                            minHeight: 48,
                            // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                          })
                        }}
                        theme={theme => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: '#6600CC',
                            primary25: '#6600CC25',
                            primary75: '#6600CC75',
                            primary50: '#6600CC50'
                          },
                        })}
                        defaultValue={city}
                        placeholder={t("city")}
                        onChange={handleCity}
                        className={handleinputValidationError(formError,'city')}
                        />
                               {handleTextValidationError(formError,'city')}
                  </Form.Group>
                </Col>
                :''}
          </Row>
        </div>
        <div className="col-12 text-right">
          <Button variant="light" disabled={
             handleDisabledBtnValidationError(formError,
                    ['name','country','city'])
                     || isLoading} size={"sm"} onClick={proceedBranch}>
            {t("save")}
          </Button>
        </div>
      </div>

    </div>

    <DataTable
        noHeader
        title={t("branche_list")}
        columns={branchesColumns}
        data={props.rwState.branches ? props.rwState.branches : []}
        pagination
    />

    <Modal show={show==="confirmbranch"} onHide={() => setShow(null)} centered size={"md"}>
      <Modal.Header closeButton className={'border-0'}>
        <Modal.Title>{t("confirmation")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={"text-center"}>
          {t("confirme_irreversile_deletion_text")}
        </div>
      </Modal.Body>
      <Modal.Footer className={'text-center border-0'}>
        <Button variant="primary" onClick={deleteBranch}>
        {t("yes")}
        </Button>
        <Button variant="light" onClick={() => setShow(null)}>
        {t("no")}
        </Button>
      </Modal.Footer>
    </Modal>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(BrancheInit);

