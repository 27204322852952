import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import "./SingleSurvey.css"
import {Button, Card, Col, Dropdown, Form, Modal, Row} from "react-bootstrap";
import * as RI from "react-icons/ri";
import * as BI from "react-icons/bi";
import * as BS from "react-icons/bs";
import Select from "react-select";
import * as actions from "../../../actions";
import connect from "react-redux/es/connect/connect";
import {isAdmin, limitText} from "../../../helpers/helper";
import * as moment from "moment";
import { useTranslation } from 'react-i18next';
const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}
const SingleSurvey = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();
    const affectations_type =
    [{ label: t('all'), value: 'all' },
    { label: t("departments"), value: "departments"},
    { label: t('branches'), value: 'branches'},
    { label: t('positions'), value: 'positions'},
    { label: t('specific_employee'), value: 'employee'}
    ]
    const [affect, setAffect] = useState(affectations_type[0])
    const [assignTo, setAssignTo] = useState([])

    const handleAffectation_type = (e) => {
        setAssignTo([]);
        setAffect(e.value)
    };

    const handleSelect = (e) => {
        setAssignTo(e)
    };


    const editSurvey= (e)=>{
        props.editSurvey(e)
    }
    const deleteSurvey= (e)=>{
        props.deleteSurvey(e._id)
    }
    const proceedAssignation = () => {
        let arr = [];
        assignTo.forEach((a)=>{arr.push(a._id)});
        setAssignTo(arr);
        let o = {
            "assignToType":affect,
            "assignTo": arr,
            "survey":props.survey?._id,
            "category":props.survey.category
        };
        props.action_assignSurvey(o);
        setShow(null)
        props.action_loading(true)
    };
    const changeState = () => {
        let o = {
            "survey":props.survey?._id,
            "category":props.survey.category
        };
        props.action_changeSurveyStatus(o);
        props.action_loading(true);
        if (props.survey.category==="review") 
        props.action_listSurveysReview();

    };
    const revive = () => {
        let o = {
            "survey":props.survey?._id,
            "category":props.survey.category
        };
        props.action_reviveSurvey(o);
        props.action_loading(true);
        if (props.survey.category==="review")
        props.action_listSurveysReview();

    };
    const cloturer = (p) => {
        props.closeSurvey(p);
    };
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;

    return<div id={'SingleSurvey'} className={'mt-3'}>
        <div className={'border-0 d-flex align-items-start'}>
            <Card className={"p-4 border-0"} style={{borderRadius:10,width:"100%",boxShadow:'none'}}>

                <h5 style={{color: '#240265'}}>
                    <Row>
                    <Col md={10} className={"text-uppercase pl-4 pr-4 pt-3"}>
                        <h6 className={"name"} style={{color:'#240265'}}> {props.survey?.title}</h6>
                    </Col>
                    <Col md={2}>
                      <Dropdown hidden={!isAdmin(role)}>
                        <Dropdown.Toggle variant="white" id="dropdown-basic">
                            <BS.BsThreeDotsVertical/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>

                            <Dropdown.Item  onClick={e => history.push('/dash/surveys/stats/'+props.survey?._id)}>
                                <BI.BiStats  style={{color: '#240265',fontWeight:'600',}} size={25}/> Statistiques
                            </Dropdown.Item>
                             <Dropdown.Item hidden={ moment(props.survey?.end_date).isBefore() || props.survey?.is_passed} onClick={e => history.push('/dash/surveys/'+props.survey?._id)}>
                                <BI.BiDetail  style={{color: '#240265',fontWeight:'600',}} size={25}/> {t("create_questions")}
                            </Dropdown.Item>
                            <Dropdown.Item hidden={ moment(props.survey?.end_date).isBefore() || props.survey?.is_passed} onClick={() => {editSurvey(props.survey)}}>
                                <BI.BiEdit  style={{color: '#240265',fontWeight:'600',}} size={25}/> {t("update")}
                            </Dropdown.Item>
                            <Dropdown.Item hidden={ moment(props.survey?.end_date).isBefore() || props.survey?.is_passed} onClick={()=>{setShow('affecte')}}>
                                <BI.BiUserPlus  style={{color: '#240265',fontWeight:'600',}} size={25}/> {t("assigned_action")}
                            </Dropdown.Item>
                            <Dropdown.Item hidden={ moment(props.survey?.end_date).isBefore() || props.survey?.is_passed} onClick={() => {revive()}}>
                                <BS.BsClockHistory  style={{color: '#240265',fontWeight:'600',}} size={20}/> {t("recall")}
                            </Dropdown.Item>
                              <Dropdown.Item hidden={ moment(props.survey?.end_date).isBefore() || props.survey?.is_passed} onClick={() => {cloturer(props.survey)}}>
                                <BS.BsSkipEnd  style={{color: '#240265',fontWeight:'600',}} size={20}/> {t("close_up")}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={()=>{deleteSurvey(props.survey)}}>
                                <BI.BiTrash  style={{color: 'red',fontWeight:'600',}} size={25}/> {t("delete")}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    </Col>
                    </Row>

                </h5>
                <div className={"pl-2"} style={{fontSize:"10pt"}}>{limitText(props.survey?.description,200)}</div>
                <div className={"pl-2 pt-3"} style={{fontSize:"10pt"}}>{new Date(props.survey?.start_date).toLocaleDateString()+" - "+new Date(props.survey?.end_date).toLocaleDateString()}</div>

                <Row className={"d-flex align-items-start justify-content-between mt-3"}>
                    <Col md={"auto"}  className={'text'} style={{color: '#240265'}}><BS.BsFillChatDotsFill size={22} className={"icon"}/> {props.survey?.questions?.length} question(s)</Col>
                    <Col md={"auto"}  className={'text'} style={{color: '#240265'}}><BI.BiTime size={22} className={"icon"}/> {props.survey?.duration} min</Col>
                    <Col md={"auto"}  hidden={!isAdmin(role)} style={{marginTop:'-8px'}}>
                        { moment(props.survey?.end_date).isAfter() && !props.survey?.is_passed?
                            props.survey.status===0?
                                <Button onClick={changeState} variant={"light"} size={"sm"} className={"border-0 rounded-pill text-primary"}>
                                    <BI.BiBlock/> {t("hanging")}
                                </Button>
                                :
                                <Button onClick={changeState} variant={"light"} size={"sm"} className={"border-0 rounded-pill text-primary"}>
                                    <BS.BsCheck/> {t("relaunch")}
                                </Button>
                            :
                            <Button disabled={true}  variant={"warning"} size={"sm"} className={"border-0 rounded-pill text-primary"}>
                                <BI.BiBlock/> {t("close_survey")}
                            </Button>
                        }
                    </Col>
                    <Col md={"auto"} hidden={isAdmin(role)}>
                        <Button style={{backgroundColor:"transparent",border:"none",marginLeft:"-35px",marginTop:"-8px"}}>
                            <BI.BiChevronRight onClick={e => history.push('/dash/surveys/'+props.survey?._id)} style={{color: '#240265',fontWeight:'600',}} size={25}/>
                        </Button>
                    </Col>
                </Row>

            </Card>
        </div>
      <Modal centered show={show === 'affecte'} onHide={handleClose}>
          <Modal.Header className={"bg-primary"}>
              <h4 className={"col-lg-12 text-white text-center"}>{t("assign_survey_to")} :</h4>
          </Modal.Header>
          <Modal.Body className={'p-4'}>
              <Form.Group>
                      <Select
                          options={affectations_type}
                          defaultValue={affect}
                          styles={{
                              control: base => ({
                                  ...base,
                              })
                          }}
                          theme={theme => ({
                              ...theme,
                              colors: {
                                  ...theme.colors,
                                  primary: '#6600CC',
                                  primary25: '#6600CC25',
                                  primary75: '#6600CC75',
                                  primary50: '#6600CC50'
                              },
                          })}
                          placeholder={t("assign_to")}
                          name='affect_to'
                          onChange={handleAffectation_type}
                      />
              </Form.Group>
              <Form.Group hidden={affect!=='branches'}>
                  <Select
                      options={props.branches}
                      getOptionLabel={o => o.name}
                      getOptionValue={o => o._id}
                      styles={{
                          control: base => ({
                              ...base,

                              minHeight: 48,
                              // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                          })
                      }}
                      theme={theme => ({
                          ...theme,
                          colors: {
                              ...theme.colors,
                              primary: '#6600CC',
                              primary25: '#6600CC25',
                              primary75: '#6600CC75',
                              primary50: '#6600CC50'
                          },
                      })}
                      placeholder={t("branch_title_unique")}
                      isMulti
                      onChange={handleSelect}
                  />
              </Form.Group>
              <Form.Group hidden={affect!=='departments'}>
                  <Select
                      options={props.departments}
                      getOptionLabel={o => o.name}
                      getOptionValue={o => o._id}
                      styles={{
                          control: base => ({
                              ...base,

                              minHeight: 48,
                              // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                          })
                      }}
                      theme={theme => ({
                          ...theme,
                          colors: {
                              ...theme.colors,
                              primary: '#6600CC',
                              primary25: '#6600CC25',
                              primary75: '#6600CC75',
                              primary50: '#6600CC50'
                          },
                      })}
                      placeholder={t("department_title_unique")}
                      isMulti
                      onChange={handleSelect}
                  />
              </Form.Group>
              <Form.Group hidden={affect!=='positions'}>
                  <Select
                      options={props.positions}
                      getOptionLabel={o => o.title}
                      getOptionValue={o => o._id}
                      styles={{
                          control: base => ({
                              ...base,

                              minHeight: 48,
                              // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                          })
                      }}
                      theme={theme => ({
                          ...theme,
                          colors: {
                              ...theme.colors,
                              primary: '#6600CC',
                              primary25: '#6600CC25',
                              primary75: '#6600CC75',
                              primary50: '#6600CC50'
                          },
                      })}
                      placeholder={t("position_title_unique")}
                      isMulti
                      onChange={handleSelect}
                  />
              </Form.Group>
              <Form.Group hidden={affect!=='employee'}>
                  <Select
                      options={props.employees}
                      getOptionLabel={o => o.lastname+' '+o.firstname}
                      getOptionValue={o => o._id}
                      styles={{
                          control: base => ({
                              ...base,
                              minHeight: 48,
                          })
                      }}
                      theme={theme => ({
                          ...theme,
                          colors: {
                              ...theme.colors,
                              primary: '#6600CC',
                              primary25: '#6600CC25',
                              primary75: '#6600CC75',
                              primary50: '#6600CC50'
                          },
                      })}
                      placeholder={t("employee_text")}
                      isMulti
                      onChange={handleSelect}
                  />
              </Form.Group>
          </Modal.Body>
          <Modal.Footer className={'border-0 pt-0'}>
              <Button variant="light" onClick={handleClose}>
                  {t("cancel")}
              </Button>
              <Button variant="primary" onClick={proceedAssignation}>
                  {t("assigned_action")}
              </Button>
          </Modal.Footer>
      </Modal>

  </div>;

};
export default connect(mapStateToProps, mapDispatchToProps)(SingleSurvey);
