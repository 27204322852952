import React, {useEffect, useState} from 'react';
import './Positions.css'
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import * as BI from "react-icons/bi";
import DataTable from "react-data-table-component";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import { useTranslation } from 'react-i18next';
import {
  handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
const mapStateToProps = (state) => {
  return {rwState: state}
};
const mapDispatchToProps = {...actions};

const Positions = (props) => {
  const { t } = useTranslation();
  const [posTitle, setPosTitle] = useState();
  const [postDesc, setPosDesc] = useState();
  const [posQualifs, setPosQualifs] = useState();
  const [show, setShow] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [current_row,setCurrentRow] = useState();
  const [formError, setFormError] = useState({})

  useEffect(() => {
    props.action_listPositions();
  }, []);
  const handlePosTitle = (e) => {
    setPosTitle(e.target.value)
    let data =handleValidation(t,{type:"text",name:'title','value':e.target.value,required:true});
    let form=formError;
            form[data.name]=data   
            setFormError(form)
  };
  const handlePosDesc = (e) => {
    setPosDesc(e.target.value)
    let data =handleValidation(t,{type:"textarea",name:'desc','value':e.target.value,required:true});
    let form=formError;
            form[data.name]=data   
            setFormError(form)
  };
  const handlePosQualifs = (e) => {
    setPosQualifs(e.target.value)
    let data =handleValidation(t,{type:"textarea",name:'qualifs','value':e.target.value,required:true});
    let form=formError;
            form[data.name]=data   
            setFormError(form)
  };
  const handleClose = () => {
    setShow(null)
  };
  const requestDataBuilderPost = () => (
      {
        "title": posTitle,
        "description": postDesc,
        "qualifications": posQualifs,
      }
  );
  const resetPosition = () => {
    setPosTitle('')
    setPosDesc('')
    setPosQualifs('')
    setFormError({})

  }
  //Position methode
  const createPosition = () => {
    let o = requestDataBuilderPost();
    props.action_loading(true)
    props.action_createPosition(o);
    setIsLoading(false)
    resetPosition();
  };
  const confirmDeletePosition = p => {
    console.log('row => ', p);
    setCurrentRow(p);
    setShow('confirmpositon')
  };
  const deletePosition = () => {
    let data = requestDataBuilderPost();
    data['id'] = current_row._id;
    props.action_deletePosition(data);
    setShow(null);
    props.action_loading(true);
    setCurrentRow()
  };
  const proceedPostion = () => {
      createPosition()
    setIsLoading(false)
  };

  const positionsColumns = [
    {
      name: t("title"),
      selector: 'title',
      cell: row => <div><div style={{width:'100%'}}>{row.title}</div></div>,

    },
    {
      name: t("description"),
      selector: 'description',
      cell: row => <div><div style={{width:'100%'}}>{row.description}</div></div>,


    },
    {
      name: t("qualifications"),
      selector: 'qualifications',
      cell: row => <div><div style={{width:'100%'}}>{row.qualifications}</div></div>,

    },
    {
      name:'',
      cell: row =>
          <div>
            <Button  onClick={ ()=>confirmDeletePosition(row)}  variant={"danger"} size={"sm"} className={"ml-2"}><BI.BiTrash/>supprimer</Button>
          </div>,
    },

  ];

  return <div id={'Branches'}>
    <h2 className="text-uppercase mt-4 mb-3">
      {t("poste_companie")}
    </h2>
    <div className="col-12 mt-4" style={{background: "#eee"}}>
      <div className="row">
        <h4 className="col-12 mb-3 mt-3">
          {t("add_new_poste_companie")}
        </h4>

        <div className="col-12">
          <Form.Group>
            <Form.Control
                size={"sm"}
                placeholder={'Intitulé du poste'}
                onChange={handlePosTitle}
                value={posTitle}
                className={handleinputValidationError(formError,'title')}
                />
                       {handleTextValidationError(formError,'title')}
          </Form.Group>
        </div>
        <div className="col-12">
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                    size={"sm"}
                    as={'textarea'}
                    placeholder={t("description")}
                    onChange={handlePosDesc}
                    value={postDesc}
                    className={handleinputValidationError(formError,'desc')}
                    />
                           {handleTextValidationError(formError,'desc')}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                    size={"sm"}
                    as={'textarea'}
                    placeholder={t("qualifications")}
                    onChange={handlePosQualifs}
                    value={posQualifs}
                    className={handleinputValidationError(formError,'qualifs')}
                    />
                           {handleTextValidationError(formError,'qualifs')}
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="col-12 text-right">
          <Button variant="light" disabled={
            handleDisabledBtnValidationError(formError,
                    ['title','desc','qualifs'])
                     || isLoading} size={"sm"} onClick={proceedPostion}>
            {t("save")}
          </Button>
        </div>
      </div>

    </div>

    <DataTable
        noHeader
        title={t("list_poste_companie")}
        columns={positionsColumns}
        data={props.rwState.positions}
        pagination
    />

    <Modal show={show==="confirmpositon"} onHide={() => setShow(null)} centered size={"md"}>
      <Modal.Header closeButton className={'border-0'}>
        <Modal.Title>{t("confirmation")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={"text-center"}>
          {t("confirme_irreversile_deletion_text")}
        </div>
      </Modal.Body>
      <Modal.Footer className={'text-center border-0'}>
        <Button variant="primary" onClick={deletePosition}>
        {t("yes")}
        </Button>
        <Button variant="light" onClick={() => setShow(null)}>
        {t("no")}
        </Button>
      </Modal.Footer>
    </Modal>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Positions);

