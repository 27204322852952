import React, {useEffect, useState} from 'react';
import './Visio.css'
import { Button, Col, Container, Form, Modal, Row,Tab} from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import {formatDate} from "react-day-picker/moment";
import {connect} from "react-redux";
import * as actions from "../../../actions";
import ContextMenu from "../../menus/ContextMenu/ContextMenu";
import * as RI from "react-icons/ri";
import * as BS from "react-icons/bs";
import slugify from "slugify";
import VisioConf from "../../widgets/VisioConf";
import {generateVisioUrl,
    handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
import DottedButton from "../../widgets/DottedButton";
import DayPickerInput from "react-day-picker/DayPickerInput";
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const Visio = (props) => {
    const { t } = useTranslation();
    const role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    const [confDate, setConfDate] = useState(null)
    const [confTime, setConfTime] = useState(null)
    const [show, setShow] = useState(null)
    const [title, setTitle] = useState()
    const [desc, setDesc] = useState()
    const [target, setTarget] = useState()
    const [selectedTargetType, setSelectedTargetType] = useState()
    const [selectedTarget, setSelectedTarget] = useState()
    const [current_row, setCurrentRow] = useState()
    const [validated, setValidated] = useState(false);
    const [auto_link, setAutolink] = useState(true);
    const [link, setLink] = useState("https://meet.glow-hr.com/8gdsyt");
    const [formError, setFormError] = useState({})
    const [duration, setDuration] = useState();
    const target_type_options =
            [{ label: t('all'), value: 'all' },
            { label: t("departments"), value: "departments"},
            { label: t('branches'), value: 'branches'},
            { label: t('positions'), value: 'positions'},
            { label: t('specific_employee'), value: 'employee'}
        ]
    const [target_type, setTargetType] = useState(target_type_options[0])

    const handleClose = () => {
        setShow(null)
        setFormError({})
    };

    const resetVisio = () => {
        setTitle('')
        setDuration('')
        setDesc('')
        setConfDate('')
        setConfTime('')
        setTarget('')
        setTargetType(target_type_options[0])
        setLink('')
    }
    const requestDataBuilder = () => {
       return {
            "title": title,
            "duration": duration,
            "description": desc,
            "conf_date": confDate,
            "conf_time": confTime,
            "target_type": target_type,
            "target": target,
            "link": link,
        }
    
    };

    const createOrUpdateVisio = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return
        }
        let l = requestDataBuilder();
        
        props.action_loading(true);
        if (current_row){
            l.id = current_row._id;
            props.action_updateVisioConf(l)
            setCurrentRow(null)
        } else{
            props.action_createVisioConf(l)
        }
        setValidated(true);
        handleClose();
    setFormError({})
    resetVisio();
    };
    const editVisio = (visio)=>{
        setDuration(visio.duration);
        setTitle(visio.title);
        setDesc(visio.description);
        setDuration(visio.duration);
        setLink(visio.link);
        setConfDate(new Date(visio.conf_date), 'DD/MM/YYYY');
        setConfTime(visio.conf_time);
        setSelectedTarget(visio.target);
        setTarget(visio.target);
        setTargetType(visio.target_type)
        setSelectedTargetType(target_type_options.find((t)=>t.value === visio.target_type))
        setCurrentRow(visio);
        setShow('create')
        let data=  handleFinaleValidation(t,[
            {type:"number",name:'duration','value':visio.duration,required:true,min:1},
            {type:"text",name:'title','value':visio.title,required:true},
            {type:"text",name:'link','value':visio.link,required:true},
            {type:"date",name:'confDate','value':visio.conf_date.toString(),required:true,min:new date()},
            {type:"text",name:'confTime','value':visio.conf_time,required:true},
           ])
                setFormError(data)
    }
    const deleteVisioConf = () => {
        props.action_deleteVisioConf(current_row._id);
        setShow(null);
        props.action_loading(true)
    };

    const handleTitle = (e) => {
        setTitle(e.target.value)
        let data =handleValidation(t,{type:"text",name:'title','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleDuration = (e) => {
        setDuration(e.target.value)
        let data =handleValidation(t,{type:"number",name:'duration','value':e.target.value,required:true,min:1});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleDesc = (e) => {
        setDesc(e.target.value)
    }
    const handleAutoLink = () => {
        setLink("https://meet.glow-hr.com/"+slugify(title||"meet"))
        let data =handleValidation(t,{type:"text",name:'link','value':link,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)  
    }
    const handleTargetType = (e) => {
        setTarget([]);
        setTargetType(e.value)
    };
    const handleConfDate = (e) => {
        setConfDate(new Date(e))
        let data =handleValidation(t,{type:"date",name:'confDate','value':e,required:true,min:new Date()});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleConfTime = (e) => {
        setConfTime(e.target.value)
        let data =handleValidation(t,{type:"text",name:'confTime','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleSelect = (e) => {
        setTarget(e)
    };
    const  handleLink = (e)=>{
        let data =handleValidation(t,{type:"text",name:'link','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
        setLink(e.target.value)
    };
    const resetForm = ()=>{
        setCurrentRow(null);
        setObs(null);
        setBranches(null);
        setDepart(null);
        setType(null);
    }
    const generateLink = ()=>{
        let link = generateVisioUrl();
        setLink(link)
    }
    const contextMenu =
            [
                {label: t("upcoming_conf"), eventKey: 'incoming'},
                {label: t("archived_conf"), eventKey: 'past'},
            ];
    useEffect(()=>{
        props.action_loading(true);
        props.action_listVisioConfs();
        props.action_listColleagues();
        props.action_setTitle(t("visio_conf"))
        let data =handleValidation(t,{type:"text",name:'link','value':link,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form);
    },[]);

    useEffect(() => {
        setIncomeVisioList([])
        setPastVisioList([])
        if(props.rwState.visio_confs && props.rwState.visio_confs.length > 0 && props.rwState.visio_confs.filter(r => moment(r.conf_date).isAfter()).length > 0) {
            //set list data
            let mylist = props.rwState.visio_confs.filter(r => moment(r.conf_date).isAfter());
            setIncomeVisioList(mylist)
        }
      if(props.rwState.visio_confs && props.rwState.visio_confs.length > 0 && props.rwState.visio_confs.filter(r => moment(r.conf_date).isBefore()).length > 0) {
            //set list data
            let mylist = props.rwState.visio_confs.filter(r => moment(r.conf_date).isBefore());
            setPastVisioList(mylist)
        }
     
       }, [props.rwState.visio_confs]);
   
      //*********************************************Income Visio Begin paginating */
      const [incomeVisioList, setIncomeVisioList] = useState(undefined);
      const [CurrentIncomeVisioLists, setCurrentIncomeVisioLists] = useState(undefined);
      const [incomeVisioPageCount, setIncomeVisioPageCount] = useState(0);
      const [incomeVisioItemOffset, setIncomeVisioItemOffset] = useState(0);
      const [incomeVisioItemsPerPage, setIncomeVisioItemsPerPage] = useState(8);

      useEffect(() => {
        if(incomeVisioList) {
            const endOffset = incomeVisioItemOffset + incomeVisioItemsPerPage;
            setCurrentIncomeVisioLists(incomeVisioList.slice(incomeVisioItemOffset, endOffset));
            setIncomeVisioPageCount(Math.ceil(incomeVisioList.length / incomeVisioItemsPerPage));
        }
       }, [incomeVisioItemOffset, incomeVisioItemsPerPage, incomeVisioList]);
   
       const handleIncomeVisioPageClick = (event) => {
        const newOffset = (event.selected * incomeVisioItemsPerPage) % incomeVisioList.length;
        setIncomeVisioItemOffset(newOffset);
    };
    //*********************************************Income Visio End paginating */

  //********************************************* Past Visio Begin paginating */
      const [pastVisioList, setPastVisioList] = useState(undefined);
      const [CurrentPastVisioLists, setCurrentPastVisioLists] = useState(undefined);
      const [pastVisioPageCount, setPastVisioPageCount] = useState(0);
      const [pastVisioItemOffset, setPastVisioItemOffset] = useState(0);
      const [pastVisioItemsPerPage, setPastVisioItemsPerPage] = useState(8);

      useEffect(() => {
        if(pastVisioList ) {
            const endOffset = pastVisioItemOffset + pastVisioItemsPerPage;
            setCurrentPastVisioLists(pastVisioList.slice(pastVisioItemOffset, endOffset));
            setPastVisioPageCount(Math.ceil(pastVisioList.length / pastVisioItemsPerPage));
        }
       }, [pastVisioItemOffset, pastVisioItemsPerPage, pastVisioList]);
   
       const handlePastVisioPageClick = (event) => {
        const newOffset = (event.selected * pastVisioItemsPerPage) % pastVisioList.length;
        setPastVisioItemOffset(newOffset);
    };
    //********************************************* Past Visio End paginating */


    return <div id={'Conference'}>
        <Container fluid>
            <Tab.Container  id="left-tabs-example" defaultActiveKey={'incoming'}>
                <div className="d-flex align-items-start justify-content-between">
                          <ContextMenu menu={contextMenu}/>
                          <Button hidden={role.toLowerCase().includes("employee")} className={"float-right"} variant={"white"} onClick={e => setShow('create')}><BS.BsCameraVideoFill color={"purple"} size={24}/>&nbsp;&nbsp; {t("new_meeting")}</Button>
                </div>

                <Tab.Content>
                    <Tab.Pane className={"lastRollL"} eventKey="incoming">
                        {
                            CurrentIncomeVisioLists && CurrentIncomeVisioLists.length > 0 ?
                                <>
                                    <Row>
                                        {CurrentIncomeVisioLists.map((visio=> {
                                            return (
                                                <Col className={"col-12 col-lg-4"}>
                                                    <VisioConf edit={()=>{editVisio(visio)}}  deleteVisio={()=>{setCurrentRow(visio);setShow('confirm')}} visio={visio}/>
                                                </Col>
                                            )
                                        }))
                                        }
                                         <Col md={12}>
                                        <div hidden={!CurrentIncomeVisioLists || (CurrentIncomeVisioLists && CurrentIncomeVisioLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleIncomeVisioPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={incomeVisioPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div></Col>
                                    </Row>
                                </>
                                :
                                <>
                                    <div className={"alert alert-warning"}>{t("no_conf_program")}</div>
                                </>
                        }

                    </Tab.Pane>
                    <Tab.Pane className={"lastRollL"} eventKey="past">
                        {
                          CurrentPastVisioLists && CurrentPastVisioLists.length > 0  ?
                                <>
                                    <Row>
                                        {CurrentPastVisioLists.map((visio=> {
                                            return (
                                                <Col md={"col-12 col-lg-4"}>
                                                    <VisioConf edit={()=>{editVisio(visio)}}  deleteVisio={()=>{setCurrentRow(visio);setShow('confirm')}} visio={visio}/>
                                                </Col>
                                            )
                                        }))
                                        }
                                          <Col md={12}>
                                                <div hidden={!CurrentPastVisioLists || (CurrentPastVisioLists && CurrentPastVisioLists.length<=0)}>
                                                        <ReactPaginate
                                                            breakLabel="..."
                                                            nextLabel={t("paginate_next")}
                                                            onPageChange={handlePastVisioPageClick}
                                                            pageRangeDisplayed={5}
                                                            pageCount={pastVisioPageCount}
                                                            previousLabel={t("paginate_previous")}
                                                            renderOnZeroPageCount={null}
                                                            className={"react-paginate float-right"}
                                                        />
                                                    </div>
                                             </Col>
                                    </Row>
                                </>
                                :
                                <>
                                    <div className={"alert alert-warning"}>{t("no_conf_available")}</div>
                                </>
                        }
                    </Tab.Pane>


                </Tab.Content>
            </Tab.Container>

            <Modal centered show={show === 'create'} onHide={handleClose}>
                <Modal.Header closeButton className={"border-0"} style={{marginBottom: -42, zIndex: 1}}/>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={createOrUpdateVisio}>
                        <Form.Group>
                            <Form.Label>{t("title")} <span className={'required_box'}>*</span></Form.Label>
                            <Form.Control
                                required
                                size={"lg"}
                                onChange={handleTitle}
                                defaultValue={title}
                                className={handleinputValidationError(formError,'title')}
                                />
                                       {handleTextValidationError(formError,'title')}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t("duration")} <span className={'required_box'}>*</span></Form.Label>
                            <Form.Control
                                required
                                type={"number"}
                                size={"lg"}
                                onChange={handleDuration}
                                defaultValue={duration}
                                className={handleinputValidationError(formError,'duration')}
                                />
                                       {handleTextValidationError(formError,'duration')}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t("duration")}</Form.Label>
                            <Form.Control
                                required
                                as={'textarea'}
                                size={"lg"}
                                onChange={handleDesc}
                                defaultValue={desc}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t("conf_date_time")} <span className={'required_box'}>*</span></Form.Label>
                          <Row>
                              <Col md={8}>
                                  <DayPickerInput style={{width:"100%"}}
                                                  inputProps={{className: 'form-control form-control-lg'}}
                                                  format="DD-MM-YYYY"
                                                  formatDate={formatDate}
                                                  placeholder={"Date"}
                                                  dayPickerProps={{
                                                      disabledDays: {before: new Date()},
                                                  }}
                                                  onDayChange={handleConfDate}
                                                  value={confDate} 
                                                  className={handleinputValidationError(formError,'confDate')}
                                                  />
                                                         {handleTextValidationError(formError,'confDate')}
                              </Col>
                              <Col md={4}>
                                  <Form.Control
                                      required
                                      onChange={handleConfTime}
                                      value={confTime}
                                      size={"lg"}
                                      type={"time"}
                                      className={handleinputValidationError(formError,'confTime')}
                                      />
                                             {handleTextValidationError(formError,'confTime')}
                 
                              </Col>
                          </Row>


                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t("participants")}</Form.Label>
                            <Select noOptionsMessage={() => t("no_data_available")}
                                options={target_type_options}
                                defaultValue={selectedTargetType}
                                styles={{
                                    control: base => ({
                                        ...base,
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("participants")}
                                name='target_type'
                                onChange={handleTargetType}
                            />
                        </Form.Group>
                        <Form.Group hidden={target_type!=='branches'}>
                            <Form.Label>{t("branch_title")} </Form.Label>
                            <Select noOptionsMessage={() => t("no_data_available")}
                                options={props.rwState.branches}
                                getOptionLabel={o => o.name}
                                getOptionValue={o => o._id}
                                defaultValue={props.rwState.branches.filter((b)=>selectedTarget?.includes(b._id))}
                                styles={{
                                    control: base => ({
                                        ...base,

                                        minHeight: 48,
                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("branch_title_unique")}
                                isMulti
                                onChange={handleSelect}
                            />
                        </Form.Group>
                        <Form.Group hidden={target_type!=='departments'}>
                            <Select noOptionsMessage={() => t("no_data_available")}
                                options={props.rwState.departments}
                                getOptionLabel={o => o.name}
                                getOptionValue={o => o._id}
                                defaultValue={props.rwState.departments.filter((b)=>selectedTarget?.includes(b._id))}
                                styles={{
                                    control: base => ({
                                        ...base,

                                        minHeight: 48,
                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("department_title_unique")}
                                isMulti
                                onChange={handleSelect}
                            />
                        </Form.Group>
                        <Form.Group hidden={target_type!=='positions'}>
                            <Select noOptionsMessage={() => t("no_data_available")}
                                options={props.rwState.positions}
                                getOptionLabel={o => o.title}
                                getOptionValue={o => o._id}
                                defaultValue={props.rwState.positions.filter((b)=>selectedTarget?.includes(b._id))}
                                styles={{
                                    control: base => ({
                                        ...base,

                                        minHeight: 48,
                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("position_title_unique")}
                                isMulti
                                onChange={handleSelect}
                            />
                        </Form.Group>
                        <Form.Group hidden={target_type!=='employee'}>
                            <Select noOptionsMessage={() => t("no_data_available")}
                                options={props.rwState.colleagues.filter(r=>r.activated)}
                                getOptionLabel={o => o.lastname+''+o.firstname}
                                getOptionValue={o => o._id}
                                defaultValue={props.rwState.colleagues.filter((b)=>selectedTarget?.includes(b._id))}
                                styles={{
                                    control: base => ({
                                        ...base,
                                        minHeight: 48,
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("employee_text")}
                                isMulti
                                onChange={handleSelect}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Button variant={"white"} className={"float-right"} onClick={()=>handleAutoLink()}>Générer un lien</Button>
                            <Form.Group>
                                <Form.Label>Lien de la conférence</Form.Label>
                                <Form.Control
                                    required
                                    size={"lg"}
                                    onChange={handleLink}
                                    value={link}
                                    className={handleinputValidationError(formError,'link')}
                                    />
                                           {handleTextValidationError(formError,'link')}
                            </Form.Group>
                        </Form.Group>
                        <Button  type={"submit"} variant={"primary"} size={"lg"}
                              disabled={
                                handleDisabledBtnValidationError(formError,
                                ['confDate','confTime','title','duration','link'])}
                            block onClick={createOrUpdateVisio} >
                                {t("save")}
                            </Button>
                       
                    </Form>
                </Modal.Body>

            </Modal>
            <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
                <Modal.Header closeButton className={'border-0'}>
                    <Modal.Title>{t("confirmation")}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className={"text-center"}>
                        {t("confirme_irreversile_deletion_text")}
                    </div>
                </Modal.Body>
                <Modal.Footer className={'text-center border-0'}>
                    <div>
                        <div className={"text-center"}>
                            <Button variant="primary" onClick={deleteVisioConf}>
                                {t("yes_delete")}
                            </Button>
                            <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                                {t("cancel")}
                            </Button>
                        </div>
                    </div>

                </Modal.Footer>
            </Modal>

        </Container>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Visio);
