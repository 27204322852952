import React, {useEffect, useState} from 'react';
import './AskingHolidays.css'
import {Button, Col, Container, Form,Dropdown , Modal, Row, Tab} from "react-bootstrap";
import mSVG from '../../../assets/gender/m.png'
import * as BI from 'react-icons/bi'
import * as BS from 'react-icons/bs'
import * as actions from "../../../actions";
import connect from "react-redux/es/connect/connect";
import { isOnlyAdmin, getFormatedDate, handleDisabledBtnValidationError, handleValidation, handleinputValidationError, handleTextValidationError} from "../../../helpers/helper";
import { useTranslation } from 'react-i18next';

const mapDispatchToProps = {...actions};
const mapStateToProps = (state) => {
    return {rwState: state}
}
const AskingHolidays = (props) => {
    const { t } = useTranslation();
    const role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    let connectedUser = JSON.parse(window.localStorage.getItem('glowhr_user'));
    const [to, setTo] = useState()
    const [from, setFrom] = useState()
    const [current, setCurrent] = useState(props.leave)
    const [showAlert, setShowAlert] = useState(false);
    const [ansBool, setAnsBool] = useState({});
    const [currentLeave, setCurrentLeave] = useState({});
    const [reason, setReason] = useState(null);
    const [formError, setFormError] = useState({})

    const answerLeave = ()=>{
        setShowAlert( null );
        let response;
        switch (ansBool) {
            case "accepter":
                response = 1;
                break;
            case "annuler":
                response=-2;
                break;
            case "refuser":
                response = -1;
                break;
                case "en attente":
                response = 0;
                break;
        }
        let l = {
            leave:currentLeave._id,
            approved:response
        };
        props.action_answerLeave(l);
        props.action_loading(true);
    };
    const handleFromChange = (e) => {
        setFrom(e.target.value)
        let data =handleValidation(t,{type:"date",name:'fromChange','value':from,required:true,min:new Date()});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }

    const handleToChange = (e) => {
        setTo(e.target.value)
        let data =handleValidation(t,{type:"date",name:'toChange','value':to,required:true,min:new Date(from)});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }

    const formatDateNow = (date) => {
        Number.prototype.AddZero= function(b,c){
            var  l= (String(b|| 10).length - String(this).length)+1;
            return l> 0? new Array(l).join(c|| '0')+this : this;
        }//to add zero to less than 10,
        var d = new Date(date);
        return [d.getFullYear(),
                (d.getMonth() + 1).AddZero(),
                d.getDate().AddZero()].join('-') + 'T' +
            [d.getHours().AddZero(),
                d.getMinutes().AddZero()].join(':');
    }
    const updAskLeave = (data) => {
        setFrom(formatDateNow(data.start_date));
        setTo(formatDateNow(data.end_date));
        setShowAlert('ask');
    }
    const resetForm = () => {
        setFrom(null);
        setTo(null);
        setShowAlert('');
    }
    const updateAskLeave = () => {
        let o = {
            "start_date": from,
            "end_date": to,
            "id": current._id,
        };
        props.action_updateAskLeave(o);
        resetForm()
    }

    const getStatus = (status)=>{
        let data = {};
        switch (status) {
            case 1:
                data.status = "Acceptée";
                data.statusClass="status-accepted";
                break;
            case 0:
                data.status = "En attente";
                data.statusClass="status-pending";
                break;
            case -1:
                data.status = "Refusée";
                data.statusClass="status-refused";
                break;
            case -2:
                data.status = "Annulée";
                data.statusClass="status-canceled";
                break;
        }
        return data;
    };
    const handleConfirm = (data)=>{
        setAnsBool(data.ansBool);
        setCurrentLeave(data.leave);
        setShowAlert(data.modal);
    };

    useEffect(()=>{
    },[]);
    return <div id={'AskingHolidays'}>
        <div className={'p-4'}>
            <div className={"d-flex align-items-center text-white type mb-3"}>
                <div><BS.BsCalendar/></div>
                <div className={"ml-3"}>{props.leave.type_info[0].name}</div>
            </div>
            <div className={'d-flex align-items-start mb-3'}>
                <div className={'mr-3'}>
                    <img src={mSVG} style={{width: 68}}/>
                </div>
                <div>
                <Dropdown className={"float-right manage-dropdown mr-5"}>
                        <Dropdown.Toggle variant="white" id="dropdown-basic">
                            <BS.BsThreeDotsVertical/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {(() => {
                                const isNotAdminOrHasReportsTo = !isOnlyAdmin(role) || 
                                                                (props.leave.employee_info?.[0]?.job_info?.[0]?.reports_to && 
                                                                props.leave.employee_info[0].job_info[0].reports_to !== connectedUser.job_info?.[0]?.employee);
                                const isPendingStatus = props.leave.status === 0;

                                return (
                                    <>
                                        {isNotAdminOrHasReportsTo || !isPendingStatus ? null : (
                                            <Dropdown.Item onClick={() => handleConfirm({ modal: "confirm", leave: props.leave, ansBool: "refuser" })}>
                                                <BI.BiCircle style={{ color: '#240265', fontWeight: '600' }} size={15} /> {t("reject")}
                                            </Dropdown.Item>
                                        )}

                                        {isNotAdminOrHasReportsTo || !isPendingStatus ? null : (
                                            <Dropdown.Item onClick={() => handleConfirm({ modal: "confirm", leave: props.leave, ansBool: "accepter" })}>
                                                <BI.BiCheckDouble style={{ color: '#240265', fontWeight: '600' }} size={15} /> {t("accept")}
                                            </Dropdown.Item>
                                        )}

                                        {props.view !== 'employee' || !isPendingStatus ? null : (
                                            <Dropdown.Item onClick={() => handleConfirm({ modal: "confirm", leave: props.leave, ansBool: "annuler" })}>
                                                <BI.BiTrashAlt style={{ color: '#240265', fontWeight: '600' }} size={15} /> {t("cancel")}
                                            </Dropdown.Item>
                                        )}
                                    </>
                                );
                            })()}
                       {/*  {props.view !== 'employee' ||( props.leave.status>1 && props.leave.status<=0) ? */}
                        {( props.leave.status>1 && props.leave.status<=0) ?
                            "" :
                            <Dropdown.Item onClick={() =>updAskLeave(props.leave)}>
                                <BI.BiEraser style={{color: '#240265', fontWeight: '600',}} size={15}/> {t("update")}
                            </Dropdown.Item>
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div>
                    <div className={'user mb-2'}>{props.leave.employee_info[0].firstname+" "+props.leave.employee_info[0].lastname}</div>
                    <div className={getStatus(props.leave.status).statusClass}>{getStatus(props.leave.status).status}</div>
                    <div className={'reason mt-2'} onClick={()=>{setReason(props.leave.description);setShowAlert('reason')}}>{t("see_reason")} </div>

                </div>
            </div>
            <div className={'dates mt-2'}>
               {t("from")} {getFormatedDate(props.leave.start_date)} 
              
            </div>
            <div className={'dates mt-2'}>
                {t("to")} {getFormatedDate(props.leave.end_date)} 
            </div>
            <div>
                <Modal show={showAlert==="confirm"} onHide={() => setShowAlert(null)} centered size={"md"}>
                    <Modal.Header closeButton className={'border-0'}>
                        <Modal.Title>{t("confirmation")}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className={"text-center"}>
                            {t("confirme_irreversile_deletion_text")}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={'text-center border-0'}>
                        <div>
                            <div className={"text-center"}>
                                <Button variant="primary" onClick={answerLeave}>
                                   {t("yes_continous")}
                                </Button>
                                <Button className={"ml-3"}  variant="light" onClick={() => setShowAlert(false)}>
                                    {t("cancel")}
                                </Button>
                            </div>
                        </div>

                    </Modal.Footer>
                </Modal>
                <Modal show={showAlert==="reason"} onHide={() => setShowAlert(null)} centered size={"md"}>
                    <Modal.Header closeButton className={'border-0'}>
                        <Modal.Title>{t("request_reason")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={"text-center m-3"}>
                            {reason?reason: t("no_reason")}
                        </div>
                    </Modal.Body>

                </Modal>
                <Modal centered show={showAlert === 'ask'} onHide={() => setShowAlert(null)}  size={"md"}>
                <Modal.Header closeButton className={"border-0"}/>
                <Modal.Body>
                    <Form  noValidate>
                    <Row>
                        <Col  lg="6" sm="12">
                            <Form.Group>
                                <Form.Label>{t("start_date")} <span className={'required_box'}>*</span></Form.Label>
                               
                                    <Form.Control
                                        onChange={handleFromChange}
                                        value={from}
                                        type={"datetime-local"}
                                        className={handleinputValidationError(formError,'fromChange')}
                                    />

                                           {handleTextValidationError(formError,'fromChange')}
                          </Form.Group>
                        </Col>
                        <Col lg="6" sm="12">
                            <Form.Group>
                                <Form.Label>{t("end_date")}  <span className={'required_box'}>*</span></Form.Label>
                                <Form.Control
                                    onChange={handleToChange}
                                    min={from}
                                    value={to}
                                    type={"datetime-local"}
                                    className={handleinputValidationError(formError,'toChange')}
                                    />
                                           {handleTextValidationError(formError,'toChange')}
                         
                            </Form.Group>
                        </Col>
                    </Row>
                        <Modal.Footer className={'border-0 pt-0'}>
                            <Button 
                             disabled={
                                handleDisabledBtnValidationError(formError,
                                ['fromChange','toChange'])}
                            type={"button"} onClick={()=>{updateAskLeave()}} variant="primary" block size={"lg"} >
                                {t("update_btn")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>

            </Modal>
            </div>
        </div>
    </div>
        ;
};

export default connect(mapStateToProps, mapDispatchToProps)(AskingHolidays);
