import React, {useEffect, useState} from 'react';
import {useParams,useHistory} from 'react-router-dom'
import "./SurveyStats.css"
import {Tab,  Modal, Button, Col, Container, Row, Card,  Badge, ListGroup} from "react-bootstrap";
import ContextMenu from "../../menus/ContextMenu";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import * as BS from "react-icons/bs";
import * as BI from "react-icons/bi";
import mSVG from "../../../assets/gender/m.png";
import Emitter from "../../../service/events";
import {isAdmin, handleValidation, handleFinaleValidation
} from "../../../helpers/helper";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const SurveyStats = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    let { id } = useParams();
    let params_id=id
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    const [show, setShow] = useState([])
    const [answers, setAnswers] = useState([])
    const [title, setTitle] = useState()
    const [curentSurvey, setcurentSurvey] = useState()
    const [question, setQuestion] = useState()
    const [questions, setQuestions] = useState()
    const [propositions, setPropositions] = useState(0)
    const [status, setStatus] = useState()
    const [comment, setComment] = useState();
    const [current_row, setCurrentRow] = useState();
    const [selectedNote, setSelectedNote] = useState(0);
    const [isUpdate, setHasUpdate] = useState(false);
    const q_types = [
        { label: t("qcm_title"), value: "qcm" },
        { label: t("qcu_title"), value: "qcu" },
        { label: t("free_response_title"), value: 'response' },
        { label: t("true_or_false_title"), value: 'true_or_false' },
        { label: t("yes_or_no_title"), value: 'yes_or_no' },
        { label: 'Note', value: 'note' },
    ];
    const [seletectedType, setSelectedType] = useState(q_types[0]);
    const [type, setType] = useState(seletectedType.value);
    const [success,setSuccess]=useState(true);
    const [inputList, setInputList] = useState([]);
    const [formError, setFormError] = useState({})

    const [pos, setPos] = useState(0);
    const handleClose = () => {
        setShow(null)
        setFormError({})
    }

    const handleTitle = (e) => {
        setTitle(e.target.value)
    };

    const handleTypeSelect = (e) => {
      //  console.log('type ==> ',e.target.value)
        setType(e.value)
        let data =handleValidation(t,{type:"text",name:'typeSelect','value':e.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handleComment = (e) => {
        setComment(e.target.value)
    };
    const handlePropositions = (e,position) => {
        if (inputList.length<=4){
            const list = [...inputList];
            list[position] = e.target.value;
            setInputList(list);
        }
    };
    const  preselectType= (val)=>{
      let count=-1,number=undefined;
        q_types.forEach(r=>{
            count++;
            if(r.value===val) number=count;
        })
      return number;
    };
    const handleQuestion = (e) => {setQuestion(e.target.value)
        let data =handleValidation(t,{type:"text",name:'question','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
            };
    const editSurvey = (survey)=>{
        setCurrentRow(survey);
      let type_number =  preselectType(survey.type)
        setQuestion(survey.question);
        setSelectedType(q_types[type_number]);
        setType(survey.type)
        // setPropositions(survey.propositions);
        setInputList(survey.propositions)
        setHasUpdate(true);
        setShow('create')
        let data=  handleFinaleValidation(t,[
            {type:"text",name:'typeSelect','value':survey.type,required:true},
            {type:"text",name:'question','value':survey.question,required:true}
           ])
                setFormError(data)
    };
    const deleteSurveyConfirm = (survey)=>{
        setcurentSurvey(survey);
        setShow('confirm')
    };
    const reinializeSurveyForm = ()=>{
        setQuestion('');
        setSelectedType(q_types[0]);
        setType(seletectedType.value);
        setInputList([]);
        setFormError({})
    }
    const showSurveyQuestion = (survey)=>{
      setcurentSurvey(survey)
        setShow('details')
    };
    const addNewProp = ()=>{
        if (inputList.length<=3){
            setInputList([...inputList, inputList.length]);
            console.log(inputList)
        }
    };
    const removeProp = index => {
       const list = [...inputList];
        list.splice(index-1, 1);
        setInputList(list);
    };
    const createSurveyQuestion = () => {
        let o = {
            "question": question,
            "type": type,
            "propositions": inputList,
            "survey_info": params_id,
            "sio_channel": "",
        };
        if (!isUpdate)
            props.action_createSurveyQuestion(o)
        else{
            o.id = current_row._id;
            props.action_updateSurveyQuestion(o);
        }
        reinializeSurveyForm();
        setShow(null);
        props.action_loading(true)
    };
    const deleteSurvey = () => {
        props.action_deleteSurveyQuestion(curentSurvey);
        setShow(null);
        props.action_loading(true)
    };
    const contextMenu =
        [
            {label: t("stats"), eventKey: 'stats'},
            {label: t("comments"), eventKey: 'comments'},
        ];

    const handleNextBtn = (pos) => {
        setPos(pos)
    };
    const handleAnswer = (e,question,index) => {
        let key = question._id;
        let tempAnswers= answers;
        if(!tempAnswers[index]){
            tempAnswers[index] = {};
            tempAnswers[index][key] = [];
            setAnswers(tempAnswers)
        }
        let tempAnsArr =  tempAnswers[index][key];
        console.log(index,tempAnswers)
        console.log({tempAnsArr})
        if (question.type==="note")                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       if (question.type==="note")
            setSelectedNote(e.target.value+1)
        if (tempAnsArr && !tempAnsArr.includes(e.target.value)){
            if (question.type==="qcm")
            tempAnsArr.push(e.target.value);
            else
                tempAnsArr[0] = e.target.value
        } else{
            if (tempAnsArr) 
            tempAnsArr = tempAnsArr.filter(a=>a!==e.target.value)
        }
        tempAnswers[index][key] = tempAnsArr;
        setAnswers(tempAnswers)
    };

    const submitAnswers = ()=>{
        console.log({answers})

        let datas = {
            answers : answers,
            comment : comment,
            survey:props.rwState.survey
        };
        props.action_submitSurvey(datas);
        props.action_loading(true);
    }
    useEffect(()=>{
        props.action_loading(true);
        props.action_oneSurvey(id);
        props.action_setTitle(t("survey_details"))
        if (!props.survey)
            props.action_loading(true);
        else
            props.action_loading(false);
        if (props.rwState.survey)
        setQuestions(props.rwState.survey.questions)
        if (props.rwState.survey.questions){
            let arr = [];
            props.rwState.survey?.questions.forEach((v)=>{
                let ansValue = {};
                ansValue[v._id] = [];
                arr.push(ansValue)
            });
            setAnswers(arr);
        }

        Emitter.on('submit-response-received',(datas)=>{
           setSuccess(datas.error);
        });

    },[]);

    const goupResponses = (responses)=>{
        //const arr = ["foo", "bar", "foo", "bar", "bar", "bar", "zoom"];
        const data = Object.assign({}, ...Array.from(new Set(responses), key => ({[key]: responses.filter(value => value === key).length})));
        return data;
    }
    const buildResponseView = (q)=>{

        switch (q.type) {
            case 'qcm':
            case 'qcu':
                return (
                    <ListGroup variant="flush">
                        {
                            q.propositions.map((p)=>{
                                return   (
                                    <ListGroup.Item>{p} <Badge variant={"primary"} className={"float-right"}><BS.BsFillPeopleFill/> {goupResponses(q.responses)[p]||0}</Badge></ListGroup.Item>
                                )
                            })
                        }
                    </ListGroup>
                );

            case 'note':
                return (
                    <ListGroup variant="flush">
                        {
                            Array.apply(null, {length: 10}).map((Number,N)=>{
                                let total = goupResponses(q.responses)[N+1]||0;
                                return   (
                                    <ListGroup.Item hidden={total===0}>Note de {N+1} <Badge variant={"primary"} className={"float-right"}><BS.BsFillPeopleFill/> {total}</Badge></ListGroup.Item>
                                )
                            })
                        }
                    </ListGroup>
                );
            case 'true_or_false':
                return (
                    <ListGroup variant="flush">
                        <ListGroup.Item>{t("true")} <Badge variant={"primary"} className={"float-right"}><BS.BsFillPeopleFill/> {goupResponses(q.responses)['Vrai']||0}</Badge></ListGroup.Item>
                        <ListGroup.Item>{t("false")} <Badge variant={"primary"} className={"float-right"}><BS.BsFillPeopleFill/> {goupResponses(q.responses)['Faux']||0}</Badge></ListGroup.Item>
                    </ListGroup>
                )
            case 'yes_or_no':
                return (
                    <ListGroup variant="flush">
                        <ListGroup.Item>{t("yes")} <Badge variant={"primary"} className={"float-right"}><BS.BsFillPeopleFill/> {goupResponses(q.responses)['Oui']||0}</Badge></ListGroup.Item>
                        <ListGroup.Item>{t("no")} <Badge variant={"primary"} className={"float-right"}><BS.BsFillPeopleFill/> {goupResponses(q.responses)['Non']||0}</Badge></ListGroup.Item>
                    </ListGroup>
                )
            case 'response':
                return(
                    <ListGroup variant="flush">
                        {
                            q.responses.map((r)=>{
                                return   (
                                    <ListGroup.Item>{r} </ListGroup.Item>
                                )
                            })
                        }
                    </ListGroup>
                )
        }
    }
    return <div id={"SurveysDetails"}>
        <Container fluid>
            <Col  className={'text-left pl-0'}>
                <Button className={"btn btn-light btn-sm"} onClick={e => history.goBack()}>
                    <BI.BiChevronLeft style={{color: '#240265', fontWeight: '600',}} size={25}/> {t("return")}
                </Button>
            </Col>
            <Row hidden={!isAdmin(role)}>
                <Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={'stats'}>
                    <div className="d-flex align-items-start justify-content-between">
                            <ContextMenu menu={contextMenu}/>
                            </div>
                        <Tab.Content>
                            <Tab.Pane className={"lastRollL"} eventKey="stats">
                            <Card className={"p-2 border-0 mb-4"} style={{borderRadius:5,marginBottom:5}}>
                                <div className="col-md-12">
                                        <h2 className="mb-0"> Sondage : <strong> {props.rwState.survey?.title}</strong>
                                        <span title={props.rwState.survey?.participants?.length+" Participant(s)"} className={"text-danger float-right"}>
                                        <BS.BsFillPeopleFill className={'text-danger'}/> {props.rwState.survey?.participants?.length}
                                    </span>
                                        </h2>
                                    </div>
                                </Card>
                                {props.rwState.questions && props.rwState.questions.length>0?
                                props.rwState.questions.map((q)=>{
                                        goupResponses(q.responses);
                                        return <Card className={"p-2 pl-4 border-0 mb-2"} style={{borderRadius:5}}>
                                            <div className={"mt-3"}>
                                                <h3>{t("question")} : <strong> {q.question}</strong></h3>
                                                <div>
                                                    {buildResponseView(q)}
                                                </div>
                                            </div>
                                        </Card>
                                    })
                                    :
                                    <Card className={"p-4"}>
                                        <div className={"alert alert-warning"}>{t("no_stats")}</div>
                                    </Card>
                                }
                            </Tab.Pane>
                            <Tab.Pane className={"lastRollL"} eventKey="comments">
                            <Card className={"p-2 pl-4 border-0"} style={{borderRadius:5}}>
                                  <table className={"table mb-0 table-borderless table-striped"}>
                                      { props.rwState.survey?.comments?.length>0?
                                          props.rwState.survey?.comments.map((comment)=>{
                                              return <tr>
                                                  <td>
                                                      <div className={'mr-3'}>
                                                          <img src={mSVG} style={{width:38,height:38}}/>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <a href={"mailto:"+comment.email}>{comment.email}</a>
                                                  </td>
                                                  <td>
                                                      {comment.comment}
                                                  </td>
                                              </tr>
                                          })
                                          :
                                          <div className={"alert alert-warning"}>{t("no_comment")}</div>
                                      }

                                  </table>
                                </Card>
                            </Tab.Pane>
                            </Tab.Content>
                    </Tab.Container>
                </Col>
            </Row>
           
        </Container>

        {/*  modals */}
       <Modal show={show === "confirm"} onHide={handleClose} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("confirmation")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={"text-center"}>
                    {t("confirme_irreversile_deletion_text")}
                </div>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>
                        <Button variant="primary" onClick={deleteSurvey}>
                            {t("yes_delete")}
                        </Button>
                        <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                            {t("cancel")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
        <Modal centered show={show === 'details'} onHide={handleClose}>
            <Modal.Header close className={"bg-primary"}>
                <h4 className={"col-lg-12 text-white text-center"}>{t("Détails")}</h4>
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <div className={"col-12"}>
                   <div><strong>{t("type")} :</strong> {curentSurvey ? curentSurvey.type :''}</div>
                    <br/>
                    <h6><strong>{t("question")} :</strong></h6>
                    <p>{curentSurvey ? curentSurvey.question :''}</p>
                </div>
                {curentSurvey && curentSurvey.propositions&& curentSurvey.propositions.length > 0 ?
                    < div className = {"col-12"} >
                        <h6><strong> {t("propositions")}</strong> </h6>
                            <ListGroup variant="flush">
                                { curentSurvey.propositions.map((surv,i) => {
                    return <ListGroup.Item className={"ml-0 pl-0"}>{surv}</ListGroup.Item>
                    })}
                    </ListGroup>
                        </div>
                    :
                   <span></span>
                }

            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("close")}
                </Button>
            </Modal.Footer>
        </Modal>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyStats);
