import React, {useEffect, useState} from 'react';
import "./OuttegrationCalendar.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {Calendar,momentLocalizer,  Views} from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/fr'
import * as actions from "../../../actions";
import {connect} from "react-redux";
import {Button, Card, Col, Container, Form, Modal, Row, Tab} from "react-bootstrap";
import 'react-day-picker/lib/style.css';
import * as RI from "react-icons/ri";
import * as BI from "react-icons/bi";
import {useHistory,useParams} from 'react-router-dom'
import {isAdmin} from "../../../helpers/helper";
import Select from "react-select";
import DataTable from "react-data-table-component";
import { useTranslation } from 'react-i18next';

moment.locale('fr');
moment().format("ll");
require('globalize/lib/cultures/globalize.culture.en-GB')
require('globalize/lib/cultures/globalize.culture.es')
require('globalize/lib/cultures/globalize.culture.fr')
require('globalize/lib/cultures/globalize.culture.ar-AE')
const localizer = momentLocalizer(moment) // or globalizeLocalizer

const mapStateToProps = (state) => {
  return {rwState: state}
}
const mapDispatchToProps = {...actions}

const OuttegrationCalendar = (props) => {
    const { t } = useTranslation();
    const messages = {
        date: t("date"),
        time: t("hour"),
        event: t("event_title"),
        allDay: t("all_days"),
        week: t("weekend"),
        work_week: t("work_weekend"),
        day: t("day"),
        month: t("month"),
        previous: t("previous"),
        next: t("next"),
        yesterday: t("yesterday"),
        tomorrow: t("tomorrow"),
        today: t("today"),
        agenda: t("agenda"),
        noEventsInRange: t("no_event_found"),
        showMore: total => `+${total} `+t("more"),
      }
    let {id }=useParams(),
    params_id=id;
    const [events, setEvents] = useState([])
    const [show, setShow] = useState([])
    const [close, setClose] = useState([])
    const [type_event, setEventType] = useState()
    const [event_target, setEventTarget] = useState([])
    const [location, setLocation] = useState()
    const [attendees_target_id, setAttendeesTargetId] = useState([])
    const [attendees_target, setAttendeesTarget] = useState("all")
    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const [event_description, setEventDescription] = useState()
    const [isEventTypeUpdate, setIsEventTypeUpdate] = useState(false)
    const [isEventUpdate, setIsEventUpdate] = useState(false)
    const [actionData, setActionData] = useState({})
    const [title, setTitle] = useState()
    const [start, setStart] = useState()
    const [end, setEnd] = useState()
    const [current_row, setCurrent_row] = useState({})
    const [current_event_type_row, setCurrent_event_type_row] = useState({})
    const affectations_type =
            [   { label: t('all'), value: 'all' },
            { label: t("departments"), value: "departments"},
            { label: t('branches'), value: 'branches'},
            { label: t('positions'), value: 'positions'},
            { label: t('specific_employee'), value: 'employee'}
            ]
    const [affect, setAffect] = useState(affectations_type[0])
    const [assignTo, setAssignTo] = useState([])
    const handleName=(e)=>{
        setName(e.target.value)
    }
 const handleLocation=(e)=>{
        setLocation(e.target.value)
    }

    const handleDescription=(e)=>{
        setDescription(e.target.value)
    }

 const handleEventDescription=(e)=>{
        setEventDescription(e.target.value)
    }

    const handleAffectation_type = (e) => {
        setAssignTo([]);
        setAffect(e)
        setAttendeesTarget(e.value)
    };

    const handleSelectAssign = (e) => {
        setAssignTo(e)
        setAttendeesTargetId(e.map(res=>{return res._id}))
    };
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    let user = JSON.parse(window.localStorage.getItem('glowhr_user')).user;
    const history = useHistory();
    useEffect(() => {
        props.action_loading(true)
        props.action_listDepart();
        props.action_listFormations();
        props.action_listColleagues();
        props.action_listEventType()
        props.action_listEvent()
    }, [])

    const formatEvents=(events)=>{
         let data=[];
        if(events && events.length>0){
            let depart= props.rwState.departs.find(res=>res._id===params_id)
            data= events.map(res=>{
             if(res.attendees.find(r=>r._id===depart.employee_info._id))
                 return {id:res._id,
                         title:
                             res.employeeCible ? (res.employeeCible._id===params_id ?
                             res.event_type_info?.name+': '+res.subject :
                            res.event_type_info?.name+' ( '+res.employeeCible.lastname+' '+res.employeeCible.firstname+' ): '+res.subject)
                     : res.event_type_info?.name+': '+res.subject ,
                     desc:res.description,
                     start:new Date(formatDateNowSpec(res.start_date,'year'),formatDateNowSpec(res.start_date,'month'),formatDateNowSpec(res.start_date,'date'),formatDateNowSpec(res.start_date,'hour'),formatDateNowSpec(res.start_date,'minute'),formatDateNowSpec(res.start_date,'seconde')),
                     end:new Date(formatDateNowSpec(res.end_date,'year'),formatDateNowSpec(res.end_date,'month'),formatDateNowSpec(res.end_date,'date'),formatDateNowSpec(res.end_date,'hour'),formatDateNowSpec(res.end_date,'minute'),formatDateNowSpec(res.end_date,'seconde'))};
            })
        }
        return data;
    }

    const resetEventType =()=>{
        setName("")
        setDescription("")
        setIsEventTypeUpdate(false);
    }
    const resetEvent =()=>{
        setTitle("")
         setAffect(affectations_type[0])
        setEventDescription("")
        setIsEventTypeUpdate(false);
        setStart("")
        setEnd("")
        setEventDescription("")
        setLocation("")
        setEventType("")
        setAttendeesTargetId([])
    }
    const editEvent=(event)=>{
        let events=props.rwState.events;
        let data= events.find(r=>r._id===event.id)
        if(data){
            let affect=affectations_type.find(r=>r.value===data.attendees_target)
            setAffect(affect)
            setStart(formatDateNow(data.start_date))
            setEnd(formatDateNow(data.end_date))
            setAttendeesTargetId(data.attendees_target_id)
            setAttendeesTarget(data.attendees_target)
            setTitle(data.title)
            setEventDescription(data.description)
            setLocation(data.location)
            setEventType(data.event_type_info)
        }
        setIsEventUpdate(true)
        setCurrent_row(data)
        isAdmin(role) ? setShow('create_event') : setShow('details_event')
    }
    const createEventType= () => {
        let o = {
            "name": name,
            "description": description,
            "sio_channel": "",
            "id":current_event_type_row?._id
        };
        if (!isEventTypeUpdate)
            props.action_createEventType(o)
        else
            props.action_updateEventType(o);
        setShow('list_type_event')
        props.action_loading(true)
        resetEventType();
    }
    const createEvent= () => {
         let o = {
            "subject": title,
            "description": event_description,
            "location": location,
             "employeeCible":params_id,
            "attendees_target": attendees_target,
            "attendees_target_id": attendees_target_id,
            "start_date": start,
            "end_date": end,
            "target":type_event.name,
            "target_id":event_target,
            "event_type_info": type_event._id,
            "sio_channel": "",
            "id":current_row?._id
        };
        if (!isEventUpdate)
            props.action_createEvent(o)
        else
            props.action_updateEvent(o);
        setShow(null)
        props.action_loading(true)
        resetEvent();
    }
   const confirmUpdateEventType= (o) => {
       setCurrent_event_type_row(o)
       setName(current_event_type_row?.name)
       setDescription(current_event_type_row?.description)
       setIsEventTypeUpdate(true)
       setShow('create_type_event')
    }
    const formatDateNow = (date) => {
        Number.prototype.AddZero= function(b,c){
            var  l= (String(b|| 10).length - String(this).length)+1;
            return l> 0? new Array(l).join(c|| '0')+this : this;
        }//to add zero to less than 10,
        var d = new Date(date);
        return [d.getFullYear(),
                (d.getMonth() + 1).AddZero(),
                d.getDate().AddZero()].join('-') + 'T' +
            [d.getHours().AddZero(),
                d.getMinutes().AddZero()].join(':');
    }
  const formatDateNowSpec = (date,type) => {
      Number.prototype.AddZero= function(b,c){
          var  l= (String(b|| 10).length - String(this).length)+1;
          return l> 0? new Array(l).join(c|| '0')+this : this;
      }//to add zero to less than 10,
        var d = new Date(date),
            f=0;
        switch (type) {
            case 'hour':
                f=d.getHours()+1;
                break;
   case 'minute':
                f=d.getMinutes();
                break;
   case 'year':
                f=d.getFullYear();
                break;
   case 'month':
                f=d.getMonth();
                break;
   case 'date':
                f=d.getDate();
                break;
case 'seconde':
                f=d.getSeconds();
                break;
        }
      return f;
    }
   const handle_type_event = (e) => {
        setEventType(e)
    }
 const handle_event_target = (e) => {
        setEventTarget(e)
    }
  const handleClose = () => {
        setShow(null)
      resetEvent();
    }
   const handleStart = (e) => {
        setStart(e.target.value)
    }
   const handleEnd = (e) => {
         setEnd(e.target.value)
    }
   const handleTitle = (e) => {
        setTitle(e.target.value)
    }
    const handleLatitude = (e) => {
        setLatitude(e.target.value)
    }
   const handleLongitude = (e) => {
        setLongitude(e.target.value)
    }
    const handleSelect = ({ start, end }) => {
        setStart(formatDateNow(start))
        setEnd(formatDateNow(end))
        setShow('create_event')
    }
    const contextMenu = [
        {label: t("companie"), eventKey: 'cCalendar'},
        {label: t("personal"), eventKey: 'pCalendar'},
    ];
    const typeEventColumns = [
        {
            name: t("last_name"),
            selector: 'name'
        },
        {
            name: t("description"),
            selector: 'description'
        },
        {
            name:<strong style={{marginLeft: '0%',width:'150px'}}>Actions</strong>,
            cell: row => <Button onClick={() => confirmUpdateEventType(row)} variant={"link"} size={"sm"}><BI.BiPencil size={22}/></Button>,
            grow:0
        },
        {
            cell: row => <Button  onClick={()=>{confirmAction({target:'event_type',o:row})}}  variant={"link"} size={"sm"}><BI.BiTrash style={{color: 'red'}} size={22}/></Button>,
            grow:0
        }
    ];
    const confirmDeleteAction=()=> {
        let data = actionData;
        switch (data.target) {
            case 'event_type':
                props.action_deleteEventType(data.o)
                props.action_loading(true)
                setShow("list_type_event")
                break
            case 'event':
                props.action_deleteEvent(data.o)
                props.action_loading(true)
                setShow(null)
                break
        }
    }
    const confirmAction=(data)=> {
        setShow('confirm')
        setActionData(data);
        switch (data.target) {
            case 'event_type':
                setClose('list_type_event')
                break
            case 'event':
                setClose(null)
                break
        }
    }
    const employeeSelect=(data,departs)=>{
       let depart= departs.find(res=>res._id===params_id)
    if(data)  return  data.find(res=>res._id===depart.employee_info._id);
    }
    return <div className={"col-12"}>
      <Container fluid>
          <Row>
              <Col sm={"12"} className={'text-left mb-3'}>
                  <Button className={"btn btn-light btn-sm"} onClick={e => history.push({pathname:'/dash/inout'})}>
                      <BI.BiChevronLeft style={{color: '#240265', fontWeight: '600',}} size={25}/>  {t("return")}
                  </Button>
                  <Button className={"btn btn-primary btn-sm float-right"} onClick={()=>setShow('create_event')}>
                      <BI.BiCog style={{color: '#dedede', fontWeight: '600',}} size={20}/>  {t("parameter_action")}
                  </Button>
              </Col>
              <Col>
                  {props.rwState.colleagues ?
                <Col sm={12} className={"mb-4"}>
                    <h6>{t("departure_parameter_action_text")} de {employeeSelect(props.rwState.colleagues,props.rwState.departs)?.lastname+' '+employeeSelect(props.rwState.colleagues,props.rwState.departs)?.firstname}</h6>
                </Col>
                      : ""}
                     <div className="d-flex align-items-start justify-content-between">
                      </div>
                           <Col sm={12}>
                                  <Calendar
                                      selectable
                                      rtl={'fr'}
                                      culture={'fr'}
                                      localizer={localizer}
                                      events={formatEvents(props.rwState.events)}
                                      startAccessor="start"
                                      endAccessor="end"
                                      step={15}
                                      timeslots={8}
                                      defaultView={Views.WEEK}
                                      style={{height: '100vh'}}
                                      onSelectEvent={event => editEvent(event)}
                                      onSelectSlot={handleSelect}
                                      messages={messages}
                                  />
                              </Col>
              </Col>
          </Row>
      </Container>
      <Modal centered show={show === 'create_event'} onHide={handleClose} backdrop="static" size={"lg"}>
          <Modal.Header close className={"bg-primary"}>
              <h4 className={"col-lg-12 text-white text-center"}>{t("ceate_event_action")}</h4>
          </Modal.Header>
          <Modal.Body className={'p-4'}>
              <Row>
          <Col sm={12}>
              <Form.Group>
                  <Form.Label>{t("event_type_text")}</Form.Label>
                  <Select noOptionsMessage={() => t("no_data_available")} options={props.rwState.event_types.filter(res=>res.type==='depart' || res.type==='global')}
                          getOptionLabel={o => o.name}
                          getOptionValue={o => o._id}
                          defaultValue={current_row?.event_type_info}
                      styles={{
                          control: base => ({
                              ...base,
                          })
                      }}
                      theme={theme => ({
                          ...theme,
                          colors: {
                              ...theme.colors,
                              primary: '#6600CC',
                              primary25: '#6600CC25',
                              primary75: '#6600CC75',
                              primary50: '#6600CC50'
                          },
                      })}
                      placeholder={'Choisir un type'}
                      name='type'
                      onChange={handle_type_event}
                  />
              </Form.Group>

              <Form.Group>
                      <Form.Label>{t("title")}</Form.Label>
                      <Form.Control
                          onChange={handleTitle}
                          defaultValue={current_row?.subject}
                          type={"text"}/>
              </Form.Group>
          </Col>

              <Col sm={12} md={6}>
             <Form.Group>
                      <Form.Label>{t("start_")}</Form.Label> <br/>
                 <Form.Control
                      onChange={handleStart}
                     value={start}
                     type={"datetime-local"}/>
              </Form.Group>
              </Col>
              <Col sm={12} md={6}>
              <Form.Group>
                      <Form.Label>{t("end_")}</Form.Label> <br/>
                  <Form.Control
                      onChange={handleEnd}
                      min={start}
                      value={end}
                      type={"datetime-local"}/>
              </Form.Group>
              </Col>
              <Col sm={12}>
              <Form.Group>
                      <Form.Label>{t("description")}</Form.Label>
                      <Form.Control as="textarea" rows={3}
                                    defaultValue={current_row?.description}
                                    onChange={handleEventDescription}
                          type={"text"}/>
              </Form.Group>
               <Form.Group>
                      <Form.Label>{t("location")}</Form.Label>
                      <Form.Control as={"input"}
                                    defaultValue={current_row?.location}
                                    onChange={handleLocation}
                          type={"text"}/>
              </Form.Group>
              </Col>
                  <Col sm={12}>
                      <Form.Label>{t("add_participants")}</Form.Label> <br/>
                      <Form.Group>
                          <Select noOptionsMessage={() => t("no_data_available")}
                              options={affectations_type}
                              defaultValue={affect}
                              styles={{
                                  control: base => ({
                                      ...base,
                                  })
                              }}
                              theme={theme => ({
                                  ...theme,
                                  colors: {
                                      ...theme.colors,
                                      primary: '#6600CC',
                                      primary25: '#6600CC25',
                                      primary75: '#6600CC75',
                                      primary50: '#6600CC50'
                                  },
                              })}
                              placeholder={t("add_to")}
                              name='affect_to'
                              onChange={handleAffectation_type}
                          />
                      </Form.Group>
                      <Form.Group hidden={affect ? affect.value!=='branches'  : !affect}>
                          <Select noOptionsMessage={() => t("no_data_available")}
                              options={props.rwState.branches}
                              getOptionLabel={o => o.name}
                              getOptionValue={o => o._id}
                              defaultValue={
                                  affect && affect.value==='branches' && current_row && current_row.attendees_target_id ? current_row .attendees_target_id.map(res=>{return res}) : ""
                              }
                              styles={{
                                  control: base => ({
                                      ...base,

                                      minHeight: 48,
                                      // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                  })
                              }}
                              theme={theme => ({
                                  ...theme,
                                  colors: {
                                      ...theme.colors,
                                      primary: '#6600CC',
                                      primary25: '#6600CC25',
                                      primary75: '#6600CC75',
                                      primary50: '#6600CC50'
                                  },
                              })}
                              placeholder={t("branch_title_unique")}
                              isMulti
                              onChange={handleSelectAssign}
                          />
                      </Form.Group>
                      <Form.Group hidden={affect ? affect.value!=='departments'  : !affect}>
                          <Select noOptionsMessage={() => t("no_data_available")}
                              options={props.rwState.departments}
                              getOptionLabel={o => o.name}
                              getOptionValue={o => o._id}
                              defaultValue={affect && affect.value==='departments' && current_row && current_row.attendees_target_id ? current_row.attendees_target_id.map(res=>{return res}) : ""}
                              styles={{
                                  control: base => ({
                                      ...base,

                                      minHeight: 48,
                                      // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                  })
                              }}
                              theme={theme => ({
                                  ...theme,
                                  colors: {
                                      ...theme.colors,
                                      primary: '#6600CC',
                                      primary25: '#6600CC25',
                                      primary75: '#6600CC75',
                                      primary50: '#6600CC50'
                                  },
                              })}
                              placeholder={t("department_title_unique")}
                              isMulti
                              onChange={handleSelectAssign}
                          />
                      </Form.Group>
                      <Form.Group hidden={affect ? affect.value!=='positions'  : !affect}>
                          <Select noOptionsMessage={() => t("no_data_available")}
                              options={props.rwState.positions}
                              getOptionLabel={o => o.title}
                              getOptionValue={o => o._id}
                              defaultValue={affect && affect.value!=='positions' && current_row && current_row.attendees_target_id ? current_row.attendees_target_id.map(res=>{return res}) : ""}
                              styles={{
                                  control: base => ({
                                      ...base,

                                      minHeight: 48,
                                      // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                  })
                              }}
                              theme={theme => ({
                                  ...theme,
                                  colors: {
                                      ...theme.colors,
                                      primary: '#6600CC',
                                      primary25: '#6600CC25',
                                      primary75: '#6600CC75',
                                      primary50: '#6600CC50'
                                  },
                              })}
                              placeholder={t("position_title_unique")}
                              isMulti
                              onChange={handleSelectAssign}
                          />
                      </Form.Group>
                      <Form.Group hidden={affect ? affect.value!=='employee' : !affect}>
                          <Select noOptionsMessage={() => t("no_data_available")}
                              options={props.rwState.colleagues}
                              getOptionLabel={o => o.lastname+''+o.firstname}
                              getOptionValue={o => o._id}
                              defaultValue={affect && affect.value==='employee' && current_row && current_row.attendees_target_id ? current_row.attendees_target_id.map(res=>{return res}) : ""}
                              styles={{
                                  control: base => ({
                                      ...base,
                                      minHeight: 48,
                                  })
                              }}
                              theme={theme => ({
                                  ...theme,
                                  colors: {
                                      ...theme.colors,
                                      primary: '#6600CC',
                                      primary25: '#6600CC25',
                                      primary75: '#6600CC75',
                                      primary50: '#6600CC50'
                                  },
                              })}
                              placeholder={t("employee_text")}
                              isMulti
                              onChange={handleSelectAssign}
                          />
                      </Form.Group>
                  </Col>
                  {type_event && type_event?.name === "formation" ?
                      <Col sm={"12"}>
                      <Form.Label>{t("assinged_some")} {type_event?.name + "(s)"} </Form.Label> <br/>
                      <Form.Group>
                      <Select noOptionsMessage={() => t("no_data_available")}
                      options={props.rwState.formations}
                      getOptionLabel={o => o.name}
                      getOptionValue={o => o._id}
                      defaultValue={current_row?.target_id}
                      styles={{
                      control: base => ({
                          ...base,

                          minHeight: 48,
                          // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                      })
                  }}
                      theme={theme => ({
                      ...theme,
                      colors: {
                          ...theme.colors,
                          primary: '#6600CC',
                          primary25: '#6600CC25',
                          primary75: '#6600CC75',
                          primary50: '#6600CC50'
                      },
                  })}
                      placeholder={t("formations_title")}
                      isMulti
                      onChange={handle_event_target}
                      />
                      </Form.Group>
                      </Col>
                      : ""
                  }
              </Row>
          </Modal.Body>
          <Modal.Footer className={'border-0 pt-0'}>
              <Button variant="light" onClick={handleClose}>
                  {t("close")}
              </Button>
              <Button variant="primary" onClick={createEvent}>
                  {isEventUpdate ? t("update") : t("save")}
              </Button>
              { isEventUpdate && current_row ?
                  <Button variant="danger" onClick={()=>confirmAction({target:'event',o:current_row})}>
                      {t("delete")}
                  </Button>
                  : ""
              }
          </Modal.Footer>
      </Modal>

        {/*details evenement*/}

      <Modal centered show={show === 'details_event'} onHide={handleClose} backdrop="static" size={"lg"}>
          <Modal.Header close className={"bg-primary"}>
              <h4 className={"col-lg-12 text-white text-center"}>{t("event_title")}</h4>
          </Modal.Header>
          <Modal.Body className={'p-4'}>
              <Row>
          <Col sm={12}>
              <Form.Group>
                  <Form.Label>{t("event_type_text")}</Form.Label>
                  <Form.Control
                      disabled={true}
                      defaultValue={current_row.event_type_info? current_row.event_type_info.name : ""}
                      type={"text"}/>
              </Form.Group>

              <Form.Group>
                      <Form.Label>{t("title")}</Form.Label>
                      <Form.Control
                          disabled={true}
                          defaultValue={current_row?.subject}
                          type={"text"}/>
              </Form.Group>
          </Col>

              <Col sm={12} md={6}>
             <Form.Group>
                      <Form.Label>{t("start_")}</Form.Label> <br/>
                 <Form.Control
                     disabled={true}
                     value={start}
                     type={"datetime-local"}/>
              </Form.Group>
              </Col>
              <Col sm={12} md={6}>
              <Form.Group>
                      <Form.Label>{t("end_")}</Form.Label> <br/>
                  <Form.Control
                      disabled={true}
                      min={start}
                      value={end}
                      type={"datetime-local"}/>
              </Form.Group>
              </Col>
              <Col sm={12}>
              <Form.Group>
                      <Form.Label>{t("description")}</Form.Label>
                      <Form.Control as="textarea" rows={3}
                                    disabled={true}
                                    defaultValue={current_row?.description}
                          type={"text"}/>
              </Form.Group>
               <Form.Group>
                      <Form.Label>{t("location")}</Form.Label>
                      <Form.Control as={"input"}
                                    disabled={true}
                                    defaultValue={current_row?.location}
                          type={"text"}/>
              </Form.Group>
                  { current_row.attendees && current_row.attendees.length >0 ?
                      <Col sm={12}>
                          <Form.Label>{t("participants")}</Form.Label> <br/>
                          {
                              current_row.attendees.map(p => {
                                  return <span
                                      className={"badge badge-light ml-3"}> {p.lastname + ' ' + p.firstname}
                                      ({p.position ? p.position.title : ""})</span>
                              })
                          }
                      </Col>
                      : ""
                  }
                  </Col>
              </Row>
          </Modal.Body>
          <Modal.Footer className={'border-0 pt-0'}>
              <Button variant="light" onClick={handleClose}>
                  {t("close")}
              </Button>
          </Modal.Footer>
      </Modal>

   <Modal centered show={show === 'create_type_event'} onHide={handleClose} backdrop="static" size={"md"}>
          <Modal.Header close className={"bg-primary"}>
              <h4 className={"col-lg-12 text-white text-center"}>{t("create_event_type")}</h4>
          </Modal.Header>
          <Modal.Body className={'p-4'}>
              <Row>
          <Col sm={12}>
              <Form.Group>
                      <Form.Label>{t("title")}</Form.Label>
                      <Form.Control
                          onChange={handleName}
                          defaultValue={name}
                          type={"text"}/>
              </Form.Group>
          </Col>
             <Col sm={12}>
              <Form.Group>
                      <Form.Label>{t("description")}</Form.Label>
                      <Form.Control as="textarea" rows={3}
                          onChange={handleDescription}
                          defaultValue={description}
                          type={"text"}/>
              </Form.Group>
              </Col>
               </Row>
          </Modal.Body>
          <Modal.Footer className={'border-0 pt-0'}>
              <Button variant="light" onClick={()=>{setShow('list_type_event')}}>
                  {t("close")}
              </Button>
           <Button variant="primary" onClick={createEventType}>
                  {t("save")}
              </Button>
          </Modal.Footer>
      </Modal>
        {/*liste event type*/}
 <Modal centered show={show === 'list_type_event'} onHide={handleClose} backdrop="static" size={"xl"}>
          <Modal.Body className={'p-4'}>
              <Row>
          <Col sm={12}>
              <div className={"col-12 text-right mb-4"}>
              <Button hidden={role.toLowerCase().includes('rh')} onClick={()=>{setShow('create_type_event')}}>{t("create_event_type")}</Button> &nbsp;&nbsp;&nbsp;
              </div>
              <h4 className={"col-lg-12 text-left"} >{t("list_event_type")}</h4>
              <div className="col-12">
              <DataTable
                  noHeader
                  columns={typeEventColumns}
                  data={props.rwState.event_types}
              />
              </div>

          </Col>

               </Row>
          </Modal.Body>
          <Modal.Footer className={'border-0 pt-0'}>
              <Button variant="light" onClick={handleClose}>
                  {t("close")}
              </Button>
          </Modal.Footer>
      </Modal>
        {/*delete confirmation */}
        <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("confirmation")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={"text-center"}>
                    {t("confirme_irreversile_deletion_text")}
                </div>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>
                        <Button variant="primary" onClick={confirmDeleteAction}>
                            {t("yes_delete")}
                        </Button>
                        <Button className={"ml-3"}  variant="light" onClick={() => setShow(close)}>
                            {t("cancel")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>

    </div>
}

export default connect(mapStateToProps, mapDispatchToProps)(OuttegrationCalendar);
