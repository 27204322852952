import React from 'react';
import './EventWidget.css'
import { Button} from "react-bootstrap";

const EventWidget = () => {
    return <div id={'EventWidget'}>
        <div className={'eventContent p-3 position-relative'}>
            <div className={'w-auto position-absolute shadow'} style={{top: -10, right: 30}}>
                <div className={'eventDuration p-2'}>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-clock" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm8-7A8 8 0 1 1 0 8a8 8 0 0 1 16 0z"/>
                        <path fillRule="evenodd"
                              d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
                    </svg>
                    <span> 30 min</span>
                </div>
            </div>
            <div className={'eventTime mt-4'}>00:00 - 00:00</div>
            <div className={'eventLabel mt-3 mb-4'}>
                Meeting avec l’equipe
            </div>
            <div className={'d-flex justify-content-between'}>
                <div className={'w-50 pr-2'}><Button variant={'purpleLight'} block>Plustard</Button></div>
                <div className={'w-50'}><Button variant={'purple'} block>Details</Button></div>
            </div>

        </div>

    </div>;
};

export default EventWidget;
