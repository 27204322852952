import React, {useEffect, useRef, useState} from 'react';
import "./FormationModuleDetails.css"
import { Modal, Button, Col, Container, Row, Card, Spinner, Badge, ListGroup, ProgressBar,Tooltip} from "react-bootstrap";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import * as RI from "react-icons/ri";
import * as BI from "react-icons/bi";
import {useParams,useHistory} from 'react-router-dom'
import {getFileUrl} from '../../../service'
import ReactPlayer from 'react-player/lazy'
import ReactAudioPlayer from 'react-audio-player';
import { Document , Page} from 'react-pdf/dist/esm/entry.webpack';
import Overlay from "react-bootstrap/Overlay";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const FormationModuleDetails = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    let user = JSON.parse(window.localStorage.getItem('glowhr_user')).user;
    let { id } = useParams();
    let params_id=id;
   useEffect(() => {
        props.action_getModuleFormation(params_id);
        setTimeout(function(){
            setIsReady(false)
        },5000)
       props.action_setTitle("Modules formation")
   }, [])
    const [show, setShow] = useState([])
    const [showModal, setShowModal] = useState([])
    const [isReady, setIsReady] = useState(true)
    const target = useRef(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const onDocumentLoadSuccess=({ numPages })=> {
            setNumPages(numPages);
        }
    const userHasFinishFormation= () => {
        let o = {
            "module_id": params_id,
            "sio_channel": "",
        };
        props.action_userHasFinishModuleFormation(o)
        props.action_loading(true)
        setShowModal(null)
    }
    const checkUserHasFinish=(module_formation)=>{
        let response =false;
        if(module_formation){
            if(module_formation.finisher_info && module_formation.finisher_info.length>0){
              let check= module_formation.finisher_info.find((p => p.user === user._id))
                if(check) response=true;
            }
        }
        return response;
    }
    return <div id={"FormationDetails"}>
        <Container fluid>
            <Row>
                <Col sm={"12"} className={'text-left'}>
                    <Button className={"btn btn-light btn-sm"} onClick={e => history.push({pathname:'/dash/courses/'+props.rwState.module_formation.formation_info._id})}>
                        <BI.BiChevronLeft style={{color: '#240265', fontWeight: '600',}} size={25}/> Retour
                    </Button>
                     { checkUserHasFinish(props.rwState.module_formation) ? <div className={"badge badge-success float-right"} style={{color: 'green!important'}}><BI.BiCheckCircle size={25}/> Terminé</div> : ""}

                </Col>
                <Col sm={12}>
                    <Row>
                    <div className={"col-12 mb-4"}>
                      <span onClick={e => history.push({pathname:'/dash/courses/'+props.rwState.module_formation.formation_info._id})} className={"btn btn-light btn-sm"}>
                          {t("formation_title_")} : {props.rwState.module_formation.formation_info? props.rwState.module_formation.formation_info.name : ''} </span> | <span className={"btn btn-light btn-sm disabled"}>{t("module_title_")} : {props.rwState.module_formation.title}</span>
                        <hr/>
                    </div>
                        <p className={"col-12 mb-4"}>
                            {props.rwState.module_formation.description}
                        </p>
                    {/*image*/}
                        {props.rwState.module_formation.type === 'image' ?
                        < div className={"col-12 text-center"} >
                            <img src={getFileUrl(props.rwState.module_formation.cours)} alt="" width={"100%"}/>
                            </div>
                            : ""
                        }
                   {/*video*/}
                        {props.rwState.module_formation.type==='video' ?
                    <div className={"col-12 text-center"}>
                    <ReactPlayer url={getFileUrl(props.rwState.module_formation.cours)} playing={true} controls={true} light={true} width={'100%'} />
                    </div>
                            : ""
                        }
                    {/*audio*/}
                        {props.rwState.module_formation.type==='audio' ?
                            <div className={"col-12 text-center"}>
                            <ReactAudioPlayer src={getFileUrl(props.rwState.module_formation.cours)} autoPlay controls/>
                            </div>
                            : ""
                        }
                    {/*document*/}
                        {props.rwState.module_formation.type === 'document' ?
                            <div className={"col-12 text-center"}>
                                <Document
                                    className={"tdocs"}
                                    loading={t("loading_init_text")}
                                    width={"100%"}
                                    file={getFileUrl(props.rwState.module_formation.cours)}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    <Page pageNumber={pageNumber}/>
                                </Document>
                                <p className={"mt-3"}>
                                    { pageNumber>1 ?
                                        <span className={"btn btn-light btn-sm mr-2"} onClick={() => {
                                            setPageNumber((pageNumber - 1))
                                        }}>{t("previous")}</span>
                                        : ""
                                    }
                                    Page {pageNumber} of {numPages}

                                    { pageNumber < numPages ?
                                        <span className={"btn btn-light btn-sm ml-2"} onClick={() => {
                                            setPageNumber((pageNumber + 1))
                                        }}>{t("next")}</span>
                                    : ""
                                    }
                                    </p>
                            </div>
                            :
                            ""
                        }


                        { !checkUserHasFinish(props.rwState.module_formation) ?
                            <div className={"col-12 mt-4 mb-5"}>
                                <Button className={"btn btn-primary btn-sm"} disabled={isReady} ref={target}
                                        onMouseOver={() => setShow('overlay')} onMouseLeave={() => setShow(null)}
                                        onClick={() => setShowModal('confirm')}>
                                    <BI.BiCheckCircle size={25}/> {t("notif_have_finish_courses")}
                                </Button>
                                <Overlay target={target.current} show={show === 'overlay'}>
                                    {({placement, arrowProps, show: _show, popper, ...props}) => (
                                        <div
                                            {...props}
                                            style={{
                                                backgroundColor: 'rgba(243,243,243,1)',
                                                padding: '2px 10px',
                                                color: '#393939',
                                                borderRadius: 3, ...props.style,
                                            }}>
                                            {t("certify_have_finish_courses")}
                                        </div>
                                    )}
                                </Overlay>
                            </div>
                            :
                            ""
                            }
                    </Row>
                </Col>

            </Row>
          </Container>
        {/*  modals */}
        {/*read Module details*/}
        <Modal show={showModal==="confirm"} onHide={() => setShowModal(null)} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("confirmation")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={"text-center"}>
                    {t("certify_have_finish_read_courses")}
                </div>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>
                        <Button variant="primary" onClick={userHasFinishFormation}>
                            {t("yes_finished")}
                        </Button>
                        <Button className={"ml-3"}  variant="light" onClick={() => setShowModal(null)}>
                            {t("cancel")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(FormationModuleDetails);
