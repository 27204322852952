import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import './Communications.css'
import * as actions from "../../../actions";
import {Button, Col, Dropdown, Form, Modal, Row, Tab} from "react-bootstrap";
import ContextMenu from "../../menus/ContextMenu";
import SingleFormation from "../../widgets/SingleFormation/SingleFormation";
import * as RI from "react-icons/ri";
import DataTable from "react-data-table-component";
import {BsPlus, BsThreeDotsVertical} from "react-icons/bs";
import Select from "react-select";
import countries from "../../../assets/countries";
import {useHistory} from 'react-router-dom'
import {uploadOnCdn,getFileUrl} from '../../../service'
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import {getFormatedDate, isAdmin, todayDateFormatForInput,
    limitText,handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}
const Communications = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    let user = JSON.parse(window.localStorage.getItem('glowhr_user')).user;
    useEffect(() => {
        props.action_loading(true);
        props.action_listNews();
        props.action_listNewsletters();
        props.action_listColleagues();
        props.action_setTitle(t("communication_text"))
    }, []);
    const [show, setShow] = useState([]);
    const [filename, setFilename] = useState([]);
    const [title, setTitle] = useState()
    const [subject, setSubject] = useState()
    const [resume, setResume] = useState()
    const [sujet, setSujet] = useState()
    const [content, setContent] = useState("<p>"+t("news_content_text")+"</p>")
    const [newsletter_content, setNewsletterContent] = useState("<p>"+t("news_content_text")+"</p>")
    const [isUpdate, setIsUpdate] = useState(false)
    const [news_status, setNewsStatus] = useState(0)
    const [newsletter_status, setNewsletterStatus] = useState(false)
    const [current_news_row, setCurrentNewsRow] = useState([])
    const [current_newsletter_row, setCurrentNewsLetterRow] = useState([])
    const [restrein, setRestrein] = useState('image/*')
    const [imageName, setImageName] = useState(t("no_file_choose"))
    const [publication_date, setPublicationDate] = useState(null)
    const editorRef = useRef(null);
    const affectations_type =
        [{ label: t('all'), value: 'all' },
            { label: t("departments"), value: "departments"},
            { label: t('branches'), value: 'branches'},
            { label: t('positions'), value: 'positions'},
            { label: t('specific_employee'), value: 'employee'}
        ]
    const [affect, setAffect] = useState(affectations_type[0])
    const [assignTo, setAssignTo] = useState([])
    const [checkAssign, setCheckAssign] = useState(false)
    const [attendees_target_id, setAttendeesTargetId] = useState([])
    const [attendees_target, setAttendeesTarget] = useState("all")
    const handleAffectation_type = (e) => {
        setAssignTo([]);
        setAffect(e)
        setAttendeesTarget(e.value)
    };
    const [formError, setFormError] = useState({})
    const handleCheckAssign = () => {
        checkAssign ? setCheckAssign(false) : setCheckAssign(true);
    };
    const handleSelectAssign = (e) => {
        if(e) {
            setAssignTo(e)
            setAttendeesTargetId(e.filter(res => {
                return res._id
            }))
        }else{
            setAttendeesTargetId([])
        }
    };
    const contextMenu =
        [
            {label: t('news'), eventKey: 'news'},
            {label: t('newsletters'), eventKey: 'newsletters'},
        ];

    const handleClose = () => {
        setShow(null)
        setFormError({})
    };
   const handlePublicationDate = (e) => {
        setPublicationDate(e.target.value)
        let data =handleValidation(t,{type:"date",name:'publicationDate','value':e.target.value,required:true,'min':new Date()});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handleTitle = (e) => {
        setTitle(e.target.value)
        let data =handleValidation(t,{type:"text",name:'title','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
     const handleSubject = (e) => {
        setSubject(e.target.value)
        let data =handleValidation(t,{type:"text",name:'subject','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handleResume = (e) => {
        setResume(e.target.value)
        let data =handleValidation(t,{type:"textarea",name:'resume','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handleNewsStatus = (e) => {
        setNewsStatus(e.target.checked)
    };
   const handleNewsletterStatus = (e) => {
       console.log(e.target.checked,'*')
        setNewsletterStatus(e.target.checked)
    };
    const handleEditorChange = (value, editor) => {
        setContent(value)
        let data =handleValidation(t,{type:"textarea",name:'editorChange','value':value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handleNewsletterEditorChange = (value, editor) => {
        setNewsletterContent(value)
        let data =handleValidation(t,{type:"textarea",name:'newsletterEditorChange','value':value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handleFile = (e) => {
        if(e) {
            let file_data = e.target.files[0]
            setFilename(file_data)
            setImageName(file_data.name)
            let data =handleValidation(t,{type:"text",name:'file','value':file_data.name,required:true});
            let form=formError;
                    form[data.name]=data   
                    setFormError(form)
        }
    };
    const deleteNews= async  (e) => {
        setCurrentNewsRow(e)
        setShow('confirmNewsDelete');
    }
    const deleteNewsletters= async  (e) => {
        setCurrentNewsLetterRow(e)
        setShow('confirmNewsletterDelete');
    }
    const confirmDeleteAction= async  () => {
        props.action_deleteNews(current_news_row)
        setShow(null);
    }
     const confirmDeleteNewsletterAction= async  () => {
        props.action_deleteNewsletters(current_newsletter_row)
        setShow(null);
    }
    const changeStatus= async  () => {
        let status=1;
        current_news_row.status===0 ? status=1 : status=0
        setFilename(undefined)
        let o = {
            "status": status,
            "sio_channel": "",
            "id": current_news_row?._id
        };
        props.action_updateNews(o);
        setShow(null)
        props.action_loading(true)
        setCurrentNewsRow([])
    }
 const showNewsDetails= async  (e) => {
        setCurrentNewsRow(e)
        setShow('detailActualite')
    }
  const showNewslettersDetails= async  (e) => {
      setCurrentNewsLetterRow(e)
        setShow('detailNewsLetter')
    }
     const acceptChangeStatus= async  (e) => {
        setCurrentNewsRow(e)
        setShow('confirmUpdateSatus')
    }
       const createNews= async  () => {
        let  file=filename,status=0;
        setFilename(undefined);
           news_status ? status=1 : status=0;
        let o = {
             "title": title,
            "resume": resume,
            "content": content,
            "sio_channel": "",
            "id": current_news_row?._id
        };
        if(!isUpdate) o.status=status;
        if(file && file!==[]){
            uploadOnCdn(file)
                .then(resp => {
                    setFilename(resp.filename);
                    o.filename=resp.filename;
                    if (!isUpdate)
                        props.action_createNews(o)
                    else
                        props.action_updateNews(o);
                })
        }else{
            if (!isUpdate)
                props.action_createNews(o)
            else
                props.action_updateNews(o);
        }
        setShow(null)
        props.action_loading(true)
        setIsUpdate(false)
           await resetNews();
           setFormError({})
    }
    const updateNews= async (e) =>{
        setCurrentNewsRow(e)
       e.status===1 ? setNewsStatus(true) : setNewsStatus(false);
        setResume(e.resume)
        setTitle(e.title)
        setImageName(e.filename)
        setContent(e.content)
        setShow('news')
        setIsUpdate(true)
        let data=  handleFinaleValidation(t,[
            {type:"text",name:'resume','value':e.resume,required:true},
            {type:"text",name:'title','value':e.title,required:true},
            {type:"text",name:'file','value':e.filename,required:true},
            {type:"text",name:'editorChange','value':e.content,required:true}
        ])
                setFormError(data)
    }
    const resetNews= async ()=>{
        setCurrentNewsRow([]);
        setIsUpdate(false);
        setResume('');
        setTitle('');
        setContent('');
        setImageName('');
        setFormError({})
    }
    const resetNewsletters= async ()=>{
        setCurrentNewsLetterRow([]);
        setIsUpdate(false);
        setSubject('');
        setNewsletterContent('');
        setAffect(affectations_type[0])
        setFormError({})
    }
    const createNewsLetter = async () => {
        let o = {
            "subject": subject,
            "content": content,
            "sio_channel": "",
            "id":current_newsletter_row?.id,
            "attendees_target": attendees_target,
            "attendees_target_id": attendees_target_id,
            "start_date": publication_date,
            "status": newsletter_status ? 'en attente' : 'publier',
        };
        if (!isUpdate)
            props.action_createNewsletters(o)
        else
            props.action_updateNewsletters(o);
        setShow(null)
        props.action_loading(true)
      await  resetNewsletters()
    };
    const newsColumns = [
        {
            name: t('status'),
            selector: 'status',
            cell: row =>
                <div>
                    { row.status===1
                        ?<span className={"badge badge-success"}>{"active"}</span>
                        :<span className={"badge badge-danger"}>{"inactif"}</span>
                    }
                </div>,

        },
        {
            name: t('publish_date'),
            selector: 'created_by',
            cell: row => <div>{row.created_date ? getFormatedDate(row.created_date) : ''}</div>,
        },

        {
            name: t('images'),
            selector: 'filename',
            cell: row =>
                <div>
                    <div style={{width:'100%'}}>
                   { row.filename ?  <img src={getFileUrl(row.filename)} alt="" width={"70px"}/> : ''}
                    </div>
                </div>,

        },{
            name: t('title'),
            selector: 'title',
            cell: row => <div><div style={{width:'100%'}}>{row.title}</div></div>,
        },
        {
            name: t('resume'),
            selector: 'resume',
            cell: row => <div><div style={{width:'100%'}}>{row.resume}</div></div>,
        },
        // {
        //     name: 'content',
        //     selector: 'content',
        //     cell: row => <div><div style={{width:'100%'}}>{row.content}</div></div>,
        // },
        {
            cell: row => <div>
                <Dropdown className={"float-right"}>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <BsThreeDotsVertical/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="1" onClick={e =>acceptChangeStatus(row)}>
                            {t("change_visibility")}
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2" onClick={() => showNewsDetails(row)}>
                            {t("details")}
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2" onClick={() => updateNews(row)}>
                            {t("update")}
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-4" onClick={() => deleteNews(row)}>
                            {t('delete')}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            ,
            grow:0
        }
    ];

    const newsLetterColumns = [
        {
            name: t("status"),
            selector: 'status',
            cell: row =>
                <div>
                    { row.status==='en attente'
                        ?<span className={"badge badge-warning"}>{row.status}</span>
                        :<span className={"badge badge-info"}>{row.status}</span>
                    }
                </div>,

        },
       {
            name: t("publish_date"),
            selector: 'start_date',
            cell: row => <div>{row.start_date ? getFormatedDate(row.start_date) : ''}</div>,
        },
       {
            name: t('subject'),
            selector: 'subject',
            cell: row => <div>{row.subject}</div>,
        },
        {
            name: t('sent_to'),
            selector: 'attendees_target',
            cell: row => <div>{row.attendees_target}</div>,
        },
        {
            name: t('sent_to'),
            selector: 'attendees_target',
            cell: row =>
                <div>
                    {row.attendees_target==='branches'
                        ? props.rwState.branches.map(r=>{
                            if(row.attendees_target_id.find(rat=>rat._id===r._id)) {
                                return <span className={"badge badge-light"}>{r.name} </span>
                            }
                        })
                        : (row.attendees_target==='departments' ?
                            props.rwState.departments.map(r=>{
                                if(row.attendees_target_id.find(rat=>rat._id===r._id)) {
                                    return <span className={"badge badge-light"}>{r.name} </span>
                                }
                            })
                                : (row.attendees_target==='positions' ?
                                    props.rwState.positions.map(r=>{
                                        if(row.attendees_target_id.find(rat=>rat._id===r._id)) {
                                            return <span className={"badge badge-light"}>{r.title} </span>
                                        }
                                    })
                                    : (row.attendees_target==='employee' ?
                                            props.rwState.colleagues.map(r=>{
                                                if(row.attendees_target_id.find(rat=>rat._id===r._id)) {
                                                    return <span className={"badge badge-light"}>{r.firstname+' '+r.firstname} </span>
                                                }
                                            })
                                            :(row.attendees_target==='all' ?
                                                            <span className={"badge badge-light"}> ... </span>
                                                        :''
                                                )
                                        )
                                )
                        )
                    }
                </div>,
        },
        {
            cell: row => <div>
                <Dropdown className={"float-right"}>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <BsThreeDotsVertical/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-2" onClick={() => showNewslettersDetails(row)}>
                            {t("content_text")}
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-4" onClick={() => deleteNewsletters(row)}>
                            {t("delete")}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            ,
            grow:0
        }
    ];

    //let url =props.zulip_url //"https://zulip-dev.glow-hr.com/";
  return isAdmin(role) ? <div id={"Communications"}>
      {/*{*/}
      {/*    props.rwState.zulip_url ?*/}
      {/*        <iframe src={props.rwState.zulip_url} frameBorder="0" style={{width:"100%",height: "calc( 100vh - 128px )"}}>zulip</iframe>*/}

      {/*        :*/}
      {/*        <div className={"text-center"}>{t("loading_init_text")} ...</div>*/}
      {/*}*/}

      <Col>
          <Tab.Container id="left-tabs-example" defaultActiveKey={'news'}>
              <div className="d-flex align-items-start justify-content-between">
                  <ContextMenu menu={contextMenu}/>
              </div>
              <Tab.Content>
                  <Tab.Pane className={"lastRollL"} eventKey="news">
                          <div className={"row"}>
                              <h6 className={"col-lg-12 text-uppercase mb-3"}>{t("news_management")}</h6>
                              <div className={"col-lg-12"}>
                                <Button size={"sm"} onClick={e => setShow('news')} className={"mb-3"}>
                                    <BsPlus/> {t("new_news")}
                                </Button>
                                <DataTable
                                        noHeader
                                        fixedHeader
                                        fixedHeaderScrollHeight="600px"
                                        pagination
                                      columns={newsColumns}
                                      data={props.rwState.news ? props.rwState.news.filter(res=>res.created_by && res.created_by._id===user._id) : []}
                                      noDataComponent={t("no_news_added")}
                                      paginationComponentOptions={
                                        {
                                            rowsPerPageText: t('line_per_page'),
                                            rangeSeparatorText: t('for'),
                                             noRowsPerPage: false,
                                              selectAllRowsItem: false,
                                               selectAllRowsItemText: t('all')}  
                                       }
                                 />
                              </div>
                          </div>
                  </Tab.Pane>
                  <Tab.Pane className={"lastRollL"} eventKey="newsletters">
                          <div className={"row"}>
                              <div className={"tableLabel col-12"}>
                                  <h6>{t("newsletters_management")}</h6>
                              </div>
                              <div className={"col-lg-12"}>

                                  <Button size={"sm"} onClick={() => setShow('newsletters')} className={"mb-3"}>
                                      <BsPlus/> {t("programms_newsletters_management")}
                                  </Button>

                                  <DataTable
                                        noHeader
                                        fixedHeader
                                        fixedHeaderScrollHeight="600px"
                                        pagination
                                      columns={newsLetterColumns}
                                      data={props.rwState.newsletters ? props.rwState.newsletters.filter(res=>res.created_by && res.created_by._id===user._id) : []}
                                      noDataComponent={t('no_newsletters_added')}
                                      paginationComponentOptions={
                                        {
                                            rowsPerPageText: t('line_per_page'),
                                            rangeSeparatorText: t('for'),
                                             noRowsPerPage: false,
                                              selectAllRowsItem: false,
                                               selectAllRowsItemText: t('all')} 
                                       }
                                 />
                              </div>
                          </div>

                  </Tab.Pane>

              </Tab.Content>
          </Tab.Container>
      </Col>
      <Modal centered show={show === 'news'} onHide={handleClose} size={"lg"}>
          <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
            {t("add_news_text")}
          </Modal.Header>
          <Modal.Body className={'p-4'}>
              <Form.Group>
                  <Form.Label>{t("add_image_file")} <span className={'required_box'}>*</span> </Form.Label>
                  <Form.File id="formcheck-api-custom" custom>
                          <Form.File.Input onChange={handleFile} accept={restrein} 
                          className={(formError && formError.file && formError.file.errors && !formError.file.formIsValid) ? 'inputValidationError' : ''}
                          />
                          <Form.File.Label data-browse={t("choose_a_file")}>
                              {imageName}
                          </Form.File.Label>
                          <Form.Control.Feedback type="valid">5MB</Form.Control.Feedback>
                      </Form.File>
                                   
                                       {(formError && formError.file && formError.file.errors && !formError.file.formIsValid) ? <span className={"validationError"}>{formError.file.errors.file}</span> : ''}
                       
              </Form.Group>
              <Form.Group>
                  <Form.Label>{t("title")} <span className={'required_box'}>*</span> </Form.Label>
                  <Form.Control
                      size={"lg"}
                      placeholder={t("present_news_title")}
                      defaultValue={title}
                      onChange={e=>{handleTitle(e)}}
                      className={(formError && formError.title && formError.title.errors && !formError.title.formIsValid) ? 'inputValidationError' : ''}
                      />
                             {(formError && formError.title && formError.title.errors && !formError.title.formIsValid) ? <span className={"validationError"}>{formError.title.errors.title}</span> : ''}
              </Form.Group>
               <Form.Group>
                  <Form.Label>{t("resume_text")} <span className={'required_box'}>*</span> </Form.Label>
                  <Form.Control
                      size={"lg"}
                      placeholder={t("tiny_news_resume_text")}
                      defaultValue={resume}
                      onChange={e=>{handleResume(e)}}
                      className={handleinputValidationError(formError,'resume')}
                      />
                             {handleTextValidationError(formError,'resume')}
              </Form.Group>
             <Form.Group>
                  <Form.Label>{t("content_text")} <span className={'required_box'}>*</span> </Form.Label>
                 <Editor
                     onInit={(evt, editor) => editorRef.current = editor}
                     value={content}
                     onEditorChange={handleEditorChange}
                     init={{
                         height: 300,
                         menubar: false,
                         plugins: [
                             'advlist autolink lists link image charmap print preview anchor',
                             'searchreplace visualblocks code fullscreen',
                             'insertdatetime media table paste code help wordcount'
                         ],
                         toolbar: 'undo redo | formatselect | ' +
                             'bold italic backcolor | alignleft aligncenter ' +
                             'alignright alignjustify | bullist numlist outdent indent | ' +
                             'removeformat | help',
                         content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                     }}
                     className={handleinputValidationError(formError,'editorChange')}
                     />
                            {handleTextValidationError(formError,'editorChange')}
              </Form.Group>
              <Form.Group>
                  <label>{t("publish_notification")}</label>
                  <Form.Check
                      type="checkbox"
                      label={t("publish_now")}
                      defaultChecked={news_status}
                      value={news_status}
                      onChange={handleNewsStatus}
                  />
              </Form.Group>
              <Row>

              </Row>
          </Modal.Body>
          <Modal.Footer className={'border-0 pt-0'}>
              <Button variant="light" onClick={handleClose}>
                  {t("cancel")}
              </Button>
              <Button variant="primary" disabled={
                  handleDisabledBtnValidationError(formError,
                  ['editorChange','resume','file','title'])} onClick={createNews}>
                  {t("save")}
              </Button>
          </Modal.Footer>
      </Modal>

  <Modal centered show={show === 'newsletters'} onHide={handleClose} size={"lg"}>
          <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
            {t("programm_newletter")}
          </Modal.Header>
          <Modal.Body className={'p-4'}>
              <Form.Group>
                  <Form.Label>{t("subject")} <span className={'required_box'}>*</span> </Form.Label>
                  <Form.Control
                      size={"lg"}
                      placeholder={t('present_newsletters_title')}
                      defaultValue={subject}
                      onChange={e=>{handleSubject(e)}}
                      className={handleinputValidationError(formError,'subject')}
                      />
                             {handleTextValidationError(formError,'subject')}
            
              </Form.Group>
              <Form.Group>
                  <Form.Check
                      type="checkbox"
                      label={t("programm_newletter")}
                      defaultChecked={newsletter_status}
                      value={newsletter_status}
                      onChange={handleNewsletterStatus}
                  />
              </Form.Group>
              {newsletter_status ?
                  <Form.Group>
                      <Form.Label>{t("publish_date")} </Form.Label>
                      <Form.Control
                          type={'datetime-local'}
                          size={"lg"}
                          min={todayDateFormatForInput()}
                          defaultValue={publication_date}
                          onChange={e => {
                              handlePublicationDate(e)
                          }}
                          className={handleinputValidationError(formError,'publicationDate')}
                          />
                                 {handleTextValidationError(formError,'publicationDate')}
                
                  </Form.Group>
                  : ''
              }
             <Form.Group>
                  <Form.Label>{t("content")} <span className={'required_box'}>*</span> </Form.Label>
                 <Editor
                     onInit={(evt, editor) => editorRef.current = editor}
                     value={newsletter_content}
                     onEditorChange={handleNewsletterEditorChange}
                     init={{
                         height: 300,
                         menubar: false,
                         plugins: [
                             'advlist autolink lists link image charmap print preview anchor',
                             'searchreplace visualblocks code fullscreen',
                             'insertdatetime media table paste code help wordcount'
                         ],
                         toolbar: 'undo redo | formatselect | ' +
                             'bold italic backcolor | alignleft aligncenter ' +
                             'alignright alignjustify | bullist numlist outdent indent | ' +
                             'removeformat | help',
                         content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                     }}
                     className={handleinputValidationError(formError,'newsletterEditorChange')}
                     />
                            {handleTextValidationError(formError,'newsletterEditorChange')}
           
              </Form.Group>
              <Form.Group>
                  <Form.Label>{t("sent_to")}</Form.Label> <br/>
                  <Form.Group>
                      <Select noOptionsMessage={() => t("no_data_available")}
                          options={affectations_type}
                          defaultValue={affect}
                          styles={{
                              control: base => ({
                                  ...base,
                              })
                          }}
                          theme={theme => ({
                              ...theme,
                              colors: {
                                  ...theme.colors,
                                  primary: '#6600CC',
                                  primary25: '#6600CC25',
                                  primary75: '#6600CC75',
                                  primary50: '#6600CC50'
                              },
                          })}
                          placeholder={t("add_to")}
                          name='affect_to'
                          onChange={handleAffectation_type}
                      />
                  </Form.Group>
                  <Form.Group hidden={affect ? affect.value!=='branches'  : !affect}>
                      <Select noOptionsMessage={() => t("no_data_available")}
                          options={props.rwState.branches}
                          getOptionLabel={o => o.name}
                          getOptionValue={o => o._id}
                          defaultValue={affect && affect.value==='branches' && current_newsletter_row && current_newsletter_row.attendees_target_id ? current_newsletter_row .attendees_target_id.map(res=>{return res}) : ""}
                          styles={{
                              control: base => ({
                                  ...base,
                                  minHeight: 48,
                                  // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                              })
                          }}
                          theme={theme => ({
                              ...theme,
                              colors: {
                                  ...theme.colors,
                                  primary: '#6600CC',
                                  primary25: '#6600CC25',
                                  primary75: '#6600CC75',
                                  primary50: '#6600CC50'
                              },
                          })}
                          placeholder={t("branch_title_unique")}
                          isMulti
                          onChange={handleSelectAssign}
                      />
                  </Form.Group>
                  <Form.Group hidden={affect ? affect.value!=='departments'  : !affect}>
                      <Select noOptionsMessage={() => t("no_data_available")}
                          options={props.rwState.departments}
                          getOptionLabel={o => o.name}
                          getOptionValue={o => o._id}
                          defaultValue={affect && affect.value==='departments' && current_newsletter_row && current_newsletter_row.attendees_target_id ? current_newsletter_row.attendees_target_id.map(res=>{return res}) : ""}
                          styles={{
                              control: base => ({
                                  ...base,

                                  minHeight: 48,
                                  // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                              })
                          }}
                          theme={theme => ({
                              ...theme,
                              colors: {
                                  ...theme.colors,
                                  primary: '#6600CC',
                                  primary25: '#6600CC25',
                                  primary75: '#6600CC75',
                                  primary50: '#6600CC50'
                              },
                          })}
                          placeholder={t("department_title_unique")}
                          isMulti
                          onChange={handleSelectAssign}
                      />
                  </Form.Group>
                  <Form.Group hidden={affect ? affect.value!=='positions'  : !affect}>
                      <Select noOptionsMessage={() => t("no_data_available")}
                          options={props.rwState.positions}
                          getOptionLabel={o => o.title}
                          getOptionValue={o => o._id}
                          defaultValue={affect && affect.value!=='positions' && current_newsletter_row && current_newsletter_row.attendees_target_id ? current_newsletter_row.attendees_target_id.map(res=>{return res}) : ""}
                          styles={{
                              control: base => ({
                                  ...base,

                                  minHeight: 48,
                                  // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                              })
                          }}
                          theme={theme => ({
                              ...theme,
                              colors: {
                                  ...theme.colors,
                                  primary: '#6600CC',
                                  primary25: '#6600CC25',
                                  primary75: '#6600CC75',
                                  primary50: '#6600CC50'
                              },
                          })}
                          placeholder={t("position_title_unique")}
                          isMulti
                          onChange={handleSelectAssign}
                      />
                  </Form.Group>
                  <Form.Group hidden={affect ? affect.value!=='employee' : !affect}>
                      <Select noOptionsMessage={() => t("no_data_available")}
                          options={props.rwState.colleagues}
                          getOptionLabel={o => o.lastname+''+o.firstname}
                          getOptionValue={o => o._id}
                          defaultValue={affect && affect.value==='employee' && current_newsletter_row && current_newsletter_row.attendees_target_id ? current_newsletter_row.attendees_target_id.map(res=>{return res}) : ""}
                          styles={{
                              control: base => ({
                                  ...base,
                                  minHeight: 48,
                              })
                          }}
                          theme={theme => ({
                              ...theme,
                              colors: {
                                  ...theme.colors,
                                  primary: '#6600CC',
                                  primary25: '#6600CC25',
                                  primary75: '#6600CC75',
                                  primary50: '#6600CC50'
                              },
                          })}
                          placeholder={t("employee_text")}
                          isMulti
                          onChange={handleSelectAssign}
                      />
                  </Form.Group>
              </Form.Group>
              <Form.Group>
                  <Form.Label>
                      {t("notif_save_newletter")}
                  </Form.Label>
              </Form.Group>
          </Modal.Body>
          <Modal.Footer className={'border-0 pt-0'}>
              <Button variant="light" onClick={handleClose}>
                  {t("cancel")}
              </Button>
              <Button variant="primary" 
              disabled={
                newsletter_status 
                ?
                handleDisabledBtnValidationError(formError,
                    ['newsletterEditorChange','subject','publicationDate'])  
                : handleDisabledBtnValidationError(formError,
                ['newsletterEditorChange','subject'])}
              onClick={createNewsLetter}>
                  {t("save")}
              </Button>
          </Modal.Footer>
      </Modal>

      <Modal show={show==="confirmNewsDelete"} onHide={() => setShow(null)} centered size={"md"}>
          <Modal.Header closeButton className={'border-0'}>
              <Modal.Title>{t("confirmation")}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
              <div className={"text-center"}>
                  {t("confirme_irreversile_deletion_text")}
              </div>
          </Modal.Body>
          <Modal.Footer className={'text-center border-0'}>
              <div>
                  <div className={"text-center"}>
                      <Button variant="warning" onClick={confirmDeleteAction}>
                          {t("yes_delete")}
                      </Button>
                      <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                          {t("cancel")}
                      </Button>
                  </div>
              </div>

          </Modal.Footer>
      </Modal>

 <Modal show={show==="confirmUpdateSatus"} onHide={() => setShow(null)} centered size={"md"}>
          <Modal.Header closeButton className={'border-0'}>
              <Modal.Title>{t("confirmation")}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
              <div className={"text-center"}>
                  {t("change_news_status")}
              </div>
          </Modal.Body>
          <Modal.Footer className={'text-center border-0'}>
              <div>
                  <div className={"text-center"}>
                      <Button variant="primary" onClick={changeStatus}>
                          {t("yes")}
                      </Button>
                      <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                          {t("cancel")}
                      </Button>
                  </div>
              </div>

          </Modal.Footer>
      </Modal>

      {current_news_row && current_news_row!==[]
          ?
        <Modal show={show==="detailActualite"} onHide={() => setShow(null)} centered size={"md"}>
          <Modal.Header closeButton className={'border-0'}>
              <Modal.Title>{t("news_details")} {current_news_row.title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
              <div className={"row"}>
                  <div className="col-12">
                      <img src={getFileUrl(current_news_row.filename)} alt="" width={"70px"}/>
                  </div>
                  <div className="col-12">
                      <h3>{current_news_row.title}</h3>
                      <p>{current_news_row.resume}</p>
                  </div>
                  <div className="col-12">
                      <label><strong> {t("content")} </strong></label>
                      <p>{current_news_row.content ? parse(current_news_row.content) : ''}</p>
                  </div>
              </div>
          </Modal.Body>
          <Modal.Footer className={'text-center border-0'}>
              <div>
                  <div className={"text-center"}>
                      <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                          {t("cancel")}
                      </Button>
                  </div>
              </div>

          </Modal.Footer>
      </Modal>
          : ''}

   {current_newsletter_row && current_newsletter_row!==[]
       ?
        <Modal show={show==="detailNewsLetter"} onHide={() => setShow(null)} centered size={"md"}>
          <Modal.Header closeButton className={'border-0'}>
              <Modal.Title>{t("newsletter_details")} {current_newsletter_row.subject}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
              <div className={"row"}>
                  <div className="col-12">
                      <label>{t("subject")}</label>
                      <h3>{current_newsletter_row.subject}</h3>
                  </div>
                  <div className="col-12 mt-3">
                      <label><strong>{t("content")}</strong></label>
                      <p>{current_newsletter_row.content ? parse(current_newsletter_row.content) : ''}</p>
                  </div>
              </div>
          </Modal.Body>
          <Modal.Footer className={'text-center border-0'}>
              <div>
                  <div className={"text-center"}>
                      <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                          {t("cancel")}
                      </Button>
                  </div>
              </div>

          </Modal.Footer>
      </Modal>
       : ''}

      <Modal show={show==="confirmNewsletterDelete"} onHide={() => setShow(null)} centered size={"md"}>
          <Modal.Header closeButton className={'border-0'}>
              <Modal.Title>{t("confirmation")}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
              <div className={"text-center"}>
                 {t("confirme_irreversile_deletion_text")}
              </div>
          </Modal.Body>
          <Modal.Footer className={'text-center border-0'}>
              <div>
                  <div className={"text-center"}>
                      <Button variant="warning" onClick={confirmDeleteNewsletterAction}>
                          {t("yes_delete")}
                      </Button>
                      <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                          {t("cancel")}
                      </Button>
                  </div>
              </div>

          </Modal.Footer>
      </Modal>

  </div>
      :
      <div id={"Communications"} className={"col-12"}>
          <div className="row">
              {props.rwState.news && props.rwState.news.length>0 ?
                  <div className="col-12 mb-4 mt-2">
                      <h2 className={"text-uppercase"}>{t("list_news_availables")}</h2>
                  </div> : ''
              }
              {props.rwState.news && props.rwState.news.length>0 ?
                  props.rwState.news.map(p=>{
                  return   <div className="col-4">
                      <div className="card" style={{border:'none',borderRadius:'10px'}}>
                          <div className="card-body">
                              <img src={getFileUrl(p.filename)} alt="" style={{width: '100%'}}/>
                              <p className={"mt-2 mb-3"}>{limitText(p.resume,150)}</p>
                              <div>
                                  <button className="btn btn-light btn-sm" onClick={()=> history.push({pathname:'/dash/communications/'+p._id})}><BsPlus/> {t("see_more")}</button>
                              </div>
                          </div>
                      </div>
                  </div>
                  })
              : <div className="col-12 text-center mt-5 pt-5">
                      {t("no_news_availables")}
                  </div>
              }
          </div>
      </div>

};
export default connect(mapStateToProps, mapDispatchToProps)(Communications);
