import React, {useEffect, useState} from 'react';
import './AccountStatus.css'
import { useTranslation } from 'react-i18next';
const AccountStatus = () => {
  const { t } = useTranslation();
  const [company,setCompany]=useState();
  useEffect(()=>{
  let data = JSON.parse(window.localStorage.getItem('glowhr_user')).user.company;
    setCompany(data)
  },[window.localStorage.getItem('glowhr_user')])
  return <div id={'AccountStatus'}>
      <div className={company?.account ? (company?.account==='live' ? "badge-success pl-3 pr-3 pt-1 pb-1" : "badge-warning pl-3 pr-3 pt-2 pb-2") : ""} style={{borderRadius: '50px',fontSize:'10pt',cursor:'pointer',fontWeight:'bold'}}>{t("account")} {company?.account}</div>
  </div>;
};

export default AccountStatus;
