import React, {useEffect, useState,useCallback} from 'react';
import "./MyCalendar.css";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from "moment";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import {Button, Card, Col, Container, Form, Modal, Row, Tab} from "react-bootstrap";
import 'react-day-picker/lib/style.css';
import * as RI from "react-icons/ri";
import * as BI from "react-icons/bi";
import {useHistory} from 'react-router-dom'
import {isAdmin, generateVisioUrl, handleValidation} from "../../../helpers/helper";
import Select from "react-select";
import DataTable from "react-data-table-component";
import { useTranslation } from 'react-i18next';

moment.locale('fr')
moment().format("ll")
const localizer = momentLocalizer(moment)

const mapStateToProps = (state) => {
  return {rwState: state}
}
const mapDispatchToProps = {...actions}

const MyCalendar = (props) => {
    const { t } = useTranslation();
    const messages = {
        date: t("date"),
        time: t("hour"),
        event: t("event_title"),
        allDay: t("all_days"),
        week: t("weekend"),
        work_week: t("work_weekend"),
        day: t("day"),
        month: t("month"),
        previous: t("previous"),
        next: t("next"),
        yesterday: t("yesterday"),
        tomorrow: t("tomorrow"),
        today: t("today"),
        agenda: t("agenda"),
      
        noEventsInRange: t("no_event_found"),
      
        showMore: total => `+${total} `+t("more"),
      }
    const [events, setEvents] = useState([])
    const [show, setShow] = useState([])
    const [close, setClose] = useState([])
    const [type_event, setEventType] = useState()
    const [location, setLocation] = useState()
    const [attendees_target_id, setAttendeesTargetId] = useState([])
    const [attendees_target, setAttendeesTarget] = useState("all")
   const [attendees_target_idFilter, setAttendeesTargetIdFilter] = useState([])
    const [attendees_targetFilter, setAttendeesTargetFilter] = useState("all")
    const [name, setName] = useState()
    const [longitude, setLongitude] = useState()
    const [latitude, setLatitude] = useState()
    const [description, setDescription] = useState()
    const [event_description, setEventDescription] = useState()
    const [isEventTypeUpdate, setIsEventTypeUpdate] = useState(false)
    const [isEventUpdate, setIsEventUpdate] = useState(false)
    const [filtrer, setFilter] = useState(false)
    const [actionData, setActionData] = useState({})
    const [title, setTitle] = useState()
    const [start, setStart] = useState()
    const [event_target, setEventTarget] = useState([])
    const [end, setEnd] = useState()
    const [current_row, setCurrent_row] = useState({})
    const [current_rowFilter, setCurrent_rowFilter] = useState({})
    const [current_event_type_row, setCurrent_event_type_row] = useState({})
    const affectations_type =
    [   { label: t('all'), value: 'all' },
        { label: t("departments"), value: "departments"},
        { label: t('branches'), value: 'branches'},
        { label: t('positions'), value: 'positions'},
        { label: t('specific_employee'), value: 'employee'}
    ]
    const [affect, setAffect] = useState(affectations_type[0])
    const [assignTo, setAssignTo] = useState([])

    const [affectFilter, setAffectFilter] = useState(affectations_type[0])
    const [assignToFilter, setAssignToFilter] = useState([])
    const [link, setLink] = useState("https://meet.glow-hr.com/8gdsyt");
    const [formError, setFormError] = useState({})

    const generateLink = ()=>{
        let link = generateVisioUrl();
        setLink(link)
    }
    const  handleLink = (e)=>{
        setLocation(e.target.e)
        setLink(e.target.e)
        let data =handleValidation(t,{type:"text",name:'link','value':e.target.e,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };
    const handleName=(e)=>{
        setName(e.target.value)
        let data =handleValidation(t,{type:"text",name:'name','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
 const handleLocation=(e)=>{
        setLocation(e.target.value)
    }

    const handleDescription=(e)=>{
        setDescription(e.target.value)
        let data =handleValidation(t,{type:"textarea",name:'description','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }

 const handleEventDescription=(e)=>{
        setEventDescription(e.target.value)
        let data =handleValidation(t,{type:"textarea",name:'eventDescription','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }

    const handleAffectation_typeFilter = (e) => {
        setAssignToFilter([]);
        setAffectFilter(e)
        setAttendeesTargetFilter(e.value)
    };

    const handleSelectAssignFilter = (e) => {
       if(e) {
           setAssignToFilter(e)
           setAttendeesTargetIdFilter(e.filter(res => {
               return res._id
           }))
       }else{
           setAttendeesTargetIdFilter([])
       }
    };
   const handleAffectation_type = (e) => {
        setAssignTo([]);
        setAffect(e)
        setAttendeesTarget(e.value)
    };
    const handle_event_target = (e) => {
        setEventTarget(e)
        let data =handleValidation(t,{type:"text",name:'eventTarget','value':e,required:true,isArray:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleSelectAssign = (e) => {
       if(e) {
           setAssignTo(e)
           setAttendeesTargetId(e.filter(res => {
               return res._id
           }))
       }else{
           setAttendeesTargetId([])
       }
    };
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    let user = JSON.parse(window.localStorage.getItem('glowhr_user')).user;
    const history = useHistory();
    useEffect(() => {
        props.action_listFormations();
        props.action_listColleagues();
        props.action_listDepartments();
        props.action_listBranches();
        props.action_listPositions();
        props.action_listEventType()
        props.action_listEvent()
        props.action_listVisioConfs();
        props.action_listSurveys();
        props.action_setTitle(t("calendar_title"))
    }, [])
    const formatEvents=(events)=>{
         let data=[];
        if(events && events.length>0){
           data= events.map(res=>{
             if(res.attendees.map(r=>r.user===user._id)) {
                 return {
                     id: res._id, title: res.subject, desc: res.description,
                     start: new Date(formatDateNowSpec(res.start_date, 'year'), formatDateNowSpec(res.start_date, 'month'), formatDateNowSpec(res.start_date, 'date'), formatDateNowSpec(res.start_date, 'hour'), formatDateNowSpec(res.start_date, 'minute'), formatDateNowSpec(res.start_date, 'seconde')),
                     end: new Date(formatDateNowSpec(res.end_date, 'year'), formatDateNowSpec(res.end_date, 'month'), formatDateNowSpec(res.end_date, 'date'), formatDateNowSpec(res.end_date, 'hour'), formatDateNowSpec(res.end_date, 'minute'), formatDateNowSpec(res.end_date, 'seconde'))
                 };
             }
             })
        }
        return data;
    }
    const resetEventType =()=>{
        setName("")
        setDescription("")
        setIsEventTypeUpdate(false);
        setCurrent_event_type_row([]);
    }
    const resetEvent =()=>{
        setTitle("")
         setAffect(affectations_type[0])
        setEventDescription("")
        setStart("")
        setEnd("")
        setEventDescription("")
        setLocation("")
        setEventType("")
        setAttendeesTargetId([])
        setEventTarget([]);
        setCurrent_row([]);
        setIsEventUpdate(false)
    }
    const editEvent=(event)=>{
        let events=props.rwState.events;
        let data= events.find(r=>r._id===event.id)
        if(data){
            let affect=affectations_type.find(r=>r.value===data.attendees_target)
            setAffect(affect)
            setStart(formatDateNow(data.start_date))
            setAttendeesTargetId(data.attendees_target_id)
            setAttendeesTarget(data.attendees_target)
            setEnd(formatDateNow(data.end_date))
            setTitle(data.title)
            setEventDescription(data.description)
            setLocation(data.location)
            setEventType(data.event_type_info)
        }
        setIsEventUpdate(true)
        setCurrent_row(data)
        isAdmin(role) ? setShow('create_event') : setShow('details_event')
    }
    const createEventType= () => {
        let o = {
            "name": name,
            "description": description,
            "sio_channel": "",
            "id":current_event_type_row?._id
        };
        if (!isEventTypeUpdate)
            props.action_createEventType(o)
        else
            props.action_updateEventType(o);
        setShow('list_type_event')
        props.action_loading(true)
        resetEventType();
    }
    const filterEvent= () => {
        if(attendees_target_idFilter) {
            let o = {
                "attendees_target": attendees_targetFilter,
                "attendees_target_id": attendees_target_idFilter,
            };
            props.action_EventFilter(o)
            props.action_loading(true)
        }
    }
  const createEvent= () => {
        let o = {
            "subject": title,
            "description": event_description,
            "location": type_event && type_event.name!=='visio_conference' ? location : link,
            "attendees_target": attendees_target,
            "attendees_target_id": attendees_target_id,
            "start_date": start,
            "end_date": end,
            "target":type_event ? type_event.name : '',
            "target_id":event_target,
            "event_type_info": type_event._id,
            "sio_channel": "",
            "id":current_row?._id
        };
        if (!isEventUpdate)
            props.action_createEvent(o)
        else
            props.action_updateEvent(o);
        setShow(null)
        props.action_loading(true)
        resetEvent();
    }
   const confirmUpdateEventType= (o) => {
       setCurrent_event_type_row(o)
       setName(o.name)
       setDescription(o.description)
       setIsEventTypeUpdate(true)
       setShow('create_type_event')
    }
    const formatDateNow = (date) => {
        Number.prototype.AddZero= function(b,c){
            var  l= (String(b|| 10).length - String(this).length)+1;
            return l> 0? new Array(l).join(c|| '0')+this : this;
        }//to add zero to less than 10,
        var d = new Date(date);
        return [d.getFullYear(),
                (d.getMonth() + 1).AddZero(),
                d.getDate().AddZero()].join('-') + 'T' +
            [d.getHours().AddZero(),
                d.getMinutes().AddZero()].join(':');
    }
  const formatDateNowSpec = (date,type) => {
      Number.prototype.AddZero= function(b,c){
          var  l= (String(b|| 10).length - String(this).length)+1;
          return l> 0? new Array(l).join(c|| '0')+this : this;
      }//to add zero to less than 10,
        var d = new Date(date),
            f=0;
        switch (type) {
            case 'hour':
                f=d.getHours()+1;
                break;
   case 'minute':
                f=d.getMinutes();
                break;
   case 'year':
                f=d.getFullYear();
                break;
   case 'month':
                f=d.getMonth();
                break;
   case 'date':
                f=d.getDate();
                break;
case 'seconde':
                f=d.getSeconds();
                break;
        }
      return f;
    }
   const handle_type_event = (e) => {
        setEventType(e)
        let data =handleValidation(t,{type:"text",name:'typeEvent','value':e._id,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
  const handleClose = () => {
        setShow(null)
      resetEvent();
    resetEventType()
  }
   const handleStart = (e) => {
        setStart(e.target.value)
        let data =handleValidation(t,{type:"date",name:'startDate','value':e.target.value,required:true,'min':new Date()});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
   const handleEnd = (e) => {
         setEnd(e.target.value)
         let data =handleValidation(t,{type:"date",name:'endDate','value':e.target.value,required:true,'min':start});
         let form=formError;
                 form[data.name]=data   
                 setFormError(form)
    }
   const handleTitle = (e) => {
        setTitle(e.target.value)
        let data =handleValidation(t,{type:"text",name:'title','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleLatitude = (e) => {
        setLatitude(e.target.value)
    }
   const handleLongitude = (e) => {
        setLongitude(e.target.value)
    }
    const handleSelect = ({ start, end }) => {
        setStart(formatDateNow(start))
        setEnd(formatDateNow(end))
        setShow('create_event')
    }
    const contextMenu = [
        {label: t("companie"), eventKey: 'cCalendar'},
        {label: t("personal"), eventKey: 'pCalendar'},
    ];
    const typeEventColumns = [
        {
            name: t("name"),
            selector: 'name'
        },
        {
            name: t("description"),
            selector: 'description'
        },
        {
            name:'',
            cell: row => <Button onClick={() => confirmUpdateEventType(row)} variant={"link"} size={"sm"}><BI.BiPencil size={22}/></Button>,
            grow:0
        },
        {
            cell: row => <Button  onClick={()=>{confirmAction({target:'event_type',o:row})}}  variant={"link"} size={"sm"}><BI.BiTrash style={{color: 'red'}} size={22}/></Button>,
            grow:0
        }
    ];
    const confirmDeleteAction=()=>{
        let data=actionData;
        switch (data.target) {
            case 'event_type':
                props.action_deleteEventType(data.o)
                props.action_loading(true)
                setShow("list_type_event")
                break
            case 'event':
                 props.action_deleteEvent(data.o)
                props.action_loading(true)
                setShow(null)
                break
        }

    }
    const confirmAction=(data)=> {
        setShow('confirm')
        setActionData(data);
        switch (data.target) {
            case 'event_type':
                setClose('list_type_event')
                break
            case 'event':
                setClose(null)
                break
        }
    }

   
    return <div className={"col-12"}>
      <Container fluid>
          <Row>
              <Col>
                  <Tab.Container id="left-tabs-example" defaultActiveKey={'cCalendar'}>
                      <div className="d-flex align-items-start justify-content-between">
                          {/* <ContextMenu menu={contextMenu}/> */}
                          { isAdmin(role) ?
                              <div className={"text-right col-12"}>
                                  <Button className={"btn btn-warning "} hidden={role.toLowerCase().includes('rh')} onClick={() => {
                                      setShow('list_type_event')
                                  }}>{t("event_type_text")}</Button> &nbsp;&nbsp;&nbsp;
                                  <Button hidden={role.toLowerCase().includes('rh')} onClick={() => {
                                      setShow('create_event')
                                  }}>{t("ceate_event_action")}</Button>
                              </div>
                              : ""
                          }
                      </div>
                      <Tab.Content>
                          <Tab.Pane className={"lastRollL"} eventKey="cCalendar">
                           <div className={"col-12"}>
                               <div className="mt-2 mb-4">
                                   <a href="#" className={"btn btn-light btn-sm"} onClick={()=>setFilter(true)} hidden={filtrer}>
                                       <BI.BiFilter style={{color: '#240265', fontWeight: '600'}} size={25}/>
                                       {t("filter_text")}
                                   </a>
                               </div>
                              <Form.Group style={{background: '#fff',padding: '2%'}} hidden={!filtrer}>
                             <div className="row">
                                 <div className="col-12 mb-3">
                                     <Form.Label> Filtrer par : </Form.Label>
                                     <BI.BiWindowClose onClick={()=>setFilter(false)} style={{cursor:'pointer', color: '#240265', fontWeight: '600',float:'right'}} size={25}/>
                                 </div>
                                 <div className="col-4">
                                     <Form.Group>
                                         <Select noOptionsMessage={() => t("no_data_available")}
                                             options={affectations_type}
                                             defaultValue={affectFilter}
                                             styles={{
                                                 control: base => ({
                                                     ...base,
                                                 })
                                             }}
                                             theme={theme => ({
                                                 ...theme,
                                                 colors: {
                                                     ...theme.colors,
                                                     primary: '#6600CC',
                                                     primary25: '#6600CC25',
                                                     primary75: '#6600CC75',
                                                     primary50: '#6600CC50'
                                                 },
                                             })}
                                             placeholder={t("add_to")}
                                             name='affect_to'
                                             onChange={handleAffectation_typeFilter}
                                         />
                                     </Form.Group>
                                 </div>
                                 <div className="col-4" hidden={!affectFilter || affectFilter.value!=='branches' && affectFilter.value!=='departments' && affectFilter.value!=='employee' && affectFilter.value!=='positions' }>
                                     <Form.Group hidden={affectFilter ? affectFilter.value!=='branches'  : !affectFilter}>
                                     <Select noOptionsMessage={() => t("no_data_available")}
                                         options={props.rwState.branches}
                                         getOptionLabel={o => o.name}
                                         getOptionValue={o => o._id}
                                         defaultValue={affectFilter && affectFilter.value==='branches' && current_rowFilter && current_rowFilter.attendees_target_id ? current_rowFilter.attendees_target_id.map(res=>{return res}) : ""}
                                         styles={{
                                             control: base => ({
                                                 ...base,
                                                 minHeight: 48,
                                                 // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                             })
                                         }}
                                         theme={theme => ({
                                             ...theme,
                                             colors: {
                                                 ...theme.colors,
                                                 primary: '#6600CC',
                                                 primary25: '#6600CC25',
                                                 primary75: '#6600CC75',
                                                 primary50: '#6600CC50'
                                             },
                                         })}
                                         placeholder={t("branch_title_unique")}
                                         isMulti
                                         onChange={handleSelectAssignFilter}
                                     />
                                 </Form.Group>
                                     <Form.Group hidden={affectFilter ? affectFilter.value!=='departments'  : !affectFilter}>
                                         <Select noOptionsMessage={() => t("no_data_available")}
                                             options={props.rwState.departments}
                                             getOptionLabel={o => o.name}
                                             getOptionValue={o => o._id}
                                             defaultValue={affectFilter && affectFilter.value==='departments' && current_rowFilter && current_rowFilter.attendees_target_id ? current_rowFilter.attendees_target_id.map(res=>{return res}) : ""}
                                             styles={{
                                                 control: base => ({
                                                     ...base,

                                                     minHeight: 48,
                                                     // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                                 })
                                             }}
                                             theme={theme => ({
                                                 ...theme,
                                                 colors: {
                                                     ...theme.colors,
                                                     primary: '#6600CC',
                                                     primary25: '#6600CC25',
                                                     primary75: '#6600CC75',
                                                     primary50: '#6600CC50'
                                                 },
                                             })}
                                             placeholder={t("department_title_unique")}
                                             isMulti
                                             onChange={handleSelectAssignFilter}
                                         />
                                     </Form.Group>
                                     <Form.Group hidden={affectFilter ? affectFilter.value!=='positions'  : !affectFilter}>
                                         <Select noOptionsMessage={() => t("no_data_available")}
                                             options={props.rwState.positions}
                                             getOptionLabel={o => o.title}
                                             getOptionValue={o => o._id}
                                             defaultValue={affectFilter && affectFilter.value!=='positions' && current_rowFilter && current_rowFilter.attendees_target_id ? current_rowFilter.attendees_target_id.map(res=>{return res}) : ""}
                                             styles={{
                                                 control: base => ({
                                                     ...base,

                                                     minHeight: 48,
                                                     // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                                 })
                                             }}
                                             theme={theme => ({
                                                 ...theme,
                                                 colors: {
                                                     ...theme.colors,
                                                     primary: '#6600CC',
                                                     primary25: '#6600CC25',
                                                     primary75: '#6600CC75',
                                                     primary50: '#6600CC50'
                                                 },
                                             })}
                                             placeholder={t("position_title_unique")}
                                             isMulti
                                             onChange={handleSelectAssignFilter}
                                         />
                                     </Form.Group>
                                     <Form.Group hidden={affectFilter ? affectFilter.value!=='employee' : !affectFilter}>
                                         <Select noOptionsMessage={() => t("no_data_available")}
                                             options={props.rwState.colleagues}
                                             getOptionLabel={o => o.lastname+''+o.firstname}
                                             getOptionValue={o => o._id}
                                             defaultValue={affectFilter && affectFilter.value==='employee' && current_rowFilter && current_rowFilter.attendees_target_id ? current_rowFilter.attendees_target_id.map(res=>{return res}) : ""}
                                             styles={{
                                                 control: base => ({
                                                     ...base,
                                                     minHeight: 48,
                                                 })
                                             }}
                                             theme={theme => ({
                                                 ...theme,
                                                 colors: {
                                                     ...theme.colors,
                                                     primary: '#6600CC',
                                                     primary25: '#6600CC25',
                                                     primary75: '#6600CC75',
                                                     primary50: '#6600CC50'
                                                 },
                                             })}
                                             placeholder={t("employee_text")}
                                             isMulti
                                             onChange={handleSelectAssignFilter}
                                         />
                                     </Form.Group>
                                 </div>
                                 <div className="col-4 pt-2">
                                     <button className="btn btn-outline-primary btn-sm" onClick={()=>filterEvent()}>{t("filter_text")}</button>
                                 </div>
                             </div>
                              </Form.Group>
                           </div>
                               <Col sm={12} className={"mb-5"}>
                                  <Calendar
                                      selectable
                                      culture={'fr'}
                                      localizer={localizer}
                                      events={formatEvents(props.rwState.events  && props.rwState.events.length>0 ? props.rwState.events : [])}
                                      startAccessor="start"
                                      endAccessor="end"
                                      step={15}
                                      timeslots={8}
                                      defaultView={Views.WEEK}
                                      onSelectEvent={event => editEvent(event)}
                                      onSelectSlot={handleSelect}
                                      messages={messages}
                                      eventPropGetter={
                                        useCallback(
                                            (event, start, end, isSelected) => ({
                                              ...(isSelected && {
                                                style: {
                                                  backgroundColor: '#000',
                                                },
                                              }),
                                             
                                              ...(event.title.toLowerCase().includes('formation') && {
                                                style: {
                                                    backgroundColor: 'darkGreen',
                                                  },
                                                className: 'darkGreen',
                                              }),
                                            ...(event.title.toLowerCase().includes('sondage') && {
                                                style: {
                                                    backgroundColor: 'orange',
                                                  },
                                                className: 'orange',
                                              }), 
                                              ...(event.title.toLowerCase().includes('shadowing') && {
                                                style: {
                                                    backgroundColor: 'blue',
                                                  },
                                                className: 'blue',
                                              }),
                                             ...(event.title.toLowerCase().includes('rendez-vous') && {
                                                style: {
                                                    backgroundColor: 'maroon',
                                                  },
                                                className: 'maroon',
                                              }),
                                             ...(event.title.toLowerCase().includes('point du premier jour') && {
                                                style: {
                                                    backgroundColor: 'purple',
                                                  },
                                                className: 'purple',
                                              }),
                                              ...(event.title.toLowerCase().includes('point de la première semaine') && {
                                                style: {
                                                    backgroundColor: 'fuchsia',
                                                  },
                                                className: 'fuchsia',
                                              }),
                                            ...(event.title.toLowerCase().includes('point a la fin des 2 semaines') && {
                                                style: {
                                                    backgroundColor: 'aqua',
                                                  },
                                                className: 'aqua',
                                              }),
                                            ...(event.title.toLowerCase().includes('Transfere de connaissance') && {
                                                style: {
                                                    backgroundColor: 'olive',
                                                  },
                                                className: 'olive',
                                              }),
                                             ...(event.title.toLowerCase().includes('coupure d\'acces') && {
                                                style: {
                                                    backgroundColor: 'red',
                                                  },
                                                className: 'red',
                                              }),
                                             ...(event.title.toLowerCase().includes('remise des affaires de l\'entreprise') && {
                                                style: {
                                                    backgroundColor: 'darkcyan',
                                                  },
                                                className: 'darkcyan',
                                              }),
                                            }),
                                            []
                                          )
                                      }
                                  />
                              </Col>
                          </Tab.Pane>
                          {/* <Tab.Pane className={"lastRollL"} eventKey="pCalendar">
                                  <Col sm={12} className={"mb-5"}>
                                      <Calendar
                                          selectable
                                          culture={'fr'}
                                          localizer={localizer}
                                          events={formatEvents(props.rwState.events && props.rwState.events.length>0 ? props.rwState.events.filter(res=>res.type==="personnal") : [])}
                                          startAccessor="start"
                                          endAccessor="end"
                                          step={15}
                                          timeslots={8}
                                          defaultView={Views.WEEK}
                                          onSelectEvent={event => editEvent(event)}
                                          onSelectSlot={handleSelect}
                                          messages={messages}
                                          eventPropGetter={
                                            useCallback(
                                                (event, start, end, isSelected) => ({
                                                  ...(isSelected && {
                                                    style: {
                                                      backgroundColor: '#000',
                                                    },
                                                  }),
                                                  ...(moment(start).hour() < 12 && {
                                                    className: 'powderBlue',
                                                  }),
                                                  ...(event.title.toLowerCase().includes('Formation') && {
                                                    style: {
                                                        backgroundColor: 'darkGreen',
                                                      },
                                                    className: 'darkGreen',
                                                  }),
                                                ...(event.title.toLowerCase().includes('sondage') && {
                                                    style: {
                                                        backgroundColor: 'orange',
                                                      },
                                                    className: 'orange',
                                                  }), 
                                                  ...(event.title.toLowerCase().includes('shadowing') && {
                                                    style: {
                                                        backgroundColor: 'blue',
                                                      },
                                                    className: 'blue',
                                                  }),
                                                 ...(event.title.toLowerCase().includes('rendez-vous') && {
                                                    style: {
                                                        backgroundColor: 'maroon',
                                                      },
                                                    className: 'maroon',
                                                  }),
                                                 ...(event.title.toLowerCase().includes('point du premier jour') && {
                                                    style: {
                                                        backgroundColor: 'purple',
                                                      },
                                                    className: 'purple',
                                                  }),
                                                  ...(event.title.toLowerCase().includes('point de la première semaine') && {
                                                    style: {
                                                        backgroundColor: 'fuchsia',
                                                      },
                                                    className: 'fuchsia',
                                                  }),
                                                ...(event.title.toLowerCase().includes('point a la fin des 2 semaines') && {
                                                    style: {
                                                        backgroundColor: 'aqua',
                                                      },
                                                    className: 'aqua',
                                                  }),
                                                ...(event.title.toLowerCase().includes('Transfere de connaissance') && {
                                                    style: {
                                                        backgroundColor: 'olive',
                                                      },
                                                    className: 'olive',
                                                  }),
                                                 ...(event.title.toLowerCase().includes('coupure d\'acces') && {
                                                    style: {
                                                        backgroundColor: 'red',
                                                      },
                                                    className: 'red',
                                                  }),
                                                 ...(event.title.toLowerCase().includes('remise des affaires de l\'entreprise') && {
                                                    style: {
                                                        backgroundColor: 'darkcyan',
                                                      },
                                                    className: 'darkcyan',
                                                  }),
                                                }),
                                                []
                                              )
                                          }
                                      />
                                  </Col>
                          </Tab.Pane> */}
                      </Tab.Content>
                  </Tab.Container>
              </Col>
          </Row>
      </Container>
      <Modal centered show={show === 'create_event'} onHide={handleClose} backdrop="static" size={"lg"}>
          <Modal.Header close className={"bg-primary"}>
              <h4 className={"col-lg-12 text-white text-center"}>{t("ceate_event_action")}</h4>
          </Modal.Header>
          <Modal.Body className={'p-4'}>
              <Row>
          <Col sm={12}>
              <Form.Group>
                  <Form.Label>{t("event_type_text")}  <span className={'required_box'}>*</span></Form.Label>
                  <Select noOptionsMessage={() => t("no_data_available")} options={props.rwState.event_types}
                          getOptionLabel={o => o.name}
                          getOptionValue={o => o._id}
                          defaultValue={current_row?.event_type_info}
                      styles={{
                          control: base => ({
                              ...base,
                          })
                      }}
                      theme={theme => ({
                          ...theme,
                          colors: {
                              ...theme.colors,
                              primary: '#6600CC',
                              primary25: '#6600CC25',
                              primary75: '#6600CC75',
                              primary50: '#6600CC50'
                          },
                      })}
                      placeholder={'Choisir un type'}
                      name='type'
                      onChange={handle_type_event}
                      className={(formError && formError.typeEvent && formError.typeEvent.errors && !formError.typeEvent.formIsValid) ? 'inputValidationError' : ''}
                      />
                             {(formError && formError.typeEvent && formError.typeEvent.errors && !formError.typeEvent.formIsValid) ? <span className={"validationError"}>{formError.typeEvent.errors.typeEvent}</span> : ''}
            
              </Form.Group>

              <Form.Group>
                      <Form.Label>{t("title")}  <span className={'required_box'}>*</span></Form.Label>
                      <Form.Control
                          onChange={handleTitle}
                          defaultValue={current_row?.subject}
                          type={"text"} 
                          className={(formError && formError.title && formError.title.errors && !formError.title.formIsValid) ? 'inputValidationError' : ''}
                          />
                                 {(formError && formError.title && formError.title.errors && !formError.title.formIsValid) ? <span className={"validationError"}>{formError.title.errors.title}</span> : ''}
               </Form.Group>
          </Col>

              <Col sm={12} md={6}>
             <Form.Group>
                      <Form.Label>{t("start_")}  <span className={'required_box'}>*</span></Form.Label> <br/>
                 <Form.Control
                      onChange={handleStart}
                     value={start}
                     type={"datetime-local"}
                     className={(formError && formError.startDate && formError.startDate.errors && !formError.startDate.formIsValid) ? 'inputValidationError' : ''}
                     />
                            {(formError && formError.startDate && formError.startDate.errors && !formError.startDate.formIsValid) ? <span className={"validationError"}>{formError.startDate.errors.startDate}</span> : ''}
            </Form.Group>
              </Col>
              <Col sm={12} md={6}>
              <Form.Group>
                      <Form.Label>{t("end_")}  <span className={'required_box'}>*</span></Form.Label> <br/>
                  <Form.Control
                      onChange={handleEnd}
                      min={start}
                      value={end}
                      type={"datetime-local"}
                      className={(formError && formError.endDate && formError.endDate.errors && !formError.endDate.formIsValid) ? 'inputValidationError' : ''}
                      />
                             {(formError && formError.endDate && formError.endDate.errors && !formError.endDate.formIsValid) ? <span className={"validationError"}>{formError.endDate.errors.endDate}</span> : ''}
             </Form.Group>
              </Col>
                  {type_event && props.rwState.formations && props.rwState.formations.length > 0 && props.rwState.formations.filter(re=>re.status==='active' || re.status==='en_cours').length > 0 && type_event?.name === "formation" ?
                      <Col sm={"12"}>
                          <Form.Label>{t("assinged_some")} {type_event?.name + "(s)"}  <span className={'required_box'}>*</span></Form.Label> <br/>
                          <Form.Group>
                              <Select noOptionsMessage={() => t("no_data_available")}
                                  options={props.rwState.formations.filter(re=>re.status==='active' || re.status==='en_cours')}
                                  getOptionLabel={o => o.name}
                                  getOptionValue={o => o._id}
                                  defaultValue={
                                      current_row && current_row.target_id ?
                                        (current_row.target_id[0]?._id  ? current_row.target_id : 
                                            props.rwState.formations.filter(res=>current_row.target_id.indexOf(res._id) >=0) 
                                            )
                                        : []
                                    }
                                  styles={{
                                      control: base => ({
                                          ...base,

                                          minHeight: 48,
                                          // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                      })
                                  }}
                                  theme={theme => ({
                                      ...theme,
                                      colors: {
                                          ...theme.colors,
                                          primary: '#6600CC',
                                          primary25: '#6600CC25',
                                          primary75: '#6600CC75',
                                          primary50: '#6600CC50'
                                      },
                                  })}
                                  placeholder={t("formation_title")}
                                  isMulti
                                  onChange={handle_event_target}
                                  className={(formError && formError.eventTarget && formError.eventTarget.errors && !formError.eventTarget.formIsValid) ? 'inputValidationError' : ''}
                                  />
                                         {(formError && formError.eventTarget && formError.eventTarget.errors && !formError.eventTarget.formIsValid) ? <span className={"validationError"}>{formError.eventTarget.errors.eventTarget}</span> : ''}
                       
                          </Form.Group>
                      </Col>
                      : ""
                  }
             
                  {type_event && props.rwState.surveys && props.rwState.surveys.length > 0 && props.rwState.surveys.filter(r=> moment(r.end_date).isAfter()).length > 0 && type_event?.name === "sondage" ?
                      <Col sm={"12"}>
                          <Form.Label>{t("assinged_some")} {type_event?.name + "(s)"}  <span className={'required_box'}>*</span></Form.Label> <br/>
                          <Form.Group>
                              <Select noOptionsMessage={() => t("no_data_available")}
                                  options={props.rwState.surveys.filter(r=> moment(r.end_date).isAfter())}
                                  getOptionLabel={o => o.title}
                                  getOptionValue={o => o._id}
                                  defaultValue={
                                    current_row && current_row.target_id ?
                                    (current_row.target_id[0]?._id ? current_row.target_id : 
                                        props.rwState.surveys.filter(res=>current_row.target_id.indexOf(res._id) >=0) 
                                        )
                                    : []
                                    }
                                  styles={{
                                      control: base => ({
                                          ...base,
                                          minHeight: 48,
                                      })
                                  }}
                                  theme={theme => ({
                                      ...theme,
                                      colors: {
                                          ...theme.colors,
                                          primary: '#6600CC',
                                          primary25: '#6600CC25',
                                          primary75: '#6600CC75',
                                          primary50: '#6600CC50'
                                      },
                                  })}
                                  placeholder={'Sondage'}
                                  isMulti
                                  onChange={handle_event_target}
                                  className={(formError && formError.eventTarget && formError.eventTarget.errors && !formError.eventTarget.formIsValid) ? 'inputValidationError' : ''}
                                  />
                                         {(formError && formError.eventTarget && formError.eventTarget.errors && !formError.eventTarget.formIsValid) ? <span className={"validationError"}>{formError.eventTarget.errors.eventTarget}</span> : ''}
                        </Form.Group>
                      </Col>
                      : ""
                  }
              <Col sm={12}>
              <Form.Group>
                      <Form.Label>{t("description")}</Form.Label>
                      <Form.Control as="textarea" rows={3}
                                    defaultValue={current_row?.description}
                                    onChange={handleEventDescription}
                          type={"text"}/>
              </Form.Group>
               <Form.Group>
               {type_event && type_event?.name === "visio_conference" ?
                <div>
                <Button variant={"white"} className={"float-right"} onClick={generateLink}>Générer un lien</Button>
                <Form.Group>
                    <Form.Label>{t("conf_link")}  <span className={'required_box'}>*</span></Form.Label>
                    <Form.Control
                        required
                        size={"lg"}
                        onChange={handleLink}
                        defaultValue={current_row && current_row.length > 0  ? current_row.location : link}
                        className={(formError && formError.link && formError.link.errors && !formError.link.formIsValid) ? 'inputValidationError' : ''}
                                  />
                                         {(formError && formError.link && formError.link.errors && !formError.link.formIsValid) ? <span className={"validationError"}>{formError.link.errors.link}</span> : ''}
                       
                </Form.Group>
                </div>
               :
               <div>
                      <Form.Label> {t("location")}</Form.Label>
                      <Form.Control as={"input"}
                                    defaultValue={current_row?.location}
                                    onChange={handleLocation}
                          type={"text"}/>
                          </div>      
}
              </Form.Group>
              </Col>
                  <Col sm={12}>
                      <Form.Label>{t("add_participants")}</Form.Label> <br/>
                      <Form.Group>
                          <Select noOptionsMessage={() => t("no_data_available")}
                              options={affectations_type}
                              defaultValue={affect}
                              styles={{
                                  control: base => ({
                                      ...base,
                                  })
                              }}
                              theme={theme => ({
                                  ...theme,
                                  colors: {
                                      ...theme.colors,
                                      primary: '#6600CC',
                                      primary25: '#6600CC25',
                                      primary75: '#6600CC75',
                                      primary50: '#6600CC50'
                                  },
                              })}
                              placeholder={t("add_to")}
                              name='affect_to'
                              onChange={handleAffectation_type}
                          />
                      </Form.Group>
                      <Form.Group hidden={affect ? affect.value!=='branches'  : !affect}>
                          <Select noOptionsMessage={() => t("no_data_available")}
                              options={props.rwState.branches}
                              getOptionLabel={o => o.name}
                              getOptionValue={o => o._id}
                              defaultValue={affect && affect.value==='branches' && current_row && current_row.attendees_target_id ?
                              (current_row.attendees_target_id[0]?._id ? current_row.attendees_target_id : 
                                props.rwState.branches.filter(res=>current_row.attendees_target_id.indexOf(res._id) >=0) 
                                )
                               : []}
                              styles={{
                                  control: base => ({
                                      ...base,
                                      minHeight: 48,
                                      // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                  })
                              }}
                              theme={theme => ({
                                  ...theme,
                                  colors: {
                                      ...theme.colors,
                                      primary: '#6600CC',
                                      primary25: '#6600CC25',
                                      primary75: '#6600CC75',
                                      primary50: '#6600CC50'
                                  },
                              })}
                              placeholder={t("branch_title_unique")}
                              isMulti
                              onChange={handleSelectAssign}
                          />
                      </Form.Group>
                      <Form.Group hidden={affect ? affect.value!=='departments'  : !affect}>
                          <Select noOptionsMessage={() => t("no_data_available")}
                              options={props.rwState.departments}
                              getOptionLabel={o => o.name}
                              getOptionValue={o => o._id}
                              defaultValue={affect && affect.value==='departments' && current_row && current_row.attendees_target_id ?
                              (current_row.attendees_target_id[0]?._id ? current_row.attendees_target_id : 
                                props.rwState.departments.filter(res=>current_row.attendees_target_id.indexOf(res._id) >=0) 
                                )
                                : ""}
                              styles={{
                                  control: base => ({
                                      ...base,

                                      minHeight: 48,
                                      // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                  })
                              }}
                              theme={theme => ({
                                  ...theme,
                                  colors: {
                                      ...theme.colors,
                                      primary: '#6600CC',
                                      primary25: '#6600CC25',
                                      primary75: '#6600CC75',
                                      primary50: '#6600CC50'
                                  },
                              })}
                              placeholder={t("department_title_unique")}
                              isMulti
                              onChange={handleSelectAssign}
                          />
                      </Form.Group>
                      <Form.Group hidden={affect ? affect.value!=='positions'  : !affect}>
                          <Select noOptionsMessage={() => t("no_data_available")}
                              options={props.rwState.positions}
                              getOptionLabel={o => o.title}
                              getOptionValue={o => o._id}
                              defaultValue={
                                  affect && affect.value!=='positions' && current_row && current_row.attendees_target_id ? 
                                  (current_row.attendees_target_id[0]?._id ? current_row.attendees_target_id : 
                                    props.rwState.positions.filter(res=>current_row.attendees_target_id.indexOf(res._id) >=0) 
                                    )
                                  : []}
                              styles={{
                                  control: base => ({
                                      ...base,

                                      minHeight: 48,
                                      // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                  })
                              }}
                              theme={theme => ({
                                  ...theme,
                                  colors: {
                                      ...theme.colors,
                                      primary: '#6600CC',
                                      primary25: '#6600CC25',
                                      primary75: '#6600CC75',
                                      primary50: '#6600CC50'
                                  },
                              })}
                              placeholder={t("position_title_unique")}
                              isMulti
                              onChange={handleSelectAssign}
                          />
                      </Form.Group>
                      <Form.Group hidden={affect ? affect.value!=='employee' : !affect}>
                          <Select noOptionsMessage={() => t("no_data_available")}
                              options={props.rwState.colleagues}
                              getOptionLabel={o => o.lastname+' '+o.firstname}
                              getOptionValue={o => o._id}
                              defaultValue={affect && affect.value==='employee' && current_row && current_row.attendees_target_id ?
                              (current_row.attendees_target_id[0]?._id ? current_row.attendees_target_id : 
                                props.rwState.colleagues.filter(res=>current_row.attendees_target_id.indexOf(res._id) >=0) 
                                )
                               : [""]}
                              styles={{
                                  control: base => ({
                                      ...base,
                                      minHeight: 48,
                                  })
                              }}
                              theme={theme => ({
                                  ...theme,
                                  colors: {
                                      ...theme.colors,
                                      primary: '#6600CC',
                                      primary25: '#6600CC25',
                                      primary75: '#6600CC75',
                                      primary50: '#6600CC50'
                                  },
                              })}
                              placeholder={t("employee_text")}
                              isMulti
                              onChange={handleSelectAssign}
                          />
                      </Form.Group>
                  </Col>
              </Row>
          </Modal.Body>
          <Modal.Footer className={'border-0 pt-0'}>
              <Button variant="light" onClick={handleClose}>
                  {t("close")}
              </Button>
              <Button variant="primary" 
              disabled={
                !formError?.typeEvent?.formIsValid || 
                !formError?.startDate?.formIsValid || 
                !formError?.endDate?.formIsValid ||
                !formError?.title?.formIsValid ||
                (link && type_event && type_event==='visio_conference' ?  !formError?.link?.formIsValid : !formError?.typeEvent?.formIsValid) || 
                (  type_event && (type_event==='formation' || type_event==='sondage') ? !formError?.eventTarget?.formIsValid  : !formError?.typeEvent?.formIsValid) 
              }
              onClick={createEvent}>
                 {isEventUpdate ? t("update") : t("save")}
              </Button>
              { isEventUpdate && current_row ?
                  <Button variant="danger" onClick={()=>confirmAction({target:'event',o:current_row})}>
                      {t("delete")}
                  </Button>
                  : ""
              }
          </Modal.Footer>
      </Modal>

        {/*details evenement*/}

      <Modal centered show={show === 'details_event'} onHide={handleClose} backdrop="static" size={"lg"}>
          <Modal.Header close className={"bg-primary"}>
              <h4 className={"col-lg-12 text-white text-center"}>{t("event_title")}</h4>
          </Modal.Header>
          <Modal.Body className={'p-4'}>
              <Row>
          <Col sm={12}>
              <Form.Group>
                  <Form.Label>{t("event_type_text")}</Form.Label>
                  <Form.Control
                      disabled={true}
                      defaultValue={current_row.event_type_info? current_row.event_type_info.name : ""}
                      type={"text"}/>
              </Form.Group>
              <Form.Group>
                      <Form.Label>{t("title")}</Form.Label>
                      <Form.Control
                          disabled={true}
                          defaultValue={current_row?.subject}
                          type={"text"}/>
              </Form.Group>
          </Col>

              <Col sm={12} md={6}>
             <Form.Group>
                      <Form.Label>{t("start_")}</Form.Label> <br/>
                 <Form.Control
                     disabled={true}
                     value={start}
                     type={"datetime-local"}/>
              </Form.Group>
              </Col>
              <Col sm={12} md={6}>
              <Form.Group>
                      <Form.Label>{t("end_")}</Form.Label> <br/>
                  <Form.Control
                      disabled={true}
                      min={start}
                      value={end}
                      type={"datetime-local"}/>
              </Form.Group>
              </Col>
              <Col sm={12}>
              <Form.Group>
                      <Form.Label>{t("description")}</Form.Label>
                      <Form.Control as="textarea" rows={3}
                                    disabled={true}
                                    defaultValue={current_row?.description}
                          type={"text"}/>
              </Form.Group>
              {current_row.location && current_row.location!=='' && current_row.event_type_info && current_row.event_type_info?.name !== "visio_conference" ?
               <Form.Group>
                      <Form.Label>{t("location")}</Form.Label>
                      <Form.Control as={"input"}
                                    disabled={true}
                                    defaultValue={current_row?.location}
                          type={"text"}/>
              </Form.Group>
              :""
}
              </Col>
                  { current_row.attendees && current_row.attendees.length > 0 ?
                      <Col sm={12}>
                          <Form.Label className={"text-uppercase mt-4"}>{t("participants")}</Form.Label> <br/>
                          {
                              current_row.attendees.map(p => {
                                  return  current_row.attendees.length > 1 ? <span
                                      className={"badge badge-light ml-2"}> {p.lastname + ' ' + p.firstname}
                                      ({p.position ? p.position.title : ""})</span>
                             : <span
                                      className={"badge badge-light"}> {p.lastname + ' ' + p.firstname}
                                      ({p.position ? p.position.title : ""})</span>
                              })
                          }
                      </Col>
                      : ""
                  }

{current_row.event_type_info && current_row.target_id && current_row.target_id.length > 0 && props.rwState.formations && props.rwState.formations.length > 0  && current_row.event_type_info?.name === "formation" ?
                      <Col sm={"12"}>
                          <Form.Label className={"text-uppercase mt-4"}>{current_row.event_type_info?.name + "(s)"}  {t("assigned_")}</Form.Label> <br/>
                          <small>{t("see_formation_details")}</small> <br></br>  
                          {
                            
                              current_row.target_id.map(p => {
                                  return current_row.target_id.length > 1 ? <a href="#" 
                                   onClick={e=> history.push({pathname:'/dash/courses/select/'+p._id, id:p._id})} 
                                             className={"badge badge-success ml-2"}> {props.rwState.formations.find(r=>r._id===p._id)?.name}
                                      </a> : <a href="#" 
                                   onClick={e=> history.push({pathname:'/dash/courses/select/'+p._id, id:p._id})} 
                                             className={"badge badge-success"}> {props.rwState.formations.find(r=>r._id===p._id)?.name}
                                      </a>
                              })
                          }
                         </Col>
                      : ""
                  }
             
                  {current_row.event_type_info && props.rwState.surveys && current_row.target_id && current_row.target_id.length > 0 && props.rwState.surveys.length > 0  && (current_row.event_type_info?.name === "sondage" || 'survey') ?
                      <Col sm={"12"}>
                      <Form.Label className={"text-uppercase mt-4"}>{current_row.event_type_info?.name + "(s)"}  {t("assigned_")}</Form.Label> <br/>
                      {
                          current_row.target_id.map(p => {
                              return current_row.target_id.length > 1 ? <a href="#" onClick={e=> history.push({pathname:'/surveys/response/'+(p._id ? p._id : p), id:(p._id ? p._id : p)})}
                                         className={"badge badge-success ml-3"}> {props.rwState.surveys.find(r=>r._id=== (p._id ? p._id : p))?.title}
                                  </a> :
                                   <a href="#" onClick={e=> history.push({pathname:'/surveys/response/'+(p._id ? p._id : p), id:(p._id ? p._id : p)})}
                                         className={"badge badge-success"}> {props.rwState.surveys.find(r=>r._id===(p._id ? p._id : p))?.title}
                                  </a>
                          })
                      }
                     </Col>
                      : ""
                  }

            {current_row.event_type_info   && current_row.event_type_info?.name === "visio_conference" ?
                      <Col sm={"12"} className={"text-center mt-5"}>
                       <a href={current_row.location}
                                         className={"btn btn-success btn-sm"}> {t("join_conf")}
                     </a>
                     </Col>
                      : ""
                  }
              </Row>
          </Modal.Body>
          <Modal.Footer className={'border-0 pt-0'}>
              <Button variant="light" onClick={handleClose}>
                  {t("close")}
              </Button>
          </Modal.Footer>
      </Modal>

   <Modal centered show={show === 'create_type_event'} onHide={handleClose} backdrop="static" size={"md"}>
          <Modal.Header close className={"bg-primary"}>
              <h4 className={"col-lg-12 text-white text-center"}>{t("create_event_type")}</h4>
          </Modal.Header>
          <Modal.Body className={'p-4'}>
              <Row>
          <Col sm={12}>
              <Form.Group>
                      <Form.Label>{t("title")}  <span className={'required_box'}>*</span></Form.Label>
                      <Form.Control
                          onChange={handleName}
                          defaultValue={name}
                          type={"text"}
                             className={(formError && formError.name && formError.name.errors && !formError.name.formIsValid) ? 'inputValidationError' : ''}
                        />
                               {(formError && formError.name && formError.name.errors && !formError.name.formIsValid) ? <span className={"validationError"}>{formError.name.errors.name}</span> : ''}
               </Form.Group>
          </Col>
             <Col sm={12}>
              <Form.Group>
                      <Form.Label>{t("description")}</Form.Label>
                      <Form.Control as="textarea" rows={3}
                          onChange={handleDescription}
                          defaultValue={description}
                          type={"text"}/>
              </Form.Group>
              </Col>
               </Row>
          </Modal.Body>
          <Modal.Footer className={'border-0 pt-0'}>
              <Button variant="light" onClick={()=>{setShow('list_type_event')}}>
                  {t("close")}
              </Button>
           <Button variant="primary" disabled={!formError?.name?.formIsValid} onClick={createEventType}>
                  {t("save")}
              </Button>
          </Modal.Footer>
      </Modal>
        {/*liste event type*/}
 <Modal centered show={show === 'list_type_event'} onHide={handleClose} backdrop="static" size={"xl"}>
          <Modal.Body className={'p-4'}>
              <Row>
          <Col sm={12}>
              <div className={"col-12 text-right mb-4"}>
              <Button hidden={role.toLowerCase().includes('rh')} onClick={()=>{setShow('create_type_event')}}>Créer un Type d'évènement</Button> &nbsp;&nbsp;&nbsp;
              </div>
              <h4 className={"col-lg-12 text-left"} >{t("list_event_type")}</h4>
              <div className="col-12">
              <DataTable
                  noHeader
                  columns={typeEventColumns}
                  data={props.rwState.event_types}
              />
              </div>

          </Col>

               </Row>
          </Modal.Body>
          <Modal.Footer className={'border-0 pt-0'}>
              <Button variant="light" onClick={handleClose}>
                  {t("close")}
              </Button>
          </Modal.Footer>
      </Modal>
        {/*delete confirmation */}
        <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("confirmation")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={"text-center"}>
                    {t("confirme_irreversile_deletion_text")}
                </div>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>
                        <Button variant="primary" onClick={confirmDeleteAction}>
                            {t("yes_delete")}
                        </Button>
                        <Button className={"ml-3"}  variant="light" onClick={() => setShow(close)}>
                            {t("cancel")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>

    </div>
}

export default connect(mapStateToProps, mapDispatchToProps)(MyCalendar);
