import axios from "axios";
import {DeviceUUID} from "device-uuid"
import env from "../env";
axios.defaults.withCredentials = true;
var fileDownload = require('js-file-download');

// localStorage.clear();
// console.log('user in session',window.localStorage.getItem('glowhr_user'))
let session = JSON.parse(window.localStorage.getItem('glowhr_user')) || {}
let role = session.user? session.user.role_info.name:"";
//axios interceptors
axios.interceptors.request.use(function (config) {
    //config.headers.deviceUUID = new DeviceUUID().get();
    if (session) {
        config.headers = {
            'Content-Type': 'application/json',
            Authorization: session.authorization_key,
            sid: session.session_id
        };
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    console.log(error.response)
    if (error.toString().includes('401')) {
        window.localStorage.removeItem('glowhr_user')
        window.location = '/auth'
    }
    return Promise.reject(error);
});

//actions
export function action_addArticle(payload) {
    return function (dispatch) {
        dispatch({type: "ADD_ARTICLE", payload})

        return {'ok': 'ok'}
    }
}

export function action_doSomething(payload) {
    return {type: "DO_SOMETHING", payload}
}

export function action_setTitle(payload) {
    return {type: "SECTITLE", payload}
}

export function action_getData() {
    return function (dispatch) {
        return axios.get('https://jsonplaceholder.typicode.com/posts')
            .then(json => {
                dispatch({type: "DATA_LOADED", payload: json});
                //window.location = 'https://google.com';
            });
    };
}

export function action_logout() {

}

export function action_updateCompanyData(data) {
    data['sio_channel'] = 'updatecompanyAbonnement_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.companyAbonnement, data)
    }
}
export function action_listCompanyAbonnement() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listCompanyAbonnement + '?sio_channel=listCompanyAbonnement_' + session.session_id)
    }
}
export function action_updateUserData(user) {
    user['sio_channel'] = 'updateUserData_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.updateUserData, user)
    }
}
export function action_createBranch(branch) {
    branch['sio_channel'] = 'newBranch_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createBranch, branch)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listBranch + '?page=1&limit=50&sio_channel=listBranches_' + session.session_id)
            });
    };
}
export function action_createExpenseType(typeDepense) {
    console.log(typeDepense);
    typeDepense['sio_channel'] = 'newTypeDepense_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createTypeDepense, typeDepense)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listTypeDepense + '?page=1&limit=50&sio_channel=listTypeDepense_' + session.session_id)
            });
    };
}
export function action_updateExpenseType(typeDepense) {
    console.log(typeDepense);
    typeDepense['sio_channel'] = 'updateTypeDepense_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateTypeDepense, typeDepense)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listTypeDepense + '?page=1&limit=50&sio_channel=listTypeDepense_' + session.session_id)
            });
    };
}


// notifications actions *************************************
export function action_listNotification() {
    return function (dispatch) {
        return  axios.get(env.DEV.apiUrl + env.urls.listNotification + '?sio_channel=listNotification_' + session.session_id)
    };
}
export function action_zulip_url() {
    return function (dispatch) {
        return  axios.get(env.DEV.apiUrl + env.urls.zulip_url + '?sio_channel=zulip_url_' + session.session_id)
    };
}

export function action_updateNotification(notifications) {
    notifications['sio_channel'] = 'updateNotifications_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateNotifications, notifications)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listNotification + '?sio_channel=listNotification_' + session.session_id)
            });
    };
}
// parameters actions *************************************
export function action_listParameters() {
    return function (dispatch) {
        return  axios.get(env.DEV.apiUrl + env.urls.listParameters + '?sio_channel=listParameters_' + session.session_id+'&page=1&limit=50')
    };
}

export function action_updateParameters(parameters) {
    parameters['sio_channel'] = 'updateParameters_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateParameters, parameters)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listParameters + '?page=1&limit=50&sio_channel=listParameters_' + session.session_id)
            });
    };
}


//***********************************************
export function action_hiredEmployee(datas) {
    datas['sio_channel'] = 'hiredEmployee_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.hiredEmployee, datas)
            .then(json => {
               axios.get(env.DEV.apiUrl + env.urls.listColleagues + '?page=1&limit=50&sio_channel=listColleagues_' + session.session_id)
            });
    };
}
export function action_simpleUpdate(datas) {
    datas['sio_channel'] = 'updateEmployee_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.simpleUpdate, datas)
    };
}
export function action_changeBirthdayStatus(datas) {
    datas['sio_channel'] = 'changeBirthdayStatus_' + session.session_id;
    return function (dispatch) {
        return axios.patch(env.DEV.apiUrl + env.urls.employee_birthday_status, datas)

    };
}

// depart actions *************************************
export function action_listDepart(depart) {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listDepart + '?page=1&limit=50&sio_channel=listDepart_' + session.session_id)
    }
}

export function action_createDepart(depart) {
    depart['sio_channel'] = 'newDepart_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createDepart, depart)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listDepart + '?page=1&limit=50&sio_channel=listDepart_' + session.session_id)
            });
    };

}
export function action_updateDepart(departs) {
    departs['sio_channel'] = 'updateDepart_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateDepart, departs)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listDepart + '?page=1&limit=50&sio_channel=listDepart_' + session.session_id)
            });
    };
}

export function action_updateStatusDepart(departs) {
    console.log(departs);
    departs['sio_channel'] = 'updateDepartStatus_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateDepart, departs)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listDepart + '?page=1&limit=50&sio_channel=listDepart_' + session.session_id)
            });
    };
}

export function action_deleteDepart(departs) {
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteDepart+departs._id+'?sio_channel=deleteDepart_' + session.session_id, departs)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listDepart + '?page=1&limit=50&sio_channel=listDepart_' + session.session_id)
            });
    };
}

// Newsletters actions ************************************
export function action_createNewsletters(newsletters) {
    newsletters['sio_channel'] = 'newNewsletters_' + session.session_id
    console.log(newsletters,'********')
    // news=JSON.stringify(news);
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createNewsletters, newsletters)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listNewsletters + '?page=1&limit=50&sio_channel=listNewsletters_' + session.session_id)
            });
    };
}
export function action_changeNewslettersStatus(newsletters) {
    newsletters['sio_channel'] = 'changeNewslettersStatus_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.changeNewslettersStatus, newsletters)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listNewsletters + '?page=1&limit=50&sio_channel=listNewsletters_' + session.session_id)
            });
    };
}
export function action_updateNewsletters(newsletters) {
    newsletters['sio_channel'] = 'updateNewsletters_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.updateNewsletters, newsletters)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listNewsletters + '?page=1&limit=50&sio_channel=listNewsletters_' + session.session_id)
            });
    };
}
export function action_deleteNewsletters(newsletters) {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.deleteNewsletters+newsletters._id+'?sio_channel=deleteNewsletters_' + session.session_id, newsletters)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listNewsletters + '?page=1&limit=50&sio_channel=listNewsletters_' + session.session_id+'&newsletters='+newsletters._id)
            });
    };
}
export function action_listNewsletters() {
    return function (dispatch) {
                axios.get(env.DEV.apiUrl + env.urls.listNewsletters + '?sio_channel=listNewsletters_' + session.session_id)
    };
}
// *********************************************************

// Actualités actions ************************************
export function action_createNews(news) {
    news['sio_channel'] = 'newNews_' + session.session_id
    console.log(news,'********')
    // news=JSON.stringify(news);
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createNews, news)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listNews + '?page=1&limit=50&sio_channel=listNews_' + session.session_id)
            });
    };
}
export function action_changeNewsStatus(news) {
    news['sio_channel'] = 'changeNewsStatus_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.changeNewsStatus, news)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listNews + '?page=1&limit=50&sio_channel=listNews_' + session.session_id)
            });
    };
}
export function action_updateNews(news) {
    news['sio_channel'] = 'updateNews_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.updateNews, news)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listNews + '?page=1&limit=50&sio_channel=listNews_' + session.session_id)
            });
    };
}
export function action_deleteNews(news) {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.deleteNews+news._id+'?sio_channel=deleteNews_' + session.session_id, news)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listNews + '?page=1&limit=50&sio_channel=listNews_' + session.session_id+'&news='+news._id)
            });
    };
}
export function action_listNews(news) {
    return function (dispatch) {
                axios.get(env.DEV.apiUrl + env.urls.listNews + '?sio_channel=listNews_' + session.session_id)
    };
}
// *********************************************************

// formation actions *************************************
export function action_userListFormation() {
    return function () {
        return axios.get(env.DEV.apiUrl + env.urls.userFormation + '?sio_channel=userListFormation_' + session.session_id)
    }
}
export function action_createFormation(formation) {
    formation['sio_channel'] = 'newFormation_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createFormation, formation)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listFormation + '?page=1&limit=50&sio_channel=listFormation_' + session.session_id)
            });
    };
}
export function action_changeStatus(formation) {
    formation['sio_channel'] = 'changeFormationStatus_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.changeFormationStatus, formation)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listFormation + '?page=1&limit=50&sio_channel=listFormation_' + session.session_id)
            });
    };
}
export function action_updateFormation(formations) {
    console.log(formations);
    formations['sio_channel'] = 'updateFormation_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.updateFormation, formations)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listFormation + '?page=1&limit=50&sio_channel=listFormation_' + session.session_id)
            });
    };
}

export function action_deleteFormation(formations) {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.deleteFormation+formations._id+'?sio_channel=deleteFormation_' + session.session_id, formations)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listFormation + '?page=1&limit=50&sio_channel=listFormation_' + session.session_id+'&formation='+formations.formation_info)
            });
    };
}
// module formation actions *************************************
export function action_createModuleFormation(formation) {
    formation['sio_channel'] = 'newModuleFormation_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createModuleFormation, formation)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listModuleFormation + '?page=1&limit=50&sio_channel=listModuleFormation_' + session.session_id+'&formation='+formation.formation_info)
            });
    };
}
export function action_userHasFinishModuleFormation(formation) {
    formation['sio_channel'] = 'userHasFinishModuleFormation_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.userHasFinishModuleFormation, formation)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.getModuleFormation+formation.module_id+'?sio_channel=getModuleFormation_' + session.session_id);
            });
    };
}
export function action_updateModuleFormation(formations) {
    console.log(formations);
    formations['sio_channel'] = 'updateFormation_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.updateModuleFormation, formations)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listModuleFormation + '?page=1&limit=50&sio_channel=listModuleFormation_' + session.session_id+'&formation='+formations.formation_info)
            });
    };
}


export function action_deleteModuleFormation(formations) {
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteModuleFormation+formations._id+'?sio_channel=deleteModuleFormation_' + session.session_id)
            .then(json => {
                 axios.get(env.DEV.apiUrl + env.urls.listModuleFormation + '?sio_channel=listModuleFormation_' + session.session_id+'&formation='+formations.formation_info+'&page=1&limit=50')
            });
    };
}
export function action_getModuleFormation(id) {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.getModuleFormation+id+'?sio_channel=getModuleFormation_' + session.session_id);
    };
}
// parcours formation actions *************************************
export function action_createParcoursFormation(formation) {
    console.log(formation);
    formation['sio_channel'] = 'newParcoursFormation_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createParcoursFormation, formation)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listParcoursFormation + '?page=1&limit=50&sio_channel=listParcoursFormation_' + session.session_id+'&formation='+formation.formation_info)
            });
    };
}
export function action_updateParcoursFormation(formations) {
    console.log(formations);
    formations['sio_channel'] = 'updateParcoursFormation_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.updateParcoursFormation, formations)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listParcoursFormation + '?page=1&limit=50&sio_channel=listParcoursFormation_' + session.session_id+'&formation='+formations.formation_info)
            });
    };
}

// tests formation actions *************************************
export function action_createTestsFormation(formation) {
    console.log(formation);
    formation['sio_channel'] = 'newTestsFormation_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createTestsFormation, formation)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listTestsFormation + '?page=1&limit=50&sio_channel=listTestsFormation_' + session.session_id+'&formation='+formation.formation_info)
            });
    };
}export function action_createTestsDoneFormation(formation) {
    formation['sio_channel'] = 'newTestsDoneFormation_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createTestsDoneFormation, formation)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listTestsFormation + '?page=1&limit=50&sio_channel=listTestsFormation_' + session.session_id+'&formation='+formation.formation_info)
            });
    };
}
export function action_updateTestsFormation(formations) {
    console.log(formations);
    formations['sio_channel'] = 'updateTestsFormation_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.updateTestsFormation, formations)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listTestsFormation + '?sio_channel=listTestsFormation_' + session.session_id+'&formation='+formations.formation_info+'&page=1&limit=50')
            });
    };
}
export function action_deleteTestsFormation(formations) {
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteTestsFormation+formations._id+'?sio_channel=deleteTestsFormation_' + session.session_id, formations)
            .then(json => {
               axios.get(env.DEV.apiUrl + env.urls.listTestsFormation + '?sio_channel=listTestsFormation_' + session.session_id+'&formation='+formations.formation_info+'&page=1&limit=50')
            });
    };
}

// assign formation actions *************************************
export function action_createAssignFormation(formation) {
    console.log(formation);
    formation['sio_channel'] = 'newAssignFormation_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createAssignFormation, formation)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listFormation + '?page=1&limit=50&sio_channel=listFormation_' + session.session_id)
            });
    };
}
export function action_deleteAssignFormation(id) {
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteAssignFormation+id+'?sio_channel=deleteAssignFormation_' + session.session_id)
            .then(json => {
               axios.get(env.DEV.apiUrl + env.urls.listFormation + '?page=1&limit=50&sio_channel=listFormation_' + session.session_id)
            });
    };
}



// Event actions *************************************
export function action_listEvent(){
    return function (dispatch) {
        console.log('has send request now to user')
        return axios.get(env.DEV.apiUrl + env.urls.listEvent + '?sio_channel=listEvent_' + session.session_id)
    };
}
export function action_listPersonalEvent(start_date,end_date){
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.get_calendar_event + '?sio_channel=get_event_list_' + session.session_id + "&page=1&limit=50&start_date="+start_date+"end_date="+end_date)
    };
}
export function action_createEvent(event) {
    event['sio_channel'] = 'newEvent_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createEvent, event)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listEvent + '?sio_channel=listEvent_' + session.session_id)
            });
    }
}

export function action_EventFilter(event) {
    console.log(event);
    event['sio_channel'] = 'filterEvent_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.filterEvent, event)
    }
}

export function action_updateEvent(event) {
    console.log(event);
    event['sio_channel'] = 'updateEvent_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateEvent, event)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listEvent + '?sio_channel=listEvent_' + session.session_id)
            });
    };
}
export function action_deleteEvent(event) {
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteEvent+event._id+'?sio_channel=deleteEvent_' + session.session_id)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listEvent + '?sio_channel=listEvent_' + session.session_id)
            });
    };
}

// eventType actions *************************************
export function action_listEventType(){
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listEventType + '?sio_channel=listEventType_' + session.session_id + "&page=1&limit=50")
    };
}
export function action_createEventType(eventType) {
    console.log(eventType);
    eventType['sio_channel'] = 'newEventType_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createEventType, eventType)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listEventType + '?page=1&limit=50&sio_channel=listEventType_' + session.session_id)
            });
    }
};
export function action_updateEventType(eventType) {
    console.log(eventType);
    eventType['sio_channel'] = 'updateEventType_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateEventType, eventType)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listEventType + '?sio_channel=listEventType_' + session.session_id)
            });
    };
}
export function action_deleteEventType(eventType) {
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteEventType+eventType._id+'?sio_channel=deleteEventType_' + session.session_id)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listEventType + '?sio_channel=listEventType_' + session.session_id)
            });
    };
}
// surveys actions *************************************
export function action_createSurvey(surveys) {
    surveys['sio_channel'] = 'newSurvey_' + session.session_id;
    let list_chnel = surveys.category === "standard"? "listSurvey_" : "listInterviews_";
    console.log(list_chnel,'****************** liste chanel')
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createSurvey, surveys)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listSurvey + '?sio_channel='+list_chnel + session.session_id+"&category="+surveys.category)
            });
    };
}
export function action_submitSurvey(datas) {
    datas['sio_channel'] = 'submitSurvey_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.submitSurvey, datas)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listSurvey + '?page=1&limit=50&sio_channel=listSurvey_' + session.session_id)
            });
    };
}
export function action_updateSurvey(datas) {
    datas['sio_channel'] = 'updateSurvey_' + session.session_id;
    let list_chnel = datas.category==="standard"?"listSurvey_":"listInterviews_";
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateSurvey, datas)
            .then(json => {
                 axios.get(env.DEV.apiUrl + env.urls.listSurvey + '?sio_channel='+list_chnel + session.session_id + "&page=1&limit=50&category="+datas.category)
            });
    };
}
export function action_assignSurvey(datas) {
    datas['sio_channel'] = 'assignSurvey_' + session.session_id
    let list_chnel = datas.category==="standard"?"listSurvey_":"listInterviews_";
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.assignSurvey, datas)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listSurvey + '?page=1&limit=50&sio_channel='+list_chnel + session.session_id+'&category='+datas.category)
            });
    };
}
export function action_changeSurveyStatus(datas) {
    datas['sio_channel'] = 'changeSurveyStatus_' + session.session_id;
    let list_chnel = datas.category==="standard"?"listSurvey_":"listInterviews_";
    return function (dispatch) {
        return axios.patch(env.DEV.apiUrl + env.urls.changeSurveyStatus, datas)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listSurvey + '?page=1&limit=50&sio_channel='+list_chnel + session.session_id+'&category='+datas.category)
            });
    };
}
export function action_reviveSurvey(datas) {
    datas['sio_channel'] = 'reviveSurvey_' + session.session_id;
    let list_chnel = datas.category==="standard"?"listSurvey_":"listInterviews_";
    return function (dispatch) {
        return axios.patch(env.DEV.apiUrl + env.urls.reviveSurvey, datas)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listSurvey + '?page=1&limit=50&sio_channel='+list_chnel + session.session_id+'&category='+datas.category)
            });
    };
}
// surveys actions *************************************
export function action_createGoal(surveys) {
    console.log(surveys);
    surveys['sio_channel'] = 'newGoal_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createGoal, surveys)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listGoals + '?page=1&limit=50&sio_channel=listGoals_' + session.session_id+"&category="+surveys.category)
            });
    };
}
export function action_appreciateGoal(datas) {
    datas['sio_channel'] = 'appreciateGoal_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.appreciateGoal, datas)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.getOneGoal+datas.goal_id + '?sio_channel=oneGoal_' + session.session_id)
            });
    };
}
export function action_updateGoal(datas) {
    datas['sio_channel'] = 'updateGoal_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateGoal, datas)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listGoals + '?page=1&limit=50&sio_channel=listGoals_' + session.session_id)
            });
    };
}
export function action_assignGoal(datas) {
    datas['sio_channel'] = 'assignGoal_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.assignGoal, datas)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.getOneGoal+datas.id + '?sio_channel=oneGoal_' + session.session_id)
            });
    };
}
export function action_removeGoalEmployee(datas) {
    datas['sio_channel'] = 'removeGoalEmployee_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.removeEmpGoal, datas)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.getOneGoal+datas.goal_id + '?sio_channel=oneGoal_' + session.session_id)
            });
    };
}
export function action_deleteGoal(id) {
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteGoal+id+"?sio_channel=deleteGoal_"+session.session_id)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listGoals + '?page=1&limit=50&sio_channel=listGoals_' + session.session_id)
            });
    };
}
export function action_deleteExpenseType(typeDepense) {
    console.log(typeDepense);
    typeDepense['sio_channel'] = 'TypeDepense_' + session.session_id
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteTypeDepense+typeDepense.id+'?&sio_channel=deleteExpenseType_' + session.session_id, typeDepense)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listTypeDepense+'?page=1&limit=50&sio_channel=listTypeDepense_' + session.session_id)
            });
    };
}

// surveys questions actions *************************************
export function action_createSurveyQuestion(survey) {
    survey['sio_channel'] = 'newSurveyQuestion_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createSurveyQuestion, survey)
            .then(json => {
                 axios.get(env.DEV.apiUrl + env.urls.oneSurvey+survey.survey_info + '?sio_channel=oneSurvey_' + session.session_id )

            });
    };
}
export function action_updateSurveyQuestion(survey) {
    survey['sio_channel'] = 'updateSurveyQuestion_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateSurveyQuestion, survey)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.oneSurvey+survey.survey_info + '?sio_channel=oneSurvey_' + session.session_id )
            });
    };
}

export function action_deleteSurveyQuestion(survey) {
    console.log({survey})
    survey['sio_channel'] = 'deleteSurveyQuestion_' + session.session_id
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteSurveyQuestion+survey._id+'?&sio_channel=deleteSurveyQuestion_' + session.session_id)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.oneSurvey+survey.survey_info + '?sio_channel=oneSurvey_' + session.session_id )
            });
    };
}
export function action_deleteSurvey(id,category) {
    let list_chnel = category==="standard"?"listSurvey_":"listInterviews_";

    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteSurvey+id+'?&sio_channel=deleteSurvey_' + session.session_id)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listSurvey+'?page=1&limit=50&sio_channel='+list_chnel + session.session_id+"&category="+category)
            });
    };
}
export function action_updatePosition(position) {
    console.log(position);
    position['sio_channel'] = 'updateposition_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updatePosition, position)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listPositions + '?page=1&limit=50&sio_channel=listPositions_' + session.session_id)
            });
    };
}

export function action_deletePosition(position) {
    console.log(position);
    position['sio_channel'] = 'deletePosition_' + session.session_id
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deletePosition+position.id+'?&sio_channel=deletePosition_' + session.session_id, position)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listPositions + '?page=1&limit=50&sio_channel=listPositions_' + session.session_id)
            });
    };
}
export function action_updateBranch(branch) {
    console.log(branch);
    branch['sio_channel'] = 'updatebranch_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateBranch, branch)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listBranch + '?page=1&limit=50&sio_channel=listBranches_' + session.session_id)
            });
    };
}

export function action_deleteBranch(branch) {
    console.log(branch);
    branch['sio_channel'] = 'deletebranch_' + session.session_id
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteBranch+branch.id+'?&sio_channel=deletebranch_' + session.session_id, branch)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listBranch + '?page=1&limit=50&sio_channel=listBranches_' + session.session_id)
            });
    };
}
export function action_deleteSetting(setting) {
    console.log(setting);
    setting['sio_channel'] = 'deleteSetting_' + session.session_id
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteSettings+setting.id+'?&sio_channel=deleteSetting_' + session.session_id, setting)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listSettings + '?page=1&limit=50&sio_channel=listSettings_' + session.session_id)
            });
    };
}
//documents


export function action_updateDocument(document) {
    console.log(document);
    document['sio_channel'] = 'updatedocument_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateDocument, document)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listDocument + '?page=1&limit=50&sio_channel=listDocuments_' + session.session_id)
            });
    };
}

export function action_deleteDocument(document) {
    console.log(document);
    document['sio_channel'] = 'deletedocument_' + session.session_id
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteDocument+document.id+'?&sio_channel=deletedocument_' + session.session_id, document)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listDocument + '?page=1&limit=50&sio_channel=listDocuments_' + session.session_id)
            });
    };
}

export function action_createDocument(document) {
    document['sio_channel'] = 'newDocument_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createDocument, document)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listDocument + '?page=1&limit=50&sio_channel=listDocuments_' + session.session_id)
                //dispatch({type: "DATA_LOADED", payload: json});
            });
    };
}


// Departement
export function action_updateDepartement(departement) {
    console.log(departement);
    departement['sio_channel'] = 'updatedepartement_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateDepartement, departement)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listDepartment + '?page=1&limit=50&sio_channel=listDepartments_' + session.session_id)
            });
    };
}

export function action_deleteDepartement(departement) {
    console.log(departement);
    departement['sio_channel'] = 'deletedepartement_' + session.session_id
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteDepartement+departement.id+'?&sio_channel=deletedepartement_' + session.session_id, departement)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listDepartment + '?page=1&limit=50&sio_channel=listDepartments_' + session.session_id)
            });
    };
}

export function action_createDepartment(department) {
    department['sio_channel'] = 'newDepartment_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createDepartment, department)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listDepartment + '?page=1&limit=50&sio_channel=listDepartments_' + session.session_id)
                //dispatch({type: "DATA_LOADED", payload: json});
            });
    };
}

export function action_createPosition(position) {
    position['sio_channel'] = 'newPosition_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createPosition, position)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listPositions + '?page=1&limit=50&sio_channel=listPositions_' + session.session_id)
                dispatch({type: "DATA_LOADED", payload: json});
            });
    };
}

export function action_createPost(post) {
    post['sio_channel'] = 'newPost_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createPost, post)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listPostNoPaginate + '?&page=1&limit=50&sio_channel=listPostNoPaginate_' + session.session_id)
            });
    };
}

export function action_updatePost(post) {
    post['sio_channel'] = 'updatePost_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updatePost, post)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listPostNoPaginate + '?&page=1&limit=50&sio_channel=listPostNoPaginate_' + session.session_id)
            });
    };
}
export function action_deletePost(id) {
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deletePost+id+ '?sio_channel=deletePost_' + session.session_id)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listPostNoPaginate + '?sio_channel=listPostNoPaginate_' + session.session_id)
            });
    };
}
export function action_changePostState(datas) {
    datas['sio_channel'] = "posteChangeState_";
    return function (dispatch) {
        return axios.patch(env.DEV.apiUrl + env.urls.postChangeState,datas)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listPostNoPaginate + '?sio_channel=listPostNoPaginate_' + session.session_id)
            });
    };
}

export function action_createColleague(colleague) {
    colleague['sio_channel'] = 'newColleague_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createColleague, colleague)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listColleagues + '?page=1&limit=50&sio_channel=listColleagues_' + session.session_id)
                //dispatch({type: "DATA_LOADED", payload: json});
            });
    };
}


export function action_createEmployeeExcel(colleague) {
    colleague['sio_channel'] = 'newColleague_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createColleagueExcel, colleague)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listColleagues + '?page=1&limit=50&sio_channel=listColleagues_' + session.session_id)
                //dispatch({type: "DATA_LOADED", payload: json});
            });
    };
}


export function action_exportEmployeeExcel() {
    //colleague['sio_channel'] = 'newColleague_' + session.session_id
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.exportColleagueExcel,{
            responseType: 'blob',
          })
            .then(json => {
                fileDownload(json,'collegues-lists.xlsx')
            });
    };
}


export function action_sendLinkToColleague(colleague) {
    colleague['sio_channel'] = 'linkToColleague_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.sendLinkToColleague, colleague).then(r=>{
            axios.get(env.DEV.apiUrl + env.urls.listColleagues + '?page=1&limit=50&sio_channel=listColleagues_' + session.session_id)
        })
    };
}


export function action_updateColleague(colleague) {
    colleague['sio_channel'] = 'updateColleague_' + session.session_id
    return dispatch => axios.put(env.DEV.apiUrl + env.urls.updateColleagues, colleague).then(r=>{
        axios.get(env.DEV.apiUrl + env.urls.listColleagues + '?page=1&limit=50&sio_channel=listColleagues_' + session.session_id)
    })
}

export function action_updateSetting(setting) {
    console.log(setting);
    setting['sio_channel'] = 'updateSettings_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateSetting, setting)
            .then(json => {
                //dispatch({type: "DATA_LOADED", payload: json});
                axios.get(env.DEV.apiUrl + env.urls.listSettings + '?page=1&limit=50&sio_channel=listSettings_' + session.session_id)
            });
    };
}

export function action_getCities(code) {
    console.log('code',code)
    return function (dispatch) {
        return axios.get('/cities.json')
            .then(json => {
                let cities = json.filter(c => c.country === code).sort()
                dispatch({type: "CITIES", payload: cities});
            });
    };
}

export function action_createLeaveType(leave) {
    leave['sio_channel'] = 'newLeaveType_' + session.session_id;
    console.log('leave datas',leave)
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.leaveType, leave)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.leaveType + '?sio_channel=listLeaveType_' + session.session_id)
                //dispatch({type: "DATA_LOADED", payload: json});
            });
    };
}

export function action_updateLeaveType(leave) {
    leave['sio_channel'] = 'updateLeaveType_' + session.session_id
    console.log('leave datas',leave);
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.leaveType, leave)
            .then(json => {
                console.log('response ',json)
                axios.get(env.DEV.apiUrl + env.urls.leaveType + '?sio_channel=listLeaveType_' + session.session_id)
                //dispatch({type: "DATA_LOADED", payload: json});
            }).catch((error)=>{
                console.log('request error',error)
            });
    };
}

export function action_deleteLeaveType(id) {
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.leaveType+"/"+id+ '?sio_channel=deleteLeaveType_' + session.session_id)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.leaveType + '?sio_channel=listLeaveType_' + session.session_id)
                //dispatch({type: "DATA_LOADED", payload: json});
            });
    };
}

export function action_askLeave(leave) {
    leave['sio_channel'] = 'newAskLeave_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.leave, leave)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.leave + '?&sio_channel=listMyLeave_' + session.session_id + "&start_date=2020-11-01&end_date=2021-12-31&employee="+session.user._id)
            }).catch((e)=>{
                console.log('request failed',e);
                let error = {
                    error:true,
                    message:"Oups! une erreur s'est produite, veuillez réessayer"
                };
                dispatch({ type: 'ERROR', payload: error });
                dispatch({ type: 'LOADING', payload: false });
            });
    };
}

export function action_updateAskLeave(leave) {
    leave['sio_channel'] = 'updateAskLeave_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.leave, leave)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.leave + '?&sio_channel=listMyLeave_' + session.session_id + "&start_date=2020-11-01&end_date=2021-12-31&employee="+session.user._id)
            }).catch((e)=>{
                console.log('request failed',e);
                let error = {
                    error:true,
                    message:"Oups! une erreur s'est produite, veuillez réessayer"
                };
                dispatch({ type: 'ERROR', payload: error });
                dispatch({ type: 'LOADING', payload: false });
            });
    };
}
export function action_createExpense(expense) {
    expense['sio_channel'] = 'newExpense_' + session.session_id;
    expense['employee'] = session.user._id;
    console.log("expense ",expense)
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.expenses, expense)
            .then(json => {
                return axios.get(env.DEV.apiUrl + env.urls.listExpenses +'?page=1&limit=50&sio_channel=listExpenses_' + session.session_id)
            }).catch((e)=>{
                console.log('request failed',e);
            });
    };
}
export function action_createSetting(setting) {
    setting['sio_channel'] = 'newSettings_' + session.session_id;
    setting['employee'] = session.user._id;
    console.log("setting ",setting)
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.settings, setting)
            .then(json => {
                return axios.get(env.DEV.apiUrl + env.urls.listSettings +'?page=1&limit=50&sio_channel=listSettings_' + session.session_id)
            }).catch((e)=>{
                console.log('request failed',e);
            });
    };
}

export function action_createPayroll(payroll) {
    payroll['sio_channel'] = 'newPayroll_' + session.session_id;
    return function (dispatch) {
        console.log('request sending',payroll);
        return axios.post(env.DEV.apiUrl + env.urls.payroll, payroll)
            .then(json => {
                return axios.get(env.DEV.apiUrl + env.urls.listPayroll +payroll.employee+'?sio_channel=listPayroll_' + session.session_id)
            }).catch((e)=>{
                console.log('request failed',e);
            });
    };
}
export function action_updateExpense(expense) {
    expense['sio_channel'] = 'updateExpense_' + session.session_id;
    expense['employee'] = session.user._id;
    console.log("expense ",expense);
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateExpense, expense)
            .then(json => {
                return axios.get(env.DEV.apiUrl + env.urls.listExpenses +'?page=1&limit=50&sio_channel=listSettings_' + session.session_id)
            }).catch((e)=>{
                console.log('request failed',e);
            });
    };
}
export function action_updateSettiing(setting) {
    setting['sio_channel'] = 'updateSettings_' + session.session_id;
    setting['employee'] = session.user._id;
    console.log("setting ",setting);
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateSetting, setting)
            .then(json => {
                return axios.get(env.DEV.apiUrl + env.urls.listSettings +'?page=1&limit=50&sio_channel=listExpenses_' + session.session_id)
            }).catch((e)=>{
                console.log('request failed',e);
            });
    };
}
export function action_deleteExpense(id) {
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteExpense+"/"+id+ '?sio_channel=deleteExpenses_' + session.session_id)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listExpenses +'?page=1&limit=50&sio_channel=listExpenses_' + session.session_id)
            });
    };
}
export function action_validExpense(id) {
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.validExpense+"/"+id+ '?sio_channel=validExpenses_' + session.session_id)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listExpenses +'?&page=1&limit=50&sio_channel=listExpenses_' + session.session_id)
            });
    };
}
export function action_appobateExpense(id) {
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.approbateExpense+"/"+id+ '?sio_channel=validExpenses_' + session.session_id)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listExpenses +'?&page=1&limit=50&sio_channel=listExpenses_' + session.session_id)
            });
    };
}
export function action_answerLeave(leave) {
    leave['sio_channel'] = 'approveLeave_' + session.session_id;
    return function (dispatch) {
        return axios.patch(env.DEV.apiUrl + env.urls.leave, leave)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.leave + '?&sio_channel=listMyLeave_' + session.session_id + "&start_date=2020-11-01&end_date=2021-12-31&employee="+session.user._id)
                axios.get(env.DEV.apiUrl + env.urls.leave + '?sio_channel=listLeave_' + session.session_id + "&start_date=2020-11-01&end_date=2020-12-31")
            }).catch((error)=>console.log('patch error',error));
    };
}

export function action_candidate(application) {
    application['sio_channel'] = 'candidated_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.application, application)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listPostApplicants + '?page=1&limit=50&sio_channel=listPostApplicants_' + session.session_id)
                //dispatch({type: "DATA_LOADED", payload: json});
            });
    };
}


export function action_exportCandidatureExcel() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.exportCandidatureExcel,{
            responseType: 'blob',
          })
            .then(json => {
                fileDownload(json,'candidatures-lists.xlsx')
            });
    };
}

export function action_create_candidature_excel(application) {
    application['sio_channel'] = 'candidated_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createCandidatureExcel, application)
            .then(json => {
                console.log(json,'****result')
                axios.get(env.DEV.apiUrl + env.urls.listPostApplicants + '?sio_channel=listPostApplicants_' + session.session_id)
            });
    };
}

export function action_refere(reference) {
    reference['sio_channel'] = 'newReference_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.references, reference)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.references + '?sio_channel=listReferences_' + session.session_id + "&page=1&limit=50")
                //dispatch({type: "DATA_LOADED", payload: json});
            });
    };
}

export function action_reference_status(reference) {
    reference['sio_channel'] = 'rejectReference_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.referencesReject,reference)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.references + '?sio_channel=listReferences_' + session.session_id + "&page=1&limit=50")
            });
    };
}
export function action_reference_accept(datas) {
    datas['sio_channel'] = 'acceptReference_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.referencesAccept, datas)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listPostApplicants + '?page=1&limit=50&sio_channel=listPostApplicants_' + session.session_id)
                axios.get(env.DEV.apiUrl + env.urls.references + '?sio_channel=listReferences_' + session.session_id + "&page=1&limit=50")
            });
    };
}

export function action_makeSuggestion(suggest) {
    suggest['sio_channel'] = 'newSuggestion_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.suggestions, suggest)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listSuggestions + '?sio_channel=listSuggestions_' + session.session_id + "&page=1&limit=25")
                //dispatch({type: "DATA_LOADED", payload: json});
            });
    };
}
export function action_deleteSuggestion(id) {
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteSuggestion+id+"?sio_channel=deleteSuggestion_"+session.session_id)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listSuggestions + '?sio_channel=listSuggestions_' + session.session_id + "&page=1&limit=25")
            });
    };
}
export function action_commentSuggestion(data) {
    data['sio_channel'] = 'newSuggestionComment_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.commentSuggestion, data)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listSuggestions + '?sio_channel=listSuggestions_' + session.session_id + "&page=1&limit=25")
            });
    };
}
export function action_deleteSuggestionComment(id) {
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteSuggestionComment+id+"?sio_channel=deleteSuggestionComment_"+session.session_id)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listSuggestions + '?sio_channel=listSuggestions_' + session.session_id + "&page=1&limit=25")
            });
    };
}
export function action_assignSuggestion(data) {
    data['sio_channel'] = 'assignSuggestion_' + session.session_id;
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.suggestionUpdate, data)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listSuggestions + '?sio_channel=listSuggestions_' + session.session_id)
            });
    };
}
export function action_updateSuggestion(data) {
    data['sio_channel'] = 'updateSuggestion_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.suggestionUpdate, data)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listSuggestions + '?sio_channel=listSuggestions_' + session.session_id )
            });
    };
}
export function action_createInterview(datas) {
    datas['sio_channel'] = 'newInterview_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createInterView, datas)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listInterView + '?sio_channel=listInterviews_' + session.session_id + "&page=1&limit=50")
                return axios.get(env.DEV.apiUrl + env.urls.listPostApplicants + '?page=1&limit=50&sio_channel=listPostApplicants_' + session.session_id)
            });
    };
}
export function action_addParticipantsToInterview(datas) {
    datas['sio_channel'] = 'addParticipantToInterview_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.addParticipantInterView, datas)
            .then(json => {
                return axios.get(env.DEV.apiUrl + env.urls.listInterView + '?sio_channel=listInterviews_' + session.session_id + "&page=1&limit=50")
            });
    };
}
export function action_updateInterview(datas) {
    datas['sio_channel'] = 'updateInterview_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateInterView, datas)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.listInterView + '?sio_channel=listInterviews_' + session.session_id + "&page=1&limit=50")
                return axios.get(env.DEV.apiUrl + env.urls.listPostApplicants + '?page=1&limit=50&sio_channel=listPostApplicants_' + session.session_id)
            });
    };
}
export function action_cancelInterview(id) {
    return function (dispatch) {
        return axios.patch(env.DEV.apiUrl + env.urls.cancelInterView+"/"+id+ '?sio_channel=cancelInterview_' + session.session_id)
            .then(json => {
                 axios.get(env.DEV.apiUrl + env.urls.listInterView + '?sio_channel=listInterviews_' + session.session_id + "&page=1&limit=50")
                return axios.get(env.DEV.apiUrl + env.urls.listPostApplicants + '?page=1&limit=50&sio_channel=listPostApplicants_' + session.session_id)
            });
    };
}
export function action_rejectCandidate(datas) {
    datas['sio_channel'] = 'rejectCandidate_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.applicationReject, datas)
            .then(json => {
                return axios.get(env.DEV.apiUrl + env.urls.listPostApplicants + '?page=1&limit=50&sio_channel=listPostApplicants_' + session.session_id)
            });
    };
}
export function action_engageCandidate(datas) {
    datas['sio_channel'] = 'engageCandidate_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.applicationEngage, datas)
            .then(json => {
                return axios.get(env.DEV.apiUrl + env.urls.listPostApplicants + '?page=1&limit=50&sio_channel=listPostApplicants_' + session.session_id)
            });
    };
}
export function action_loading(s) {
    return function (dispatch) {
        return dispatch({type: "LOADING", payload: s});
    };
}

export function action_getRercruitment(recruitment_id){
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.get_recruitment+recruitment_id + '?sio_channel=recruitmentApplication_' + recruitment_id)
    }
}

export function action_createPlanAmelioration(datas) {
    datas['sio_channel'] = 'newPlanAmelioration_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createPlanAmelioration, datas)
            .then(json => {
                return axios.get(env.DEV.apiUrl + env.urls.listPlanAmelioration + '?page=1&limit=50&sio_channel=listPlanAmeliorations_' + session.session_id+"&employee="+datas.employee)
            });
    };
}
export function action_updatePlanAmelioration(datas) {
    datas['sio_channel'] = 'updatePlanAmelioration_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updatePlanAmelioration, datas)
            .then(json => {
                return axios.get(env.DEV.apiUrl + env.urls.listPlanAmelioration + '?page=1&limit=50&sio_channel=listPlanAmeliorations_' + session.session_id+"&employee="+datas.employee)
            });
    };
}
export function action_changePlanAmeliorationState(datas) {
    datas['sio_channel'] = 'changePlanAmeliorationState_' + session.session_id;
    return function (dispatch) {
        return axios.patch(env.DEV.apiUrl + env.urls.changeStatePlanAmelioration,datas)
            .then(json => {
                return axios.get(env.DEV.apiUrl + env.urls.listPlanAmelioration + '?page=1&limit=50&sio_channel=listPlanAmeliorations_' + session.session_id+"&employee="+datas.employee)
            });
    };
}
export function action_deletePlanAmelioration(datas) {
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deletePlanAmelioration+datas.id+ '?sio_channel=deletePlanAmelioration_' + session.session_id)
            .then(json => {
                return axios.get(env.DEV.apiUrl + env.urls.listPlanAmelioration + '?page=1&limit=50&sio_channel=listPlanAmeliorations_' + session.session_id+"&employee="+datas.employee)
            });
    };
}

export function action_createVisioConf(datas) {
    datas['sio_channel'] = 'newVisioConf_' + session.session_id;
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.visioConf, datas)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.visioConf + '?sio_channel=listVisioConf_' + session.session_id)
            });
    };
}

export function action_updateVisioConf(datas) {
    datas['sio_channel'] = 'updateVisioConf_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.visioConf, datas)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.visioConf + '?sio_channel=listVisioConf_' + session.session_id)
            }).catch((error)=>{
                console.log('request error',error)
            });
    };
}

export function action_deleteVisioConf(id) {
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.visioConf+"/"+id+ '?sio_channel=deleteVisioConf_' + session.session_id)
            .then(json => {
                axios.get(env.DEV.apiUrl + env.urls.visioConf + '?sio_channel=listVisioConf_' + session.session_id)
            });
    };
}

export function action_listVisioConfs() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.visioConf + '?page=1&limit=50&sio_channel=listVisioConf_' + session.session_id)
    };
}
export function action_oneVisioConf(conf_id) {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.visioConf+"/"+conf_id + '?sio_channel=oneVisioConf_' + session.session_id)
    };
}

export function action_listPlanAmeliorations(employee) {
    return function (dispatch) {
        let path = employee ? "&employee="+employee: "";
                return axios.get(env.DEV.apiUrl + env.urls.listPlanAmelioration + '?page=1&limit=50&sio_channel=listPlanAmeliorations_' + session.session_id+path)
    };
}
export function action_createAvertissment(datas) {
    datas['sio_channel'] = 'newAvertissment_' + session.session_id
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.createAvertissment, datas)
            .then(json => {
                return axios.get(env.DEV.apiUrl + env.urls.listAvertissment + '?page=1&limit=50&sio_channel=listAvertissments_' + session.session_id+"&employee="+datas.employee)
            });
    };
}
export function action_updateAvertissment(datas) {
    datas['sio_channel'] = 'updateAvertissment_' + session.session_id
    return function (dispatch) {
        return axios.put(env.DEV.apiUrl + env.urls.updateAvertissment, datas)
            .then(json => {
                return axios.get(env.DEV.apiUrl + env.urls.listAvertissment + '?page=1&limit=50&sio_channel=listAvertissments_' + session.session_id+"&employee="+datas.employee)
            });
    };
}
export function action_changeAvertissmentState(datas) {
    datas['sio_channel'] = 'changeAvertissmentState_' + session.session_id;
    return function (dispatch) {
        return axios.patch(env.DEV.apiUrl + env.urls.changeStateAvertissment,datas)
            .then(json => {
                return axios.get(env.DEV.apiUrl + env.urls.listAvertissment + '?page=1&limit=50&sio_channel=listAvertissments_' + session.session_id+"&employee="+datas.employee)
            });
    };
}
export function action_deleteAvertissment(datas) {
    return function (dispatch) {
        return axios.delete(env.DEV.apiUrl + env.urls.deleteAvertissment+datas.id+ '?sio_channel=deleteAvertissment_' + session.session_id)
            .then(json => {
                return axios.get(env.DEV.apiUrl + env.urls.listAvertissment + '?page=1&limit=50&sio_channel=listAvertissments_' + session.session_id+"&employee="+datas.employee)
            });
    };
}
export function action_listAvertissments(employee) {
    return function (dispatch) {
        let path = employee ? "&employee="+employee: "";
        return axios.get(env.DEV.apiUrl + env.urls.listAvertissment + '?page=1&limit=50&sio_channel=listAvertissments_' + session.session_id+path)
    };
}

///////////////////////////////////////////////////////////listing
export function action_getOneGoals(id){
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.getOneGoal +id+ '?sio_channel=oneGoal_' + session.session_id )
    };
}
export function action_listGoals(){
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listGoals + '?sio_channel=listGoals_' + session.session_id + "&page=1&limit=50")
    };
}
export function action_listInterviews(){
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listInterView + '?sio_channel=listInterviews_' + session.session_id + "&page=1&limit=50")
    };
}
export function action_listFormations(){
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listFormation + '?sio_channel=listFormation_' + session.session_id + "&page=1&limit=50")
    };
}
export function action_listModuleFormations(formation_id){
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listModuleFormation + '?sio_channel=listModuleFormation_' + session.session_id + "&page=1&limit=50&formation="+formation_id)
    };
}

export function action_allListModuleFormations(){
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.alllistModuleFormation + '?sio_channel=allListModuleFormation_' + session.session_id + "&page=1&limit=50&formation=")
    };
}

export function action_listParcoursFormations(formation_id){
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listParcoursFormation + '?sio_channel=listParcoursFormation_' + session.session_id + "&page=1&limit=50&formation="+formation_id)
    };
}

export function action_listTestsFormations(formation_id){
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listTestsFormation + '?sio_channel=listTestsFormation_' + session.session_id + "&page=1&limit=50&formation="+formation_id)
    };
}

export function action_oneSurvey(id){
    console.log('get one survey called',id)
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.oneSurvey+id + '?sio_channel=oneSurvey_' + session.session_id )
    };
}
export function action_listSurveys(){
    console.log("********** list survey")
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listSurvey + '?sio_channel=listSurvey_' + session.session_id + "&page=1&limit=50&category=standard")
    };
}

export function action_listSurveysReview(){
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listSurvey + '?sio_channel=listSurvey_' + session.session_id + "&page=1&limit=50&category=review")
    };
}

export function action_listSurveyQuestions(id){
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listSurveyQuestion + '?sio_channel=listSurveyQuestion_' + session.session_id + "&page=1&limit=50&survey="+id)
    };
}

export function action_listBranches() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listBranch + '?page=1&limit=50&sio_channel=listBranches_' + session.session_id)
    };
}
export function action_listPayrolls() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listPayroll +'?sio_channel=listPayroll_' + session.session_id)
    };
}
export function action_Payroll(id) {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.Payroll +id +'?page=1&limit=50&sio_channel=Payroll_' + session.session_id)
    };
}
export function action_expensebyId(id) {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.expenseById +id +'?page=1&limit=50&sio_channel=Expense_' + session.session_id)
    };
}

export function action_listSetting() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listSettings + '?page=1&limit=50&sio_channel=listSettings_' + session.session_id)
    };
}

export function action_listDepartments() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listDepartment + '?page=1&limit=50&sio_channel=listDepartments_' + session.session_id)
    };
}

export function action_listDocuments() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listDocument + '?page=1&limit=50&sio_channel=listDocuments_' + session.session_id)
    };
}

export function action_listPositions() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listPositions + '?page=1&limit=50&sio_channel=listPositions_' + session.session_id)
    };
}

export function action_listPost(page) {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listPostNoPaginate + '?page='+page+'&limit=50&sio_channel=listPostNoPaginate_' + session.session_id)
    };
}
export function action_listPostNoPaginate() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listPostNoPaginate + '?limit=50&sio_channel=listPostNoPaginate_' + session.session_id)
    };
}
export function action_searchPost(datas) {
    datas['sio_channel'] = 'listPost_'+ session.session_id;
    return function (dispatch) {
        return axios.post(env.DEV.apiUrl + env.urls.searchPost ,datas )
    };
}

export function action_listColleagues() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listColleagues + '?page=1&limit=50&sio_channel=listColleagues_' + session.session_id)
    };
}

export function action_detailsColleague(user) {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.detailsColleague+user + '?sio_channel=detailsColleague_' + session.session_id)
    };
}

export function action_organigram() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.organigram + '?sio_channel=organigram_' + session.session_id)
    };
}

export function action_listEvents() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.events + '?sio_channel=myevents_' + session.session_id + "&start_date=2020-11-01&end_date=2020-12-31")
    };
}



export function action_listLeaveType() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.leaveType + '?sio_channel=listLeaveType_' + session.session_id)
    };
}

export function action_listLeave() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.leave + '?sio_channel=listLeave_' + session.session_id + "&start_date=2020-11-01&end_date=2021-12-31")
    };
}

export function action_listMyLeave() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.leave + '?sio_channel=listMyLeave_' + session.session_id + "&start_date=2020-11-01&end_date=2021-12-31&employee="+session.user._id)
    };
}

export function action_listSuggestions() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listSuggestions + '?sio_channel=listSuggestions_' + session.session_id + "&page=1&limit=25")
    };
}
export function action_listMySuggestions() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.suggestions + '?sio_channel=listMySuggestions_' + session.session_id + "&page=1&limit=25&employee="+session.user._id)
    };
}

export function action_listPostApplicants() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listPostApplicants + '?page=1&limit=50&sio_channel=listPostApplicants_' + session.session_id)
    };
}

export function action_listReferences() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.references + '?page=1&limit=50&sio_channel=listReferences_' + session.session_id)
    };
}
export function action_listTypeDepense() {
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listTypeDepense + '?page=1&limit=50&sio_channel=listTypeDepense_' + session.session_id)
    };
}

export function action_listRoles(){
    return function (dispatch) {
        return axios.get(env.DEV.apiUrl + env.urls.listRoles + '?page=1&limit=50&sio_channel=listRoles_' + session.session_id)
    }
}

export function action_listExpenses() {
    return function (dispatch) {
        //return axios.get(env.DEV.apiUrl + env.urls.listExpenses  + '?page=1&limit=50&sio_channel=listExpenses_' + session.session_id)
        return axios.get(env.DEV.apiUrl + env.urls.listExpenses +'?page=1&limit=50&sio_channel=listExpenses_' + session.session_id)

    }
}
