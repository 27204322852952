import React, {useEffect, useState} from 'react';
import env from "../../../env";
import "./Documents.css"
import { Form, Modal,Button,Col, Dropdown} from "react-bootstrap";
import {BiFilter,BiEdit, BiDownload, BiTrash ,BiLoader} from "react-icons/bi";
import {BsThreeDots} from "react-icons/bs";
import {FcDocument,FcPicture, FcVideoFile, FcSpeaker} from "react-icons/fc";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import {limitText,isAdmin,
     handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
import Select from "react-select";
import {
    getFileFromCdn,
    getFileUrl,
    uploadOnCdn
} from '../../../service';
import {toast} from "react-toastify";
import ReactPaginate from 'react-paginate';
import ReactPlayer from 'react-player/lazy'
import ReactAudioPlayer from 'react-audio-player';
import { Document , Page} from 'react-pdf/dist/esm/entry.webpack';
var fileDownload = require('js-file-download');
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}
const Documents = (props) => {
    const { t } = useTranslation();
  useEffect(() => {
    props.action_listColleagues();
    props.action_listDocuments();
    props.action_listDepartments();
    props.action_listBranches();
    props.action_listPositions();
    props.action_listRoles();
  }, []);
  let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
  let user = JSON.parse(window.localStorage.getItem('glowhr_user')).user;
 
  let sid = JSON.parse(window.localStorage.getItem('glowhr_user')).session_id;
  const q_typesDocument = [
      { label: t("document_with_format_pdf"), value: "document" },
      { label: t("video_with_format_mp4"), value: "video" },
      { label: t("audio_with_format_mp3"), value: 'audio' },
      { label: t("image_with_format"), value: 'image' },
  ];
 
  const [seletectedType, setSelectedType] = useState(q_typesDocument[0]);
  const [type, setType] = useState(seletectedType.value);

  const [title, setTitle] = useState()
  const [file, setFile] = useState(undefined)
  const [fileName, setFileName] = useState(t("no_file_choose"))
  const [restrein, setRestrein] = useState('.pdf')
  const [current_row, setCurrentRow] = useState()
  const [isUpdate, setHasUpdate] = useState(false)
  const [seletectedTypeDocument, setSelectedTypeDocument] = useState(q_typesDocument[0]);
  const [typeDocument, setTypeDocument] = useState(seletectedTypeDocument?.value);
  const [current_Document_row, setCurrentDocumentRow] = useState();
  const affectations_type =
        [{ label: t('all'), value: 'all' },
            { label: t("departments"), value: "departments"},
            { label: t('branches'), value: 'branches'},
            { label: t('positions'), value: 'positions'},
            { label: t('specific_employee'), value: 'employee'}
        ]
const [affect, setAffect] = useState(affectations_type[0])
const [assignTo, setAssignTo] = useState([])
const [attendees_target_id, setAttendeesTargetId] = useState([])
const [attendees_target, setAttendeesTarget] = useState()
const [formError, setFormError] = useState({})
const [show,setShow]= useState();
const [description, setDescription] = useState()
const [filtrer, setFilter] = useState(false)
const [documents, setDocuments] = useState(undefined)
const [titleFilter, setTitleFilter] = useState(undefined)
const [seletectedTypeDocumentFilter, setSelectedTypeDocumentFilter] = useState(q_typesDocument[0])
const [numPages, setNumPages] = useState(null);
const [pageNumber, setPageNumber] = useState(1);
const onDocumentLoadSuccess=({ numPages })=> {
        setNumPages(numPages);
    }
  const resetDocument= () => {
    setCurrentDocumentRow({})
    setTitle("");
    setSelectedTypeDocument(q_typesDocument[0]);
    setTypeDocument(seletectedTypeDocument.type)
    setDescription("");
    setHasUpdate(false);
    setShow(null)
    setFormError({})
};
  const editDocument= (Document) => {
    setCurrentDocumentRow(Document)
    setCurrentRow(Document)
    let affect=affectations_type.find(r=>r.value===Document.attendees_target)
    setAffect(affect)
    setAttendeesTargetId(Document.attendees_target_id)
    setAttendeesTarget(Document.attendees_target)
    setTitle(Document.title);
    setSelectedTypeDocument(q_typesDocument.find(t=>t.value===Document.type));
    setTypeDocument(Document.type)
    setDescription(Document.description);
    setFileName(Document.file);
    setHasUpdate(true);
    setShow('create_Document')
    let data=  handleFinaleValidation(t,[
        {type:"text",name:'title','value':Document.title,required:true},
        {type:"text",name:'typeSelectDocument','value':Document.type,required:true},
        {type:"text",name:'file','value':Document.file,required:true}
       ])
            setFormError(data)
};

useEffect(()=>{
  if(props.rwState.documents) {
        setDocuments(props.rwState.documents)
  }
},[props.rwState.documents])

const handleAffectation_type = (e) => { 
  setAssignTo([]);
  setAffect(e)
  setAttendeesTarget(e.value)
  let data =handleValidation(t,{type:"text",name:'affectationType','value':e.value,required:true});
  let form=formError;
          form[data.name]=data   
          setFormError(form)
};

const handleClose = () => {
  setShow('');
};
const handleCheckAssign = () => {
     checkAssign ? setCheckAssign(false) : setCheckAssign(true);
};
const handleSelectAssign = (e) => {
  if(e) {
      setAssignTo(e)
      setAttendeesTargetId(e.filter(res => {
          return res._id
      }))
  }else{
      setAttendeesTargetId([])
  }
};

const createDocument= async  () => {
  let  files=file;
  setFile(undefined)
let o = {
    "type": typeDocument,
    "title": title,
    "description": description,
    "attendees_target": attendees_target,
    "attendees_target_id": attendees_target_id,
    "sio_channel": "",
    "id": current_Document_row?._id
};

if(files){
         uploadOnCdn(files)
            .then(resp => {
                setFile(resp?.filename);
               o.file=resp?.filename;
                if (!isUpdate)
                    props.action_createDocument(o)
                else
                    props.action_updateDocument(o);
            })
    }else{
      if (!isUpdate)
      toast("Aucun document téléversé.",{type:"warning"})
                else
                    props.action_updateDocument(o);
    }

setShow(null)
props.action_loading(true)
resetDocument()
}

  const handleTypeDocument=(type)=>{
    let DocumentType=type;
    let restrein=".pdf";
    switch (DocumentType) {
        case 'video':
            restrein="video/*"
            break;
     case 'audio':
            restrein="audio/*"
            break;
     case 'image':
            restrein="image/*"
            break;
     case 'document':
            restrein=".pdf"
            break;
    }
    setRestrein(restrein)
}
  const handleTypeSelect = (e) => {
    //  console.log('type ==> ',e.target.value)
      setType(e.value)
      let data =handleValidation(t,{type:"text",name:'typeSelect','value':e.value,required:true});
      let form=formError;
              form[data.name]=data   
              setFormError(form)
  };
  const handleTitle = (e) => {
    setTitle(e.target.value)
    let data =handleValidation(t,{type:"text",name:'title','value':e.target.value,required:true});
    let form=formError;
            form[data.name]=data   
            setFormError(form)
};

 const handleTitleFilter = (e) => {
    setTitleFilter(e.target.value)
  
};

 const handleTypeSelectDocumentFilter = (e) => {
     (e && e.length>0) ? setSelectedTypeDocumentFilter(e.map(res=> {return res.value})) : setSelectedTypeDocumentFilter([]);
  };


 const handleTypeSelectDocument = (e) => {
    //  console.log('type ==> ',e.target.value)
      setTypeDocument(e.value)
     handleTypeDocument(e.value)
     let data =handleValidation(t,{type:"text",name:'typeSelectDocument','value':e.value,required:true});
     let form=formError;
             form[data.name]=data   
             setFormError(form)
  };
  const handleDescription = (e) => {
      setDescription(e.target.value)
  };

 const handleFile = (e) => {
     if(e) {
         let file_data = e.target.files[0]
         setFile(file_data)
         setFileName(file_data.name)
         let data =handleValidation(t,{type:"text",name:'file','value':file_data.name,required:true});
         let form=formError;
                 form[data.name]=data   
                 setFormError(form)
     }
  };
 //******************************************paginate filter data */
 useEffect(() => {
    setActiveDocumentList([])
  if(documents && documents.length > 0) {
      let mylist = documents;
      setActiveDocumentList(mylist)
  }
 }, [documents]);

/*********************************************Document Active Begin paginating */
//Document Active
const [activeDocumentList, setActiveDocumentList] = useState(undefined);
const [CurrentActiveLists, setCurrentActiveLists] = useState(undefined);
const [activePageCount, setActivePageCount] = useState(0);
const [activeItemOffset, setActiveItemOffset] = useState(0);
const [activeItemsPerPage, setActiveItemsPerPage] = useState(24);

useEffect(() => {
  if(activeDocumentList ) {
      const endOffset = activeItemOffset + activeItemsPerPage;
      setCurrentActiveLists(activeDocumentList.slice(activeItemOffset, endOffset));
      setActivePageCount(Math.ceil(activeDocumentList.length / activeItemsPerPage));
  }
 }, [activeItemOffset, activeItemsPerPage, activeDocumentList]);

 const handlePageClick = (event) => {
  const newOffset = (event.selected * activeItemsPerPage) % activeDocumentList.length;
  setActiveItemOffset(newOffset);
};
//*********************************************Document Active End paginating */

const downloadDocument = image_key => {
    getFileFromCdn(image_key)
            .then(response => {
                fileDownload(response,image_key)
            })
            .catch(err => console.log('error get from cdn : ', err))
       
}
const showDocument = image_key => {
      const url = getFileUrl(image_key),
            link = document.createElement('a');
      link.href = url
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
}



const showDocumentDetails = current_row => {
     setCurrentRow(current_row);
     setShow('showDocument');
}


const setDetails = current_row => {
     setCurrentRow(current_row);
     setShow('detailsDocument');
}
const deleteDocument = row_data => {
  setCurrentRow(row_data)
  setShow('confirm')
}
const confirmDeleteDocument = () => {
  let data=[];
   data['id'] = current_row._id
  props.action_deleteDocument(data)
  setShow(null)
  props.action_loading(true)
  setCurrentRow()
}

const closeFilter = () => {
  setDocuments(props.rwState.documents)
  setFilter(false);
}
const filterDocument = () => {
  let documents=props.rwState.documents;
  if(documents){
  if(seletectedTypeDocumentFilter && seletectedTypeDocumentFilter.length> 0){
    documents=documents.filter(res=>res.type && seletectedTypeDocumentFilter.indexOf(res.type)>=0)
  }
 if(titleFilter && titleFilter.length > 0){
    documents=documents.filter(res=>res.title && res.title.includes(titleFilter));
  }
 setDocuments(documents)
  return ;
}
}
  return <div id={"documents"}>
    {/* <iframe src={url} id={"myiFrame"} frameBorder="0" style={{width:"100%",height: "calc( 100vh - 128px )"}}>GED</iframe>
    */}
 <Col xs={"12"} lg={"12"} hidden={!isAdmin(role)}>
    <button class="btn btn-sm btn-primary" onClick={()=>setShow('create_Document')}>{t("add_document_btn")}</button>
  </Col>
  <Col xs={"12"} lg={"12"}>
  <div id="persoDocs" className={"mt-2 mb-4 col-lg-12"} >
  <Col xs={"12"} lg={"12"}>
                                             <div className={'row'}>
                                             {/* <h4 className={'col-lg-12 pt-3 pb-3'}>Liste des documents téléversés</h4> */}

                                             <div className="mt-4 mb-4 col-lg-12">
                                               <a href="#" className={"btn btn-light btn-sm"} onClick={()=>setFilter(true)} hidden={filtrer}>
                                                <BiFilter style={{color: '#240265', fontWeight: '600'}} size={25}/>
                                                 {t("filter_text")}
                                              </a> <br/>
                                              <Form.Group hidden={!filtrer}>
                                                  <div className="row">
                                                      <div className="col-12 mb-2">
                                                          <Form.Label> {t("filter_by")} : </Form.Label>
                                                      </div>
                                                      <Form.Group>
                    <Col>
                        <Form.Label> {t("type_document_text")} </Form.Label>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={q_typesDocument}
                            //defaultValue={seletectedTypeDocumentFilter}
                            styles={{
                                control: base => ({
                                    ...base,
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("type_document_text")}
                            name='type'
                            isMulti
                            onChange={handleTypeSelectDocumentFilter}
                            />
                    </Col>

                </Form.Group>
                <Form.Group>
                    <Col>
                        <Form.Label>{t("document_name_text")}</Form.Label>
                        <Form.Control
                            onChange={handleTitleFilter}
                            defaultValue={titleFilter}
                            type={"text"}
                            />
                    </Col>
                </Form.Group>
                <Form.Group>
                    <Col className="mt-4 pt-2">
                       <button className="btn btn-outline-primary btn-sm" onClick={e=>{filterDocument()}}>{t("filter_text")}</button>
                       &nbsp;&nbsp;<button className="btn btn-light btn-sm" onClick={e=>{closeFilter()}}>
                         {t("close")}
                         </button>
                       </Col>
                </Form.Group>
                     </div>
                   </Form.Group>
                      
                                            </div>

                                                {CurrentActiveLists && CurrentActiveLists.length > 0 ?
                                                    CurrentActiveLists.map((curr_doc, index) => (
                                                        <div className={' col-lg-2 mb-1'} key={index}>
                                                          <div className={'col-lg-12 docItem'}  style={{ borderRadius:'10px'}}>
                                                            <Dropdown className={"float-right manage-dropdown"}>
                        <Dropdown.Toggle variant="transparent" size={"sm"} id="dropdown-basic">
                            <BsThreeDots/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setDetails(curr_doc)}>
                                <BiLoader style={{color: 'red', fontWeight: '600',}} size={25}/> {t("assigned")}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => editDocument(curr_doc)}>
                                <BiEdit style={{color: 'orange', fontWeight: '600',}} size={25}/> {t("updated")}
                            </Dropdown.Item>
                             <Dropdown.Item onClick={() => downloadDocument(curr_doc.file)}>
                                <BiDownload style={{color: '#240265', fontWeight: '600',}} size={25}/> {t("download")}
                            </Dropdown.Item>
                        <Dropdown.Item onClick={() => deleteDocument(curr_doc)}>
                                <BiTrash style={{color: 'red', fontWeight: '600',}} size={25}/> {t("delete")}
                            </Dropdown.Item>
                       
                        </Dropdown.Menu>
                    </Dropdown>
                                                            <div className={'docIcon  pt-3'} onClick={()=>showDocumentDetails(curr_doc)} style={{ cursor:'pointer' }}>
                                                              {curr_doc.type==='document' ? 
                                                                <FcDocument size={120}   style={{color: '#240265'}}/>
                                                                : 
                                                                ''}
                                                            {curr_doc.type==='video' ? 
                                                                <FcVideoFile size={120}  style={{color: '#240265'}}/>
                                                                : 
                                                                ''}
                                                            {curr_doc.type==='image' ? 
                                                                <FcPicture size={120}  style={{color: '#240265'}}/>
                                                                : 
                                                                ''}
                                                            {curr_doc.type==='audio' ? 
                                                                <FcSpeaker size={120}  style={{color: '#240265'}}/>
                                                                : 
                                                                ''}
                                                            </div>
                                                           <div className={'text-center mb-3'} title={curr_doc.title}>{limitText(curr_doc.title,10)} </div>
                                                         </div>
                                                         </div>
                                                    ))
                                                    :
                                                    <div className={"col-12 col-lg-12 mt-5 mb-5 pb-5"}>
                                                        <div className={"alert alert-warning"}>
                                                            {t("no_data_available")}
                                                        </div>
                                                    </div>
                                                }
                                                 <Col md={12} className={"mt-3"}>
                                                 <div hidden={!CurrentActiveLists || (CurrentActiveLists && CurrentActiveLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={activePageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div></Col>
                                            </div>
                                            </Col>
                                        </div>
                                        </Col>
    <Modal centered show={show === 'create_Document'} onHide={handleClose}>
            <Modal.Header className={"bg-primary"}>
                <h4 className={"col-lg-12 text-white text-center"}>{ isUpdate ? t("updated_document_text") :t("created_document_text")}</h4>
            </Modal.Header>
            <Modal.Body className={'p-4'}>
                <Form.Group>
                    <Col>
                        <Form.Label>{t("choose_doc_type")} <span className={'required_box'}>*</span></Form.Label>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={q_typesDocument}
                            defaultValue={seletectedTypeDocument}
                            styles={{
                                control: base => ({
                                    ...base,
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("choose_doc_type")}
                            name='type'
                            onChange={handleTypeSelectDocument}
                            className={handleinputValidationError(formError,'typeSelectDocument')}
                            />
                                   {handleTextValidationError(formError,'typeSelectDocument')}
                 
                    </Col>

                </Form.Group>
                <Form.Group>
                    <Col>
                        <Form.Label>{t("define_document_title_text")}  <span className={'required_box'}>*</span></Form.Label>
                        <Form.Control
                            onChange={handleTitle}
                            defaultValue={current_Document_row?.title}
                            type={"text"}
                            className={handleinputValidationError(formError,'title')}
                            />
                                   {handleTextValidationError(formError,'title')}
                 
                    </Col>
                </Form.Group>
                <Form.Group>
                    <Col>
                        <Form.Label>{t("describe_document_text")}</Form.Label>
                        <Form.Control
                            onChange={handleDescription}
                            defaultValue={current_Document_row?.description}
                            as={"textarea"}/>
                    </Col>
                </Form.Group>
                <Form.Group>
                    <Col>
                        <Form.File id="formcheck-api-custom" custom>
                            <Form.File.Input onChange={handleFile} accept={restrein} 
                            className={handleinputValidationError(formError,'file')}
                            />
                            <Form.File.Label data-browse={t("choose_a_file")}>
                                {fileName}
                            </Form.File.Label>
                            <Form.Control.Feedback type="valid">1GB</Form.Control.Feedback>
                        </Form.File>
                            
                                   {handleTextValidationError(formError,'file')}
                    </Col>
                </Form.Group>
                <Form.Group>
                <Col>
                <Form.Label>{t("assigned_to")} </Form.Label> <br/>
                    <Form.Group>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={affectations_type}
                            defaultValue={affect}
                            styles={{
                                control: base => ({
                                    ...base,
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t('add_to')}
                            name='affect_to'
                            onChange={handleAffectation_type}
                            className={handleinputValidationError(formError,'affectationType')}
                            />
                                   {handleTextValidationError(formError,'affectationType')}
                    </Form.Group>
                    <Form.Group hidden={affect ? affect.value!=='branches'  : !affect}>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={props.rwState.branches}
                            getOptionLabel={o => o.name}
                            getOptionValue={o => o._id}
                            defaultValue={affect && affect.value==='branches' && current_row && current_row.attendees_target_id ? current_row .attendees_target_id.map(res=>{return res}) : ""}
                            styles={{
                                control: base => ({
                                    ...base,
                                    minHeight: 48,
                                    // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("branch_title_unique")}
                            isMulti
                            onChange={handleSelectAssign}
                        />
                    </Form.Group>
                    <Form.Group hidden={affect ? affect.value!=='departments'  : !affect}>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={props.rwState.departments}
                            getOptionLabel={o => o.name}
                            getOptionValue={o => o._id}
                            defaultValue={affect && affect.value==='departments' && current_row && current_row.attendees_target_id ? current_row.attendees_target_id.map(res=>{return res}) : ""}
                            styles={{
                                control: base => ({
                                    ...base,

                                    minHeight: 48,
                                    // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("department_title_unique")}
                            isMulti
                            onChange={handleSelectAssign}
                        />
                    </Form.Group>
                    <Form.Group hidden={affect ? affect.value!=='positions'  : !affect}>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={props.rwState.positions}
                            getOptionLabel={o => o.title}
                            getOptionValue={o => o._id}
                            defaultValue={affect && affect.value!=='positions' && current_row && current_row.attendees_target_id ? current_row.attendees_target_id.map(res=>{return res}) : ""}
                            styles={{
                                control: base => ({
                                    ...base,

                                    minHeight: 48,
                                    // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("position_title_unique")}
                            isMulti
                            onChange={handleSelectAssign}
                        />
                    </Form.Group>
                    <Form.Group hidden={affect ? affect.value!=='employee' : !affect}>
                        <Select noOptionsMessage={() => t("no_data_available")}
                            options={props.rwState.colleagues.filter(r=>r.activated)}
                            getOptionLabel={o => o.lastname+' '+o.firstname}
                            getOptionValue={o => o._id}
                            defaultValue={affect && affect.value==='employee' && current_row && current_row.attendees_target_id ? current_row.attendees_target_id.map(res=>{return res}) : ""}
                            styles={{
                                control: base => ({
                                    ...base,
                                    minHeight: 48,
                                })
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#6600CC',
                                    primary25: '#6600CC25',
                                    primary75: '#6600CC75',
                                    primary50: '#6600CC50'
                                },
                            })}
                            placeholder={t("employee_text")}
                            isMulti
                            onChange={handleSelectAssign}
                        />
                    </Form.Group>
                    </Col>
                </Form.Group>
               
            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
            <Col>
                <Button variant="light" onClick={handleClose}>
                    {t("cancel")}
                </Button>
                <Button variant="primary"
                //  disabled={
                //   handleDisabledBtnValidationError(formError,
                //     ['title','typeSelectDocument','file']) 
                // }
                onClick={createDocument}>
                    {isUpdate ? t("update") : t("save")}
                </Button>
                </Col>
            </Modal.Footer>
        </Modal>
      
       {/*read Module details*/}
       <Modal show={show==="showDocument"}  centered>
           
            <Modal.Body>
                <div className={"text-center"}>
                  {current_row && current_row.file ? 

 (current_row.type === 'image' ?
                        < div className={"col-12 text-center"}>
                            <img src={getFileUrl(current_row.file)} alt="" width={"100%"}/>
                            </div>
                            :
                   /*video*/
                        (current_row.type==='video' ?
                    <div className={"col-12 text-center"}>
                    <ReactPlayer url={getFileUrl(current_row.file)} controls={true} light={true} width={'100%'} />
                    </div>
                            : 
                        (current_row.type==='audio' ?
                            <div className={"col-12 text-center"}>
                            <ReactAudioPlayer src={getFileUrl(current_row.file)} autoPlay controls/>
                            </div>
                            : 
                    (current_row.type === 'document' ?
                            <div className={"col-12 text-center"}>
                                <Document
                                    className={"tdocs"}
                                    loading={t("loading_init_text")}
                                    width={"100%"}
                                    file={getFileUrl(current_row.file)}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    <Page pageNumber={pageNumber}/>
                                </Document>
                                <p className={"mt-3"}>
                                    { pageNumber>1 ?
                                        <span className={"btn btn-light btn-sm mr-2"} onClick={() => {
                                            setPageNumber((pageNumber - 1))
                                        }}>précédent</span>
                                        : ""
                                    }
                                    Page {pageNumber} of {numPages}

                                    { pageNumber < numPages ?
                                        <span className={"btn btn-light btn-sm ml-2"} onClick={() => {
                                            setPageNumber((pageNumber + 1))
                                        }}>suivant</span>
                                    : ""
                                    }
                                    </p>
                            </div>
                            :
                            ""
                       ))))
                        : ''}              
                          </div>
                          <div className={"col-lg-12 text-center mt-4"}>
                          <Button variant="light" onClick={handleClose}> {t("close")} </Button>
                          </div>
            </Modal.Body>
        </Modal>
  
   <Modal show={show==="detailsDocument"}  centered>
           
            <Modal.Body>
                <div className={"text-left"}>
                  {current_row ? 
                    <div className={"col-lg-12"}>
                      <h4>{current_row.type +': '+ current_row.title }</h4>
                      <p>{current_row.description }</p>
                      <div>Assigne à { affectations_type.find(r=> r.value===current_row.attendees_target)?.label }</div>
                    
                     {current_row.attendees_target!=='all' ? 
                      <div>
                        {
                          current_row.attendees_target==='branches' ? 
                      
                        current_row.attendees_target_id.map(r=>{
                          return <span className={"badge badge-light mr-2"}>{ r?.name}</span>;
                        }) 
                        
                      
                        : (
                          current_row.attendees_target==='departments' ? 
                         
                          current_row.attendees_target_id.map(r=>{
                            return <span className={"badge badge-light mr-2"}>{ r?.name}</span>;
                          }) 
                          
                         
                          : (
                            current_row.attendees_target==='positions' ? 
                           
                            current_row.attendees_target_id.map(r=>{
                              return <span className={"badge badge-light mr-2"}>{ r?.title}</span>;
                            }) 
                            
                           
                            : (
                              current_row.attendees_target==='employee' ? 
                            
                              current_row.attendees_target_id.map(r=>{
                                return <span className={"badge badge-light mr-2"}>{r?.firstname+' '+r?.lastname}</span>;
                              }) 
                              
                            
                              : ''
                            )
                          )
                        )
                      }
                      </div>
                      : ''}
                    </div>
                        : ''}              
                          </div>
                          <div className={"col-lg-12 text-center mt-4"}>
                          <Button variant="light" onClick={handleClose}> {t("close")} </Button>
                          </div>
            </Modal.Body>
        </Modal>

        <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("confirmation")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={"text-center"}>
                    {t("confirme_irreversile_deletion_text")}
                </div>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>
                        <Button variant="primary" onClick={confirmDeleteDocument}>
                            {t("yes_delete")}
                        </Button>
                        <Button className={"ml-3"}  variant="light" onClick={() => setShow(close)}>
                            {t("cancel")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
