import React, {useEffect, useState} from 'react';
import "./Recruitment.css"
import {Alert, Button, Col, Container, Form, Modal, Row, Tab, Dropdown} from "react-bootstrap";
import SpecialEvent from "../../widgets/SpecialEvent";
import ContextMenu from "../../menus/ContextMenu";
import Referer from "../../widgets/Referer";
import SearchPostes from "../../widgets/SearchPostes";
import Select from "react-select";
import Creatable from 'react-select/creatable';
import * as actions from "../../../actions";
import {connect} from "react-redux";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {formatDate} from "react-day-picker/moment";
import Applicant from "../../widgets/Applicant";
import Announcement from "../../widgets/Announcement";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import ReactPaginate from 'react-paginate';
import * as RI from 'react-icons/ri'
import * as BI from 'react-icons/bi'
import {isAdmin,
    handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const Recruitment = (props) => {
    const { t } = useTranslation();
    const role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;

    const contextMenu = !isAdmin(role)?
        [
            {label: t("reference"), eventKey: 'reference'},
            {label: t("active_position"), eventKey: 'search'}
        ]
        :[
        {label: t("application"), eventKey: 'list'},
        {label: t("interviews"), eventKey: 'entrevue'},
        {label: t("reference"), eventKey: 'reference'},
        {label: t("active_position"), eventKey: 'search'},
        {label: t("archived_position"), eventKey: 'archived'},
    ]
  const contextCandidatureMenu =[
        {label: t("on_waiting"), eventKey: 'c-waiting'},
        {label: t("accepted"), eventKey: 'c-accepted'},
        {label: t("rejected"), eventKey: 'c-rejected'},
    ]
    const contextReferenceMenu =[
        {label: t("on_waiting"), eventKey: 'r-waiting'},
        {label: t("accepted"), eventKey: 'r-accepted'},
        {label: t("rejected"), eventKey: 'r-rejected'},
    ]
    const [show, setShow] = useState(null)
    const [posTitle, setPosTitle] = useState()
    const [branch, setBranch] = useState([])
    const [department, setDepartment] = useState([])
    const [position, setPosition] = useState(null)
    const [contract_type, setContractType] = useState(null)
    const [postDesc, setPosDesc] = useState(null)
    const [city, setCity] = useState(null)
    const [has_motiv_letter, setHasMotivLetter] = useState(false)
    const [openDate, setOpenDate] = useState(null)
    const [tags, setTags] = useState([])
    const [current_row, setCUrrentRow] = useState(null)
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const [formError, setFormError] = useState({})
    const contract_types = [{label:t("cdd"),value:"cdd"},{label:t("cdi"),value:"cdi"},{label:t("freelance"),value:"freelance"},{label:t("part_time"),value:"part_time"},{label:t("full_time"),value:"full_time"}];
    
    const nextPage = () => {
        //setSkip(skip + limit)
    }

    const previousPage = () => {
        //setSkip(skip - limit)
    }

        useEffect(() => {
        props.action_loading(true);
        props.action_listPostApplicants();
        props.action_listPostNoPaginate()
        props.action_listReferences();
        props.action_listColleagues();
        props.action_listInterviews();
        props.action_listDepartments();
        props.action_listBranches();
        props.action_listPositions();
            props.action_setTitle(t("recuitment"))

        }, []);
    
    const handleClose = () => {
        setShow(null)
        setFormError({})
        reset();
    }


    const editPost = (row)=>{
        setPosTitle(row.title);
        setPosDesc(row.description);
        setCity(row.city);
        let tags = [];
        row.tags.forEach((tag)=>tags.push({label:tag,value:tag}));
        setTags(tags);
        setBranch(row.branches);
        setDepartment(props.rwState.departments.filter((d)=>row.departments.includes(d._id)));
        setPosition(props.rwState.positions.filter((p)=>row.position_info===p._id));
        setContractType(contract_types.filter((p)=>row.contract_type===p.value));
        setOpenDate(row.opening_date)
        setCUrrentRow(row);
        setShow('newPost')
        let data=  handleFinaleValidation(t,[
            {type:"text",name:'title','value':row.title,required:true},
            {type:"textarea",name:'postDesc','value':row.description,required:true},
            {type:"text",name:'city','value':row.city,required:true},
            {type:"text",name:'branch','value':row.branches,required:true,isArray:true},
            {type:"text",name:'department','value':row.departments,required:true,isArray:true},
            {type:"text",name:'position','value':row.position_info,required:true},
            {type:"text",name:'contractType','value':row.contract_type,required:true},
            {type:"date",name:'openDate','value':row.opening_date,required:true,min:new Date()},
           ])
                setFormError(data)
    };
    const reset = () => {
        setPosTitle('');
        setPosDesc('');
        setCity('');
       setTags('');
        setBranch([]);
        setDepartment([]);
        setPosition('');
        setContractType('');
        setOpenDate('')
        setCUrrentRow('');
    }
    const createPost = () => {
        let o = {
            "title": posTitle,
            "description": postDesc,
            "position": position,
            "branches": branch,
            "departments": department,
            "tags": tags.map(t=>t.label),
            "opening_date": openDate,
            "contract_type": contract_type.value,
            "city": city
        };
        if (current_row){
            o.id = current_row._id;
            props.action_updatePost(o);
        } else{
            props.action_createPost(o);
        }
        props.action_loading(true);
        setShow(null);
        setFormError({})
        reset();
    }
    const deletePost = (id)=>
    {
        props.action_deletePost(id);
        props.action_loading(true);
    }


    const programInterview = (datas) => {
        props.action_createInterview(datas);
        props.action_loading(true);
    }
    const cancelInterview = (id) => {
        props.action_cancelInterview(id);
        props.action_loading(true);
    }
    const addParticipants = (datas) => {
        props.action_addParticipantsToInterview(datas);
        props.action_loading(true);
        setShow(null);
    }

    const handlePosTitle = (e) => {
        setPosTitle(e.target.value)
        let data =handleValidation(t,{type:"text",name:'title','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handlePosDesc = (e) => {
        setPosDesc(e.target.value)
        let data =handleValidation(t,{type:"textarea",name:'postDesc','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleCity = (e) => {
        setCity(e.target.value)
        let data =handleValidation(t,{type:"text",name:'city','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleHasMotivLet = (e) => {
        setHasMotivLetter(!has_motiv_letter)
    }
    const handleBranch = (e) => {
        setBranch(e)
        let data =handleValidation(t,{type:"text",name:'branch','value':e,required:true,isArray:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleDepartment = (e) => {
        setDepartment(e)
        let data =handleValidation(t,{type:"text",name:'department','value':e,required:true,isArray:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handlePosition = (e) => {
        setPosition(e._id)
        let data =handleValidation(t,{type:"text",name:'position','value':e?._id,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleContractType = (e) => {
        setContractType(e)
        let data =handleValidation(t,{type:"text",name:'contractType','value':e.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleTags = (e) => {
        setTags(e)

    }
    const handleTagsInputChange = (e) => {
        // console.log(e)
    }
    const handleOpenDate = (e) => {
        setOpenDate(new Date(e))
        let data =handleValidation(t,{type:"date",name:'openDate','value':e,required:true,min:new Date()});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handlePageClick = (e) => {
        let selectedPage = page>e.selected?props.rwState.paginator.prev:props.rwState.paginator.next;
        setPage(selectedPage);
        props.action_loading(true);
        props.action_listPost(selectedPage);
    }

    const [filtrerCandidature, setFilterCandidature] = useState(false)
    const [allCandidatures, setAllCandidature] = useState(undefined)
    const [selectedPostFilter, setSelectedPostFilter] = useState(undefined)
    const handleSelectPosteFilter = (e) => {
        (e && e.length>0) ?  setSelectedPostFilter(e.map(res=> {return res._id})) : setSelectedPostFilter([]);
    };
    const closeCandidatureFilter = () => {
        setAllCandidature(props.rwState.applicants)
        setFilterCandidature(false);
    }
    const filterCandidature = () => {
        let candidatures=props.rwState.applicants;
        if(candidatures){
        if(selectedPostFilter && selectedPostFilter.length> 0){
            candidatures=candidatures.filter(res=>res.recruitment && selectedPostFilter.indexOf(res.recruitment._id) >= 0)
        }
        setAllCandidature(candidatures)
        console.log(candidatures);
        return ;
     }
    }

    useEffect(() => {
        setApplicantsList([])
        if(allCandidatures &&  allCandidatures.length > 0 &&  allCandidatures.filter(r=>r.status===1) &&  allCandidatures.filter(r=>r.status===1).length >0) {
            let mylist = allCandidatures.filter(r=>r.status===1);
            setApplicantsList(mylist)
          }
          setApplicantsWaitingList([])
          if(allCandidatures &&  allCandidatures.length > 0 &&  allCandidatures.filter(r=>r.status===0) &&  allCandidatures.filter(r=>r.status===0).length >0) {
            let mylists = allCandidatures.filter(r=>r.status===0);
            setApplicantsWaitingList(mylists)
          }
          setApplicantsRejectedList([])
          if(allCandidatures &&  allCandidatures.length > 0 &&  allCandidatures.filter(r=>r.status===-1) &&  allCandidatures.filter(r=>r.status===-1).length >0) {
            let mylistes = allCandidatures.filter(r=>r.status===-1);
            setApplicantsRejectedList(mylistes)
          }
       }, [allCandidatures]);

     useEffect(() => {
        setAllCandidature(props.rwState.applicants)
       }, [props.rwState.applicants]);
   
    /*********************************************Applicants Begin paginating */
      // Applicants
      const [applicantsList, setApplicantsList] = useState(undefined);
      const [CurrentApplicantsLists, setCurrentApplicantsLists] = useState(undefined);
      const [applicantsPageCount, setApplicantsPageCount] = useState(0);
      const [applicantsOffset, setApplicantsItemOffset] = useState(0);
      const [applicantsPerPage, setApplicantsItemsPerPage] = useState(8);

      useEffect(() => {
        if(applicantsList) {
            const endOffset = applicantsOffset + applicantsPerPage;
            setCurrentApplicantsLists(applicantsList.slice(applicantsOffset, endOffset));
            setApplicantsPageCount(Math.ceil(applicantsList.length / applicantsPerPage));
        }
       }, [applicantsOffset, applicantsPerPage, applicantsList]);
   
       const handleApplicantsPageClick = (event) => {
        const newOffset = (event.selected * applicantsPerPage) % applicantsList.length;
        setApplicantsItemOffset(newOffset);
    };
    //********************************************* Applicants End paginating */

    /*********************************************ApplicantsWaiting Begin paginating */
      // ApplicantsWaiting
      const [applicantsWaitingList, setApplicantsWaitingList] = useState(undefined);
      const [CurrentApplicantsWaitingLists, setCurrentApplicantsWaitingLists] = useState(undefined);
      const [applicantsWaitingPageCount, setApplicantsWaitingPageCount] = useState(0);
      const [applicantsWaitingOffset, setApplicantsWaitingItemOffset] = useState(0);
      const [applicantsWaitingPerPage, setApplicantsWaitingItemsPerPage] = useState(8);

      useEffect(() => {
        if(applicantsWaitingList) {
            const endOffset = applicantsWaitingOffset + applicantsWaitingPerPage;
            setCurrentApplicantsWaitingLists(applicantsWaitingList.slice(applicantsWaitingOffset, endOffset));
            setApplicantsWaitingPageCount(Math.ceil(applicantsWaitingList.length / applicantsWaitingPerPage));
        }
       }, [applicantsWaitingOffset, applicantsWaitingPerPage, applicantsWaitingList]);
   
       const handleApplicantsWaitingPageClick = (event) => {
        const newOffset = (event.selected * applicantsWaitingPerPage) % applicantsWaitingList.length;
        setApplicantsWaitingItemOffset(newOffset);
    };
    //********************************************* ApplicantsWaiting End paginating */

/*********************************************ApplicantsRejected Begin paginating */
      // ApplicantsRejected
      const [applicantsRejectedList, setApplicantsRejectedList] = useState(undefined);
      const [CurrentApplicantsRejectedLists, setCurrentApplicantsRejectedLists] = useState(undefined);
      const [applicantsRejectedPageCount, setApplicantsRejectedPageCount] = useState(0);
      const [applicantsRejectedOffset, setApplicantsRejectedItemOffset] = useState(0);
      const [applicantsRejectedPerPage, setApplicantsRejectedItemsPerPage] = useState(8);

      useEffect(() => {
        if(applicantsRejectedList) {
            const endOffset = applicantsRejectedOffset + applicantsRejectedPerPage;
            setCurrentApplicantsRejectedLists(applicantsRejectedList.slice(applicantsRejectedOffset, endOffset));
            setApplicantsRejectedPageCount(Math.ceil(applicantsRejectedList.length / applicantsRejectedPerPage));
        }
       }, [applicantsRejectedOffset, applicantsRejectedPerPage, applicantsRejectedList]);
   
       const handleApplicantsRejectedPageClick = (event) => {
        const newOffset = (event.selected * applicantsRejectedPerPage) % applicantsRejectedList.length;
        setApplicantsRejectedItemOffset(newOffset);
    };
    //********************************************* ApplicantsRejected End paginating */

    //********************************************* Interviews Start paginating */

    useEffect(() => {
        setInterviewsList([])
        if(props.rwState.interviews &&  props.rwState.interviews.length > 0 &&  props.rwState.interviews.filter(r=>r.status!==-1) &&  props.rwState.interviews.filter(r=>r.status!==-1).length > 0) {
            let mylist = props.rwState.interviews.filter(r=>r.status!==-1);
            setInterviewsList(mylist)
        }
        setInterviewsArchivedList([])
        if(props.rwState.interviews &&  props.rwState.interviews.length > 0 &&  props.rwState.interviews.filter(r=>r.status===-1) &&  props.rwState.interviews.filter(r=>r.status===-1).length > 0 ) {
            let mylist = props.rwState.interviews.filter(r=>r.status===-1);
            setInterviewsArchivedList(mylist)
        }
       }, [props.rwState.interviews]);
   
    /*********************************************Interviews Begin paginating */
      // Interviews
      const [interviewsList, setInterviewsList] = useState(undefined);
      const [CurrentInterviewsLists, setCurrentInterviewsLists] = useState(undefined);
      const [interviewsPageCount, setInterviewsPageCount] = useState(0);
      const [interviewsOffset, setInterviewsItemOffset] = useState(0);
      const [interviewsPerPage, setInterviewsItemsPerPage] = useState(8);

      useEffect(() => {
        if(interviewsList) {
            const endOffset = interviewsOffset + interviewsPerPage;
            setCurrentInterviewsLists(interviewsList.slice(interviewsOffset, endOffset));
            setInterviewsPageCount(Math.ceil(interviewsList.length / interviewsPerPage));
        }
       }, [interviewsOffset, interviewsPerPage, interviewsList]);
   
       const handleInterviewsPageClick = (event) => {
        const newOffset = (event.selected * interviewsPerPage) % interviewsList.length;
        setInterviewsItemOffset(newOffset);
    };
    //********************************************* Interviews End paginating */

     /*********************************************Interviews annule Begin paginating */
      // Interviews
      const [interviewsArchivedList, setInterviewsArchivedList] = useState(undefined);
      const [CurrentInterviewsArchivedLists, setCurrentInterviewsArchivedLists] = useState(undefined);
      const [interviewsArchivedPageCount, setInterviewsArchivedPageCount] = useState(0);
      const [interviewsArchivedOffset, setInterviewsArchivedItemOffset] = useState(0);
      const [interviewsArchivedPerPage, setInterviewsArchivedItemsPerPage] = useState(8);

      useEffect(() => {
        if(interviewsArchivedList) {
            const endOffset = interviewsArchivedOffset + interviewsArchivedPerPage;
            setCurrentInterviewsArchivedLists(interviewsArchivedList.slice(interviewsArchivedOffset, endOffset));
            setInterviewsArchivedPageCount(Math.ceil(interviewsArchivedList.length / interviewsArchivedPerPage));
        }
       }, [interviewsArchivedOffset, interviewsArchivedPerPage, interviewsArchivedList]);
   
       const handleInterviewsArchivedPageClick = (event) => {
        const newOffset = (event.selected * interviewsArchivedPerPage) % interviewsArchivedList.length;
        setInterviewsArchivedItemOffset(newOffset);
    };
    //********************************************* Interviews annule End paginating */

    const [filtrerReference, setFilterReference] = useState(false)
    const [allReferences, setAllReference] = useState(undefined)
    const [selectedReferencePostFilter, setSelectedReferencePostFilter] = useState(undefined)
    const handleReferenceSelectPosteFilter = (e) => {
        (e && e.length>0) ?  setSelectedReferencePostFilter(e.map(res=> {return res._id})) : setSelectedReferencePostFilter([]);
    };
    const closeReferenceFilter = () => {
        setAllReference(props.rwState.references)
        setFilterReference(false);
    }
    const filterReference = () => {
        let references=props.rwState.references;
        if(references){
        if(selectedReferencePostFilter && selectedReferencePostFilter.length> 0){
            references=references.filter(res=>res.recruitment && selectedReferencePostFilter.indexOf(res.recruitment._id) >= 0)
        }
        setAllReference(references)
        return ;
     }
    }
    useEffect(() => {
        setReferencesList([])
        if(allReferences &&  allReferences.length > 0 &&   allReferences.filter(r=>r.status===1) &&   allReferences.filter(r=>r.status===1).length >0) {
            let mylist = allReferences.filter(r=>r.status===1);
            setReferencesList(mylist)
        }
       setReferenceWaitingList([])
        if(allReferences &&  allReferences.length > 0  &&   allReferences.filter(r=>r.status===0) &&   allReferences.filter(r=>r.status===0).length >0) {
            let mylist = allReferences.filter(r=>r.status===0);
            setReferenceWaitingList(mylist)
        }
       setReferenceRejectedList([])
        if(allReferences &&  allReferences.length > 0 &&   allReferences.filter(r=>r.status===-1) &&   allReferences.filter(r=>r.status===-1).length >0) {
            let mylist = allReferences.filter(r=>r.status===-1);
            setReferenceRejectedList(mylist)
        }
       }, [allReferences]);
   
       useEffect(() => {
        setAllReference(props.rwState.references)
       }, [props.rwState.references]);
   
    /*********************************************References Begin paginating */
      // References
      const [referencesList, setReferencesList] = useState(undefined);
      const [CurrentReferencesLists, setCurrentReferencesLists] = useState(undefined);
      const [referencesPageCount, setReferencesPageCount] = useState(0);
      const [referencesOffset, setReferencesItemOffset] = useState(0);
      const [referencesPerPage, setReferencesItemsPerPage] = useState(8);

      useEffect(() => {
        if(referencesList) {
            const endOffset = referencesOffset + referencesPerPage;
            setCurrentReferencesLists(referencesList.slice(referencesOffset, endOffset));
            setReferencesPageCount(Math.ceil(referencesList.length / referencesPerPage));
        }
       }, [referencesOffset, referencesPerPage, referencesList]);
   
       const handleReferencesPageClick = (event) => {
        const newOffset = (event.selected * referencesPerPage) % referencesList.length;
        setReferencesItemOffset(newOffset);
    };
    //********************************************* References End paginating */

    /*********************************************ReferenceWaiting Begin paginating */
      // ReferenceWaiting
      const [referencesWaitingList, setReferenceWaitingList] = useState(undefined);
      const [CurrentReferenceWaitingLists, setCurrentReferenceWaitingLists] = useState(undefined);
      const [referencesWaitingPageCount, setReferenceWaitingPageCount] = useState(0);
      const [referencesWaitingOffset, setReferenceWaitingItemOffset] = useState(0);
      const [referencesWaitingPerPage, setReferenceWaitingItemsPerPage] = useState(8);

      useEffect(() => {
        if(referencesWaitingList) {
            const endOffset = referencesWaitingOffset + referencesWaitingPerPage;
            setCurrentReferenceWaitingLists(referencesWaitingList.slice(referencesWaitingOffset, endOffset));
            setReferenceWaitingPageCount(Math.ceil(referencesWaitingList.length / referencesWaitingPerPage));
        }
       }, [referencesWaitingOffset, referencesWaitingPerPage, referencesWaitingList]);
   
       const handleReferenceWaitingPageClick = (event) => {
        const newOffset = (event.selected * referencesWaitingPerPage) % referencesWaitingList.length;
        setReferenceWaitingItemOffset(newOffset);
    };
    //********************************************* ReferenceWaiting End paginating */

    /*********************************************ReferenceRejected Begin paginating */
      // ReferenceRejected
      const [referencesRejectedList, setReferenceRejectedList] = useState(undefined);
      const [CurrentReferenceRejectedLists, setCurrentReferenceRejectedLists] = useState(undefined);
      const [referencesRejectedPageCount, setReferenceRejectedPageCount] = useState(0);
      const [referencesRejectedOffset, setReferenceRejectedItemOffset] = useState(0);
      const [referencesRejectedPerPage, setReferenceRejectedItemsPerPage] = useState(8);

      useEffect(() => {
        if(referencesRejectedList) {
            const endOffset = referencesRejectedOffset + referencesRejectedPerPage;
            setCurrentReferenceRejectedLists(referencesRejectedList.slice(referencesRejectedOffset, endOffset));
            setReferenceRejectedPageCount(Math.ceil(referencesRejectedList.length / referencesRejectedPerPage));
        }
       }, [referencesRejectedOffset, referencesRejectedPerPage, referencesRejectedList]);
   
       const handleReferenceRejectedPageClick = (event) => {
        const newOffset = (event.selected * referencesRejectedPerPage) % referencesRejectedList.length;
        setReferenceRejectedItemOffset(newOffset);
    };
    //********************************************* ReferenceRejected End paginating */


    useEffect(() => {
        setActivePostsList([])
        if(props.rwState.posts &&  props.rwState.posts.filter(p=>p.status==='active') &&  props.rwState.posts.filter(p=>p.status==='active').length > 0 ) {
            let mylist = props.rwState.posts.filter(p=>p.status==='active');
            setActivePostsList(mylist)
        }
       }, [props.rwState.posts]);
   
    /*********************************************ActivePosts Begin paginating */
      // ActivePosts
      const [activePostsList, setActivePostsList] = useState(undefined);
      const [CurrentActivePostsLists, setCurrentActivePostsLists] = useState(undefined);
      const [activePostsPageCount, setActivePostsPageCount] = useState(0);
      const [activePostsOffset, setActivePostsItemOffset] = useState(0);
      const [activePostsPerPage, setActivePostsItemsPerPage] = useState(8);

      useEffect(() => {
        if(activePostsList) {
            const endOffset = activePostsOffset + activePostsPerPage;
            setCurrentActivePostsLists(activePostsList.slice(activePostsOffset, endOffset));
            setActivePostsPageCount(Math.ceil(activePostsList.length / activePostsPerPage));
        }
       }, [activePostsOffset, activePostsPerPage, activePostsList]);
   
       const handleActivePostsPageClick = (event) => {
        const newOffset = (event.selected * activePostsPerPage) % activePostsList.length;
        setActivePostsItemOffset(newOffset);
    };
    //********************************************* ActivePosts End paginating */

    
    useEffect(() => {
        setArchivedPostsList([])
        if(props.rwState.posts &&  props.rwState.posts.filter(p=>p.status==='archived') &&  props.rwState.posts.filter(p=>p.status==='archived').length > 0 ) {
            let mylist = props.rwState.posts.filter(p=>p.status==='archived');
            setArchivedPostsList(mylist)
        }
       }, [props.rwState.posts]);
   
    /*********************************************ArchivedPosts Begin paginating */
      // ArchivedPosts
      const [archivedPostsList, setArchivedPostsList] = useState(undefined);
      const [CurrentArchivedPostsLists, setCurrentArchivedPostsLists] = useState(undefined);
      const [archivedPostsPageCount, setArchivedPostsPageCount] = useState(0);
      const [archivedPostsOffset, setArchivedPostsItemOffset] = useState(0);
      const [archivedPostsPerPage, setArchivedPostsItemsPerPage] = useState(8);

      useEffect(() => {
        if(archivedPostsList) {
            const endOffset = archivedPostsOffset + archivedPostsPerPage;
            setCurrentArchivedPostsLists(archivedPostsList.slice(archivedPostsOffset, endOffset));
            setArchivedPostsPageCount(Math.ceil(archivedPostsList.length / archivedPostsPerPage));
        }
       }, [archivedPostsOffset, archivedPostsPerPage, archivedPostsList]);
   
       const handleArchivedPostsPageClick = (event) => {
        const newOffset = (event.selected * archivedPostsPerPage) % archivedPostsList.length;
        setArchivedPostsItemOffset(newOffset);
    };
    //********************************************* ArchivedPosts End paginating */
     
 const exportCandidature=()=>{
        props.action_exportCandidatureExcel();
    }

    return <div id={"Recruitment"}>
        <Container fluid>
            <Tab.Container id="left-tabs-example" defaultActiveKey={!isAdmin(role)?"reference":"list"}>
                <div className={"d-flex align-items-start justify-content-between"}>
                    <ContextMenu menu={contextMenu}/>
                    <div hidden={!isAdmin(role)}>
                        <Button variant={"purple"} onClick={e => setShow('newPost')}>{t("create_position")}</Button>
                      
                        {/* <Button variant={"grey"}>Voir a candidature</Button> */}
                    </div>
                </div>
                <Tab.Content>
                    <Tab.Pane eventKey="list">
                        <div>
                            <div>
                                <div className={"mb-4"}>
                                 <div className="sectionSubTitle pt-3 mb-3">{t("applications")} ({props.rwState.applicants?.length})</div>
                                 
                                    <a href="#" className={"btn btn-light btn-sm mr-2"} onClick={()=>exportCandidature()} hidden={filtrerCandidature}>
                                        <BI.BiCloudDownload style={{color: '#240265', fontWeight: '600'}} size={25}/>
                                        {t("export_on")} xlxs
                                     </a>
                                   <a href="#" className={"btn btn-light btn-sm"} onClick={()=>setFilterCandidature(true)} hidden={filtrerCandidature}>
                                        <BI.BiFilter style={{color: '#240265', fontWeight: '600'}} size={25}/>
                                        {t("filter_text")}
                                     </a>
                                   <div className={"mt-2"}>
                                     <Form.Group style={{background: '#fff',padding: '2%'}} hidden={!filtrerCandidature}>
                                    <div className="row">
                                     <div className="col-12 mb-3">
                                        <Form.Label> {t("filter_by")} : </Form.Label>
                                        <BI.BiWindowClose onClick={e=>{closeCandidatureFilter()}} style={{cursor:'pointer', color: '#240265', fontWeight: '600',float:'right'}} size={25}/>
                                     </div>
                                    <div className="col-4" >
                                        <Form.Group >
                                            <Select noOptionsMessage={() => t("no_data_available")}
                                                options={props.rwState.posts}
                                                getOptionLabel={o => o.title}
                                                getOptionValue={o => o._id}
                                                defaultValue={selectedPostFilter}
                                                styles={{
                                                    control: base => ({
                                                        ...base,

                                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                                    })
                                                }}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: '#6600CC',
                                                        primary25: '#6600CC25',
                                                        primary75: '#6600CC75',
                                                        primary50: '#6600CC50'
                                                    },
                                                })}
                                                placeholder={t("position_title_unique")}
                                                isMulti
                                                onChange={handleSelectPosteFilter}
                                            />
                                        </Form.Group>
                                    </div>
                                   
                                   <div className="col-12 pt-1">
                                        <button className="btn btn-outline-primary btn-sm" onClick={e=>{filterCandidature()}}>{t("filter_text")}</button>
                                    </div>
                                </div>
                            </Form.Group>
                       
                                     </div>
                                </div>
                                <Tab.Container id="left-tabs-example" defaultActiveKey={"c-waiting"}>
                                    <div className={"d-flex align-items-start justify-content-between"}>
                                        <ContextMenu menu={contextCandidatureMenu}/>
                                    </div>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="c-waiting">
                                        <div>
                                            <Row>
                                                {CurrentApplicantsWaitingLists && CurrentApplicantsWaitingLists.length > 0 ?
                                                CurrentApplicantsWaitingLists.map(a =>
                                                    <Col className={"col-lg-4 col-12"}>
                                                        <Applicant programInterview={(datas)=>{programInterview(datas)}} colleagues={props.rwState.colleagues} applicant={a}/>
                                                    </Col>
                                                ):
                                                    <Col>
                                                        <div className={'alert alert-warning '}>{t("no_application_for_moment")}</div>
                                                    </Col>
                                                }
                                                <Col md={12} className={"mt-3"}>
                                                <div hidden={!CurrentApplicantsWaitingLists || (CurrentApplicantsWaitingLists && CurrentApplicantsWaitingLists.length<=0)}>
                                                        <ReactPaginate
                                                            breakLabel="..."
                                                            nextLabel={t("paginate_next")}
                                                            onPageChange={handleApplicantsWaitingPageClick}
                                                            pageRangeDisplayed={5}
                                                            pageCount={applicantsWaitingPageCount}
                                                            previousLabel={t("paginate_previous")}
                                                            renderOnZeroPageCount={null}
                                                            className={"react-paginate float-right"}
                                                        />
                                                    </div></Col>
                                            </Row>
                                        </div>
                                        </Tab.Pane>
                                     <Tab.Pane eventKey="c-accepted">
                                        <div>
                                            <Row>
                                                {CurrentApplicantsLists && CurrentApplicantsLists.length > 0 ?
                                                CurrentApplicantsLists.map(a =>
                                                    <Col className={"col-lg-4 col-12"}>
                                                        <Applicant programInterview={(datas)=>{programInterview(datas)}} colleagues={props.rwState.colleagues} applicant={a}/>
                                                    </Col>
                                                ):
                                                    <Col>
                                                        <div className={'alert alert-warning '}>{t("no_application_for_moment")}</div>
                                                    </Col>
                                                }
                                                <Col md={12} className={"mt-3"}>
                                                <div hidden={!CurrentApplicantsLists || (CurrentApplicantsLists && CurrentApplicantsLists.length<=0)}>
                                                        <ReactPaginate
                                                            breakLabel="..."
                                                            nextLabel={t("paginate_next")}
                                                            onPageChange={handleApplicantsPageClick}
                                                            pageRangeDisplayed={5}
                                                            pageCount={applicantsPageCount}
                                                            previousLabel={t("paginate_previous")}
                                                            renderOnZeroPageCount={null}
                                                            className={"react-paginate float-right"}
                                                        />
                                                    </div></Col>
                                            </Row>
                                        </div>
                                        </Tab.Pane>
                                     <Tab.Pane eventKey="c-rejected">
                                        <div>
                                            <Row>
                                                {CurrentApplicantsRejectedLists && CurrentApplicantsRejectedLists.length > 0 ?
                                                CurrentApplicantsRejectedLists.map(a =>
                                                    <Col className={"col-lg-4 col-12"}>
                                                        <Applicant programInterview={(datas)=>{programInterview(datas)}} colleagues={props.rwState.colleagues} applicant={a}/>
                                                    </Col>
                                                ):
                                                    <Col>
                                                        <div className={'alert alert-warning '}>{t("no_application_for_moment")}</div>
                                                    </Col>
                                                }
                                                <Col md={12} className={"mt-3"}>
                                                <div hidden={!CurrentApplicantsRejectedLists || (CurrentApplicantsRejectedLists && CurrentApplicantsRejectedLists.length<=0)}>
                                                        <ReactPaginate
                                                            breakLabel="..."
                                                            nextLabel={t("paginate_next")}
                                                            onPageChange={handleApplicantsRejectedPageClick}
                                                            pageRangeDisplayed={5}
                                                            pageCount={applicantsRejectedPageCount}
                                                            previousLabel={t("paginate_previous")}
                                                            renderOnZeroPageCount={null}
                                                            className={"react-paginate float-right"}
                                                        />
                                                    </div></Col>
                                            </Row>
                                        </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                              
                            </div>
                           
                        </div>
                    </Tab.Pane> <Tab.Pane eventKey="entrevue">
                        <div>
                        <div className={"mt-1 mb-4 "}>
                           
                           <div className={"mb-4"}>
                               <h3 className="sectionSubTitle">{t("schedule_interview")}</h3>
                           </div>
                           <div>
                               <Row>
                           { !CurrentInterviewsLists || CurrentInterviewsLists.length <=0 ?   
                              
                               <Col>
                                  <div className={'alert alert-warning '}>{t("no_schedule_interview")}</div>
                               </Col>
                             :
                           <div className={"specialEventsList"}>
                                   { CurrentInterviewsLists.map(i =>
                                      
                                           <Col className={"col-lg-4 col-12"}>
                                           <SpecialEvent cancelInterview={(id)=>{cancelInterview(id)}} addParticipant={(data)=>{addParticipants(data)}} interview={i} colleagues={props.rwState.colleagues}/>
                                       </Col>
                                       
                                   )}
                           </div>
}
                           <Col md={12} className={"mt-3"}>
                                   <div hidden={!CurrentInterviewsLists || (CurrentInterviewsLists && CurrentInterviewsLists.length<=0)}>
                                           <ReactPaginate
                                               breakLabel="..."
                                               nextLabel={t("paginate_next")}
                                               onPageChange={handleInterviewsPageClick}
                                               pageRangeDisplayed={5}
                                               pageCount={interviewsPageCount}
                                               previousLabel={t("paginate_previous")}
                                               renderOnZeroPageCount={null}
                                               className={"react-paginate float-right"}
                                           />
                                        </div>
                                        </Col>
                                        </Row>
                           </div>
                       </div>

                       <div className={"mt-1 mb-4 "}>
                           
                           <div className={"mb-4"}>
                               <h3 className="sectionSubTitle">{t("cancelled_schedule_interview")}</h3>
                           </div>
                           <div>
                               <Row>
                           { !CurrentInterviewsArchivedLists || CurrentInterviewsArchivedLists.length <=0 ?   
                              
                               <Col>
                                  <div className={'alert alert-warning '}>{t("no_cancelled_schedule_interview")}</div>
                               </Col>
                             :
                           <div className={"specialEventsList"}>
                                   { CurrentInterviewsArchivedLists.map(i =>
                                      
                                           <Col className={"col-lg-4 col-12"}>
                                           <SpecialEvent cancelInterview={(id)=>{cancelInterview(id)}} addParticipant={(data)=>{addParticipants(data)}} interview={i} colleagues={props.rwState.colleagues}/>
                                       </Col>
                                       
                                   )}
                           </div>
}
                           <Col md={12} className={"mt-3"}>
                                   <div hidden={!CurrentInterviewsArchivedLists || (CurrentInterviewsArchivedLists && CurrentInterviewsArchivedLists.length<=0)}>
                                           <ReactPaginate
                                               breakLabel="..."
                                               nextLabel={t("paginate_next")}
                                               onPageChange={handleInterviewsArchivedPageClick}
                                               pageRangeDisplayed={5}
                                               pageCount={interviewsArchivedPageCount}
                                               previousLabel={t("paginate_previous")}
                                               renderOnZeroPageCount={null}
                                               className={"react-paginate float-right"}
                                           />
                                        </div>
                                        </Col>
                                        </Row>
                           </div>
                       </div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="reference">
                    <div className={"mb-4"}>
                                    <h3 className="sectionSubTitle">Reférences ( {allReferences  ? allReferences.length : 0} )</h3>  
                                    <a href="#" className={"btn btn-light btn-sm mt-3"} onClick={()=>setFilterReference(true)} hidden={filtrerReference}>
                                        <BI.BiFilter style={{color: '#240265', fontWeight: '600'}} size={25}/>
                                        {t("filter_text")}
                                     </a>
                                     <div className={"mt-3"}>
                                     <Form.Group style={{background: '#fff',padding: '2%'}} hidden={!filtrerReference}>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <Form.Label> Filtrer par : </Form.Label>
                                        <BI.BiWindowClose onClick={e=>{closeReferenceFilter()}} style={{cursor:'pointer', color: '#240265', fontWeight: '600',float:'right'}} size={25}/>
                                    </div>
                                    <div className="col-4" >
                                        <Form.Group >
                                            <Select noOptionsMessage={() => t("no_data_available")}
                                                options={props.rwState.posts}
                                                getOptionLabel={o => o.title}
                                                getOptionValue={o => o._id}
                                                defaultValue={selectedReferencePostFilter}
                                                styles={{
                                                    control: base => ({
                                                        ...base,

                                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                                    })
                                                }}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: '#6600CC',
                                                        primary25: '#6600CC25',
                                                        primary75: '#6600CC75',
                                                        primary50: '#6600CC50'
                                                    },
                                                })}
                                                placeholder={t("position_title_unique")}
                                                isMulti
                                                onChange={handleReferenceSelectPosteFilter}
                                            />
                                        </Form.Group>
                                    </div>
                                   
                                   <div className="col-12 pt-1">
                                        <button className="btn btn-outline-primary btn-sm" onClick={e=>{filterReference()}}>{t("filter_text")}</button>
                                    </div>
                                </div>
                            </Form.Group>
                       
                                     </div>
                              

                                </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={"r-waiting"}>
                                    <div className={"d-flex align-items-start justify-content-between"}>
                                        <ContextMenu menu={contextReferenceMenu}/>
                                    </div>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="r-waiting">
                                        <div>
                                        <div className={"mb-5"}>
                               
                                <Row>
                                    {CurrentReferenceWaitingLists && CurrentReferenceWaitingLists.length > 0  ?
                                     CurrentReferenceWaitingLists.map(r=>
                                        <Col className={"col-lg-4 col-12"}>
                                            <Referer reference={r}/>
                                        </Col>
                                    ):
                                        <Col>
                                            <div className={"alert alert-warning"}>{t("no_reference")}</div>
                                        </Col>
                                    }
                                      <Col md={12} className={"mt-3"}>
                                        <div hidden={!CurrentReferenceWaitingLists || (CurrentReferenceWaitingLists && CurrentReferenceWaitingLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleReferenceWaitingPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={referencesWaitingPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div>
                                             </Col>
                                </Row>

                            </div>
                                        </div>
                                        </Tab.Pane>
                                     <Tab.Pane eventKey="r-accepted">
                                        <div>
                                        <div className={"mb-5"}>
                                <Row>
                                    {CurrentReferencesLists && CurrentReferencesLists.length > 0    ?
                                     CurrentReferencesLists.map(r=>
                                        <Col className={"col-lg-4 col-12"}>
                                            <Referer reference={r}/>
                                        </Col>
                                    ):
                                        <Col>
                                            <div className={"alert alert-warning"}>{t("no_reference")}</div>
                                        </Col>
                                    }
                                      <Col md={12} className={"mt-3"}>
                                        <div hidden={!CurrentReferencesLists || (CurrentReferencesLists && CurrentReferencesLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleReferencesPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={referencesPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div>
                                             </Col>
                                </Row>

                            </div>
                                        </div>
                                        </Tab.Pane>
                                     <Tab.Pane eventKey="r-rejected">
                                     <div className={"mb-5"}>
                              
                                <Row>
                                    {CurrentReferenceRejectedLists && CurrentReferenceRejectedLists.length > 0  ?
                                     CurrentReferenceRejectedLists.map(r=>
                                        <Col className={"col-lg-4 col-12"}>
                                            <Referer reference={r}/>
                                        </Col>
                                    ):
                                        <Col>
                                            <div className={"alert alert-warning"}>{t("no_reference")}</div>
                                        </Col>
                                    }
                                      <Col md={12} className={"mt-3"}>
                                        <div hidden={!CurrentReferenceRejectedLists || (CurrentReferenceRejectedLists && CurrentReferenceRejectedLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleReferenceRejectedPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={referencesRejectedPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div>
                                             </Col>
                                </Row>

                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                        <div>
                            
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="search">
                        {CurrentActivePostsLists ?
                            CurrentActivePostsLists.length >= 0 ?
                            <div>
                        <Row>
                            <Col>
                                <SearchPostes posts={props.rwState.posts}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className={"resultLabel m-3"}>{t("we_found")} { CurrentActivePostsLists.length  ? CurrentActivePostsLists.length : 0} {t("jobs")}</div>
                            </Col>
                        </Row>
                        <Row>
                            {CurrentActivePostsLists.length > 0 ? CurrentActivePostsLists.map((p =>
                                    <Col xs={"auto"} lg={"4"}>
                                        {/*<Referer post={p} view={"post"} listing={'search'}/>*/}
                                        <Announcement references={props.rwState.references.filter(res=> res.recruitment._id===p._id)} candidats={props.rwState.applicants.filter(res=> res.recruitment._id === p._id)} archive={()=>archivePost(p)} editPost={()=>editPost(p)} deletePost={()=>{deletePost(p._id)}} post={p}/>
                                    </Col>
                            )):
                                <Col>
                                    <div className={"alert alert-warning"}>{t("no_news")}</div>
                                </Col>
                            }
  <Col md={12} className={"mt-3"}>
                                        <div hidden={!CurrentActivePostsLists || (CurrentActivePostsLists && CurrentActivePostsLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleActivePostsPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={activePostsPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div>
                                             </Col>
                        </Row>
                     
                            </div>
                                :
                                <div></div>
                            :
                            <div></div>
                            }
                    </Tab.Pane>
                        <Tab.Pane eventKey="archived">
                            {CurrentArchivedPostsLists ?
                                CurrentArchivedPostsLists.length > 0 ?
                                    <div>
                        <Row>
                            {CurrentArchivedPostsLists.length > 0 ? CurrentArchivedPostsLists.map((p =>
                                    <Col xs={"auto"} lg={"4"}>
                                        {/*<Referer post={p} view={"post"} listing={'search'}/>*/}
                                        <Announcement  references={props.rwState.references.filter(res=> res.recruitment._id===p._id)} candidats={props.rwState.applicants.filter(res=> res.recruitment._id === p._id)} archive={()=>archivePost(p)} editPost={()=>editPost(p)} deletePost={()=>{deletePost(p._id)}} post={p}/>
                                    </Col>
                            )):
                                <Col>
                                    <div className={"alert alert-warning"}>{t("no_news_archived")}</div>
                                </Col>
                            }
<Col md={12} className={"mt-3"}>
                                        <div hidden={!CurrentArchivedPostsLists || (CurrentArchivedPostsLists && CurrentArchivedPostsLists.length<=0)}>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t("paginate_next")}
                                                    onPageChange={handleArchivedPostsPageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={archivedPostsPageCount}
                                                    previousLabel={t("paginate_previous")}
                                                    renderOnZeroPageCount={null}
                                                    className={"react-paginate float-right"}
                                                />
                                             </div>
                                             </Col>
                        </Row>
                                    </div>
                                    :
                                    <div></div>
                                :
                                <div></div>
                            }
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Container>



        <Modal centered show={show === 'newPost'} onHide={handleClose}>
            <Modal.Body className={'p-4'}>
                <Form.Group>
                    <Form.Control
                        size={"lg"}
                        placeholder={t("poste_title")}
                        onChange={handlePosTitle}
                        value={posTitle}
                        className={handleinputValidationError(formError,'title')}
                        />
                               {handleTextValidationError(formError,'title')}
             
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        size={"lg"}
                        as={'textarea'}
                        placeholder={t("description")}
                        value={postDesc}
                        onChange={handlePosDesc}
                        rows={6}
                        className={handleinputValidationError(formError,'postDesc')}
                        />
                               {handleTextValidationError(formError,'postDesc')}
               </Form.Group>
                <Form.Group>
                    <Select noOptionsMessage={() => t("no_data_available")}
                        options={props.rwState.branches}
                        getOptionLabel={o => o.name}
                        getOptionValue={o => o._id}
                        defaultValue={branch}
                        styles={{
                            control: base => ({
                                ...base,

                                minHeight: 48,
                                // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                            })
                        }}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: '#6600CC',
                                primary25: '#6600CC25',
                                primary75: '#6600CC75',
                                primary50: '#6600CC50'
                            },
                        })}
                        placeholder={t("branch_title_unique")}
                        isMulti
                        onChange={handleBranch}
                        className={handleinputValidationError(formError,'branch')}
                        />
                               {handleTextValidationError(formError,'branch')}
              
                </Form.Group>
                <Form.Group>
                    <Select noOptionsMessage={() => t("no_data_available")}
                        options={props.rwState.departments}
                        getOptionLabel={o => o.name}
                        getOptionValue={o => o._id}
                        defaultValue={department}
                        styles={{
                            control: base => ({
                                ...base,

                                minHeight: 48,
                                // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                            })
                        }}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: '#6600CC',
                                primary25: '#6600CC25',
                                primary75: '#6600CC75',
                                primary50: '#6600CC50'
                            },
                        })}
                        placeholder={t("department_title_unique")}
                        isMulti
                        onChange={handleDepartment}
                        className={handleinputValidationError(formError,'department')}
                        />
                               {handleTextValidationError(formError,'department')}
              
                </Form.Group>
                <Form.Group>
                    <Select noOptionsMessage={() => t("no_data_available")}
                        options={props.rwState.positions}
                        getOptionLabel={o => o.title}
                        getOptionValue={o => o._id}
                        defaultValue={position}
                        styles={{
                            control: base => ({
                                ...base,

                                minHeight: 48,
                                // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                            })
                        }}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: '#6600CC',
                                primary25: '#6600CC25',
                                primary75: '#6600CC75',
                                primary50: '#6600CC50'
                            },
                        })}
                        placeholder={t("position_title_unique")}
                        onChange={handlePosition}
                        className={handleinputValidationError(formError,'position')}
                        />
                               {handleTextValidationError(formError,'position')}
              
                </Form.Group>
                <Form.Group>
                    <Select noOptionsMessage={() => t("no_data_available")}
                        options={contract_types}
                        defaultValue={contract_type}
                        styles={{
                            control: base => ({
                                ...base,

                                minHeight: 48,
                                // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                            })
                        }}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: '#6600CC',
                                primary25: '#6600CC25',
                                primary75: '#6600CC75',
                                primary50: '#6600CC50'
                            },
                        })}
                        placeholder={t("poste_type")}
                        onChange={handleContractType}
                        className={handleinputValidationError(formError,'contractType')}
                        />
                               {handleTextValidationError(formError,'contractType')}
              
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        size={"lg"}
                        placeholder={t("city")}
                        onChange={handleCity}
                        value={city}
                        className={handleinputValidationError(formError,'city')}
                        />
                               {handleTextValidationError(formError,'city')}
              
                </Form.Group>
                <Form.Group>
                    <Creatable
                        options={tags}
                        // getOptionLabel={o => o.title}
                        // getOptionValue={o => o.id}
                        styles={{
                            control: base => ({
                                ...base,

                                minHeight: 48,
                                // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                            })
                        }}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: '#6600CC',
                                primary25: '#6600CC25',
                                primary75: '#6600CC75',
                                primary50: '#6600CC50'
                            },
                        })}
                        isMulti
                        isClearable
                        onInputChange={handleTagsInputChange}
                        value={tags}
                        placeholder={t("tags")}
                        onChange={handleTags}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("hired_date")}</Form.Label>
                    <DayPickerInput style={{width:"100%"}}
                        inputProps={{className: 'form-control form-control-lg'}}
                        format="DD-MM-YYYY"
                        formatDate={formatDate}
                        placeholder={"Date limite"}
                        onDayChange={handleOpenDate}
                                    value={openDate}
                                    className={handleinputValidationError(formError,'openDate')}
                                    />
                                           {handleTextValidationError(formError,'openDate')}
                </Form.Group>
                <Form.Group>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"/>
                        }
                        label={t("require_cover_letter")}
                        checked={current_row?.has_motiv_letter}
                        onChange={handleHasMotivLet}
                    />
                </Form.Group>

            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={handleClose}>
                    {t("cancel")}
                </Button>
                <Button variant="primary"
                 disabled={
                   handleDisabledBtnValidationError(formError,
                    ['openDate','branch','position','city','title','department','contractType','postDesc'])
                }
                onClick={createPost}>
                    {t("save")}
                </Button>
            </Modal.Footer>
        </Modal>

    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Recruitment);
