import React from 'react';
import './Lost.css'
import {Button} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
const Lost = () => {
  const { t } = useTranslation();
  return <div id={'Lost'}>
    <div className={'d-flex align-items-center justify-centent-center w-50 h-100 m-auto'}>
      <div className={'text-center'}>
        <h1>{t('lost_title')}</h1>
        <p>{t('lost_text')}</p>
        <Button size={"lg"} href={'/'}>{t('return')}</Button>
      </div>
    </div>
  </div>;
};

export default Lost;
