import React, {useEffect, useRef, useState} from 'react';
import { Select } from '@material-ui/core';
import "./Analytics.css"
import {isAdmin} from "../../../helpers/helper";
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import {
    Col,
    Container,
    Row,

} from "react-bootstrap";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}

const mapDispatchToProps = {...actions}


const Analytics = (props) => {
    const { t } = useTranslation();
    const getDiffDate=(birthdate)=>{
        let birth=new Date(birthdate),
            today=new Date();
        // return birth.diff(today,'years');
        var diff =(birth.getTime() - today.getTime()) / 1000;
        diff /= (60 * 60 * 24);
        return Math.abs(Math.round(diff/365.25));
    };
    const ref = useRef();


    const optionsSt = {
        scales: {
            yAxes: [
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                },
            ],
        },
    };

    const optionsVer = {
        scales: {
            yAxes: [
                {                    stacked: true,

                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                },
            ],
        },
    };

    const OptionTauxRoulement = [
        { value: 'departement', label: t('department') },
        { value: 'mois', label: t('month') },
        { value: 'poste', label: t('poste') },
        { value: 'ans', label: t('year') },
        { value: 'demission', label: 'resignation' }
    ];
   const OptionRecrutment= [
        { value: 'departement', label: t('department') },
        { value: 'ans', label: t('year') },
        { value: 'poste', label: t('poste') },
    ];
    const [taux_roulement,setTauxRoulement]=useState();
    const [recruitement,setRecruitment]=useState();
    const [lineData,setLineData]=useState(undefined);
    const [dataVer,setDataVer]=useState(undefined);
    const [dataDou,setDataDou]=useState(undefined);
    const [dataEthies,setDataEthies]=useState(undefined);
    const [recruit,setDataRecruits]=useState(undefined);
    const [anciennete,setDataAnciennete]=useState(undefined);

    const LineOptions = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };


    useEffect(() => {
        props.action_loading(true);
        props.action_listColleagues()
        props.action_listDepart();
        props.action_listDepartments();
        props.action_listPositions();
        props.action_listPostNoPaginate()
        props.action_listLeave();
        setTauxRoulement('departement')
            }, []);
    useEffect(() => {
        setTauxRoulement('departement')
            if (props.rwState.departments && props.rwState.colleagues && props.rwState.colleagues.length>0 ) {
                let depart = [],
                    data = [];
                for (const dep of props.rwState.departments) {
                    depart.push(dep.name)
                    let employee = props.rwState.colleagues.filter(r => r.department === dep._id && r.onboarded);
                    data.push(employee.length)
                }
                let lineData = {
                    labels: depart ? depart : [],
                    datasets: [
                        {
                            label: t('taux_roulement_by_department'),
                            data: data ? data : [],
                            fill: false,
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                        },
                    ],
                };
                setLineData(lineData)
                let dataVerSet = [
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) >= 18 && getDiffDate(c.personal.birthdate) <= 25).length > 9 ? props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) >= 18 && getDiffDate(c.personal.birthdate) <= 25).length : props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) >= 18 && getDiffDate(c.personal.birthdate) <= 25).length) : 0,
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) >= 26 && getDiffDate(c.personal.birthdate) <= 30).length > 9 ? props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) >= 26 && getDiffDate(c.personal.birthdate) <= 30).length : props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) >= 26 && getDiffDate(c.personal.birthdate) <= 30).length) : 0,
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) >= 31 && getDiffDate(c.personal.birthdate) <= 40).length > 9 ? props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) >= 31 && getDiffDate(c.personal.birthdate) <= 40).length : props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) >= 31 && getDiffDate(c.personal.birthdate) <= 40).length) : 0,
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) >= 41 && getDiffDate(c.personal.birthdate) <= 50).length > 9 ? props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) >= 41 && getDiffDate(c.personal.birthdate) <= 50).length : props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) >= 41 && getDiffDate(c.personal.birthdate) <= 50).length) : 0,
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) >= 51 && getDiffDate(c.personal.birthdate) <= 60).length > 9 ? props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) >= 51 && getDiffDate(c.personal.birthdate) <= 60).length : props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) >= 51 && getDiffDate(c.personal.birthdate) <= 60).length) : 0,
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) > 60).length > 9 ? props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) > 60).length : props.rwState.colleagues.filter(c => getDiffDate(c.personal.birthdate) > 60).length) : 0,
                ];
               const dataVer = {
                    labels: ['18-25', '26-30', '31-40', '41-50', '51-60', '60+'],
                    datasets: [
                        {
                            label: t('employee_age_range'),
                            data: dataVerSet ? dataVerSet : [],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                };
                setDataVer(dataVer)
                //anciennter chart
                let dataAncienneteSet = [
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) >= 0 && getDiffDate(c.hired_on) <= 2).length > 9 ? props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) >= 0 && getDiffDate(c.hired_on) <= 2).length : props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) >= 0 && getDiffDate(c.hired_on) <= 2).length) : 0,
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) >= 3 && getDiffDate(c.hired_on) <= 5).length > 9 ? props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) >= 3 && getDiffDate(c.hired_on) <= 5).length : props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) >= 3 && getDiffDate(c.hired_on) <= 5).length) : 0,
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) >= 6 && getDiffDate(c.hired_on) <= 10).length > 9 ? props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) >= 6 && getDiffDate(c.hired_on) <= 10).length : props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) >= 6 && getDiffDate(c.hired_on) <= 10).length) : 0,
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) >= 11 && getDiffDate(c.hired_on) <= 20).length > 9 ? props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) >= 11 && getDiffDate(c.hired_on) <= 20).length : props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) >= 11 && getDiffDate(c.hired_on) <= 20).length) : 0,
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) >= 21 && getDiffDate(c.hired_on) <= 30).length > 9 ? props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) >= 21 && getDiffDate(c.hired_on) <= 30).length : props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) >= 21 && getDiffDate(c.hired_on) <= 30).length) : 0,
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) > 30).length > 9 ? props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) > 30).length : props.rwState.colleagues.filter(c => getDiffDate(c.hired_on) > 30).length) : 0,
                ];
                const dataAncieneter = {
                    labels: ['0-2', '3-5', '6-10', '11-20', '21-30', '31+'],
                    datasets: [
                        {
                            label: t('employee_seniority'),
                            data: dataAncienneteSet ? dataAncienneteSet : [],
                            backgroundColor: [
                                'rgba(133,255,99,0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(46,192,113,0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255,50,38,0.2)',
                            ],
                            borderColor: [
                                'rgb(29,29,29)',
                                'rgb(171,235,187)',
                                'rgb(66,54,255)',
                                'rgb(192,182,71)',
                                'rgba(153, 102, 255, 1)',
                                'rgb(255,4,93)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                };
                setDataAnciennete(dataAncieneter)
                //end anciennete
                let dataDouSet = [props.rwState.colleagues ? (props.rwState.colleagues.filter(c => c.personal.gender === 'F').length > 9 ? props.rwState.colleagues.filter(c => c.personal.gender === 'F').length : props.rwState.colleagues.filter(c => c.personal.gender === 'F').length) : 0,
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => c.personal.gender === 'H').length > 9 ? props.rwState.colleagues.filter(c => c.personal.gender === 'H').length : props.rwState.colleagues.filter(c => c.personal.gender === 'H').length) : 0,
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => c.personal.gender === 'A').length > 9 ? props.rwState.colleagues.filter(c => c.personal.gender === 'A').length : props.rwState.colleagues.filter(c => c.personal.gender === 'A').length) : 0
                ]
                const dataDou = {
                    labels: [t('woman'), t('man'), t('other')],
                    datasets: [
                        {
                            label: t('employee_sex'),
                            data:  dataDouSet ? dataDouSet : [],
                            backgroundColor: [
                                '#f0f',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',

                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                };
                setDataDou(dataDou)
                let dataEthniesSet = [
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => c.personal.ethnie === 'noir').length > 9 ? props.rwState.colleagues.filter(c => c.personal.ethnie === 'noir').length : props.rwState.colleagues.filter(c => c.personal.ethnie === 'noir').length) : 0,
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => c.personal.ethnie === 'autochtones').length > 9 ? props.rwState.colleagues.filter(c => c.personal.ethnie === 'autochtones').length : props.rwState.colleagues.filter(c => c.personal.ethnie === 'autochtones').length) : 0,
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => c.personal.ethnie === 'blanc').length > 9 ? props.rwState.colleagues.filter(c => c.personal.ethnie === 'blanc').length : props.rwState.colleagues.filter(c => c.personal.ethnie === 'blanc').length) : 0,
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => c.personal.ethnie === 'moyen-orient').length > 9 ? props.rwState.colleagues.filter(c => c.personal.ethnie === 'moyen-orient').length : props.rwState.colleagues.filter(c => c.personal.ethnie === 'moyen-orient').length) : 0,
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => c.personal.ethnie === 'asiatique').length > 9 ? props.rwState.colleagues.filter(c => c.personal.ethnie === 'asiatique').length : props.rwState.colleagues.filter(c => c.personal.ethnie === 'asiatique').length) : 0,
                    props.rwState.colleagues ? (props.rwState.colleagues.filter(c => c.personal.ethnie === 'hispanique-latino').length > 9 ? props.rwState.colleagues.filter(c => c.personal.ethnie === 'hispanique-latino').length : props.rwState.colleagues.filter(c => c.personal.ethnie === 'hispanique-latino').length) : 0
                ];
                const dataEthies = {
                    labels: [t('black'), t('aboriginal'), t('white'), t('middle_est'), t('asian'), t('latino')],
                    datasets: [
                        {
                            label: t('etchnic_ratio'),
                            data: dataEthniesSet ? dataEthniesSet : [],
                            backgroundColor: [
                                '#f0f',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                };
                setDataEthies(dataEthies)
                setRecruitment('departement')
                let recruitm = [],
                    dataRecruit = [];
                if (props.rwState.posts) {
                    for (const dep of props.rwState.departments) {
                        recruitm.push(dep.name)
                        let pots = props.rwState.posts.filter(r => r.departments.indexOf(dep._id) >= 0 && r.status);
                        dataRecruit.push(pots.length)
                    }

                    const dataSt = {
                        labels: recruitm ? recruitm : [],
                        datasets: [
                            {
                                label: t('recrutment_per_department'),
                                data: dataRecruit ? dataRecruit : [],
                                backgroundColor: [
                                    '#5f37ff',
                                    'rgba(73,235,125,0.2)',
                                    'rgba(255, 206, 86, 0.2)',
                                    'rgba(192,90,166,0.2)',
                                    'rgba(153, 102, 255, 0.2)',
                                    'rgba(83,124,255,0.2)',
                                ],
                                borderColor: [
                                    'rgb(93,255,182)',
                                    'rgba(54, 162, 235, 1)',
                                    'rgb(255,118,147)',
                                    'rgb(56,27,192)',
                                    'rgb(255,249,174)',
                                    'rgb(218,26,255)',
                                ],
                            },
                        ],
                    };
                    setDataRecruits(dataSt);
                }
            }
    }, [props.rwState.colleagues]);    
    const [type, setType] = useState()
const handleTauxRoulemane = (e) => {
  let line={};
    switch (e.value) {
        case 'departement':
           let depart=[],
               data=[];
           for(const dep of props.rwState.departments){
               depart.push(dep.name)
               let employee= props.rwState.colleagues.filter(r=> r.department===dep._id && r.onboarded);
                data.push(employee.length)
           }
            line = {
                labels: depart ? depart : [],
                datasets: [
                    {
                        label: t('taux_roulement_by_department'),
                        data: data ? data : [],
                        fill: false,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                    },
                ],
            };
            break;
    case 'mois':
           let date=[t('january'),t('february'),t('march'),t('april'),t('may'),t('june'),t('jully'),t('august'),t('september'),t('october'),t('november'),t('december')],
               dateL=[1,2,3,4,5,6,7,8,9,10,11,12],
               datas=[];
           for(const dat of dateL){
               let empl= props.rwState.colleagues.filter(r=>
               {
                   if(r.onboarded && r.onboarded_at){
                       var myD=new Date(r.onboarded_at)
                       if(myD.getMonth()===dat) return r;
                   }
               })
                   datas.push(empl.length)
           }
            line = {
                labels: date ? date : [],
                datasets: [
                    {
                        label: t('taux_roulement_per_month'),
                        data: datas ? datas : [],
                        fill: false,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                    },
                ],
            };
            break;
     case 'poste':
         let poste=[],
             dataPoste=[];
         for(const pos of props.rwState.positions){
             poste.push(pos.title)
             let employee= props.rwState.colleagues.filter(r=> r.position===pos._id && r.onboarded);
             dataPoste.push(employee.length)
         }
         line = {
             labels: poste ? poste : [],
             datasets: [
                 {
                     label: t('taux_roulement_per_poste'),
                     data: dataPoste ? dataPoste : [],
                     fill: false,
                     backgroundColor: [
                         'rgba(255, 99, 132, 0.2)',
                         'rgba(54, 162, 235, 0.2)',
                         'rgba(255, 206, 86, 0.2)',
                         'rgba(75, 192, 192, 0.2)',
                         'rgba(153, 102, 255, 0.2)',
                         'rgba(255, 159, 64, 0.2)',
                     ],
                     borderColor: [
                         'rgba(255, 99, 132, 1)',
                         'rgba(54, 162, 235, 1)',
                         'rgba(255, 206, 86, 1)',
                         'rgba(75, 192, 192, 1)',
                         'rgba(153, 102, 255, 1)',
                         'rgba(255, 159, 64, 1)',
                     ],
                 },
             ],
         };
         break;
        case 'ans':
            let dateAn=[],
                datasAn=[];
            for(const empl of props.rwState.colleagues){
               if(empl.onboarded_at){
                   let date=new Date(empl.onboarded_at);
                   dateAn.push(date.getFullYear())
               };
            }
            if(dateAn.length>0) {
                for (const dt of dateAn) {
                    let empl = props.rwState.colleagues.filter(r =>{
                        if(r.onboarded_at) {
                            var myD=new Date(r.onboarded_at)
                            if(myD.getFullYear()===dt) return r;
                        }
                    })
                    datasAn.push(empl.length);
                }
            }
            line = {
                labels: dateAn ? dateAn : [],
                datasets: [
                    {
                        label: t('taux_roulement_per_year'),
                        data: datasAn ? datasAn : [],
                        fill: false,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                    },
                ],
            };
            break;
       case 'demission':
            let demission=['demission','depart_volontaire'],
                datasDemisson=[];
                let departD=props.rwState.departs.filter(r=>!r.depart_volontaire);
                let departV=props.rwState.departs.filter(r=>r.depart_volontaire);
                datasDemisson.push(departD.length);
                datasDemisson.push(departV.length);
            line = {
                labels: demission ? demission : [],
                datasets: [
                    {
                        label: t('taux_roulement_per_demission'),
                        data: datasDemisson ? datasDemisson : [],
                        fill: false,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                    },
                ],
            };
            break;
    }
    setLineData(line);
    };

 const handleRecrutement = (e) => {
  let line={};
    switch (e.value) {
        case 'departement':
            let recruitm=[],
                dataRecruit=[];
            for(const dep of props.rwState.departments){
                recruitm.push(dep.name)
                let pots= props.rwState.posts.filter(r=> r.departments.indexOf(dep._id)>=0 && r.status);
                dataRecruit.push(pots.length)
            }
            line = {
                labels:recruitm ? recruitm : [],
                datasets: [
                    {
                        label: t('recruitment_per_department'),
                        data: dataRecruit ? dataRecruit : [],
                        backgroundColor: [
                            '#5f37ff',
                            'rgba(73,235,125,0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(192,90,166,0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(83,124,255,0.2)',
                        ],
                        borderColor: [
                            'rgb(93,255,182)',
                            'rgba(54, 162, 235, 1)',
                            'rgb(255,118,147)',
                            'rgb(56,27,192)',
                            'rgb(255,249,174)',
                            'rgb(218,26,255)',
                        ],
                    },
                ],
            };
            break;
     case 'poste':
         let poste=[],
             dataPoste=[];
         for(const pos of props.rwState.positions){
             poste.push(pos.title)
             let post= props.rwState.posts.filter(r=> r.position_info===pos._id && r.status);
             dataPoste.push(post.length)
         }
         line = {
             labels:poste ? poste : [],
             datasets: [
                 {
                     label: t('recruitment_per_poste'),
                     data: dataPoste ? dataPoste : [],
                     backgroundColor: [
                         '#5f37ff',
                         'rgba(73,235,125,0.2)',
                         'rgba(255, 206, 86, 0.2)',
                         'rgba(192,90,166,0.2)',
                         'rgba(153, 102, 255, 0.2)',
                         'rgba(83,124,255,0.2)',
                     ],
                     borderColor: [
                         'rgb(93,255,182)',
                         'rgba(54, 162, 235, 1)',
                         'rgb(255,118,147)',
                         'rgb(56,27,192)',
                         'rgb(255,249,174)',
                         'rgb(218,26,255)',
                     ],
                 },
             ],
         };
         break;
        case 'ans':
            let dateAn=[],
                datasAn=[];
            for(const post of props.rwState.posts){
                if(post.status) {
                    let date = new Date(post.created_date);
                    dateAn.push(date.getFullYear());
                }
            }
            if(dateAn.length>0) {
                for (const dt of dateAn) {
                    let empl = props.rwState.posts.filter(r =>{
                        if(r.status) {
                            var myD = new Date(r.created_date)
                            if (myD.getFullYear() === dt) return r;
                        }
                    })
                    datasAn.push(empl.length);
                }
            }
            line = {
                labels:dateAn ? dateAn : [],
                datasets: [
                    {
                        label: t('recruitment_per_year'),
                        data: datasAn ? datasAn : [],
                        backgroundColor: [
                            '#5f37ff',
                            'rgba(73,235,125,0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(192,90,166,0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(83,124,255,0.2)',
                        ],
                        borderColor: [
                            'rgb(93,255,182)',
                            'rgba(54, 162, 235, 1)',
                            'rgb(255,118,147)',
                            'rgb(56,27,192)',
                            'rgb(255,249,174)',
                            'rgb(218,26,255)',
                        ],
                    },
                ],
            };
            break;
   }
     setDataRecruits(line);
    };

    return <div style={{padding:'1%',borderLeft:'1px solid #f1f1f1'}}   id={"Analytics"}>
        {isAdmin ?
        props.rwState.colleagues ?
            (props.rwState.colleagues.length > 0
            ?
            <Container fluid>
                <div style={{marginTop: '3%'}}>
                    <Row>
                        <Col xs={4}>
                            <Col style={{backgroundColor: "#fff", padding: "2%"}}>
                                <Row>
                                    <Col xs={6}>
                                        <img src="/static/media/inout.1d8ad5a9.svg" alt="icon" width="24" height="24"/>
                                    </Col>
                                    <Col xs={6}>
                                        <h2 style={{textAlign: 'right', marginTop: '0%'}} className={'pt-1'}>
                                            {props.rwState.colleagues ? (props.rwState.colleagues.length > 9 ? props.rwState.colleagues.length : '0' + props.rwState.colleagues.length) : '-'}
                                        </h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <h6 style={{
                                            textAlign: 'center',
                                            marginTop: '8%',
                                            fontSize: '12px'
                                        }}>{t("employeed")} </h6>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        <Col xs={4}>
                            <Col style={{backgroundColor: "#fff", padding: "2%"}}>
                                <Row>
                                    <Col>
                                        <img src="/static/media/recruit.9feb7075.svg" alt="icon" width="24"
                                             height="24"/>
                                    </Col>
                                    <Col xs={6}>
                                        <h2 style={{textAlign: 'right', marginTop: '0%'}} className={'pt-1'}>
                                            {props.rwState.posts ? (props.rwState.posts.length > 9 ? props.rwState.posts.length : '0' + props.rwState.posts.length) : '-'}
                                        </h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <h6 style={{textAlign: 'center', marginTop: '8%', fontSize: '12px'}}>Recrutement
                                            en cours</h6>
                                    </Col>
                                </Row>
                            </Col>

                        </Col>
                        <Col xs={4}>
                            <Col style={{backgroundColor: "#fff", padding: "2%"}}>
                                <Row>
                                    <Col xs={6}>
                                        <img src="/static/media/holidays.b7ff7d3f.svg" alt="icon" width="24"
                                             height="24"/>
                                    </Col>
                                    <Col xs={6}>
                                        <h2 style={{textAlign: 'right', marginTop: '0%'}} className={'pt-1'}>
                                            {props.rwState.askingLeaves ? (props.rwState.askingLeaves.length > 9 ? props.rwState.askingLeaves.filter(l => l.status === -1).length : '0' + props.rwState.askingLeaves.filter(l => l.status === -1).length) : '-'}
                                        </h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <h6 style={{textAlign: 'center', marginTop: '8%', fontSize: '12px'}}>Employé en
                                            congé</h6>
                                    </Col>
                                </Row>

                            </Col>
                        </Col>
                    </Row>

                </div>
                {/*
            les charts
*/}
                <Row style={{marginTop: '3%'}}>
                    {dataDou ?
                    <Col lg={6} className={"mt-2 p-4"}>
                        <Col style={{backgroundColor: "#fff", border: '1px solid #f8f8f8'}} className={"pt-3"}>
                            <Doughnut data={dataDou}/>
                        </Col>
                    </Col>
                        : ''}
                    {dataVer ?
                    <Col lg={6} className={"mt-2 p-4"}>
                        <Col style={{backgroundColor: "#fff", border: '1px solid #f8f8f8'}} className={"pt-3"}>
                            <Bar data={dataVer} options={optionsVer}/>
                        </Col>
                    </Col>
                        : ''}
                    {anciennete ?
                    <Col lg={6} className={"mt-2 p-4"}>
                        <Col style={{backgroundColor: "#fff", border: '1px solid #f8f8f8'}} className={"pt-3"}>

                            <Bar data={anciennete} options={optionsVer}/>
                        </Col>
                    </Col>
                        : ''}
                    {dataEthies ?
                    <Col lg={6} className={"mt-2 p-4"}>
                        <Col style={{backgroundColor: "#fff", border: '1px solid #f8f8f8'}} className={"pt-3"}>

                            <Bar data={dataEthies} options={optionsVer}/>
                        </Col>
                    </Col>
                        : ''}
                    {lineData ?
                    <Col lg={6} className={"mt-2 p-4"}>
                        <Col style={{backgroundColor: "#fff", border: '1px solid #f8f8f8'}} className={"pt-3"}>
                            <Col>
                                <Select noOptionsMessage={() => t('no_data_available')}
                                   
                                    options={OptionTauxRoulement ? OptionTauxRoulement : []}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            height: 18,
                                            marginTop: '2%',
                                            minHeight: 38,
                                            // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                        })
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#6600CC',
                                            primary25: '#6600CC25',
                                            primary75: '#6600CC75',
                                            primary50: '#6600CC50'
                                        },
                                    })}
                                    defaultValue={taux_roulement}
                                    onChange={handleTauxRoulemane}
                                />
                            </Col>

                            {lineData ?
                                <Line data={lineData} options={LineOptions} id={"myChart"}/>
                                : ''
                            }
                        </Col>
                    </Col>
                        : ''}
                    {recruit ?
                    <Col lg={6} className={"mt-2 p-4"}>
                        <Col style={{backgroundColor: "#fff", border: '1px solid #f8f8f8'}} className={"pt-3"}>
                            <Col>
                                <Select noOptionsMessage={() => t('no_data_available')}
                                    options={OptionRecrutment ? OptionRecrutment : []}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            height: 18,
                                            marginTop: '2%',
                                            minHeight: 38,
                                            // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                        })
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#6600CC',
                                            primary25: '#6600CC25',
                                            primary75: '#6600CC75',
                                            primary50: '#6600CC50'
                                        },
                                    })}
                                    defaultValue={recruitement}
                                    onChange={handleRecrutement}
                                />
                            </Col>

                            {recruit ?
                                <Bar data={recruit} options={optionsSt}/>
                                : ''
                            }
                        </Col>
                    </Col>
                        : ''}
                </Row>
                {/*<Row style={{marginTop:'3%'}}>*/}
                {/*    <Col  style={{backgroundColor:"#fff",border:'1px solid #f8f8f8',marginLeft:'5%'}}  xs={5} >*/}
                {/*        <div className='header'>*/}
                {/*            <Row>*/}
                {/*                <Col xs={7}>*/}
                {/*                    <Form.Group>*/}
                {/*                        <Row>*/}
                {/*                            <Col>*/}
                {/*                                <Select noOptionsMessage={() => t("no_data_available")}*/}
                {/*                                    options={OptionAnnee}*/}
                {/*                                    styles={{*/}
                {/*                                        control: base => ({*/}
                {/*                                            ...base,*/}
                {/*                                            height: 18,*/}
                {/*                                            marginTop:'2%',*/}
                {/*                                            minHeight: 38,*/}
                {/*                                            // borderColor:true?'#dc3545':'hsl(0,0%,80%)'*/}
                {/*                                        })*/}
                {/*                                    }}*/}
                {/*                                    theme={theme => ({*/}
                {/*                                        ...theme,*/}
                {/*                                        colors: {*/}
                {/*                                            ...theme.colors,*/}
                {/*                                            primary: '#6600CC',*/}
                {/*                                            primary25: '#6600CC25',*/}
                {/*                                            primary75: '#6600CC75',*/}
                {/*                                            primary50: '#6600CC50'*/}
                {/*                                        },*/}
                {/*                                    })}*/}
                {/*                                    defaultValue={current_row?.type}*/}
                {/*                                    onChange={handleTypeChange}*/}
                {/*                                />*/}
                {/*                            </Col>*/}
                {/*                        </Row>*/}
                {/*                    </Form.Group>*/}
                {/*                </Col>*/}
                {/*                <Col xs={5} className={'text-right'}>*/}
                {/*                    <Button className={'m-2'} size={"sm"} onClick={e => setShow('expense_modal')}>*/}
                {/*                        <RI.BsFileEarmarkArrowDown/> Télécharger*/}
                {/*                    </Button>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*            <h6 style={{textAlign:'center',marginTop:'5%',fontSize:'12px'}} className='title'>L’évolution des embauches</h6>*/}
                {/*        </div>*/}
                {/*        <Line data={lineData} options={LineOptions} />*/}
                {/*    </Col>*/}
                {/*    <Col xs={5}  style={{backgroundColor:"#ffffff",border:'1px solid #f8f8f8',}}>*/}
                {/*        <div className='header'>*/}
                {/*            <Row>*/}
                {/*                <Col xs={7}>*/}
                {/*                    <Form.Group>*/}
                {/*                        <Row>*/}
                {/*                            <Col>*/}
                {/*                                <Select noOptionsMessage={() => t("no_data_available")}*/}
                {/*                                    options={OptionAnnee}*/}
                {/*                                    styles={{*/}
                {/*                                        control: base => ({*/}
                {/*                                            ...base,*/}
                {/*                                            height: 18,*/}
                {/*                                            minHeight: 38,*/}
                {/*                                            marginTop:'2%',*/}
                {/*                                            // borderColor:true?'#dc3545':'hsl(0,0%,80%)'*/}
                {/*                                        })*/}
                {/*                                    }}*/}
                {/*                                    theme={theme => ({*/}
                {/*                                        ...theme,*/}
                {/*                                        colors: {*/}
                {/*                                            ...theme.colors,*/}
                {/*                                            primary: '#6600CC',*/}
                {/*                                            primary25: '#6600CC25',*/}
                {/*                                            primary75: '#6600CC75',*/}
                {/*                                            primary50: '#6600CC50'*/}
                {/*                                        },*/}
                {/*                                    })}*/}
                {/*                                    defaultValue={current_row?.type}*/}
                {/*                                    onChange={handleTypeChange}*/}
                {/*                                />*/}
                {/*                            </Col>*/}
                {/*                        </Row>*/}
                {/*                    </Form.Group>*/}
                {/*                </Col>*/}
                {/*                <Col xs={5} className={'text-right'}>*/}
                {/*                    <Button className={'m-2'} size={"sm"} onClick={e => setShow('expense_modal')}>*/}
                {/*                        <RI.BsFileEarmarkArrowDown/>  Télécharger*/}
                {/*                    </Button>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*            <h6 style={{textAlign:'center',marginTop:'5%',fontSize:'12px'}} className='title'>Les meilleures plateformes de recrutement</h6>*/}
                {/*        </div>*/}
                {/*        <Bar  data={dataSt} options={optionsSt} />*/}
                {/*    </Col>*/}
                {/*</Row>*/}

            </Container>
            :
            <div className="col-12 pt-5 mt-5 mb-5 pb-5 text-center">
                <h5>{t('loading_init_text')}</h5>
            </div>)
            :
            <div className="col-12 pt-5 mt-5 mb-5 pb-5 text-center">
                <h5>{t('statistic_not_available')}</h5>
            </div>
: <div className="col-12 pt-5 mt-5 mb-5 pb-5">
                <h5>{t('acces_denied')}</h5>
            </div>
        }
    </div>
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
