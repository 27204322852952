import React from 'react';
import "./Loading.css"
import {connect} from "react-redux";
import * as actions from "../../actions";
import {Spinner} from "react-bootstrap";

const mapStateToProps = (state) => {
  return {rwState: state}
}
const mapDispatchToProps = {...actions}

const Loading = (props) => {
  return props.rwState.loading && <div id={'Loading'}>
    <Spinner animation="border" role="status" size={"lg"}>
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
