import React, {useEffect, useState} from 'react';
import {Badge, Button, Col, Row, Modal, Form} from "react-bootstrap";
import {useStore} from "react-redux";
import connect from "react-redux/es/connect/connect";
import * as actions from "../../../actions";
import { getFileUrl} from "../../../service";
import * as BS from "react-icons/bs";
import {
    handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const ViewCandidate = (props) => {
    const { t } = useTranslation();
    const store = useStore()
    const [show, setShow] = useState(false);
    const [comment, setComment] = useState("");
    const [formError, setFormError] = useState({})


    const handleClose = () => setShow(false);
    const handleComment = (e) => {
        setComment(e.target.value)
        let data =handleValidation(t,{type:"text",name:'comment','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };


    useEffect(() => {
    }, [props.applicant])

    const handleAccept = () => {
        let o = {
            status: 1,
            comment:'',
            id:props.applicant._id,
        };
        props.action_engageCandidate(o)
        props.action_loading(true);
        props.onHide();
    }

    const handleReject = () => {
        let o = {
            status: -1,
            comment: comment,
            id:props.applicant._id,
        };
        props.action_rejectCandidate(o);
        props.action_loading(true);
        props.onHide();
    }
    const getFilePath = (filename)=>{
        return filename.includes('file-')?
            getFileUrl(filename):filename;
    };

  return <div id={'ViewCandidate'}>
      <Row>
          <Col md={6}>
              <div className="p-4">
              <div>
                  <strong>{t("first_name")}:</strong> {props.applicant?.firstname}</div>
              <div>
                  <strong>{t("last_name")}:</strong> {props.applicant?.lastname}</div>
              <div>
                  <strong>{t("email")}:</strong> {props.applicant?.email}</div>
             {props.applicant?.comment ? 
              <div>
                  <strong>{t("motif")}:</strong>
                  <p>{props.applicant?.comment}</p>
              </div>
              : ''}
              <div>
                  <strong>{t("cv_url_")}:</strong> <a href={getFilePath(props.applicant?.cv_url)}>
                    {t("see_now")}
                  </a>
              </div>
              {props.applicant?.motivation_letter ?
              <div>
                  <strong>{t("recognise_letter")} :</strong>
                  {props.applicant?.motivation_letter ?
                          <a href={getFilePath(props.applicant?.motivation_letter)}>
                              {getFilePath(props.applicant?.motivation_letter)}
                          </a>
                      :
                      "---"
                  }

              </div>
              : ''}
              <div>
                  <strong>
                      <span>{t("candidat_status")}:  </span>
                      {props.applicant?.status===0 && (<Badge variant="dark">{t("waiting")}</Badge>)}
                      {props.applicant?.status===1 && (<Badge variant="success">{t("recruit")}</Badge>)}
                      {props.applicant?.status===-1 && (<Badge variant="danger">{t("rejected_")}</Badge>)}
                  </strong>
              </div>
              </div>
          </Col>
          <Col md={6}>
              <div style={{ background:'#f8f8f8' }} className="p-4">
                  <h2>{t("poste_details")}</h2>
              <div>
                  <strong>{t("annonce")}:</strong> {props.applicant?.recruitment?.title}
              </div>
              <strong>{t("description")}:</strong>
              <p>{props.applicant?.recruitment?.description}</p>
             
              </div>
          </Col>

      </Row>
      <div hidden={props?.applicant?.status!==0} className={props?.applicant?.status!==0?'':'p-3 text-center d-flex justify-content-center'}>
          <div className="p-2">
              <Button onClick={handleAccept}><BS.BsCheckCircle/> {t("hired")}</Button>
          </div>
          <div className="p-2">
              <Button variant={"danger"} onClick={()=>setShow('comment')}><BS.BsTrash/> {t("rejected_action")}</Button>
          </div>
      </div>

      <Modal size={"md"} centered show={show === 'comment'} onHide={handleClose}>
            <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
                {t("motif")}
            </Modal.Header>
            <Modal.Body className={'p-5'}>
                <Form>
                <Form.Row>
                    <Form.Group style={{width:"100%"}}>
                            <Form.Label>{t("reject_motif")}</Form.Label>
                            <Form.Control
                                required
                                onChange={handleComment}
                                defaultValue={comment}
                                as={"textarea"}  
                                className={handleinputValidationError(formError,'comment')}
                                />
                                       {handleTextValidationError(formError,'comment')}
                    </Form.Group>
                </Form.Row>
                    <br/>
                        <div className={"d-flex align-items-end justify-content-end"}>
                        <Button variant="light" onClick={handleClose}>
                            {t("cancel")}
                        </Button> &nbsp;
                        <Button variant="primary" type="button"
                        disabled={
                            handleDisabledBtnValidationError(formError,
                             ['comment'])
                         }
                        onClick={()=>handleReject()}>
                            {t("validate")}
                        </Button>
                        </div>
                </Form>

            </Modal.Body>

        </Modal>
  

  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCandidate);
