import React, {useEffect, useRef, useState} from 'react';
import './expireDiscount.css'
import * as RI from "react-icons/ri";
import DateCountdown from 'react-date-countdown-timer';
import {getFormatedDate} from "../../../helpers/helper";
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import {MdClose} from "react-icons/md";

const ExpireDiscount = () => {
  const { t } = useTranslation();
  let myref=useRef();
  const [company,setCompany]=useState(undefined);
  const [close,setClose]=useState(true);
  // locales={['an','mois','jour','heure','minute','seconde']}
  // locales_plural={['ans','mois','jours','heures','minutes','secondes']}
  useEffect(()=>{
   let data = JSON.parse(window.localStorage.getItem('glowhr_user')).user.company;
   setCompany(data)
   sessionStorage.getItem('close') && sessionStorage.getItem('close') === 'exist' ? setClose(true) : setClose(false);

    },[window.localStorage.getItem('glowhr_user')])
  const closeExpireDiscount=()=>{
    sessionStorage.setItem('close','exist');
    setClose(true);
  }

  // Random component
const Completionist = () => <span>You are good to go!</span>;

// Renderer callback with condition
const renderer = ({days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <span>{t("expire_at")} {days} {t("days")} {hours}:{minutes}:{seconds}</span>;
  }
};

  return <div id={'expireDiscount'} hidden={close} className={"col-sm-12 col-md-12 pt-2 pb-2  pl-2 text-center"} style={{backgroundColor:"#ffc107"}}>
      <div style={{borderRadius: '50px',fontSize:'10pt',fontWeight:'bold'}}>
      <Countdown
    date={new Date(company?.expired_at)}  renderer={renderer} />
       {/*  Expire le : {getFormatedDate(company?.expired_at)} */}
        {/*<DateCountdown*/}
        {/*    ref={myref}*/}
        {/*    noAnimate={true}*/}
        {/*    dateTo={company?.expired_at}*/}
        {/*    mostSignificantFigure="none"*/}
        {/*    numberOfFigures="6"*/}
        {/*    callback={()=>alert('Hello')} />*/}
      </div>
    <span className={"position-absolute"} style={{top:0,right:'10px',cursor: 'pointer'}} onClick={()=>closeExpireDiscount()}><MdClose size={32} /></span>
  </div>;
};

export default ExpireDiscount;
