import React, { useEffect, useState, useCallback } from 'react';
import './Dashboard.css'
import MainMenu from "../menus/MainMenu";
import RightPane from "../RightPane";
import {Button, Col, Container, Form, Modal, Row, Tab} from "react-bootstrap";
import SectionTitle from "../widgets/SectionTitle";
import SearchBar from "../widgets/SearchBar";
import NotifIcon from "../widgets/NotifIcon";
import CurrentUser from "../widgets/CurrentUser";
import { Route, Switch } from "react-router-dom";
import Branches from "../sections/Branches";
import BrancheInit from "../widgets/BrancheInit";
import Profile from "../sections/Profile";
import ColleguesList from "../sections/ColleguesList";
import InOut from "../sections/InOut";
import Holidays from "../sections/Holidays";
import MyCalendar from "../sections/MyCalendar";
import Suggestions from "../sections/Suggestions";
import ContextMenu from "../menus/ContextMenu";
import Recruitment from "../sections/Recruitment";
import * as RI from "react-icons"
import Communications from "../sections/Communications";
import Communications_details from "../sections/Communications_details";
import Documents from "../sections/Documents";
import Payments from "../sections/Payments";
import Payroll from "../sections/Payroll";
import Settings from "../sections/Settings";
import DashCalendar from "../widgets/DashCalendar";
import DashComm from "../widgets/DashComm";
import DashBestDep from "../widgets/DashBestDep";
import DashTrainProgress from "../widgets/DashTrainProgress";
import moment from 'moment'
import { useSocket } from '../Custom-Hooks'
import { v1 as uuidv1 } from 'uuid'
import { getCalendarEvent } from '../../service'
import Formations from "../sections/Formations";
import Surveys from "../sections/Surveys/Surveys";
import SurveyDetails from "../sections/SurveyDetails/SurveyDetails";
import SurveyStats from "../sections/SurveyStats/SurveyStats";
import Performances from "../sections/Performances";
import PerformanceDetails from "../sections/PerformanceDetails";
import FormationDetails from "../sections/FormationDetails/FormationDetails";
import FormationModuleDetails from "../sections/FormationModuleDetails";
import FormationTestDetails from "../sections/FormationTestDetails";
import Analytics from "../sections/Analytics";
import PayrollDetails from "../sections/PayrollDetails";
import PayrollList from "../sections/PayrollList";
import Parametres from "../sections/Parametres";
import Discipline from "../sections/Discipline/Discipline";
import IntegrationCalendar from "../sections/IntegrationCalendar";
import OuttegrationCalendar from "../sections/OuttegrationCalendar";
import * as actions from "../../actions";
import {connect} from "react-redux";
import Notifications from "../sections/Notifications/Notifications";
import ExpenseDetails from "../sections/ExpenseDetails";
import FormationsFind from "../sections/FormationsFind/FormationsFind";
import Visio from "../sections/Visio/Visio";
import VisioRoom from "../sections/VisioRoom/VisioRoom";
import SurveyResponse from "../sections/SurveyResponse";
import AccountStatus from "../widgets/AccountStatus/AccountStatus";
import {isAdmin} from "../../helpers/helper";
import ExpireDiscount from "../widgets/expireDiscount";

import Select from "react-select";
import Creatable from "react-select/creatable/dist/react-select.esm";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {formatDate} from "react-day-picker/moment";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import menuIconSvg from "../../assets/dash-logo.svg";
import Organigram from "../sections/Organigram";
import DetailsCollegues from "../sections/DetailsCollegues";
import Departement from "../widgets/Departement";
import Positions from "../widgets/Positions";
import { useIdleTimer } from 'react-idle-timer'
import { useTranslation } from 'react-i18next';
import {MdApps} from "react-icons/md";

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}


const Dashboard = (props) => {
  const { t, i18n } = useTranslation();
  const handleOnIdle = event => {
    if(authed && authed!==null){
     localStorage.removeItem('glowhr_user')
     window.location = '/auth'
     setAuthed(false)
    }
   }
   
   const handleOnActive = event => {
   }
   
   const handleOnAction = event => {
   }
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onActive: handleOnActive,
    onAction: handleOnAction,
    onIdle: handleOnIdle,
    debounce: 500
  })
  const [authed, setAuthed] = useState(null)

  const [showMenu, setShowMenu] = useState(false)
  const [show, setShow] = useState(false)
  const [step, setStep] = useState(1)
  const [showLeftPane, setShowLeftPane] = useState(false)
  const [SIO_CHANNEL_GET_EVENT_LIST] = useState('get_event_list_' + uuidv1())
  const socketIoInstance = useSocket()
  const [MyEvents, setMyEvents] = useState([])
  const [role, setRole] = useState(undefined)
  const [user, setUser] = useState(undefined)
  const [company,setCompany]=useState(undefined);
  const [companyExpireDate,setCompanyExpireDate]=useState(undefined);
  const [statusCodeBtn,setStatusCodeBtn]=useState(true);
  const [errorCodeBtn,setErrorCodeBtn]=useState(false);
  const [code,setCode]=useState();

  useEffect(() => {
    //localStorage.removeItem('glowhr_user')
    if (!localStorage.getItem('glowhr_user')) {
      window.location = '/auth'
      setAuthed(false)
    } else {
        setAuthed(true)
        props.action_userListFormation()
        props.action_listEvent()
        props.action_listNotification()
      setRole(JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name);
          setUser(JSON.parse(window.localStorage.getItem('glowhr_user')).user);
    }

  }, [socketIoInstance, SIO_CHANNEL_GET_EVENT_LIST])
  // localStorage.getItem('glowhr_user')

 useEffect(() => {
     if(user) if(!localStorage.getItem('first_come'+user._id)) setShow(true);
     if(user) setCompanyExpireDate(user.company.expired_at);
  }, [user])

 useEffect(() => {
  props.action_setTitle(t('dashboard'))
  }, [])


  
  const contextMenu = []
  const handleClose = () => {
    setShow(false)
  }
  const handleCodes = (e) => {
      let data=e.target.value.replace(' ','')
    if(data.length >= 300){
        setErrorCodeBtn(false)
        setStatusCodeBtn(false)
        setCode(e.target.value)
    }else{
        setErrorCodeBtn(true)
        setStatusCodeBtn(true)
    }
  } 
  
  const upDateCompanyCodes = (e) => {
   let data={
        code:code,
    }
    setStatusCodeBtn(true)
    props.action_updateCompanyData(data);
    props.action_loading(true)
    setCode('');
  }
  function showLeft() {
    setShowMenu(!showMenu)
    setShowLeftPane(!showLeftPane)
  }
  const formatEvents=(events)=>{
    let data=[];
    if(events && events.length>0){
      data= events.map(res=>{
          return {id:res._id,title:res.subject,desc:res.description,
            start:new Date(formatDateNowSpec(res.start_date,'year'),formatDateNowSpec(res.start_date,'month'),formatDateNowSpec(res.start_date,'date'),formatDateNowSpec(res.start_date,'hour'),formatDateNowSpec(res.start_date,'minute'),formatDateNowSpec(res.start_date,'seconde')),
            end:new Date(formatDateNowSpec(res.end_date,'year'),formatDateNowSpec(res.end_date,'month'),formatDateNowSpec(res.end_date,'date'),formatDateNowSpec(res.end_date,'hour'),formatDateNowSpec(res.end_date,'minute'),formatDateNowSpec(res.end_date,'seconde'))};
      })
    }
    return data;
  }
  const formatDateNowSpec = (date,type) => {
    Number.prototype.AddZero= function(b,c){
      var  l= (String(b|| 10).length - String(this).length)+1;
      return l> 0? new Array(l).join(c|| '0')+this : this;
    }//to add zero to less than 10,
    var d = new Date(date),
        f=0;
    switch (type) {
      case 'hour':
        f=d.getHours()+1;
        break;
      case 'minute':
        f=d.getMinutes();
        break;
      case 'year':
        f=d.getFullYear();
        break;
      case 'month':
        f=d.getMonth();
        break;
      case 'date':
        f=d.getDate();
        break;
      case 'seconde':
        f=d.getSeconds();
        break;
    }
    return f;
  }
  const listenEventListResponse = useCallback(
    () => {
      socketIoInstance?.on(SIO_CHANNEL_GET_EVENT_LIST, response => {
        const events = response.data.events
        console.log('events = ', events)
        let MyEvents = formatEvents(events);
        // events.map((val, idx) => {
        //   return ({ id: idx, title: val.title, start: new Date(val.start_date), end: new Date(val.end_date) })
        // })
        console.log('MyEvents => ', MyEvents)
        setMyEvents(MyEvents)
        socketIoInstance.off(SIO_CHANNEL_GET_EVENT_LIST)
      })
    }, [])
  const updateUserFistTime = () => {
    let o = {
      "first_time": false,
      "_id": user._id,
    };

      props.action_simpleUpdate(o);
    setShow(null)
    props.action_loading(true)
  };
  const changeLanguageHandler = (e) =>
  { 
    let lang=e.target.value
    i18n.changeLanguage(lang)
  }


  return authed && <div id={'dash'}>
    {companyExpireDate && moment(companyExpireDate).isAfter()? 
    <div className="row" >
       
      {role && isAdmin(role)
          ?
          <ExpireDiscount/>
          : ""}
      <div className="col-sm-2 col-md-2 d-none d-sm-block" >
        <MainMenu toggleMenu={setShowMenu} menuState={!showMenu} />
      </div>
      <div className="col-sm-10 col-md-10 col-xs-12" >
        <div className={'d-flex'}>
          <div id={'leftPane'} className="d-flex d-block d-sm-none" hidden={showLeftPane}>
            {/* <SideMenu toggleMenu={setShowMenu} menuState={showMenu} /> */}
            {showMenu && <MainMenu toggleMenu={setShowMenu} menuState={showMenu} />}
          </div>
          <div id={'mainContent'}>
            <div className={'mt-4'}>
              <Container fluid>
                <Row>
                  <Col xs={"auto"} className={'d-block d-sm-none d-md-none d-lg-none'}>
                    <Button variant={"white"} size={"lg"} className={"shadow"} onClick={showLeft}><MdApps /></Button>
                  </Col>
                  <Col xs={"auto"} className={'d-none d-sm-block'}><SectionTitle /></Col>
                  {
                    role && isAdmin(role)
                      ?  <Col xs={"auto"}><AccountStatus/></Col>
                      : null
                  }
                  <Col><SearchBar /></Col>
                  {/*<Col xs={"auto"}><NotifIcon/></Col>*/}
                  <Col xs={"auto"}><NotifIcon notifications={props.rwState.notifications ? props.rwState.notifications : []} /></Col>
                  <Col xs={"auto"}>
                  <select className='form-control' onChange={changeLanguageHandler}>
                    <option value={"en"}>English</option>
                    <option value={"fr"}>French</option>
                  </select>
                  </Col>
                  <Col xs={"auto"}><CurrentUser /></Col>
                </Row>
              </Container>
            </div>
            <main className={'mt-4'}>
              <Switch>
                <Route exact path="/dash/">
                  <Container fluid>
                    <Row>
                      <Col xs={12}>
                        <DashCalendar all_events={props.rwState.events && props.rwState.events.length>0 ? props.rwState.events : []} current_events={formatEvents(props.rwState.events && props.rwState.events.length>0 ? props.rwState.events.filter(res=>res.type==="personnal") : [])} />
                        <div className={'mb-3 pb-3'} />
                      </Col>
                    </Row>
                   <Row>
                      <Col xs={12} sm={12} md={12} className={'mb-2'}>
                        <DashTrainProgress progress_formations={props.rwState.user_formations} />
                      </Col>
                      </Row>
                   <Row>
                      <Col xs={12} sm={12} md={12}>
                        <DashComm />
                      </Col>
                    </Row>
                  </Container>
                  <div className={'mb-3 pb-3'} />
                </Route>
                <Route exact path="/dash/backdoor">
                  <Container fluid>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="list">
                      <ContextMenu menu={contextMenu} />
                      <Tab.Content>
                        <Tab.Pane eventKey="list">
                          list
                    </Tab.Pane>
                        <Tab.Pane eventKey="write">
                          write
                    </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Container>

                </Route>
                <Route exact path="/dash/company">
                  <Branches />
                </Route>
                <Route exact path="/dash/hierarchy">
                  <Organigram />
                </Route>
                <Route exact path="/dash/profile">
                  <Profile />
                </Route>

                <Route exact path="/dash/colleagues">
                  <ColleguesList />
                </Route>
                 <Route exact path="/dash/colleague/:id">
                  <DetailsCollegues />
                </Route>
                <Route exact path="/dash/inout">
                  <InOut />
                </Route>
                 <Route exact path="/dash/user/inout/:id">
                  <IntegrationCalendar />
                </Route>
                 <Route exact path="/dash/user/inout/out/:id">
                  <OuttegrationCalendar />
                </Route>
                <Route exact path="/dash/holidays">
                  <Holidays />
                </Route>
                <Route exact path="/dash/courses">
                  <Formations />
                </Route>
                  <Route exact path="/dash/courses/:id">
                  <FormationDetails />
                </Route>
                <Route exact path="/dash/courses/learning/:id">
                  <FormationModuleDetails />
                </Route>
                 <Route exact path="/dash/courses/test/:id">
                  <FormationTestDetails/>
                </Route>
                 <Route exact path="/dash/courses/select/:id_formation">
                  <FormationsFind/>
                </Route>
                <Route exact path="/dash/calendar">
                  <MyCalendar />
                </Route>
                <Route exact path="/dash/documents">
                  <Documents />
                </Route>
                <Route exact path="/dash/communications">
                  <Communications />
                </Route>
               <Route exact path="/dash/communications/:id">
                  <Communications_details />
                </Route>
                <Route exact path="/dash/suggestions">
                  <Suggestions />
                </Route>
                  <Route exact path="/dash/surveys">
                  <Surveys />
                </Route>
                  <Route exact path="/dash/performance">
                  <Performances />
                </Route>
                  <Route exact path="/dash/performance/:id">
                      <PerformanceDetails />
                  </Route>
                  <Route exact path="/dash/discipline/:id?">
                      <Discipline/>
                  </Route>
                  <Route exact path="/dash/surveys/:id">
                  <SurveyDetails />
                </Route>
                 <Route exact path="/dash/surveys/stats/:id">
                  <SurveyStats />
                </Route>
                <Route exact path="/dash/recruit">
                  <Recruitment />
                </Route>
                <Route exact path="/dash/payments">
                  <Payments />
                </Route>
                <Route exact path="/dash/payroll">
                  <Payroll />
                </Route>
                <Route exact path="/dash/analytics">
                  <Analytics />
                </Route>
                <Route exact path="/dash/payroll/details/:id">
                  <PayrollDetails />
                </Route>
                <Route exact path="/dash/payroll/expense/:id">
                  <ExpenseDetails />
                </Route>
                  <Route exact path="/dash/payroll_list/:employee_id">
                  <PayrollList />
                </Route>
             <Route exact path="/dash/general/settings">
                  <Parametres />
                </Route>
             <Route exact path="/dash/notifications">
                  <Notifications />
                </Route>
                <Route exact path={"/dash/conference"}>
                  <Visio/>
                </Route>
                <Route exact path={"/dash/conference/:id"}>
                  <VisioRoom/>
                </Route>
              </Switch>
            </main>
          </div>
         {/*  <div className="panelright">
            <div className="dropdown-content ">  <RightPane /></div>
          </div>*/}
            </div> 
      </div>
      {
    user && user.first_time || user.first_time===undefined ?
          <Modal centered show={show} size={"lg"}  backdrop="static" onHide={handleClose}>
            <Modal.Body className={'p-4'}>
              {role && isAdmin(role) ?
                  <div className={"col-lg-12 pt-3"}>
                      {step===1 ?
                      <div>
                    <div className={" pb-2 text-left col-lg-12 mb-3"}>
                      <img src={menuIconSvg} style={{ width: "200px", height: "auto" }} alt={"logo"}/>
                    </div>
                  <h2 className={"text-left col-lg-12 text-uppercase"}>{t('welcome_text')}</h2>
                    <p className={"text-left col-lg-12"}>
                      {t('user_account_text_actif')}
                       <br/> {t('user_account_subtext_actif')}
                    </p>

                                <h3 className={"text-left col-lg-12"}>{t('to_know')} !</h3>
                      <p className={"text-left col-lg-12 font-italic"}>{t('first_init_text1')}. <br/> 
                      {t('first_init_text2')}. </p>
                <div>
                    <div className="col-lg-12 text-left">
                      <Button variant="primary"  onClick={e=>{setStep(2)}}>
                        {t('begin')}
                      </Button>
                    </div>
                </div>
                   </div>
                          : ''}
                      {step===2 ?
                          <div>
                        <Departement/>
                        <div className=" mt-3 text-right">
                            <Button variant="primary" onClick={e=>{setStep(3)}} size={"sm"} disabled={!props.rwState.departments || props.rwState.departments.length<=0}>
                                {t('continous')}
                            </Button>
                        </div>
                    </div>
                          : ''}
                      {step===3 ?
                   <div>
                        <BrancheInit/>
                        <div className=" mt-3 text-right">
                            <Button variant="primary" size={"sm"} onClick={e=>{setStep(4)}} disabled={!props.rwState.branches || props.rwState.branches.length<=0}>
                            {t('continous')}
                            </Button>
                        </div>
                    </div>
                          : ''}
                      {step===4 ?
                 <div>
                        <Positions/>
                        <div className=" mt-3 text-right">
                            <Button variant="primary" size={"sm"} onClick={e=>{setStep(5)}} disabled={!props.rwState.positions || props.rwState.positions.length<=0}>
                            {t('continous')}
                            </Button>
                        </div>
                    </div>
                          : ''}
                  {step===5 ?
                 <div>
                     <div className={" pb-2 text-left col-lg-12 mb-3"}>
                         <img src={menuIconSvg} style={{ width: "200px", height: "auto" }} alt={"logo"}/>
                     </div>
                     <h2 className={"text-left col-lg-12 text-uppercase"}>{t('preconfig_text')}</h2>
                     <p className={"text-left col-lg-12"}>
                         {t('first_init_text3')}
                         <br/>
                       <span className={"font-italic"} style={{color:"orange"}}> {t('first_init_note')} <strong>{t('employed_profil')}</strong> {t('first_init_noté2')}
                    </span>
                     </p>
                        <div className="col-12 mt-3 text-left">
                            <Button variant="primary" size={"sm"} onClick={updateUserFistTime}>
                                {t('start')}
                            </Button>
                        </div>
                    </div>
                          : ''}
                  </div>
                  :
                  <div className={"col-lg-12 pt-5 pt-5"}>
                    <div>
                      <div className={" pb-2 text-left col-lg-12 mb-2"}>
                        <img src={menuIconSvg} style={{ width: "200px", height: "auto" }} alt={"logo"}/>
                      </div>
                      <h2 className={"text-left col-lg-12"}>{t('welcome_text')}</h2>
                      <p className={"text-left col-lg-12"}>
                      {t('user_account_text_actif')}
                        <br/> {t('admin_account_subtext_actif')}
                      </p>
                    </div>
                    <h3 className={"text-left col-lg-12"}>{t('to_know')} !</h3>
                    <p className={"text-left col-lg-12 font-italic"}>
                      {t('admin_first_init_text1')} 
                      <br/> 
                      {t('admin_first_init_text2')} </p>
                    <div>

                      <h2 className={"text-left col-lg-12"}> {t('profil_update_text')}</h2>
                      <p className={"text-left col-lg-12 "}>
                        {t('admin_first_init_text3')} <strong>{t('employed_profil')}</strong> {t('admin_first_init_text4')}
                      </p>

                    </div>

                    <div>
                      <div className="col-lg-12 text-left">
                        <Button variant="primary"  onClick={updateUserFistTime}>
                          {t('i_understand')}
                        </Button>
                      </div>
                    </div>
                  </div>
              }
            </Modal.Body>
          </Modal>
          : null
    }
    </div>
    :
    (companyExpireDate ? 
    <div className={"row"}>
        <div className={"offset-md-3 col-md-6 col-12 mt-5 pt-5"}>
        <p className={"alert alert-warning col-12 "}>
               {t('abonnement_expire_title')} 
            </p>
            <div className={"col-12 pt-4 pb-4"} style={{ background: "#f8f8f8" }}>
           
            <div className={"form-group"}>
                <label>{t('code')}</label>
                <textarea onChange={handleCodes} style={{ border: errorCodeBtn ? '1px solid red' : '' ,minHeight:'200px'}} className={"form-control"} row={"10"} name={"codes"} placeholder={t('code_insert_text')}></textarea>
              {errorCodeBtn ? <span style={{ color : 'red' }}> {t('invalid_code_text')} </span> : ''}
               <div className={"text-right"}>
               <button type="button" onClick={e=>{upDateCompanyCodes()}} className={"btn btn-primary btn-sm mt-2"} disabled={statusCodeBtn}> {t('validate_code_text')}</button>
               </div>
            </div>
            <p>
              <strong>{t('nb')} : </strong> {t('abonnement_expire_note')} <a href="https://glowhr.com" className={"link"}>www.glowhr.com</a> {t('abonnement_expire_note1')}.
             <br/>
             <br/>
             <a href="https://glowhr.com" className={"link"}>{t('buy_subscription')}</a> 
         
            </p>
            </div>
        </div>
    </div>
   : '')
    }
  </div>;
};


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
