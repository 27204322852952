import React, {useEffect, useState} from 'react';
import "./SingleFormation.css"
import {Button, Card, Col, Dropdown, Form, Modal, Row} from "react-bootstrap";
import * as RI from "react-icons/ri";
import * as BI from "react-icons/bi";
import * as BS from "react-icons/bs";
import {useHistory} from 'react-router-dom';
import * as actions from "../../../actions";
import {connect} from "react-redux";
import Select from "react-select";
import {limitText, isAdmin, goToExternalLink, isOnlyAdmin,
    handleDisabledBtnValidationError, handleValidation, handleFinaleValidation, handleinputValidationError, handleTextValidationError
} from "../../../helpers/helper";
import {formatDate} from 'react-day-picker/moment';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}

const SingleFormation = (props) => {
    const { t } = useTranslation();
    useEffect(() => {

    }, []);
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    let user = JSON.parse(window.localStorage.getItem('glowhr_user')).user;
    const [show, setShow] = useState(false);
    const [showCommentaire, setShowComentaire] = useState(false);
    const [moduleFormation, setModuleFormation] = useState([]);
    const [showStatusConfirm, setShowStatusConfirm] = useState(null);
    const [commentaireCloture, setCommentaireCloture] = useState(undefined);
    const history = useHistory();
    const [actionData, setActionData] = useState({})
    const handleShow = () => setShow(true);
    const [current_row, setCurrentRow] = useState()
    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const [employee, setEmployee] = useState([])
    const [formations, setformations] = useState({})
    const [formationDatas, setFormationDatas] = useState([])
    const [employeeDatas, setEmployeeDatas] = useState([])
    const [isExternal, setIsExternal] = useState(false)
    const [externalLink, setExternalLink] = useState("")
    const [start, setStart] = useState()
    const [end, setEnd] = useState()
    const [formError, setFormError] = useState({})
    const affectations_type =
            [{ label: t('all'), value: 'all' },
            { label: t("departments"), value: "departments"},
            { label: t('branches'), value: 'branches'},
            { label: t('positions'), value: 'positions'},
            { label: t('specific_employee'), value: 'employee'}
            ]
    const [affect, setAffect] = useState(affectations_type[0])
    const [assignTo, setAssignTo] = useState([])
    const [attendees_target_id, setAttendeesTargetId] = useState([])
    const [attendees_target, setAttendeesTarget] = useState("all")
    const handleClose = () => {setShow(false)
        setFormError({})
    };
    const handleAffectation_type = (e) => {
        setAssignTo([]);
        setAffect(e)
        setAttendeesTarget(e.value)
        let data =handleValidation(t,{type:"text",name:'affectationType','value':e.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    };

    const handleStart = (e) => {
        setStart(e.target.value)
        let data =handleValidation(t,{type:"text",name:'start','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleEnd = (e) => {
        setEnd(e.target.value)
        let data =handleValidation(t,{type:"text",name:'end','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }

    const handleSelectAssign = (e) => {
        if(e) {
            setAssignTo(e)
            setAttendeesTargetId(e.filter(res => {
                return res._id
            }))
        }else{
            setAttendeesTargetId([])
        }
    };
    const handleName = (e) => {
        setName(e.target.value)
        let data =handleValidation(t,{type:"text",name:'name','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleExternal = (e) => {
        let data=e.target.value;
         if(data==="external") { setIsExternal(true);}else{
             setIsExternal(false)
        }
    }
    const handleExternalLink = (e) => {
        setExternalLink(e.target.value)
        let data =handleValidation(t,{type:"text",name:'externalLink','value':e.target.value,required:true});
        let form=formError;
                form[data.name]=data   
                setFormError(form)
    }
    const handleEmployee = (e) => {
        if (e) setEmployee(e.map(result=>{
            return result._id;
        }));
    };
    const handleDescriptionName = (e) => {
        setDescription(e.target.value)
    }
   const handleCommentCloture = (e) => {
        setCommentaireCloture(e.target.value)
    }
    const editFormation= (e)=>{
        setIsExternal(e.external)
        setCurrentRow(e);
        setShow('updateModule')
        let data=  handleFinaleValidation(t,[
            {type:"text",name:'start','value':e.start,required:true},
            {type:"text",name:'end','value':e.end,required:true},
            {type:"text",name:'name','value':e.name,required:true},
            {type:"text",name:'externalLink','value':e.external_link,required:true},
            {type:"text",name:'affectationType','value':e.affectations_type,required:true},
           ])
                setFormError(data)
    }
    const deleteFormation= (e)=>{
        props.action_deleteFormation(e)
        props.action_loading(true)
    }
    const showAssignation = (formations)=>{
        setformations(formations)
        setShow('assignations')
    }
   const  showCommentairesData= (formations)=>{
        setformations(formations)
       setShowComentaire('commentaire')
    }
    const closeShowComentaire = ()=>{
        setformations(undefined)
        setShowComentaire(null);
    }
   const showAssignationDetails = async (formations,employee_id)=>{
     await  props.action_listTestsFormations(formations._id);
       let check=[],employeecheck=[];
           if( formations.test_finisher_info && formations.test_finisher_info.length>0){
               formations.test_finisher_info.forEach(response=>{
                    check=  response[employee_id]
                   return;
               })
           }
     if(formations.assign_to)  employeecheck=  formations.assign_to.find(p=>p.employee_info.user===user._id)?.employee_info
       setEmployeeDatas(employeecheck);
           setFormationDatas(check)
        setShow('assignationsDetails')
    }
   const getQuestionData=(questions,id)=>{
       let question= {};
       if(questions && questions.length>0) question= questions.find(res=>res._id===id)
       return question;
    }
    const closeAssignation = ()=>{
        setformations({})
        setShow(null)
    }
    const proceed = () => {
        let o = {
            "name": name,
            "description": description,
            "external":isExternal,
            "external_link":externalLink,
            "sio_channel": "",
            "id":current_row?._id
        };
        props.action_updateFormation(o);
        setShow(null)
        props.action_loading(true)
    };
      const proceedAssignation = () => {
        let o = {
            "formation_info": props.formation._id,
            "attendees_target": attendees_target,
            "attendees_target_id": attendees_target_id,
            "start_date": start,
            "end_date": end,
            "sio_channel": "",
        };
        props.action_createAssignFormation(o);
        setShow(null)
        props.action_loading(true)
    };
    const proceedStatusChange = (change_to) => {
        let o = {
            "formation_info": props.formation._id,
            "status": change_to,
            "sio_channel": "",
        };
        if(commentaireCloture) o.cloture_commentaire=commentaireCloture;
        props.action_changeStatus(o);
        props.action_loading(true)
        setShowStatusConfirm(null)
        setCommentaireCloture(undefined)
    };
    const confirmAction=(data)=>{
        setShow('confirm')
        setActionData(data);
    }
    const confirmDeleteAction=()=>{
        let data=actionData;
        switch (data.target) {
            case 'formation':
                props.action_deleteFormation(data.o);
                break
            case 'assignate_to':
                props.action_deleteAssignFormation(data.o)
                break
        }
        props.action_loading(true)
        setShow(null)
    }
    const checkUserHasFinish=(test_formation)=>{
        let resp =false;
        if(test_formation){
            if( test_formation.test_finisher_info && test_formation.test_finisher_info.length>0){
                test_formation.test_finisher_info.forEach(response=>{
                    let check=  response[test_formation.assign_to.find(p=>p.employee_info.user===user._id)?.employee_info._id]
                    if(check){ resp=true; return; }
                })
            }
        }
        return resp;
    }
    const getFormationModule=async (modules,formation_id)=>{
        let hasModule=0,
          module=await modules.filter(res => res.formation_info && res.formation_info._id && res.formation_info._id===formation_id);
        if(module && module.length>0) hasModule=module.length;
         return hasModule;
    }
     const checkUserHasFinishWithEmployeeId=(test_formation,employee)=>{
        let resp =false;
        if(test_formation){
            if( test_formation.test_finisher_info && test_formation.test_finisher_info.length>0){
                test_formation.test_finisher_info.forEach(response=>{
                    let check=  response[employee._id]
                    if(check){ resp=true; return; }
                })
            }
        }
        return resp;
    }
    const checkHasFinishedModule=(test_formation)=>{
        let response=false;
        if(test_formation) {
                if (test_formation.assign_to && test_formation.assign_to.length > 0) {
                    response = test_formation.assign_to.find(p => p.employee_info.user === user._id)?.status
            }
        }
        return response;
    }
    const getUserNote=(test_formation)=>{
        let resp =false,
            note=0;
        if(test_formation){
            if( test_formation.test_finisher_info && test_formation.test_finisher_info.length>0){
                test_formation.test_finisher_info.forEach(response=>{
                    let check_results=  response[test_formation.assign_to.find(p=>p.employee_info.user===user._id)?.employee_info._id]
                   if(check_results) check_results.forEach(res=>{
                        note+=res.result;
                    });
                    return;
                })
            }
        }
        return note;
    }
    const countResult=(test_formation)=>{
        let total=0;
        if(test_formation){
            if( test_formation.test_finisher_info && test_formation.test_finisher_info.length>0){
                test_formation.test_finisher_info.forEach(response=>{
                    let check_results=  response[test_formation.assign_to.find(p=>p.employee_info.user===user._id)?.employee_info._id]
                    if(check_results) total= check_results.length;
                    return;
                })
            }
        }
        return total;
    }
    const formatResponseData=(res)=>{
        let data=[];
        if(res.includes('|')){
            data=res.split('|')
        }else{
            data[0]=res;
        }
        return data
    }
    return <div className={'pl-0 pr-1 pt-4 border-0  mb-2'} id={props.formation._id}>
            <Card className={"p-4 border-0"} style={{borderRadius: 10,boxShadow:"0px 3px 40px #8b93a564"}}>
                {isAdmin(role) ?
                    <Dropdown className={"float-right manage-dropdown"}>
                        <Dropdown.Toggle variant="white" id="dropdown-basic">
                            <BS.BsThreeDotsVertical/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        
                            {props.formation.status ==="active" && props.formation.external || props.modules && props.modules.length > 0 && props.formation.status ==="active" && !props.formation.external ?
                            <Dropdown.Item onClick={() => {
                                setShow('assign')
                            }}>
                                <BI.BiUserPlus style={{color: '#240265', fontWeight: '600',}} size={15}/> {t("assigned_action")}
                            </Dropdown.Item>
                                : ""
                            }
                            {props.formation.status ==="waiting" && props.modules && props.modules.length >0  ||  props.formation.status ==="waiting" && props.formation.external ?
                            <Dropdown.Item onClick={() => {
                               proceedStatusChange("active")
                            }}>
                              <span> <BI.BiCheckboxChecked style={{color: '#240265', fontWeight: '600',}} size={15}/> {t("activate")} </span>
                              </Dropdown.Item>
                                : ""
                            }

                            {props.formation.status ==="active" ?
                            <Dropdown.Item onClick={() => {
                                proceedStatusChange("waiting")
                            }}>
                                <BI.BiLoader style={{color: '#240265', fontWeight: '600',}} size={15}/> {t("waiting")}
                            </Dropdown.Item>
                                : ""
                            }
                              {props.formation.status ==="finish" ?
                            <Dropdown.Item onClick={() => {
                                proceedStatusChange("waiting")
                            }}>
                                <BI.BiLoader style={{color: '#240265', fontWeight: '600',}} size={15}/> {t("activate")}
                            </Dropdown.Item>
                                : ""
                            }
                            {props.formation.status ==="active" ?

                            <Dropdown.Item onClick={() => {
                                setShowStatusConfirm('confirm')
                            }}>
                                <BI.BiBlock style={{color: '#240265', fontWeight: '600',}} size={15}/> {t("closed")}
                            </Dropdown.Item>
                                : ""
                            }
                             <Dropdown.Item onClick={() => {
                                editFormation(props.formation)
                            }}>
                                <BI.BiEdit style={{color: '#240265', fontWeight: '600',}} size={15}/> {t("update")}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => {
                                confirmAction({target: 'formation', o: props.formation})
                            }}>
                                <BI.BiTrash style={{color: 'red', fontWeight: '600',}} size={15}/> {t("delete")}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    : ""
                }
                <div className={'user mb-12'}><h5 style={{color: '#240265'}}>{props.formation ? props.formation.name : ''}
                    <br/>
                    <small>
                        <span className={"badge badge-light"}>{ !props.formation.external ? t("internal") : t("external")}</span>
                    </small>
                     &nbsp;&nbsp;
                    {!props.formation.external
                        ?
                        (props.formation && props.modules && props.modules.length > 0
                        ?
                        <small>{props.modules.length>9 ? "" : "0" }{props.modules.length} module(s)</small>
                    : <small style={{color:'red'}}>{t("no_module_added")}</small>)
                        :''
                    }
                </h5>
                    {isAdmin(role) ?
                        <h5 style={{color:'orange'}}>
                            {props.formation.assign_to ?
                            props.formation.assign_to.map(r => {
                                if (r.employee_info && r.employee_info.user === user.user && r.start_date) {
                                     return 'Début : ' +formatDate(r.start_date, 'DD/MM/YYYY à H:m')
                                }
                            })
                            : ''
                        }
                            &nbsp;&nbsp;&nbsp;  {props.formation.assign_to ?
                            props.formation.assign_to.map(r => {
                                if (r.employee_info && r.employee_info.user === user.user && r.end_date) {
                                    return 'Fin : ' +formatDate(r.end_date, 'DD/MM/YYYY à H:m')
                                }
                            })
                            : ''

                        }

                        </h5>
                    :''
                    }
                </div>
                <div className={'label mb-12'}
                     style={{color: '#393939', fontSize: '14px'}}>{props.formation ? limitText(props.formation.description,150) : ''}</div>
                <Row style={{marginTop: '35px'}}>
                    {isAdmin(role) && props.formation.status==='finish' ?
                        <Col sm={"6"} className={'user mb-2'} style={{color: '#240265', cursor: 'pointer'}}>
                      <span className={'btn btn-light btn-sm'} onClick={() => {showCommentairesData(props.formation)}}>
                        {props.formation.status ==="finish" ? '' : <BI.BiComment size={15}/>}   {t("comment")}
                      </span>
                        </Col>
                        : ""
                    }
                    {(isAdmin(role) && props.formation.status!=='finish' && props.modules && props.modules.length > 0) || (isAdmin(role) && props.formation.status!=='finish' && props.formation.external)  ?
                        <Col sm={"6"} className={'user'} hidden={props.formation.assign_to.length <= 0}
                             style={{color: '#240265', cursor: 'pointer'}} onClick={() => {
                            showAssignation(props.formation)
                        }}>
                      <span className={'btn btn-light btn-sm'}>  <BI.BiUserPin
                          size={15}/> {props.formation.assign_to.length}
                          &nbsp; {t("collegue")} </span>
                        </Col>
                        : ""
                    }
                    {isAdmin(role) && props.formation.status!=='finish'  ?

                        <Col sm={"6"} className={'user'} hidden={props.formation.assign_to.length > 0}
                            style={{color: '#240265'}}><BI.BiUserPin size={15}/>
                            {t("no_assignation")}
                        </Col>
                        : ""
                    }

                    {isAdmin(role)  ? " " :
                        (checkHasFinishedModule(props.formation) || checkUserHasFinish(props.formation) ?
                        <Col md={8} className={"text-left"}>
                            {(checkHasFinishedModule(props.formation) ?
                                <div className={"badge badge-light badge-md mr-2"}><BI.BiCheckCircle size={15}/> {t("module_finished")}</div>
                                :
                                "")}
                            {
                                (checkUserHasFinish(props.formation) ?
                                    <div className={"badge badge-outline-success badge-md"} style={{color: 'green'}}><BI.BiCheckCircle size={15}/> {t("tests_finished")} : {getUserNote(props.formation) + '/' + countResult(props.formation)}
                                    </div>
                                    : "")

                            }
                        </Col>
                        : ""
                        )
                    }
                    <Col sm={isAdmin(role) ? 6 :"4"} className={isAdmin(role) ? "text-right" : (!checkUserHasFinish(props.formation) ?  "text-left" : "text-right")}>

                        <Button style={{ border: "none", marginTop: "-5px"}} className={'btn btn-light btn-sm'}
                            onClick={e => props.formation.external ? goToExternalLink(props.formation.external_link) : history.push({pathname:'/dash/courses/'+props.formation._id, id:props.formation._id})}>
                            { !props.formation.external ? t("modules_tests") : t("navigate_to") }
                        </Button>
                    </Col>
                </Row>
                {isAdmin(role) && props.formation.status!=='finish' && props.modules && props.modules.length <= 0 ?
                <Row>
                    {!props.formation.external ?
                        <Col>
                            <span style={{color: 'red', fontSize: '10pt'}}><strong>PS: </strong> {t("notif_module_assignation")}</span>
                        </Col>
                        : ''
                    }
                </Row>
                    : ''
}
            </Card>

        <Modal centered show={show === 'assignations'} onHide={handleClose}>
            <Modal.Body className={'p-4'}>
                    <div className={"col-12"}>
                        <Row>
                            <div className={"col-12 mb-4"}> {t("list_module_assignate")} <strong> {formations ? formations.name : '...' } </strong> </div>
                            {formations && formations.assign_to && formations.assign_to.length > 0 ?
                                 formations.assign_to.map((p,i)=>{
                                return <div className={"col-12"}>
                                    <Row>
                                    <div className={"col-8"}>{p.employee_info.firstname+" "+p.employee_info.lastname}</div>
                                    <div  className={"text-right col-4"}>
                                        {checkUserHasFinishWithEmployeeId(props.formation,p.employee_info) ?
                                       <span  className={'btn btn-outline-primary btn-sm'} onClick={()=>{showAssignationDetails(formations,p.employee_info._id)}}>
                                           <BS.BsBarChart size={15} /> {t("follow_up")}
                                        </span>
                                           :
                                            <span  className={'btn btn-light btn-sm'}>
                                           <BI.BiLoader size={15} /> ...
                                        </span>
                                        }
                                            &nbsp;&nbsp;
                                       <span  className={'btn btn-outline-danger btn-sm'} onClick={()=>{confirmAction({target:'assignate_to',o:p._id})}}>
                                           <BS.BsTrash size={15} />
                                        </span>
                                       </div>
                                    </Row>
                                    <hr/>
                                </div>
                            })
                                :
                                <div>{t("no_assignation_found")}</div>
                            }
                        </Row>
                    </div>
            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={closeAssignation}>
                    {t("close")}
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal centered show={show === 'assignationsDetails'} onHide={()=>{setShow('assignations')}}>
            <Modal.Body className={'p-4'}>
                    <div className={"col-12"}>
                        <Row>
                            <div className={" mb-3"}>{t("test_formation_folow_up")}   <br/>
                                <strong>
                                    {t("collegue")} : {employeeDatas.firstname+" "+employeeDatas.lastname} </strong>
                            </div>
                            <div className="col-12 mb-4 pt-2 pb-2" style={{border: "1px solid #240265",color: "#240265",borderRadius:'6px'}}>
                                <strong>{t("total_score_obtained")} : </strong>
                                <span>
                                    {getUserNote(props.formation) <=9 ? 0 : ""}{getUserNote(props.formation)} / {countResult(props.formation) <=9 ? 0 : ""}{countResult(props.formation)}
                            </span>
                            </div>
                            {formationDatas ?
                                formationDatas.map((formation,index1)=>{
                                     return <div className={"col-12 mb-3"} style={{background: "#e5e5e5",borderRadius:"5px",padding: "15px",}}>
                                         <div className="row">
                                             <div className="col-12 mb-3">
                                                 <span style={{background:"#240265",padding:"2px 10px",borderRadius:"6px",color:"#fff"}}> {(index1+1)} </span> &nbsp;&nbsp;
                                                 {getQuestionData(props.rwState.tests_formations,formation.test_formation_info) ? getQuestionData(props.rwState.tests_formations,formation.test_formation_info).question : ""}
                                             </div>
                                             <div className="col-12 mb-3">
                                                 <strong>{t("score_obtained")}: </strong><span>
                                                 { formation.result <=9 ? 0 : ''}{formation.result}</span>
                                             </div>
                                            <div className="col-12 mb-3">
                                                     <strong>{t("response_set")}: </strong> <br/>{
                                                     formation.responses.map((response,index2)=>{
                                                        return <span>{(index2+1)} - {response}</span>
                                            })
                                            }
                                             </div>
                                          <div className="col-12 mb-3">
                                                     <strong>{t("possible_response")} : </strong> <br/>{
                                              formation.test_formation_info ? (
                                                      getQuestionData(props.rwState.tests_formations,formation.test_formation_info).responses ?
                                                      formatResponseData(getQuestionData(props.rwState.tests_formations,formation.test_formation_info).responses).map((response,index3)=>{
                                                        return <span>{(index3+1)} - {response}</span>
                                            })
                                                  : "..." )
                                                  : "..."
                                            }
                                             </div>

                                         </div>
                                     </div>
                                })
                                : ""
                            }

                        </Row>
                    </div>
            </Modal.Body>
            <Modal.Footer className={'border-0 pt-0'}>
                <Button variant="light" onClick={()=>{setShow('assignations')}}>
                    {t("close")}
                </Button>
            </Modal.Footer>
        </Modal>
            {/*Edite Formation*/}
            <Modal centered show={show === 'updateModule'} onHide={handleClose}>
                <Modal.Header className='bg-primary text-white font-weight-bold text-center'>
                    {
                        current_row ? (<Modal.Title className='w-100'>{t("update_text")}</Modal.Title>) : (<Modal.Title className='w-100'>{t("add_training_btn")}</Modal.Title>)
                    }
                </Modal.Header>
                <Modal.Body className={'p-4'}>
                    <Form.Group>
                        <Form.Label>{t("type_training")}</Form.Label> <br/>
                        <div key={`inline-external`} className="mb-3">
                            <Form.Check
                                size={"lg"}
                                type={"radio"}
                                name={"external"}
                                label={"Interne"}
                                value={"internal"}
                                id={"inline-external-2"}
                                inline
                                onChange={handleExternal}
                            />
                            <Form.Check
                                size={"lg"}
                                type={"radio"}
                                name={"external"}
                                label={"Externe"}
                                checked={isExternal}
                                value={"external"}
                                id={"inline-external-1"}
                                inline
                                onChange={handleExternal}
                            />

                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            size={"lg"}
                            placeholder={t("last_name")}
                            defaultValue={current_row?.name}
                            onChange={handleName}
                            className={handleinputValidationError(formError,'name')}
                            />
                                   {handleTextValidationError(formError,'name')}
                    </Form.Group>
                    {isExternal ?
                        <Form.Group>
                            <Form.Label>{t("external_formation_link")}</Form.Label> <br/>
                            <Form.Control
                                size={"lg"}
                                placeholder={t("external_formation_link")}
                                defaultValue={current_row?.external_link}
                                onChange={handleExternalLink}
                                className={handleinputValidationError(formError,'externalLink')}
                                />
                                       {handleTextValidationError(formError,'externalLink')}
                        </Form.Group>
                        : ""
                    }
                    <Form.Group>
                        <Form.Control
                            size={"lg"}
                            as={"textarea"}
                            placeholder={t("description")}
                            onChange={handleDescriptionName}
                            defaultValue={current_row?.description}

                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className={'border-0 pt-0'}>
                    <Button variant="light" onClick={handleClose}>
                        {t("cancel")}
                    </Button>
                    <Button variant="primary"
                     disabled={
                       isExternal ?
                        handleDisabledBtnValidationError(formError,['externalLink','name']) 
                            :  handleDisabledBtnValidationError(formError,['name'])
                    }
                    onClick={proceed}>
                        {t("save")}
                    </Button>
                </Modal.Footer>
            </Modal>
            {/*Assigner*/}
          <Modal centered show={show === 'assign'} onHide={handleClose}>
                <Modal.Header className='bg-primary text-white font-weight-bold text-center'> {t("assign_training_to_employee")}
                </Modal.Header>
                <Modal.Body className={'p-4'}>
                     <Form.Group>
                        <Form.Label>{t("assigned_to")} </Form.Label> <br/>
                        <Form.Group>
                            <Select
                                options={affectations_type}
                                defaultValue={affect}
                                styles={{
                                    control: base => ({
                                        ...base,
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("add_to")}
                                name='affect_to'
                                onChange={handleAffectation_type}
                                className={handleinputValidationError(formError,'affectationType')}
                                />
                                       {handleTextValidationError(formError,'affectationType')}
                        </Form.Group>
                        <Form.Group hidden={affect ? affect.value!=='branches'  : !affect}>
                            <Select
                                options={props.rwState.branches}
                                getOptionLabel={o => o.name}
                                getOptionValue={o => o._id}
                                defaultValue={affect && affect.value==='branches' && current_row && current_row.attendees_target_id ? current_row .attendees_target_id.map(res=>{return res}) : ""}
                                styles={{
                                    control: base => ({
                                        ...base,
                                        minHeight: 48,
                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("branch_title_unique")}
                                isMulti
                                onChange={handleSelectAssign}
                            />
                        </Form.Group>
                        <Form.Group hidden={affect ? affect.value!=='departments'  : !affect}>
                            <Select
                                options={props.rwState.departments}
                                getOptionLabel={o => o.name}
                                getOptionValue={o => o._id}
                                defaultValue={affect && affect.value==='departments' && current_row && current_row.attendees_target_id ? current_row.attendees_target_id.map(res=>{return res}) : ""}
                                styles={{
                                    control: base => ({
                                        ...base,

                                        minHeight: 48,
                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("department_title_unique")}
                                isMulti
                                onChange={handleSelectAssign}
                            />
                        </Form.Group>
                        <Form.Group hidden={affect ? affect.value!=='positions'  : !affect}>
                            <Select
                                options={props.rwState.positions}
                                getOptionLabel={o => o.title}
                                getOptionValue={o => o._id}
                                defaultValue={affect && affect.value!=='positions' && current_row && current_row.attendees_target_id ? current_row.attendees_target_id.map(res=>{return res}) : ""}
                                styles={{
                                    control: base => ({
                                        ...base,

                                        minHeight: 48,
                                        // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("position_title_unique")}
                                isMulti
                                onChange={handleSelectAssign}
                            />
                        </Form.Group>
                        <Form.Group hidden={affect ? affect.value!=='employee' : !affect}>
                            <Select
                                options={props.rwState.colleagues.filter(r=>r.activated)}
                                getOptionLabel={o => o.lastname+' '+o.firstname}
                                getOptionValue={o => o._id}
                                defaultValue={affect && affect.value==='employee' && current_row && current_row.attendees_target_id ? current_row.attendees_target_id.map(res=>{return res}) : ""}
                                styles={{
                                    control: base => ({
                                        ...base,
                                        minHeight: 48,
                                    })
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#6600CC',
                                        primary25: '#6600CC25',
                                        primary75: '#6600CC75',
                                        primary50: '#6600CC50'
                                    },
                                })}
                                placeholder={t("employee_text")}
                                isMulti
                                onChange={handleSelectAssign}
                               />     
                        </Form.Group>
                    </Form.Group>
                    <Row>
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>{t("start_")}</Form.Label> <br/>
                                <Form.Control
                                    onChange={handleStart}
                                    value={start}
                                    type={"datetime-local"}
                                    className={handleinputValidationError(formError,'start')}
                                    />
                                           {handleTextValidationError(formError,'start')}
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>{t("end_")}</Form.Label> <br/>
                                <Form.Control
                                    onChange={handleEnd}
                                    min={start}
                                    value={end}
                                    type={"datetime-local"}
                                    className={handleinputValidationError(formError,'end')}
                                    />
                                           {handleTextValidationError(formError,'end')}
                            </Form.Group>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer className={'border-0 pt-0'}>
                    <Button variant="light" onClick={handleClose}>
                        {t("cancel")}
                    </Button>
                    <Button variant="primary"
                      disabled={
                         handleDisabledBtnValidationError(formError,
                        ['affectationType','start','end'])
                    }
                    onClick={proceedAssignation}>
                        {t("assigned_action")}
                    </Button>
                </Modal.Footer>
            </Modal>

        {/*confirm delete*/}
        <Modal show={show==="confirm"} onHide={() => setShow(null)} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("confirmation")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={"text-center"}>
                    {t("confirme_irreversile_deletion_text")}
                </div>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>
                        <Button variant="primary" onClick={confirmDeleteAction}>
                            {t("yes_delete")}
                        </Button>
                        <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                            {t("cancel")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
 {/*confirm status change*/}
        <Modal show={showStatusConfirm==="confirm"} onHide={() => setShowStatusConfirm(null)} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("confirmation")} de cloture</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={"text-justify"}>
                    {t("close_training_notif")}
                </div>
                <Form.Group className={"mt-3"}>
                <Form.Label>{t("comment")}</Form.Label> <br/>
                <Form.Control
                    onChange={handleCommentCloture}
                    min={start}
                    value={end}
                    as={"textarea"}
                />
            </Form.Group>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-center"}>
                        <Button disabled={!commentaireCloture} variant="primary" onClick={()=>{proceedStatusChange('finish')}}>
                            {t("yes_close")}
                        </Button>
                        <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
                            {t("cancel")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>

        {formations ?
      <Modal show={showCommentaire==="commentaire"} onHide={closeShowComentaire} centered size={"md"}>
            <Modal.Header closeButton className={'border-0'}>
                <Modal.Title>{t("training_comment")} {formations.name}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className={"text-justify"}>
                    {formations.cloture_commentaire}
                </p>
            </Modal.Body>
            <Modal.Footer className={'text-center border-0'}>
                <div>
                    <div className={"text-right"}>
                        <Button className={"ml-3"}  variant="light" onClick={closeShowComentaire}>
                            {t("close")}
                        </Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
: "" }
    </div>
    }

export default connect(mapStateToProps, mapDispatchToProps)(SingleFormation);
