import React, {useEffect, useState} from 'react';
import "./Setting.css"
import { Form, Modal, Button, Col, Container, Row} from "react-bootstrap";
import * as RI from "react-icons/ri";
import Select from "react-select";
import {BsPlus} from "react-icons/bs";
import * as actions from "../../../actions";
import {connect} from "react-redux";

const mapStateToProps = (state) => {
  return {rwState: state}
}
const optionsIndice = [
  { value: 'Pourcentage', label: 'Pourcentage(%)' },
  { value: 'Montant', label: 'Montant' },
];
const OptionElement = [
  { value: 'prime', label: 'Prime' },
  { value: 'taxe', label: 'Taxe' },
];
const OptionChamps = [
  { value: 'manager', label: 'Manager' },
  { value: 'rh', label: 'RH' },
  { value: 'tous', label: 'Tous le monde' },
  { value: 'tous_department', label: 'Tous les departements' },
  { value: 'department', label: 'Un departement' },
  { value: 'tous_branche', label: 'Toutes les branches' },
  { value: 'branche', label: 'Une branche' },
  { value: 'employee', label: 'Un employee' },
];

const mapDispatchToProps = {...actions}


const Setting = (props) => {
  useEffect(() => {
    props.action_listSetting();
  }, []);
  let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
  const [show, setShow] = useState([])
  const [type, setType] = useState()
  const [libelle, setLibelle] = useState()
  const [description, setDescription] = useState()
  const [indice, setIndice] = useState()
  const [valeur, setValue] = useState()
  const [champs, setChamps] = useState()
  const [selectedOption] = useState()
  const [current_row, setCurrentRow] = useState()
  const [isUpdate, setHasUpdate] = useState(false)

  const handleClose = () => {
    setShow(null)
  }

  const handleChangeOptionChamps = e => {
    console.log("Champs",e)
    setChamps(e.value);

  };
  const handleTypeChange = (e) => {
    setType(e.value)
  }

  const handleDescription = (e) => {
    setDescription(e.target.value)
  }
  const handleLibelle = (e) => {
    setLibelle(e.target.value)
  }
  const handleValeur = (e) => {
    setValue(e.target.value)
  }

  const handleIndice = (e) => {
    setIndice(e.value)
  };
  const editSettings = (setting)=>{
    console.log('configuration ==>',setting);
    //expense['id'] = expense._id;
    setting['id'] = setting._id;
    setting['libelle'] = setting.libelle;
    setting['description'] = setting.description;
    setting['type'] =setting.type;
    setting['value'] =valeur;
    setting['indice'] =indice;
    setting['champs'] =setting.champs;
    setCurrentRow(setting);
    props.action_updateSetting(setting)
    setShow(null)
    props.action_loading(true)
    setHasUpdate(true);
  };
  const requestDataBuilderSettings = () => (
      {
        "type": type,
        "libelle": libelle,
        "description": description,
        "indice": indice,
        "value": valeur,
        "champs": champs,
        "sio_channel": "",
      }
  );
  const createSettings = () => {
    let o = requestDataBuilderSettings();
    if (!isUpdate)
      props.action_createSetting(o);
    else{
      o.id = current_row._id;
      props.action_updateSetting(o);
    }
    setShow(null)
    props.action_loading(true)
  };
  const deleteSettings = () => {
    console.log('delete');
    let data = requestDataBuilderSettings();
    data['id'] = current_row._id;
    props.action_deleteSetting(data);
    console.log('data', data);
    setShow(null);
    props.action_loading(true);
    setCurrentRow()
  };
  const deleteSet = s => {
    console.log('row => ', s);
    setCurrentRow(s);
    setShow('confirm_setting')
  };
 const showdetail = d => {
    console.log('row => ', d);
    setCurrentRow(d);
    setShow('detail')
  };

  const renderElement=()=>{
    if(champs && champs ==="department"){
      return  <Col>
        <Form.Label>Selectionner le département</Form.Label>
        <Select
            options={props.rwState.departments}
            getOptionLabel={o => o.name}
            getOptionValue={o => o.name}
            styles={{
              control: base => ({
                ...base,
                height: 48,
                minHeight: 48,
                // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
              })
            }}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#6600CC',
                primary25: '#6600CC25',
                primary75: '#6600CC75',
                primary50: '#6600CC50'
              },
            })}
            defaultValue={champs}
            placeholder={'Liste des departements'}
            onChange={handleTypeChange}
        />
      </Col>

    }
    else if(champs && champs.value ==="branche"){
      return <Col>
        <Form.Label>Selectionner la branche</Form.Label>
        <Select
            options={props.rwState.branches}
            getOptionLabel={o => o.name}
            getOptionValue={o => o.name}
            styles={{
              control: base => ({
                ...base,
                height: 48,
                minHeight: 48,
                // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
              })
            }}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#6600CC',
                primary25: '#6600CC25',
                primary75: '#6600CC75',
                primary50: '#6600CC50'
              },
            })}
            defaultValue={champs}
            placeholder={'Liste des branches'}
            onChange={handleTypeChange}
        />
      </Col>

    }
    else if(champs && champs.value ==="employee"){
      return <Col>
        <Form.Label>Selectionner l'employee</Form.Label>
        <Select
            options={props.rwState.colleagues}
            getOptionLabel={o => o.lastname +' '+  o.firstname}
            getOptionValue={o => o.lastname}
            styles={{
              control: base => ({
                ...base,
                height: 48,
                minHeight: 48,
                // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
              })
            }}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#6600CC',
                primary25: '#6600CC25',
                primary75: '#6600CC75',
                primary50: '#6600CC50'
              },
            })}
            defaultValue={champs}
            placeholder={'Liste des employees'}
            onChange={handleTypeChange}
        />
      </Col>
    }

    return null;
  };
  return <div id={"Setting"}>
    <Container fluid>
      <Row>
        <Col>
            <div className="d-flex align-items-start justify-content-between">

              {/* <div hidden={!role.includes('Employee')}>*/}
                <div style={{marginLeft:"75%",marginTop:"-4%"}} className="d-flex align-items-end justify-content-end">
                    <Button className={'m-2'} size={"sm"} onClick={e => setShow('configuration')}>
                        <BsPlus />  Nouvelle configuration
                    </Button>
                </div>
            </div>
        </Col>
      </Row>
      <Row style={{marginTop:'25px'}}>
        <Col xs={6} >
          {
            props.rwState.settings?.map((q)=>{
              return   (
                    <div  onClick={ ()=>showdetail(q)} style={{marginTop:"25px",cursor:'pointer',color:"#6610f2",fontWeight:"bold"}}>{q.libelle}
                    </div>
              )
            })
          }
        </Col>
          <hr/>
        <Col xs={2}>
          {
            props.rwState.settings?.map((q)=>{
              return   (
                  <div style={{marginTop:"25px"}}>{q.value}
                  </div>

              )
            })
          }
          </Col>
          <hr/>

          <Col    xs={2}>
          {
            props.rwState.settings?.map((q)=>{
              return   (
                  <div style={{marginTop:"25px"}}>{q.indice}
                  </div>
              )
            })
          }
        </Col>
          <hr/>
          <Col  xs={2}>
          {
            props.rwState.settings?.map((q)=>{
              return   (
                  <div >
                    <Button style={{marginTop:"14px"}}  onClick={ ()=>deleteSet(q)}     variant={"link"} size={"sm"}><BS.BsXCircle style={{color: 'red'}} size={22}/></Button>

                  </div>
              )
            })
          }
        </Col>
      </Row>
    </Container>
    <Modal size={"lg"} centered show={show === 'configuration'} onHide={handleClose}>
      <Modal.Header className={"bg-primary"}>
        {
          current_row ? (<Modal.Title className={"col-lg-12 text-white text-center"}>Modification de la configuration</Modal.Title>) : (<Modal.Title className={"col-lg-12 text-white text-center"}>Nouvelle configuration</Modal.Title>)
        }
      </Modal.Header>
      <Modal.Body className={'p-4'}>
        <Form.Group>
          <Row>
            <Col>
              <Select
                  options={OptionElement}
                  styles={{
                    control: base => ({
                      ...base,
                      height: 48,
                      minHeight: 48,
                      // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#6600CC',
                      primary25: '#6600CC25',
                      primary75: '#6600CC75',
                      primary50: '#6600CC50'
                    },
                  })}
                  defaultValue={current_row?.type}
                  placeholder={'Type d\'élement'}
                  onChange={handleTypeChange}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>Libelle</Form.Label>
              <Form.Control
                  defaultValue={current_row?.libelle}
                  onChange={handleLibelle}

              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>Indice</Form.Label>
              <Select
                  value={selectedOption}
                  styles={{
                    control: base => ({
                      ...base,
                      height: 48,
                      minHeight: 48,
                      // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#6600CC',
                      primary25: '#6600CC25',
                      primary75: '#6600CC75',
                      primary50: '#6600CC50'
                    },
                  })}
                  defaultValue={current_row?.indice}
                  onChange={handleIndice}
                  options={optionsIndice}
              />
            </Col>
            <Col>
              <Form.Label>Valeur</Form.Label>
              <Form.Control
                  defaultValue={current_row?.value}
                  onChange={handleValeur}

              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>Champs d'application</Form.Label>
              <Select
                  styles={{
                    control: base => ({
                      ...base,
                      height: 48,
                      minHeight: 48,
                      // borderColor:true?'#dc3545':'hsl(0,0%,80%)'
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#6600CC',
                      primary25: '#6600CC25',
                      primary75: '#6600CC75',
                      primary50: '#6600CC50'
                    },
                  })}
                  defaultValue={current_row?.champs}
                  onChange={handleChangeOptionChamps}
                  options={OptionChamps}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            {renderElement()}
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>Description</Form.Label>
              <Form.Control
                  defaultValue={current_row?.description}
                  onChange={handleDescription}
                  as={"textarea"}/>
            </Col>
          </Row>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className={'border-0 pt-0'}>
        <Button variant="light" onClick={handleClose}>
          {t("cancel")}
        </Button>
        <Button variant="primary" onClick={createSettings}>
          {t("save")}
        </Button>
      </Modal.Footer>
    </Modal>
    <Modal show={show==="confirm_setting"} onHide={() => setShow(null)} centered size={"md"}>
      <Modal.Header closeButton className={'border-0'}>
        <Modal.Title>{t("confirmation")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={"text-center"}>
          {t("confirme_irreversile_deletion_text")}
        </div>
      </Modal.Body>
      <Modal.Footer className={'text-center border-0'}>
        <div>
          <div className={"text-center"}>
            <Button variant="primary" onClick={deleteSettings}>
              Oui, Supprimer
            </Button>
            <Button className={"ml-3"}  variant="light" onClick={() => setShow(null)}>
              {t("cancel")}
            </Button>
          </div>
        </div>

      </Modal.Footer>
    </Modal>
    <Modal show={show==="detail"} onHide={() => setShow(null)} centered size={"md"}>
      <Modal.Header className={"bg-primary"}>
        <Modal.Title className={"col-lg-12 text-white text-center"}>Description de la configuration</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={"text-center"}>
          <div style={{marginTop:"25px"}}>{current_row?.description}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className={'text-center border-0'}>
        <div>
          <div className={"text-center"}>
          </div>
        </div>

      </Modal.Footer>
    </Modal>

  </div>
};

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
