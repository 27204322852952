import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import * as actions from "../../../actions";
import {useParams,useHistory} from 'react-router-dom'
import {getFileUrl} from '../../../service'
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {rwState: state}
}
const mapDispatchToProps = {...actions}
const Communications_details = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    let role = JSON.parse(window.localStorage.getItem('glowhr_user')).user.role_info.name;
    let user = JSON.parse(window.localStorage.getItem('glowhr_user')).user;
    let { id } = useParams();
    let params_id=id;
    useEffect(() => {
        props.action_loading(true);
        props.action_listNews();
        props.action_setTitle("Communication")
    }, []);

    //let url =props.zulip_url //"https://zulip-dev.glow-hr.com/";
  return <div id={"Communications_details"} className={"col-12"}>
          <div className="row">
              <div className="col-12 text-right">
                  <button className="btn btn-light btn-sm" onClick={()=> history.push({pathname:'/dash/communications'})}>{t("turn_backward")}</button>
              </div>
              {props.rwState.news && props.rwState.news.length>0 ?
                  props.rwState.news.map(p=>{
                      if(p._id===id) {
                          return <div className="col-12">
                              <div className="card" style={{border: 'none', borderRadius: '10px'}}>
                                  <div className="card-body">
                                      <h3>{p.title}</h3>

                                      <img src={getFileUrl(p.filename)} alt="" width={"100%"}/>
                                      <p className={"mt-2 mb-3"}>{parse(p.resume)}</p>
                                      <p className={"mt-2 mb-3"}>{parse(p.content)}</p>
                                  </div>
                              </div>
                          </div>
                      }
                  })
              : <div className="col-12 text-center mt-5 pt-5">
                     ....
                  </div>
              }
          </div>
      </div>

};
export default connect(mapStateToProps, mapDispatchToProps)(Communications_details);
